/* eslint-disable camelcase */
/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';

import auth from 'app/containers/auth';
import { createSelector } from 'reselect';
import {
  GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY,
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  SET_AUTH,
  SENDING_REQUEST,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SET_SUBSCRIPTION_MAIL_SENT,
  SET_HAS_SUBSCRIBED,
  SET_AI_PANEL_DISPLAY_MODE,
} from './constants';
import { getAIPanelMode } from '../AIPanelManager';

const getAuthInitialState = () =>
  fromJS({
    username: '',
    password: '',
    loginError: null,
    currentlySending: false,
    loggedIn: auth.loggedIn(),
    subscriptions: {
      subscribed: false,
      /** @deprecated */
      admin: false,
      teams: {},
      acquisition: false,
      teleradiology: false,
      managerPermissions: {},
      ...restoreFromLocalStorage(GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY),
    },
    AIPanelMode: getAIPanelMode(),
  });

function globalReducer(state = getAuthInitialState(), action) {
  switch (action.type) {
    case SET_SUBSCRIPTION_MAIL_SENT:
      return state.set('subscription_mail_sent', action.value);

    case CHANGE_USERNAME:
      return state.set('username', action.username);

    case CHANGE_PASSWORD:
      return state.set('password', action.password);

    case SET_AUTH:
      return state.set('loggedIn', action.newAuthState);

    case SENDING_REQUEST:
      return state.set('currentlySending', action.sending);

    case REQUEST_ERROR:
      return state.set('loginError', action.error);

    case CLEAR_ERROR:
      return state.set('loginError', null);

    case SET_HAS_SUBSCRIBED:
      return state.setIn(['subscriptions', 'subscribed'], action.hasSubscribed);

    case SET_AI_PANEL_DISPLAY_MODE:
      return state.set('AIPanelMode', action.mode);

    default:
      return state;
  }
}

export const selectGlobal = (state) => state.get('global');
export const selectUsername = createSelector(selectGlobal, (global) => global.get('username'));
export const selectLoggedIn = (state) => state.get('global').get('loggedIn');
export const selectSubscribed = (state) =>
  state.get('global').get('subscriptions').get('subscribed');
export const makeSelectAIPanelMode = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('AIPanelMode'));

export const attachGlobalSubscriptionsConfigurationWithLocalStorageSynchronizationObserver = (
  store
) => {
  observeStore(
    store,
    (state) => state.get('global').get('subscriptions'),
    makeSyncReduxWithLocalStorageObserver(GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default globalReducer;
