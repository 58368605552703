import './style.css';

import React from 'react';

class ToolsDropdownIndicator extends React.PureComponent {
  render = () => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="tools-dropdown" {...this.props.innerProps}>
      <i className="svg-icon angle-down" />
    </div>
  );
}

export default ToolsDropdownIndicator;
