import React from 'react';
import { Icon } from 'semantic-ui-react';
import Select, { components } from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const CUSTOM_STYLES = {
  container: (provided) => ({
    ...provided,
    lineHeight: '1',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '10px',
    padding: '0',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#5e5e5e',
    color: 'white',
    borderColor: '#5e5e5e',
    fontSize: 12,
    height: 30,
    minHeight: 30,
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '4',
  }),
  option: (provided) => ({
    ...provided,

    fontSize: 13,
    minHeight: 32,
  }),
  placeholder: () => ({
    color: 'white',
    flexGrow: '1',
    textAlign: 'center',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
    lineHeight: 1,
    textAlign: 'center',
    backgroundColor: '#7e7979',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    width: 15,
    backgroundColor: '#7e7979',
    borderColor: '#7e7979',
  }),
};
class RegionsFilter extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  render() {
    const ValueContainer = ({ children, ...props }) =>
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && <Icon name="filter" style={{ lineHeight: 1 }} />}
          {children}
        </components.ValueContainer>
      );

    const regionsOptions = [
      {
        label: this.formatMessage({ id: 'monitoring.regions.thorax' }),
        value: 'thorax',
      },
      {
        label: this.formatMessage({ id: 'monitoring.regions.abdomen' }),
        value: 'abdomen',
      },
      {
        label: this.formatMessage({ id: 'monitoring.regions.pelvis' }),
        value: 'pelvis',
      },
      {
        label: this.formatMessage({ id: 'monitoring.regions.autre' }),
        value: 'autre',
      },
    ];
    return (
      <Select
        isMulti
        styles={CUSTOM_STYLES}
        ref="regionsFilter"
        placeholder={this.formatMessage({ id: 'monitoring.regions' })}
        options={regionsOptions}
        components={{ ValueContainer }}
        onChange={this.props.setRegions}
        noOptionsMessage={() => null}
        isSearchable={false}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegionsFilter));
