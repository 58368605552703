/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './style.scss';
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Accordion,
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';
import produce from 'immer';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { format } from 'date-fns';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import AddIALicenseModal from 'app/containers/AdminPage/AddIALicenseModal';
import withContext from 'app/utils/withContext';
import AdminStoreContext from 'app/injectors/AdminStore/context';
import AddMemberButton from 'app/containers/AdminPage/AddMemberButton';
import UserStoreContext from 'app/injectors/UserStore/context';
import toastifyPromise from 'app/utils/toastifyPromise';
import AddTeamSponsorButton from 'app/containers/AdminPage/AddTeamSponsorButton';
import ConfirmWithIntl from 'app/components/ConfirmWithIntl';

const TEAM_NAME_VALIDATOR = /[^\w_\d\s:]/;

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const IdentifiableMultiValueRemove = ({ innerProps, ...rest }) => (
  <components.MultiValueRemove
    {...rest}
    innerProps={{ ...innerProps, 'data-testid': 'multi-value-remove' }}
  />
);

class AdminPage extends React.Component {
  /**
   *
   * @param {Object} props
   * @param {AdminStore} props.adminStore
   */
  constructor(props) {
    super(props);
    this.state = {
      isTeamModalOpen: false,
      teamName: '',
      newTeamLoading: false,
      teams: [],
      permissions: [],
    };
  }

  componentDidMount() {
    this.loadPermissions();
    this.refreshTeams();
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  loadPermissions = () => {
    const { userStore } = this.props;
    userStore.getPermissions().then((permissions) => this.setState({ permissions }));
  };

  roleOptions = () => [
    { key: 'admin', value: 'admin', text: this.formatMessage({ id: 'team.role.admin' }) },
    { key: 'member', value: 'member', text: this.formatMessage({ id: 'team.role.member' }) },
  ];

  handleAddTeamSubmit = (e) => {
    e.preventDefault();
    const { adminStore } = this.props;
    const { teamName } = this.state;

    this.setState({ newTeamLoading: true });

    const promise = adminStore.addTeam(teamName);

    toastifyPromise('addTeam', promise);

    promise
      .then(() => {
        this.setState({ newTeamLoading: false, isTeamModalOpen: false });
        this.refreshTeams();
      })
      .catch(() => {
        this.setState({ newTeamLoading: false });
      });
  };

  handleAddTeamSponsorSubmit = (team, newSponsor) => {
    /** @type {{adminStore:AdminStore}} */
    const { adminStore } = this.props;
    const { name, sponsors = [] } = team;

    toastifyPromise(
      'addTeamSponsor',
      adminStore.updateTeamSponsors(name, [...sponsors, newSponsor])
    ).then(this.refreshTeams);
  };

  removeSponsor = (team, sponsor) => {
    /** @type {{adminStore:AdminStore}} */
    const { adminStore } = this.props;
    const { name, sponsors = [] } = team;

    const sponsorsWithRemovedValue = _.reject(sponsors, (v) => v === sponsor);

    toastifyPromise(
      'removeTeamSponsor',
      adminStore.updateTeamSponsors(name, sponsorsWithRemovedValue)
    ).then(this.refreshTeams);
  };

  closeAddTeamModal = () => this.setState({ isTeamModalOpen: false });

  refreshTeams = () => {
    const { adminStore } = this.props;
    adminStore.getTeams().then((teams) => {
      this.setState({ teams });
      teams.forEach(({ name }) => this.refreshTeamMembers(name));
    });
  };

  refreshTeamMembers = (name) => {
    const { adminStore } = this.props;
    adminStore.getTeamMembers(name).then((members) => {
      this.setState(
        produce((draftState) => {
          draftState.teams.find((team) => team.name === name).members = members;
        })
      );
    });
  };

  openAddTeamModal = () => {
    this.setState({ isTeamModalOpen: true });
  };

  handleChange = (event, teamName) => {
    const stateUpdate = {};
    stateUpdate[teamName] = event.target.value;
    this.setState(stateUpdate);
  };

  renderAddTeamModal = () => {
    const { teamName, isTeamModalOpen } = this.state;
    const isValidTeamName = !teamName.match(TEAM_NAME_VALIDATOR);
    const isGlobalAdmin = this.isGlobalAdmin();

    if (!isGlobalAdmin) return null;
    return (
      <Modal
        size="mini"
        onOpen={this.openAddTeamModal}
        open={isTeamModalOpen}
        onClose={this.closeAddTeamModal}
        trigger={
          <Button positive data-testid="adminPage-addTeam-button">
            <Icon name="plus" />
            <FormattedMessage id="adminPage.button.addTeam" />
          </Button>
        }
      >
        <Modal.Header>
          <FormattedMessage id="general.create.header" />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleAddTeamSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                icon="users"
                iconPosition="left"
                placeholder={this.formatMessage({ id: 'adminPage.TeamName' })}
                name="TeamName"
                onChange={(e) => this.handleChange(e, 'teamName')}
                type="text"
                data-testid="adminPage-modal-addTeam-input"
                error={
                  !isValidTeamName && {
                    pointing: 'below',
                    content: <FormattedMessage id="adminPage.badTeamNameError" />,
                  }
                }
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        {this.renderNewTeamFormActions()}
      </Modal>
    );
  };

  renderNewTeamFormActions = () => {
    const { teamName, newTeamLoading } = this.state;
    const isValidTeamName = teamName.length && !teamName.match(TEAM_NAME_VALIDATOR);

    if (newTeamLoading) {
      return (
        <Modal.Actions>
          <Button positive loading />
        </Modal.Actions>
      );
    }

    return (
      <Modal.Actions>
        <Button basic color="red" onClick={this.closeAddTeamModal}>
          <Icon name="times" />
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button
          positive
          disabled={!isValidTeamName}
          type="button"
          onClick={this.handleAddTeamSubmit}
        >
          <Icon name="plus" />
          <FormattedMessage id="general.add" />
        </Button>
      </Modal.Actions>
    );
  };

  updateReferents = async (teamName, referents) => {
    const { adminStore } = this.props;
    await toastifyPromise(
      'updateTeamReferents',
      adminStore.updateTeamReferents(teamName, referents)
    );
    this.refreshTeams();
  };

  onReferentCreate = (team, newReferent) =>
    this.updateReferents(team.name, [...(team.referents_mails ?? []), newReferent]);

  onReferentChange = (team, { action, ...actionMeta }) => {
    if (action !== 'remove-value') return;
    const { removedValue } = actionMeta;
    this.updateReferents(team.name, _.without(team.referents_mails ?? [], removedValue.value));
  };

  renderReferent = (team) => {
    const referents = (team.referents_mails ?? []).map((referentMail) => ({
      label: referentMail,
      value: referentMail,
    }));
    const placeholder = this.formatMessage({ id: 'adminPage.ReferentsMail.placeholder' });

    return (
      <Creatable
        aria-label="Referents Mails"
        isMulti
        placeholder={placeholder}
        options={[]}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            minWidth: '18em',
          }),
        }}
        components={{ MultiValueRemove: IdentifiableMultiValueRemove }}
        onCreateOption={(...args) => this.onReferentCreate(team, ...args)}
        onChange={(_e, action) => this.onReferentChange(team, action)}
        noOptionsMessage={() => placeholder}
        value={referents}
      />
    );
  };

  renderIALicenses = (team) => {
    const isGlobalAdmin = this.isGlobalAdmin();
    const isTeamAdmin = isGlobalAdmin || this.isTeamAdmin(team.name);

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Icon name="certificate" />
            <FormattedMessage id="adminPage.licenses" />
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Table definition striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.license.start" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.license.end" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.license.total_count" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {team.ia_licenses.map((license) => (
                <Table.Row key={license._id} aria-label="ia license details">
                  <Table.Cell>{license.name}</Table.Cell>
                  <Table.Cell>{format(license.start, 'yyyy-MM-dd')}</Table.Cell>
                  <Table.Cell>{format(license.end, 'yyyy-MM-dd')}</Table.Cell>
                  <Table.Cell>{license.total_count}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
        {isTeamAdmin ? (
          <Card.Content extra>
            <AddIALicenseModal team={team} onNewLicense={this.refreshTeams} />
          </Card.Content>
        ) : null}
      </Card>
    );
  };

  updateTeamMemberRole = (teamName, mail, role) => {
    const { adminStore } = this.props;
    const promise = adminStore.updateTeamMember(teamName, mail, role);
    toastifyPromise('updateTeamMember', promise);

    promise.then(this.refreshTeams);
  };

  removeTeamMember = (teamName, mail) => {
    const { adminStore } = this.props;
    const promise = adminStore.removeTeamMember(teamName, mail);
    toastifyPromise('removeTeamMember', promise);

    promise.then(() => this.refreshTeamMembers(teamName));
  };

  // eslint-disable-next-line react/destructuring-assignment
  isTeamAdmin = (teamName) => this.state.permissions?.includes(`team.${teamName}.admin`);

  // eslint-disable-next-line react/destructuring-assignment
  isGlobalAdmin = () => this.state.permissions.includes('admin');

  renderTeamMembers = (team) => {
    const { formatMessage } = this;
    const isGlobalAdmin = this.isGlobalAdmin();
    const isTeamAdmin = isGlobalAdmin || this.isTeamAdmin(team.name);

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Icon name="users" />
            <FormattedMessage id="adminPage.teamMembers" />
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.member.mail" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.member.phoneNumber" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.member.role" />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {team?.members?.map(({ mail, phoneNumber, role }) => {
                const canDeleteMember = isGlobalAdmin || (role === 'member' && isTeamAdmin);
                return (
                  <Table.Row key={mail} aria-label="team-member-row">
                    <Table.Cell>{mail}</Table.Cell>
                    <Table.Cell>{phoneNumber}</Table.Cell>
                    <Table.Cell>
                      {isGlobalAdmin ? (
                        <Dropdown
                          value={role}
                          onChange={(e, { value }) =>
                            this.updateTeamMemberRole(team.name, mail, value)
                          }
                          options={this.roleOptions()}
                          icon="pencil"
                        />
                      ) : (
                        <FormattedMessage id={`team.role.${role}`} />
                      )}
                    </Table.Cell>
                    {isTeamAdmin && (
                      <Table.Cell textAlign="right">
                        {canDeleteMember ? (
                          <ConfirmWithIntl
                            trigger={
                              <Button
                                aria-label="team.member.delete"
                                icon="trash"
                                color="red"
                                size="mini"
                                title={formatMessage({ id: 'general.delete' })}
                              />
                            }
                            onConfirm={() => this.removeTeamMember(team.name, mail)}
                            contentId="adminPage.deleteMember.confirm"
                          />
                        ) : null}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Card.Content>
        {isTeamAdmin ? (
          <Card.Content extra>
            <AddMemberButton
              team={team}
              onNewTeamMember={() => this.refreshTeamMembers(team.name)}
            />
          </Card.Content>
        ) : null}
      </Card>
    );
  };

  renderTeamSponsors = (team) => {
    const { formatMessage } = this;
    const isGlobalAdmin = this.isGlobalAdmin();
    const isTeamAdmin = isGlobalAdmin || this.isTeamAdmin(team.name);

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Icon name="barcode" />
            <FormattedMessage id="adminPage.sponsors.title" />
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="adminPage.sponsors.header.name" />
                </Table.HeaderCell>
                {isTeamAdmin && <Table.HeaderCell width={1} />}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {team?.sponsors?.map((sponsorName) => (
                <Table.Row key={sponsorName} aria-label="team-sponsor-row">
                  <Table.Cell>{sponsorName}</Table.Cell>
                  {isTeamAdmin && (
                    <Table.Cell textAlign="right">
                      <ConfirmWithIntl
                        trigger={
                          <Button
                            aria-label="adminPage.sponsors.delete"
                            icon="trash"
                            color="red"
                            size="mini"
                            title={formatMessage({ id: 'general.delete' })}
                          />
                        }
                        onConfirm={() => this.removeSponsor(team, sponsorName)}
                        contentId="adminPage.sponsors.delete.confirm"
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
        {isTeamAdmin ? (
          <Card.Content extra>
            <AddTeamSponsorButton
              onNewSponsor={(newSponsor) => this.handleAddTeamSponsorSubmit(team, newSponsor)}
            />
          </Card.Content>
        ) : null}
      </Card>
    );
  };

  renderTeamContent = (team) => (
    <Grid stackable>
      <Grid.Row columns={3}>
        <Grid.Column>{this.renderIALicenses(team)}</Grid.Column>
        <Grid.Column>{this.renderTeamMembers(team)}</Grid.Column>
        <Grid.Column>{this.renderTeamSponsors(team)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );

  renderTeams = () => {
    const { teams } = this.state;
    if (teams.length === 0) {
      return (
        <Header size="huge">
          <FormattedMessage id="adminPage.NoTeam" />
        </Header>
      );
    }

    return (
      <Accordion
        fluid
        styled
        panels={teams.map((team) => {
          const now = new Date();
          const availableLicenseCount = team.ia_licenses
            .filter(({ start, end }) => start <= now && end >= now)
            .map((license) => license.total_count)
            .reduce((a, b) => a + b, 0);

          return {
            key: team.name,
            title: {
              icon: <></>,
              content: (
                <Header as="h3" className="admin-page__team-title">
                  <div>
                    <Icon name="dropdown" />
                    {team.name}
                  </div>

                  <div
                    className="admin-page__team-title__referent"
                    onClick={preventDefault}
                    data-testid="adminPage-team-referents"
                  >
                    <Popup
                      trigger={
                        <Label color="blue">
                          <Icon name="certificate" />
                          {team.currently_in_use} / {availableLicenseCount}
                        </Label>
                      }
                      content={<FormattedMessage id="adminPage.numberOfLicenses" />}
                    />
                    <FormattedMessage id="adminPage.ReferentsMail" />
                    &nbsp;{this.renderReferent(team)}
                  </div>
                </Header>
              ),
            },
            content: { content: this.renderTeamContent(team) },
          };
        })}
      />
    );
  };

  render() {
    const { teams } = this.state;

    return (
      <div className="admin-page">
        <Helmet title="Admin Page" />
        <div className=" ui admin-page__container">
          <h2>
            <FormattedMessage id="adminPage.title" />
          </h2>
          {this.renderAddTeamModal()}

          <div className="admin-page__numberOfTeams">
            <Label color="blue">
              <Icon name="users" />
              <FormattedMessage id="adminPage.numberOfTeams" />
              {teams.length}
            </Label>
          </div>

          {this.renderTeams()}
        </div>
      </div>
    );
  }
}

export default withContext(
  withContext(injectIntl(AdminPage), AdminStoreContext, 'adminStore'),
  UserStoreContext,
  'userStore'
);
