const PLATFORMS = {
  WINDOWS: 'WINDOWS',
  MAC: 'MAC',
  LINUX: 'LINUX',
  SUN: 'SUN',
  OPENBSD: 'OPENBSD',
  ANDROID: 'ANDROID',
  AIX: 'AIX',
};
Object.freeze(PLATFORMS);

const PLATFORMS_NAMES = {
  win32: PLATFORMS.WINDOWS,
  darwin: PLATFORMS.MAC,
  linux: PLATFORMS.LINUX,
  sunos: PLATFORMS.SUN,
  openbsd: PLATFORMS.OPENBSD,
  android: PLATFORMS.ANDROID,
  aix: PLATFORMS.AIX,
};
Object.freeze(PLATFORMS_NAMES);

const getCurrentPlatform = () => PLATFORMS_NAMES[process.platform];

export { PLATFORMS, getCurrentPlatform };
