import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';
import electron from 'app/native/node/electron';
import TeleradiologyAPIContext from 'app/providers/TeleradiologyAPIProvider/context';
import toastifyPromise from 'app/utils/toastifyPromise';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Dimmer, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import TeleradiologyPanelButtonAction from '../Panel/ButtonAction';

export type TeleradiologyReportDisplayButtonProps = {
  studyId: string;
};

const openReportInWindow = (reportBlob: Blob) => {
  const url = URL.createObjectURL(reportBlob);
  if (electron()) {
    const browserWindow = new (electron().remote.BrowserWindow)();
    browserWindow.loadURL(url);
    browserWindow.on('closed', () => URL.revokeObjectURL(url));
  } else {
    const browserWindow = window.open(url);
    // We revoke the url only when the window is unloaded to allow user to download the file
    browserWindow.onload = () => {
      browserWindow.onbeforeunload = () => URL.revokeObjectURL(url);
    };
  }
};

export default function TeleradiologyReportDisplayButton({
  studyId,
}: TeleradiologyReportDisplayButtonProps) {
  const teleradiologyApi = useContext(TeleradiologyAPIContext);

  const retrieveAndOpenReport = useCallback(() => {
    toastifyPromise('teleradiology.report.download', teleradiologyApi.getAnalysisReport(studyId))
      .then(openReportInWindow)
      .catch(() => {});
  }, [studyId]);

  return (
    <Button color="green" type="submit" onClick={retrieveAndOpenReport}>
      <Icon name="file pdf" /> <FormattedMessage id="teleradiology.analyze-request.report.upload" />
    </Button>
  );
}
