/* eslint-disable no-bitwise */
import './style.scss';

import React, { useState } from 'react';
import * as pt from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form, Input, Popup } from 'semantic-ui-react';

import FilesystemUtility, {
  checkFileAccesses,
  FILE_ACCESSES,
  isDirectory,
} from 'app/interfaces/FileSystemUtility';
import electron from 'app/native/node/electron';

/**
 * @param {{
 * intl,
 * fileSystemUtility:FilesystemUtility,
 * requiredDirectoryAccesses:FILE_ACCESSES.READ | FILE_ACCESSES.WRITE,
 * useDirectory,
 * setSelectedDirectory,
 * placeholderKey,
 * invalidDirectoryKey,
 * setDirectoryDescriptionKey,
 * clearDirectoryDescriptionKey,
 * }} props
 */
const DirectorySelector = ({
  intl,
  label,
  fileSystemUtility,
  requiredDirectoryAccesses,
  useDirectory,
  setSelectedDirectory,
  placeholderKey,
  invalidDirectoryKey,
  setDirectoryDescriptionKey,
  clearDirectoryDescriptionKey,
}) => {
  const selectedDirectory = useDirectory();
  const [isInvalidDirectory, setIsInvalidDirectory] = useState(false);
  const dialog = electron()?.remote.dialog;
  if (!dialog) return null;

  const openDirectorySelector = async () => {
    const { filePaths, canceled } = await dialog.showOpenDialog({ properties: ['openDirectory'] });
    if (canceled) return;
    const fileInfo = fileSystemUtility.getFileInfoSync(filePaths[0]);
    const isSelectedFileDirectory = isDirectory(fileInfo);
    const isMatchingAccessRights = checkFileAccesses(fileInfo, requiredDirectoryAccesses);
    if (!isSelectedFileDirectory || !isMatchingAccessRights) {
      setIsInvalidDirectory(true);
    } else {
      setIsInvalidDirectory(false);
      setSelectedDirectory(filePaths[0]);
    }
  };

  const onClearWatcher = () => {
    setSelectedDirectory(undefined);
  };

  let error;
  if (isInvalidDirectory) {
    error = intl.formatMessage({ id: invalidDirectoryKey });
  }

  return (
    <div className="directory-picker">
      <Form.Field
        className="directory-picker__input"
        error={error}
        readOnly
        placeholder={intl.formatMessage({ id: placeholderKey })}
        control={(props) => <Input label={label} {...props} />}
        value={selectedDirectory ?? ''}
      />
      <div className="directory-picker__button-group">
        <Popup
          content={<FormattedMessage id={setDirectoryDescriptionKey} />}
          trigger={
            <Button
              icon="folder open"
              error={error}
              onClick={openDirectorySelector}
              aria-label="pick-directory"
              data-testid="pick-directory"
            />
          }
        />
        <Popup
          content={<FormattedMessage id={clearDirectoryDescriptionKey} />}
          trigger={
            <Button
              color="red"
              icon="trash"
              aria-label="clear-directory"
              onClick={onClearWatcher}
            />
          }
        />
      </div>
    </div>
  );
};

DirectorySelector.propTypes = {
  intl: pt.shape({ formatMessage: pt.func.isRequired }).isRequired,
  label: pt.oneOfType([pt.string, pt.element]),
  fileSystemUtility: pt.shape(FilesystemUtility),
  requiredDirectoryAccesses: pt.number,
  useDirectory: pt.func.isRequired,
  setSelectedDirectory: pt.func.isRequired,
  placeholderKey: pt.string.isRequired,
  invalidDirectoryKey: pt.string.isRequired,
  setDirectoryDescriptionKey: pt.string.isRequired,
  clearDirectoryDescriptionKey: pt.string.isRequired,
};

DirectorySelector.defaultProps = {
  requiredDirectoryAccesses: FILE_ACCESSES.READ | FILE_ACCESSES.WRITE,
  label: undefined,
  fileSystemUtility: undefined,
};

export default injectIntl(DirectorySelector);
