import { DicomData } from 'app/interfaces/Dicom';
import { AcquisitionConstants } from 'app/interfaces/Image';
import {
  dicomDateToDateTimeFromTag,
  getDicomDataValue,
  getPatientInfoFromDicomData,
  getPixelSpacing,
  getXRayDetectorID,
} from 'app/utils/dicom/DicomData';
import _ from 'lodash';

export default (dicomData: DicomData, speciesMapping?: object) => {
  const acquisitionTime = dicomDateToDateTimeFromTag(
    dicomData,
    'AcquisitionDate',
    'AcquisitionTime'
  );
  const studyTime = dicomDateToDateTimeFromTag(dicomData, 'StudyDate', 'StudyTime');
  const patient = getPatientInfoFromDicomData(dicomData, speciesMapping);
  const detectorInfo = {
    manufacturer: getDicomDataValue(dicomData, 'Manufacturer'),
    serialNumber: getXRayDetectorID(dicomData),
    productModel: getDicomDataValue(dicomData, 'ManufacturerModelName'),
    pixelSpacing: getPixelSpacing(dicomData),
  };
  const acquisitionConstants = _.pickBy<AcquisitionConstants>({
    kV: parseInt(getDicomDataValue(dicomData, 'KVP'), 10),
    s: parseInt(getDicomDataValue(dicomData, 'ExposureTimeInuS'), 10) / 1000 / 1000,
    mA: parseInt(getDicomDataValue(dicomData, 'XRayTubeCurrentInuA'), 10) / 1000,
    mAs: parseInt(getDicomDataValue(dicomData, 'ExposureInuAs'), 10) / 1000,
  });

  return { acquisitionTime, patient, detectorInfo, studyTime, acquisitionConstants };
};
