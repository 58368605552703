import React from 'react';
import { toast } from 'react-toastify';
import FailedRequestText from 'app/containers/RequestText/FailedRequestText';
import PendingRequestText from 'app/containers/RequestText/PendingRequestText';
import SuccessRequestText from 'app/containers/RequestText/SuccessRequestText';

export default (requestType, promise) => {
  toast.promise(promise, {
    pending: { render: () => <PendingRequestText requestType={requestType} /> },
    success: { render: () => <SuccessRequestText requestType={requestType} /> },
    error: { render: ({ data }) => <FailedRequestText requestType={requestType} error={data} /> },
  });
  return promise;
};
