import React from 'react';
import PACSCommunication from 'app/adapters/PACSCommunication';
import PACSCommunicationCtx from 'app/injectors/PACSCommunication/context';
import getPicoxiaDicomModule from 'app/native/node-addons/picoxia-dicom';

export default function PACSCommunicationInjectorProvider({ children }) {
  if (!getPicoxiaDicomModule()) return <>{children}</>;

  return (
    <PACSCommunicationCtx.Provider value={PACSCommunication}>
      {children}
    </PACSCommunicationCtx.Provider>
  );
}
