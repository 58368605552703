import cornerstoneWADOImageLoader, { wadouri } from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import csc from 'cornerstone-core';
import configure from '../../modified_modules/cornerstone/configure';

configure(cornerstoneWADOImageLoader, cornerstoneWebImageLoader);

// This is a hack used because imagePlaneModule is not filled properly by wadouri.metaDataProvider
// With this method we disable it only for 1 module which cause our problem.
csc.metaData.removeProvider(wadouri.metaData.metaDataProvider);
csc.metaData.addProvider((type, imageId) => {
  if (type !== 'imagePlaneModule') return wadouri.metaData.metaDataProvider(type, imageId);
  return undefined;
});

export { cornerstoneWADOImageLoader, cornerstoneWebImageLoader };
