import React from 'react';
import PropTypes from 'prop-types';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';

import './style.scss';

const Button = ({ url, action, color, size, className, style, onClick, children }) => {
  let buttonClassName = 'button';
  if (color !== '') {
    buttonClassName = `${buttonClassName} button--${color}`;
  }
  if (size !== '') {
    buttonClassName = `${buttonClassName} button--${size}`;
  }

  if (action) {
    return (
      <button
        type="button"
        className={`${buttonClassName} ${className}`}
        style={style}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <IntlCrawlableLink className={`${buttonClassName} ${className}`} url={url} style={style}>
      {children}
    </IntlCrawlableLink>
  );
};

Button.defaultProps = {
  color: '',
  size: '',
  className: '',
  style: null,
  action: false,
  onClick: null,
  url: undefined,
};

Button.propTypes = {
  url: PropTypes.string,
  action: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;
