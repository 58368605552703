import _ from 'lodash';
import toDegree from 'app/CornerstoneTools/geometry/toDegree';
import { getLinesAngle } from 'app/CornerstoneTools/geometryHelper';
import { DisplayableImageData, NorbergOlssonAnnotation, Point, Size } from 'app/interfaces/Image';
import roundToDecimal from 'app/utils/roundToDecimal';
import { PelvisPredictions } from 'app/interfaces/Predictions';

const mapFloatCoordsToPixels = ({ x, y }: Point, { width, height }: Size) => ({
  x: width * x,
  y: height * y,
});

const computeRadiusCoord = (
  hipSide: PelvisPredictions['norberg_olsson'][keyof PelvisPredictions['norberg_olsson']]['keypoints'],
  image: DisplayableImageData
) => {
  const { x: cx, y: cy } = mapFloatCoordsToPixels(hipSide.femoral_head.center, image);
  const { x: rx, y: ry } = mapFloatCoordsToPixels(hipSide.femoral_head.radius, image);
  return { x: cx - (rx + ry) / 2, y: cy };
};

export const computeNorbergOlssonAngles = (
  leftFemoralHeadCenter: Point,
  leftAcetabularRim: Point,
  rightFemoralHeadCenter: Point,
  rightAcetabularRim: Point
) => {
  let angle1;
  let angle2;
  if (leftFemoralHeadCenter && leftAcetabularRim && rightFemoralHeadCenter) {
    angle1 = roundToDecimal(
      toDegree(
        getLinesAngle(
          [leftFemoralHeadCenter, leftAcetabularRim],
          [leftFemoralHeadCenter, rightFemoralHeadCenter]
        )
      ),
      1
    );
  }
  if (rightFemoralHeadCenter && rightAcetabularRim && leftFemoralHeadCenter) {
    angle2 = roundToDecimal(
      toDegree(
        getLinesAngle(
          [rightFemoralHeadCenter, rightAcetabularRim],
          [rightFemoralHeadCenter, leftFemoralHeadCenter]
        )
      ),
      1
    );
  }

  // First angle being clockwise means that is located due to how NO angle are built.
  // In image y axis is inverted from the usual X, Y axis, this means that clockwise is
  // denoted by positive angle
  const isFirstAngleClockwise = angle1 > 0;

  return {
    leftAngle: Math.abs(isFirstAngleClockwise ? angle1 : angle2),
    rightAngle: Math.abs(isFirstAngleClockwise ? angle2 : angle1),
    // In case hips are upside down, ai may fail to identify properly left and right hip.
    isAngleCorrect: isFirstAngleClockwise,
  };
};

export function convertPredictionsToNorbergOlssonAnnotation(
  norbergOlssonPrediction: PelvisPredictions['norberg_olsson'],
  image: DisplayableImageData
): NorbergOlssonAnnotation {
  const { left, right } = norbergOlssonPrediction ?? {};
  if (left?.keypoints === undefined || right?.keypoints === undefined) return undefined;
  const annotation: NorbergOlssonAnnotation = {
    handles: {
      leftFemoralHeadCenter: mapFloatCoordsToPixels(left.keypoints.femoral_head.center, image),
      leftFemoralHeadRadius: computeRadiusCoord(left.keypoints, image),
      leftAcetabularRim: mapFloatCoordsToPixels(left.keypoints.acetabulum, image),

      rightFemoralHeadCenter: mapFloatCoordsToPixels(right.keypoints.femoral_head.center, image),
      rightFemoralHeadRadius: computeRadiusCoord(right.keypoints, image),
      rightAcetabularRim: mapFloatCoordsToPixels(right.keypoints.acetabulum, image),
    },
    completed: true,
  };

  return _.merge(
    annotation,
    computeNorbergOlssonAngles(
      annotation.handles.leftFemoralHeadCenter,
      annotation.handles.leftAcetabularRim,
      annotation.handles.rightFemoralHeadCenter,
      annotation.handles.rightAcetabularRim
    )
  );
}
