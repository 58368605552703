import _ from 'lodash';
import React from 'react';

type Provider =
  typeof React.Component<React.PropsWithChildren<{}>>
  | React.FunctionComponent<React.PropsWithChildren<{}>>;
type ProviderWithProps = [Provider, { [key: string]: any }];

type ReactProviderProps = { providers: (ProviderWithProps | [Provider])[] };

export default class ReactSequentialProviders extends React.PureComponent<ReactProviderProps> {
  readonly providers: Provider[];
  render() {
    const { providers, children } = this.props;
    const providersFromInnerToOuter = [...providers].reverse();
    const nestedProviders = _.reduce(
      providersFromInnerToOuter,
      (acc, provider) => {
        const [Provider, props = {}] = provider;
        return <Provider {...props}>{acc}</Provider>;
      },
      children
    );
    return nestedProviders;
  }
}
