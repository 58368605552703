import cst from 'cornerstone-tools';

const zoomUtils = cst.import('util/zoomUtils');

/**
 * Extend 'ZoomMouseWheelTool' to have the min scale corresponding to the
 * full image.
 * The default 'ZoomMouseWheelTool' require a minScale configuration that
 * can lead to big image being zoomed too much.
 */
class ZoomMouseWheelTool extends cst.ZoomMouseWheelTool {
  mouseWheelCallback(evt) {
    const { element, viewport, spinY } = evt.detail;

    const { invert, ticksMultiplier = 1 / 4 } = this.configuration;
    const ticks = (invert ? spinY : -spinY) * ticksMultiplier;

    const updatedViewport = zoomUtils.changeViewportScale(viewport, ticks, {});

    cst.external.cornerstone.setViewport(element, updatedViewport);
  }
}

export default ZoomMouseWheelTool;
