import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';

import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

export class PaymentSuccessPage extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  componentDidMount = () => {
    setTimeout(() => {
      browserHistory.push('/viewer');
    }, 2500);
  };

  render() {
    return (
      <div style={{ marginTop: '10%', textAlign: 'center' }}>
        <h1>
          <FormattedMessage id="paymentSuccess.title" />
        </h1>
        <span style={{ fontSize: '2em' }}>
          <FormattedMessage id="paymentSuccess.success" />
        </span>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessPage);
