import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Paragraph to display mandatory mention for Form
 * @returns {JSX.Element}
 */
function FormMandatoryMention() {
  return (
    <p className="mandatory-mention">
      <FormattedMessage
        id="form.mandatory-mention"
        values={{
          span: (chunks: any) => <span className="asterisk">{chunks}</span>,
        }}
      />
    </p>
  );
}

export default FormMandatoryMention;
