import './styles.scss';

import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import {
  Button,
  Header,
  HeaderContent,
  HeaderSubheader,
  Icon,
  Message,
  Modal,
} from 'semantic-ui-react';
import toastifyPromise from 'app/utils/toastifyPromise';
import TeleradiologyAPIContext from 'app/providers/TeleradiologyAPIProvider/context';
import { API } from 'app/utils/apiCalls';
import { Patient } from 'app/interfaces/Patient';
import { ImageForTeleradiologySelection } from 'app/containers/Teleradiology/VetFlow/Modal/ImageForTeleradiologySelection';
import ImagesUploadSelector from 'app/containers/Teleradiology/ImagesUploadSelector';
import DicomWriterContext from 'app/providers/DicomWriterContext';
import DicomBuilderContext from 'app/providers/DicomBuilder/context';
import ImageWriterContext from 'app/providers/ImageWriterContext';
import formatImageForUpload from 'app/containers/Teleradiology/formatImageForUpload';
import { DicomTransferSyntax } from 'app/interfaces/Dicom';
import { SyncStatusEnum } from 'app/components/SyncStatus';
import {
  AnalyzeResultWithImageUploadPromises,
  ImageUploadPromisesByImageId,
  ImagesToUpload,
  TeleradiologyAnalysisStatus,
  TeleradiologyStudyIdentifier,
} from 'app/interfaces/TeleradiologyAPI';
import GenericError from 'app/errors/GenericError';
import logger from 'app/utils/debug/logger';
import ReactHookFormMessageError from 'app/containers/Teleradiology/MessageError';
import TeleradiologyReportDisplayButton from 'app/containers/Teleradiology/ReportDisplayButton';
import { ImageForUpload, uploadTeleradiologyImages } from 'app/utils/teleradiology/uploadImages';
import TeleradiologyVetFlowModalAnalyzeRequestDetail from './Detail';
import TeleradiologyVetFlowModalAnalyzeRequestForm, { FormDataType } from './Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { ageToString } from 'app/components/AgeFromDate';
import TeleradiologyPanelButtonAction from 'app/containers/Teleradiology/Panel/ButtonAction';
import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';

type TeleradiologyVetFlowModalAnalyzeRequestProps = {
  studyId?: string;
  studyDate?: number | Date;
  animal?: Patient;
  images: ImageForTeleradiologySelection[];
  aiReport?: string;
};
export type TeleradiologyVetFlowModalAnalyzeRequestImplProps =
  TeleradiologyVetFlowModalAnalyzeRequestProps & {
    teleradiologyStudy?: TeleradiologyStudyIdentifier;
    onTeleradiologyAnalysisRequested: () => void;
  };

type ImagesStatusState = { [key: string]: SyncStatusEnum };

const INCOMPLETE_STUDY_STATUS = [SyncStatusEnum.NOT_SYNC, SyncStatusEnum.ERROR_SYNC];

const wrapAIReportInsideDelimiters = (aiReport: string, intl: IntlShape): string => {
  if (!aiReport) return '';

  return `${intl.formatMessage({
    id: 'teleradiology.analyze-request.ai-report.delimiter.start',
  })}${aiReport}${intl.formatMessage({
    id: 'teleradiology.analyze-request.ai-report.delimiter.end',
  })}`;
};

/**
 * Modal to display VetFlow Form Study Create
 */
function TeleradiologyVetFlowModalAnalyzeRequestImpl({
  studyId,
  studyDate,
  animal,
  images,
  teleradiologyStudy,
  onTeleradiologyAnalysisRequested,
  aiReport = '',
}: TeleradiologyVetFlowModalAnalyzeRequestImplProps) {
  const TeleradiologyApi = useContext(TeleradiologyAPIContext);

  const [open, setOpen] = useState(false);
  const [confirmImageReUploadOpen, setConfirmImageReUploadOpen] = useState(false);
  const [isRequestSending, setIsRequestSending] = useState(false);
  const intl = useIntl();
  const modalContentRef = useRef<HTMLDivElement>();
  const [wrappedAIReport, setWrappedAIReport] = useState<string>('');

  useEffect(() => {
    setWrappedAIReport(wrapAIReportInsideDelimiters(aiReport, intl));
  }, [aiReport]);

  // Image handling part
  const [selectedImages, setSelectedImages] = useState<(string | number)[]>([]);
  const [imagesStatus, setImagesStatus] = useState<ImagesStatusState>({});
  const [isTeleradiologyStudyOngoing, setIsTeleradiologyStudyOngoing] = useState(false);
  const dicomWriter = useContext(DicomWriterContext);
  const dicomBuilder = useContext(DicomBuilderContext);
  const imageWriter = useContext(ImageWriterContext);

  const imagesWithStatus = images.map((image) => ({
    ...image,
    status: imagesStatus[image.backendId],
  }));

  const setImageStatus = (backendId: string, status: SyncStatusEnum) => {
    setImagesStatus((currentStatus) => ({
      ...currentStatus,
      [backendId]: status,
    }));
  };

  const updateImagesStatusFromTeleradiologyStudy = () => {
    if (!teleradiologyStudy?.images) return;

    teleradiologyStudy.images.forEach((image) => {
      setImagesStatus((currentImagesStatus) => {
        const backendId = image.image_id;
        const currentImageStatus = currentImagesStatus?.[backendId];
        // We avoid overriding ONGOING status to not break animation
        if (
          currentImageStatus === SyncStatusEnum.ONGOING ||
          currentImageStatus === SyncStatusEnum.ERROR_SYNC
        ) {
          return currentImagesStatus;
        }

        const newStatusFromTeleradiologyStudy =
          image.upload_status === API.Teleradiology.ImageUploadStatus.PENDING
            ? SyncStatusEnum.NOT_SYNC
            : SyncStatusEnum.SYNC;

        return {
          ...currentImagesStatus,
          [image.image_id]: newStatusFromTeleradiologyStudy,
        };
      });
    });
  };

  const updateStudyStatusFromTeleradiologyStudy = () => {
    if (!teleradiologyStudy?.analysis_status) return;
    const isOngoing = teleradiologyStudy.analysis_status !== TeleradiologyAnalysisStatus.DONE;
    setIsTeleradiologyStudyOngoing(isOngoing);
  };

  useEffect(updateImagesStatusFromTeleradiologyStudy, [teleradiologyStudy?.images]);
  useEffect(updateStudyStatusFromTeleradiologyStudy, [teleradiologyStudy?.analysis_status]);

  const onImagesSelect = (imageIds: number[]) => {
    setSelectedImages(imageIds);
  };

  const scrollToTop = useCallback(() => {
    modalContentRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const wrapUploadRequest = async (uploadsRequest: () => Promise<ImageUploadPromisesByImageId>) => {
    setIsRequestSending(true);
    try {
      const uploadsPromises = await uploadsRequest();

      await Promise.allSettled(
        _.map(uploadsPromises, (promise, imageId) => {
          setImageStatus(imageId, SyncStatusEnum.ONGOING);
          return promise
            .then(() => setImageStatus(imageId, SyncStatusEnum.SYNC))
            .catch(() => setImageStatus(imageId, SyncStatusEnum.ERROR_SYNC));
        })
      );
    } catch {}
    setIsRequestSending(false);
  };

  const uploadNewImages = (imagesToUpload: ImageForUpload[]) => {
    wrapUploadRequest(() =>
      uploadTeleradiologyImages(
        TeleradiologyApi,
        { dicomBuilder, dicomWriter, imageWriter },
        studyId,
        imagesToUpload
      )
    );
  };

  const triggerImageUpload = () => {
    const imagesToUpload = imagesWithStatus.filter((image) => selectedImages.includes(image.id));
    const isImageAlreadySentPresent = _.some(
      imagesToUpload,
      (image) => image.status === SyncStatusEnum.SYNC
    );

    if (!isImageAlreadySentPresent) {
      uploadNewImages(imagesToUpload);
    } else {
      setConfirmImageReUploadOpen(true);
    }
  };

  const reuploadCancel = () => {
    setConfirmImageReUploadOpen(false);
  };

  const reuploadSendAll = () => {
    const imagesToUpload = imagesWithStatus.filter((image) => selectedImages.includes(image.id));
    uploadNewImages(imagesToUpload);
    setConfirmImageReUploadOpen(false);
  };

  const reuploadSendNew = () => {
    const imagesToUpload = imagesWithStatus.filter(
      (image) => selectedImages.includes(image.id) && image.status !== SyncStatusEnum.SYNC
    );
    uploadNewImages(imagesToUpload);
    setConfirmImageReUploadOpen(false);
  };

  const isStudyDone = teleradiologyStudy?.analysis_status === API.Teleradiology.AnalysisStatus.DONE;
  const isStudyIncomplete = _.some(imagesStatus, (status) =>
    INCOMPLETE_STUDY_STATUS.includes(status)
  );

  // End of image handling part

  const studyCreationDate = studyDate
    ? format(studyDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  const studyAnimalBirthDate = animal?.birth_date ? format(animal.birth_date, 'yyyy-MM-dd') : '';

  const studyAnimalAgeString = animal?.birth_date ? ageToString(animal.birth_date, intl) : '';

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormDataType>({
    values: {
      examDate: studyCreationDate,
      reason: '',
      clinicalSigns: '',
      veterinarianInterpretation: wrappedAIReport,
      patientName: animal?.name ?? '',
      patientSpecie: animal?.specie ?? '',
      patientRace: animal?.race ?? '',
      patientSex: animal?.sex ?? '',
      patientAge: studyAnimalAgeString ?? '',
      patientBirthDate: studyAnimalBirthDate,
      patientWeight: '',
      ownerLastName: animal?.owner_name ?? '',
      ownerAddress: '',
      ownerPostCode: '',
      ownerCity: '',
      ownerPhoneNumber: '',
      acceptGeneralTermsOfSales: false,
    },
    defaultValues: {
      isUrgent: false,
      examiningEmail: '',
      referentClinic: '',
      referentVeterinarian: '',
      referentEmail: '',
      patientSpecieCustom: '',
      ownerFirstName: '',
      ownerCountry: '',
      ownerCountryCode: '',
      ownerEmail: '',
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
    },
  });

  const requestAnalyze = async (formData: FormDataType) => {
    const studyDetails = {
      companyName: API.Teleradiology.Provider.VEDIM,
      examType: API.Teleradiology.VetflowExamType.Radiologie,
      examDate: new Date(formData.examDate),
      examiningVeterinarian: formData.examiningVeterinarian,
      examiningEmail: formData.examiningEmail,
      referentClinic: formData.referentClinic,
      referentVeterinarian: formData.referentVeterinarian,
      referentEmail: formData.referentEmail,
      reason: formData.reason,
      clinicalSigns: formData.clinicalSigns,
      veterinarianInterpretation: formData.veterinarianInterpretation,
      isUrgent: !!formData.isUrgent,
      patient: {
        name: formData.patientName,
        specie: formData.patientSpecie,
        specieCustom: formData.patientSpecieCustom,
        race: formData.patientRace,
        age: formData.patientAge,
        birthDate: new Date(formData.patientBirthDate),
        sex: formData.patientSex,
        weight: formData.patientWeight,
        owner: {
          lastName: formData.ownerLastName,
          firstName: formData.ownerFirstName,
          address: {
            address: formData.ownerAddress,
            city: formData.ownerCity,
            postCode: formData.ownerPostCode,
            country: formData.ownerCountryCode,
            phoneNumber: formData.ownerPhoneNumber,
            email: formData.ownerEmail,
          },
        },
      },
    };

    let imageFileContents: ImagesToUpload;
    try {
      imageFileContents = _.fromPairs(
        await Promise.all(
          images
            .filter((image) => selectedImages.includes(image.id))
            .map(
              async ({
                image,
                transformation,
                dicomData,
                backendId,
              }: ImageForTeleradiologySelection) => [
                backendId,
                {
                  imageContent: await formatImageForUpload(
                    { dicomBuilder, dicomWriter, imageWriter },
                    image,
                    transformation,
                    dicomData,
                    undefined
                  ),
                },
              ]
            )
        )
      );
    } catch (error) {
      console.warn('Failed to prepare images for upload', error);
      return;
    }

    scrollToTop();

    return TeleradiologyApi.requestAnalyze(studyId, studyDetails, imageFileContents).then(
      (result: AnalyzeResultWithImageUploadPromises) => {
        try {
          onTeleradiologyAnalysisRequested();
        } catch {}
        setIsTeleradiologyStudyOngoing(true);

        Object.keys(imageFileContents).forEach((backendId) =>
          setImageStatus(backendId, SyncStatusEnum.ONGOING)
        );
        const imageUploadPromises = result.imageUploadPromises;

        return Promise.all(
          _.map(imageUploadPromises, (imageUploadPromise, imageId) =>
            imageUploadPromise
              .then(() => setImageStatus(imageId, SyncStatusEnum.SYNC))
              .catch((error: GenericError) => {
                logger.warn('Failed to send image for teleradiology', error);
                setImageStatus(imageId, SyncStatusEnum.ERROR_SYNC);
              })
          )
        );
      }
    );
  };

  const onSubmit = (formData: FormDataType) => {
    setIsRequestSending(true);

    toastifyPromise('teleradiology.vetflow.analyze-request', requestAnalyze(formData))
      .then(() => {
        setIsRequestSending(false);
        setOpen(false);
      })
      .catch((error: Error) => {
        setIsRequestSending(false);
        console.error('server-api-error', error);
      });
  };

  const sendOrGetReportButton = (() => {
    if (isStudyDone) return <TeleradiologyReportDisplayButton studyId={studyId} />;

    if (isStudyIncomplete) {
      return (
        <Button color="green" type="submit" onClick={triggerImageUpload}>
          <Icon name="sync" loading={isRequestSending} />{' '}
          <FormattedMessage id="teleradiology.analyze-request.upload-retry.button" />
        </Button>
      );
    }
    if (isTeleradiologyStudyOngoing) {
      return (
        <Button color="green" type="submit" onClick={triggerImageUpload}>
          {isRequestSending ? <Icon name="sync" loading /> : <Icon name="upload" />}{' '}
          <FormattedMessage id="teleradiology.analyze-request.upload-new-send.button" />
        </Button>
      );
    }
    return (
      <Button
        color="green"
        type="submit"
        onClick={handleSubmit(onSubmit, scrollToTop)}
        disabled={isRequestSending}
        loading={isRequestSending}
      >
        {isRequestSending ? <Icon name="sync" loading /> : <Icon name="check" />}{' '}
        <FormattedMessage id="general.create" />
      </Button>
    );
  })();

  const titleFormattedMessageId = (() => {
    let status = 'create';
    if (isStudyDone) status = 'done';
    else if (isStudyIncomplete) status = 'incomplete';
    else if (isTeleradiologyStudyOngoing) status = 'pending';

    return `teleradiology.analyze-request.study-${status}.title`;
  })();

  const actionButton = (() => {
    if (isStudyDone) {
      return (
        <TeleradiologyPanelButtonAction
          radiologistComment={teleradiologyStudy?.radiologist_comment}
          action="studyDone"
          onClick={() => setOpen(true)}
        />
      );
    }
    if (isStudyIncomplete) {
      return (
        <TeleradiologyPanelButtonAction action="studyIncomplete" onClick={() => setOpen(true)} />
      );
    }
    if (isTeleradiologyStudyOngoing) {
      return <TeleradiologyPanelButtonAction action="studyPending" onClick={() => setOpen(true)} />;
    }
    return <TeleradiologyPanelButtonAction action="studyCreate" onClick={() => setOpen(true)} />;
  })();

  const hasNonDicomImages = _.some(
    images.filter((image) => selectedImages.includes(image.id)),
    { dicomData: undefined }
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={actionButton}
      className="teleradiology-analyse-request-modal dark-mode"
    >
      <Modal.Header>
        <Header as="h3">
          <TeleradiologyIcon size="big" inverted />
          <HeaderContent>
            <FormattedMessage id={titleFormattedMessageId} />
            <HeaderSubheader>
              <FormattedMessage id="teleradiology.title" />
            </HeaderSubheader>
          </HeaderContent>
        </Header>
      </Modal.Header>

      <div className="scrolling content" ref={modalContentRef}>
        <Modal.Description>
          {!_.isEmpty(errors) && <ReactHookFormMessageError errors={errors} />}

          <fieldset id="analyze-images">
            <legend>
              <FormattedMessage id="teleradiology.analyze-request.images.label" />
            </legend>
            <ImagesUploadSelector
              images={imagesWithStatus}
              onSelect={onImagesSelect}
              miniatureSize="10rem"
            />
          </fieldset>
          {teleradiologyStudy && (
            <TeleradiologyVetFlowModalAnalyzeRequestDetail
              teleradiologyStudy={teleradiologyStudy}
            />
          )}
          {!teleradiologyStudy && (
            <TeleradiologyVetFlowModalAnalyzeRequestForm
              animal={animal}
              scrollToTop={scrollToTop}
              onSubmit={onSubmit}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              setValue={setValue}
            />
          )}
        </Modal.Description>
        <Modal open={confirmImageReUploadOpen}>
          <Modal.Header>
            <FormattedMessage id="teleradiology.analyze-request.reupload-images.title" />
          </Modal.Header>
          <Modal.Actions>
            <Button onClick={reuploadCancel}>
              <FormattedMessage id="general.cancel" />
            </Button>
            <Button secondary onClick={reuploadSendAll}>
              <FormattedMessage id="teleradiology.analyze-request.reupload-images.button.send-all" />
            </Button>
            <Button primary onClick={reuploadSendNew}>
              <FormattedMessage id="teleradiology.analyze-request.reupload-images.button.send-new" />
            </Button>
          </Modal.Actions>
        </Modal>
      </div>

      <Modal.Actions>
        {hasNonDicomImages && (
          <Message
            className="teleradiology-analyse-request-modal__warning-message"
            negative
            icon="warning sign"
            content={<FormattedMessage id="teleradiology.analyze-request.warning.not-dicom" />}
          />
        )}
        <Button onClick={() => setOpen(false)}>
          <Icon name="remove" /> <FormattedMessage id="general.cancel" />
        </Button>
        {sendOrGetReportButton}
      </Modal.Actions>
    </Modal>
  );
}

function TeleradiologyVetFlowModalAnalyzeRequest(
  props: TeleradiologyVetFlowModalAnalyzeRequestProps
) {
  const TeleradiologyApi = useContext(TeleradiologyAPIContext);
  const [teleradiologyStudy, setTeleradiologyStudy] =
    useState<TeleradiologyStudyIdentifier>(undefined);
  const { studyId } = props;

  const loadStudyStatus = () => {
    if (!studyId) return;
    TeleradiologyApi.getStatus(studyId)
      .then((study: API.Teleradiology.StudyIdentifier) => setTeleradiologyStudy(study))
      .catch(() => {
        // No teleradiology study for given studyId
      });
  };

  useEffect(loadStudyStatus, [studyId]);

  // if (teleradiologyStudy?.analysis_status === API.Teleradiology.AnalysisStatus.DONE) {
  //   return <TeleradiologyReportDisplayButton studyId={studyId} />;
  // }

  return (
    <TeleradiologyVetFlowModalAnalyzeRequestImpl
      {...props}
      teleradiologyStudy={teleradiologyStudy}
      onTeleradiologyAnalysisRequested={loadStudyStatus}
    />
  );
}

export default TeleradiologyVetFlowModalAnalyzeRequest;
