import { AnyAnnotation, BaseAnnotation } from 'app/interfaces/Image';

export default class MeasurementEvent extends Event {
  readonly imageId: string;
  readonly toolName: string;
  readonly measurementData: AnyAnnotation;

  constructor(type: string, imageId: string, toolName: string, measurementData: AnyAnnotation) {
    super(type);
    this.imageId = imageId;
    this.toolName = toolName;
    this.measurementData = measurementData;
  }
}
