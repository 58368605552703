import { SET_LAST_RELEASE_URL, SET_PATCH_NOTES } from 'app/redux/versionUpdate/actions';
import { fromJS } from 'immutable';

const versionUpdateInitialState = fromJS({});

const versionUpdateReducer = (state = versionUpdateInitialState, { type, payload }) => {
  switch (type) {
    case SET_LAST_RELEASE_URL:
      return state.set('releaseUrl', payload.releaseUrl);
    case SET_PATCH_NOTES:
      return state.set('patchNotes', payload.patchNotes);
    default:
      return state;
  }
};

export const selectVersionUpdate = (state) => state.get('versionUpdate');

export default versionUpdateReducer;
