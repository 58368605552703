import TimeoutPromise from 'app/utils/TimeoutPromise';

/**
 *
 * @template InputPromise
 * @param {InputPromise} promise
 * @param {number} minimumDuration
 * @returns {InputPromise}
 */
const MinDurationPromise = async (promise, minimumDuration) => {
  const [promiseResult] = await Promise.allSettled([promise, TimeoutPromise(minimumDuration)]);
  if (promiseResult.status === 'fulfilled') {
    return Promise.resolve(promiseResult.value);
  }
  return Promise.reject(promiseResult.reason);
};

export default MinDurationPromise;
