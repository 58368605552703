export default {
  FIELDS_MISSING: 'fields missing',
  PATIENT_CREATION_CONFLICT: 'patient_creation_conflict',
  INVALID_FIELD: 'invalid_field',
  FIELD_NAME_NOT_ALLOWED: 'FIELD_NAME_NOT_ALLOWED',
  INVALID_MAIL: 'invalid mail',
  MAIL_NOT_CONFIRMED: 'mail not confirmed',
  USER_ALREADY_EXISTS: 'user already exists',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  DEMO_ALREADY_USED: 'DEMO_ALREADY_USED',
  ENTITY_DOES_NOT_EXISTS: 'ENTITY_DOES_NOT_EXISTS',
  USER_NOT_FOUND: 'user not found',
  CREATE_USER_FIRST: 'CREATE_USER_FIRST',
  WEAK_PASSWORD: 'weak password',
  WRONG_CREDENTIALS: 'wrong credentials',
  CREDITS_NEEDED: 'no_credit_left',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNKNOWN_SPONSOR: 'unknown sponsor',
  LANGUAGE_NOT_FOUND: 'language does not exist',
  NOT_MANAGER: 'not user manager role',
  NO_LICENSE_PACK: 'NO_LICENSE_PACK',
  INVALID_IP: 'INVALID_IP',
  INVALID_PARTNER_IP: 'INVALID_PARTNER_IP',
  PACK_NOT_FOUND: 'PACK_NOT_FOUND',
  WEEK_USAGE_EXCEEDED: 'week_usage_exceeded',
  DAY_USAGE_EXCEEDED: 'day_usage_exceeded',
  MISSING_EMAIL_ADDRESS: 'MISSING_EMAIL_ADDRESS',
  INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
  MISSING_NAME: 'MISSING_NAME',
  MISSING_PHONE_NUMBER: 'MISSING_PHONE_NUMBER',
  MISSING_POSTAL_ADDRESS: 'MISSING_POSTAL_ADDRESS',
  MISSING_POST_CODE: 'MISSING_POST_CODE',
  MISSING_CITY: 'MISSING_CITY',
  MISSING_COUNTRY_CODE: 'MISSING_COUNTRY_CODE',
  MISSING_COUNTRY: 'MISSING_COUNTRY',
  FAILED_TO_SEND_MAIL: 'FAILED_TO_SEND_MAIL',
  NOT_FOUND: 'NOT_FOUND',
  DATA_CONFLICT: 'DATA_CONFLICT',
  EXTERNAL_TOKEN_SESSION_EXPIRED: 'EXTERNAL_TOKEN_SESSION_EXPIRED',
  INVALID_SESSION_ID: 'INVALID_SESSION_ID',
  DISCONNECTION_NEEDED: 'DISCONNECTION_NEEDED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  INVALID_PERMISSIONS: 'INVALID_PERMISSIONS',
  ACQUISITION_LICENSE_ALREADY_IN_USE: 'ACQUISITION_LICENSE_ALREADY_IN_USE',
  AUTH_LEVEL_NOT_MATCHED: 'authorization level not matched',
  ALL_DEVICE_LOGOUT: 'user logged out from all devices after token creation',
  NOT_A_VALID_STRING: 'Not a valid string: should not contain {, }, $, =>, (, )',
  IMAGE_COULD_NOT_BE_READ: 'Image could not be read',
  SERVER_ERROR: 'SERVER_ERROR',
  TELERADIOLOGY_CONNECTION_FAILED: 'TELERAD_CONNECTION_FAILED',
  TELERADIOLOGY_NONE_RESPONSE: 'TELERAD_NONE_RESPONSE',
  TELERADIOLOGY_REQUEST_FAILED: 'TELERAD_REQUEST_FAILED',
  TELERADIOLOGY_USER_ALREADY_USED: 'TELERAD_USER_ALREADY_USED',
  TELERADIOLOGY_USER_NOT_FOUND: 'TELERAD_USER_NOT_FOUND',
  TELERADIOLOGY_STUDY_NOT_FOUND: 'TELERAD_STUDY_NOT_FOUND',
  TELERADIOLOGY_STUDY_ALREADY_CREATED_FOR_PATIENT: 'TELERAD_STUDY_ALREADY_CREATED_FOR_PATIENT',
  TELERADIOLOGY_FILE_ALREADY_UPLOADED: 'TELERAD_FILE_ALREADY_UPLOADED',
  TELERADIOLOGY_SERVER_ERROR: 'TELERAD_SERVER_ERROR',
  TELERADIOLOGY_STUDY_NOT_LONGER_MODIFIABLE: 'TELERAD_STUDY_NOT_LONGER_MODIFIABLE',
};
