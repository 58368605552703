export default class GenericError extends Error {
  cause: string;
  detail?: string;

  constructor(cause: string, detail?: string, error?: Error) {
    let message = `${cause}`;
    if (detail) message += `: ${detail}`;
    super(message);

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, GenericError.prototype);

    // Retrieve Error properties
    this.stack = error?.stack;

    this.cause = cause;
    this.detail = detail;
  }
}
