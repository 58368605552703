/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
import './style.css';

import produce from 'immer';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import React from 'react';
import { Confirm } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import ApiCalls from '../../utils/apiCalls';

const path = window.nodeModules?.path;
// const fs = require('fs');
const fs = window.nodeModules?.fs;
const remote = window.nodeModules?.electron.remote;

const { licensing, xray } = window.nodeModules ?? {};

const DEFAULT_LICENSE_MANAGER_PROVIDER_CONTEXT = {
  isVerified: false,
};

const LicenseManagerContext = React.createContext(DEFAULT_LICENSE_MANAGER_PROVIDER_CONTEXT);

const LicenseManagerContextShape = pt.shape({
  isVerified: pt.bool,
});

const VERIFICATION_REFRESH_RESULTS = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  VERIFIED: 'VERIFIED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  UNKNOW_ERROR: 'UNKNOW_ERROR',
};
Object.freeze(VERIFICATION_REFRESH_RESULTS);

class LicenseManagerProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVerified: false,
      verificationErrorOpen: false,
    };
    this.initIdentifiersFromFilesystem();
    Object.freeze(this.state);
  }

  componentDidMount = () => {
    if (licensing && xray) {
      this.startAutoRefreshIdentifiers();
    }
  };

  initIdentifiersFromFilesystem = () => {
    if (!licensing || !xray) return;
    try {
      licensing.initCOM();
      window.onbeforeunload = () => licensing.closeCOM();
    } catch (error) {
      console.log('initCOM error:');
      console.log(error);
      console.log('Security issues can arise on reload, not a problem');
    }
    const identifiersPath = path.join(remote.app.getPath('userData'), 'license_identifiers');
    const signaturePath = path.join(remote.app.getPath('userData'), 'signature');
    let identifiers;
    let signature;
    let isDeviceVerified = false;
    try {
      identifiers = fs.readFileSync(identifiersPath, 'utf8').replace('\r\n', '\n');
      signature = new Uint8Array(fs.readFileSync(signaturePath));
      if (identifiers && signature) {
        isDeviceVerified = licensing.checkAndStoreDeviceIdentifiers(identifiers, signature);
        this.state.isVerified = isDeviceVerified;
      }
    } catch (e) {
      console.log('initIdentifiersFromFilesystem error', e);
    }
  };

  updateVerifiedState = () =>
    this.setState(
      produce((draftState) => {
        let isVerified = false;
        try {
          // We check using stored value instead of an argument value because our licensed
          // modules use stored value.
          isVerified = licensing.checkStoredDeviceIdentifiers();
        } catch (e) {
          console.log('updateVerifiedState', e);
        }
        if (draftState.isVerified !== isVerified) draftState.isVerified = isVerified;
      })
    );

  switchMessageErrorState = () =>
    this.setState(
      produce((draftState) => {
        draftState.verificationErrorOpen = !draftState.verificationErrorOpen;
      })
    );

  refreshIdentifiers = () => {
    const identifiersString = licensing.generateIdentifiersHashWithVersion();
    return ApiCalls.refreshLicenseIdentifiers(identifiersString)
      .then(
        // eslint-disable-next-line camelcase
        ({ data: { identifiers_with_expiration_time, signature } }) => {
          // console.log('identifiers_with_expiration_time');
          // console.log(identifiers_with_expiration_time);
          // console.log('signature');
          // console.log(new Uint8Array(signature));
          // return VERIFICATION_REFRESH_RESULTS.UNKNOW_ERROR;
          const signatureBuffer = Uint8Array.from(signature);
          try {
            const isDeviceVerified = licensing.checkAndStoreDeviceIdentifiers(
              identifiers_with_expiration_time,
              signatureBuffer
            );
            if (isDeviceVerified) {
              this.saveIdentifiersToFileSystem(identifiers_with_expiration_time, signatureBuffer);
              return VERIFICATION_REFRESH_RESULTS.VERIFIED;
            }
            return VERIFICATION_REFRESH_RESULTS.VERIFICATION_FAILED;
          } catch (e) {
            console.log('refreshIdentifiers error:');
            console.log(e);
            if (e.license_error) {
              return VERIFICATION_REFRESH_RESULTS.VERIFICATION_FAILED;
            }
            return VERIFICATION_REFRESH_RESULTS.UNKNOW_ERROR;
          }
        },
        (error) => {
          if (error.response?.status === 403 || error.response?.status === 401) {
            this.switchMessageErrorState();
            return VERIFICATION_REFRESH_RESULTS.UNAUTHORIZED;
          }
          console.log(error);
          return VERIFICATION_REFRESH_RESULTS.UNKNOW_ERROR;
        }
      )
      .finally(() => this.updateVerifiedState());
  };

  startAutoRefreshIdentifiers = () =>
    this.refreshIdentifiers().then((verificationResult) => {
      let retryTimeout = 30 * 1000;
      if (verificationResult === VERIFICATION_REFRESH_RESULTS.VERIFIED) {
        retryTimeout = 60 * 60 * 1000;
      }
      if (verificationResult === VERIFICATION_REFRESH_RESULTS.VERIFICATION_FAILED) {
        retryTimeout = 30 * 1000;
      }
      if (verificationResult === VERIFICATION_REFRESH_RESULTS.UNAUTHORIZED) {
        retryTimeout = 60 * 60 * 1000;
      }
      if (verificationResult === VERIFICATION_REFRESH_RESULTS.UNKNOW_ERROR) {
        retryTimeout = 30 * 1000;
      }

      setTimeout(this.startAutoRefreshIdentifiers, retryTimeout);
    });

  saveIdentifiersToFileSystem = (identifiers, signature) => {
    const identifiersPath = path.join(remote.app.getPath('userData'), 'license_identifiers');
    const signaturePath = path.join(remote.app.getPath('userData'), 'signature');
    fs.writeFileSync(identifiersPath, identifiers);
    fs.writeFileSync(signaturePath, signature);
  };

  render = () => {
    // eslint-disable-next-line react/prop-types
    const { children, intl } = this.props;
    const { verificationErrorOpen } = this.state;
    return (
      <LicenseManagerContext.Provider value={this.state}>
        {children}
        <Confirm
          className="license-error-confirm"
          content={intl.formatMessage({ id: 'license.content' })}
          header={intl.formatMessage({ id: 'license.header' })}
          open={verificationErrorOpen}
          cancelButton={null}
          onCancel={this.switchMessageErrorState}
          onConfirm={this.switchMessageErrorState}
        />
      </LicenseManagerContext.Provider>
    );
  };
}

const LicenseManagerProviderWithIntl = injectIntl(LicenseManagerProvider);

export {
  LicenseManagerContext,
  LicenseManagerContextShape,
  LicenseManagerProviderWithIntl as LicenseManagerProvider,
};
