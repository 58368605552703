/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback } from 'react';

import Viewer, { ViewerProps } from 'app/components/Viewer';
import DataLossGuard from 'app/components/DataLossGuard';
import { ImagePath } from 'app/interfaces/ImageLoader';

export type ViewerWithAutoImportedStudiesProps = Omit<
  ViewerProps,
  'importedImages' | 'onImportedImagesFromAnotherStudy'
>;

function ViewerWithAutoImportedStudies(props: ViewerWithAutoImportedStudiesProps) {
  const [importedImages, setImportedImages] = useState<ImagePath[]>();

  const onImportedImagesFromAnotherStudy = useCallback((pendingImportedImageFiles: ImagePath[]) => {
    setImportedImages(pendingImportedImageFiles);
  }, []);

  return (
    <DataLossGuard>
      <Viewer
        {...props}
        importedImages={importedImages}
        onImportedImagesFromAnotherStudy={onImportedImagesFromAnotherStudy}
      />
    </DataLossGuard>
  );
}

export default ViewerWithAutoImportedStudies;
