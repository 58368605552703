import React from 'react';
import * as pt from 'prop-types';
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import { injectIntl } from 'react-intl';
import { cloneDeep } from 'lodash';

import { forceImageUpdate } from '../../CornerstoneTools/forceImageUpdate';
import { focusHandles } from '../../CornerstoneTools/CropTool';

class FixedAngleRotateButton_ extends React.PureComponent {
  doRotation = () => {
    const { focusedElement, angle } = this.props;
    if (!focusedElement) return;
    try {
      const { viewport } = csc.getEnabledElement(focusedElement);
      viewport.rotation += angle;

      const cropToolData = cst.getToolState(focusedElement, 'Crop');
      if (cropToolData && cropToolData.data.length > 0) {
        focusHandles(focusedElement, cropToolData.data[0].handles);
      }
      forceImageUpdate(focusedElement);
    } catch (error) {
      console.log('Error in FixedAngleRotateButton', error);
    }
  };

  render = () => {
    const { content, tooltipId, intl } = this.props;
    return (
      <button
        type="button"
        className="picoxia tool-button"
        onClick={this.doRotation}
        title={intl.formatMessage({ id: tooltipId })}
      >
        {content}
      </button>
    );
  };
}

FixedAngleRotateButton_.propTypes = {
  content: pt.oneOfType([pt.string, pt.shape()]).isRequired,
  angle: pt.number.isRequired,
  intl: pt.shape().isRequired,
  tooltipId: pt.string.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
};

FixedAngleRotateButton_.defaultProps = {
  focusedElement: null,
};

const FixedAngleRotateButton = injectIntl(FixedAngleRotateButton_);

const makeFixedAngleRotateButton = (angle, content, tooltipId) => (props) =>
  (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FixedAngleRotateButton content={content} angle={angle} tooltipId={tooltipId} {...props} />
  );

export { FixedAngleRotateButton, makeFixedAngleRotateButton };
