import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, FormField, Icon, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import countries from 'app/constants/countries';
import GoogleReCaptcha from 'app/components/Form/GoogleReCaptcha';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import FormattedServerError from 'app/components/FormattedServerError';
import RequiredAsterisk from 'app/components/RequiredAsterisk';
import ApiCalls from 'app/utils/apiCalls';
import validator from 'validator';
import './styles.scss';

function SendDemoRequestForm({ requestType }) {
  const intl = useIntl();

  const [authorClientTypeValue, setAuthorClientTypeValue] = useState('Vétérinaire');
  const [authorNameValue, setAuthorNameValue] = useState('');
  const [authorEmailValue, setAuthorEmailValue] = useState('');
  const [authorPhoneValue, setAuthorPhoneValue] = useState('');
  const [authorAddressValue, setAuthorAddressValue] = useState('');
  const [authorPostCodeValue, setAuthorPostCodeValue] = useState('');
  const [authorCityValue, setAuthorCityValue] = useState('');
  const [authorCountryCodeValue, setAuthorCountryCodeValue] = useState();
  const [authorCountryValue, setAuthorCountryValue] = useState();
  const [productsValue, setProductsValue] = useState([]);
  const [whatTimeValue, setWhatTimeValue] = useState('');
  const [freeTextValue, setFreeTextValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [sentSuccessfully, setSentSuccessfully] = useState(false);

  const [serverError, setServerError] = useState(null);
  const [missingNameError, setMissingNameError] = useState(false);
  const [missingEmailError, setMissingEmailError] = useState(false);
  const [missingPhoneError, setMissingPhoneError] = useState(false);
  const [missingAddressError, setMissingAddressError] = useState(false);
  const [missingPostCodeError, setMissingPostCodeError] = useState(false);
  const [missingCityError, setMissingCityError] = useState(false);
  const [missingCountryCodeError, setMissingCountryCodeError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [failedToSendError, setFailedToSendError] = useState(false);
  const [reachedRequestsLimitError, setReachedRequestsLimitError] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const captchaRef = useRef();

  const setServerErrorState = (error) => {
    setServerError(error);
    if (error?.response?.data?.cause === 'MISSING_EMAIL_ADDRESS') {
      setMissingEmailError(true);
    } else if (error?.response?.data?.cause === 'INVALID_EMAIL_ADDRESS') {
      setInvalidEmailError(true);
    } else if (error?.response?.data?.cause === 'MISSING_NAME') {
      setMissingNameError(true);
    } else if (error?.response?.data?.cause === 'MISSING_PHONE_NUMBER') {
      setMissingPhoneError(true);
    } else if (error?.response?.data?.cause === 'MISSING_POSTAL_ADDRESS') {
      setMissingAddressError(true);
    } else if (error?.response?.data?.cause === 'MISSING_POSTAL_CODE') {
      setMissingPostCodeError(true);
    } else if (error?.response?.data?.cause === 'MISSING_CITY') {
      setMissingCityError(true);
    } else if (error?.response?.data?.cause === 'MISSING_COUNTRY_CODE') {
      setMissingCountryCodeError(true);
    } else if (error?.response?.data?.cause === 'BAD_CAPTCHA') {
      setCaptchaError(true);
    } else if (error?.response?.data?.cause === 'FAILED_TO_SEND_MAIL') {
      setFailedToSendError(true);
    } else if (error?.response?.status === 429) {
      setReachedRequestsLimitError(true);
    } else {
      setUnknownError(true);
    }
  };

  const checkEmailIsValid = () => {
    if (!authorEmailValue) {
      setMissingEmailError(true);
      setInvalidEmailError(false);
      return false;
    }

    if (!validator.isEmail(authorEmailValue)) {
      setMissingEmailError(false);
      setInvalidEmailError(true);
      return false;
    }

    setMissingEmailError(false);
    setInvalidEmailError(false);

    return true;
  };

  const setFieldError = (fieldName, value) => {
    if ('Address' === fieldName) {
      setMissingAddressError(value);
    }

    if ('City' === fieldName) {
      setMissingCityError(value);
    }

    if ('CountryCode' === fieldName) {
      setMissingCountryCodeError(value);
    }

    if ('Name' === fieldName) {
      setMissingNameError(value);
    }

    if ('Phone' === fieldName) {
      setMissingPhoneError(value);
    }

    if ('PostCode' === fieldName) {
      setMissingPostCodeError(value);
    }
  };

  const checkFieldIsFilled = (fieldName) => {
    const field = `author${fieldName}Value`;

    const fields = {
      authorNameValue,
      authorPhoneValue,
      authorAddressValue,
      authorPostCodeValue,
      authorCityValue,
      authorCountryCodeValue,
    };

    // eslint-disable-next-line react/destructuring-assignment
    const fieldValue = fields[field];
    if (fieldValue === '') {
      setFieldError(fieldName, true);
      return false;
    }
    setFieldError(fieldName, false);
    return true;
  };

  const resetErrors = () => {
    setServerError(null);
    setMissingNameError(false);
    setMissingEmailError(false);
    setMissingPhoneError(false);
    setMissingAddressError(false);
    setMissingPostCodeError(false);
    setMissingCityError(false);
    setMissingCountryCodeError(false);
    setInvalidEmailError(false);
    setFailedToSendError(false);
    setReachedRequestsLimitError(false);
    setCaptchaError(false);
    setUnknownError(false);
  };

  const sendRequest = () => {
    setIsLoading(true);
    resetErrors();

    ApiCalls.sendRequest(
      requestType,
      authorNameValue,
      authorEmailValue,
      authorPhoneValue,
      authorAddressValue,
      authorPostCodeValue,
      authorCityValue,
      authorCountryCodeValue,
      authorCountryValue,
      productsValue,
      freeTextValue,
      whatTimeValue,
      authorClientTypeValue,
      captchaRef.current.getValue()
    )
      .then(() => {
        setSentSuccessfully(true);
        setIsLoading(false);
        captchaRef.current?.reset();
      })
      .catch((error) => {
        setServerErrorState(error);
        setIsLoading(false);
        captchaRef.current?.reset();
      });
  };

  const handleSubmitRequest = (event) => {
    event.preventDefault();
    const emailIsValid = checkEmailIsValid();
    const nameIsFilled = checkFieldIsFilled('Name');
    const phoneIsFilled = checkFieldIsFilled('Phone');
    const addressIsFilled = checkFieldIsFilled('Address');
    const postCodeIsFilled = checkFieldIsFilled('PostCode');
    const cityIsFilled = checkFieldIsFilled('City');
    const countryCodeIsFilled = checkFieldIsFilled('ContryCode');
    if (
      nameIsFilled &&
      emailIsValid &&
      phoneIsFilled &&
      addressIsFilled &&
      postCodeIsFilled &&
      cityIsFilled &&
      countryCodeIsFilled
    ) {
      sendRequest();
    }
  };

  const handleSelectProduct = (event) => {
    const newProductsValue = [...productsValue];

    const indexOfProduct = newProductsValue.indexOf(event.target.id);
    if (indexOfProduct === -1) {
      newProductsValue.push(event.target.id);
      setProductsValue(newProductsValue);
    }
    if (indexOfProduct !== -1) {
      newProductsValue.splice(indexOfProduct, 1);
      setProductsValue(newProductsValue);
    }
  };

  const handleChangeCountryCode = (event, { value }) => {
    const country = countries.find(({ countryCode }) => countryCode === value);
    setAuthorCountryValue(country.name);
    setAuthorCountryCodeValue(country.countryCode);
  };

  // eslint-disable-next-line consistent-return
  const renderGeneralError = (error) => {
    if (captchaError || failedToSendError || reachedRequestsLimitError) {
      return (
        <Message
          error
          header={intl.formatMessage({ id: 'sendRequestForm.error' })}
          content={<FormattedServerError error={error} />}
        />
      );
    }
    if (unknownError) {
      return (
        <Message
          error
          header={intl.formatMessage({ id: 'sendRequestForm.error' })}
          content={<FormattedServerError />}
        />
      );
    }
  };

  let mailErrorText;
  if (invalidEmailError) {
    mailErrorText = intl.formatMessage({ id: 'sendRequestForm.error.invalidEmail' });
  } else if (missingEmailError) {
    mailErrorText = intl.formatMessage({ id: 'sendRequestForm.error.missingEmail' });
  }

  const nameErrorText = missingNameError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missingName' })
    : null;
  const phoneErrorText = missingPhoneError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missingPhone' })
    : null;
  const addressErrorText = missingAddressError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missingAddress' })
    : null;
  const postCodeErrorText = missingPostCodeError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missing-post-code' })
    : null;
  const cityErrorText = missingCityError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missing-city' })
    : null;
  const countryCodeErrorText = missingCountryCodeError
    ? intl.formatMessage({ id: 'sendRequestForm.error.missing-country-code' })
    : null;

  useEffect(() => {
    if (missingEmailError || invalidEmailError) checkEmailIsValid();
  }, [authorEmailValue, missingEmailError, invalidEmailError]);
  useEffect(() => {
    if (missingNameError) checkFieldIsFilled('Name');
  }, [authorNameValue, missingNameError]);
  useEffect(() => {
    if (missingPhoneError) checkFieldIsFilled('Phone');
  }, [authorPhoneValue, missingPhoneError]);
  useEffect(() => {
    if (missingAddressError) checkFieldIsFilled('Address');
  }, [authorAddressValue, missingAddressError]);
  useEffect(() => {
    if (missingPostCodeError) checkFieldIsFilled('PostCode');
  }, [authorPostCodeValue, missingPostCodeError]);
  useEffect(() => {
    if (missingCityError) checkFieldIsFilled('City');
  }, [authorCityValue, missingCityError]);

  return (
    <>
      {!sentSuccessfully && (
        <>
          {renderGeneralError(serverError)}
          <p className="mandatory-mention">
            <FormattedMessage
              id="form.mandatory-mention"
              values={{
                span: (chunks) => <span className="asterisk">{chunks}</span>,
              }}
            />
          </p>
          <Form loading={isLoading} onSubmit={handleSubmitRequest} className="request-form">
            <fieldset className="request-form__fieldset">
              <legend className="request-form__fieldset-legend">
                <FormattedMessage id="sendRequestForm.authorInfos" />
              </legend>
              {requestType === 'demo' && (
                <>
                  <Form.Group inline>
                    <Form.Field>
                      <label htmlFor="clientType">
                        <FormattedMessage id="sendRequestForm.client-type.label" />
                        &nbsp;
                        <RequiredAsterisk />
                      </label>
                    </Form.Field>
                    <Form.Field>
                      <Form.Radio
                        id="client-type-veterinarian"
                        name="clientType"
                        label={intl.formatMessage({
                          id: 'sendRequestForm.client-type.veterinarian.label',
                        })}
                        value="Vétérinaire"
                        checked={authorClientTypeValue === 'Vétérinaire'}
                        onChange={(event) => {
                          setAuthorClientTypeValue(event.target.value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Radio
                        id="client-type-equipment-supplier"
                        name="clientType"
                        label={intl.formatMessage({
                          id: 'sendRequestForm.client-type.equipment-supplier.label',
                        })}
                        value="Fournisseur d'équipement"
                        checked={authorClientTypeValue === "Fournisseur d'équipement"}
                        onChange={(event) => {
                          setAuthorClientTypeValue(event.target.value);
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              <Form.Group widths="equal">
                <Form.Input
                  required
                  fluid
                  id="name"
                  label={intl.formatMessage({ id: 'sendRequestForm.authorInfos.name' })}
                  value={authorNameValue}
                  error={nameErrorText}
                  onChange={(event) => {
                    setAuthorNameValue(event.target.value);
                  }}
                  onBlur={() => checkFieldIsFilled('Name')}
                />
                <Form.Input
                  required
                  fluid
                  id="email"
                  label={intl.formatMessage({ id: 'sendRequestForm.authorInfos.email' })}
                  placeholder="mail@mail.com"
                  value={authorEmailValue}
                  error={mailErrorText}
                  onChange={(event) => {
                    setAuthorEmailValue(event.target.value);
                  }}
                  onBlur={checkEmailIsValid}
                />
                <Form.Input
                  required
                  fluid
                  id="phone"
                  label={intl.formatMessage({ id: 'sendRequestForm.authorInfos.phone' })}
                  value={authorPhoneValue}
                  error={phoneErrorText}
                  onChange={(event) => {
                    setAuthorPhoneValue(event.target.value);
                  }}
                  onBlur={() => checkFieldIsFilled('Phone')}
                />
              </Form.Group>
              <Form.Input
                required
                fluid
                id="address"
                label={intl.formatMessage({ id: 'sendRequestForm.authorInfos.address' })}
                value={authorAddressValue}
                error={addressErrorText}
                onChange={(event) => {
                  setAuthorAddressValue(event.target.value);
                }}
                onBlur={() => checkFieldIsFilled('Address')}
              />
              <Form.Group widths="equal">
                <Form.Input
                  required
                  fluid
                  label={intl.formatMessage({ id: 'invoiceAddressModal.postCode' })}
                  value={authorPostCodeValue}
                  error={postCodeErrorText}
                  onChange={(event) => {
                    setAuthorPostCodeValue(event.target.value);
                  }}
                  onBlur={() => checkFieldIsFilled('PostCode')}
                />
                <Form.Input
                  required
                  fluid
                  label={intl.formatMessage({ id: 'invoiceAddressModal.city' })}
                  value={authorCityValue}
                  error={cityErrorText}
                  onChange={(event) => {
                    setAuthorCityValue(event.target.value);
                  }}
                  onBlur={() => checkFieldIsFilled('City')}
                />
              </Form.Group>

              <FormField required error={countryCodeErrorText}>
                <label>
                  <FormattedMessage id="invoiceAddressModal.country" />
                </label>
                <DropdownCountriesList
                  fluid
                  value={authorCountryCodeValue}
                  error={countryCodeErrorText}
                  onChange={handleChangeCountryCode}
                  onBlur={() => checkFieldIsFilled('CountryCode')}
                />
              </FormField>
            </fieldset>

            <fieldset className="request-form__fieldset">
              <legend className="request-form__fieldset-legend">
                <FormattedMessage id="sendRequestForm.requestContent" />
              </legend>
              <Form.Field>
                <label htmlFor="products">
                  <FormattedMessage id="sendRequestForm.requestContent.products" />
                </label>
              </Form.Field>
              <Form.Group inline>
                <Form.Field>
                  <Form.Checkbox
                    id="picoxia-intelligence"
                    name="products"
                    label={intl.formatMessage({
                      id: 'sendRequestForm.requestContent.products.picoxIAIntelligence',
                    })}
                    onChange={handleSelectProduct}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Checkbox
                    id="picoxia-acquisition"
                    name="products"
                    label={intl.formatMessage({
                      id: 'sendRequestForm.requestContent.products.picoxIAAcquisition',
                    })}
                    onChange={handleSelectProduct}
                  />
                </Form.Field>
              </Form.Group>

              {requestType === 'demo' && (
                <Form.Input
                  id="whatTime"
                  label={intl.formatMessage({
                    id: 'sendRequestForm.requestContent.whatTime',
                  })}
                  value={whatTimeValue}
                  onChange={(event) => setWhatTimeValue(event.target.value)}
                />
              )}

              <Form.TextArea
                id="freeText"
                className="request-form__content-textarea"
                label={intl.formatMessage({ id: 'sendRequestForm.requestContent.freeText' })}
                value={freeTextValue}
                onChange={(event) => setFreeTextValue(event.target.value)}
              />
            </fieldset>

            <GoogleReCaptcha useRef={captchaRef} />

            <Form.Field control={Button} color="green" className="request-form__submit-button">
              <Icon name="send" />
              <FormattedMessage id="general.send" />
            </Form.Field>
          </Form>
        </>
      )}

      {sentSuccessfully && (
        <Message
          success
          header={intl.formatMessage({ id: 'sendRequestForm.success.sentmessage' })}
          content={
            intl.formatMessage({ id: 'sendRequestForm.success.sentmessagedetail' }) +
            authorEmailValue
          }
        />
      )}
    </>
  );
}

export default SendDemoRequestForm;
