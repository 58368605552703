import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Card = ({ children }) => <div className="cardsList__card">{children}</div>;

const Icon = ({ icon }) => (
  <div className="cardsList__icon-bloc">
    <img className="cardsList__icon" src={icon} alt="" />
  </div>
);
Card.Icon = Icon;

const Title = ({ children }) => <h2 className="cardsList__title">{children}</h2>;
Card.Title = Title;

Icon.defaultProps = {
  icon: null,
};

Icon.propTypes = {
  icon: PropTypes.element,
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
