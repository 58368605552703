import { import as cstImport } from 'cornerstone-tools';
import { projectPerpendicularToAxis } from './pixelToCanvasUtils';

const drawLine = cstImport('drawing/drawLine');

const drawLineMarker = (context, elem, color, line, width) => {
  const [start, end] = line;
  const startMarker = [
    projectPerpendicularToAxis(elem, start, start, end, width / 2),
    projectPerpendicularToAxis(elem, start, start, end, -width / 2),
  ];
  const endMarker = [
    projectPerpendicularToAxis(elem, end, start, end, width / 2),
    projectPerpendicularToAxis(elem, end, start, end, -width / 2),
  ];
  // We use a slightly wider line marker
  drawLine(context, elem, startMarker[0], startMarker[1], { color });
  drawLine(context, elem, endMarker[0], endMarker[1], { color });
};

export default drawLineMarker;
