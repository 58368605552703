/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import './style.scss';

export const SetAIPanelHoveredForceOpenContext = React.createContext();

const FoldablePanel = ({ panelDisplayMode, handleSwitchMode, children }) => {
  const [panelInHoveredModeIsOpen, setPanelInHoveredModeIsOpen] = useState(false);
  const [AIPanelHoveredForceOpen, setAIPanelHoveredForceOpen] = useState(false);

  const basePanelClassName = 'controlled-panel';
  const modePanelClassName = panelDisplayMode === 'hovered' ? 'hovered' : '';
  const openPanelClassName = panelInHoveredModeIsOpen || AIPanelHoveredForceOpen ? 'open' : '';
  const fullPanelClassName = `${basePanelClassName} ${modePanelClassName} ${openPanelClassName}`;

  return (
    <>
      {panelDisplayMode === 'hovered' ? (
        <div
          role="button"
          className="narrow-bar"
          data-testid="narrow-bar"
          onMouseEnter={() => setPanelInHoveredModeIsOpen(true)}
          onTouchStart={() => handleSwitchMode('fixed')}
        >
          <Icon className="narrow-bar__icon" name="angle double left" />
        </div>
      ) : null}
      <div
        className={fullPanelClassName}
        data-testid="controlled-panel"
        onMouseLeave={() => setPanelInHoveredModeIsOpen(false)}
      >
        <div className="controlled-panel__content">
          <SetAIPanelHoveredForceOpenContext.Provider value={setAIPanelHoveredForceOpen}>
            {children}
          </SetAIPanelHoveredForceOpenContext.Provider>
        </div>
        <div className="controlled-panel__switch">
          {panelDisplayMode === 'hovered' ? (
            <Icon name="unlock" title="Unlocked panel" onClick={() => handleSwitchMode('fixed')} />
          ) : null}
          {panelDisplayMode === 'fixed' ? (
            <Icon name="lock" title="Locked panel" onClick={() => handleSwitchMode('hovered')} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FoldablePanel;
