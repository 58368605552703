import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Header, Segment, Icon, Button, Image } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import AppHelmet from 'app/components/AppHelmet';
import ApiCalls from 'app/utils/apiCalls';

export class DownloadPage extends React.PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <div style={{ marginBottom: '75px' }}>
        <AppHelmet titleIntlID="download.pageTitle" descriptionIntlID="download.pageDescription" />
        <div className="ui container" style={{ paddingTop: '20px' }}>
          <h1 style={{ fontSize: '2.7em', textAlign: 'center', marginBottom: '35px' }}>
            <FormattedMessage id="download.title" />
          </h1>
          <Segment as="section" style={{ textAlign: 'center', marginBottom: '35px' }}>
            <Header icon>
              <Image src="/icon.png" style={{ width: '6.5em' }} alt="Logo PicoxIA" />
              <p>
                <FormattedMessage id="download.startNow" />
              </p>
            </Header>
            <br />
            <Button
              href={`${ApiCalls.getBackendRoute()}/download_update?app_name=picoxia_ia`}
              className="green"
            >
              <FormattedMessage id="download.windows" />
            </Button>
          </Segment>

          <section>
            <Header as="h2" dividing>
              <FormattedMessage id="download.features" />
            </Header>
            <Header as="h3" dividing>
              <Icon name="cut" />
              <FormattedMessage id="download.features.snipper" />
            </Header>
            <p>
              <FormattedMessage id="download.features.snipper.description" />
            </p>
            <div style={{ textAlign: 'center' }}>
              <iframe
                style={{ maxWidth: '100%', border: 'none' }}
                src="https://player.vimeo.com/video/347287927??dnt=true&title=0&byline=0&portrait=0"
                width="640"
                height="332"
                frameBorder="0"
                allowFullScreen
                title={intl.formatMessage({ id: 'download.features.snipper.video.title' })}
              />
            </div>
            <Header as="h3" dividing>
              <Icon name="paper plane" />
              <FormattedMessage id="download.features.export" />
            </Header>
            <p>
              <FormattedMessage id="download.features.export.description" />
            </p>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DownloadPage));
