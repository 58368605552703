import { useCallback, useRef } from 'react';

export default function useAutoFocusRef() {
  const inputRef = useRef(null);

  const focusInputRefOnOpen = useCallback((ref) => {
    if (ref !== inputRef.current) {
      // Focussing select directly will cause an unpleasant scroll to top
      // To avoid that, we focus the ref once the component has settled
      setTimeout(() => ref?.focus(), 0);
    }
    inputRef.current = ref;
  }, []);

  return focusInputRefOnOpen;
}
