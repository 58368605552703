import {
  SW_VER,
  GEN_OPERATIVE_STATUS,
  RAD_VALUE_COMB_STATUS,
  RAD_VALUES_POST_EXP,
  PREP_STATE,
  EXP_STATE,
  GEN_THICKNESS,
  ERROR,
  WARNING,
} from 'app/xray/generator/MessageCode';

export const CONNECTION_TIMEOUT_MS = 20 * 1000;
export const GENERATOR_WAKE_UP_INTERVAL_MS = 4 * 1000;
export const CONNECTION_RETRY_INTERVAL_MS = 5 * 1000;
export const CONNECTION_KEEP_ALIVE_TIMEOUT_MS = 4 * 1000;
export const SET_RAD_RETRY_DELAY = 1500;
export const OPERATING_MODE = {
  SLEEPING: 1,
  STANDBY: 2,
  SHUTDOWN: 121,
};

export const FOCUS = {
  SMALL: 0,
  BIG: 1,
};
export const RAD_PARAMETER_KEY_TO_RAD_PARAMETER_CODE = {
  kV: 1,
  mAs: 2,
  mA: 3,
  ms: 4,
};

export const UNIT_STATUS = {
  GENERATOR_NOT_DETECTED: 0,
  SLEEPING_MODE: 1,
  STANDBY_MODE: 2,
  FIRST_TIME: 3,
  XRAY_IN_PROGRESS: 4,
  END_OF_XRAY: 5,
  SYSTEM_ERROR: 6,
  WAIT_CAPACITOR: 7,
  SHUTDOWN_IN_PROGRESS: 16,
  INTERNAL_BOARD_COMMUNICATION: 32,
};

export const READY_STATUS = {
  READY: 1,
  BUSY: 2,
};
export const READY_STATUS_READY = 1;
export const READY_STATUS_BUSY = 2;

export const STATE_CHANGE = {
  DISACTIVE: 0,
  ACTIVE: 1,
};

export const EVENT_SW_VER = 'generator_sw_ver';
export const EVENT_GENERATOR_STATUS = 'generator_status';
export const EVENT_GENERATOR_RAD_PARAMETERS_CHANGE = 'generator_rad_parameters_change';
export const EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE = 'generator_rad_parameters_exposure';
export const EVENT_GENERATOR_PREP_STATE = 'generator_prep_state';
export const EVENT_GENERATOR_EXPOSURE_STATE = 'generator_exposure_state';
export const EVENT_GENERATOR_THICKNESS = 'generator_thickness';
export const EVENT_GENERATOR_ERROR = 'generator_error';
export const EVENT_GENERATOR_WARNING = 'generator_warning';
/**
 * @typedef {Object} GeneratorSoftwareVersion
 * @prop {Number} version
 * @prop {Number} major
 * @prop {Number} minor
 */
/**
 * @typedef {Object} RadParameters
 * @prop {Number} kV
 * @prop {Number} mAs
 * @prop {Number} mA
 * @prop {Number} ms
 * @prop {FOCUS.SMALL|FOCUS.BIG} focus
 */
/**
 * @typedef {Object} PostExposureRadDose
 * @prop {Number} dose
 *
 * @typedef {RadParameters & ExposureRadDose} PostExposureRadParameters
 */
/**
 * @typedef {Object} GeneratorThickness
 * @prop {Number} thickness
 */
/**
 * @typedef {(
 * UNIT_STATUS.GENERATOR_NOT_DETECTED|UNIT_STATUS.SLEEPING_MODE|
 * UNIT_STATUS.STANDBY_MODE|UNIT_STATUS.FIRST_TIME|UNIT_STATUS.XRAY_IN_PROGRESS|
 * UNIT_STATUS.END_OF_XRAY|UNIT_STATUS.SYSTEM_ERROR|UNIT_STATUS.WAIT_CAPACITOR|
 * UNIT_STATUS.SHUTDOWN_IN_PROGRESS
 * )} UnitStatus
 */
/**
 * @typedef {READY_STATUS_READY|READY_STATUS_BUSY} ReadyStatus
 */
/**
 * @typedef {Object} GeneratorOperativeStatus
 * @prop {UnitStatus} unitStatus
 * @prop {ReadyStatus} readyStatus
 */
/**
 * @typedef {Object} GeneratorStateChange
 * @prop {STATE_CHANGE.ACTIVE|STATE_CHANGE.DISACTIVE} status
 */
/**
 * @typedef {Object} GeneratorError
 * @prop {Number} errorCode
 */
/**
 * @typedef {Object} GeneratorWarning
 * @prop {Number} warningCode
 */

export const GENERATOR_WARNING = {
  RAD_VALUE_OUT_OF_RANGE: 1,
  INCORRECT_HEATING_CURRENT_IN_STANDBY: 19,
  NO_RADIOLOGICAL_PARAMETER: 37,
  TUBE_TEMPERATURE_EXCEEDING: 23,
  X_RAY_TUBE_NOT_READY: 29,
  FIRST_TIME_TOO_LONG: 35,
  UNCHARGED_CAPACITORS: 24,
  STATOR_CURRENT_TOO_LOW_IN_FIRST_TIME: 40,
  STATOR_CURRENT_TOO_HIGH_IN_FIRST_TIME: 41,
  INTERNAL_ERROR_SECURITY: 42,
  CONNECTION_FAULT: 47,
  XRAY_CANCEL_BY_OPERATOR: 31,
  HEATING_CURRENT_TOO_LOW_DURING_XRAY: 20,
  HEATING_CURRENT_TOO_HIGH_DURING_XRAY: 45,
  KV_OVERSHOOT: 21,
  KV_ASYMMETRY: 22,
  X_RAY_DURATION_TOO_LONG: 28,
  KV_RISING_TOO_SLOW: 32,
  KV_RISING_TOO_FAST: 38,
  ANODE_CURRENT_TOO_HIGH: 39,
  NO_KV: 44,
  CURRENT_ANODE_DEVIATION_20MA: 33,
  CURRENT_ANODE_DEVIATION_50MA: 34,
  INTERNAL_ERROR_MEMORY: 43,
  INTERNAL_ERROR_TIMING1: 30,
  INTERNAL_ERROR_TIMING2: 48,
  ABNORMAL_STATOR_CURRENT_IN_STANDBY: 17,

  RAD_VALUES_ADJUSTED_MODIFIED: 2,
};

export const IGNORED_WARNINGS = [
  GENERATOR_WARNING.FIRST_TIME_TOO_LONG,
  GENERATOR_WARNING.RAD_VALUES_ADJUSTED_MODIFIED,
];
export const IGNORED_WARNINGS_IN_HISTORY = [
  GENERATOR_WARNING.RAD_VALUES_ADJUSTED_MODIFIED,
  GENERATOR_WARNING.RAD_VALUE_OUT_OF_RANGE,
  GENERATOR_WARNING.UNCHARGED_CAPACITORS,
];

export const GENERATOR_ERROR = {
  KV_OVERSHOOT: 133,
  KV_ASYMMETRY: 134,
  X_RAY_DURATION_TOO_LONG: 140,
  KV_RISING_TOO_SLOW: 144,
  CURRENT_ANODE_DEVIATION_50MA_2_TIMES: 146,
  KV_RISING_TOO_FAST: 150,
  NO_COMM_WITH_XRAY_GENERATOR: 207,
};

export const GENERATOR_AGGREGATED_ERROR = {
  ...GENERATOR_WARNING,
  ...GENERATOR_ERROR,
};
export const GENERATOR_HEADER_TO_EVENT_DESCRIPTOR = {
  [SW_VER]: {
    eventName: EVENT_SW_VER,
    params: ['version', 'major', 'minor'],
    bytesLength: [2],
  },
  [GEN_OPERATIVE_STATUS]: {
    eventName: EVENT_GENERATOR_STATUS,
    params: ['unitStatus', 'readyStatus'],
  },
  [RAD_VALUE_COMB_STATUS]: {
    eventName: EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
    params: ['kV', 'mAs', 'mA', 'ms', 'focus'],
    bytesLength: [1, 2, 2, 2],
  },
  [RAD_VALUES_POST_EXP]: {
    eventName: EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE,
    params: ['dose', 'kV', 'mAs', 'mA', 'ms', 'focus'],
    bytesLength: [2, 1, 2, 2, 2],
  },
  [PREP_STATE]: { eventName: EVENT_GENERATOR_PREP_STATE, params: ['status'] },
  [EXP_STATE]: { eventName: EVENT_GENERATOR_EXPOSURE_STATE, params: ['status'] },
  [GEN_THICKNESS]: {
    eventName: EVENT_GENERATOR_THICKNESS,
    params: ['thickness'],
    bytesLength: [2],
  },
  [ERROR]: { eventName: EVENT_GENERATOR_ERROR, params: ['errorCode'] },
  [WARNING]: { eventName: EVENT_GENERATOR_WARNING, params: ['warningCode'] },
};
