import produce from 'immer';
import * as _ from 'lodash';

const VALID_DYSPLASIA_PATTERNS = [
  'defaut_de_coaptation',
  'incongruence',
  'insuffisance_de_couverture_acetabulaire',
  'arthrose',
];

const filterDysplasiaPredictions = (predictions) =>
  produce(predictions, (draftPredictions) => {
    if (draftPredictions.norberg_olsson) {
      [draftPredictions.norberg_olsson.left, draftPredictions.norberg_olsson.right].forEach(
        (draftHip) => {
          draftHip.patterns = _.pick(draftHip.patterns, VALID_DYSPLASIA_PATTERNS);
        }
      );
    }
  });

// eslint-disable-next-line import/prefer-default-export
export { filterDysplasiaPredictions };
