import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import 'semantic-ui-css/semantic.min.css';
import ApiCalls from '../../utils/apiCalls';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Header, Dividing, Segment, Icon, Modal, Input, Button, Form } from 'semantic-ui-react';
import { browserHistory } from 'react-router';

export class InvoiceAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      workplaceName: '',
      address: '',
      postCode: '',
      city: '',
      country: '',
      isLoading: false,
    };
  }

  componentDidMount = () => {
    ApiCalls.getInvoiceAddress().then((savedInvoiceData) => {
      this.setState(savedInvoiceData.data);
    });
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  //  componentDidUpdate = (prevProps, prevState, snapshot) => {
  ////  componentDidUpdate = () => {
  //    console.log(this.props.isOpen)
  //    this.setState({isOpen: this.props.isOpen})
  //  }

  save = () => {
    const address = {
      workplaceName: this.state.workplaceName,
      address: this.state.address,
      postCode: this.state.postCode,
      city: this.state.city,
      country: this.state.country,
      phoneNumber: this.state.phoneNumber,
    };
    this.setState({ isLoading: true });
    ApiCalls.setInvoiceAddress(address)
      .then(this.props.onSave)
      .then(() => {
        this.setState({ isLoading: false });
        if (this.props.closeWhenDone) {
          this.props.close();
        }
      });
  };

  handleChange = (event, fieldName) => {
    let stateUpdate = {};
    stateUpdate[fieldName] = event.target.value;
    this.setState(stateUpdate);
  };

  render() {
    return (
      <Modal
        onClose={this.props.close}
        open={this.props.isOpen}
        /*trigger={<Button>Show Modal</Button>}*/
      >
        <Modal.Header>
          <FormattedMessage id="invoiceAddressModal.header" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              fluid
              value={this.state.workplaceName}
              onChange={(e) => this.handleChange(e, 'workplaceName')}
              label={this.formatMessage({ id: 'invoiceAddressModal.workplaceName' })}
              placeholder={this.formatMessage({ id: 'invoiceAddressModal.workplaceName' })}
            />
            <Form.Input
              fluid
              value={this.state.address}
              onChange={(e) => this.handleChange(e, 'address')}
              label={this.formatMessage({ id: 'invoiceAddressModal.address' })}
              placeholder={this.formatMessage({ id: 'invoiceAddressModal.address' })}
            />
            <Form.Group widths="equal">
              <Form.Input
                fluid
                value={this.state.postCode}
                onChange={(e) => this.handleChange(e, 'postCode')}
                label={this.formatMessage({ id: 'invoiceAddressModal.postCode' })}
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.postCode' })}
              />
              <Form.Input
                fluid
                value={this.state.city}
                onChange={(e) => this.handleChange(e, 'city')}
                label={this.formatMessage({ id: 'invoiceAddressModal.city' })}
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.city' })}
              />
              <Form.Input
                fluid
                value={this.state.country}
                onChange={(e) => this.handleChange(e, 'country')}
                label={this.formatMessage({ id: 'invoiceAddressModal.country' })}
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.country' })}
              />
            </Form.Group>
            <Form.Input
              fluid
              value={this.state.phoneNumber}
              onChange={(e) => this.handleChange(e, 'phoneNumber')}
              label={this.formatMessage({ id: 'invoiceAddressModal.phoneNumber' })}
              placeholder={this.formatMessage({ id: 'invoiceAddressModal.phoneNumber' })}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={this.props.close}>
            <FormattedMessage id="invoiceAddressModal.cancel" />
          </Button>
          <Button
            content={this.formatMessage({ id: 'invoiceAddressModal.save' })}
            labelPosition="right"
            icon={this.state.isLoading ? '' : 'checkmark'}
            onClick={this.save}
            positive
            loading={this.state.isLoading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvoiceAddressModal));
