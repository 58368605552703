import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CardsList = ({ children }) => <div className="cardsList">{children}</div>;

CardsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardsList;
