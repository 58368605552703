import React, { BaseSyntheticEvent, useContext, useEffect, useRef } from 'react';
import { DisplayViewport, DisplayableImageData, ImageAnnotations } from 'app/interfaces/Image';
import {
  BasicImageRenderer,
  BasicRendererAnnotationsForDisplay,
} from 'app/interfaces/ImageRenderer';
import thumbnailImageRendererFactoryContext from 'app/providers/ImageRenderer/ThumbnailImageRendererFactory/context';

type ThumbnailImageRendererProps = {
  image: DisplayableImageData;
  viewport?: DisplayViewport;
  annotations?: BasicRendererAnnotationsForDisplay;
  className?: string;
  onRendererRef?: (ref: BasicImageRenderer) => void;
};

const preventDefault = (e: BaseSyntheticEvent) => e.preventDefault();

export function ThumbnailImageRenderer({
  image,
  viewport,
  annotations,
  className = '',
  onRendererRef,
}: ThumbnailImageRendererProps) {
  const renderer = useRef<BasicImageRenderer>();
  const imageRendererFactory = useContext(thumbnailImageRendererFactoryContext);
  const currentRenderer = renderer.current;

  const onRef = (ref: HTMLElement) => {
    if (!ref || renderer.current?.element === ref) return;

    renderer.current = imageRendererFactory?.create(ref);
    onRendererRef?.(renderer.current);
  };

  useEffect(() => {
    return () => currentRenderer?.destroy();
  }, [currentRenderer]);

  useEffect(() => {
    if (!image) return;
    renderer.current?.displayImage(image, {
      viewport,
      annotations,
    });
  }, [currentRenderer, image, viewport, annotations]);

  return (
    <div
      ref={onRef}
      className={className}
      data-testid="thumbnail-render-area"
      onContextMenu={preventDefault}
      style={{ width: '100%', height: '100%' }}
    >
      <canvas className="cornerstone-canvas" style={{ objectFit: 'none' }} />
    </div>
  );
}
