import { DataImage } from 'app/interfaces/DataImage';
import { DisplayableImageData } from 'app/interfaces/Image';

export default function convertDisplayableImageDataToDataImage(
  displayableImage: DisplayableImageData
): DataImage {
  const rawData = displayableImage.getPixelData();
  let data;
  if (rawData instanceof Uint8Array || rawData instanceof Uint16Array) {
    data = rawData;
  } else {
    data = new Uint8Array(rawData);
  }

  return {
    data,
    width: displayableImage.width,
    height: displayableImage.height,
    bytes_per_pixel: data.BYTES_PER_ELEMENT,
    minPixelValue: displayableImage.minPixelValue,
    maxPixelValue: displayableImage.maxPixelValue,
    windowCenter: displayableImage.windowCenter,
    windowWidth: displayableImage.windowWidth,
  };
}
