import { import as cornerstoneToolsImport } from 'cornerstone-tools';
import * as _ from 'lodash';
import { noop } from 'lodash';
import { getFormat } from 'app/utils/dateUtil';
import { getViewport } from 'cornerstone-core';
import { computeAge } from '../components/AgeFromDate';

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');

export default class DicomDataPrinterTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'DicomDataPrinter',
      configuration: {
        lineHeight: 15,
        font: '14px Lato',
        fontColor: 'white',
        getIntl: () => {},
      },
      supportedInteractionTypes: [],
    };

    super(props, defaultProps);

    this.preventNewMeasurement = true;

    this.dicomTextArea = { topLeft: { y: 0 } };
  }

  /**
   * @brief Disable since we do not want click to create a new tool data.
   */
  // eslint-disable-next-line class-methods-use-this
  createNewMeasurement = () => {};

  // eslint-disable-next-line class-methods-use-this
  pointNearTool = () => false;

  /* eslint-disable no-param-reassign */
  // eslint-disable-next-line class-methods-use-this
  updateCachedStats = () => {};

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { element } = eventData;
    const { getIntl } = this.configuration;
    /**  @type {import('react-intl').IntlShape} */
    const { formatMessage = noop, formatNumber = noop, locale } = getIntl() ?? {};

    // We have tool data for this element - iterate over each one and draw it
    const { displayedMetadata } = element;
    if (!displayedMetadata) return;

    const context = getNewContext(eventData.canvasContext.canvas);
    draw(context, (ctx) => {
      const {
        studyDescription,
        seriesDescription,
        patientName,
        patientSex,
        patientBirthDate,
        patientSpecie,
        patientChipID,
        patientPedigreeID,
        responsiblePerson,
        acquisitionDateTime,
        referringPhysicianName,
        institutionAddress,
        institutionName,
        // In millimeter
        pixelSpacing,
        kv,
        mA,
        mAs,
      } = displayedMetadata;

      const { width, height } = eventData.canvasContext.canvas;
      const { lineHeight, fontColor, font } = this.configuration;
      ctx.fillStyle = fontColor;
      ctx.font = font;
      ctx.textAlign = 'start';
      ctx.textBaseline = 'top';
      let strokeX = 5;
      let strokeY = 5;
      // top left
      if (patientChipID) {
        const text = formatMessage(
          { id: `patient_info.chip_id.dicom_insert` },
          { chip_id: patientChipID }
        );
        ctx.fillText(text, strokeX, strokeY);
        strokeY += lineHeight;
      }

      if (patientPedigreeID) {
        const text = formatMessage(
          { id: `patient_info.pedigree_id.dicom_insert` },
          { pedigree_id: patientPedigreeID }
        );
        ctx.fillText(text, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (patientName) {
        const text = formatMessage(
          { id: `patient_info.animal_name.dicom_insert` },
          { patient: patientName }
        );
        ctx.fillText(text, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (responsiblePerson) {
        const text = formatMessage(
          { id: `patient_info.owner_name.dicom_insert` },
          { owner: responsiblePerson }
        );
        ctx.fillText(text, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (patientSpecie) {
        ctx.fillText(
          formatMessage({
            id: `patient_info.specie.${patientSpecie}`,
            defaultMessage: patientSpecie,
          }),
          strokeX,
          strokeY
        );
        strokeY += lineHeight;
      }
      if (patientSex) {
        const sexText = formatMessage({
          id: `patient_info.sex.${patientSex}`,
          defaultMessage: patientSex,
        });
        ctx.fillText(sexText, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (patientBirthDate) {
        const patientAge = computeAge(patientBirthDate);
        let patientAgeString = '';
        if (patientAge.years > 0) {
          patientAgeString += formatNumber(patientAge.years, {
            style: 'unit',
            unit: 'year',
            unitDisplay: 'narrow',
          });
        }
        if (patientAge.months > 0) {
          patientAgeString += `${patientAgeString && ' '}${formatNumber(patientAge.months, {
            style: 'unit',
            unit: 'month',
            unitDisplay: 'narrow',
          })}`;
        }
        if (patientAge.weeks > 0) {
          patientAgeString += `${patientAgeString && ' '}${formatNumber(patientAge.weeks, {
            style: 'unit',
            unit: 'week',
            unitDisplay: 'narrow',
          })}`;
        }
        ctx.fillText(patientAgeString, strokeX, strokeY);
        strokeY += lineHeight;
      }
      this.dicomTextArea.topLeft.y = strokeY;

      // bottom left
      ctx.textAlign = 'start';
      ctx.textBaseline = 'bottom';
      strokeX = 5;
      strokeY = height - 5;
      if (referringPhysicianName) {
        ctx.fillText(referringPhysicianName, strokeX, strokeY);
        strokeY -= lineHeight;
      }

      // top right
      strokeX = width - 5;
      strokeY = 5;
      ctx.textAlign = 'end';
      ctx.textBaseline = 'top';
      if (acquisitionDateTime) {
        ctx.fillText(
          acquisitionDateTime.toLocaleString(locale, getFormat('shortTime')),
          strokeX,
          strokeY
        );
        strokeY += lineHeight;
      }
      if (studyDescription) {
        ctx.fillText(studyDescription, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (seriesDescription) {
        ctx.fillText(seriesDescription, strokeX, strokeY);
        strokeY += lineHeight;
      }

      // bottom right
      ctx.textBaseline = 'bottom';
      ctx.textAlign = 'end';
      strokeX = width - 5;
      strokeY = height - 5;
      if (institutionName) {
        ctx.fillText(institutionName, strokeX, strokeY);
        strokeY -= lineHeight;
      }
      if (institutionAddress) {
        ctx.fillText(institutionAddress, strokeX, strokeY);
        strokeY -= lineHeight;
      }
      if (pixelSpacing) {
        const pixelSpacingString = _.map(pixelSpacing, (value) => parseFloat(value).toFixed(3));
        ctx.fillText(formatMessage({ id: 'image_info.pixel_spacing' }), strokeX, strokeY);
        strokeY -= lineHeight;
        ctx.fillText(
          `${pixelSpacingString} ${formatMessage({ id: 'image_info.pixel_spacing.unit' })}`,
          strokeX,
          strokeY
        );
        strokeY -= lineHeight;
      }
      if (kv || mA || mAs) {
        const acquisitionConstantText = _.filter([
          formatMessage({ id: 'image_info.KVP.unit' }, { KVP: kv }),
          formatMessage({ id: 'image_info.Exposure.unit' }, { Exposure: +mAs.toFixed(2) }),
          formatMessage(
            { id: 'image_info.XRayTubeCurrent.unit' },
            { XRayTubeCurrent: Math.floor(mA) }
          ),
        ]).join(' ');
        ctx.fillText(acquisitionConstantText, strokeX, strokeY);
        strokeY -= lineHeight;
      }
      try {
        const viewport = getViewport(element);
        const { windowCenter, windowWidth } = viewport?.voi ?? {};
        if (windowWidth && windowCenter) {
          ctx.fillText(
            `WW/WC : ${Math.floor(windowWidth)}/${Math.floor(windowCenter)}`,
            strokeX,
            strokeY
          );
          strokeY -= lineHeight;
        }

        // eslint-disable-next-line no-empty
      } catch {}
    });
  };
}
