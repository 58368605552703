import FormMandatoryMention from 'app/components/Form/MandatoryMention';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormField, Icon, Input, Label, Popup, Select } from 'semantic-ui-react';
import { Patient } from 'app/interfaces/Patient';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import { makeSexesOptions } from 'app/constants/sexes';
import { makeRacesOptions, makeSpeciesOptions } from 'app/constants/species';
import countries from 'app/constants/countries';
import TeleradiologyVetFlowModalAnalyzeRequestTermsOfSales from './TermsOfSales';
import _ from 'lodash';
import ApiCalls from 'app/utils/apiCalls';

export type FormDataType = {
  isUrgent?: boolean;
  examDate: string | Date;
  reason: string;
  clinicalSigns: string;
  veterinarianInterpretation: string;
  examiningVeterinarian?: string;
  examiningEmail?: string;
  referentClinic?: string;
  referentVeterinarian?: string;
  referentEmail?: string;
  patientName: string;
  patientSpecie: string;
  patientSpecieCustom?: string;
  patientRace: string;
  patientSex: string;
  patientBirthDate: string | Date;
  patientAge: string;
  patientWeight: string;
  ownerLastName: string;
  ownerFirstName?: string;
  ownerAddress: string;
  ownerPostCode: string;
  ownerCity: string;
  ownerCountry?: string;
  ownerCountryCode?: string;
  ownerEmail?: string;
  ownerPhoneNumber: string;
  acceptGeneralTermsOfSales: boolean;
};

type TeleradiologyVetFlowModalAnalyzeRequestFormProps = {
  animal?: Patient;
  scrollToTop: () => void;
  onSubmit: (formData: FormDataType) => void;
  control: Control<any>;
  errors: FieldErrors;
  handleSubmit: UseFormHandleSubmit<FormDataType>;
  setValue: UseFormSetValue<any>;
};

function TeleradiologyVetFlowModalAnalyzeRequestForm({
  animal,
  scrollToTop,
  onSubmit,
  control,
  errors,
  handleSubmit,
  setValue,
}: TeleradiologyVetFlowModalAnalyzeRequestFormProps) {
  const intl = useIntl();

  const speciesOptions = makeSpeciesOptions(intl).map((specie) => ({
    key: specie.value,
    text: specie.label,
    value: specie.value,
  }));

  const racesOptions = makeRacesOptions(intl, animal?.specie).map((race) => ({
    key: race.value,
    text: race.label,
    value: race.value,
  }));

  const sexesOptions = makeSexesOptions(intl).map((sex) => ({
    key: sex.value,
    text: sex.label,
    value: sex.value,
  }));

  const handleChangeCountryCode = (event: any, { value }: { value: string }) => {
    const country = countries.find(({ countryCode }) => countryCode === value);
    setValue('ownerCountry', country.name);
    setValue('ownerCountryCode', country.countryCode);
  };

  const setFakeOwnerDetails = () => {
    ApiCalls.getUserDetails().then((response) => {
      setValue('ownerAddress', response.data.user.address.address);
      setValue('ownerPostCode', response.data.user.address.postCode);
      setValue('ownerCity', response.data.user.address.city);
      setValue('ownerPhoneNumber', response.data.user.address.phoneNumber);
    });
  };

  useEffect(() => {
    setFakeOwnerDetails();
  }, []);

  return (
    <>
      <FormMandatoryMention />
      <Form onSubmit={handleSubmit(onSubmit, scrollToTop)}>
        <fieldset id="analyze-exam">
          <legend>
            <FormattedMessage id="teleradiology.analyze-request.exam.label" />
          </legend>
          <Form.Group widths="equal">
            <Controller
              name="isUrgent"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Form.Checkbox
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.is-urgent.label',
                  })}
                  checked={value}
                  onChange={(_event, { checked }) => onChange(checked)}
                  onBlur={onBlur}
                  error={!!errors.isUrgent}
                />
              )}
            />
            <Controller
              name="examDate"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.exam-date.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="date"
                  required
                  readOnly
                  labelPosition="left"
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.exam-date.label',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.examDate}
                >
                  <Label>
                    <Icon fitted name="calendar alternate outline" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />
          </Form.Group>

          <Controller
            name="reason"
            control={control}
            rules={{
              required: 'teleradiology.analyze-request.reason.error.required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.TextArea
                required
                label={intl.formatMessage({ id: 'teleradiology.analyze-request.reason.label' })}
                placeholder={intl.formatMessage({
                  id: 'teleradiology.analyze-request.reason.placeholder',
                })}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.reason}
              />
            )}
          />
          <Controller
            name="clinicalSigns"
            control={control}
            rules={{
              required: 'teleradiology.analyze-request.clinical-signs.error.required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.TextArea
                required
                label={intl.formatMessage({
                  id: 'teleradiology.analyze-request.clinical-signs.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'teleradiology.analyze-request.clinical-signs.placeholder',
                })}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.clinicalSigns}
              />
            )}
          />
          <Controller
            name="veterinarianInterpretation"
            control={control}
            rules={{
              required: 'teleradiology.analyze-request.veterinarian-interpretation.error.required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.TextArea
                required
                label={intl.formatMessage({
                  id: 'teleradiology.analyze-request.veterinarian-interpretation.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'teleradiology.analyze-request.veterinarian-interpretation.placeholder',
                })}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                rows={6}
                error={!!errors.veterinarianInterpretation}
              />
            )}
          />
          <Form.Group widths="equal">
            <Controller
              name="examiningVeterinarian"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  labelPosition="left"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.examining-veterinarian.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.examining-veterinarian.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.examiningVeterinarian}
                >
                  <Label>
                    <Icon fitted name="doctor" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />
            <Controller
              name="examiningEmail"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="email"
                  labelPosition="left"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.examining-email.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.examining-email.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.examiningEmail}
                >
                  <Label>
                    <Icon fitted name="at" />
                  </Label>

                  <input />
                </Form.Input>
              )}
            />
          </Form.Group>
          <Controller
            name="referentClinic"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Input
                type="text"
                labelPosition="left"
                fluid
                label={intl.formatMessage({
                  id: 'teleradiology.analyze-request.referent-clinic.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'teleradiology.analyze-request.referent-clinic.placeholder',
                })}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.referentClinic}
              >
                <Label>
                  <Icon fitted name="home" />
                </Label>

                <input />
              </Form.Input>
            )}
          />
          <Form.Group widths="equal">
            <Controller
              name="referentVeterinarian"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  labelPosition="left"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.referent-veterinarian.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.referent-veterinarian.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.referentVeterinarian}
                >
                  <Label>
                    <Icon fitted name="doctor" />
                  </Label>

                  <input />
                </Form.Input>
              )}
            />
            <Controller
              name="referentEmail"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="email"
                  labelPosition="left"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.referent-email.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.referent-email.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.referentEmail}
                >
                  <Label>
                    <Icon fitted name="at" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />
          </Form.Group>
        </fieldset>
        <fieldset id="analyze-patient">
          <legend>
            <FormattedMessage id="teleradiology.analyze-request.patient.label" />
          </legend>
          <Controller
            name="patientName"
            control={control}
            rules={{
              required: 'teleradiology.analyze-request.patient.name.error.required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField required>
                <label>
                  <AnimalInformationsEditHelper />{' '}
                  <FormattedMessage id="teleradiology.analyze-request.patient.name.label" />
                </label>
                <Input
                  type="text"
                  fluid
                  required
                  readOnly
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.patient.name.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.patientName}
                />
              </FormField>
            )}
          />
          <Form.Group widths="equal">
            <Controller
              name="patientSpecie"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.patient.specie.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField required>
                  <label>
                    <AnimalInformationsEditHelper />{' '}
                    <FormattedMessage id="teleradiology.analyze-request.patient.specie.label" />
                  </label>
                  <Form.Select
                    fluid
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.analyze-request.patient.specie.placeholder',
                    })}
                    value={value}
                    options={speciesOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.patientSpecie}
                  />
                </FormField>
              )}
            />
            <Controller
              name="patientSpecieCustom"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.patient.specie-custom.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.patient.specie-custom.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.patientSpecieCustom}
                />
              )}
            />
            <Controller
              name="patientRace"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.patient.race.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField required>
                  <label>
                    <AnimalInformationsEditHelper />{' '}
                    <FormattedMessage id="teleradiology.analyze-request.patient.race.label" />
                  </label>
                  <Select
                    fluid
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.analyze-request.patient.race.placeholder',
                    })}
                    value={value}
                    options={racesOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.patientRace}
                  />
                </FormField>
              )}
            />
          </Form.Group>

          <Form.Group className="flex center">
            <Controller
              name="patientSex"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.patient.sex.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField required>
                  <label>
                    <AnimalInformationsEditHelper />{' '}
                    <FormattedMessage id="teleradiology.analyze-request.patient.sex.label" />
                  </label>
                  <Select
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.analyze-request.patient.sex.placeholder',
                    })}
                    icon={null}
                    options={sexesOptions}
                    trigger={sexesOptions.find((option) => option.value === value)?.text}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.patientSex}
                    className="patient-sex-select"
                  />
                </FormField>
              )}
            />
            <Controller
              name="patientBirthDate"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.patient.birth-date.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField required>
                  <label>
                    <AnimalInformationsEditHelper />{' '}
                    <FormattedMessage id="teleradiology.analyze-request.patient.birth-date.label" />
                  </label>
                  <Input
                    type="date"
                    required
                    readOnly
                    labelPosition="left"
                    width={5}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.patientBirthDate}
                  >
                    <Label>
                      <Icon fitted name="calendar alternate outline" />
                    </Label>
                    <input />
                  </Input>
                </FormField>
              )}
            />
            <Controller
              name="patientWeight"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.patient.weight.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.patient.weight.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.patient.weight.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.patientWeight}
                  width={5}
                  style={{ flexGrow: 1 }}
                />
              )}
            />
          </Form.Group>
        </fieldset>
        <fieldset id="analyze-owner">
          <legend>
            <FormattedMessage id="teleradiology.analyze-request.owner.label" />
          </legend>
          <Form.Group widths="equal">
            <Controller
              name="ownerLastName"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.owner.last-name.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField required>
                  <label>
                    <AnimalInformationsEditHelper />{' '}
                    <FormattedMessage id="teleradiology.analyze-request.owner.last-name.label" />
                  </label>
                  <Input
                    type="text"
                    fluid
                    required
                    readOnly
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.analyze-request.owner.last-name.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.ownerLastName}
                  />
                </FormField>
              )}
            />
            <Controller
              name="ownerFirstName"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.first-name.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.first-name.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.ownerFirstName}
                />
              )}
            />
          </Form.Group>

          <Controller
            name="ownerAddress"
            control={control}
            rules={{
              required: 'teleradiology.analyze-request.owner.address.error.required',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Input
                type="text"
                fluid
                required
                label={intl.formatMessage({
                  id: 'teleradiology.analyze-request.owner.address.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'teleradiology.analyze-request.owner.address.placeholder',
                })}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.ownerAddress}
              />
            )}
          />
          <Form.Group widths="equal">
            <Controller
              name="ownerPostCode"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.owner.post-code.error.required',
                minLength: {
                  value: 5,
                  message: 'teleradiology.analyze-request.owner.post-code.error.length',
                },
                maxLength: {
                  value: 5,
                  message: 'teleradiology.analyze-request.owner.post-code.error.length',
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.post-code.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.post-code.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.ownerPostCode}
                />
              )}
            />
            <Controller
              name="ownerCity"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.owner.city.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.city.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.city.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.ownerCity}
                />
              )}
            />
            <Controller
              name="ownerCountryCode"
              control={control}
              render={({ field: { onBlur, value } }) => (
                <div className="field">
                  <label htmlFor="countryCode">
                    <FormattedMessage id="teleradiology.analyze-request.owner.country.label" />
                  </label>
                  <DropdownCountriesList
                    id="ownerCountryCode"
                    fluid
                    value={value}
                    onChange={handleChangeCountryCode}
                    onBlur={onBlur}
                    error={!!errors.ownerCountryCode}
                  />
                </div>
              )}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Controller
              name="ownerEmail"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="email"
                  labelPosition="left"
                  fluid
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.email.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.email.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.ownerEmail}
                >
                  <Label>
                    <Icon fitted name="at" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />
            <Controller
              name="ownerPhoneNumber"
              control={control}
              rules={{
                required: 'teleradiology.analyze-request.owner.phone-number.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  labelPosition="left"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.phone-number.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.analyze-request.owner.phone-number.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.ownerPhoneNumber}
                >
                  <Label>
                    <Icon fitted name="phone" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />
          </Form.Group>
        </fieldset>
        <Controller
          name="acceptGeneralTermsOfSales"
          control={control}
          rules={{
            required: 'teleradiology.analyze-request.accept-general-terms-of-sales.error.required',
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Form.Checkbox
              required
              label={
                <label
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(!value);
                  }}
                >
                  <FormattedMessage id="teleradiology.analyze-request.accept-general-terms-of-sales.label" />
                  &nbsp;
                  <TeleradiologyVetFlowModalAnalyzeRequestTermsOfSales
                    onAcceptClick={() => onChange(true)}
                  />
                </label>
              }
              checked={value}
              onChange={(_event, { checked }) => onChange(checked)}
              onBlur={onBlur}
              error={!!errors.acceptGeneralTermsOfSales}
            />
          )}
        />
      </Form>
    </>
  );
}

function AnimalInformationsEditHelper() {
  const intl = useIntl();
  return (
    <Popup
      trigger={<Icon name="help circle" />}
      content={intl.formatMessage({
        id: 'teleradiology.analyze-request.animal.edit-helper',
      })}
    />
  );
}

export default TeleradiologyVetFlowModalAnalyzeRequestForm;
