/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { linter, lintGutter } from '@codemirror/lint';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Header, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import { setDicomMapping } from 'app/redux/userInputMapping/actions';
import { selectDicomMapping } from 'app/redux/userInputMapping/reducer';
import ActionResultButton from 'app/components/ActionResultButton';
import DEFAULT_SPECIES_MAPPING from 'app/constants/speciesMappings';

const CODE_MIRROR_EXTENSIONS = [json(), lintGutter(), linter(jsonParseLinter())];
const DEFAULT_DICOM_MAPPING_JSON = JSON.stringify({ species: DEFAULT_SPECIES_MAPPING }, null, 2);

function UserMappingConfiguration() {
  const dispatch = useDispatch();
  const dicomMapping = useSelector(selectDicomMapping);
  const jsonDicomMapping = useMemo(
    () => (dicomMapping ? JSON.stringify(dicomMapping, null, 2) : DEFAULT_DICOM_MAPPING_JSON),
    [dicomMapping]
  );
  const [open, setOpen] = useState(false);
  const [pendingDicomMapping, setPendingDicomMapping] = useState(jsonDicomMapping);
  const [dicomMappingError, setDicomMappingError] = useState(undefined);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // Restore last saved dicom mapping
    setPendingDicomMapping(jsonDicomMapping);
    setDicomMappingError(undefined);
  };
  const clearInput = () => {
    setPendingDicomMapping(DEFAULT_DICOM_MAPPING_JSON);
    setDicomMappingError(undefined);
  };
  const handlePendingMappingChange = (value) => {
    setDicomMappingError(undefined);
    setPendingDicomMapping(value);
  };
  const handleSubmit = () => {
    try {
      let newDicomMapping;

      if (!pendingDicomMapping || pendingDicomMapping === '') newDicomMapping = undefined;
      else newDicomMapping = JSON.parse(pendingDicomMapping);
      dispatch(setDicomMapping(newDicomMapping));
      return true;
    } catch (e) {
      setDicomMappingError(e.message);
      return false;
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <FormattedMessage id="general.configure" />
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Header content={<FormattedMessage id="account.configuration.user_mapping.title" />} />
        <Modal.Content>
          <Form>
            <Form.Field error={!!dicomMappingError} value={pendingDicomMapping}>
              <label>
                <div style={{ gap: '0.5em' }} className="flex">
                  <FormattedMessage id="account.configuration.user_mapping.dicom.label" />
                  <Popup
                    wide="very"
                    trigger={<Icon size="small" color="grey" name="help" circular />}
                    content={
                      <span style={{ 'white-space': 'break-spaces' }}>
                        <FormattedMessage id="account.configuration.user_mapping.dicom.label.detail.content" />
                      </span>
                    }
                    header={
                      <Header>
                        <FormattedMessage id="account.configuration.user_mapping.dicom.label.detail.title" />
                      </Header>
                    }
                    position="bottom center"
                  />
                </div>
                <div>
                  <CodeMirror
                    maxHeight="400px"
                    value={pendingDicomMapping}
                    onChange={handlePendingMappingChange}
                    extensions={CODE_MIRROR_EXTENSIONS}
                  />
                  {dicomMappingError && (
                    <Label pointing prompt data-testid="user_mapping-dicom-error">
                      {dicomMappingError}
                    </Label>
                  )}
                </div>
              </label>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>
            <FormattedMessage id="general.cancel" />
          </Button>
          <Button onClick={clearInput}>
            <FormattedMessage id="general.reset" />
          </Button>
          <ActionResultButton
            color="green"
            onClick={handleSubmit}
            type="submit"
            successContent={<FormattedMessage id="general.success" />}
            failureProps={{ color: 'red' }}
            onSuccess={handleClose}
          >
            <FormattedMessage id="general.confirm" />
          </ActionResultButton>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default UserMappingConfiguration;
