import React from 'react';
import * as pt from 'prop-types';
import * as csc from 'cornerstone-core';
import { forceImageUpdate } from '../../CornerstoneTools/forceImageUpdate';
import OnClickToolButton from './OnClickToolButton';

class NegativeColorButton extends React.PureComponent {
  doNegativeColor = () => {
    const { focusedElement } = this.props;
    if (!focusedElement) return;
    try {
      const { viewport } = csc.getEnabledElement(focusedElement);
      viewport.invert = !viewport.invert;
      forceImageUpdate(focusedElement);
    } catch (error) {
      console.log('Error in NegativeColorButton', error);
    }
  };

  render = () => (
    <OnClickToolButton
      onClick={this.doNegativeColor}
      tooltipId="tools.negative.tooltip"
      content={<i className="svg-icon negative-color" />}
    />
  );
}

NegativeColorButton.propTypes = {
  focusedElement: pt.instanceOf(HTMLElement),
};

NegativeColorButton.defaultProps = {
  focusedElement: null,
};

export default NegativeColorButton;
