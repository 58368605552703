import { Point } from 'app/CornerstoneTools/types';
import { getEnabledElement, pixelToCanvas } from 'cornerstone-core';
import { mapValues } from 'lodash';
import normalizePoint from 'app/CornerstoneTools/utils/normalizePoint';

export default function normalizeHandlesInCanvas(
  element: HTMLElement,
  annotation: { handles: { [key: string]: Point } }
) {
  return {
    ...annotation,
    handles: mapValues(annotation.handles, (handle: Point) => ({
      ...handle,
      ...normalizePoint(getEnabledElement(element).canvas, pixelToCanvas(element, handle as any)),
    })),
  };
}
