import usePasswordProtection from 'app/utils/hooks/usePasswordProtection';
import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export default function useGenericSHA256Protection({ headerId, placeholderId, passwordHash }) {
  const intl = useIntl();

  const header = intl.formatMessage({ id: headerId });
  const placeholder = intl.formatMessage({ id: placeholderId });
  const passwordValidationFn = useCallback(
    (input) => sha256(input).toString(CryptoJS.enc.Hex) === passwordHash,
    [passwordHash]
  );

  return usePasswordProtection({ header, placeholder, passwordValidationFn });
}
