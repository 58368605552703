import { fromJS } from 'immutable';
import { Store } from 'redux';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { ANNOUNCEMENTS_SET_TELERADIOLOGY_SEEN } from 'app/redux/announcements/actions';
import observeStore from 'app/utils/redux/observeStore';

export const ANNOUNCEMENTS_LOCAL_STORAGE_KEY = 'redux.announcements';

const getAnnouncementsInitialState = () =>
  fromJS({
    teleradiologySeen: false,
    ...restoreFromLocalStorage(ANNOUNCEMENTS_LOCAL_STORAGE_KEY),
  });

type SetTeleradiologySeen = { isSeen: boolean };

type Action = {
  type: typeof ANNOUNCEMENTS_SET_TELERADIOLOGY_SEEN;
} & SetTeleradiologySeen;

const announcementsReducer = (state = getAnnouncementsInitialState(), action: Action) => {
  switch (action.type) {
    case ANNOUNCEMENTS_SET_TELERADIOLOGY_SEEN:
      return state.set('teleradiologySeen', action.isSeen);
    default:
      return state;
  }
};

export const selectAnnouncements = (state: any) => state.get('announcements');
export const selectAnnouncementsTeleradiologySeen = (state: any): boolean =>
  selectAnnouncements(state).get('teleradiologySeen');

export const attachAnnouncementsWithLocalStorageSynchronizationObserver = (store: Store<any>) => {
  observeStore(
    store,
    selectAnnouncements,
    makeSyncReduxWithLocalStorageObserver(ANNOUNCEMENTS_LOCAL_STORAGE_KEY)
  );
};

export default announcementsReducer;
