import * as csm from 'cornerstone-math';

export default (femoralHeadCenter, femoralHeadRadius, acetabularCavityCenter) => {
  if (
    femoralHeadCenter === undefined ||
    femoralHeadRadius === undefined ||
    acetabularCavityCenter === undefined
  )
    return undefined;
  try {
    const distractionIndex =
      csm.point.distance(femoralHeadCenter, acetabularCavityCenter) / femoralHeadRadius;
    if (Number.isNaN(distractionIndex)) return undefined;
    return distractionIndex;
  } catch {
    return undefined;
  }
};
