import React from 'react';
import * as PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import './style.css';
import 'app/styles/style.scss';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { capitalizeWords } from '../../utils/stringUtils';

class Pattern extends React.Component {
  constructor(props) {
    super(props);

    this.THRESHOLD_FOR_SECURE = 0.75;
    this.THRESHOLD_FOR_POSITIVE = 0.5;
    this.THRESHOLD_FOR_DOUBTFUL = 0.25;
    this.hiddenPatterns = ['chien', 'chat', 'face'];
    this.state = {
      feedback: props.feedback,
      confidence: props.confidence,
      patternName: props.patternName,
      showFeedbackButtons: props.showFeedbackButtons,
      showIcon: props.showIcon,
    };
  }

  static getDerivedStateFromProps = (props, state) => ({
    feedback: props.feedback,
    confidence: props.confidence,
    patternName: props.patternName,
    showFeedbackButtons: props.showFeedbackButtons,
    showIcon: props.showIcon,
  });

  getPercentageClassName = () => (typeof this.state.feedback === 'undefined' ? 'detail' : 'hidden');

  getIconClassName = () => {
    switch (this.getPositivity()) {
      case 'positiveFeedback':
        return 'check icon';
      case 'negativeFeedback':
        return 'x icon';
      case 'secure':
        return 'check icon';
      case 'positive':
        return 'question icon';
      case 'doubtful':
        return 'question icon';
      case 'negative':
        return 'x icon';
      case 'hidden':
        return '';
    }
  };

  getSize = () => {
    switch (this.getPositivity()) {
      case 'positiveFeedback':
        return this.props.largeSize;
      case 'negativeFeedback':
        return this.props.largeSize;
      case 'secure':
        return this.props.largeSize;
      case 'positive':
        return this.props.largeSize;
      case 'doubtful':
        return this.props.largeSize;
      case 'negative':
        return this.props.smallSize;
      case 'hidden':
        return '';
    }
  };

  getColor = () => {
    switch (this.getPositivity()) {
      case 'positiveFeedback':
        return 'green';
      case 'negativeFeedback':
        return 'grey';
      case 'secure':
        return 'green';
      case 'positive':
        return 'blue';
      case 'doubtful':
        return 'greyBlue';
      case 'negative':
        return 'grey';
      case 'hidden':
        return '';
    }
  };

  isPositive = () => {
    const positivity = this.getPositivity();
    return positivity === 'positiveFeedback' || positivity === 'positive';
  };

  getPositivity = () => {
    if (typeof this.state.feedback !== 'undefined') {
      if (this.state.feedback) {
        return 'positiveFeedback';
      }
      return 'negativeFeedback';
    }
    if (this.hiddenPatterns.indexOf(this.state.patternName) > -1) {
      // if (this.state.confidence >= this.THRESHOLD_FOR_SECURE) {
      //   return (<span className="ui grey tag label" style={{textTransform: "capitalize"}}>{this.state.patternName}</span>);
      // } else {
      return null;
      // }
    }
    if (this.state.confidence >= this.THRESHOLD_FOR_SECURE) {
      return 'secure';
    }
    if (this.state.confidence >= this.THRESHOLD_FOR_POSITIVE) {
      return 'positive';
    }
    if (this.state.confidence >= this.THRESHOLD_FOR_DOUBTFUL) {
      return 'doubtful';
    }
    return 'negative';
  };

  // giveFeedback = (feedbackValue) => {
  //   this.setState({feedback: feedbackValue, confidence: 1.});
  //   return ApiCalls.sendFeedback(this.state.feedback, this.state.imageId);
  // };

  renderPositiveFeedbackButton = () => {
    if (!this.state.showFeedbackButtons) {
      return null;
    }
    // const isPositive = this.isPositive();
    // const iconClass = isPositive ? " x" : " check";
    return (
      <button onClick={() => this.givePositiveFeedback()} type="button">
        <i className={`ui icon feedback check ${this.props.largeSize} `} />
      </button>
    );
  };

  renderNegativeFeedbackButton = () => {
    if (!this.state.showFeedbackButtons) {
      return null;
    }
    // const isPositive = this.isPositive();
    // const iconClass = isPositive ? " x" : " check";
    return (
      <button onClick={() => this.giveNegativeFeedback()} type="button">
        <i className={`ui icon feedback x ${this.props.largeSize}`} />
      </button>
    );
  };

  givePositiveFeedback = () => {
    this.setState({ confidence: 1, feedback: true });
    this.props.giveFeedback(this.state.patternName, true);
  };

  giveNegativeFeedback = () => {
    this.setState({ confidence: 1, feedback: false });
    this.props.giveFeedback(this.state.patternName, false);
  };

  renderIcon = () => {
    if (this.state.showIcon) {
      return <i className={this.getIconClassName()} />;
    }
    return null;
  };

  getStyle = () => ({
    ...this.props.style,
  });

  renderConfidence = () => {
    if (this.props.hideConfidence) {
      return null;
    }
    return (
      <div className={this.getPercentageClassName()}>
        {Math.round(this.state.confidence * 100)} %
      </div>
    );
  };

  getPattern = () => {
    const { intl } = this.props;
    const { patternName } = this.state;
    const patternText = capitalizeWords(intl.formatMessage({ id: patternName }), true);
    return (
      <span
        className={`ui ${this.getColor()} image label ${this.getSize()} single-pattern`}
        style={this.getStyle()}
      >
        {this.renderIcon()}
        <span className="pattern-text" title={patternText}>
          {patternText}
        </span>
        {this.renderConfidence()}
        <div className="feedback-group">
          {this.renderPositiveFeedbackButton()}
          {this.renderNegativeFeedbackButton()}
        </div>
      </span>
    );
  };

  render() {
    // {this.state.patternName.replace(/_/g, " ")}
    return (
      <Popup
        content={<FormattedMessage id={`pattern.confidence.${this.getPositivity()}`} />}
        trigger={this.getPattern()}
      />
    );
  }
}

Pattern.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

Pattern.defaultProps = {
  showIcon: true,
  largeSize: 'large',
  smallSize: 'medium',
  style: {},
};

function mapStateToProps(state) {
  return {
    route: state.get('route'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Pattern));
