/* eslint-disable no-bitwise */
import * as _ from 'lodash';

import TAG_DICT from './TAG_DICT';

const NON_STRING_VR_LIST = ['AT', 'FL', 'FD', 'OB', 'OF', 'OW', 'SI', 'SQ', 'SS', 'UL', 'US'];
const NUMBER_VR_LIST = ['US', 'SS', 'UL', 'SL', 'FD', 'FL'];

const getImplicitVR = (tag) => {
  if (tag.name === 'PixelData') {
    return 'OW';
  }
  if (tag.tag === '(60xx,3000)') {
    return 'OW';
  }
  if (tag.name === 'WaveformData') {
    return 'OW';
  }
  if (
    tag.name === 'RedPaletteColorLookupTableData' ||
    tag.name === 'GreenPaletteColorLookupTableData' ||
    tag.name === 'BluePaletteColorLookupTableData' ||
    tag.name === 'AlphaPaletteColorLookupTableData'
  ) {
    return 'OW';
  }
  if (
    tag.name === 'SegmentedRedPaletteColorLookupTableData' ||
    tag.name === 'SegmentedGreenPaletteColorLookupTableData' ||
    tag.name === 'SegmentedBluePaletteColorLookupTableData'
  ) {
    return 'OW';
  }
  if (tag.name === 'LUTData') {
    return 'OW';
  }
  if (tag.name === 'LUTDescriptor') {
    // - PS 3.3, C.11.1.1.1: may be heterogeneous, depends on
    //   Pixel Representation
    // - PS 3.3, C.11.2.1.1: may be heterogeneous, depends on
    //   Pixel Representation, Rescale Slope and Rescale Intercept
    // - C.11.4.1: always US
    // - C.11.6.1.1: always US
    // FIXME This is too context-dependent
    return 'UN';
  }
  if (tag.name === 'BlendingLookupTableData') {
    return 'OW';
  }
  if (
    tag.name === 'VertexPointIndexList' ||
    tag.name === 'EdgePointIndexList' ||
    tag.name === 'TrianglePointIndexList' ||
    tag.name === 'PrimitivePointIndexList'
  ) {
    return 'OW';
  }
  return tag.vr;
};

function getTag(tag) {
  const tagGroup = tag.substring(1, 5);
  const tagElement = tag.substring(5, 9);
  const tagIndex = `(${tagGroup},${tagElement})`.toUpperCase();
  const tagName = TAG_DICT[tagIndex];
  return { tagName, tagGroup, tagElement };
}

const isStringVr = (vr) => !NON_STRING_VR_LIST.includes(vr);

const isNumberVR = (vr) => NUMBER_VR_LIST.includes(vr);

const numberElementToValue = (dataSet, element, vr) => {
  const value = [];
  if (vr === 'US') {
    value.push(dataSet.uint16(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 2; i += 1) {
      value.push(dataSet.uint16(element.tag, i));
    }
  } else if (vr === 'SS') {
    value.push(dataSet.int16(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 2; i += 1) {
      value.push(dataSet.int16(element.tag, i));
    }
  } else if (vr === 'UL') {
    value.push(dataSet.uint32(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 4; i += 1) {
      value.push(dataSet.uint32(element.tag, i));
    }
  } else if (vr === 'SL') {
    value.push(dataSet.int32(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 4; i += 1) {
      value.push(dataSet.int32(element.tag, i));
    }
  } else if (vr === 'FD') {
    value.push(dataSet.double(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 8; i += 1) {
      value.push(dataSet.double(element.tag, i));
    }
  } else if (vr === 'FL') {
    value.push(dataSet.float(element.tag));
    for (let i = 1; i < dataSet.elements[element.tag].length / 4; i += 1) {
      value.push(dataSet.float(element.tag, i));
    }
  }
  return value.length === 1 ? value[0] : value;
};

const stringElementToValue = (dataSet, element, vr) => {
  // Next we ask the dataset to give us the element's data in string form.  Most elements are
  // strings but some aren't so we do a quick check to make sure it actually has all ascii
  // characters so we know it is reasonable to display it.
  const str = dataSet.string(element.tag);
  if (vr === 'LT') return str;
  if (str === undefined) return undefined;
  const strings = str.split('\\');
  return strings.length === 1 ? strings[0] : strings;
};

const elementToValue = (dataSet, element, vr) => {
  // Since the dataset might be encoded using implicit transfer syntax and we aren't using
  // a data dictionary, we need some simple logic to figure out what data types these
  // elements might be.  Since the dataset might also be explicit we could be switch on the
  // VR and do a better job on this, perhaps we can do that in another example

  // First we check to see if the element's length is appropriate for a UI or US VR.
  // US is an important type because it is used for the
  // image Rows and Columns so that is why those are assumed over other VR types.
  if (vr === undefined) {
    if (element.length === 2) {
      return dataSet.uint16(element.tag);
    }
    if (element.length === 4) {
      return dataSet.uint32(element.tag);
    }
    return new DataView(dataSet.byteArray.buffer, element.dataOffset, element.length);
  }
  if (isStringVr(vr)) {
    return stringElementToValue(dataSet, element, vr);
  }
  if (isNumberVR(vr)) {
    return numberElementToValue(dataSet, element, vr);
  }
  if (vr === 'OB' || vr === 'OW' || vr === 'UN' || vr === 'OF' || vr === 'UT') {
    return new DataView(dataSet.byteArray.buffer, element.dataOffset, element.length);
  }
  if (vr === 'AT') {
    const group = dataSet.uint16(element.tag, 0);
    const groupHexStr = `0000${group.toString(16)}`.substr(-4);
    const elementValue = dataSet.uint16(element.tag, 1);
    const elementHexStr = `0000${elementValue.toString(16)}`.substr(-4);
    return `${groupHexStr}${elementHexStr}`;
  }
  if (vr === 'SQ') {
    // Should have been parsed when processing items.
  } else {
    // If it is some other length and we have no string
    console.log(`${element.tag} with no display code for VR ${vr}`);
  }
  return undefined;
};

const fragmentsToValue = (dataSet, element, vr) => {
  // First we have to build offset table then we can copy the binary data.
  if (vr === 'OB' || vr === 'OW') {
    const value = { data: [], VR: vr };
    const { basicOffsetTable, fragments } = element;
    const basicOffsetTableRepresentation = new Uint32Array(basicOffsetTable.length);
    // Offset table store uint32 but should be represented as
    // a raw array for 32 bits int.
    basicOffsetTable.forEach((fragmentOffset, index) => {
      basicOffsetTableRepresentation[index] = fragmentOffset;
    });
    value.data.push(basicOffsetTableRepresentation);

    // Then we simply push the fragments one after the other
    fragments.forEach(({ position, length }) => {
      value.data.push(new DataView(dataSet.byteArray.buffer, position, length));
    });
    return value;
  }

  console.error(`Cannot handle fragment ${element} with VR : ${vr}`);
  return undefined;
};

const convertDicomParserDataToDicomData = (dataSet) => {
  const dicomData = {};
  _.forEach(dataSet.elements, (element) => {
    // const isP10Header = element.tag <= 'x0002ffff';
    // const isPrivate = dicomParser.isPrivateTag(element.tag);
    // if (isP10Header || isPrivate) return;
    const { tagName: tag, tagElement } = getTag(element.tag);

    // We ignore group length, they should not be stored
    if (tagElement === '0000') return;

    // We ignore unknown tags because they mess up implementation of writeDicom.
    // TODO(GC) handle unknown tags
    // if (tag === undefined) return;
    const propertyName = tag?.vr ? tag?.name : element.tag.replace(/^x/, '');
    const vr = element.vr ?? (tag ? getImplicitVR(tag) : undefined);

    // Here we check for Sequence items and iterate over them if present.  items will not be set in the
    // element object for elements that don't have SQ VR type.  Note that implicit little endian
    // sequences will are currently not parsed.
    if (element.items) {
      dicomData[propertyName] = _.map(element.items, (item) =>
        convertDicomParserDataToDicomData(item.dataSet)
      );
      return;
    }
    if (element.fragments) {
      dicomData[propertyName] = fragmentsToValue(dataSet, element, vr);
      return;
    }
    // use VR to display the right value
    const isExplicitVR = vr && vr !== tag?.vr;
    const value = elementToValue(dataSet, element, vr);
    if (isExplicitVR) {
      dicomData[propertyName] = { data: value, VR: vr };
    } else {
      dicomData[propertyName] = value;
    }
  });
  return dicomData;
};

export { convertDicomParserDataToDicomData };
export default convertDicomParserDataToDicomData;
