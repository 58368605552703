/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { selectUsername } from 'app/redux/global/reducer';

const SentryUserInfo = () => {
  const username = useSelector(selectUsername);

  useEffect(() => {
    Sentry.setTag('user_mail', username);
  }, [username]);

  return null;
};

export default SentryUserInfo;
