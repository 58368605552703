import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Icon, Popup } from 'semantic-ui-react';
import useAutoFocusRef from 'app/utils/hooks/useAutoFocusRef';

export default function AddTeamSponsorButton({ onNewSponsor }) {
  const [sponsor, setSponsor] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const autoFocusRef = useAutoFocusRef();

  const onClick = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onSubmit = useCallback(() => {
    onNewSponsor(sponsor);
    setOpen(false);
  }, [sponsor, onNewSponsor]);

  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button floated="right" color="green" size="tiny" onClick={onClick}>
          <Icon name="plus" />
          <FormattedMessage id="adminPage.sponsors.add.button" />
        </Button>
      }
      on="click"
      content={
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <label htmlFor="adminPage.sponsors.add.label">
              <FormattedMessage id="adminPage.sponsors.add.label" />
            </label>
            <Form.Input
              id="adminPage.sponsors.add.label"
              icon="barcode"
              iconPosition="left"
              value={sponsor}
              onChange={(e, { value }) => setSponsor(value)}
              ref={autoFocusRef}
            />
          </Form.Field>
          <Button positive type="submit">
            <Icon name="plus" />
            <FormattedMessage id="general.add" />
          </Button>
        </Form>
      }
    />
  );
}
