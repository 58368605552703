import React, { useState } from 'react';

import CodecModuleContext from 'app/providers/CodecModuleProvider/context';
import createCodecModuleWithWorker from 'app/encoders/byTransferSyntax';

// eslint-disable-next-line react/prop-types
export default function CodecModuleProvider({ children }: JSX.ElementChildrenAttribute) {
  const [codecModule] = useState(() => createCodecModuleWithWorker());

  return <CodecModuleContext.Provider value={codecModule}>{children}</CodecModuleContext.Provider>;
}
