import './styles.scss';

import React from 'react';
import ImagesSelector, { ImageSelectorProps } from 'app/components/ImagesSelector';
import StaticImageRenderer, { ImageRendererProps } from 'app/components/StaticImageRenderer';
import { SyncStatusEnum } from 'app/components/SyncStatus';
import ImageUploadStatus from 'app/containers/Teleradiology/ImageUploadStatus';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type ImageUploadRendererProps = ImageRendererProps & { status?: SyncStatusEnum; dicomData?: any };

function ImageUploadRenderer(
  { status, dicomData, ...renderProps }: ImageUploadRendererProps,
  index: number
) {
  return (
    <div className="teleradiology-image-upload__image">
      <StaticImageRenderer {...renderProps} />
      {status && (
        <div className="teleradiology-image-upload__image__status">
          <ImageUploadStatus status={status} />
        </div>
      )}
      {!dicomData && (
        <div className="teleradiology-image-upload__image__warning-status">
          <Popup
            trigger={
              <Icon
                name="warning sign"
                color="yellow"
                data-testid={'teleradiology.analyze-request.images.warning.not-dicom'}
              />
            }
            content={
              <FormattedMessage id="teleradiology.analyze-request.images.warning.not-dicom" />
            }
          />
        </div>
      )}
    </div>
  );
}

export type ImagesUploadSelectorProps = ImageSelectorProps & {
  images: { status?: SyncStatusEnum }[];
};

export default function ImagesUploadSelector(props: ImagesUploadSelectorProps) {
  const imageRender = (renderProps: ImageRendererProps, index: number) => (
    <ImageUploadRenderer
      {...renderProps}
      status={props.images[index]?.status}
      dicomData={props.images[index]?.dicomData}
    />
  );
  return <ImagesSelector {...props} imageRender={imageRender} />;
}
