import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import * as PropTypes from 'prop-types';

import {browserHistory} from 'react-router';
import {FormattedMessage} from 'react-intl';


export class PaymentCanceledPage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    // this.state = {
    // };
  };

  // componentDidMount = () => {
    // setTimeout(function () {
    //   browserHistory.push("/online");
    // }, 9500);
  // }

  render() {
    return (
      <div style={{marginTop: "10%", textAlign: "center"}}>
        <h1><FormattedMessage id="paymentCanceled.title"/></h1>
        <span style={{fontSize: "2em"}}><FormattedMessage id="paymentCanceled.canceled"/></span>
        <br/>
        <a style={{fontSize: "2em"}} onClick={browserHistory.push("/purchase")}><FormattedMessage id="paymentCanceled.backToBilling"/></a>
      </div>
    )
  }
}

PaymentCanceledPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};


const mapStateToProps = createStructuredSelector({});


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCanceledPage);
