export const ANNOTATION_SAVE_DELAY = 2000;
export const INFERENCE_GUARD_TIMEOUT = 10000;
export const NON_ANNOTATIONS_TOOLS = [
  'ZoomMouseWheel',
  'FineGrainZoomMouseWheel',
  'ZoomTouchPinch',
  'Pan',
  'PanWheel',
  'Eraser',
  'WWWC',
  'Crop',
  'Magnify',
  'PanMultiTouch',
  'WWWCRightClick',
  'LeftMarker',
  'RightMarker',
];
