/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
// Remove those 2 eslint disable
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */

/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import 'app/styles/style.scss';
import 'app/components/Dropzone/style.scss';
import './style.scss';

import produce from 'immer';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import React, { BaseSyntheticEvent, createRef, useContext, useEffect } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Confirm, Dimmer, Divider, Icon, Loader, Popup } from 'semantic-ui-react';

import EditAnatomicRegionModal from 'app/components/Dropzone/EditAnatomicRegionModal';
import MeasurementTools from 'app/components/Dropzone/MeasurementTools';
import PatientInfoModal from 'app/components/Dropzone/PatientInfoModal';
import { UPPER_TOOLS_BAR_TOOLS_LISTS } from 'app/components/Dropzone/constants';
import { onFullSizeConfigurationToggle } from 'app/components/Dropzone/effects';
import { createCommonToolsList } from 'app/components/Dropzone/utils';
import EditableReport from 'app/components/EditableReport';
import {
  FlatPanelState,
  FlatPanelStateContext,
  getCurrentDetector,
  getCurrentDetectorState,
} from 'app/components/FlatPanelStateProvider';
import PatientInfoBar from 'app/components/PatientInfoBar';
import ToolsBar from 'app/components/ToolsBar';
import FullSizeConfigurationButton from 'app/components/ToolsBar/FullSizeConfigurationButton';
import imagesReducer, {
  Action as ReducerAction,
  acquisitionAddWorkListImage,
  acquisitionLoadWorkListImage,
  acquisitionReceiveAcquiredImage,
  acquisitionRedoImage,
  acquisitionUpdateAcquisitionConstants,
  acquisitionUpdateAnatomicRegion,
  negatoChangeMode,
  displayableMetadataUpdate,
  feedbackUpdate,
  imageCacheInitialContent,
  imageDelete,
  imageLoadFailure,
  imageLoadStart,
  imageLoadSuccess,
  imageProcessingDone,
  imageProcessingStart,
  imageSelect,
  imageSelectNextWorklist,
  imageUpdateProcessingOptions,
  imagesUpdatePatientSpecie,
  pacsEndSync,
  pacsStartSync,
  pacsSyncNeeded,
  predictionsLoadFailure,
  predictionsLoadStart,
  predictionsLoadSuccess,
  predictionsSetActiveRegion,
  negatoSelectView,
  toolsChangeAnnotationsVisibility,
  toolsRemoveAnnotation,
  toolsUpdateAnnotation,
  toolsUpdateStates,
  toolsUpdateViewport,
  imageSetIsRawDataSaved,
  toolsConfirmRealSizeCalibration,
  toolsCancelRealSizeCalibration,
  toolsResetRealSizeCalibration,
  toolsSwitchRealSizeCalibration,
} from 'app/components/Viewer/reducer';
import { rejectImageDeletionEffect } from 'app/components/Viewer/useImagesEffect';
import WhatImagesToSend from 'app/components/WhatImagesToSend';
import ObjectIdGenerator from 'app/interfaces/ObjectIdGenerator';
import { selectViewerConfiguration } from 'app/redux/reducers';
import { updateFullSize } from 'app/redux/viewerConfiguration/actions';
import createDeferredPromise from 'app/utils/createDeferredPromise';
import { getDicomDataValue, getPixelSpacing, getXRayDetectorID } from 'app/utils/dicom/DicomData';
import PredictionsDisplay from './PredictionsDisplay';

import { isVisibleToolState } from 'app/CornerstoneTools/constants';
import { DataLossGuardConnectorContext } from '../DataLossGuard/DataLossGuardConnectorContext';
import CountDown from 'app/components/Dropzone/CountDown';
import ExportStudyModal from 'app/components/Dropzone/ExportStudyModal';
import ExamIcon from 'app/components/ExamIcon';
import FileWatcher from 'app/components/FileWatcher';
import FlatPanelList from 'app/components/FlatPanelList';
import FoldableAIPanel from 'app/components/FoldableAIPanel';
import ImageToPACSSyncStatus from 'app/components/ImageToPACSSyncStatus';
import { PACSCommunicationContext } from 'app/components/PACSCommunicationProvider';
import Snipper from 'app/components/Snipper';
import UseEffect from 'app/hooks/components/UseEffect';
import UseImagesEffect from 'app/hooks/components/UseImagesEffect';
import UserDisplayableError from 'app/interfaces/UserDisplayableError';
import xray from 'app/native/node-addons/xray';
import {
  isPACSConfigurationDisabled,
  selectPACSConfiguration,
} from 'app/redux/PACSConfiguration/reducer';
import { DETECTOR_EVENTS } from 'app/types/xray';
import MaxDurationPromise, { PromiseTimeoutError } from 'app/utils/MaxDurationPromise';
import PROCESSING_OPTIONS from 'app/utils/PROCESSING_OPTIONS';
import { computeScaleFactorFromStoredViewport } from 'app/utils/cornerstone/imageUtils';
import logger from 'app/utils/debug/logger';
import extractAcquisitionDataFromDicomData from 'app/utils/dicom/extractAcquisitionDataFromDicomData';
import makeDicomFromAcquisitionDetails from 'app/utils/dicom/makeDicomFromAcquisitionDetails';
import convertDisplayableImageDataToDataImage from 'app/utils/image/convertDisplayableImageDataToDataImage';
import getProcessingKeyFromInfo from 'app/utils/imageProcessing/getProcessingKeyFromInfo';
import isDataImageProcessable from 'app/utils/imageProcessing/isDataImageProcessable';
import { isAcquisitionImage } from 'app/utils/isAcquisitionImage';
import isWorkListImage from 'app/utils/isWorkListImage';
import loadDemoImages from 'app/utils/loadDemoImages';
import { DEFAULT_SPECIE } from 'app/constants/species';
import { getProcessingFromAnatomicRegion } from 'app/utils/xrayRegions';
import { ANNOTATION_SAVE_DELAY, INFERENCE_GUARD_TIMEOUT } from './constants';
import XRayAreaView from 'app/components/XRayAreaView';
import XRayGeneratorStatus from 'app/components/XRayGeneratorStatus';
import { PMSExportContext } from 'app/providers/PMSIntegration/PMSExportProvider';
import disableAllComponentMethodsOnUnmount from 'app/utils/react/disableAllComponentMethodsOnUnmount';
import { UserInputMappingState, selectDicomMapping } from 'app/redux/userInputMapping/reducer';
import ButtonStudySave from 'app/components/Dropzone/ButtonStudySave';
import {
  CompatDropzoneImage,
  CompatDropzoneStudy,
  RawImageSaveData,
  ViewerImage,
  ViewerImages,
} from 'app/components/Viewer/types';
import { ImageLoader, ImagePath, LoadableImageFile, LoadedImage } from 'app/interfaces/ImageLoader';
import { ImageRenderer } from 'app/interfaces/ImageRenderer';
import { ToolsStates } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';
import { StudyStore } from 'app/interfaces/StudyStore';
import { Patient } from 'app/interfaces/Patient';
import { DataImage } from 'app/interfaces/DataImage';
import {
  AcquisitionConstants,
  CropAnnotation,
  ImageAnnotations,
  Viewport,
  XRayImage,
} from 'app/interfaces/Image';
import { Iterable } from 'immutable';
import IDicomBuilder, { EncodedTransferSyntaxes } from 'app/interfaces/IDicomBuilder';
import DicomBuilderContext from 'app/providers/DicomBuilder/context';
import { ExportImageToPMSFn } from 'app/interfaces/PMSExporter';
import { DicomData, DicomTransferSyntax } from 'app/interfaces/Dicom';
import { selectAiOnlyConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import AddXRayViewModal from 'app/components/Dropzone/AddXRayViewModal';
import { IReportGenerator } from 'app/interfaces/IReportGenerator';
import PACSImagesSelector from 'app/components/Dropzone/PACSImagesSelector';
import { formatViewerImagesToSelectableImages } from 'app/components/Dropzone/formatStudyForExportModal';
import { TaskHandler, TaskScheduler } from 'app/utils/async/TaskScheduler';
import ParallelTaskScheduler from 'app/utils/async/ParallelTaskScheduler';
import DataSaveEffect from 'app/components/DataSaveEffect';
import { MainImageRenderer } from 'app/components/MainImageRenderer';
import computeDisplayedImageMetadata from 'app/components/Viewer/computeDisplayedImageMetadata';
import { ThumbnailImageRenderer } from 'app/components/ThumbnailImageRenderer';
import { convertRectToCropHandles } from 'app/CornerstoneTools/CropTool';
import { IDisplayableImageEncoder } from 'app/interfaces/IDisplayableImageEncoder';
import {
  convertCropAnnotationToCropRect,
  convertCropAnnotationsToCropRect,
} from './convertCropAnnotationsToCropRect';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';
import NegatoButton, { NegatoMode, NEGATO_MODE_INFO } from 'app/components/ToolsBar/NegatoButton';
import { AllPredictions, ValidPredictions } from 'app/interfaces/Predictions';
import TeleradiologyPanel from 'app/containers/Teleradiology/Panel';
import formatStudyImagesForTeleradiology from 'app/components/Viewer/formatStudyImagesForTeleradiology';
import { isFullResolutionDicom } from './isFullResolutionDicom';
import { ImagesType as PMSImagesType, PMSExportFn } from 'app/pms/exporter/PmsExporter';
import ToolValidationOverlay from 'app/components/Dropzone/ToolValidationOverlay';
import XRayOperatorSelector from 'app/components/XRayOperatorSelector';
import RealSizeMeasurementConfigurationButton from 'app/components/ToolsBar/RealSizeMeasurementConfigurationButton';
import convertDataImageToDisplayableImageData from 'app/utils/image/convertDataImageToDisplayableImageData';
import { generateRawUint16ImageId } from 'app/utils/cornerstone/loadImage';
import ComputationCache from 'app/utils/cache/ComputationCache';
import { IImageProcessor, ProcessingKind, ProcessingOptions } from 'app/interfaces/IImageProcessor';
import { PACSCommunication } from 'app/interfaces/PACSCommunication';

const findFirstLoadErrorIndex = (images: ViewerImages) => _.findKey(images, 'loadError');

type LoadImagePromise<R> = Promise<R> & { load?: () => Promise<R> };
const createLoadImagePromise = <R extends unknown>(
  loadFn: () => Promise<R>
): LoadImagePromise<R> => {
  const deferredLoad: LoadImagePromise<R> & DeferredPromise<R> = createDeferredPromise();
  deferredLoad.load = async () => loadFn().then(deferredLoad.resolve).catch(deferredLoad.reject);

  return deferredLoad;
};

type LoadingPromisesRegistry = {
  [imageId: string]: LoadImagePromise<LoadedImage>;
};

const isProcessingForImageNeeded = (loadedImage: LoadedImage, imageDescriptor: ViewerImage) => {
  const processingType = imageDescriptor?.imageMetadata?.processingType;

  const { imageData } = loadedImage;
  const { getPixelData, color } = imageData ?? {};
  const pixelData = getPixelData?.();

  if (!pixelData) return false;
  if (color) return false;
  if (!(pixelData instanceof Uint16Array)) return false;
  if (processingType === undefined) return false;

  return true;
};

type ProcessingCache = ComputationCache<[DataImage, ProcessingOptions], Promise<DataImage>>;

type CachedProcessFunction = (
  savedStudyId: string,
  imageId: string,
  rawImage: DataImage,
  options: ProcessingOptions
) => Promise<DataImage>;

const processLoadingImage = async (
  studyId: string,
  imageId: string,
  loadedImage: LoadedImage,
  imageDescriptor: ViewerImage,
  processWithCache: CachedProcessFunction,
  processingCache: ProcessingCache
) => {
  const processingType = imageDescriptor?.imageMetadata?.processingType;

  const { imageData, processedImage: preProcessedImage, dicomData } = loadedImage;
  const { width, height } = imageData ?? {};

  let cropRect;
  const { annotations = {} } = imageDescriptor;

  const [cropAnnotationKey] = Object.keys(annotations?.Crop ?? {});
  if (cropAnnotationKey) {
    const { scaleFactor } = computeScaleFactorFromStoredViewport(imageDescriptor.viewport, {
      width,
      height,
    });
    cropRect = convertCropAnnotationsToCropRect(annotations.Crop);
    cropRect = cropRect.map((coord) => coord * scaleFactor);
  }
  const dataImage = convertDisplayableImageDataToDataImage(loadedImage.imageData);
  const processingOptions = {
    ...PROCESSING_OPTIONS[processingType],
    crop_rect: cropRect,
    photometric_interpretation: getDicomDataValue(dicomData, 'PhotometricInterpretation'),
  };
  if (preProcessedImage) {
    processingCache.setCacheEntry([dataImage, processingOptions], preProcessedImage);
    return { processedImage: preProcessedImage, isFromLastProcessing: true };
  }

  const processedImage = await processWithCache(studyId, imageId, dataImage, processingOptions);
  return { processedImage, isFromLastProcessing: false };
};

const convertCropAnnotationToCropRectWithScale = (
  cropAnnotations: ImageAnnotations['Crop'],
  annotationsViewport: Viewport,
  imageDimensions: { width: number; height: number }
) => {
  let cropRect = convertCropAnnotationsToCropRect(cropAnnotations);
  if (!cropRect) return undefined;

  const { scaleFactor } = computeScaleFactorFromStoredViewport(
    annotationsViewport,
    imageDimensions
  );
  cropRect = cropRect.map((coord) => coord * scaleFactor);
  return cropRect;
};

type ProcessOptions = {
  processingType: ProcessingKind;
  cropRect?: number[];
  photometric_interpretation?: string;
};
type ProcessFunction = (
  imageId: string,
  pixelContent: DataImage,
  options: ProcessOptions
) => Promise<void>;

const triggerProcessOnCrop = (
  toolName: string,
  measurementData: CropAnnotation,
  imageId: string,
  pixelContent: DataImage,
  {
    processingType,
    photometric_interpretation,
  }: { processingType: ProcessingKind; photometric_interpretation: string },
  processFn: ProcessFunction
) => {
  if (toolName !== 'Crop') return;
  if (!(pixelContent?.data instanceof Uint16Array)) return;
  if (!processingType) return;

  const cropRect = convertCropAnnotationToCropRect(measurementData);

  processFn(imageId, pixelContent, { processingType, photometric_interpretation, cropRect });
};

type FullSizeValidationButtonsIfOnGoingProps = {
  isFullSizeConfiguration: boolean;
  image: ViewerImage;
  renderer: ImageRenderer;
  switchFullSizeConfiguration: () => void;
};
function FullSizeValidationButtonsIfOnGoing({
  isFullSizeConfiguration,
  image,
  renderer,
  switchFullSizeConfiguration,
}: FullSizeValidationButtonsIfOnGoingProps) {
  const dispatch = useDispatch();

  if (!isFullSizeConfiguration) return null;

  return (
    <ToolValidationOverlay
      onConfirm={() => {
        const detectorId = getXRayDetectorID(image.dicomData);
        const updatedFullSizeScale = renderer.getViewport().scale;
        dispatch(updateFullSize(detectorId, updatedFullSizeScale));
        switchFullSizeConfiguration();
      }}
      onCancel={switchFullSizeConfiguration}
    />
  );
}

function extractVisibleAnnotations(
  annotations: ImageAnnotations,
  toolsStates: ToolsStates
): ImageAnnotations {
  return _.pickBy(annotations, (annotation, annotationName: keyof ToolsStates) =>
    isVisibleToolState(toolsStates[annotationName]?.state)
  );
}

const preventDefault = (e: BaseSyntheticEvent) => e.preventDefault();

const makeDicomImageFromDetailsAndViewport = ({
  studyId,
  image,
  imageId,
  patient,
  imagesOrder,
  intl,
  dicomBuilder,
  transferSyntax,
  forceUniqueID = false,
  includeMetadata = false,
  includeAnnotations = false,
}: {
  studyId: string;
  image: ViewerImage;
  imageId: string;
  patient: Patient;
  imagesOrder: string[];
  intl: IntlShape;
  dicomBuilder: IDicomBuilder;
  transferSyntax?: EncodedTransferSyntaxes;
  forceUniqueID?: boolean;
  includeMetadata?: boolean;
  includeAnnotations?: boolean;
}) => {
  const { displayableImage, acquisitionTime } = image;
  const { anatomicRegion, detectorInfo, viewport, annotations, acquisitionConstants } = image;

  const dicomData = makeDicomFromAcquisitionDetails({
    intl,
    studyId,
    imageId,
    imageIndex: imagesOrder.indexOf(imageId),
    acquisitionTime,
    acquisitionConstants,
    anatomicRegion,
    detectorInfo,
    patient,
    dicomData: image.dicomData,
    forceUniqueID,
  });

  let crop;
  const cropRect = convertCropAnnotationsToCropRect(annotations?.Crop);
  if (cropRect) {
    const [x, y, width, height] = cropRect;
    crop = { x, y, width, height };
  }

  const { invert, rotation, hflip, vflip, voi } = viewport ?? {};

  return dicomBuilder.injectProcessedImageData(
    dicomData,
    convertDisplayableImageDataToDataImage(displayableImage),
    { invert, crop, hflip, vflip, rotation },
    voi,
    transferSyntax,
    {
      annotations: includeAnnotations
        ? extractVisibleAnnotations(image.annotations, image.toolsList)
        : undefined,
      metadata: includeMetadata ? image.displayedMetadata : undefined,
    }
  );
};

const doesProcessingTypeMatchProcessingKey = (processingType: string, processingKey: string) =>
  processingType?.includes(processingKey) ||
  (processingType?.includes('skull') && processingKey === 'bones');

const IMPORTED_IMAGE_TYPES = ['automaticExport', 'commandLine'];

const isImportedImage = ({ origin }: { origin?: string } = {}) =>
  IMPORTED_IMAGE_TYPES.includes(origin);

export type ViewerProps = {
  imageLoader: ImageLoader;
  imageProcessor: IImageProcessor;
  objectIdGenerator: ObjectIdGenerator;
  reportGenerator: IReportGenerator;
  studyStore: StudyStore;
  patientStore: PatientStore;
  inferenceExecutor: InferenceExecutor;
  initialStudyId?: string;
  imagePaths?: ImagePath[];
  importedImages?: ImagePath[];
  initialPatientInfo?: Patient;
  mode?: 'acquisition';
  history?: any; // BrowserHistory compatible
  onImagePathsAcknowledged?: (imagePaths: ImagePath[]) => void;
  onImportedImagesFromAnotherStudy: (imageFiles: LoadableImageFile[]) => void;
  hideHeader: (shouldHideHeader: boolean) => void;
};

export type ViewerImplProps = ViewerProps & {
  intl: IntlShape;
  // Context
  viewerConfiguration: Iterable<string, any>;
  imageEncoder: IDisplayableImageEncoder;
  dicomBuilder: IDicomBuilder;
  exportToPMS?: PMSExportFn;
  dataLossGuard?: DataLossGuardConnector;
  pacsCommunication?: PACSCommunication;
  PACSConfiguration?: Iterable<unknown, unknown>;
  dicomMapping?: UserInputMappingState['dicomMapping'];
  flatPanelState?: FlatPanelState;
  aiOnlyConfiguration: Map<string, any>;
};

export type ReportState = {
  comment: string;
  isCommentDirty: boolean;
};

export type ViewerState = {
  studyId: string;
  creationDate: Date;
  images: ViewerImages;
  imagesOrder: string[];
  isFullScreen: boolean;
  isFullSizeConfiguration: boolean;
  showAnnotations: boolean;
  acquisitionCountDown?: number;
  patient?: Patient;
  pms_id?: string;
  studyLoadFailedConfirmOpen: boolean;
  commonToolsList: ToolsStates;
  negatoMode: NegatoMode;
  selectedNegatoView: number;
} & ReportState;

const isProcessingCacheKeysEquals = (
  [image1, options1]: [DataImage, ProcessingOptions],
  [image2, options2]: [DataImage, ProcessingOptions]
) => {
  return image1.data === image2.data && _.isEqual(options1, options2);
};

class ViewerImpl extends React.Component<ViewerImplProps, ViewerState> {
  private imageLoadingScheduler: TaskScheduler;
  private imagesLoadingHandlers: { [imageId: string]: TaskHandler } = {};
  private fileInputRef: React.RefObject<HTMLInputElement>;
  private renderers: ImageRenderer[] = [];
  private imageLoadingPromises: LoadingPromisesRegistry = {};
  private acquisitionIntervalRef: number | undefined = undefined;
  private lastSavedState: {
    report?: ReportState;
  } = {};
  private pendingImageFiles: [string, LoadableImageFile][] = [];
  private isAutoImportNewStudyChangePending: boolean = false;
  private memoizedCalls: { [memoizationKey: string]: any } = {};
  private processingCache;

  constructor(props: ViewerImplProps) {
    super(props);
    const { objectIdGenerator, intl } = this.props;

    this.state = {
      images: {},
      imagesOrder: [],
      isFullScreen: false,
      isFullSizeConfiguration: false,
      showAnnotations: true,
      acquisitionCountDown: undefined,
      patient: { specie: DEFAULT_SPECIE },
      studyId: objectIdGenerator.create(),
      creationDate: new Date(),
      comment: '',
      pms_id: undefined,
      isCommentDirty: false,
      studyLoadFailedConfirmOpen: false,
      commonToolsList: createCommonToolsList(() => intl),
      negatoMode: NegatoMode.SINGLE,
      selectedNegatoView: 0,
    };

    this.imageLoadingScheduler = new ParallelTaskScheduler(3);
    this.fileInputRef = createRef();
    this.processingCache = new ComputationCache(
      (rawImage: DataImage, options: ProcessingOptions) =>
        this.props.imageProcessor.process(rawImage, options),
      isProcessingCacheKeysEquals
    );
  }

  componentDidMount() {
    const { initialPatientInfo, mode, initialStudyId, objectIdGenerator, patientStore } =
      this.props;
    if (initialPatientInfo) {
      if (initialPatientInfo._id) {
        patientStore
          .getPatient(initialPatientInfo._id)
          .then((animal: Patient) => this.linkPatientToStudy({ animal }))
          .catch((e: any) => logger.warn('patient retrieval failed', e));
      } else {
        this.linkPatientToStudy({ animal: { ...initialPatientInfo, specie: DEFAULT_SPECIE } });
      }
    }
    if (initialStudyId) return;
    if (mode === 'acquisition') {
      this.dispatch(acquisitionAddWorkListImage(objectIdGenerator.create()));
    }
  }

  componentWillUnmount() {
    disableAllComponentMethodsOnUnmount(this);
  }

  /* Getters */
  get currentImageId() {
    return this.useMemo('currentImageId', () => _.findKey(this.state.images, 'isSelected'), [
      this.state.images,
    ]);
  }

  get currentImage() {
    return this.state.images[this.currentImageId];
  }

  get isAnyImagePresent() {
    return this.useMemo(
      'isAnyImagePresent',
      () =>
        _.some(
          this.state.images,
          (image) =>
            !!image.displayableImage ||
            image.isImageLoading ||
            image.loadError ||
            isWorkListImage(image) ||
            isAcquisitionImage(image)
        ),
      [this.state.images]
    );
  }

  get isAnyImageLoaded() {
    return _.some(this.state.images, (image) => !!image);
  }

  get currentImageToolsList() {
    return this.useMemo(
      'getCurrentImageToolsList',
      () =>
        this.currentImage?.toolsList && {
          ...this.state.commonToolsList,
          ...this.currentImage.toolsList,
        },
      [this.currentImage?.toolsList, this.state.commonToolsList]
    );
  }

  get toolsProps() {
    return this.useMemo(
      'toolsProps',
      () => {
        const { intl, viewerConfiguration } = this.props;
        const { isFullScreen, patient, isFullSizeConfiguration, negatoMode } = this.state;
        const { currentImageId, currentImage } = this;
        const { isRealSizeMeasurementCalibration } = currentImage ?? {};
        const currentProcessingType = currentImage?.imageMetadata?.processingType;

        const isCurrentImageProcessable = isDataImageProcessable(currentImage?.initialDataImage);
        const fullSizeScale = viewerConfiguration.getIn([
          'fullSize',
          getXRayDetectorID(currentImage?.dicomData),
        ]);

        const processLoadedImageByType = (processingKey: string) => {
          const processingType = getProcessingKeyFromInfo(processingKey, {
            specie: patient?.specie ?? DEFAULT_SPECIE,
            anatomicRegion: currentImage?.anatomicRegion,
          });
          if (currentImage?.imageMetadata?.processingType === processingType) return;

          this.processLoadedImage(currentImageId, currentImage?.initialDataImage, {
            // anatomicRegion: currentImage?.anatomicRegion,
            photometric_interpretation: currentImage?.photometric_interpretation,
            processingType,
            cropRect: convertCropAnnotationToCropRectWithScale(
              currentImage.annotations?.Crop,
              currentImage?.viewport,
              currentImage?.displayableImage
            ),
          });
        };

        const isFullSizeConfigurable = getXRayDetectorID(currentImage?.dicomData) !== undefined;

        return {
          FullScreen: { isFullScreen, switchFullScreen: this.switchFullScreen },
          PicoxiaAnalysis: {
            isImageModified: currentImage?.isImageNewPredictionsNeeded,
            onClick: this.refreshInferenceOnCurrentImage,
          },
          FullSizeConfiguration: {
            content: (
              <FullSizeConfigurationButton
                applyFullSize={() => {
                  if (!fullSizeScale) return false;
                  this.dispatch(toolsUpdateViewport(this.currentImageId, { scale: fullSizeScale }));
                  return true;
                }}
                isFullSizeConfigurationOngoing={isFullSizeConfiguration}
                toggleFullSizeConfiguration={this.switchFullSizeConfiguration}
                hidden={!isFullSizeConfigurable}
              />
            ),
          },
          RealSizeMeasurementCalibration: {
            content: (
              <RealSizeMeasurementConfigurationButton
                resetRealSizeMeasurement={() =>
                  this.dispatch(toolsResetRealSizeCalibration(currentImageId))
                }
                isRealSizeMeasurementConfigurationOngoing={isRealSizeMeasurementCalibration}
                toggleRealSizeMeasurementConfiguration={() =>
                  this.switchRealSizeMeasurementCalibration(currentImageId)
                }
              />
            ),
          },
          Negato: {
            content: (
              <NegatoButton
                currentMode={negatoMode}
                onNegatoSelect={(mode) => this.dispatch(negatoChangeMode(mode))}
              />
            ),
          },
          Processing: {
            isShown: isCurrentImageProcessable,
            processingList: {
              default: {
                label: intl.formatMessage({ id: 'tools.default_processing.label' }),
                callback: () => processLoadedImageByType('default'),
                isCurrentProcessing: doesProcessingTypeMatchProcessingKey(
                  currentProcessingType,
                  'default'
                ),
                processedImage: undefined,
              },
              abdomen: {
                label: intl.formatMessage({ id: 'tools.abdomen_processing.label' }),
                callback: () => processLoadedImageByType('abdomen'),
                isCurrentProcessing: doesProcessingTypeMatchProcessingKey(
                  currentProcessingType,
                  'abdomen'
                ),
                processedImage: undefined,
              },
              bones: {
                label: intl.formatMessage({ id: 'tools.bones_processing.label' }),
                callback: () => processLoadedImageByType('bones'),
                isCurrentProcessing: doesProcessingTypeMatchProcessingKey(
                  currentProcessingType,
                  'bones'
                ),
                processedImage: undefined,
              },
              thorax: {
                label: intl.formatMessage({ id: 'tools.thorax_processing.label' }),
                callback: () => processLoadedImageByType('thorax'),
                isCurrentProcessing: doesProcessingTypeMatchProcessingKey(
                  currentProcessingType,
                  'thorax'
                ),
                processedImage: undefined,
              },
            },
          },
        };
      },
      [
        this.props.intl,
        this.props.viewerConfiguration,
        this.state.isFullScreen,
        this.state.patient,
        this.state.negatoMode,
        this.state.isFullSizeConfiguration,
        this.currentImageId,
        this.currentImage,
      ]
    );
  }

  getImageIndex(imageId: string) {
    const { imagesOrder } = this.state;
    return imagesOrder.indexOf(imageId);
  }
  /* Getters end */

  // Reducer dispatcher
  dispatch = (action: ReducerAction) =>
    this.setState(
      // eslint-disable-next-line react/destructuring-assignment
      (draftState) => imagesReducer(draftState, action, { current: [this.props.intl] })
    );

  /* Helper functions */

  useMemo = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    fnName: string,
    callback: T,
    deps: any[]
  ): ReturnType<T> => {
    const memoizationName = `${fnName}_memo`;
    this.memoizedCalls[memoizationName] ??= memoizeOne((...args: Parameters<T>) =>
      callback(...args)
    );
    return this.memoizedCalls[memoizationName](...deps);
  };

  /* Start state setters */

  switchFullScreen = () => this.setState(({ isFullScreen }) => ({ isFullScreen: !isFullScreen }));

  switchFullSizeConfiguration = () =>
    this.setState(({ isFullSizeConfiguration }) => ({
      isFullSizeConfiguration: !isFullSizeConfiguration,
    }));

  switchRealSizeMeasurementCalibration = (imageId: string, isOn?: boolean) => {
    this.dispatch(toolsSwitchRealSizeCalibration(imageId, isOn));
  };

  confirmRealSizeMeasurementCalibration = () => {
    const { currentImageId } = this;
    this.dispatch(toolsConfirmRealSizeCalibration(currentImageId));
    this.switchRealSizeMeasurementCalibration(currentImageId, false);
  };

  cancelRealSizeMeasurementCalibration = () => {
    const { currentImageId } = this;
    this.dispatch(toolsCancelRealSizeCalibration(currentImageId));
    this.switchRealSizeMeasurementCalibration(currentImageId, false);
  };

  onSwitchShownAnnotationsTools = () =>
    this.setState(({ showAnnotations }) => ({ showAnnotations: !showAnnotations }));

  setAcquisitionCountDown = (acquisitionCountDown: number) =>
    this.setState({ acquisitionCountDown });

  setPatient = (patient: Patient) => this.setState({ patient });

  setStudyId = (studyId: string) => this.setState({ studyId });

  setComment = (comment: string) => this.setState({ comment });

  setIsCommentDirty = (isCommentDirty: boolean) => this.setState({ isCommentDirty });

  setStudyLoadFailedConfirmOpen = (studyLoadFailedConfirmOpen: boolean) =>
    this.setState({ studyLoadFailedConfirmOpen });

  produceCommonToolsList = (update: (toolsList: ToolsStates) => void | ToolsStates) =>
    this.setState(
      produce(({ commonToolsList }) => {
        const updatedCommonToolsList = update(commonToolsList);
        if (!updatedCommonToolsList) return undefined;

        return { commonToolsList: updatedCommonToolsList };
      })
    );

  produceImageToolsList = (updateFn: (toolsList: ToolsStates) => void) => {
    const updatedToolsList = _.cloneDeep(this.currentImage.toolsList);
    updateFn(updatedToolsList);

    this.dispatch(toolsUpdateStates(this.currentImageId, updatedToolsList));
  };

  /* End state setters */

  /* Study functions */

  onStudyFailureModalClose = () => this.setStudyLoadFailedConfirmOpen(false);

  onCommentChange = (modifiedComment: string) => {
    this.setComment(modifiedComment);
    this.setIsCommentDirty(true);
  };

  redoAcquisition = (imageId: string) => {
    this.dispatch(acquisitionRedoImage(this.props.objectIdGenerator.create(), imageId));
  };

  onDeleteImage = (event: React.UIEvent, imageId: string) => {
    event.stopPropagation();

    this.props.studyStore.deleteImage(this.state.studyId, imageId);
    this.dispatch(imageDelete(imageId));
  };

  /* Study functions end */

  /* Image addition function */

  onImageUpload = async (imageFile: LoadableImageFile, isSelected = false, origin = 'upload') => {
    const { images } = this.state;
    const { objectIdGenerator, imageLoader } = this.props;

    const imageId = findFirstLoadErrorIndex(images) ?? objectIdGenerator.create();
    this.pendingImageFiles.push([imageId, imageFile]);
    this.dispatch(imageLoadStart(imageId, { origin, isSelected }));
    this.registerImagesInLoadingQueue({
      imageId,
      loadFn: () => imageLoader.load(imageFile),
    });
  };

  onImagesUpload = (imageFiles: LoadableImageFile[], origin: string) =>
    imageFiles.forEach((imageFile, index) => this.onImageUpload(imageFile, index === 0, origin));

  onSnipImages = (imageFiles: LoadableImageFile[]) => this.onImagesUpload(imageFiles, 'snip');

  onAutoImportImages = (imageFiles: LoadableImageFile[]) =>
    this.onImagesUpload(imageFiles, 'automaticExport');

  onFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const { files } = event.dataTransfer;
    _.times(files.length, (index) => this.onImageUpload(files[index], index === 0));
  };

  onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    _.times(files.length, (index) => this.onImageUpload(files[index], index === 0));
    this.fileInputRef.current.value = '';
  };

  loadImagePaths = () => {
    const { imagePaths, onImagePathsAcknowledged } = this.props;
    if (!imagePaths || imagePaths.length === 0) return;

    this.onImagesUpload([...imagePaths], 'commandLine');
    onImagePathsAcknowledged?.([...imagePaths]);
  };

  loadAutoImportedImages = () => {
    const { importedImages } = this.props;
    if (!importedImages || importedImages.length === 0) return;
    console.log('importedImages', importedImages);
    this.onImagesUpload(importedImages, 'automaticExport');
  };

  triggerFileUpload = () => this.fileInputRef.current.click();

  addWorkListImage = (anatomicRegion: string) => {
    const { objectIdGenerator } = this.props;
    const { specie } = this.state.patient;
    const imageId = objectIdGenerator.create();
    this.dispatch(acquisitionAddWorkListImage(imageId, { anatomicRegion }));
  };

  /* Image upload function end */

  /* Legacy data manipulation */

  makeOldAPIStudy: () => CompatDropzoneStudy = () =>
    this.useMemo(
      'makeOldAPIStudy',
      () => {
        const { intl, dicomBuilder } = this.props;
        const { patient, comment, isCommentDirty, studyId, imagesOrder, images, creationDate } =
          this.state;

        const study: CompatDropzoneStudy = {
          ID: studyId,
          creationDate,
          animal: patient,
          comment,
          isCommentDirty,
          images: [],
        };

        study.images = _(images)
          .omitBy(isWorkListImage)
          .map((image, imageId) => {
            const {
              predictions,
              origin,
              displayableImage,
              annotations,
              viewport,
              toolsList,
              displayedMetadata,
            } = image;

            const oldAPIImage: CompatDropzoneImage = {
              id: imageId,
              backendId: imageId,
              viewport,
              annotations: extractVisibleAnnotations(annotations, toolsList),
              predictions,
              origin,
              image: displayableImage,
              metadata: displayedMetadata,
            };

            if (isFullResolutionDicom(image)) {
              oldAPIImage.computeDicomData = () =>
                makeDicomImageFromDetailsAndViewport({
                  studyId,
                  image,
                  imageId,
                  patient,
                  imagesOrder,
                  intl,
                  dicomBuilder,
                  transferSyntax: undefined,
                  includeAnnotations: true,
                });
            }
            return oldAPIImage;
          })
          .value();

        return study;
      },
      [
        this.props.intl,
        this.props.dicomBuilder,
        this.state.patient,
        this.state.comment,
        this.state.isCommentDirty,
        this.state.studyId,
        this.state.imagesOrder,
        this.state.images,
      ]
    );

  /* Legacy data manipulation end */

  prepareImageForPACSSelection = () =>
    this.useMemo(
      'prepareImageForPACSSelection',
      () => {
        const { images } = this.state;
        const pacsExportableImages = formatViewerImagesToSelectableImages(
          _.map(images, (image, imageId) => ({
            ...image,
            id: imageId,
          }))
        );
        return pacsExportableImages;
      },
      [this.props.dicomBuilder, this.state.images]
    );

  sendSelectedImagesToPACS = (images: ReturnType<typeof this.prepareImageForPACSSelection>) => {
    _.forEach(images, (image) => this.syncImageWithPACS(image.id));
  };

  /* Patient manipulation */

  linkPatientToStudy = ({ animal }: { animal: Patient }) => {
    this.setPatient(animal);
    this.props.studyStore.updatePatient(this.state.studyId, animal);
  };

  /* Patient manipulation end */

  /* Image save functions */

  exportImagesToPMSWithSaveGuard = (exportOnInvocation = true) => {
    const { exportToPMS, intl, dataLossGuard } = this.props;
    const { pms_id, images, patient, studyId, creationDate, comment, isCommentDirty } = this.state;

    if (!exportToPMS) {
      dataLossGuard?.setDataSaveCallback(`pms_export`, undefined);
      return;
    }

    const imagesForPMS = _.filter(images, isAcquisitionImage).map((image) => ({
      ...image,
      annotations: extractVisibleAnnotations(image.annotations, image.toolsList),
    }));

    const exportToPMSContinuableFn = memoizeOne(() =>
      exportToPMS(
        { pms_id, comment, creationDate, isCommentDirty, _id: studyId },
        patient,
        imagesForPMS as PMSImagesType
      ).then((results) => {
        if (_.some(results, { status: 'rejected' })) {
          this.exportImagesToPMSWithSaveGuard(false);
          _.filter(results, { status: 'rejected' }).forEach((result: PromiseRejectedResult) => {
            logger.warn('Failed to export image to PACS: ', result.reason);
          });
          throw new UserDisplayableError(
            intl.formatMessage({ id: 'viewer.unsaved_data.pms_export' })
          );
        }

        dataLossGuard?.setDataSaveCallback(`pms_export`, undefined);
      })
    );

    if (exportOnInvocation) exportToPMSContinuableFn();

    dataLossGuard?.setDataSaveCallback(`pms_export`, () => exportToPMSContinuableFn());
  };

  clearStudy = () => {
    const { history } = this.props;
    const isAcquisitionSoftware = !!xray();
    if (isAcquisitionSoftware) {
      history.push('/acquisition');
    } else {
      history.push('/viewer');
    }
  };

  validateStudy = () => {
    const { images, studyId } = this.state;
    const { studyStore, viewerConfiguration } = this.props;
    const syncToPACSOnValidate: boolean = viewerConfiguration.get('syncToPACSOnValidate');

    _.forEach(images, (image, imageId) => {
      if (isWorkListImage(image)) {
        studyStore.deleteImage(studyId, imageId);
        this.dispatch(imageDelete(imageId));
      }
    });

    if (syncToPACSOnValidate) this.syncWithPACSUsingSaveGuard();
    this.exportImagesToPMSWithSaveGuard();

    this.clearStudy();
  };

  saveImageData = (imageId: string, image: Partial<XRayImage>) =>
    this.props.studyStore
      .updateImage(this.state.studyId, imageId, image)
      .then()
      .catch((e) => {
        const { imagesOrder } = this.state;
        console.error('Error saving image', e);
        throw new UserDisplayableError(
          this.props.intl.formatMessage(
            { id: 'viewer.unsaved_data.image' },
            { index: imagesOrder.indexOf(imageId) + 1 }
          )
        );
      });

  saveRawImage = async ({
    studyId,
    imageId,
    dataImage,
    acquisitionDetails,
    dicomData,
  }: RawImageSaveData & { studyId: string; imageId: string }) => {
    const { studyStore, intl } = this.props;
    const { imagesOrder } = this.state;
    try {
      await studyStore.saveRawImage(studyId, imageId, dataImage, acquisitionDetails, dicomData);
      this.dispatch(imageSetIsRawDataSaved(imageId));
    } catch (error) {
      throw new UserDisplayableError(
        intl.formatMessage(
          { id: 'viewer.unsaved_data.dicom' },
          { index: imagesOrder.indexOf(imageId) + 1 }
        )
      );
    }
  };

  saveLastProcessedImage = (image: ViewerImage, imageId: string) => {
    if (!image.processedImage || image.isFromLastProcessing) return;

    this.props.studyStore.saveLastProcessedImage(this.state.studyId, imageId, image.processedImage);
  };

  /* Report save functions start */

  formatReportState = (): ReportState => ({
    comment: this.state.comment,
    isCommentDirty: this.state.isCommentDirty,
  });

  saveReport = ({ comment, isCommentDirty }: ReportState) =>
    this.props.studyStore.updateReport(this.state.studyId, comment, isCommentDirty).catch(() => {
      throw new UserDisplayableError(
        this.props.intl.formatMessage({ id: 'viewer.unsaved_data.report' })
      );
    });

  /* Report save functions end */

  /* Study save functions end */

  /* Pacs save functions start */

  markAllImageAsOutOfSync = () =>
    _.forEach(this.state.images, (image, imageId) => {
      this.dispatch(pacsSyncNeeded(imageId));
    });

  syncImageWithPACS = async (imageId: string) => {
    const { images, studyId, patient, imagesOrder } = this.state;
    const { studyStore, pacsCommunication, PACSConfiguration, intl, dicomBuilder, dataLossGuard } =
      this.props;
    const image = images[imageId];
    const isPACSEnabled = pacsCommunication && !isPACSConfigurationDisabled(PACSConfiguration);
    if (!isPACSEnabled) throw Error('PACS is not enabled');
    const isCompatibleWithPACS = isAcquisitionImage(image);
    if (!isCompatibleWithPACS) throw Error('Image not compatible with PACS');

    this.dispatch(pacsStartSync(imageId));
    const pacsStoredDicomData = await makeDicomImageFromDetailsAndViewport({
      studyId,
      image,
      imageId,
      patient,
      imagesOrder,
      intl,
      dicomBuilder,
      forceUniqueID: true,
      includeAnnotations: true,
    });

    return pacsCommunication
      .store(pacsStoredDicomData)
      .then(() => {
        this.dispatch(pacsEndSync(imageId));
        dataLossGuard?.setDataSaveCallback(`pacs.${imageId}`, undefined);
        studyStore.updateImage(studyId, imageId, {
          image_metadata: { PACS: { isSync: true } },
        });
      })
      .catch((error) => {
        this.dispatch(
          pacsEndSync(imageId, { syncError: error ?? new Error('PACS store failure') })
        );

        throw error ?? new Error('PACS store failure');
      });
  };

  syncImageWithPACSUsingSaveGuard = (imageId: string, syncOnCall = true) => {
    const { images, studyId, patient, imagesOrder } = this.state;
    const { studyStore, pacsCommunication, PACSConfiguration, intl, dicomBuilder, dataLossGuard } =
      this.props;

    const image = images[imageId];
    const isPACSSyncNeeded =
      pacsCommunication &&
      !isPACSConfigurationDisabled(PACSConfiguration) &&
      isAcquisitionImage(image) &&
      !image?.imageMetadata?.PACS?.isSync;

    if (!isPACSSyncNeeded) {
      dataLossGuard?.setDataSaveCallback(`pacs.${imageId}`, undefined);
      return;
    }

    const syncWithPACSContinuableFn = memoizeOne(async () => {
      return this.syncImageWithPACS(imageId)
        .then(() => {
          dataLossGuard?.setDataSaveCallback(`pacs.${imageId}`, undefined);
        })
        .catch((error) => {
          this.syncImageWithPACSUsingSaveGuard(imageId, false);

          throw new UserDisplayableError(
            intl.formatMessage(
              { id: 'viewer.unsaved_data.pacs' },
              { index: imagesOrder.indexOf(imageId) + 1 }
            )
          );
        });
    });

    if (syncOnCall) syncWithPACSContinuableFn();

    dataLossGuard?.setDataSaveCallback(`pacs.${imageId}`, () => syncWithPACSContinuableFn());
  };

  syncWithPACSUsingSaveGuard = () => {
    const { images } = this.state;

    _.forEach(images, (_image, imageId) => this.syncImageWithPACSUsingSaveGuard(imageId));
  };

  /* PACS save functions end */

  /* Image loading for display */

  isDicomFromAnotherStudy = (dicomData: DicomData) => {
    const { images } = this.state;
    const currentImageStudyInstanceID = getDicomDataValue(dicomData, 'StudyInstanceUID');
    if (!currentImageStudyInstanceID) return false;

    const existingImportedImagesStudyInstanceID = _.reject(
      _.map(images, (image) => getDicomDataValue(image.dicomData, 'StudyInstanceUID')),
      _.isUndefined
    );
    const isAllStudyInstanceIDMatchCurrent = _.every(
      existingImportedImagesStudyInstanceID,
      (studyInstanceID) => studyInstanceID === currentImageStudyInstanceID
    );
    return !isAllStudyInstanceIDMatchCurrent;
  };

  reloadStudyForNewImportedStudy = () => {
    const { dataLossGuard } = this.props;
    const pendingAutomaticImportedImageFiles = this.pendingImageFiles.map(
      ([_imageId, imageFile]) => imageFile
    );
    if (this.isAutoImportNewStudyChangePending) return;

    this.isAutoImportNewStudyChangePending = true;
    dataLossGuard
      .clearComponentWithDataSave()
      .then(() => this.props.onImportedImagesFromAnotherStudy(pendingAutomaticImportedImageFiles))
      .catch(() => {
        this.isAutoImportNewStudyChangePending = false;
      });
  };

  onImageLoaded = async (imageId: string, loadedImage: LoadedImage) => {
    const { studyId, images, patient } = this.state;
    const { studyStore, dicomMapping, aiOnlyConfiguration } = this.props;
    const isAIOnly = aiOnlyConfiguration.get('enabled');

    const { dicomData } = loadedImage;
    if (!images[imageId]) return;

    if (this.isDicomFromAnotherStudy(dicomData)) {
      if (isAIOnly || isImportedImage(images[imageId])) {
        this.reloadStudyForNewImportedStudy();
        this.dispatch(imageDelete(imageId));
        return;
      }
    }
    this.pendingImageFiles = _.reject(
      this.pendingImageFiles,
      ([pendingImageId]) => pendingImageId === imageId
    );

    if (dicomData) {
      const { patient: patientInfo } = extractAcquisitionDataFromDicomData(
        dicomData,
        dicomMapping?.species
      );
      const isPatientNamePresent = patientInfo?.name;
      const isPatientAlreadyExist = patient?.name;

      if (!isPatientAlreadyExist && isPatientNamePresent) {
        this.setPatient(patientInfo);

        studyStore
          .updatePatient(studyId, patientInfo)
          .then((savedPatient) => this.setPatient(savedPatient));
      }
    }
    this.dispatch(imageLoadSuccess(imageId, loadedImage));
  };

  registerImagesInLoadingQueue = (
    ...imagesWithLoadFunction: {
      imageId: string;
      loadFn: () => Promise<LoadedImage>;
    }[]
  ) => {
    imagesWithLoadFunction.forEach(({ imageId, loadFn }) => {
      this.imageLoadingPromises[imageId] = createLoadImagePromise(loadFn);

      this.imageLoadingPromises[imageId]
        .finally(() => {
          this.imagesLoadingHandlers[imageId] = undefined;
        })
        .then(async (loadedImage) => this.onImageLoaded(imageId, loadedImage))
        .catch((error) => {
          logger.error('Image loading error', error);
          this.dispatch(imageLoadFailure(imageId, error));
        });

      this.imagesLoadingHandlers[imageId] = this.imageLoadingScheduler.schedule(
        this.imageLoadingPromises[imageId].load
      );
    });
  };

  onLoadDemo = async () => {
    const { objectIdGenerator, imageLoader } = this.props;

    const demoImages = await Promise.all(loadDemoImages());
    demoImages.forEach((demoImage) => {
      const imageId = objectIdGenerator.create();
      this.dispatch(imageLoadStart(imageId, { origin: 'demo' }));
      this.registerImagesInLoadingQueue({
        imageId,
        loadFn: () => imageLoader.load(demoImage),
      });
    });
  };

  onRendererRef = (mainRenderer: ImageRenderer, negatoViewIndex: number) => {
    const isRefNew = mainRenderer !== this.renderers[negatoViewIndex];
    if (!isRefNew) return;

    this.renderers[negatoViewIndex] = mainRenderer;

    if (!this.renderers[negatoViewIndex]) return;
    mainRenderer.addEventListener(
      'annotation_updated',
      ({ imageId, toolName, measurementData }) => {
        if (toolName === 'Crop') return;
        this.dispatch(toolsUpdateAnnotation(imageId, toolName, measurementData));
      }
    );
    mainRenderer.addEventListener(
      'annotation_completed',
      ({ imageId, toolName, measurementData }) => {
        this.dispatch(toolsUpdateAnnotation(imageId, toolName, measurementData));
        if (toolName === 'Crop') {
          const image = this.state.images[imageId];
          const cropMeasurement = measurementData as CropAnnotation;

          const oldRect = convertCropAnnotationToCropRect(
            image?.annotations?.[toolName]?.[measurementData.uuid]
          );
          const newRect = convertCropAnnotationToCropRect(cropMeasurement);
          if (_.isEqual(oldRect, newRect)) {
            return;
          }
          triggerProcessOnCrop(
            toolName,
            cropMeasurement,
            imageId,
            image.initialDataImage,
            {
              processingType: image.imageMetadata?.processingType,
              photometric_interpretation: image?.photometric_interpretation,
            },
            this.processLoadedImage
          );
        }
      }
    );
    mainRenderer.addEventListener(
      'annotation_removed',
      ({ imageId, toolName, measurementData }) => {
        this.dispatch(toolsRemoveAnnotation(imageId, toolName, measurementData.uuid));
      }
    );
    mainRenderer.addEventListener('viewport_updated', ({ imageId, viewport }) => {
      this.dispatch(toolsUpdateViewport(imageId, viewport));
    });
  };

  /* Image loading for display end */

  /* Image inference */

  computePredictions = (
    imageId: string,
    retrievePredictionsFn: () => Promise<AllPredictions>,
    firstTry: boolean = true
  ) => {
    this.dispatch(predictionsLoadStart(imageId));

    return retrievePredictionsFn()
      .then(async (predictions: ValidPredictions) => {
        // No need for complicated logic since we will only retry once on failure
        if (firstTry && predictions.error) {
          await this.computePredictions(imageId, retrievePredictionsFn, false);
          return;
        }
        this.dispatch(predictionsLoadSuccess(imageId, predictions));
      })
      .catch((error: Error) => {
        this.dispatch(predictionsLoadFailure(imageId, error));
        throw error;
      });
  };

  refreshInference = async (imageId: string) =>
    this.computePredictions(imageId, async () =>
      this.props.imageEncoder
        .toBase64((await this.imageLoadingPromises[imageId]).imageData)
        .then((imageBase64) =>
          this.props.inferenceExecutor.refreshInference({ imageId, image: imageBase64 })
        )
    );

  refreshInferenceOnCurrentImage = async () => {
    await this.refreshInference(this.currentImageId);
  };

  async computeInitialInference(imageId: string) {
    return MaxDurationPromise(
      this.computePredictions(imageId, async () => {
        const { inferenceExecutor, imageEncoder } = this.props;
        const { images, studyId } = this.state;

        return inferenceExecutor.infer({
          studyId,
          imageId,
          filename: images[imageId].filename,
          origin: images[imageId].origin,
          base64Image: await imageEncoder.toBase64(images[imageId].displayableImage),
        });
      }),
      INFERENCE_GUARD_TIMEOUT
    ).catch((error) => {
      const { imagesOrder } = this.state;
      const { intl } = this.props;
      logger.error(error);
      throw new UserDisplayableError(
        intl.formatMessage(
          { id: 'viewer.unsaved_data.inference_timeout' },
          { index: imagesOrder.indexOf(imageId) + 1 }
        )
      );
    });
  }

  /* Image inference end */

  /* Image tools */

  switchVHSState = () => {
    const VHSState = this.currentImage?.toolsList?.VHS?.state;
    if (!VHSState) return;
    const newState = isVisibleToolState(VHSState) ? 'disabled' : 'passive';
    this.dispatch(toolsUpdateStates(this.currentImageId, { VHS: { state: newState } }));
  };

  setPatientRace = (race: string) => this.setPatient({ ...this.state.patient, race });

  setActiveRegionNameForImage = (activeRegionName: string) =>
    this.dispatch(predictionsSetActiveRegion(this.currentImageId, activeRegionName));

  onFeedbackUpdate = (imageId: string) => (patternName: string, isPositive: boolean) => {
    this.dispatch(feedbackUpdate(imageId, patternName, isPositive));
  };

  updateImageDisplayedMetadata = (image: ViewerImage, imageId: string) => {
    const { intl } = this.props;
    const { patient } = this.state;
    if (!isFullResolutionDicom(image)) return;
    this.dispatch(
      displayableMetadataUpdate(imageId, computeDisplayedImageMetadata(image, patient, intl))
    );
  };

  updateAllImageDisplayedMetadata = () => {
    const { images } = this.state;
    _.forEach(images, this.updateImageDisplayedMetadata);
  };

  /* Image tools end */

  /* Processing */

  processWithCache = async (
    savedStudyId: string,
    imageId: string,
    rawImage: DataImage,
    options: ProcessingOptions
  ): Promise<DataImage> => {
    const usedOptions = _.omitBy({ ...options }, _.isUndefined) as ProcessingOptions;
    if (options.crop_rect) {
      usedOptions.crop_rect = usedOptions.crop_rect.map(Math.round);
    }
    logger.time(`Processing for ${imageId}`);
    const processedImage = await this.processingCache.getOrCompute(rawImage, usedOptions);
    logger.timeEnd(`Processing for ${imageId}`);
    if (processedImage.collimation_rect) {
      usedOptions.crop_rect ??= processedImage.collimation_rect;
    }

    return processedImage;
  };

  processLoadedImage = async (
    imageId: string,
    pixelContent: DataImage,
    { processingType, cropRect, photometric_interpretation }: ProcessOptions
  ) => {
    const { studyId } = this.state;
    const processingOptions: ProcessingOptions = _.cloneDeep(PROCESSING_OPTIONS[processingType]);
    if (cropRect) {
      processingOptions.crop_rect = cropRect;
    }
    processingOptions.photometric_interpretation = photometric_interpretation;
    this.dispatch(imageProcessingStart(imageId, { processingType }));

    const processedImage = await this.processWithCache(
      studyId,
      imageId,
      pixelContent,
      processingOptions
    );

    this.dispatch(imageProcessingDone(imageId, { processedImage, isNewProcessing: true }));

    this.registerImagesInLoadingQueue({
      imageId,
      loadFn: async () => ({
        imageData: convertDataImageToDisplayableImageData(
          processedImage,
          `rawUint16:${generateRawUint16ImageId()}`
        ),
      }),
    });
  };

  /* Processing end */

  /* Acquisition */

  onAcquisitionConstantChange = (imageId: string, acquisitionsConstants: AcquisitionConstants) => {
    this.dispatch(acquisitionUpdateAcquisitionConstants(imageId, acquisitionsConstants));
  };

  clearAcquisitionCountDown = () => {
    this.setAcquisitionCountDown(undefined);
    clearInterval(this.acquisitionIntervalRef);
  };

  startNextAcquisitionSkipIfNeeded = () => {
    const isAnyOtherWorklistImage = _.some(this.state.images, isWorkListImage);
    if (!isAnyOtherWorklistImage) return;

    let acquisitionCount = 5;
    this.setAcquisitionCountDown(acquisitionCount);

    this.acquisitionIntervalRef = setInterval(() => {
      acquisitionCount -= 1;
      this.setAcquisitionCountDown(acquisitionCount);
      if (acquisitionCount <= 0) {
        this.dispatch(imageSelectNextWorklist(this.currentImageId));
        this.clearAcquisitionCountDown();
      }
    }, 1000) as unknown as number;
  };

  onNewAcquisitionImage = async (acquiredImage: DataImage) => {
    // Required call to free force JS run loop to handle this code execution outside of the NEW_IMAGE callback.
    await undefined;
    const { flatPanelState, objectIdGenerator, imageLoader } = this.props;
    const { studyId, patient } = this.state;
    const { currentImageId, currentImage } = this;

    let acquiredImageId = currentImageId;
    if (!isWorkListImage(currentImage)) {
      acquiredImageId = objectIdGenerator.create();
    }
    const detectorInfo = getCurrentDetectorState(flatPanelState)?.allAttributes;

    this.dispatch(
      acquisitionReceiveAcquiredImage(acquiredImageId, {
        acquiredImage,
        detectorInfo,
        patient,
      })
    );

    const processingType = getProcessingFromAnatomicRegion(currentImage.anatomicRegion);
    const processingOptions = {
      ...PROCESSING_OPTIONS[processingType],
      photometric_interpretation: 'MONOCHROME',
    };
    this.dispatch(imageProcessingStart(acquiredImageId, { processingType }));

    const processedImage = await this.processWithCache(
      studyId,
      acquiredImageId,
      acquiredImage,
      processingOptions
    );

    if (processedImage.collimation_rect) {
      const cropAnnotation = {
        ...convertRectToCropHandles(processedImage.collimation_rect),
        uuid: 'crop_uuid',
      };
      this.dispatch(toolsUpdateAnnotation(acquiredImageId, 'Crop', cropAnnotation));
    }
    this.dispatch(imageProcessingDone(acquiredImageId, { processedImage }));

    this.startNextAcquisitionSkipIfNeeded();

    this.dispatch(imageLoadStart(acquiredImageId, { origin: 'acquisition', isSelected: true }));
    this.registerImagesInLoadingQueue({
      imageId: acquiredImageId,
      loadFn: async () => ({
        imageData: convertDataImageToDisplayableImageData(
          processedImage,
          `rawUint16:${generateRawUint16ImageId()}`
        ),
      }),
    });
  };

  listenForAcquisitionImage = () => {
    const detector = getCurrentDetector(this.props.flatPanelState);

    if (!detector) return undefined;

    const { onNewAcquisitionImage } = this;
    detector.on(DETECTOR_EVENTS.NEW_IMAGE, onNewAcquisitionImage);
    detector.on(DETECTOR_EVENTS.RETRANSFER_IMAGE, onNewAcquisitionImage);
    return () => {
      detector.removeListener(DETECTOR_EVENTS.NEW_IMAGE, onNewAcquisitionImage);
      detector.removeListener(DETECTOR_EVENTS.RETRANSFER_IMAGE, onNewAcquisitionImage);
    };
  };

  /* Acquisition end */

  /* Sub render functions */

  renderAIPanel = () => {
    const {
      isCommentDirty,
      showAnnotations,
      isFullSizeConfiguration,
      patient,
      selectedNegatoView,
      studyId,
      creationDate,
      comment,
      images,
      imagesOrder,
    } = this.state;
    const { intl } = this.props;
    const { currentImageId, currentImage, currentImageToolsList } = this;
    const currentRenderer = this.renderers[selectedNegatoView];
    const isDisplayableImage = currentImage && !isWorkListImage(currentImage);
    const panelStyle = !isDisplayableImage ? { display: 'none' } : {};

    const {
      annotations,
      anatomicRegion,
      predictions,
      isPredictionsLoading,
      inferenceError,
      feedback,
      activeRegionName,
      origin,
      isRealSizeMeasurementCalibration,
    } = currentImage ?? {};

    const focusedElement = currentRenderer?.element;
    return (
      <div style={panelStyle}>
        <FoldableAIPanel>
          <div data-testid="image-tools" className="image-tools">
            {isDisplayableImage && (
              <div>
                <EditableReport
                  study={this.makeOldAPIStudy()}
                  onChange={this.onCommentChange}
                  minHeight="95px"
                  maxHeight="225px"
                  isDirty={isCommentDirty}
                />
              </div>
            )}
            {isDisplayableImage && currentImageToolsList && (
              <div>
                <MeasurementTools
                  predictions={predictions}
                  toolsList={currentImageToolsList}
                  focusedElement={focusedElement}
                  produceCommonToolsList={this.produceCommonToolsList}
                  produceImageToolsList={this.produceImageToolsList}
                  showAnnotations={showAnnotations}
                  onSwitchAnnotationState={this.onSwitchShownAnnotationsTools}
                  anatomicRegion={anatomicRegion}
                  disabled={isFullSizeConfiguration || isRealSizeMeasurementCalibration}
                />
              </div>
            )}
            <TeleradiologyPanel
              studyId={studyId}
              animal={patient}
              studyDate={creationDate}
              images={formatStudyImagesForTeleradiology(
                images,
                intl,
                studyId,
                patient,
                imagesOrder
              )}
              aiReport={comment}
            />
            {isDisplayableImage && (
              <PredictionsDisplay
                origin={origin}
                switchVHSState={this.switchVHSState}
                setPatientRace={this.setPatientRace}
                patient={patient}
                annotations={annotations}
                activeRegionName={activeRegionName}
                setActiveRegionName={this.setActiveRegionNameForImage}
                predictions={predictions}
                loading={isPredictionsLoading}
                inferenceError={inferenceError}
                feedback={feedback}
                onFeedbackUpdate={this.onFeedbackUpdate(currentImageId)}
                key={currentImageId}
              />
            )}
          </div>
        </FoldableAIPanel>
      </div>
    );
  };

  renderToolsPanel = () => {
    const { currentImage } = this;

    return (
      <div>
        {this.renderAIPanel()}
        {isWorkListImage(currentImage) && (
          <div className="acquisition-pane">
            <XRayGeneratorStatus />
            <Divider style={{ width: '80%' }} />
            <FlatPanelList />
            <XRayOperatorSelector />
          </div>
        )}
      </div>
    );
  };

  renderThumbnailImage = (image: ViewerImage, imageId: string) => {
    const { PACSConfiguration } = this.props;
    const { patient } = this.state;

    const { specie } = patient;

    const { isImageLoading, isProcessingOngoing, loadError, isSelected, anatomicRegion } = image;
    const shouldDisplayPACSStatus =
      !isPACSConfigurationDisabled(PACSConfiguration) && isAcquisitionImage(image);

    return (
      <div key={imageId}>
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => this.dispatch(imageSelect(imageId))}
          onClick={() => this.dispatch(imageSelect(imageId))}
          className={`image thumbnail-image ${isSelected ? 'selected' : ''}`}
          data-testid="thumbnail-image"
        >
          {isWorkListImage(image) ? (
            <ExamIcon exam={anatomicRegion} />
          ) : (
            <div className="radio">
              <Dimmer
                data-testid="thumbnail-render-area-loader"
                active={isImageLoading || isProcessingOngoing}
                style={{ zIndex: 0 }}
              >
                <Loader style={{ zIndex: 0 }} className="ui loader loaderPerformance" />
              </Dimmer>
              <ThumbnailImageRenderer
                image={image.displayableImage}
                viewport={image.viewport}
                annotations={image.annotations}
                className={`radio-image-container ${loadError ? 'hidden' : ''}`}
              />

              <div className="thumbnail-status">
                {shouldDisplayPACSStatus && (
                  <ImageToPACSSyncStatus
                    isSync={image?.imageMetadata?.PACS?.isSync}
                    isSyncOngoing={image?.PACS?.isSyncOngoing}
                    syncError={image?.PACS?.syncError}
                  />
                )}
              </div>
              {loadError && (
                <div className="image-load-failure image-load-failure--thumbnail">
                  <Icon name="warning sign" />
                  <FormattedMessage id="dropzone.image_load.failure" />
                </div>
              )}
            </div>
          )}

          <div className="thumbnail-actions flex">
            {(isWorkListImage(image) || isAcquisitionImage(image)) && (
              <EditAnatomicRegionModal
                specie={specie}
                image={image}
                onNewAnatomicRegion={(newAnatomicRegion: string) => {
                  this.dispatch(acquisitionUpdateAnatomicRegion(imageId, newAnatomicRegion));
                }}
              />
            )}

            {isAcquisitionImage(image) && (
              <Popup
                content={<FormattedMessage id="dropzone.redo_acquisition" />}
                trigger={
                  <button
                    type="button"
                    className="thumbnail-icon large icon redo-image"
                    data-testid="redo-button"
                    onClick={() => this.redoAcquisition(imageId)}
                  >
                    <Icon fitted name="redo alternate" />
                  </button>
                }
              />
            )}
            <button
              type="button"
              className="thumbnail-icon large icon delete-image"
              data-testid="delete-button"
              onClick={(event) => this.onDeleteImage(event, imageId)}
            >
              <Icon fitted name="times" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderSingleImageRendererView(negatoViewIndex: number) {
    const { images, selectedNegatoView } = this.state;
    let imageIdToDisplay = _.findKey(images, (image) => image.negatoView === negatoViewIndex);
    const renderedImage = images[imageIdToDisplay];
    const noImageForNegato = !renderedImage;
    const isCurrentNegatoView = selectedNegatoView === negatoViewIndex;

    let imageId;
    let image;
    let tools;

    if (!noImageForNegato && !isWorkListImage(renderedImage)) {
      imageId = imageIdToDisplay;
      image = renderedImage;
      tools = image?.toolsList && {
        ...this.state.commonToolsList,
        ...image.toolsList,
      };
    }
    const {
      isImageLoading,
      isProcessingOngoing,
      loadError,
      displayableImage,
      viewport,
      annotations,
      displayedMetadata,
    } = image ?? {};

    const hiddenRenderer = noImageForNegato || loadError;

    const selectViewIfNotCurrent = !isCurrentNegatoView
      ? () => this.dispatch(negatoSelectView(negatoViewIndex))
      : undefined;

    return (
      <div
        className={`radio-image-container ${isCurrentNegatoView ? 'selected' : ''}`}
        data-testid="main-radio-image"
        onPointerDown={selectViewIfNotCurrent}
      >
        <Dimmer
          data-testid="image-render-area-loader"
          active={isImageLoading || isProcessingOngoing}
        >
          <Loader size="huge" className="ui loader loaderPerformance" />
        </Dimmer>

        <div className={`radio-view ${hiddenRenderer ? 'no-image' : ''}`}>
          <MainImageRenderer
            imageId={imageId}
            image={displayableImage}
            toolsStates={tools}
            viewport={viewport}
            annotations={annotations}
            metadata={displayedMetadata}
            onRendererRef={(e) => this.onRendererRef(e, negatoViewIndex)}
          />
        </div>

        {loadError && (
          <div className="image-load-failure">
            <div>
              <Icon name="warning sign" />
              <FormattedMessage id="dropzone.image_load.failure" />
            </div>
          </div>
        )}
        {noImageForNegato && (
          <div className="negato-no-image">
            <div>
              <Icon name="info" circular />
              <FormattedMessage id="dropzone.negato.no_image_to_display" />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderImageViewer() {
    const { negatoMode } = this.state;
    const isSelected = !isWorkListImage(this.currentImage);
    const selectedClass = isSelected ? 'selected' : '';
    const { viewCount = 1 } = NEGATO_MODE_INFO[negatoMode];

    return (
      <div className={`main-radio-image ${selectedClass}`}>
        <div className={`${negatoMode}`}>
          {this.renderSingleImageRendererView(0)}
          {viewCount >= 2 && this.renderSingleImageRendererView(1)}
          {viewCount >= 3 && this.renderSingleImageRendererView(2)}
          {viewCount >= 4 && this.renderSingleImageRendererView(3)}
        </div>
      </div>
    );
  }

  renderViewerPane = () => {
    const { PACSConfiguration } = this.props;

    const { images, patient, isFullSizeConfiguration, selectedNegatoView } = this.state;
    const { acquisitionCountDown } = this.state;
    const { currentImage, isAnyImagePresent, currentImageToolsList } = this;
    const { isAnyImageLoaded, fileInputRef, toolsProps } = this;

    const isPACSActivated = !isPACSConfigurationDisabled(PACSConfiguration);
    const imagesForPACS = isPACSActivated ? this.prepareImageForPACSSelection() : undefined;
    imagesForPACS?.filter((image) => !image.imageMetadata?.PACS?.isSync) ?? [];
    const currentRenderer = this.renderers[selectedNegatoView];
    const focusedElement = currentRenderer?.element;

    const { name, owner_name, sex, birth_date, file_id, specie } = patient ?? {};
    const placeHolderDisplayClassName = isAnyImagePresent ? 'hidden' : '';
    const { isRealSizeMeasurementCalibration } = currentImage ?? {};

    return (
      <>
        {isAnyImageLoaded && (
          <div className="patient-info-bar-wrapper">
            <PatientInfoBar
              animalName={name}
              ownerName={owner_name}
              sex={sex}
              birthDate={birth_date}
              fileID={file_id}
              specie={specie}
            />
            <PatientInfoModal
              initialPatientData={patient}
              onPatientSave={this.linkPatientToStudy}
            />
          </div>
        )}
        {currentImageToolsList && (
          <ToolsBar
            predictions={currentImage.predictions}
            toolsList={currentImageToolsList}
            focusedElement={focusedElement}
            produceCommonToolsList={this.produceCommonToolsList}
            produceImageToolsList={this.produceImageToolsList}
            displayedToolsLists={UPPER_TOOLS_BAR_TOOLS_LISTS}
            toolsProps={toolsProps}
            anatomicRegion={currentImage.anatomicRegion}
            disabled={isFullSizeConfiguration || isRealSizeMeasurementCalibration}
          />
        )}
        <picture id="radioPicture">
          <div
            data-testid="file-upload-placeholder"
            className={`placeholderForImagesWrapper ${placeHolderDisplayClassName}`}
          >
            {!isAnyImageLoaded && <WhatImagesToSend loadDemo={this.onLoadDemo} />}
            <div className="placeholderForImagesButtonWrapper">
              <button
                className="placeholder placeholderForImage"
                onClick={this.triggerFileUpload}
                type="button"
              >
                <label htmlFor="file-upload">
                  <FormattedMessage id="dropzone.dragAndDropText.notIE" />
                </label>
                <div>
                  <Icon name="upload" size="huge" className="icon-upload-color" />
                </div>
              </button>
            </div>
            <input
              id="file-upload"
              type="file"
              data-testid="file-upload"
              accept=".jpg,.JPG,.png,.PNG,.dcm,.DCM,.dicom,.DICOM,.JPEG,.jpeg"
              onChange={this.onFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
              multiple
            />
          </div>
          {this.renderImageViewer()}
          {_.map(images, (image, imageId) => {
            const { isProcessingOngoing, isSelected } = image;
            const selectedClass = isSelected ? 'selected' : '';

            if (!isWorkListImage(image)) return null;
            return (
              <div
                key={imageId}
                className={`main-radio-image ${selectedClass}`}
                data-testid="xray-area-image"
              >
                <XRayAreaView
                  image={image}
                  animal={patient}
                  onChange={(acquisitionConstants) =>
                    this.onAcquisitionConstantChange(imageId, acquisitionConstants)
                  }
                  isProcessingOnGoing={isProcessingOngoing}
                  isFocused={isSelected}
                />
              </div>
            );
          })}
          <FullSizeValidationButtonsIfOnGoing
            isFullSizeConfiguration={isFullSizeConfiguration}
            image={currentImage}
            renderer={currentRenderer}
            switchFullSizeConfiguration={this.switchFullSizeConfiguration}
          />
          {isRealSizeMeasurementCalibration && (
            <ToolValidationOverlay
              onConfirm={this.confirmRealSizeMeasurementCalibration}
              onCancel={this.cancelRealSizeMeasurementCalibration}
            />
          )}
        </picture>

        {acquisitionCountDown !== undefined && (
          <div className="acquisition-countdown">
            <CountDown count={acquisitionCountDown} duration={5} />
          </div>
        )}
      </>
    );
  };

  /* Sub render functions end */

  /* Effects */
  onAnnotationDisplayChange = () => {
    const { images, showAnnotations } = this.state;
    _.forEach(images, (image, imageId) => {
      this.dispatch(toolsChangeAnnotationsVisibility(imageId, showAnnotations));
    });
  };

  onFullScreenChange = () => {
    this.props.hideHeader(this.state.isFullScreen);
  };

  loadStudy = () => {
    const { initialStudyId, studyStore, imageLoader } = this.props;

    if (!initialStudyId) return;
    studyStore
      .getStudy(initialStudyId)
      .then(
        ({
          _id: studyId,
          images,
          patient,
          creationDate,
          pms_id = undefined,
          comment = '',
          isCommentDirty = false,
        }) => {
          if (patient) this.setPatient(patient);

          if (creationDate) this.setState({ creationDate });

          // Fill the last saved state to loaded state
          this.lastSavedState.report = { comment, isCommentDirty };

          this.setState({
            pms_id,
            studyId,
            comment,
            isCommentDirty,
          });

          images.forEach(async (image) => {
            const imageId = image._id;
            const {
              predictions,
              feedback,
              annotations,
              viewport,
              anatomicRegion,
              image_metadata,
              acquisitionConstants,
              origin,
            } = image;

            if (isWorkListImage(image)) {
              this.dispatch(
                acquisitionLoadWorkListImage(imageId, { anatomicRegion, acquisitionConstants })
              );
            } else {
              this.imageLoadingPromises[imageId] = createDeferredPromise();
              this.dispatch(
                imageLoadStart(imageId, {
                  annotations,
                  viewport,
                  imageMetadata: image_metadata,
                  origin,
                  anatomicRegion,
                  acquisitionConstants,
                  acquisitionTime: image.acquisitionTime,
                  detectorInfo: image.detectorInfo,
                  feedback,
                })
              );
              this.dispatch(predictionsLoadSuccess(imageId, predictions));
            }
          });
          const imagesWithLoadFn = _.reject(images, isWorkListImage).map(({ _id: imageId }) => ({
            imageId,
            loadFn: async () => {
              const loadedImage = await studyStore.loadImage(studyId, imageId);
              this.dispatch(imageCacheInitialContent(imageId, loadedImage.imageData));

              const imageDescriptor = this.state.images[imageId];

              if (!isProcessingForImageNeeded(loadedImage, imageDescriptor)) {
                return loadedImage;
              }
              const processingType = imageDescriptor?.imageMetadata?.processingType;
              this.dispatch(imageProcessingStart(imageId, { processingType, isReload: true }));
              this.dispatch(
                imageUpdateProcessingOptions(imageId, {
                  photometric_interpretation: getDicomDataValue(
                    loadedImage.dicomData,
                    'PhotometricInterpretation'
                  ),
                })
              );
              const { processedImage, isFromLastProcessing } = await processLoadingImage(
                studyId,
                imageId,
                loadedImage,
                imageDescriptor,
                this.processWithCache,
                this.processingCache
              );

              this.dispatch(imageProcessingDone(imageId, { processedImage, isFromLastProcessing }));
              const [rowPixelSpacing, columnPixelSpacing] =
                getPixelSpacing(loadedImage.dicomData) || [];
              return {
                ...loadedImage,
                imageData: {
                  rowPixelSpacing,
                  columnPixelSpacing,
                  ...(await imageLoader.load(processedImage)).imageData,
                },
              };
            },
          }));
          this.registerImagesInLoadingQueue(...imagesWithLoadFn);
        }
      )
      .catch(() => this.setStudyLoadFailedConfirmOpen(true));
  };

  onPatientSpecieChange = () => {
    this.dispatch(imagesUpdatePatientSpecie(this.state.patient?.specie));
  };

  startFullSizeConfiguration = () => {
    onFullSizeConfigurationToggle(
      this.state.isFullSizeConfiguration,
      this.currentImageToolsList,
      this.produceImageToolsList,
      this.produceCommonToolsList
    );
  };

  forceLoadOnImageSelect = ({ isSelected, displayableImage }: ViewerImage, imageId: string) => {
    if (!isSelected) return;
    if (displayableImage) return;
    this.imageLoadingScheduler.forceExecution(this.imagesLoadingHandlers[imageId]);
  };

  updateReportOnChange = () => {
    const { reportGenerator, intl } = this.props;
    const { isCommentDirty, patient, images, creationDate } = this.state;
    if (isCommentDirty) return;
    this.setComment(reportGenerator.generate(intl, patient, images, creationDate));
  };

  cleanupExecutionContextOnDelete = (image: ViewerImage, imageId: string) => {
    const { dataLossGuard } = this.props;

    if (image !== undefined) return;
    dataLossGuard?.setDataSaveCallback(`inference.${imageId}`, undefined);
    dataLossGuard?.setDataSaveCallback(`pacs.${imageId}`, undefined);
  };
  /* Effects end */

  render() {
    const {
      initialStudyId,
      flatPanelState,
      studyStore,
      imagePaths,
      importedImages,
      PACSConfiguration,
    } = this.props;

    const { studyId, isFullScreen, images, patient, isFullSizeConfiguration } = this.state;
    const { studyLoadFailedConfirmOpen, showAnnotations } = this.state;
    const { comment } = this.state;
    const { isAnyImagePresent } = this;

    const isPACSActivated = !isPACSConfigurationDisabled(PACSConfiguration);
    const imagesForPACS = isPACSActivated ? this.prepareImageForPACSSelection() : undefined;
    const initialSelectedImages =
      imagesForPACS?.filter((image) => !image.imageMetadata?.PACS?.isSync) ?? [];
    const detector = getCurrentDetector(flatPanelState);

    const { specie } = patient ?? {};

    return (
      <>
        <div
          data-testid="viewer"
          className={`dropzone viewer-background${isFullScreen ? ' fullscreen' : ''}`}
          onDrop={this.onFileDrop}
          onDragOver={preventDefault}
        >
          <div className="viewer-grid">
            <div className="left-pane">
              <div>
                <div className="thumbnails" data-testid="thumbnails-panel">
                  <div>{isAnyImagePresent && _.map(images, this.renderThumbnailImage)}</div>
                </div>
                <div className="add-image-tools">
                  <Button.Group vertical>
                    {flatPanelState && (
                      <AddXRayViewModal
                        specie={specie}
                        onNewAnatomicRegion={this.addWorkListImage}
                      />
                    )}
                    <Popup
                      position="right center"
                      trigger={
                        <button
                          type="button"
                          className="button picoxia add-image"
                          onClick={this.triggerFileUpload}
                        >
                          <Icon name="image outline" size="large" />
                          <span>
                            <FormattedMessage id="dropzone.add_file" />
                          </span>
                        </button>
                      }
                      content={<FormattedMessage id="dropzone.add_file.tooltip" />}
                    />
                    <Snipper onImageReady={this.onSnipImages} />
                    <FileWatcher onImageReady={this.onAutoImportImages} />
                  </Button.Group>
                </div>
                <div className="left-pane-divider" />

                <div className="study-actions">
                  <div className={`icons-list ${isPACSActivated ? 'with-pacs' : ''}`}>
                    <ButtonStudySave validateStudy={this.validateStudy} />
                    {isPACSActivated && (
                      <div>
                        <PACSImagesSelector
                          images={imagesForPACS}
                          initialSelectedImages={initialSelectedImages}
                          onConfirm={this.sendSelectedImagesToPACS}
                        />
                      </div>
                    )}
                    <ExportStudyModal currentStudy={this.makeOldAPIStudy()} />
                    <Popup
                      trigger={
                        <button
                          className="picoxia"
                          type="button"
                          data-testid="clear-study"
                          onClick={this.clearStudy}
                        >
                          <Icon name="close" size="big" />
                        </button>
                      }
                      content={<FormattedMessage id="dropzone.get_out.tooltip" />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="viewer-pane" data-testid="viewer-panel">
              {this.renderViewerPane()}
            </div>
            <div className="tools-pane" data-testid="tools-panel">
              {this.renderToolsPanel()}
            </div>
          </div>
          <Confirm
            open={studyLoadFailedConfirmOpen}
            onClose={this.onStudyFailureModalClose}
            onCancel={this.onStudyFailureModalClose}
            cancelButton={null}
            confirmButton={<FormattedMessage id="dropzone.study_load.failure" />}
          />
        </div>
        <UseEffect setup={this.onAnnotationDisplayChange} deps={[showAnnotations]} />
        <UseEffect setup={this.onFullScreenChange} deps={[isFullScreen]} />
        <UseEffect setup={this.loadStudy} deps={[studyStore, initialStudyId]} />
        <UseEffect setup={this.loadImagePaths} deps={[imagePaths]} />
        <UseEffect setup={this.loadAutoImportedImages} deps={[importedImages]} />
        <UseEffect setup={this.onPatientSpecieChange} deps={[specie]} />
        <UseEffect setup={this.markAllImageAsOutOfSync} deps={[patient]} />
        <UseEffect setup={this.startFullSizeConfiguration} deps={[isFullSizeConfiguration]} />
        <UseEffect setup={this.listenForAcquisitionImage} deps={[detector]} />
        <UseEffect setup={this.updateAllImageDisplayedMetadata} deps={[patient]} />
        <UseImagesEffect
          effect={rejectImageDeletionEffect(this.updateImageDisplayedMetadata)}
          images={images}
          deps={[
            'dicomData',
            'anatomicRegion',
            'acquisitionTime',
            'acquisitionConstants',
            'detectorInfo',
            'initialDataImage',
          ]}
        />
        <UseImagesEffect
          effect={rejectImageDeletionEffect(this.saveLastProcessedImage)}
          images={images}
          deps={['processedImage']}
        />
        <UseImagesEffect
          effect={rejectImageDeletionEffect(this.forceLoadOnImageSelect)}
          images={images}
          deps={['isSelected']}
        />
        <UseImagesEffect
          effect={rejectImageDeletionEffect(this.onAnnotationDisplayChange)}
          images={images}
          deps={['toolsList']}
        />
        <UseImagesEffect
          effect={this.updateReportOnChange}
          images={images}
          deps={['predictions', 'feedback']}
        />
        <UseImagesEffect
          effect={this.updateReportOnChange}
          images={images}
          deps={['annotations.VHS', 'annotations.NorbergOlsson']}
        />
        <UseImagesEffect
          effect={this.clearAcquisitionCountDown}
          images={images}
          deps={['isSelected']}
        />
        <UseImagesEffect effect={this.cleanupExecutionContextOnDelete} images={images} />
        {comment && (
          <DataSaveEffect
            data={this.formatReportState()}
            initialData={this.lastSavedState.report}
            saveCallback={(reportData) => this.saveReport(reportData)}
            debounceDelay={ANNOTATION_SAVE_DELAY}
            dataLossKey="report"
          />
        )}
        {_.map(this.state.images, (image, imageId) => (
          <DataSaveEffect
            key={imageId}
            data={image.saveState?.acquisitionInfo}
            initialData={image.reloadedState?.acquisitionInfo}
            saveCallback={(data) => this.saveImageData(imageId, { ...data, origin: image.origin })}
            dataLossKey={`image.${imageId}.metadata`}
          />
        ))}
        {_.map(this.state.images, (image, imageId) => (
          <DataSaveEffect
            key={imageId}
            data={image.saveState?.viewer}
            initialData={image.reloadedState?.viewer}
            saveCallback={(data) => this.saveImageData(imageId, data)}
            debounceDelay={ANNOTATION_SAVE_DELAY}
            dataLossKey={`image.${imageId}.viewer`}
          />
        ))}
        {_.map(
          this.state.images,
          (image, imageId) =>
            // Raw dicom modification
            !image.isImageLoading &&
            isFullResolutionDicom(image) && (
              <DataSaveEffect
                key={imageId}
                data={image.saveState?.rawImage}
                initialData={image.reloadedState?.rawImage}
                saveCallback={(data) => this.saveRawImage({ studyId, imageId, ...data })}
                onlyGuard={image.isRawSavedOnce}
                dataLossKey={`dicom.${imageId}`}
                excludedProperties={[
                  'acquisitionDetails.patient._id',
                  'acquisitionDetails.patient.owner',
                  'acquisitionDetails.patient.chip_id',
                  'acquisitionDetails.patient.pedigree_id',
                ]}
              />
            )
        )}

        {_.map(
          this.state.images,
          (image, imageId) =>
            // Inference guard
            !image.isImageLoading &&
            image.displayableImage &&
            !image.predictions && (
              <DataSaveEffect
                key={imageId}
                data={true}
                saveCallback={() => this.computeInitialInference(imageId)}
                dataLossKey={`inference.${imageId}`}
              />
            )
        )}
      </>
    );
  }
}

function Viewer(props: ViewerProps) {
  const injections = {
    flatPanelState: useContext(FlatPanelStateContext),
    pacsCommunication: useContext(PACSCommunicationContext),
    dataLossGuard: useContext(DataLossGuardConnectorContext),
    exportToPMS: useContext(PMSExportContext),
    imageEncoder: useContext(IDisplayableImageEncoderContext),
    dicomBuilder: useContext(DicomBuilderContext),
    aiOnlyConfiguration: useSelector(selectAiOnlyConfiguration),
    viewerConfiguration: useSelector(selectViewerConfiguration),
    PACSConfiguration: useSelector(selectPACSConfiguration),
    dicomMapping: useSelector(selectDicomMapping),
    intl: useIntl(),
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ViewerImpl {...props} {...injections} />;
}

export default Viewer;
