import logger from 'app/utils/debug/logger';
import XRayGeneratorController from 'app/adapters/XRayGenerator/XRayGeneratorController';
import EventEmitter from 'events';

export default class SerialXRayGeneratorControllerHandle extends EventEmitter {
  constructor(serialportModule, devicePath) {
    super();

    this.serialportModule = serialportModule;
    this.devicePath = devicePath;
    this.id = devicePath;
  }

  connect() {
    return new Promise((resolve, reject) => {
      const serialPort = new this.serialportModule.SerialPort(
        {
          path: this.devicePath,
          baudRate: 19200,
          dataBits: 8,
          parity: 'none',
          stopBits: 2,
          rts: true,
          rtsMode: 'enable',
        },
        (error) => {
          if (error) {
            reject(error);
            return;
          }

          serialPort.on('close', () => {
            // Not protected by tests, hard to do but needed
            this.emit('disconnected');
          });

          serialPort.on('error', (event) => {
            if (event.message === 'Port is not open') return;
            logger.warn('SerialPort error', event);
          });

          resolve(new XRayGeneratorController(serialPort));
        }
      );
    });
  }
}
