/* eslint-disable class-methods-use-this */
import * as Comlink from 'comlink';
import _ from 'lodash';

import makeBasicFunctionalWorkerPool, { WorkerPool } from 'app/encoders/BasicFunctionalWorkerPool';
import { CodecModule, ImageInfo, TypedArray } from 'app/interfaces/CodecModule';
// @ts-ignore
import CodecWorker from './codec.worker';

class CodecModuleWithWorker implements CodecModule {
  private codecWorker: WorkerPool<CodecModule>;

  constructor() {
    this.codecWorker = makeBasicFunctionalWorkerPool(
      _.times(3, () => Comlink.wrap<CodecModule>(new CodecWorker()))
    );
  }

  async encode(
    imageFrame: TypedArray,
    imageInfo: ImageInfo,
    encoderTransferSyntaxUID: string,
    options: any
  ) {
    return await this.codecWorker
      .get()
      .encode(imageFrame, imageInfo, encoderTransferSyntaxUID, options);
  }

  async decode(
    imageFrame: TypedArray,
    sourceTransferSyntaxUID: string,
    imageInfo: Partial<ImageInfo> = {}
  ) {
    return await this.codecWorker.get().decode(imageFrame, sourceTransferSyntaxUID, imageInfo);
  }
}

export default function createCodecModuleWithWorker() {
  if (!window.Worker) return undefined;
  if (typeof WebAssembly === 'undefined') return undefined;
  return new CodecModuleWithWorker();
}
