import { PatternRepresentation, PatternsList } from 'app/utils/reports/types';
import { MARQUE, PRESENT, PROBABLE, SUSPICION, ABSENT } from './constants';

const inSingle = (listOfPatterns: PatternsList, item: PatternRepresentation) =>
  listOfPatterns.indexOf(item) > -1;

const isIn = (listOfLists: PatternsList[], item: PatternRepresentation) =>
  listOfLists.some((list) => inSingle(list, item));

const severityToInt = (severity: string) => {
  switch (severity) {
    case 'marque':
      return MARQUE;
    case 'present':
      return PRESENT;
    case 'probable':
      return PROBABLE;
    case 'suspicion':
      return SUSPICION;
    case 'absent':
      return ABSENT;
    default:
      console.log('Unknown severity:', severity);
      return null;
  }
};

const getSeverite = (pattern: string, ...patternsBySeverityList: PatternsList[]) => {
  const [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns] =
    patternsBySeverityList;
  if (isIn([markedPatterns], pattern)) {
    return 'marque';
  }
  if (isIn([presentPatterns], pattern)) {
    return 'present';
  }
  if (isIn([likelyPatterns], pattern)) {
    return 'probable';
  }
  if (isIn([unlikelyPatterns], pattern)) {
    return 'suspicion';
  }
  return 'absent';
};

const getSeveriteInt = (
  pattern: string,
  markedPatterns: PatternsList,
  presentPatterns: PatternsList,
  likelyPatterns: PatternsList,
  unlikelyPatterns: PatternsList,
  ...args: PatternsList[]
) =>
  severityToInt(
    getSeverite(pattern, markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns)
  );

const haveSameSeverity = (patterns: PatternsList, listOfLists: PatternsList[]) =>
  // listOfLists is supposed to be ordered by decreasing severity
  listOfLists.some((list) => patterns.every((pattern) => inSingle(list, pattern)));

const addToMaxSeverity = (
  patternToAdd: PatternRepresentation,
  patterns: PatternsList,
  listOfLists: PatternsList[]
) => {
  // listOfLists is supposed to be ordered by decreasing severity
  const listToAppendTo = listOfLists.find((list) =>
    patterns.some((pattern) => inSingle(list, pattern))
  );
  if (listToAppendTo) {
    listToAppendTo.unshift(patternToAdd);
  }
};

const isLeftSeverityWorst = (leftSeverity: string, rightSeverity: string) =>
  severityToInt(leftSeverity) > severityToInt(rightSeverity);

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowerCaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
function removeTrailingDot(string: string) {
  return string.endsWith('.') ? string.slice(0, -1) : string;
}
function addTrailingDot(string: string) {
  return string.endsWith('.') ? string : `${string}.`;
}

export {
  inSingle,
  isIn,
  severityToInt,
  getSeverite,
  getSeveriteInt,
  haveSameSeverity,
  addToMaxSeverity,
  isLeftSeverityWorst,
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  removeTrailingDot,
  addTrailingDot,
};
