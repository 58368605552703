const fileType = window.nodeModules?.['file-type'];

const path = window.nodeModules?.path;
// This file should not be imported in WEB mode.
const fs = window.nodeModules?.fs;

const isDicomImage = (imageFilePath) => {
  // Because we allow an empty extension, we need to check that the file is not a dir.
  const extension = path.extname(imageFilePath);
  return (
    ['.DCM', '.dcm', '.DICOM', '.dicom', ''].indexOf(extension) > -1 &&
    !fs.lstatSync(imageFilePath).isDirectory()
  );
};

const isWebImage = (imageFilePath) => {
  const extension = path.extname(imageFilePath);
  return (
    [
      '.jpeg',
      '.jpe',
      '.jpg',
      '.jfif',
      '.pjpeg',
      '.pjp',
      '.png',
      '.apng',
      '.avif',
      '.svg',
      '.webp',
      '.gif',
      '.bmp',
    ].indexOf(extension.toLowerCase()) > -1
  );
};

const loadWebImage = (imageFilePath) => {
  let resolvePromise;
  const promise = new Promise((resolve) => {
    resolvePromise = resolve;
  });
  fs.readFile(imageFilePath, async (error, imageBuffer) => {
    if (error) {
      console.log(error);
    } else {
      const { mime } = await fileType.fromBuffer(imageBuffer);
      const base64Image = `data:${mime};base64,${imageBuffer.toString('base64')}`;
      resolvePromise(base64Image);
    }
  });
  return promise;
};

export { isDicomImage, isWebImage, loadWebImage };
