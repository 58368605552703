import electron from 'app/native/node/electron';

export default async function generatePDFFromHtml(htmlString: string): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    try {
      const { BrowserWindow } = electron().remote;
      const workerWindow = new BrowserWindow({ show: false });
      const b64Html = `data:text/html;charset=utf-8;base64,${Buffer.from(htmlString).toString(
        'base64'
      )}`;
      workerWindow.hide();
      workerWindow.loadURL(b64Html);
      workerWindow.webContents.on('did-finish-load', async () => {
        workerWindow.hide();
        const buffer = await workerWindow.webContents.printToPDF({
          landscape: false,
          pageSize: 'A4',
        });
        workerWindow.close();
        resolve(buffer);
      });
      workerWindow.webContents.on('did-fail-load', (e) => {
        console.error('did-fail-load', e);
        reject(e);
      });
    } catch (e) {
      reject(e);
    }
  });
}
