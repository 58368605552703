import ApiCalls from 'app/utils/apiCalls';
import toastifyPromise from 'app/utils/toastifyPromise';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';

/**
 * Bouton pour ajouter automatiquement une licence d'essais de nbDays jours et nbDays IA
 * @param {User} user
 * @param [User] onSubscriptionAdded
 * @param int nbDays
 * @returns {JSX.Element}
 */
function ButtonFreeTrialSubscription({ user, onSubscriptionAdded, nbDays }) {
  const [isRequestSending, setIsRequestSending] = useState(false);
  const { mail, is_demo_used: isDemoUsed } = user;

  const onClick = () => {
    setIsRequestSending(true);

    const promise = ApiCalls.addFreeTrialSubscription({ mail });
    toastifyPromise('add-free-trial-subscription', promise);
    promise
      .then(() => {
        setIsRequestSending(false);
        onSubscriptionAdded();
      })
      .catch(() => {
        setIsRequestSending(false);
      });
  };

  if (isDemoUsed) {
    return null;
  }

  return (
    <Button
      size="mini"
      color="teal"
      onClick={() => onClick()}
      loading={isRequestSending}
      disabled={isDemoUsed}
    >
      <Icon name="plus" />
      <FormattedMessage
        id="mgmt.subscription.add.free-trial"
        values={{ days: nbDays, credits: 3 * nbDays }}
      />
    </Button>
  );
}

export default ButtonFreeTrialSubscription;
