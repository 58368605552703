/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as PropTypes from 'prop-types';
import regions from './regions';
import cat from './cat';
import dog from './dog';
import './style.scss';

const rabbit = (
  <svg>
    <path d={regions[8].rabbit} stroke="#77ACF1" strokeWidth="15" />
  </svg>
);
const ferret = (
  <svg>
    <path d={regions[8].ferret} stroke="#77ACF1" strokeWidth="15" />
  </svg>
);
const bird = (
  <svg>
    <path d={regions[8].bird} stroke="#77ACF1" strokeWidth="15" />
  </svg>
);
const reptile = (
  <svg>
    <path d={regions[8].reptile} stroke="#77ACF1" strokeWidth="15" />
  </svg>
);
const rodent = (
  <svg>
    <path d={regions[8].rodent} stroke="#77ACF1" strokeWidth="15" />
  </svg>
);

class RegionSelection extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  handleRegionSelection = (area) => {
    const { onRegionSelection } = this.props;
    onRegionSelection(area);
  };

  componentWillUnmount = () => {
    const { onRegionSelection } = this.props;
    onRegionSelection(null);
  };

  getSelectedClassName = (region) => {
    const { regionSelected } = this.props;
    return region === regionSelected ? 'selected' : 'not-selected';
  };

  render() {
    const { specie, regionSelected } = this.props;
    const displayedSpecie = specie === 'other' ? 'dog' : specie;

    return (
      <div className="region-selection">
        <div className="region-name-container">
          {regionSelected && (
            <p className="region-name">
              <FormattedMessage id={`exam.body_part.${regionSelected}`} />
            </p>
          )}
        </div>
        <svg
          width="20vw"
          height="20vh"
          viewBox="0 0 1504 1009"
          xmlns="http://www.w3.org/2000/svg"
          className="animal-body"
        >
          {regions.map(
            (region) =>
              region[displayedSpecie] && (
                <path
                  id={region.name}
                  onClick={() => this.handleRegionSelection(region.name)}
                  className={this.getSelectedClassName(region.name)}
                  d={region[displayedSpecie]}
                  key={region.name}
                />
              )
          )}
          {displayedSpecie === 'cat' && cat}
          {displayedSpecie === 'dog' && dog}
          {displayedSpecie === 'rabbit' && rabbit}
          {displayedSpecie === 'ferret' && ferret}
          {displayedSpecie === 'bird' && bird}
          {displayedSpecie === 'reptile' && reptile}
          {displayedSpecie === 'rodent' && rodent}
        </svg>
      </div>
    );
  }
}

RegionSelection.propTypes = {
  intl: PropTypes.shape().isRequired,
  specie: PropTypes.string.isRequired,
  regionSelected: PropTypes.string,
  onRegionSelection: PropTypes.func.isRequired,
};
RegionSelection.defaultProps = {
  regionSelected: null,
};
export default injectIntl(RegionSelection);
