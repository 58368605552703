/* eslint-disable react/destructuring-assignment */
import React from 'react';
import * as pt from 'prop-types';
import OnClickToolButton from './OnClickToolButton';

class FullScreenButton extends React.PureComponent {
  render = () => (
    <OnClickToolButton
      onClick={this.props.switchFullScreen}
      tooltipId="tools.fullscreen.tooltip"
      content={<i className="svg-icon zoom-fit" />}
      isActive={this.props.isFullScreen}
    />
  );
}

FullScreenButton.propTypes = {
  switchFullScreen: pt.func,
  isFullScreen: pt.bool,
};

FullScreenButton.defaultProps = {
  switchFullScreen: () => {},
  isFullScreen: false,
};

export default FullScreenButton;
