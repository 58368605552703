import { PACSConfigurationState } from 'app/redux/PACSConfiguration/reducer';

export const SET_PACS_CONFIGURATION = 'c';
export const SET_PACS_ENABLED = 'SET_PACS_ENABLED';

export const setPACSConfiguration = (pacsConfiguration: Partial<PACSConfigurationState>) => ({
  type: SET_PACS_CONFIGURATION,
  pacsConfiguration,
});

export const setPACSEnabled = (enabled: boolean) => ({
  type: SET_PACS_ENABLED,
  enabled,
});
