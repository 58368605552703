import React from 'react';
import TeleradiologyAPIContext from './context';

/**
 *
 * @param {Object} param0
 * @param {TeleradiologyAPI} param0.teleradiologyAPI
 */
export default function TeleradiologyAPIProvider({ teleradiologyAPI, children }) {
  return (
    <TeleradiologyAPIContext.Provider value={teleradiologyAPI}>
      {children}
    </TeleradiologyAPIContext.Provider>
  );
}
