import AppHelmet from 'app/components/AppHelmet';
import SendRequestForm from 'app/components/Form/SendRequestForm';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const RequestDemoPage = () => (
  <div className="requestDemo">
    <AppHelmet
      titleIntlID="requestDemoPage.metaTitle"
      descriptionIntlID="requestDemoPage.metaDesc"
    />
    <div className="container">
      <h1 className="requestDemo__title">
        <FormattedMessage id="requestDemoPage.title" />
      </h1>
      <SendRequestForm whatTimeField requestType="demo" />
    </div>
  </div>
);

export default RequestDemoPage;
