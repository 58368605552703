/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { enUS, fr, de, el, nl, srLatn, ru, uk, it, fi, pt, sk, es } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import translationMessages from 'app/translationMessages';

registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('sr', srLatn);
registerLocale('el', el);
registerLocale('nl', nl);
registerLocale('ru', ru);
registerLocale('uk', uk);
registerLocale('it', it);
registerLocale('fi', fi);
registerLocale('pt', pt);
registerLocale('es', es);
registerLocale('sk', sk);
registerLocale('cz', enUS); // date-fns do not provide format for tcheque, so we will use enUS as default

export const appLocales = [
  'en',
  'fr',
  'de',
  'sr',
  'el',
  'nl',
  'ru',
  'uk',
  'it',
  'fi',
  'pt',
  'sk',
  'es',
  'cz',
];
Object.freeze(appLocales);

export { translationMessages };
