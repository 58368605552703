import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message, MessageHeader } from 'semantic-ui-react';
import { FieldErrors } from 'react-hook-form';

type ReactHookFormMessageErrorProps = {
  errors: FieldErrors;
};
/**
 * Message to display Errors from react-hook-form
 */
function ReactHookFormMessageError({ errors }: ReactHookFormMessageErrorProps) {
  const listErrors = Object.keys(errors).map((key) => (
    <li key={key}>
      <FormattedMessage id={errors[key].message as string} />
    </li>
  ));
  return (
    <Message negative>
      <MessageHeader>
        <FormattedMessage id="react-hook-form.message.error.title" />
      </MessageHeader>
      <p>
        <FormattedMessage id="react-hook-form.message.error.description" />
      </p>
      <ul>{listErrors}</ul>
    </Message>
  );
}

export default ReactHookFormMessageError;
