/* eslint-disable camelcase */
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { fromJS } from 'immutable';
import {
  ACQUISITION_PERMISSION,
  TELERADIOLOGY_PERMISSION,
  ADMIN_PERMISSION,
} from 'app/utils/apiCalls';
import observeStore from 'app/utils/redux/observeStore';
import {
  PERMISSIONS_LOCAL_STORAGE_KEY,
  SET_MANAGER_PERMISSIONS,
  UPDATE_PERMISSIONS,
} from './constants';

/** @var {object} permissionsInitialState */
const permissionsInitialState = () =>
  fromJS({
    admin: false,
    teams: {},
    acquisition: false,
    teleradiology: false,
    managerPermissions: {},
    ...restoreFromLocalStorage(
      PERMISSIONS_LOCAL_STORAGE_KEY,
      restoreFromLocalStorage('global.subscriptions')
    ),
  });

/**
 *
 * @param {object} state
 * @param {object} action
 * @returns
 */
function permissionsReducer(state = permissionsInitialState(), action) {
  switch (action.type) {
    case SET_MANAGER_PERMISSIONS: {
      const {
        can_see_users_managed_by = [],
        manager_name,
        can_change_manager = false,
      } = action.managerPermissions;

      return state.set(
        'managerPermissions',
        fromJS({
          can_see_users_managed_by,
          manager_name,
          can_change_manager,
        })
      );
    }

    case UPDATE_PERMISSIONS: {
      const hasAcquisitionPermissions = action.permissions.includes(ACQUISITION_PERMISSION);
      const hasTeleradiologyPermissions = action.permissions.includes(TELERADIOLOGY_PERMISSION);
      const isAdmin = action.permissions.includes(ADMIN_PERMISSION);

      const teams = {};
      action.permissions
        .filter((permission) => permission.startsWith('team.'))
        .forEach((permission) => {
          const [teamName, role] = permission.split('.').slice(1);
          teams[teamName] = role;
        });

      return state
        .set('admin', isAdmin)
        .set('acquisition', hasAcquisitionPermissions)
        .set('teleradiology', hasTeleradiologyPermissions)
        .set('teams', teams);
    }

    default:
      return state;
  }
}

export const selectPermissions = (state) => state.get('permissions');
export const selectPermissionAdmin = (state) => state.get('permissions').get('admin');
export const selectPermissionAcquisition = (state) => state.get('permissions').get('acquisition');
export const selectPermissionTeleradiology = (state) =>
  state.get('permissions').get('teleradiology');
export const selectPermissionTeams = (state) => state.get('permissions').get('teams');

export const attachPermissionsWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectPermissions,
    makeSyncReduxWithLocalStorageObserver(PERMISSIONS_LOCAL_STORAGE_KEY)
  );
};

export default permissionsReducer;
