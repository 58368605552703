import useGenericSHA256Protection from 'app/utils/hooks/passwordProtection/useGenericSHA256Protection';

const PASSWORD_HASH = '0ad94cd4e912f65758b50e7b641fa7c9459c86bc55400fd3d2635b6bc437fe07';

export default function useTechnicianPasswordProtection() {
  return useGenericSHA256Protection({
    headerId: 'password_required.picoxia_tech',
    placeholderId: 'password_required.picoxia_tech.placeholder',
    passwordHash: PASSWORD_HASH,
  });
}
