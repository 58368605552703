/* eslint-disable max-classes-per-file */
import { enable, Viewport as CscViewport } from 'cornerstone-core';
import _ from 'lodash';
// @ts-ignore
import * as cst from 'cornerstone-tools';
import * as csc from 'cornerstone-core';
import { addToolState, getToolState } from 'app/CornerstoneTools';
import { focusHandles } from 'app/CornerstoneTools/CropTool';
import {
  computeScaleFactorFromStoredViewport,
  getMatchingResolutionViewport,
  getViewportToSave,
  rescaleAnnotationsPoints,
} from 'app/utils/cornerstone/imageUtils';
import clearElement from 'app/CornerstoneTools/clearElement';
import resetCursor from 'app/CornerstoneTools/resetCursor';
import { DisplayOptions, ImageRenderer } from 'app/interfaces/ImageRenderer';
import { ToolsInitializer, ToolsStates } from './ImageRenderer/ConfigurableToolsOptions';
import { IntlShape } from 'react-intl';
import {
  BaseAnnotation,
  CropAnnotation,
  DisplayViewport,
  DisplayableImageData,
  ImageAnnotations,
  ImageDisplayableMetadata,
  Viewport,
} from 'app/interfaces/Image';
import {
  createCommonToolsList,
  createImageToolsList,
} from 'app/adapters/ImageRenderer/DefaultCornerstoneToolsInitialization';
import MeasurementEvent from 'app/interfaces/MeasurementEvent';
import { memoizeDebounce } from 'app/utils/lodashMixins';
import ViewportEvent from 'app/interfaces/ViewportEvent';
import invalidateAllTools from 'app/CornerstoneTools/invalidateAllTools';

const TOOL_STATE_FUNCTIONS_MAP = {
  active: cst.setToolActiveForElement,
  disabled: cst.setToolDisabledForElement,
  enabled: cst.setToolEnabledForElement,
  passive: cst.setToolPassiveForElement,
};

function isNewAnnotation(element: HTMLElement, toolName: string, annotation: BaseAnnotation) {
  const toolState = getToolState(element, toolName)?.data ?? [];
  return !_.some(toolState, { uuid: annotation.uuid });
}

function updateExistingAnnotation(
  element: HTMLElement,
  toolName: string,
  annotation: BaseAnnotation,
  checkForChanges: boolean
) {
  const toolState = getToolState(element, toolName)?.data ?? [];
  const existingAnnotation = _.find(toolState, { uuid: annotation.uuid });
  if (!existingAnnotation) return;
  if (!checkForChanges) {
    _.merge(existingAnnotation, annotation);
    return false;
  }
  const originalAnnotation = _.cloneDeep(existingAnnotation);
  _.merge(existingAnnotation, annotation);
  const changeDetected = !_.isEqual(existingAnnotation, originalAnnotation);

  return changeDetected;
}

function isEqual<T>(v1: T, v2: T) {
  return v1 === v2 || _.isEqual(v1, v2);
}

export default class MainImageRenderer extends EventTarget implements ImageRenderer {
  public element: HTMLElement & { displayedMetadata?: ImageDisplayableMetadata };
  private lastToolsState: ToolsStates;
  private lastViewport: DisplayViewport;
  private lastAnnotations: ImageAnnotations;
  private lastImageDisplayed: DisplayableImageData;
  private resizeObserver: ResizeObserver;
  private imageId: string;
  private onAnnotationChangeDebounced: ReturnType<typeof memoizeDebounce>;
  private onViewportChangeDebounced: ReturnType<typeof memoizeDebounce>;

  constructor(element: HTMLElement, toolsInitializer: ToolsInitializer) {
    super();
    this.element = element;
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.element);

    // Store listener linked to mainRenderer for unsubscribe on destroy
    enable(this.element);
    cst.setElementToolStateManager(this.element, cst.newImageIdSpecificToolStateManager());

    this.initTools(toolsInitializer);

    this.onViewportChangeDebounced = memoizeDebounce(
      (imageId: string, viewport: Viewport) => {
        this.dispatchEvent(new ViewportEvent('viewport_updated', imageId, viewport));
      },
      300,
      { resolver: (imageId: string) => imageId }
    );

    // Any function run during this event must be very fast, else this can lead to stuttering and unrecoverable crash of the GPU
    this.element.addEventListener(csc.EVENTS.IMAGE_RENDERED, this.onRenderEvent);

    // Some data inside measurementData are throttled and only updated after a short delay
    // (100ms). Because of this, we have to run this data update after a debounce and not
    // on every update. Sending annotation update on every modification also impact smoothness
    this.onAnnotationChangeDebounced = memoizeDebounce(this.onAnnotationChange, 300, {
      resolver: (evt: any, imageId: string) => `${evt.type}${evt.detail.toolName}${imageId}`,
    });

    this.element.addEventListener(cst.EVENTS.MEASUREMENT_ADDED, (evt) =>
      this.onAnnotationChange(evt, this.imageId)
    );
    this.element.addEventListener(cst.EVENTS.MEASUREMENT_MODIFIED, (evt) =>
      this.onAnnotationChangeDebounced(evt, this.imageId)
    );
    this.element.addEventListener(cst.EVENTS.MEASUREMENT_COMPLETED, (evt) =>
      this.onAnnotationChange(evt, this.imageId)
    );

    this.element.addEventListener(
      cst.EVENTS.MEASUREMENT_REMOVED,
      (
        evt: Event & {
          detail: {
            toolName: string;
            toolType: string; // Deprecation notice: toolType will be replaced by toolName
            element: HTMLElement;
            measurementData: any;
          };
        }
      ) => {
        if (!evt.detail.toolName) return;
        this.dispatchEvent(
          new MeasurementEvent(
            'annotation_removed',
            this.imageId,
            evt.detail.toolName,
            evt.detail.measurementData
          )
        );
      }
    );
  }

  checkChanges = (
    image: DisplayableImageData,
    { toolsStates, viewport, annotations, metadata }: DisplayOptions
  ) => {
    const changes = [];
    if (!isEqual(this.lastToolsState, toolsStates)) changes.push('toolsStates');
    if (!isEqual(this.lastViewport, viewport)) changes.push('viewport');
    if (!isEqual(this.lastAnnotations, annotations)) changes.push('annotations');
    if (!isEqual(this.element.displayedMetadata, metadata)) changes.push('metadata');
    if (this.lastImageDisplayed !== image) changes.push('image');

    return changes.length > 0 ? changes : undefined;
  };

  onAnnotationChange = (evt: any, imageId: string) => {
    const { type } = evt;
    const { toolName } = evt.detail;
    if (!toolName) return;

    // Avoid sending event for ongoing Crop, it hurts display
    const isAdditionOrCompletionEvent = [
      cst.EVENTS.MEASUREMENT_COMPLETED,
      cst.EVENTS.MEASUREMENT_ADDED,
    ].includes(type);
    if (!isAdditionOrCompletionEvent && toolName === 'Crop') return;

    this.dispatchEvent(
      new MeasurementEvent(
        'annotation_updated',
        imageId,
        evt.detail.toolName,
        evt.detail.measurementData
      )
    );

    if (type === cst.EVENTS.MEASUREMENT_COMPLETED) {
      this.dispatchEvent(
        new MeasurementEvent(
          'annotation_completed',
          imageId,
          evt.detail.toolName,
          evt.detail.measurementData
        )
      );
    }
  };

  setToolsAnnotations = (
    imageAnnotations: ImageAnnotations
  ): boolean | CropAnnotation['handles'] => {
    if (imageAnnotations === this.lastAnnotations) return;

    const existingCropHandles = getToolState(this.element, 'Crop')?.data?.[0]?.handles;

    let isAnyChange = false;
    _.forEach(imageAnnotations, (annotations, toolName) => {
      _.forEach(annotations, (measurementData) => {
        const newAnnotation = isNewAnnotation(this.element, toolName, measurementData);
        if (newAnnotation) {
          addToolState(this.element, toolName, _.cloneDeep(measurementData));
        } else {
          isAnyChange ||= updateExistingAnnotation(
            this.element,
            toolName,
            measurementData,
            !isAnyChange
          );
        }
      });
    });
    this.lastAnnotations = imageAnnotations;
    if (!existingCropHandles) {
      return getToolState(this.element, 'Crop')?.data?.[0]?.handles ?? isAnyChange;
    }
    return isAnyChange;
  };

  displayImage = (
    imageId: string,
    image: DisplayableImageData,
    { toolsStates, viewport, annotations, metadata }: DisplayOptions
  ) => {
    if (!image) return;
    const changes = this.checkChanges(image, { toolsStates, viewport, annotations, metadata });
    if (!changes) return;

    this.element.displayedMetadata = metadata;

    let imageViewport: CscViewport;
    let scaleFactor = 1;
    if (!viewport) {
      imageViewport = csc.getDefaultViewportForImage(this.element, image as csc.Image);
    } else if (viewport?.scale) {
      // We are displaying an already displayed image
      imageViewport = viewport;
    } else if (viewport) {
      // We are restoring a reloaded viewport
      imageViewport = getMatchingResolutionViewport({ ...viewport }, image);
      scaleFactor = computeScaleFactorFromStoredViewport(viewport, image).scaleFactor;
    }
    // This allow catching case of non cropped image that a reloaded
    const missingViewportFocus = !imageViewport.scale;

    const isImageChange = changes.includes('image');
    this.imageId = imageId;
    if (isImageChange) {
      csc.displayImage(
        this.element,
        { ...image, imageId } as csc.Image,
        _.cloneDeep(imageViewport)
      );
      // Needed else when image change due to processing it might not be updated (image has same ID)
      csc.updateImage(this.element, true);
      this.lastImageDisplayed = image;
      this.lastViewport = imageViewport;
      invalidateAllTools(this.element);
      // We always reset tool state on an image change
    } else if (imageViewport && changes.includes('viewport')) {
      const currentViewport = this.getViewport();
      if (!isEqual(currentViewport, viewport)) {
        csc.setViewport(this.element, _.cloneDeep(imageViewport));
      }
      this.lastViewport = imageViewport;
    }
    if (missingViewportFocus) csc.resize(this.element, true);

    // Annotations must be set before tools state, else Crop activation callback will create wrong crop area
    if (annotations && changes.includes('annotations')) {
      const annotationsToRestore =
        scaleFactor !== 1 ? rescaleAnnotationsPoints(annotations, scaleFactor) : annotations;
      const newCropHandlesOrNeedUpdate = this.setToolsAnnotations(annotationsToRestore);

      // In case an annotation was overridden, we have to refresh the image to display it
      if (newCropHandlesOrNeedUpdate === true) {
        csc.updateImage(this.element);
      } else if (newCropHandlesOrNeedUpdate) {
        // Here we want to focus on the cropped area only when the user trigger a crop or when an image is reloaded from the server with a cropped area.
        // This correspond to an image either with scale or with crop
        const cropHandles: CropAnnotation['handles'] = newCropHandlesOrNeedUpdate;
        const focussedViewport = focusHandles(this.element, cropHandles);
        if (focussedViewport) {
          // We need to instantly notify it to the wrapping component to allow it to use the latest viewport without even waiting for a real viewport change to avoid component updates overriding the viewport with previous unset value.
          // Mostly useful on image acquisition.
          this.onViewportChangeDebounced(imageId, focussedViewport);
          this.onViewportChangeDebounced.getMemoized(imageId).flush();
        }
      }
    }
    if (toolsStates && changes.includes('toolsStates')) {
      this.setToolsState(toolsStates);
    }
  };

  private initTools = (tools: ToolsInitializer) => {
    _.forEach(tools, ({ options, tool }) => {
      if (cst.getToolForElement(this.element, options.name)) return;
      cst.addToolForElement(this.element, tool, _.cloneDeep(options));
    });
  };

  private setToolsState = (tools: ToolsStates) => {
    if (this.lastToolsState === tools) return;

    let mouseButtonHasActiveTool = false;

    _.forEach(tools, ({ state, options }, toolName) => {
      if (state === 'active' && options.mouseButtonMask === 1) {
        mouseButtonHasActiveTool = true;
      }
      const currentTool = cst.getToolForElement(this.element, toolName);
      // Setting state indiscriminately break cursor from showing.
      if (currentTool.mode !== state) {
        const clonedOptions = _.cloneDeep(options);
        const { supportedInteractionTypes } = clonedOptions ?? {};
        TOOL_STATE_FUNCTIONS_MAP[state](
          this.element,
          toolName,
          clonedOptions,
          supportedInteractionTypes
        );
      }
    });

    if (!mouseButtonHasActiveTool) resetCursor(this.element);
    this.lastToolsState = tools;
  };

  getViewport = () => getViewportToSave(this.element);

  private handleResize: ResizeObserverCallback = ([resizeData]) => {
    const { element } = this;
    if (resizeData.contentBoxSize === undefined) {
      csc.resize(element, true);
      return;
    }
    const contentBoxSize = resizeData.contentBoxSize[0];
    if (contentBoxSize.blockSize === 0) return;

    const viewport = csc.getViewport(element);
    const shouldFitWindow = !viewport?.scale;
    csc.resize(element, shouldFitWindow);
  };

  private onRenderEvent = () => {
    const { lastViewport, imageId } = this;
    const viewport = this.getViewport();

    if (_.isEqual(viewport, lastViewport)) return;

    this.onViewportChangeDebounced(imageId, viewport);

    const isTransientChange = isEqual(
      _.omit(viewport, ['translation', 'scale']),
      _.omit(lastViewport, ['translation', 'scale'])
    );
    if (!isTransientChange) {
      // Update viewport on every change is costly due to its frequency and the fact that it can trigger component update
      this.onViewportChangeDebounced.getMemoized(imageId).flush();
    }
  };

  destroy() {
    this.resizeObserver.disconnect();
    clearElement(this.element);
  }
}

export class MainImageRendererFactory {
  private intl: IntlShape;
  private toolsInitializer: ToolsInitializer;

  constructor(intl: IntlShape, toolsInitializer?: ToolsInitializer) {
    this.intl = intl;

    this.toolsInitializer = toolsInitializer ?? {
      ...createCommonToolsList(() => this.intl),
      ...createImageToolsList(() => this.intl),
    };
  }

  create = (element: HTMLElement) => {
    const renderer = new MainImageRenderer(element, this.toolsInitializer);

    return renderer;
  };

  syncIntl(intl: IntlShape) {
    this.intl = intl;
  }
}
