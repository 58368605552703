import { IImageWriter, ImageWriterEncodeOptions } from 'app/interfaces/IImageWriter';
import { DisplayableImageData } from 'app/interfaces/Image';
import { Transformation, VOI } from 'app/interfaces/Image/Transformation';
import { IImageTransformer } from 'app/interfaces/ImageTransformer';
import {
  convertDisplayableImageToTransformableImage,
  toBlob,
  transformImage,
} from 'app/utils/imageManipulation/ImageTransformation';
import _ from 'lodash';

export default class ImageWriter implements IImageWriter {
  async writeImageFileDataFromDisplayableImage(
    image: DisplayableImageData,
    transformation: Transformation,
    encodeOptions: ImageWriterEncodeOptions = { type: 'image/png' }
  ): Promise<Blob | undefined> {
    const transformableImage = convertDisplayableImageToTransformableImage(image);

    if (!transformableImage) return undefined;

    // Here we pass maxWidth an maxHeight, this is not a strict resize. If image is smaller we do not want to perform e resize.
    const actualTransformation: Transformation = { ..._.omit(transformation, ['resize']) };
    if (encodeOptions.maxHeight || encodeOptions.maxWidth) {
      actualTransformation.resize = {};

      if (encodeOptions.maxWidth) {
        actualTransformation.resize.width = Math.min(
          encodeOptions.maxWidth,
          transformableImage.width
        );
      }
      if (encodeOptions.maxHeight) {
        actualTransformation.resize.height = Math.min(
          encodeOptions.maxHeight,
          transformableImage.height
        );
      }
    }

    const transformedImage = transformImage(transformableImage, actualTransformation);

    return toBlob(transformedImage, encodeOptions.type, encodeOptions.quality);
  }
}
