import BadParserError from 'app/pms/parser/BadParserError';
import ParsingError from 'app/pms/parser/ParsingError';
import { DEFAULT_SPECIE, SpeciesType } from 'app/constants/species';
import PmsParser, { PmsConfiguration, parseDate, parseDateTime } from './PmsParser';

type SpecieCodesAliasesType = Record<string, SpeciesType>;

type GenericConfiguration = {
  specieCodesAliases?: SpecieCodesAliasesType;
};

type PmsConfigurations = PmsConfiguration & {
  [key in string]?: GenericConfiguration;
};

export default class GenericPMSJSONParser implements PmsParser {
  private specieCodesAliases: SpecieCodesAliasesType = undefined;

  constructor(private configurationKey: string, private originValue: string) {}

  updateConfiguration = (pmsConfiguration: PmsConfigurations) => {
    const specieCodesAliases = pmsConfiguration?.[this.configurationKey]?.specieCodesAliases;
    if (specieCodesAliases) {
      this.specieCodesAliases = specieCodesAliases;
    }
  };

  parse = (fileContent: string) => {
    let parsedExam;
    try {
      parsedExam = JSON.parse(fileContent);
    } catch (e) {
      throw new ParsingError(e);
    }

    const {
      pms_exam_id,
      owner_name,
      name,
      file_id,
      chip_id,
      birth_date: birth_date_string,
      specie_code,
      sex,
      exam_datetime,
      attending_veterinarian,
      origin,
    } = parsedExam;

    if (origin !== this.originValue) {
      throw new BadParserError(`JSON file is not compatible with ${this.originValue}`);
    }

    const birth_date = parseDate(birth_date_string);
    const patientInfo = {
      file_id,
      chip_id,
      owner_name,
      birth_date,
      name,
      sex,
      specie: this.specieCodesAliases?.[specie_code] ?? DEFAULT_SPECIE,
      attending_veterinarian,
    };
    const exam = {
      pms_id: pms_exam_id,
      planningDate: exam_datetime ? parseDateTime(exam_datetime) : new Date(),
      pms_software: this.originValue,
    };
    return {
      patientInfo,
      exam,
    };
  };
}
