import React from 'react';
import * as PropTypes from 'prop-types';

import RegionSelection from '../RegionSelection';
import ExamSelection from '../ExamSelection';

class ExamSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainRegion: undefined,
    };
  }

  onComplete = (subRegion, view) => {
    const { onComplete, specie } = this.props;
    const { mainRegion } = this.state;
    onComplete({ specie, mainRegion, subRegion, view });
  };

  render() {
    const { specie, onRegionChange } = this.props;
    const { mainRegion } = this.state;
    return (
      <>
        <RegionSelection
          key={specie}
          specie={specie}
          regionSelected={mainRegion}
          onRegionSelection={(region) => {
            this.setState({ mainRegion: region });
            onRegionChange(region);
          }}
        />
        {mainRegion ? (
          <ExamSelection
            key={mainRegion}
            regionSelected={mainRegion}
            onComplete={this.onComplete}
          />
        ) : (
          // Here we display something just to reserve the space on the page
          <div className="two-lists" />
        )}
      </>
    );
  }
}
ExamSelector.propTypes = {
  specie: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  onRegionChange: PropTypes.func,
};

ExamSelector.defaultProps = {
  onComplete: () => {},
  onRegionChange: () => {},
};

export default ExamSelector;
