import xray from 'app/native/node-addons/xray';
import electron from 'app/native/node/electron';
import getPath from 'app/native/node/path';
import fs from 'app/native/node/fs';
import _ from 'lodash';
import { FlatPanelConfiguration } from 'app/redux/flatPanelConfiguration/reducer';

const getDetectorManager = () => xray()?.DetectorManager.get();

// eslint-disable-next-line camelcase
const migratePixrad9_5_0_SerialNumberChange = (pixradUserConfigPath: string) => {
  try {
    const path = getPath();
    const referencesPath = path.join(pixradUserConfigPath, 'references');
    const referencesFiles = fs().readdirSync(referencesPath);

    const oldAPIReferencesFiles = _.filter(referencesFiles, (filename) =>
      filename.startsWith('SN')
    );

    oldAPIReferencesFiles.forEach((filename) => {
      const newFilename = filename.replace('SN', '');
      if (fs().existsSync(path.join(referencesPath, newFilename))) return;
      fs().copyFileSync(
        path.join(referencesPath, filename),
        path.join(referencesPath, newFilename)
      );
    });
  } catch {
    //
  }
};

const initDetectorManager = (config: FlatPanelConfiguration) => {
  if (!xray()) return undefined;

  if (getDetectorManager()) return getDetectorManager();

  const path = getPath();
  const { remote } = electron();

  // const XRayPackagePath = path.dirname(window.require.resolve('xray/package.json'));
  // @ts-ignore
  const baseConfigPath = path.join(window.electronRootDir, 'base_configurations');
  const userConfigPath = path.join(remote.app.getPath('userData'), 'xray_configurations');

  const thalesBaseConfigPath = path.join(
    // @ts-ignore
    window.electronRootDir,
    'pixrad_bin/pixrad_configurations'
  );

  const thalesDetectorConfigRelativePath = 'config/config_3543EZhd';

  const thalesUserConfigPath = path.join(remote.app.getPath('userData'), 'pixrad_configurations');

  try {
    migratePixrad9_5_0_SerialNumberChange(thalesUserConfigPath);
  } catch {
    //
  }

  const detectorManager = xray().DetectorManager.create({
    iray: [baseConfigPath, userConfigPath],
    thales: [thalesBaseConfigPath, thalesUserConfigPath, thalesDetectorConfigRelativePath],
    config,
  });
  return detectorManager;
};

export { initDetectorManager, getDetectorManager };
