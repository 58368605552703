/* eslint-disable react/prop-types */
import logger from 'app/utils/debug/logger';
import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { useTimeout } from 'usehooks-ts';

export default function ActionResultButton({
  onClick,
  content = undefined,
  children,
  successContent,
  failureContent,
  onSuccess,
  onFailure,
  successProps = {},
  failureProps = {},
  delay = 1000,
  ...rest
} = {}) {
  const [state, setState] = useState();
  const defaultContent = content ?? children;
  const delayOrDisableTimeout = state !== undefined ? delay : null;

  useTimeout(() => {
    if (state === 'failure') onFailure?.();
    else onSuccess?.();

    setState(undefined);
  }, delayOrDisableTimeout);

  const onClickAction = useCallback(async () => {
    let clickResult;
    try {
      clickResult = onClick();
    } catch (e) {
      logger.warn('JSON validation error', e);
      clickResult = false;
    }
    const isFailure = clickResult === false;
    setState(isFailure ? 'failure' : 'success');
  }, [onClick]);

  const [renderedContent, buttonProps] = useMemo(() => {
    switch (state) {
      case 'success':
        return [successContent ?? defaultContent, { ...rest, ...successProps }];
      case 'failure':
        return [failureContent ?? defaultContent, { ...rest, ...failureProps }];
      default:
        return [defaultContent, rest];
    }
  }, [state, defaultContent, failureContent, successContent]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button onClick={onClickAction} {...buttonProps}>
      {renderedContent}
    </Button>
  );
}
