import ApiCalls from 'app/utils/apiCalls';
import toastifyPromise from 'app/utils/toastifyPromise';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Button, Confirm, Icon } from 'semantic-ui-react';

export default function EndSubscriptionConfirm({ userMail, subscription, onSubscriptionEnded }) {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);

  const open = useCallback(() => setOpened(true), [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);

  const endSubscription = useCallback(() => {
    // Ignored by API
    const endTime = new Date();
    const promise = toastifyPromise(
      'endSubscription',
      ApiCalls.endSubscription(userMail, subscription._id, endTime)
    );

    return promise.then(() => onSubscriptionEnded());
  }, [userMail, subscription, onSubscriptionEnded]);

  return (
    <Confirm
      open={opened}
      onClose={close}
      onOpen={open}
      trigger={
        <Button
          icon
          size="mini"
          color="red"
          title={intl.formatMessage({ id: 'mgmt.subscription.end.title' })}
        >
          <Icon name="delete" />
        </Button>
      }
      header={intl.formatMessage({ id: 'mgmt.subscription.end.title' })}
      content={intl.formatMessage({ id: 'mgmt.subscription.end.content' })}
      onConfirm={endSubscription}
      onCancel={close}
      confirmButton={intl.formatMessage({ id: 'general.confirm' })}
      cancelButton={intl.formatMessage({ id: 'general.cancel' })}
    />
  );
}
