import { IntlShape } from 'react-intl';
import * as csc from 'cornerstone-core';
// @ts-ignore
import * as cst from 'cornerstone-tools';

import { ToolsInitializer } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';
import {
  DEFAULT_JPEG_ENCODE_OPTIONS,
  EncodeOptions,
  IDisplayableImageEncoder,
  JPEGEncodeOptions,
} from 'app/interfaces/IDisplayableImageEncoder';
import {
  DisplayableImageData,
  ImageAnnotations,
  ImageDisplayableMetadata,
  Size,
} from 'app/interfaces/Image';
import { createImageToolsList } from 'app/adapters/ImageRenderer/DefaultCornerstoneToolsInitialization';
import _ from 'lodash';
import { computeCanvasDimension } from 'app/CornerstoneTools/loadImageInCanvas';
import { ConfigurableToolsKey } from 'app/adapters/ImageRenderer/ConfigurableToolsKeys';
import { computeCropFocusViewport } from 'app/CornerstoneTools/CropTool';
import { ElementToolStateManager } from 'app/CornerstoneTools/ElementToolStateManager';
import displayImageWithSync from 'app/CornerstoneTools/displayImageWithSync';
import clearElement from 'app/CornerstoneTools/clearElement';
import logger from 'app/utils/debug/logger';
import ComputationCache from 'app/utils/cache/ComputationCache';

const setupCornerstoneToolsForConverter = (element: HTMLElement, tools: ToolsInitializer) => {
  _.forEach(tools, ({ tool, options }, name) => {
    const converterOptions = _.cloneDeep({ ...options, name, mouseButtonMask: 0 });
    cst.addToolForElement(element, tool, converterOptions);
    cst.setToolEnabledForElement(element, name, converterOptions);
  });
};

const getDisplayedArea = (
  { width, height }: DisplayableImageData,
  annotations?: ImageAnnotations
) => {
  let displayedArea = { width, height };
  if (!annotations?.Crop) return { displayedArea };

  const [_uuid, data] = _.toPairs(annotations.Crop)?.[0] ?? [];
  const handles = data?.handles;

  if (!handles) return { displayedArea };

  displayedArea = {
    width: Math.abs(handles.topLeft.x - handles.end.x),
    height: Math.abs(handles.topLeft.y - handles.end.y),
  };

  return { displayedArea, cropHandles: handles };
};

export type BlobWithSize = Blob & Size;

function isComputeParamsEqualDefault(
  searchedKeys: [DisplayableImageData, EncodeOptions],
  cachedKeys: [DisplayableImageData, EncodeOptions]
): boolean {
  const [image1, options1] = searchedKeys;
  const [image2, options2] = cachedKeys;

  return _.isEqual(options1, options2) && image1?.getPixelData() === image2?.getPixelData();
}

export class DisplayableImageEncoder implements IDisplayableImageEncoder {
  public intl: IntlShape;
  private toolsInitializer: ToolsInitializer;
  private encodingCache;

  constructor(intl: IntlShape, toolsInitializer?: ToolsInitializer) {
    this.intl = intl;

    this.toolsInitializer = toolsInitializer ?? {
      ..._.omit(
        createImageToolsList(() => this.intl),
        ['ScaleOverlay']
      ),
    };
    this.encodingCache = new ComputationCache(this.computeToCanvas, isComputeParamsEqualDefault);
  }

  async toBase64(
    image: DisplayableImageData,
    options: JPEGEncodeOptions = DEFAULT_JPEG_ENCODE_OPTIONS
  ) {
    console.time(`toBase64 ${image.imageId}`);
    console.time(`toCanvas ${image.imageId}`);
    const canvas = await this.toCanvas(image, {
      ...options,
    });
    console.timeEnd(`toCanvas ${image.imageId}`);
    const base64Data = canvas.toDataURL('image/jpeg', options.quality);
    console.timeEnd(`toBase64 ${image.imageId}`);
    return base64Data;
  }

  async toBlob(
    image: DisplayableImageData,
    options: JPEGEncodeOptions = DEFAULT_JPEG_ENCODE_OPTIONS
  ): Promise<BlobWithSize> {
    return this.toJPEG(image, {
      ...options,
    });
  }

  async toJPEG(
    image: DisplayableImageData,
    options: JPEGEncodeOptions = DEFAULT_JPEG_ENCODE_OPTIONS
  ): Promise<BlobWithSize> {
    console.time(`toJPEG ${image.imageId}`);
    const canvas = await this.toCanvas(image, {
      ...options,
    });
    const blob = await new Promise((resolve: (blob: Blob) => void) => {
      canvas.toBlob((blob: Blob) => resolve(blob), 'image/jpeg', options.quality);
    });

    console.timeEnd(`toJPEG ${image.imageId}`);
    return Object.assign(blob, {
      width: canvas.width,
      height: canvas.height,
    });
  }

  toCanvas = async (
    image: DisplayableImageData,
    { viewport, annotations, maxHeight, maxWidth, metadata }: EncodeOptions = {}
  ) => {
    return this.encodingCache.getOrCompute(image, {
      viewport,
      annotations,
      maxHeight,
      maxWidth,
      metadata,
    });
  };

  computeToCanvas = async (
    image: DisplayableImageData,
    { viewport, annotations, maxHeight, maxWidth, metadata }: EncodeOptions = {}
  ) => {
    const element: HTMLElement & { displayedMetadata?: ImageDisplayableMetadata } =
      document.createElement('div');
    csc.enable(element, { renderer: 'webgl' });
    const { canvas } = csc.getEnabledElement(element);

    const { displayedArea, cropHandles } = getDisplayedArea(image, annotations);
    const [canvasWidth, canvasHeight] = computeCanvasDimension(
      { ...displayedArea, ..._.pick(image, ['columnPixelSpacing', 'rowPixelSpacing']) },
      viewport?.rotation,
      maxWidth,
      maxHeight
    );

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    cst.setElementToolStateManager(element, new ElementToolStateManager(element));
    setupCornerstoneToolsForConverter(element, this.toolsInitializer);

    _.forEach(annotations, (toolAnnotations, toolName: ConfigurableToolsKey) => {
      if (this.toolsInitializer[toolName] === undefined) {
        logger.warn(`Trying to render missing tool ${toolName}`);
        return;
      }
      _.forEach(toolAnnotations, (measurementData) => {
        cst.addToolState(element, toolName, _.cloneDeep(measurementData));
      });
    });

    let drawnViewport = _.merge(
      // We need this part to init proper voi
      csc.getDefaultViewport(canvas, image as any),
      _.pick(viewport ?? {}, ['vflip', 'hflip', 'invert', 'rotation', 'voi'])
    );
    if (cropHandles) {
      drawnViewport = computeCropFocusViewport(cropHandles, {
        image,
        canvas,
        viewport: drawnViewport,
      });
    }

    element.displayedMetadata = metadata;
    await displayImageWithSync(element, { ...image }, drawnViewport);

    clearElement(element);
    element.remove();

    return canvas;
  };
}
