const logger = {
  trace: console.trace,
  log: console.log,
  debug: console.debug,
  info: console.info,
  warn: console.warn,
  error: console.error,
  time: console.time,
  timeEnd: console.timeEnd,
  timeLog: console.timeLog,
};

export default logger;
