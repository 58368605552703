import { getLanguage } from './windowUtils';
import { translate } from './languageUtil';

/**
 *
 * @param {string} formatName
 * @returns {Intl.DateTimeFormatOptions}
 */
const getFormat = (formatName) => {
  if (formatName === 'dateNotDayInWeek') {
    return { day: 'numeric', month: 'long', year: 'numeric' };
  }
  if (formatName === 'longDate') {
    return { day: 'numeric', weekday: 'long', month: 'long', year: 'numeric' };
  }
  if (formatName === 'longDateTime') {
    return {
      day: 'numeric',
      weekday: 'long',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
  }
  if (formatName === 'shortTime') {
    return {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
  }
  if (formatName === 'short') {
    return { day: 'numeric', month: 'numeric', year: 'numeric' };
  }
  if (formatName === 'today' || formatName === 'time') {
    return { hour: '2-digit', minute: '2-digit' };
  }

  return { day: 'numeric', month: 'numeric', year: 'numeric' };
};

const localeDate = (date, formatName, addDesignator) => {
  const le = addDesignator ? translate('date.le') : '';
  const dateObject = new Date(date);
  return (
    le +
    (formatName == 'today' ? translate('DisplayDate.today') + ', ' : '') +
    dateObject.toLocaleString(getLanguage(), getFormat(formatName))
  );
};

export { localeDate, getFormat };
