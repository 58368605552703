import { DicomData } from 'app/interfaces/Dicom';
import {
  AssociationParameters,
  FindQueryModel,
  GetQueryDataSet,
  GetQueryModel,
  PACSError,
  QueryDataSet,
  RequestSuccess,
} from 'app/interfaces/PACSCommunication';

const ERROR_STATUS = {
  UNREACHABLE_HOST: 'UNREACHABLE_HOST',
  REJECTED: 'REJECTED',
  NOT_ASSOCIATED: 'NOT_ASSOCIATED',
  BROKEN_ASSOCIATION: 'BROKEN_ASSOCIATION',
  INVALID_SOP_CLASS: 'INVALID_SOP_CLASS',
  MISSING_SOP_CLASS: 'MISSING_SOP_CLASS',
  MISSING_INSTANCE_ID: 'MISSING_INSTANCE_ID',
  UNKNOWN: 'UNKNOWN',
};

export interface BasePACSModule {
  associate: (associationParameters: AssociationParameters) => Promise<undefined | PACSError>;
  echo: () => Promise<RequestSuccess | PACSError>;
  store: (dataSet: DicomData) => Promise<RequestSuccess | PACSError>;
  storeMany: (dataSets: DicomData[]) => Promise<PromiseSettledResult<RequestSuccess | PACSError>>;
  find: (
    queryDataSet: QueryDataSet,
    queryModel: FindQueryModel
  ) => Promise<DicomData[] | PACSError>;
  get: (
    queryDataSet: GetQueryDataSet,
    queryModel: GetQueryModel
  ) => Promise<DicomData[] | PACSError>;
  abort: () => Promise<boolean>;
  release: () => Promise<boolean>;
}

type PicoxiaDicomModule = {
  readDicom: (dicomBuffer: Uint8Array | string) => DicomData;
  writeDicom: (data: DicomData, header?: DicomData, transferSyntax?: string) => Uint8Array;
  writeDicomAsync: (
    data: DicomData,
    header?: DicomData,
    transferSyntax?: string
  ) => Promise<Uint8Array>;
  PACSCommunication: BasePACSModule;
};

const getPicoxiaDicomModule = (): PicoxiaDicomModule => (window as any).nodeModules?.picoxiaDicom;

export default getPicoxiaDicomModule;

export { ERROR_STATUS };
