import { ViewerImage } from 'app/components/Viewer/types';
import { ImageDisplayableMetadata } from 'app/interfaces/Image';
import { Patient } from 'app/interfaces/Patient';
import {
  getDicomDataValue,
  makeStudyDescriptionFromAnatomicRegion,
} from 'app/utils/dicom/DicomData';
import isWorkListImage from 'app/utils/isWorkListImage';
import { IntlShape } from 'react-intl';

export default function computeDisplayedImageMetadata(
  image: ViewerImage,
  patient: Patient,
  intl: IntlShape
): ImageDisplayableMetadata {
  if (!image && isWorkListImage) return undefined;

  const { acquisitionConstants, detectorInfo, dicomData, anatomicRegion } = image;
  const {
    StudyDescription = getDicomDataValue(dicomData, 'StudyDescription'),
    SeriesDescription = getDicomDataValue(dicomData, 'SeriesDescription'),
  } = makeStudyDescriptionFromAnatomicRegion(intl, anatomicRegion) || {};

  return {
    referringPhysicianName: patient?.attending_veterinarian,
    responsiblePerson: patient?.owner_name,
    patientName: patient?.name,
    patientSex: patient?.sex,
    patientBirthDate: patient?.birth_date,
    patientSpecie: patient?.specie,
    patientChipID: patient?.chip_id,
    patientPedigreeID: patient?.pedigree_id,
    studyDescription: StudyDescription,
    seriesDescription: SeriesDescription,
    institutionAddress: getDicomDataValue(dicomData, 'InstitutionAddress'),
    institutionName: getDicomDataValue(dicomData, 'InstitutionName'),
    acquisitionDateTime: image.acquisitionTime,
    pixelSpacing: detectorInfo?.pixelSpacing,
    kv: acquisitionConstants?.kV,
    mA: acquisitionConstants?.mA,
    mAs:
      acquisitionConstants?.mAs || acquisitionConstants?.mA * acquisitionConstants?.s || undefined,
  };
}
