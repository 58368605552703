import * as _ from 'lodash';

const predictionsMerger = (objValue, srcValue) => {
  if (!Number.isNaN(+srcValue) && (objValue === undefined || !Number.isNaN(+objValue))) {
    return objValue === undefined ? srcValue : Math.max(objValue, srcValue);
  }
  if (_.isObject(srcValue)) {
    return _.mergeWith(objValue, srcValue, predictionsMerger);
  }
  return undefined;
};

// eslint-disable-next-line import/prefer-default-export
export { predictionsMerger };
