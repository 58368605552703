export const getFirstAnnotationValue = <T>(
  toolAnnotations: Record<string, T> | Map<string, T>
): T => {
  if (toolAnnotations === undefined) return undefined;
  if (toolAnnotations instanceof Map) {
    return toolAnnotations?.values()?.next()?.value;
  }
  if (toolAnnotations instanceof Object) {
    return Object.values(toolAnnotations)[0];
  }
  return undefined;
};
