import _ from 'lodash';

const DAYS_IN_MS = 24 * 60 * 60 * 1000;
const WEEKS_IN_MS = 7 * DAYS_IN_MS;

const diffInWeeks = ({ start, end }) => (end - start) / WEEKS_IN_MS;

const checkForRangeIntersection = (range, ranges) =>
  ranges.map(({ start, end }) => range.start <= end && range.end >= start);

const mergeIntersectingRanges = (ranges) => ({
  start: _.minBy(ranges, 'start').start,
  end: _.maxBy(ranges, 'end').end,
});

export default function computeUserMeanWeekUsageFromSubscriptions(subscriptions) {
  if (subscriptions.length === 0) return 0;
  try {
    const cumulatedCount = _.sumBy(subscriptions, 'used');

    // Split all subscription into non overlapping ranges to compute total weeks usage.
    const nonOverlappingRanges = _.reduce(
      subscriptions,
      (accumulatedRanges, subscription) => {
        const currentRange = { start: subscription.buyDate, end: subscription.currentPeriodEnd };
        const rangeIntersections = checkForRangeIntersection(currentRange, accumulatedRanges);

        if (_.some(rangeIntersections)) {
          const isIntersecting = (_v, index) => rangeIntersections[index];
          const intersectingRanges = _.filter(accumulatedRanges, isIntersecting);
          const nonIntersectingRanges = _.reject(accumulatedRanges, isIntersecting);

          const intersectionRange = mergeIntersectingRanges([...intersectingRanges, currentRange]);

          return [...nonIntersectingRanges, intersectionRange];
        }

        return [...accumulatedRanges, currentRange];
      },
      []
    );

    const now = new Date();
    const nonOverlappingRangesToToday = nonOverlappingRanges.map(({ start, end }) => ({
      start: _.min([start, now]),
      end: _.min([end, now]),
    }));

    const totalWeeksUsage = _.sum(nonOverlappingRangesToToday.map(diffInWeeks));

    return cumulatedCount / totalWeeksUsage;
  } catch {
    return 0;
  }
}
