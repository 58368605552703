import { triggerEvent } from 'app/CornerstoneTools';
import moveNewHandle from 'app/CornerstoneTools/moveNewHandle';
import * as cst from 'cornerstone-tools';

const makeMoveHandlerChain = (
  { element },
  tool,
  measurementData,
  interactionType,
  previousHandler,
  [currentHandlerKey, ...nextHandlersKeys]
) => {
  if (!measurementData.handles[currentHandlerKey]) {
    measurementData.handles[currentHandlerKey] = { ...previousHandler };
  }
  const callbacks = {};

  callbacks.cancelFn = moveNewHandle(
    { element },
    tool.name,
    measurementData,
    measurementData.handles[currentHandlerKey],
    tool.options,
    interactionType,
    (success) => {
      measurementData.active = false;
      if (measurementData.cancelled || !success) {
        cst.removeToolState(element, tool.name, measurementData);
        return;
      }

      if (nextHandlersKeys.length === 0) {
        const eventType = cst.EVENTS.MEASUREMENT_COMPLETED;
        measurementData.completed = true;
        const measurementEventData = {
          toolName: tool.name,
          toolType: tool.name,
          element,
          measurementData,
        };

        triggerEvent(element, eventType, measurementEventData);
        return;
      }

      const nextHandlerCallbacks = makeMoveHandlerChain(
        { element },
        tool,
        measurementData,
        interactionType,
        measurementData.handles[currentHandlerKey],
        nextHandlersKeys
      );

      Object.assign(callbacks, nextHandlerCallbacks);
    }
  );
  return callbacks;
};

export default makeMoveHandlerChain;
