import { getImage, getViewport } from 'cornerstone-core';
import _ from 'lodash';

import { ExportableStudy } from 'app/components/Dropzone/ExportStudyModal/exportStudy';
import { DropzoneStudy } from 'app/interfaces/Study';
import isWorkListImage from 'app/utils/isWorkListImage';
import { ImageId, SelectableImage } from 'app/components/ImagesSelector';
import { DisplayableImageData, Viewport } from 'app/interfaces/Image';
import IDicomBuilder from 'app/interfaces/IDicomBuilder';
import { injectProcessedDicomData } from 'app/utils/RadioImageData';
import { DicomTransferSyntax } from 'app/interfaces/Dicom';
import { BasicRendererAnnotationsForDisplay } from 'app/interfaces/ImageRenderer';

type ImageBaseForSelectable = {
  origin?: string;
  id: ImageId;
  cornerstoneRef: HTMLElement | undefined;
};

type ViewerImageBaseForSelectable = {
  origin?: string;
  id: ImageId;
  displayableImage?: DisplayableImageData;
  viewport?: Viewport;
  annotations?: BasicRendererAnnotationsForDisplay;
};

export const formatViewerImagesToSelectableImages = <T extends ViewerImageBaseForSelectable>(
  studyImages: T[]
): (T & SelectableImage)[] =>
  _(studyImages)
    .reject(isWorkListImage)
    .map((image) => ({
      ...image,
      id: image.id,
      image: image.displayableImage,
      viewport: image.viewport,
      annotations: image.annotations,
    }))
    .filter()
    .value();

export const formatStudyImagesToExportableImages = <T extends ImageBaseForSelectable>(
  studyImages: T[],
  dicomBuilder: IDicomBuilder,
  transferSyntax?: DicomTransferSyntax
): (T & SelectableImage)[] =>
  _(studyImages)
    .reject(isWorkListImage)
    .map((image) => {
      try {
        const cscImage = getImage(image.cornerstoneRef);
        if (!cscImage) return undefined;
        return {
          ...image,
          id: image.id,
          image: cscImage,
          viewport: getViewport(image.cornerstoneRef) as unknown as Viewport,
          mainRenderer: { element: image.cornerstoneRef },
          computeDicomData: () =>
            injectProcessedDicomData(dicomBuilder, image, true, transferSyntax),
        };
      } catch {
        return undefined;
      }
    })
    .filter()
    .value();

export default function (
  study: DropzoneStudy,
  dicomBuilder: IDicomBuilder,
  transferSyntax?: DicomTransferSyntax
): ExportableStudy & { images: SelectableImage[] } {
  return {
    ...study,
    images: formatStudyImagesToExportableImages(study.images, dicomBuilder, transferSyntax),
  };
}
