class ElementToolStateManager {
  constructor(element) {
    this.toolState = {};
    this.element = element;
  }

  get = (_element, toolName) => this.toolState[toolName];

  add = (_element, toolName, data) => {
    if (!this.toolState[toolName]) {
      this.toolState[toolName] = { data: [] };
    }
    this.toolState[toolName].data.push(data);
  };

  clear = () => {
    this.toolState = {};
  };
}

export { ElementToolStateManager };
