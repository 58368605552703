import './style.scss';

import React from 'react';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';

class DysplasiaPattern extends React.PureComponent {
  render() {
    const { content, left, right, isTool } = this.props;
    return (
      <Button.Group className="dysplasia no-hover" size="tiny" compact>
        <Button className={`value ${left.color}`} basic={isTool} compact>
          {left.value}
        </Button>
        <Button className="pattern" basic compact>
          {content}
        </Button>
        <Button className={`value ${right.color}`} basic={isTool} compact>
          {right.value}
        </Button>
      </Button.Group>
    );
  }
}

const valueShape = { value: pt.oneOfType([pt.string, pt.element]), color: pt.any };

DysplasiaPattern.propTypes = {
  content: pt.element.isRequired,
  left: pt.shape(valueShape).isRequired,
  right: pt.shape(valueShape).isRequired,
  isTool: pt.bool,
};
DysplasiaPattern.defaultProps = {
  isTool: false,
};

export default injectIntl(DysplasiaPattern);
