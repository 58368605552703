import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { DetectorKind } from 'app/redux/flatPanelConfiguration/actions';

export type DetectorKindDropdownProps = {
  value: DetectorKind;
  onChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: { value: DetectorKind }
  ) => void;
} & Omit<DropdownProps, 'onChange' | 'value'>;

function DetectorKindDropdown({
  value: detectorKind,
  onChange,
  ...dropdownProps
}: DetectorKindDropdownProps) {
  const intl = useIntl();

  const options = useMemo(
    () => [
      { key: 1, value: undefined, text: intl.formatMessage({ id: 'flat_panel.kind.ANY' }) },
      { key: 2, value: 'GER', text: intl.formatMessage({ id: 'flat_panel.kind.GER' }) },
      {
        key: 3,
        value: 'THALES',
        text: intl.formatMessage({ id: 'flat_panel.kind.THALES' }),
      },
    ],
    [intl]
  );

  const selectedText = _.find(options, ({ value }) => value === detectorKind).text;

  const onDetectorSelected = useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
      onChange(event, { value: data.value as DetectorKind }),
    [onChange]
  );

  return (
    <Dropdown
      {...dropdownProps}
      options={options}
      value={detectorKind}
      onChange={onDetectorSelected}
      trigger={selectedText}
    />
  );
}

export default DetectorKindDropdown;
