import ApiCalls from 'app/utils/apiCalls';
import { changeLocale } from './actions';
import { USER_SET_LANGUAGE } from './constants';

const languageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_SET_LANGUAGE: {
      store.dispatch(changeLocale(action.locale));
      localStorage.setItem('language', action.locale);

      const isLoggedIn = store.getState().get('global').get('loggedIn');
      if (isLoggedIn) {
        ApiCalls.setLanguage(action.locale);
      }

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default languageMiddleware;
