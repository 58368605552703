import ApiCalls from 'app/utils/apiCalls';

let localStorage;

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === 'test') {
  localStorage = require('localStorage');
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage;
}

const NO_TOKEN = 'NO_TOKEN';

const auth = {
  logout() {
    return ApiCalls.logout().finally(auth.removeToken);
  },

  removeToken() {
    auth.setLocalToken(NO_TOKEN);
  },

  setLocalToken(value) {
    localStorage.loggedInToken = value;
  },

  getLocalToken() {
    return localStorage.loggedInToken;
  },

  loggedIn() {
    const localToken = auth.getLocalToken();
    return localToken && localToken !== 'undefined' && localToken !== NO_TOKEN;
  },

  register(data) {
    return ApiCalls.register(data).then((response) => ({ ...response, success: true }));
  },

  changePassword(token, data) {
    return ApiCalls.changePassword(token, data)
      .then((response) => {
        return auth.login(response.data.mail, data.password);
      })
      .catch((error) => ({ error }));
  },

  confirmMail(data) {
    return ApiCalls.confirmMail(data)
      .then((response) => {
        auth.setLocalToken(response.data.token);
        return response;
      })
      .catch((error) => ({ error }));
  },

  unsubscribeMail(unsubscribe_token) {
    return ApiCalls.unsubscribeMail(unsubscribe_token)
      .then((response) => {
        auth.setLocalToken(response.data.token);
        return response;
      })
      .catch((error) => ({ error }));
  },

  sendResetPasswordMail(data) {
    return ApiCalls.sendResetPasswordMail(data).catch((error) => ({ error }));
  },

  login(username, password) {
    if (auth.loggedIn()) {
      auth.removeToken();
    }
    return ApiCalls.login({ username, password })
      .then((response) => {
        auth.setLocalToken(response.data.token);
        return { response };
      })
      .catch((error) => ({ error }));
  },
};

export default auth;
