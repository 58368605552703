import './style.scss';

import React from 'react';
import ImagesSelector, { ImageSelectorProps } from 'app/components/ImagesSelector';
import StaticImageRenderer, { ImageRendererProps } from 'app/components/StaticImageRenderer';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';

type ImageExportRendererProps = ImageRendererProps & {
  computeDicomData?: any;
  isDicomExport: boolean;
};

function ImageExportRenderer(
  { isDicomExport, computeDicomData, ...renderProps }: ImageExportRendererProps,
  index: number
) {
  const intl = useIntl();

  return (
    <div className="export-image__image">
      <StaticImageRenderer {...renderProps} />
      {isDicomExport && !computeDicomData && (
        <div className="export-image__image__warning-status">
          <Popup
            trigger={
              <Icon
                name="warning sign"
                color="yellow"
                data-testid={'exportStudyModal.warningMessage.noDicom'}
              />
            }
            content={<FormattedMessage id="exportStudyModal.warningMessage.noDicom" />}
          />
        </div>
      )}
    </div>
  );
}

export type ImagesExportSelectorProps = ImageSelectorProps & {
  images: { computeDicomData?: any }[];
  isDicomExport: boolean;
};

export default function ImagesExportSelector(props: ImagesExportSelectorProps) {
  const imageRender = (renderProps: ImageRendererProps, index: number) => (
    <ImageExportRenderer
      isDicomExport={props.isDicomExport}
      {...renderProps}
      computeDicomData={props.images[index]?.computeDicomData}
    />
  );
  return <ImagesSelector {...props} imageRender={imageRender} />;
}
