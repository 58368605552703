/* eslint-disable max-len */
import AppHelmet from 'app/components/AppHelmet';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import React from 'react';
import Slider from 'react-slick';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'app/components/UIComponents/Button';
import Hook from 'app/components/UIComponents/Hook';
import Gradient from 'app/components/UIComponents/Gradient';
import CardsList from 'app/components/UIComponents/CardsList';
import Card from 'app/components/UIComponents/CardsList/Card';

import './style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ViewerScreenshotEn from './images/viewer-screenshot-en.png';
import WorklistScreenshotEn from './images/worklist-screenshot-en.png';
import CloudIcon from './images/icon-cloud.svg?url';
import VetIcon from './images/icon-vet.svg?url';
import AIIcon from './images/icon-ai.svg?url';
import RadioIcon from './images/icon-radio.svg?url';
import RadioImage1 from './images/radio1.jpg';
import RadioImage2 from './images/radio2.jpg';
import RadioImage3 from './images/radio3.jpg';
import RadioImage4 from './images/radio4.jpg';
import RadioImage5 from './images/radio5.jpg';
import LogoAssistovet from './images/logo_assistovet.svg?url';
import LogoVetoPartner from './images/logo-vetopartner.svg?url';
import MultiSupportIllustration from './images/multi-support.png';

const PicoxIAAcquisitionPage = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const intl = useIntl();

  const radioImages = [RadioImage1, RadioImage3, RadioImage5, RadioImage2, RadioImage4];

  return (
    <div className="picoxia">
      <AppHelmet
        titleIntlID="picoxIAAcquisition.metaTitle"
        descriptionIntlID="picoxIAAcquisition.metaDescription"
      />
      <div className="picoxIAAcquisition">
        <section className="container pageHeader">
          <div className="pageHeader__content">
            <div className="pageHeader__left">
              <h1 className="title title--big">
                <FormattedMessage id="picoxIAAcquisition.header.title" />
              </h1>
              <p>
                <Hook>
                  <FormattedMessage id="picoxIAAcquisition.header.hook" />
                </Hook>
              </p>
              <p className="pageHeader__desc">
                <FormattedMessage id="picoxIAAcquisition.header.text1" />{' '}
                <IntlCrawlableLink url="/picoxia-intelligence">
                  <FormattedMessage id="picoxIAAcquisition.header.picoxIAIntelligenceLink" />
                </IntlCrawlableLink>{' '}
                <FormattedMessage id="picoxIAAcquisition.header.text2" />
              </p>
              <div className="pageHeader__buttons">
                <Button url="/request-demo">
                  <FormattedMessage id="picoxIAAcquisition.header.button.requestDemo" />
                </Button>
              </div>
            </div>
            <div className="pageHeader__screenshots">
              <img
                src={WorklistScreenshotEn}
                alt={intl.formatMessage({ id: 'picoxIAAcquisition.header.worklistScreenshot.alt' })}
                className="pageHeader__screenshot"
              />
              <img
                src={ViewerScreenshotEn}
                alt={intl.formatMessage({ id: 'picoxIAAcquisition.header.viewerScreenshot.alt' })}
                className="pageHeader__screenshot"
              />
            </div>
          </div>
        </section>

        <section className="container stateOfTheArt">
          <div className="stateOfTheArt__content">
            <h2 className="title">
              <FormattedMessage id="picoxIAAcquisition.stateOfTheArt.title" />
            </h2>
            <p>
              <FormattedMessage id="picoxIAAcquisition.stateOfTheArt.text" />
            </p>
          </div>
          <div className="stateOfTheArt__slider">
            <Slider {...sliderSettings}>
              {radioImages.map((radioImage) => (
                <img
                  src={radioImage}
                  alt={intl.formatMessage({ id: 'picoxIAAcquisition.stateOfTheArt.radio.alt' })}
                />
              ))}
            </Slider>
          </div>
        </section>

        <Gradient />
        <section className="container advantages">
          <CardsList>
            <Card>
              <Card.Icon icon={RadioIcon} />
              <Card.Title>
                <FormattedMessage id="picoxIAAcquisition.advantages.simplicity.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAAcquisition.advantages.simplicity.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={AIIcon} />
              <Card.Title>
                <FormattedMessage id="picoxIAAcquisition.advantages.integrated.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAAcquisition.advantages.integrated.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={CloudIcon} />
              <Card.Title>
                <FormattedMessage id="picoxIAAcquisition.advantages.cloud.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAAcquisition.advantages.cloud.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={VetIcon} />
              <Card.Title>
                <FormattedMessage id="picoxIAAcquisition.advantages.byVets.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAAcquisition.advantages.byVets.text" />
              </p>
            </Card>
          </CardsList>
        </section>

        <section className="container uniqueSolution">
          <div className="uniqueSolution__content">
            <h2 className="title">
              <FormattedMessage id="picoxIAAcquisition.uniqueSolution.title" />
            </h2>
            <p>
              <FormattedMessage id="picoxIAAcquisition.uniqueSolution.text" />
            </p>
          </div>
          <div>
            <img
              src={MultiSupportIllustration}
              alt={intl.formatMessage({
                id: 'picoxIAAcquisition.uniqueSolution.multisupportIllustration.alt',
              })}
              className="uniqueSolution__illustration"
            />
          </div>
        </section>

        <section className="container interfaced">
          <div className="interfaced__content">
            <h2 className="title">
              <FormattedMessage id="picoxIAAcquisition.interfaced.title" />
            </h2>
            <p>
              <FormattedMessage id="picoxIAAcquisition.interfaced.text" />
            </p>
            <div className="interfaced__separation" />
            <div className="interfaced__logos">
              <img src={LogoAssistovet} alt="Assistovet" className="interfaced__logo" />
              <img src={LogoVetoPartner} alt="VetoPartner" className="interfaced__logo" />
            </div>
            <p>
              <Hook>
                <FormattedMessage id="picoxIAAcquisition.interfaced.hook" />
              </Hook>
            </p>
            <div className="uniqueSolution__buttons">
              <Button url="/request-demo">
                <FormattedMessage id="picoxIAAcquisition.interfaced.button.contact" />
              </Button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default PicoxIAAcquisitionPage;
