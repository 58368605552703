export const GM_VET_DEFAULT_SPECIES_ALIASES = {
  Chat: 'cat',
  Chien: 'dog',
  'Chien de prairie': 'rodent',
  Chinchilla: 'rodent',
  Cobaye: 'rodent',
  Ecureuil: 'rodent',
  Furet: 'other',
  Gerbille: 'rodent',
  Hamster: 'rodent',
  Lapin: 'rabbit',
  Octodon: 'rodent',
  Oiseau: 'bird',
  Rat: 'rodent',
  Reptile: 'reptile',
  Souris: 'rodent',
  Asin: 'other',
  Bovin: 'other',
  Caprin: 'other',
  Cheval: 'other',
  Ovin: 'other',
  Porcin: 'other',
  Abeilles: 'other',
  Autres: 'other',
} as const;

export const DEFAULT_SPECIES_ALIASES_BY_PMS = {
  gm_vet: GM_VET_DEFAULT_SPECIES_ALIASES,
};
