import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Divider,
  Form,
  FormField,
  Header,
  HeaderContent,
  HeaderSubheader,
  Icon,
  Label,
  Message,
  Modal,
} from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import ApiCalls, { API } from 'app/utils/apiCalls';
import countries from 'app/constants/countries';
import toastifyPromise from 'app/utils/toastifyPromise';
import TeleradiologyAPIContext from 'app/providers/TeleradiologyAPIProvider/context';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import ReactHookFormMessageError from 'app/containers/Teleradiology/MessageError';
import { useDispatch } from 'react-redux';
import updateTeleradiology from 'app/redux/teleradiology/actions';
import FormMandatoryMention from 'app/components/Form/MandatoryMention';
import TeleradiologyPanelButtonAction from 'app/containers/Teleradiology/Panel/ButtonAction';
import { TeleradiologyAffiliateIdentifier } from 'app/interfaces/TeleradiologyAPI';
import TeleradiologyVetFlowModalAffiliateCreateTermsOfUse from './TermsOfUse';
import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';

type FormDataType = {
  lastName: string;
  firstName?: string;
  email: string;
  phoneNumber: string;
  vatNumber: string;
  workplaceName: string;
  address: string;
  postCode: string;
  city: string;
  country: string;
  countryCode: string;
  language: string;
  acceptGeneralTermsOfUse: boolean;
};
/**
 * Modal to display VetFlow Form Affiliate Create
 */
function TeleradiologyVetFlowModalAffiliateCreate() {
  const TeleradiologyApi = useContext(TeleradiologyAPIContext);
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const [isRequestSending, setIsRequestSending] = useState(false);
  const [userDetails, setUserDetails] = useState(undefined);
  const getUserDetails = () => {
    ApiCalls.getUserDetails().then((response) => {
      setUserDetails({
        lastName: response.data.user?.last_name ?? '',
        firstName: response.data.user?.first_name ?? '',
        email: response.data.user.mail,
        phoneNumber: response.data.user?.address?.phoneNumber ?? '',
        workplaceName: response.data.user?.address?.workplaceName ?? '',
        address: response.data.user.address?.address ?? '',
        postCode: response.data.user.address?.postCode ?? '',
        city: response.data.user.address?.city ?? '',
        countryCode: response.data.user.address?.countryCode ?? '',
        country: response.data.user.address?.country ?? '',
        language: response.data.user?.language ?? '',
      });
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const createAffiliate = (formData: FormDataType) => {
    const newAffiliate = {
      companyName: API.Teleradiology.Provider.VEDIM,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      vatNumber: formData.vatNumber,
      clinic: formData.workplaceName,
      address: formData.address,
      city: formData.city,
      postCode: formData.postCode,
      countryCode: formData.countryCode,
      country: formData.country,
      language: formData.language,
    };
    return TeleradiologyApi.createAffiliate(newAffiliate);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    values: userDetails,
  });

  const handleChangeCountryCode = (event: any, { value }: { value: string }) => {
    const country = countries.find(({ countryCode }) => countryCode === value);
    setValue('country', country.name);
    setValue('countryCode', country.countryCode);
  };

  const dispatch = useDispatch();

  const onSubmit = (formData: FormDataType) => {
    setIsRequestSending(true);
    toastifyPromise('teleradiology.vetflow.affiliate-create', createAffiliate(formData))
      .then((affiliateIdentifier: TeleradiologyAffiliateIdentifier) => {
        setIsRequestSending(false);
        setOpen(false);
        getUserDetails();
        dispatch(
          updateTeleradiology({
            type: affiliateIdentifier.type,
            affiliateId: affiliateIdentifier.teleradiology_affiliate_id,
          })
        );
      })
      .catch((error: Error) => {
        getUserDetails();
        setIsRequestSending(false);
        console.error('server-api-error', error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <TeleradiologyPanelButtonAction action="affiliateCreate" onClick={() => setOpen(true)} />
      }
      className="teleradiology-affiliate-create-modal dark-mode"
    >
      <Modal.Header>
        <Header as="h3">
          <TeleradiologyIcon size="big" inverted />
          <HeaderContent>
            <FormattedMessage id="teleradiology.affiliate-create.title" />
            <HeaderSubheader>
              <FormattedMessage id="teleradiology.title" />
            </HeaderSubheader>
          </HeaderContent>
        </Header>
      </Modal.Header>

      <Modal.Content scrolling>
        <Modal.Description>
          {!_.isEmpty(errors) && <ReactHookFormMessageError errors={errors} />}

          <Message info>
            <p>
              <FormattedMessage id="teleradiology.affiliate-create.message.information" />
            </p>
          </Message>

          <FormMandatoryMention />

          <Form loading={userDetails === undefined} onSubmit={handleSubmit(onSubmit)}>
            <Form.Group widths="equal">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'teleradiology.affiliate-create.last-name.error.required',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="text"
                    fluid
                    required
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.last-name.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.last-name.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.lastName}
                  />
                )}
              />

              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="text"
                    fluid
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.first-name.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.first-name.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.firstName}
                  />
                )}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'teleradiology.affiliate-create.email.error.required',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="email"
                    labelPosition="left"
                    fluid
                    required
                    readOnly
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.email.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.email.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.email}
                  >
                    <Label>
                      <Icon fitted name="at" />
                    </Label>
                    <input />
                  </Form.Input>
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: 'teleradiology.affiliate-create.phone-number.error.required',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="text"
                    labelPosition="left"
                    fluid
                    required
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.phone-number.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.phone-number.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.phoneNumber}
                  >
                    <Label>
                      <Icon fitted name="phone" />
                    </Label>
                    <input />
                  </Form.Input>
                )}
              />
            </Form.Group>
            <Controller
              name="vatNumber"
              control={control}
              rules={{
                required: 'teleradiology.affiliate-create.vat-number.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.vat-number.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.vat-number.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.vatNumber}
                />
              )}
            />

            <Divider section />

            <Controller
              name="workplaceName"
              control={control}
              rules={{
                required: 'teleradiology.affiliate-create.workplace-name.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  labelPosition="left"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.workplace-name.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.workplace-name.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.workplaceName}
                >
                  <Label>
                    <Icon fitted name="home" />
                  </Label>
                  <input />
                </Form.Input>
              )}
            />

            <Controller
              name="address"
              control={control}
              rules={{
                required: 'teleradiology.affiliate-create.address.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.address.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'teleradiology.affiliate-create.address.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.address}
                />
              )}
            />
            <Form.Group widths="equal">
              <Controller
                name="postCode"
                control={control}
                rules={{
                  required: 'teleradiology.affiliate-create.post-code.error.required',
                  minLength: {
                    value: 5,
                    message: 'teleradiology.affiliate-create.post-code.error.length',
                  },
                  maxLength: {
                    value: 5,
                    message: 'teleradiology.affiliate-create.post-code.error.length',
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="text"
                    fluid
                    required
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.post-code.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.post-code.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.postCode}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'teleradiology.affiliate-create.city.error.required',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Form.Input
                    type="text"
                    fluid
                    required
                    label={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.city.label',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'teleradiology.affiliate-create.city.placeholder',
                    })}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.city}
                  />
                )}
              />
            </Form.Group>

            <Controller
              name="countryCode"
              control={control}
              rules={{
                required: 'teleradiology.affiliate-create.country.error.required',
              }}
              render={({ field: { onBlur, value } }) => (
                <FormField required error={!!errors.countryCode}>
                  <label>
                    <FormattedMessage id="teleradiology.affiliate-create.country.label" />
                  </label>
                  <DropdownCountriesList
                    fluid
                    value={value}
                    onChange={handleChangeCountryCode}
                    onBlur={onBlur}
                    error={!!errors.countryCode}
                    upward
                  />
                </FormField>
              )}
            />

            <Controller
              name="acceptGeneralTermsOfUse"
              control={control}
              rules={{
                required:
                  'teleradiology.affiliate-create.accept-general-terms-of-use.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Checkbox
                  required
                  label={
                    <label
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange(!value);
                      }}
                    >
                      <FormattedMessage id="teleradiology.affiliate-create.accept-general-terms-of-use.label" />
                      &nbsp;
                      <TeleradiologyVetFlowModalAffiliateCreateTermsOfUse
                        onAcceptClick={() => onChange(true)}
                      />
                    </label>
                  }
                  checked={value}
                  onChange={(_event, { checked }) => onChange(checked)}
                  onBlur={onBlur}
                  error={!!errors.acceptGeneralTermsOfUse}
                />
              )}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          <Icon name="remove" /> <FormattedMessage id="general.cancel" />
        </Button>
        <Button
          color="green"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isRequestSending}
        >
          <Icon name="check" /> <FormattedMessage id="general.create" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default TeleradiologyVetFlowModalAffiliateCreate;
