import { forEach } from 'lodash';

const REACT_SELECT_TOOL_BAR_STYLE = {
  container: (provided) => ({
    ...provided,
    color: 'white',
    border: 'none',
    textAlign: 'center',
    borderRadius: '5px',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    minHeight: '0',
    justifyContent: 'center',
    borderColor: 'inherit',
    boxShadow: 'none',
    flexWrap: 'nowrap',
    ':hover': {
      borderColor: 'inherit',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#444645',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
  }),
};
const updateToolsList = (
  toolsList,
  name,
  produceImageToolsList,
  produceCommonToolsList,
  setToolStateFn,
  newState
) => {
  if (!toolsList?.[name]) return;
  const { mouseButtonMask } = toolsList[name].options;

  if (setToolStateFn) setToolStateFn(newState);

  const toolUpdateFn = (draftTool, toolName) => {
    if (toolName === name) {
      draftTool.state = newState;
    } else {
      // Early exit if there is no button conflict with this specific tool.
      if (draftTool.options.mouseButtonMask !== mouseButtonMask) return;
      // Only disable 'active' tools when we switch to an 'active' tool.
      if (newState === 'active' && draftTool.state === 'active') {
        draftTool.state = 'passive';
      }
    }
  };

  produceImageToolsList((draftToolsList) => {
    forEach(draftToolsList, toolUpdateFn);
  });

  produceCommonToolsList((draftToolsList) => {
    forEach(draftToolsList, toolUpdateFn);
  });
};

export default updateToolsList;
export { updateToolsList, REACT_SELECT_TOOL_BAR_STYLE };
