/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Accordion, Icon, Button } from 'semantic-ui-react';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import AppHelmet from 'app/components/AppHelmet';

const sections = [
  {
    titleId: 'cheatSheet.section.thorax',
    image: 'thorax.png',
    sheets: [
      {
        header: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_1.label',
        desc: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_1.desc',
        ref: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_1.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_2.label',
        desc: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_2.desc',
        ref: 'cheatSheet.Le_diagnostic_differentiel_des_opacites_pulmonaires_2.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.Hemorragie_et_contusions_pulmonaires.label',
        desc: 'cheatSheet.Hemorragie_et_contusions_pulmonaires.desc',
        ref: 'cheatSheet.Hemorragie_et_contusions_pulmonaires.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.La_torsion_lobaire.label',
        desc: 'cheatSheet.La_torsion_lobaire.desc',
        ref: 'cheatSheet.La_torsion_lobaire.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.Lasthme_felin.label',
        desc: 'cheatSheet.Lasthme_felin.desc',
        ref: 'cheatSheet.Lasthme_felin.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.Les_bronchopneumonies.label',
        desc: 'cheatSheet.Les_bronchopneumonies.desc',
        ref: 'cheatSheet.Les_bronchopneumonies.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.Loedeme_pulmonaire.label',
        desc: 'cheatSheet.Loedeme_pulmonaire.desc',
        ref: 'cheatSheet.Loedeme_pulmonaire.picoxiaref',
        image: 'thorax.png',
      },
      {
        header: 'cheatSheet.La_thrombo-embolie_pulmonaire.label',
        desc: 'cheatSheet.La_thrombo-embolie_pulmonaire.desc',
        ref: 'cheatSheet.La_thrombo-embolie_pulmonaire.picoxiaref',
        image: 'thorax.png',
      },
    ],
  },
  {
    titleId: 'cheatSheet.section.NAC',
    sheets: [
      {
        header: 'cheatSheet.La_stase_digestive_lapin.label',
        desc: 'cheatSheet.La_stase_digestive_lapin.desc',
        ref: 'cheatSheet.La_stase_digestive_lapin.picoxiaref',
        image: 'rabbitIcon.png',
      },
    ],
  },
];

export class CheatSheetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  renderSections = (activeIndex) =>
    sections.map((section, index) => (
      <section key={index}>
        <Accordion.Title
          as="h2"
          style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '0', marginTop: '1rem' }}
          active={activeIndex === index}
          index={index}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          {this.formatMessage({ id: section.titleId })}
        </Accordion.Title>

        <div
          className="ui custom divider"
          style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
        />
        <Accordion.Content active={activeIndex === index}>
          <div className="ui cards" style={{ marginTop: '10px' }}>
            {this.renderCards(section.sheets)}
          </div>
        </Accordion.Content>
      </section>
    ));

  renderCards = (sheets) =>
    sheets.map((sheet, index) => (
      <article className="card" key={index} style={{ minWidth: '17rem' }}>
        <div className="content">
          <h3 className="header" style={{ textAlign: 'center' }}>
            {this.formatMessage({ id: sheet.header })}
          </h3>
        </div>
        <div className="content" style={{ textAlign: 'center' }}>
          <img
            className="section picture"
            src={require(`../../images/Desktop/${sheet.image}`)}
            style={{ maxWidth: '50px' }}
            alt=""
          />
          <p style={{ marginTop: '1rem' }}>{this.formatMessage({ id: sheet.desc })}</p>
        </div>
        {!this.props.isLoggedIn
          ? this.renderNotConnectedButton()
          : this.renderConnectedButton(sheet)}
      </article>
    ));

  renderNotConnectedButton = () => (
    <IntlCrawlableLink url="/register" className="ui button blue bottom attached">
      <i className="eye icon" />
      <FormattedMessage id="cheatSheet.button.notConnected" />
    </IntlCrawlableLink>
  );

  renderConnectedButton = (sheet) => (
    <Button
      className="bottom attached picoxiaLightGreen greenHover"
      href={`/${this.formatMessage({ id: sheet.ref })}`}
    >
      <i className="download icon" />
      <FormattedMessage id="cheatSheet.button.download" />
    </Button>
  );

  render() {
    return (
      <div>
        <AppHelmet
          titleIntlID="cheatSheet.pageTitle"
          descriptionIntlID="cheatSheet.pageDescription"
        />
        <div className="ui container" style={{ paddingTop: '20px' }}>
          <h1 style={{ fontSize: '2.7em', textAlign: 'center', marginBottom: '0px' }}>
            <FormattedMessage id="cheatSheet.pageTitle" />
          </h1>
          <div
            className="ui custom divider"
            style={{ width: '30%', marginTop: '20px', marginBottom: '5px' }}
          />
          <p
            style={{
              marginTop: '20px',
              textAlign: 'center',
              fontSize: '1.2em',
              lineHeight: '1.3em',
            }}
          >
            <FormattedMessage
              id="cheatSheet.pageSubtitle"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </p>
          <Accordion style={{ marginTop: '30px', marginBottom: '5rem' }}>
            {this.renderSections(this.state.activeIndex)}
          </Accordion>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheatSheetPage));
