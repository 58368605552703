import { ViewerImage, ViewerImages } from 'app/components/Viewer/types';
import _ from 'lodash';
import { useEffect } from 'react';

export type Effect = (image: ViewerImage, imageId: string, prevImage: ViewerImage) => void;

const useImagesEffect = (
  effect: Effect,
  images: ViewerImages,
  prevImages: ViewerImages,
  imageDeps: any[] = undefined
) => {
  useEffect(() => {
    const imagesIds = _.uniq([...Object.keys(images), ...Object.keys(prevImages ?? {})]);

    const differencesMap = _.fromPairs(
      imagesIds.map((imageId) => [imageId, [prevImages?.[imageId], images?.[imageId]]])
    );

    _.forEach(differencesMap, ([prevImage, image], imageId: string) => {
      let imagesValuesToCompare: any[] = [image];
      let prevImagesValuesToCompare: any[] = [prevImage];
      if (Array.isArray(imageDeps)) {
        imagesValuesToCompare = _.at(image, imageDeps);
        prevImagesValuesToCompare = _.at(prevImage, imageDeps);
      }
      const isAnyImageDifference = imagesValuesToCompare.some(
        (value, index) => value !== prevImagesValuesToCompare?.[index]
      );
      if (isAnyImageDifference) {
        effect(image, imageId, prevImage);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, prevImages, imageDeps]);
};

export const rejectImageDeletionEffect =
  (fn: Effect) => (image: ViewerImage, imageId: string, prevImage: ViewerImage) =>
    image && fn(image, imageId, prevImage);

export default useImagesEffect;
