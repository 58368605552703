import { getHandleNearImagePoint, store } from 'app/CornerstoneTools';

export default (element, data, coords, interactionType) => {
  if (data.visible === false) {
    return false;
  }
  const nearDistance =
    interactionType === 'mouse' ? store.state.clickProximity : store.state.touchProximity;

  const nearHandleInRange = getHandleNearImagePoint(element, data.handles, coords, nearDistance);
  return nearHandleInRange !== undefined;
};
