import { fromJS } from 'immutable';
import { SET_IMAGE_ID, SET_STUDY_ID } from './constants';

const currentStudyInfosState = fromJS({
  studyId: undefined,
  imageId: undefined,
});

const currentStudyInfosReducer = (state = currentStudyInfosState, { type, payload }) => {
  switch (type) {
    case SET_STUDY_ID:
      return state.set('studyId', payload.studyId);
    case SET_IMAGE_ID:
      return state.set('imageId', payload.imageId);
    default:
      return state;
  }
};

export const selectCurrentStudyInfos = (state) => state.get('currentStudyInfos');
export const selectCurrentStudyId = (state) => state.get('currentStudyInfos').get('studyId');
export const selectCurrentImageId = (state) => state.get('currentStudyInfos').get('imageId');

export default currentStudyInfosReducer;
