import 'app/styles/style.scss';
import './style.scss';

import React, { useState } from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Icon, Modal, Popup } from 'semantic-ui-react';

import ExamSelector from '../ExamSelector';
import ExamIcon from '../ExamIcon';
import { DEFAULT_SPECIE, SpeciesType } from 'app/constants/species';
import { AnatomicRegionDetailed } from 'app/interfaces/Image';

type EditAnatomicRegionModalProps = {
  specie?: SpeciesType;
  image: {
    anatomicRegion: string;
  };
  onNewAnatomicRegion?: (newAnatomicRegion: string) => void;
};

function EditAnatomicRegionModal({
  specie,
  image,
  onNewAnatomicRegion,
}: EditAnatomicRegionModalProps) {
  const [open, setOpen] = useState(false);

  const onAnatomicRegionSelected = ({
    specie: selectedSpecie,
    mainRegion,
    subRegion,
    view,
  }: AnatomicRegionDetailed) => {
    const anatomicRegion = _.filter([selectedSpecie, mainRegion, subRegion, view], _.identity).join(
      ' '
    );
    onNewAnatomicRegion(anatomicRegion);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      trigger={
        <Popup
          content={<FormattedMessage id="dropzone.edit_anatomic_region.tooltip" />}
          trigger={
            <button
              type="button"
              data-testid="edit-anatomic-region"
              className="thumbnail-icon large icon edit-anatomic-region"
              onClick={() => setOpen(true)}
            >
              {/* <i style={{ backgroundColor: 'white' }} className="svg-icon pencil" /> */}
              <Icon fitted name="pencil" />
            </button>
          }
        />
      }
      className="anatomic-region-selection-modal"
    >
      <div
        className="anatomic-region-selection-modal__content"
        data-testid="edit-anatomic-region-modal"
      >
        <h2>
          <FormattedMessage id="dropzone.edit_anatomic_region.title" />
        </h2>
        <div>
          <div className="flex" style={{ alignItems: 'center' }}>
            <div style={{ flexDirection: 'column' }} className="flex center">
              <h3>
                <FormattedMessage id="dropzone.edit_anatomic_region.current_region" />
              </h3>
              <ExamIcon style={{ height: '130px', width: '130px' }} exam={image.anatomicRegion} />
            </div>
            <ExamSelector specie={specie} onComplete={onAnatomicRegionSelected} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

EditAnatomicRegionModal.defaultProps = {
  specie: DEFAULT_SPECIE,
  onNewAnatomicRegion: () => {},
};

// class EditAnatomicRegionModal extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       opened,
//     };
//   }

//   render() {}
// }

export default React.memo(EditAnatomicRegionModal);
