const isElectron = () => process.env.PLATFORM !== 'website';

const isDev = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev';

const isStaging = () => process.env.MODE === 'staging';

// Webpack injection.
// eslint-disable-next-line no-undef
const isAcquisitionDemo = () => globalThis.IS_ACQUISITION_DEMO || IS_ACQUISITION_DEMO;

const getAppName = () => process.env.APP_NAME;

module.exports = { isElectron, isDev, isStaging, getAppName, isAcquisitionDemo };
