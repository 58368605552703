// Function to download data to a file
export default function downloadFile(data, filename, openOnSave = true) {
  const file = data;
  if (process.env.PLATFORM !== 'website' && openOnSave) {
    const url = URL.createObjectURL(file);
    const { ipcRenderer, shell } = window.nodeModules?.electron;
    ipcRenderer
      .invoke('download', { url, options: { saveAs: true, filename } })
      .then((filePath) => {
        window.URL.revokeObjectURL(url);
        shell.openPath(filePath).then((ret) => {
          if (ret !== '') {
            console.warn('openPath failure', ret);
          }
        });
      });
    return;
  }
  // var file = new Blob([data], {type: type});
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
