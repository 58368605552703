import * as csc from 'cornerstone-core';

export default function forceImageUpdate(element) {
  const enabledElement = csc.getEnabledElement(element);

  if (enabledElement.image) {
    csc.updateImage(element);
  }
}

const forceImageUpdateWithPromise = async (element) =>
  new Promise((resolve, reject) => {
    element.addEventListener(csc.EVENTS.IMAGE_RENDERED, resolve, { once: true });
    element.addEventListener(csc.EVENTS.IMAGE_LOAD_FAILED, reject, { once: true });
    forceImageUpdate(element);
  });

export { forceImageUpdateWithPromise, forceImageUpdate };
