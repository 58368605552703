export const VALID_PREDICTION_TYPES = [
  'entirebody_profil',
  'entirebody_face',
  'thorax_profil',
  'thorax_face',
  'pelvis_face',
  'abdomen_profil',
  'abdomen_face',
] as const;
export function isValidPredictionType(type: string): type is typeof VALID_PREDICTION_TYPES[number] {
  return (VALID_PREDICTION_TYPES as unknown as string[]).includes(type);
}

export const THORAX_PREDICTION_TYPES = [
  'thorax_profil',
  'thorax_face',
  'entirebody_profil',
  'entirebody_face',
] as const;
export function isThoraxPredictionType(
  type: string
): type is typeof THORAX_PREDICTION_TYPES[number] {
  return (THORAX_PREDICTION_TYPES as unknown as string[]).includes(type);
}

export const ABDOMEN_PREDICTION_TYPES = [
  'abdomen_profil',
  'abdomen_face',
  'entirebody_profil',
  'entirebody_face',
] as const;
export function isAbdomenPredictionType(
  type: string
): type is typeof ABDOMEN_PREDICTION_TYPES[number] {
  return (ABDOMEN_PREDICTION_TYPES as unknown as string[]).includes(type);
}

export const PELVIS_PREDICTION_TYPES = ['pelvis_face'] as const;
export function isPelvisPredictionType(
  type: string
): type is typeof PELVIS_PREDICTION_TYPES[number] {
  return (PELVIS_PREDICTION_TYPES as unknown as string[]).includes(type);
}

export const FRONTAL_VIEW_TYPES = [
  'pelvis_face',
  'thorax_face',
  'abdomen_face',
  'entirebody_face',
] as const;
export function isFrontalViewType(type: string): type is typeof FRONTAL_VIEW_TYPES[number] {
  return (FRONTAL_VIEW_TYPES as unknown as string[]).includes(type);
}
export const SIDE_VIEW_TYPES = ['thorax_profil', 'entirebody_profil', 'abdomen_profil'] as const;
export function isSideViewType(type: string): type is typeof SIDE_VIEW_TYPES[number] {
  return (SIDE_VIEW_TYPES as unknown as string[]).includes(type);
}

export default VALID_PREDICTION_TYPES;
