import * as csm from 'cornerstone-math';

const computeRank3Determinant = ([a, b, c], [d, e, f], [g, h, i]) =>
  a * e * i + b * f * g + c * d * h - c * e * g - b * d * i - a * f * h;

// Implementation taken from here:
// https://fr.wikipedia.org/wiki/Cercle_circonscrit_%C3%A0_un_triangle
export default (A, B, C) => {
  if (!A || !B || !C) return undefined;
  const { x: xa, y: ya } = A;
  const { x: xb, y: yb } = B;
  const { x: xc, y: yc } = C;

  const coordinatesDeterminant = 2 * computeRank3Determinant([xa, ya, 1], [xb, yb, 1], [xc, yc, 1]);

  if (Math.abs(coordinatesDeterminant) < 10 ** -12) return undefined;

  const a = xa ** 2 + ya ** 2;
  const b = xb ** 2 + yb ** 2;
  const c = xc ** 2 + yc ** 2;

  const x0 = computeRank3Determinant([a, ya, 1], [b, yb, 1], [c, yc, 1]) / coordinatesDeterminant;
  const y0 = -computeRank3Determinant([a, xa, 1], [b, xb, 1], [c, xc, 1]) / coordinatesDeterminant;

  const center = { x: x0, y: y0 };
  const radius = csm.point.distance(center, A);

  return { center, radius };
};
