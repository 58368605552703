/* eslint-disable no-bitwise */
import GeneratorMessageError from 'app/xray/generator/GeneratorMessageError';
import _ from 'lodash';

/**
 * @param {Number} values
 * @param {Number} byteLengths Number of bytes of each value ,since we have to
 * encode words in LSB
 * @returns {[Number]}
 */
const toLSBBytes = (value, byteLength = 1) =>
  _.times(byteLength, (shift) => (value >> (8 * shift)) & 0xff);

/**
 * @param {[Number]} bytes
 * @returns {Number}
 */
const fromLSBBytes = (bytes) =>
  bytes.reduce((value, byte, index) => value + byte * 2 ** (8 * index));

/**
 * @param {[Number]} values
 * @param {[Number]} byteLengths Number of bytes of each value ,since we have to
 * encode words in LSB
 * @returns {Buffer}
 */
export const encode = (values, byteLengths) => {
  // Since
  const lsbBytesValues = _.map(values, (value, index) => toLSBBytes(value, byteLengths?.[index]));

  const checksum = _.reduce(_.flatten(lsbBytesValues), (prev, curr) => prev ^ curr);

  const hexStringValues = [...lsbBytesValues, [checksum]].map((bytesValue) =>
    bytesValue.map((byteValue) => byteValue.toString(16).padStart(2, '0')).join('')
  );

  return Buffer.from(hexStringValues.join(''), 'ascii');
};

/**
 *
 * @param {Buffer} buffer
 * @param {[Number]} bytesLengths Number of bytes of each value ,since we have to
 * encode words in LSB
 * @return {[Number]}
 */
export const decode = (buffer, bytesLengths = []) => {
  const checksum = parseInt(buffer.subarray(-2).toString(), 16);
  const bytes = _.times(buffer.length / 2 - 1, (iteration) =>
    parseInt(buffer.subarray(2 * iteration, 2 * iteration + 2).toString(), 16)
  );
  const bytesChecksum = _.reduce(bytes, (prev, curr) => prev ^ curr);

  if (checksum !== bytesChecksum) throw new GeneratorMessageError('Invalid Checksum');

  const bytesLengthsCompleted = [
    ...bytesLengths,
    ..._.times(bytes.length - _.sum(bytesLengths), () => 1),
  ];

  let bytesArrayIndex = 0;
  const decodedBuffer = bytesLengthsCompleted.map((bytesLength) => {
    const value = fromLSBBytes(bytes.slice(bytesArrayIndex, bytesArrayIndex + bytesLength));
    bytesArrayIndex += bytesLength;
    return value;
  });

  return decodedBuffer;
};

export const getHeader = (buffer) => parseInt(buffer.subarray(0, 2).toString(), 16);
