import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as PropTypes from 'prop-types';

class ProtocolExams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getExamsStringDescription = (exams) => {
    const { intl } = this.props;
    const occurrences = exams.reduce(
      (prev, curr) => (prev[curr] ? ++prev[curr] : (prev[curr] = 1), prev),
      {}
    );
    const examList = Object.entries(occurrences).map(([examString, examOccurrences]) => {
      const examArr = examString.split(' ');
      const bodyPart = _.nth(examArr, -2);
      const view = _.nth(examArr, -1);
      let examDescription = `${intl.formatMessage({
        id: `exam.body_part.${bodyPart}`,
      })} ${intl.formatMessage({ id: `exam.view.${view}.short_format` })}`;
      if (examOccurrences > 1) {
        examDescription += ` (${examOccurrences})`;
      }
      return examDescription;
    });
    return examList;
  };

  renderProtocolExams = (exams) => this.getExamsStringDescription(exams).join(', ');

  render() {
    const { protocol } = this.props;
    return <div className="protocol-exams">{this.renderProtocolExams(protocol.exams)}</div>;
  }
}

ProtocolExams.propTypes = {
  protocol: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
};

export default injectIntl(ProtocolExams);
