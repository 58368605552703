import React from 'react';
import {
  Button,
  Header,
  Icon,
  Label,
  Popup,
  SemanticCOLORS,
  SemanticICONS,
} from 'semantic-ui-react';
import TeleradiologyIcon from '../TeleradiologyIcon';
import { FormattedMessage, useIntl } from 'react-intl';

type ActionType =
  | 'affiliateCreate'
  | 'studyCreate'
  | 'studyIncomplete'
  | 'studyPending'
  | 'studyDone';

type ActionInfos = {
  color?: SemanticCOLORS;
  icon: SemanticICONS;
  formattedMessageId: string;
};

type TeleradiologyPanelButtonActionProps = {
  action: ActionType;
  radiologistComment?: string;
  onClick: () => void;
};

/**
 * Button Action for Teleradiology Panel
 */
function TeleradiologyPanelButtonAction({
  action,
  radiologistComment,
  onClick,
}: TeleradiologyPanelButtonActionProps) {
  const intl = useIntl();

  const buttons: Record<ActionType, ActionInfos> = {
    affiliateCreate: {
      icon: 'user',
      formattedMessageId: 'teleradiology.affiliate-create.title',
    },
    studyCreate: {
      icon: 'folder open',
      formattedMessageId: 'teleradiology.analyze-request.study-create.title',
    },
    studyIncomplete: {
      color: 'red',
      icon: 'warning sign',
      formattedMessageId: 'teleradiology.analyze-request.study-incomplete.title',
    },
    studyPending: {
      color: 'orange',
      icon: 'wait',
      formattedMessageId: 'teleradiology.analyze-request.study-pending.title',
    },
    studyDone: {
      color: 'green',
      icon: 'download',
      formattedMessageId: 'teleradiology.analyze-request.study-done.title',
    },
  };

  const button = buttons[action];

  const popupContent = radiologistComment ? (
    <div>
      <Header as="h4">
        <FormattedMessage id="teleradiology.analyze-request.radiologist.comment.label" />
      </Header>
      <pre>{radiologistComment}</pre>
    </div>
  ) : (
    intl.formatMessage({
      id: 'teleradiology.subtitle',
    })
  );

  return (
    <Button fluid as="div" labelPosition="left" onClick={onClick}>
      <Popup
        trigger={
          <Label basic pointing="right" color={button.color}>
            <TeleradiologyIcon />
            &nbsp;&nbsp;
            <FormattedMessage id="teleradiology.title" />
          </Label>
        }
        content={popupContent}
        on="hover"
        inverted
        position="bottom center"
      />
      <Button fluid color={button.color} icon>
        <Icon name={button.icon} />
        &nbsp;&nbsp;
        <FormattedMessage id={button.formattedMessageId} />
      </Button>
    </Button>
  );
}

export default TeleradiologyPanelButtonAction;
