import { CropAnnotation, ImageAnnotations } from 'app/interfaces/Image';

export const convertCropAnnotationToCropRect = (cropAnnotation: CropAnnotation) => {
  if (!cropAnnotation) return undefined;

  const { topLeft, end } = cropAnnotation?.handles ?? {};
  let cropRect;
  if (topLeft && end) {
    const { x, y } = topLeft;
    const { x: xEnd, y: yEnd } = end;
    cropRect = [x, y, xEnd - x, yEnd - y];
  }
  return cropRect;
};

export const convertCropAnnotationsToCropRect = (cropAnnotations: ImageAnnotations['Crop']) => {
  if (!cropAnnotations) return undefined;

  const [cropUUID] = Object.keys(cropAnnotations);
  if (!cropUUID) return undefined;
  return convertCropAnnotationToCropRect(cropAnnotations?.[cropUUID]);
};
