import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as _ from 'lodash';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Button, Form, Container, Icon, Segment, Divider, Grid } from 'semantic-ui-react';
import queryString from 'query-string';
import ApiCalls from '../../utils/apiCalls';
import { CHANGE_USERNAME } from '../../redux/global/constants';
import { logout } from '../../redux/global/actions';

export class PartnerLinkPage extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    const queryStringParsed = queryString.parse(window.location.search);
    const sessionId = queryStringParsed.id;
    (this.sessionId = sessionId),
      (this.state = {
        error: null,
        sessionId: null,
        username: null,
        partnerDisplayName: null,
        successYouCanClose: false,
      });
  }

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      return;
    }
    if (!this.props.username || this.props.username === '') {
      ApiCalls.getMail().then((response) => {
        this.props.dispatch({ type: CHANGE_USERNAME, username: response.data.mail }); // ===> does not work
        this.setState({ username: response.data.mail });
      });
    } else {
      this.setState({ username: this.props.username });
    }
    ApiCalls.getSessionDetails(this.sessionId)
      .then((response) => {
        this.setState({
          partnerDisplayName: response.data.partner_display_name,
          actions: response.data.actions,
        });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  logoutAndRedirectToLogin = () => {
    this.props.dispatch(logout({ redirectRoute: '/login?id=' + this.sessionId }));
  };

  confirmSession = () => {
    const action = this.state.actions.confirm_session;
    ApiCalls.confirmSession(this.sessionId)
      .then(() => {
        this.redirect(action);
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  rejectSession = () => {
    const action = this.state.actions.reject_session;
    ApiCalls.rejectSession(this.sessionId).then(() => {
      this.redirect(action);
    });
  };

  redirect = (action) => {
    if (action === 'successClose') {
      this.setState({ successYouCanClose: true });
    } else {
      browserHistory.push(action + '?partner=picoxia&id=' + this.sessionId);
    }
  };

  renderBody = () => {
    const errorCause = _.get(this.state.error, 'response.data.cause', null);
    if (
      errorCause &&
      (errorCause === 'INVALID_SESSION_ID' || errorCause === 'EXTERNAL_TOKEN_SESSION_EXPIRED')
    ) {
      return this.renderExpired();
    } else if (this.state.successYouCanClose) {
      return this.renderSuccessYouCanClose();
    } else {
      return this.renderNormal();
    }
  };

  renderNormal = () => {
    return (
      <div>
        <h1 style={{ marginTop: '20vh' }}>
          <FormattedMessage
            id="link.title"
            values={{ partnerDisplayName: this.state.partnerDisplayName }}
          />
        </h1>
        <FormattedMessage id="link.loggedInAs" values={{ username: this.state.username }} />
        <Segment placeholder style={{ margin: '30px auto 0px auto', width: '75%' }}>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <FormattedMessage
                id="link.authorisePartnerQuestions"
                values={{ partnerDisplayName: this.state.partnerDisplayName }}
              />
              <br />
              <Button
                positive
                icon
                labelPosition="right"
                style={{ marginTop: '20px' }}
                onClick={this.confirmSession}
              >
                <FormattedMessage id="link.authorizeButton" />
                <Icon name="check" />
              </Button>
            </Grid.Column>

            <Grid.Column verticalAlign="middle">
              <FormattedMessage id="link.thisIsNotMe" values={{ username: this.state.username }} />
              <br />
              <Button
                icon
                labelPosition="right"
                style={{ marginTop: '20px' }}
                onClick={this.logoutAndRedirectToLogin}
              >
                <FormattedMessage id="link.changeAccountButton" />
                <Icon name="sign-out" />
              </Button>
            </Grid.Column>
          </Grid>

          <Divider vertical>
            <FormattedMessage id="link.or" />
          </Divider>
        </Segment>
      </div>
    );
  };

  renderExpired = () => {
    return (
      <div>
        <h1 style={{ marginTop: '20vh' }}>
          <FormattedMessage id="link.expired" />
        </h1>
        <a
          onClick={() => browserHistory.push('/')}
          style={{ fontWeight: 'bold', fontSize: '1.5em' }}
        >
          <FormattedMessage id="link.backToHome" /> <Icon name="long arrow alternate right" />
        </a>
      </div>
    );
  };

  renderSuccessYouCanClose = () => {
    return (
      <div>
        <h1 style={{ marginTop: '20vh' }}>
          <FormattedMessage id="link.successYouCanClose" />
        </h1>
      </div>
    );
  };

  render() {
    if (!this.props.isLoggedIn) {
      browserHistory.push('/login?id=' + this.sessionId);
      return (
        <div>
          <Helmet
            title={this.formatMessage({ id: 'link.pageTitle' })}
            meta={[
              { name: 'description', content: this.formatMessage({ id: 'link.pageDescription' }) },
            ]}
          />
          <Container textAlign="center" style={{ paddingTop: '20px' }}>
            <h1>
              <FormattedMessage id="link.redirecting" />
            </h1>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <Helmet
            title={this.formatMessage({ id: 'link.pageTitle' })}
            meta={[
              { name: 'description', content: this.formatMessage({ id: 'link.pageDescription' }) },
            ]}
          />
          <Container textAlign="center" style={{ paddingTop: '20px' }}>
            {this.renderBody()}
          </Container>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
    username: state.get('global').get('username'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PartnerLinkPage));
