import styled, { CSSProperties } from 'styled-components';

export const Button = styled.button`
  display: flex;
  font-weight: 700;
  font-size: 1.3em;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  background-color: ${(props: CSSProperties) => props.backgroundColor};
  padding: 10px;
  color: white;
  border-radius: 5px;
`;
