/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import cst from 'cornerstone-tools';
import csc from 'cornerstone-core';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import resetCursor from './resetCursor';

/**
 * Extend 'MagnifyTool' always zoom without needing to click.
 */
class MagnifyTool extends cst.MagnifyTool {
  constructor(props = {}) {
    super(props);

    // Mode Callbacks: (element, options)
    this.activeCallback = this.enableMagnificationCanvas.bind(this);
    this.enabledCallback = this.enableMagnificationCanvas.bind(this);
    this.passiveCallback = this.clearMagnificationCanvas.bind(this);
    this.disabledCallback = this.clearMagnificationCanvas.bind(this);

    // Touch
    this.postTouchStartCallback = undefined;
    this.touchDragCallback = undefined;
    this.touchEndCallback = undefined;
    this.touchDragEndCallback = undefined;
    // Mouse
    this.postMouseDownCallback = undefined;
    this.mouseDragCallback = undefined;
    this.mouseUpCallback = undefined;
    // On quick clicks, mouseUp does not fire, but this does
    this.mouseClickCallback = undefined;
    // Misc
    this.newImageCallback = this._drawMagnificationTool.bind(this);
  }

  enableMagnificationCanvas = (element) => {
    this._createMagnificationCanvas(element);
    if (!element[`${this.name}ToolMoveListener`]) {
      element[`${this.name}ToolMoveListener`] = this.mouseMoveCallback;
      element[`${this.name}ToolRenderListener`] = this.adaptMagnifierZoom;
      element.addEventListener(cst.EVENTS.MOUSE_MOVE, element[`${this.name}ToolMoveListener`]);
      element.addEventListener(
        csc.EVENTS.IMAGE_RENDERED,
        element[`${this.name}ToolRenderListener`]
      );
    }
  };

  clearMagnificationCanvas = (element) => {
    this._removeZoomElement();
    this._destroyMagnificationCanvas(element);
    if (element[`${this.name}ToolMoveListener`]) {
      element.removeEventListener(cst.EVENTS.MOUSE_MOVE, element[`${this.name}ToolMoveListener`]);
      element.removeEventListener(
        cst.EVENTS.IMAGE_RENDERED,
        element[`${this.name}ToolRenderListener`]
      );
      delete element[`${this.name}ToolMoveListener`];
      delete element[`${this.name}ToolRenderListener`];
    }
    resetCursor();
  };

  mouseMoveCallback = (evt) => {
    if (!this.zoomElement) {
      this._addMagnifyingGlass(evt);
    } else {
      this._updateMagnifyingGlass(evt);
    }
  };

  adaptMagnifierZoom = (evt) => {
    const { element } = evt.detail;
    if (this.zoomElement) {
      const { viewport: magnifyViewport, image } = csc.getEnabledElement(this.zoomElement);
      const { viewport } = csc.getEnabledElement(element);
      const newMagnifyViewport = _.cloneDeep(magnifyViewport);
      newMagnifyViewport.scale = viewport.scale * this.configuration.magnificationLevel;
      newMagnifyViewport.translation = { ...viewport.translation };
      if (
        magnifyViewport.scale !== newMagnifyViewport.scale ||
        !_.isEqual(magnifyViewport.translation, newMagnifyViewport.translation)
      ) {
        csc.setViewport(this.zoomElement, newMagnifyViewport);
      }
    }
  };
}

export default MagnifyTool;
