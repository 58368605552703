import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';
import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';

import ToolType from '../../types/ToolType';

const NORBERG_OLSSON_REGIONS = ['pelvis_face'];
Object.freeze(NORBERG_OLSSON_REGIONS);

function NorbergOlssonButton(props) {
  const { produceImageToolsList } = props;

  const disableDistractionIndexOnActivation = (newState) => {
    if (!['active', 'passive'].includes(newState)) return;
    produceImageToolsList((draftToolsList) => {
      if (draftToolsList.DistractionIndex === undefined) return;
      draftToolsList.DistractionIndex.state = 'disabled';
    });
  };

  return (
    <ActivatableToolButton
      icon="norberg_olsson"
      tooltipId="tools.norberg_olsson.tooltip"
      disabledState="disabled"
      setToolStateFn={disableDistractionIndexOnActivation}
      {...props}
    />
  );
}

NorbergOlssonButton.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  produceImageToolsList: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape({
    type: pt.string,
    norberg_olsson: pt.shape(),
  }),
};

NorbergOlssonButton.defaultProps = {
  focusedElement: null,
  predictions: {},
};

export default injectIntl(NorbergOlssonButton);
