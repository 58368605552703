import { getToolForElement } from 'app/CornerstoneTools';
import ChainingToolBuilder from 'app/CornerstoneTools/ChainingToolBuilder';
import checkPointNearHandles from 'app/CornerstoneTools/checkPointNearHandles';
import { drawToolStepExplanations } from 'app/CornerstoneTools/utils/toolStepsExplanations';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import {
  import as cornerstoneToolsImport,
  toolStyle,
  getToolState,
  addToolState,
  toolColors,
} from 'cornerstone-tools';
import * as _ from 'lodash';
import { norbergOlssonCursor } from './Cursors';

import forceImageUpdate from './forceImageUpdate';
import { offsetPixelPointInCanvas } from './pixelToCanvasUtils';
import {
  computeNorbergOlssonAngles,
  convertPredictionsToNorbergOlssonAnnotation,
} from 'app/utils/predictions/convertPredictionsToNorbergOlssonAnnotation';

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const throttle = cornerstoneToolsImport('util/throttle');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');
const setShadow = cornerstoneToolsImport('drawing/setShadow');
const drawHandles = cornerstoneToolsImport('drawing/drawHandles');
const drawCircle = cornerstoneToolsImport('drawing/drawCircle');
const drawLinkedTextBox = cornerstoneToolsImport('drawing/drawLinkedTextBox');
const drawJoinedLines = cornerstoneToolsImport('drawing/drawJoinedLines');

const TEXT_BOX_HANDLE_INITIAL_DATA = {
  active: false,
  hasMoved: false,
  movesIndependently: false,
  drawnIndependently: true,
  allowedOutsideImage: true,
  hasBoundingBox: true,
};
Object.freeze(TEXT_BOX_HANDLE_INITIAL_DATA);

/**
 * @public
 * @class NorbergOlssonTool
 * @memberof Tools.Annotation
 * @classdesc Add a Norberg Olsson tool in the canvas.
 * @extends Tools.Base.BaseAnnotationTool
 * @hideconstructor
 *
 * @param {ToolConfiguration} [props={}]
 */
export default class NorbergOlssonTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'NorbergOlsson',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        angleTextOffset: { x: 10, y: -20 },
        dicomDataPrinterToolName: 'DicomDataPrinter',
        getIntl: () => {},
      },
      svgCursor: norbergOlssonCursor,
    };

    super(props, defaultProps);

    this.preventNewMeasurement = false;

    this.toolBuilder = new ChainingToolBuilder({
      name: this.name,
      createNewMeasurement: this.createNewMeasurement,
      // Points name contains left and right but this lateralization is not guaranteed since users
      // can start drawing tool from right to left. Those names are kept to avoid version
      // problem between full precomputed and manual/precomputed version.
      handlesKeys: [
        'leftFemoralHeadRadius',
        'leftFemoralHeadCenter',
        'leftAcetabularRim',
        'rightFemoralHeadRadius',
        'rightFemoralHeadCenter',
        'rightAcetabularRim',
      ],
      maximumToolData: 1,
    });
    this.addNewMeasurement = this.toolBuilder.addNewMeasurement;
    this.pointNearTool = checkPointNearHandles;

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement = (eventData) => ({
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    handles: {
      leftFemoralHeadRadius: {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        highlight: true,
        active: true,
      },
      textBoxL: {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
      },
      textBoxR: {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
      },
    },
  });

  /* eslint-disable no-param-reassign */
  // eslint-disable-next-line class-methods-use-this
  updateCachedStats = (_image, _element, data) => {
    const { leftFemoralHeadCenter, leftAcetabularRim, rightFemoralHeadCenter, rightAcetabularRim } =
      data.handles;

    const { leftAngle, rightAngle, isAngleCorrect } = computeNorbergOlssonAngles(
      leftFemoralHeadCenter,
      leftAcetabularRim,
      rightFemoralHeadCenter,
      rightAcetabularRim
    );
    _.merge(data, { leftAngle, rightAngle, isAngleCorrect, invalidated: false });
    /* eslint-enable no-param-reassign */
  };

  drawAnnotations = (element, context, toolData) => {
    const {
      leftFemoralHeadCenter,
      leftFemoralHeadRadius,
      leftAcetabularRim,
      rightFemoralHeadCenter,
      rightFemoralHeadRadius,
      rightAcetabularRim,
    } = toolData.handles;

    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;
    setShadow(context, this.configuration);

    // Differentiate the color of activation tool
    const color = toolColors.getColorIfActive(toolData);

    const lineOptions = { color };
    const circleOptions = { color };

    const getCanvasDist = (p1, p2) =>
      cornerstoneMath.point.distance(
        cornerstone.pixelToCanvas(element, p1),
        cornerstone.pixelToCanvas(element, p2)
      );

    if (leftFemoralHeadCenter && leftFemoralHeadRadius) {
      // Draw Circle
      drawCircle(
        context,
        element,
        leftFemoralHeadCenter,
        getCanvasDist(leftFemoralHeadCenter, leftFemoralHeadRadius),
        circleOptions,
        'pixel'
      );
    }
    if (rightFemoralHeadCenter && rightFemoralHeadRadius) {
      drawCircle(
        context,
        element,
        rightFemoralHeadCenter,
        getCanvasDist(rightFemoralHeadCenter, rightFemoralHeadRadius),
        circleOptions,
        'pixel'
      );
    }

    if (leftAcetabularRim) {
      drawJoinedLines(
        context,
        element,
        leftAcetabularRim,
        _.filter([leftFemoralHeadCenter, rightFemoralHeadCenter, rightAcetabularRim]),
        lineOptions
      );
    }

    // Draw the handles
    const handleOptions = {
      color,
      handleRadius,
      drawHandlesIfActive: drawHandlesOnHover,
      hideHandlesIfMoving,
    };

    if (this.configuration.drawHandles) {
      drawHandles(context, { element }, toolData.handles, handleOptions);
    }
  };

  drawTool = (element, context, toolData) => {
    this.drawAnnotations(element, context, toolData);
    this.updateCachedStats(undefined, undefined, toolData);
    this.drawToolTextBox(element, toolData, context);
  };

  // eslint-disable-next-line class-methods-use-this
  drawToolTextBox = (element, toolData, context) => {
    const lineWidth = toolStyle.getToolWidth();
    const color = toolColors.getColorIfActive(toolData);
    const { angleTextOffset } = this.configuration;

    const displayTextBox = (angle, textBoxHandleKey, femoralHeadCHandle, textAlign) => {
      if (!angle) return;

      if (toolData.handles[textBoxHandleKey] === undefined) {
        toolData.handles[textBoxHandleKey] = _.clone(TEXT_BOX_HANDLE_INITIAL_DATA);
      }
      const textBoxHandle = toolData.handles[textBoxHandleKey];

      if (!textBoxHandle.hasMoved) {
        const pixelCoords = offsetPixelPointInCanvas(element, femoralHeadCHandle, {
          x: textAlign === 'left' ? angleTextOffset.x : -angleTextOffset.x,
          y: angleTextOffset.y,
        });
        textBoxHandle.x = pixelCoords.x;
        textBoxHandle.y = pixelCoords.y;
      }
      context.save();
      context.textAlign = textAlign;
      drawLinkedTextBox(
        context,
        element,
        textBoxHandle,
        `${angle}°`,
        toolData.handles,
        () => [femoralHeadCHandle],
        color,
        lineWidth,
        0,
        true
      );
      context.restore();
    };
    const { leftFemoralHeadCenter, rightFemoralHeadCenter } = toolData.handles;

    // In case hips are upside down left and right head may be wrong.
    // We use angle rotation direction to detect this change.
    const { leftAngle, rightAngle, isAngleCorrect } = toolData;
    const leftHead = isAngleCorrect ? leftFemoralHeadCenter : rightFemoralHeadCenter;
    const rightHead = isAngleCorrect ? rightFemoralHeadCenter : leftFemoralHeadCenter;
    displayTextBox(leftAngle, 'textBoxL', leftHead, isAngleCorrect ? 'left' : 'right');
    displayTextBox(rightAngle, 'textBoxR', rightHead, isAngleCorrect ? 'right' : 'left');
  };

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { element } = eventData;

    // If we have no toolData for this element, return immediately as there is nothing to do
    // console.log(cornerstone.getEnabledElement(evt.currentTarget))
    const toolData = getToolState(evt.currentTarget, this.name);
    const { getIntl, dicomDataPrinterToolName } = this.configuration;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(context, (ctx) => {
      const dicomDataPrinterTextOffset =
        getToolForElement(element, dicomDataPrinterToolName)?.dicomTextArea?.topLeft?.y ?? 0;

      drawToolStepExplanations(
        ctx,
        toolData?.data,
        getToolForElement(element, this.name).mode === 'active',
        [
          'tools.norberg_olsson.step1',
          'tools.norberg_olsson.step2',
          'tools.norberg_olsson.step3',
        ].map((id) => () => getIntl()?.formatMessage({ id })),
        [
          ['leftFemoralHeadRadius', 'rightFemoralHeadRadius'],
          ['leftFemoralHeadCenter', 'rightFemoralHeadCenter'],
          ['leftAcetabularRim', 'rightAcetabularRim'],
        ],
        {
          x: 5,
          y: dicomDataPrinterTextOffset + 20,
        },
        { maxWidth: (2 * eventData.canvasContext.canvas.width) / 5 }
      );
      toolData?.data?.forEach((data) => {
        if (this.toolBuilder.checkToolCreationCancelled(element, data)) return;
        this.drawTool(element, ctx, data);
      });
    });
  };
}
