import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { DisplayableImageData } from 'app/interfaces/Image';
import { Viewport } from 'app/interfaces/Image';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ThumbnailImageRenderer } from 'app/adapters/ThumbnailImageRenderer';
import { BasicDisplayOptions, DisplayEventCallback } from 'app/interfaces/ImageRenderer';

export type ImageRendererProps = {
  image: DisplayableImageData;
} & BasicDisplayOptions;

export default function ({ image, viewport, annotations }: ImageRendererProps) {
  const [isImageDisplayed, setIsImageDisplayed] = useState<boolean>(false);

  const thumbnailRenderer = useRef<ThumbnailImageRenderer>();
  const displayUpdateListener = useRef<DisplayEventCallback>((event) => {
    setIsImageDisplayed(event.detail.isImageDisplayed);
  });

  const onRef = (element: HTMLElement | null) => {
    if (!element || thumbnailRenderer.current) return;

    thumbnailRenderer.current = new ThumbnailImageRenderer(element);
    thumbnailRenderer.current.addEventListener('display_updated', displayUpdateListener.current);

    if (image) thumbnailRenderer.current?.displayImage(image, { viewport, annotations });
  };

  useEffect(() => {
    thumbnailRenderer.current?.displayImage(image, { viewport, annotations });
  }, [image, viewport, annotations]);

  useEffect(() => () => thumbnailRenderer.current?.destroy(), []);

  return (
    <div className="static-image-renderer">
      <Dimmer.Dimmable dimmed={!isImageDisplayed} className="static-image-renderer__dimmer">
        <Dimmer active={!isImageDisplayed}>
          <Loader />
        </Dimmer>
        <div ref={onRef}>
          <canvas />
        </div>
      </Dimmer.Dimmable>
    </div>
  );
}
