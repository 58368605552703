import _ from 'lodash';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Label, LabelProps } from 'semantic-ui-react';

type FormattedLabelProps = LabelProps &
  MessageDescriptor & {
    values?: Record<string, any>;
  };

export default function FormattedLabel(props: FormattedLabelProps) {
  return (
    <Label {..._.omit(props, ['id', 'description', 'defaultMessage', 'values'])}>
      <FormattedMessage {..._.pick(props, ['id', 'description', 'defaultMessage', 'values'])} />
    </Label>
  );
}
