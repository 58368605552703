import { AxiosError } from 'axios';
import APICauses from 'app/errors/APICauses';
import GenericError from 'app/errors/GenericError';

export const parseAPIErrorToGenericError = (error: AxiosError): GenericError => {
  const { cause = APICauses.UNKNOWN_ERROR, detail } = error?.response.data ?? {};
  return new GenericError(cause, detail);
};

export const throwAsGenericError = (error: AxiosError): undefined => {
  throw parseAPIErrorToGenericError(error);
};
