import './style.scss';

import React, { memo, useCallback, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  XRayGeneratorControllerContext,
  XRayGeneratorStateContext,
} from 'app/providers/XRayGeneratorProvider';
import { getKeyByValue } from 'app/types/xray';
import {
  GENERATOR_ERROR,
  GENERATOR_WARNING,
  OPERATING_MODE,
  READY_STATUS,
  UNIT_STATUS,
} from 'app/xray/generator/constants';
import { Button, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { selectXRayDevicePath } from 'app/redux/xrayGeneratorConfiguration/reducer';
import { ReactComponent as XRayReadyIcon } from 'app/../static/svg_icons/xray_ready_icon.svg';
import { ReactComponent as XRayBusyIcon } from 'app/../static/svg_icons/xray_busy_icon.svg';
import { ReactComponent as XRayNoneIcon } from 'app/../static/svg_icons/xray_none_icon.svg';
import { ReactComponent as XRayOngoingIcon } from 'app/../static/svg_icons/xray_ongoing_icon.svg';
import { ReactComponent as XRayPrepIcon } from 'app/../static/svg_icons/xray_prep_icon.svg';
import { ReactComponent as XRayPrepImpossibleIcon } from 'app/../static/svg_icons/xray_prep_impossible_icon.svg';
import { ReactComponent as XRayErrorIcon } from 'app/../static/svg_icons/xray_error_icon.svg';
import DemoVirtualXRayControllerPedal from 'app/components/DemoVirtualXRayControllerPedal';

const stateToXRayIcon = ({
  unitStatus,
  readyStatus,
  warning,
  error,
  xRayOngoing,
  prepStarted,
} = {}) => {
  if (error || warning) return <XRayErrorIcon />;
  if (xRayOngoing) return <XRayOngoingIcon />;
  if (prepStarted) return <XRayPrepIcon />;

  switch (unitStatus) {
    case undefined:
      return <XRayNoneIcon />;
    case UNIT_STATUS.STANDBY_MODE:
      return readyStatus === READY_STATUS.READY ? <XRayReadyIcon /> : <XRayBusyIcon />;
    case UNIT_STATUS.FIRST_TIME:
      return <XRayPrepIcon />;
    case UNIT_STATUS.XRAY_IN_PROGRESS:
      return <XRayOngoingIcon />;
    case UNIT_STATUS.SYSTEM_ERROR:
      return <XRayErrorIcon />;
    case UNIT_STATUS.SLEEPING_MODE:
    case UNIT_STATUS.END_OF_XRAY:
    case UNIT_STATUS.SHUTDOWN_IN_PROGRESS:
      return <XRayBusyIcon />;
    case UNIT_STATUS.WAIT_CAPACITOR:
      return <XRayPrepImpossibleIcon />;
    case UNIT_STATUS.GENERATOR_NOT_DETECTED:
    case UNIT_STATUS.INTERNAL_BOARD_COMMUNICATION:
    default:
      return <XRayErrorIcon />;
  }
};

const StatusText = ({
  unitStatus,
  readyStatus,
  warning,
  error,
  xRayOngoing,
  prepStarted,
  connectionState,
}) => {
  if (connectionState !== 'connected') {
    if (connectionState === 'connecting') {
      return (
        <div className="generator-status__text ">
          <FormattedMessage id="xray_generator.status.connecting" />
        </div>
      );
    }
    return (
      <div className="generator-status__text">
        <FormattedMessage id="xray_generator.status.no_generator" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="generator-status__text generator-status__text--error">
        <Icon name="warning sign" />
        <FormattedMessage
          id={`xray_generator.status.error.${getKeyByValue(GENERATOR_ERROR, error)}`}
        />
      </div>
    );
  }
  if (warning) {
    return (
      <div className="generator-status__text generator-status__text--warning">
        <Icon name="warning sign" />
        <FormattedMessage
          id={`xray_generator.status.warning.${getKeyByValue(GENERATOR_WARNING, warning)}`}
        />
      </div>
    );
  }

  if (xRayOngoing) {
    return (
      <div className="generator-status__text generator-status__text--ongoing">
        <FormattedMessage id="xray_generator.status.xray_ongoing" />
      </div>
    );
  }
  if (prepStarted) {
    return (
      <div className="generator-status__text generator-status__text--prep">
        <FormattedMessage id="xray_generator.status.prep" />
      </div>
    );
  }
  if (readyStatus === READY_STATUS.READY) {
    return (
      <div className="generator-status__text generator-status__text--ready">
        <FormattedMessage id="xray_generator.status.ready" />
      </div>
    );
  }
  switch (unitStatus) {
    case UNIT_STATUS.STANDBY_MODE:
      return (
        <div className="generator-status__text generator-status__text--standby">
          <FormattedMessage id="xray_generator.status.standby" />
        </div>
      );
    case UNIT_STATUS.FIRST_TIME:
      return (
        <div className="generator-status__text generator-status__text--prep">
          <FormattedMessage id="xray_generator.status.prep" />
        </div>
      );
    case UNIT_STATUS.XRAY_IN_PROGRESS:
      return (
        <div className="generator-status__text generator-status__text--ongoing">
          <FormattedMessage id="xray_generator.status.xray_ongoing" />
        </div>
      );
    case UNIT_STATUS.SLEEPING_MODE:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.sleeping" />
        </div>
      );
    case UNIT_STATUS.END_OF_XRAY:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.end_xray" />
        </div>
      );
    case UNIT_STATUS.WAIT_CAPACITOR:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.wait_capacitor" />
        </div>
      );
    case UNIT_STATUS.SHUTDOWN_IN_PROGRESS:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.shutdown" />
        </div>
      );
    case UNIT_STATUS.INTERNAL_BOARD_COMMUNICATION:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.communication_error" />
        </div>
      );
    case UNIT_STATUS.GENERATOR_NOT_DETECTED:
    default:
      return (
        <div className="generator-status__text">
          <FormattedMessage id="xray_generator.status.no_generator" />
        </div>
      );
  }
};

const XRayGeneratorStatus = () => {
  const devicePath = useSelector(selectXRayDevicePath);
  if (!devicePath) return null;

  const generatorStatus = useContext(XRayGeneratorStateContext) ?? {};
  const { unitStatus, warning, error } = generatorStatus;
  const xRayGeneratorController = useContext(XRayGeneratorControllerContext);

  const cancelFirstTime = useCallback(
    () => xRayGeneratorController?.cancelPreparation(),
    [xRayGeneratorController]
  );

  const clearWarning = useCallback(() => {
    xRayGeneratorController?.resetWarning();
  }, [xRayGeneratorController]);

  useEffect(() => {
    if (unitStatus === UNIT_STATUS.SLEEPING_MODE) {
      xRayGeneratorController?.changeOperatingMode(OPERATING_MODE.STANDBY);
    }
  }, [xRayGeneratorController, unitStatus]);

  useEffect(
    () => () => xRayGeneratorController?.changeOperatingMode(OPERATING_MODE.SLEEPING),
    [xRayGeneratorController]
  );

  const stateIcon = stateToXRayIcon(generatorStatus);

  const isWarningState = warning && !error && warning !== GENERATOR_WARNING.X_RAY_TUBE_NOT_READY;
  const isPrepState = unitStatus === UNIT_STATUS.FIRST_TIME;
  return (
    <div className="generator-status">
      <div className="generator-status__title">
        <h2>
          <FormattedMessage id="xray_generator" />
        </h2>
      </div>
      <div className="generator-status__display">
        <div className="generator-status__display__icon">{stateIcon}</div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StatusText {...generatorStatus} />
      </div>
      <Button
        color={isPrepState ? 'red' : undefined}
        onClick={cancelFirstTime}
        disabled={!isPrepState}
      >
        <FormattedMessage id="xray_generator.preparation.cancel" />
      </Button>
      <DemoVirtualXRayControllerPedal />
      {isWarningState && (
        <div className="generator-status__warning-button">
          <Button onClick={clearWarning}>
            <FormattedMessage id="xray_generator.reset" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(XRayGeneratorStatus);
