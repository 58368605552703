/* eslint-disable react/forbid-prop-types */
import { useEffect } from 'react';
import * as pt from 'prop-types';

export default function UseEffect({ setup, deps }) {
  useEffect(setup, deps);
  return null;
}

UseEffect.propTypes = {
  setup: pt.func.isRequired,
  deps: pt.arrayOf(pt.any),
};

UseEffect.defaultProps = {
  deps: undefined,
};
