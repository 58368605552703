/**
 * @typedef {Error} PromiseCanceledError
 * @prop {bool} isCanceled
 */

const cancelError = () => {
  const error = new Error('Promise canceled');
  error.isCanceled = true;
  return error;
};

function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => (isCanceled ? reject(cancelError()) : resolve(val)))
      .catch((error) => (isCanceled ? reject(cancelError()) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export default makeCancelable;
