import * as _ from 'lodash';

import ApiCalls from './apiCalls';
import isWorkListImage from './isWorkListImage';
import RadioImageData from './RadioImageData';
import { getReportFromStudy } from './reports/reportsGenerator';

const initRadioImage = async (
  studyId,
  intl,
  image,
  imageInitialToolsList,
  enhanceImageFn,
  applyProcessCallback,
  computePicoxiaAnalysisFn,
  updateImageFn,
  localDicomStoreContext,
  taskScheduler
) => {
  let imageFile;
  const dicomPath = await localDicomStoreContext?.getDicomImagePath(studyId, image._id);
  if (dicomPath) {
    imageFile = {
      path: dicomPath,
      isFromFileSystem: true,
    };
  } else {
    const base64ImageResponse = await ApiCalls.getImage({ imageId: image._id });
    imageFile = `data:image/png;base64,${base64ImageResponse.data.image}`;
  }
  const radioImage = new RadioImageData(
    imageFile,
    image.origin,
    imageInitialToolsList,
    image.annotations,
    image.viewport,
    image.image_metadata,
    intl,
    applyProcessCallback,
    computePicoxiaAnalysisFn,
    updateImageFn,
    taskScheduler
  );

  enhanceImageFn?.(radioImage);
  _.merge(radioImage, {
    backendId: image._id,
    feedback: image.feedback || {},
    predictions: image.predictions || {},
    anatomicRegion: image.anatomicRegion,
    acquisitionConstants: image.acquisitionConstants,
  });
  return radioImage;
};

const initWorkListImage = async (image) => ({
  id: image._id,
  backendId: image._id,
  origin: image.origin,
  anatomicRegion: image.anatomicRegion,
  acquisitionConstants: {
    thickness: 0,
    kV: 0,
    mA: 0,
    s: 0,
    ...image.acquisitionConstants,
  },
});

const loadStudy = (
  studyIDs,
  intl,
  imageInitialToolsList,
  enhanceImageFn,
  localDicomStoreContext,
  applyProcessCallback = () => {},
  computePicoxiaAnalysisFn = () => {},
  updateImageFn = () => {},
  taskScheduler
) =>
  /* eslint-disable no-underscore-dangle */
  ApiCalls.getStudies(studyIDs).then(async (studies) => {
    if (studies.length === 0) return {};
    const loadedStudy = { ...studies[0] };

    const radioImages = await Promise.all(
      loadedStudy.images.map(async (image) => {
        if (isWorkListImage(image)) {
          return initWorkListImage(image, studies[0]);
        }
        return initRadioImage(
          studies[0]._id,
          intl,
          image,
          imageInitialToolsList,
          enhanceImageFn,
          applyProcessCallback,
          computePicoxiaAnalysisFn,
          updateImageFn,
          localDicomStoreContext,
          taskScheduler
        );
      })
    );

    loadedStudy.images = radioImages;
    loadedStudy.ID = loadedStudy._id;
    if (!loadedStudy.comment) {
      loadedStudy.comment = getReportFromStudy(loadedStudy, intl);
    }
    return loadedStudy;
  });

export default loadStudy;
export { loadStudy };
