/**
 * LoginPage selectors
 */

import { createSelector } from 'reselect';

const selectGlobal = (state) => state.get('global');


const makeLoginError = () => createSelector(
  selectGlobal,
  (global) => global.get('loginError'),
);

export {
  makeLoginError,
};
