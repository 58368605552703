import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Icon, List, Label, Confirm } from 'semantic-ui-react';
import Creatable from 'react-select/creatable';
import ApiCalls from '../../utils/apiCalls';
import stringToColor from '../../utils/colorUtils';

class UserLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      open: false,
      optionDeleted: '',
      countUsersWithLabel: 0,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.getOptions(nextProps.labels);
  };

  componentDidMount = () => {
    this.getOptions(this.props.labels);
  };

  getOptions = (labels) => {
    const options = [];
    for (const [key, value] of Object.entries(labels)) {
      options.push(this.createOption(value, key));
    }
    this.setState({ options });
  };

  renderLabelOptions = (user) => {
    const selectInputRef = useRef;
    return (
      <div>
        <Creatable
          onChange={(inputValue) => {
            this.addLabelToUser(user.mail, inputValue.value);
            selectInputRef.current.select.clearValue();
          }}
          onCreateOption={this.handleCreate}
          options={this.state.options}
        />
        {this.renderAlertDeleteLabel()}
      </div>
    );
  };

  deleteLabelUser = (mail, idLabel) => {
    ApiCalls.deleteLabelUser(mail, idLabel).then(() => {
      this.props.onDeleteLabelUser();
    });
  };

  getLabelId = (name) => {
    for (const [key, value] of Object.entries(this.props.labels)) {
      if (value == name) return key;
    }
  };

  renderLabelsUser = (user) => {
    const { labels } = this.props;
    if (!labels || !user.labels) {
      return null;
    }
    return user.labels.map((id) => {
      const label = labels[id];
      if (label !== undefined) {
        const color = stringToColor(label);
        return (
          <List.Item key={id}>
            <Label style={{ backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})` }}>
              {label}
              <Icon
                name="delete"
                link
                onClick={() => {
                  this.deleteLabelUser(user.mail, this.getLabelId(label));
                }}
              />
            </Label>
          </List.Item>
        );
      }
    });
  };

  deleteLabel = (id) => {
    ApiCalls.deleteLabel(id).then(() => {
      this.props.onDeleteLabel();
    });
  };

  handleConfirm = () => {
    this.setState({ open: false });
    this.deleteLabel(this.state.optionDeleted);
  };

  handleCancel = () => this.setState({ open: false });

  countUsersWithLabel = (idLabel) => {
    ApiCalls.getCountUsersWithLabel(idLabel).then((response) => {
      this.setState({ countUsersWithLabel: response.data.number, open: true });
    });
  };

  renderAlertDeleteLabel = () => (
    <div>
      <Confirm
        open={this.state.open}
        header={` Cette étiquette est utilisée ${this.state.countUsersWithLabel} fois`}
        content="Êtes-vous sûr de vouloir supprimer ette étiquette ?"
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />
    </div>
  );

  createOption = (label, id) => ({
    label: (
      <div>
        {label}
        <Icon
          style={{ float: 'right' }}
          name="trash"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              optionDeleted: id,
            });
            this.countUsersWithLabel(id);
          }}
        />
      </div>
    ),
    value: id,
  });

  addLabelToUser = (mail, idLabel) => {
    ApiCalls.addLabelToUser(mail, idLabel).then(() => {
      this.props.onAddLabelToUser();
    });
  };

  handleCreate = (inputValue) => {
    ApiCalls.addLabel(inputValue).then(() => {
      this.props.onAddLabel();
    });
  };

  render() {
    return (
      <div>
        {this.renderLabelOptions(this.props.user)}
        <br />
        {this.renderLabelsUser(this.props.user)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLabels);
