import React from 'react';
import ApiCalls from '../../utils/apiCalls';

const DEFAULT_IMAGE_CACHE_CONTEXT = {
  getImage: (imageId) => null,
  imageCache: {},
};

Object.freeze(DEFAULT_IMAGE_CACHE_CONTEXT);

const ImageCacheContext = React.createContext(DEFAULT_IMAGE_CACHE_CONTEXT);

class ImageCacheProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      getImage: this.getImage,
      imageCache: {},
    };
    this.isMounted_ = true;
  }

  componentWillUnmount = () => {
    this.isMounted_ = false;
  };

  setStateIfMounted = (...args) => this.isMounted_ && this.setState(...args);

  fetchImageIntoCache = async (imageId) => {
    {
      const { imageCache } = this.state;
      if (imageCache[imageId]) return;
    }
    // A bit dumb but it prevent setState being called during render.
    await undefined;
    const base64ImagePromise = ApiCalls.getImage({ imageId });
    this.setStateIfMounted(({ imageCache }) => ({
      imageCache: { ...imageCache, [imageId]: { imagePromise: base64ImagePromise } },
    }));

    try {
      const base64ImageResponse = await base64ImagePromise;
      this.setStateIfMounted(({ imageCache }) => ({
        imageCache: {
          ...imageCache,
          [imageId]: { imageBase64: `data:image/png;base64,${base64ImageResponse.data.image}` },
        },
      }));
    } catch (e) {
      console.warn('image fetch failed ', e);
      this.setStateIfMounted(({ imageCache }) => ({
        imageCache: {
          ...imageCache,
          [imageId]: { imageBase64: null },
        },
      }));
    }
  };

  getImage = (imageId) => {
    const { imageCache } = this.state;
    this.fetchImageIntoCache(imageId);
    return imageCache[imageId]?.imageBase64;
  };

  render = () => {
    const { children } = this.props;
    return <ImageCacheContext.Provider value={this.state}>{children}</ImageCacheContext.Provider>;
  };
}

export { ImageCacheProvider, ImageCacheContext, DEFAULT_IMAGE_CACHE_CONTEXT };
