/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import * as csc from 'cornerstone-core';
import * as csm from 'cornerstone-math';
import { toolStyle, getToolState, toolColors, getModule } from 'cornerstone-tools';
import * as cst from 'cornerstone-tools';

import { CircleRadiusCursor } from './Cursors';

const BaseAnnotationTool = cst.import('base/BaseAnnotationTool');
const throttle = cst.import('util/throttle');
const getNewContext = cst.import('drawing/getNewContext');
const draw = cst.import('drawing/draw');
const setShadow = cst.import('drawing/setShadow');
const drawHandles = cst.import('drawing/drawHandles');
const drawLine = cst.import('drawing/drawLine');
const drawCircle = cst.import('drawing/drawCircle');
const drawLinkedTextBox = cst.import('drawing/drawLinkedTextBox');
const lineSegDistance = cst.import('util/lineSegDistance');
const getLogger = cst.import('util/getLogger');
const getPixelSpacing = cst.import('util/getPixelSpacing');

const logger = getLogger('tools:annotation:CircleRadiusTool');

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
function _sanitizeMeasuredValue(value) {
  const parsedValue = Number(value);
  const isNumber = !Number.isNaN(parsedValue);

  return isNumber ? parsedValue : undefined;
}

// - SideEffect: Updates annotation 'suffix'
function textBoxText(annotation, rowPixelSpacing, colPixelSpacing) {
  const measuredValue = _sanitizeMeasuredValue(annotation.length);

  // measured value is not defined, return empty string
  if (!measuredValue) {
    return '';
  }

  // Set the length text suffix depending on whether or not pixelSpacing is available
  let suffix = 'mm';

  if (!rowPixelSpacing || !colPixelSpacing) {
    suffix = 'pixels';
  }

  annotation.unit = suffix;

  return `${measuredValue.toFixed(2)} ${suffix}`;
}

function textBoxAnchorPoints(handles) {
  const midpoint = {
    x: (handles.start.x + handles.end.x) / 2,
    y: (handles.start.y + handles.end.y) / 2,
  };

  return [handles.start, midpoint, handles.end];
}

const getCanvasDist = (element, p1, p2) =>
  csm.point.distance(csc.pixelToCanvas(element, p1), csc.pixelToCanvas(element, p2));

/**
 * @public
 * @class CircleRadiusTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class CircleRadiusTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'CircleRadius',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      //   svgCursor: lengthCursor,
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
      svgCursor: CircleRadiusCursor,
    };

    super(props, defaultProps);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  createNewMeasurement(eventData) {
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(`required eventData not supplied to tool ${this.name}'s createNewMeasurement`);

      return undefined;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
          pointNearHandle: () => false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);

      return false;
    }

    if (data.visible === false) {
      return false;
    }

    const radiusDistance = getCanvasDist(element, data.handles.start, data.handles.end);
    const centerDistance = csm.point.distance(
      csc.pixelToCanvas(element, data.handles.start),
      coords
    );

    const isNearCircle = Math.abs(centerDistance - radiusDistance) < 5;
    const isNearLine = lineSegDistance(element, data.handles.start, data.handles.end, coords) < 25;
    return isNearCircle || isNearLine;
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx = (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy = (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);

    // Store the length inside the tool for outside access
    /* eslint-disable no-param-reassign */
    data.length = length;
    data.invalidated = false;
    /* eslint-enable no-param-reassign */
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    const lineWidth = toolStyle.getToolWidth();
    const { lineDash } = getModule('globalConfiguration').configuration;

    for (let i = 0; i < toolData.data.length; i += 1) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        // Configurable shadow
        setShadow(context, this.configuration);

        const color = toolColors.getColorIfActive(data);

        const lineOptions = { color, lineDash };

        // Draw the measurement line
        drawLine(context, element, data.handles.start, data.handles.end, lineOptions);
        const radiusDistance = getCanvasDist(element, data.handles.start, data.handles.end);
        drawCircle(context, element, data.handles.start, radiusDistance, { color });

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        if (!data.handles.textBox.hasMoved) {
          const coords = {
            x: Math.max(data.handles.start.x, data.handles.end.x),
          };

          // Depending on which handle has the largest x-value,
          // Set the y-value for the text box
          if (coords.x === data.handles.start.x) {
            coords.y = data.handles.start.y;
          } else {
            coords.y = data.handles.end.y;
          }

          data.handles.textBox.x = coords.x;
          data.handles.textBox.y = coords.y;
        }

        // Move the textbox slightly to the right and upwards
        // So that it sits beside the length tool handle
        const xOffset = 10;

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.length) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        const text = textBoxText(data, rowPixelSpacing, colPixelSpacing);

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          text,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          xOffset,
          true
        );
      });
    }
  }
}
