import { Task, TaskHandler, TaskScheduler } from 'app/utils/async/TaskScheduler';
import _, { uniqueId } from 'lodash';

export default class ParallelTaskScheduler implements TaskScheduler {
  private availableExecutionSlots: number;
  private pendingTasks: { handler: TaskHandler; task: Task }[] = [];
  private stopped: boolean = false;

  constructor(maxParallelism: number = 1) {
    this.availableExecutionSlots = maxParallelism;
  }

  schedule(task: () => Promise<any>): TaskHandler {
    if (this.stopped) return;

    const handler = uniqueId();
    if (this.availableExecutionSlots) {
      this.startTaskExecution(task);
    } else {
      this.pendingTasks.push({ handler, task });
    }
    return handler;
  }

  forceExecution(handler: TaskHandler) {
    if (!handler) return;

    const requestedTask = _.find(this.pendingTasks, { handler });
    _.remove(this.pendingTasks, { handler });
    this.startTaskExecution(requestedTask?.task);
  }

  stop() {
    this.stopped = true;
    this.pendingTasks = [];
  }

  private startTaskExecution(task: Task) {
    if (!task) return;

    this.availableExecutionSlots -= 1;
    task().finally(() => {
      this.availableExecutionSlots += 1;

      this.startTaskExecution(this.pendingTasks.shift()?.task);
    });
  }
}
