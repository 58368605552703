/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { FormattedMessage } from 'react-intl';
import { isSmallScreen } from '../../utils/windowUtils';
import IntlCrawlableLink from '../IntlCrawlableLink';

class LoginButton extends React.Component {
  render() {
    const linkContent = isSmallScreen() ? (
      <i className="ui icon sign-in user-icon" />
    ) : (
      <FormattedMessage id="loginButton.buttonText" />
    );
    return (
      <IntlCrawlableLink url="/login" className="ui button">
        {linkContent}
      </IntlCrawlableLink>
    );
  }
}

export default LoginButton;
