import React from 'react';
import './style.scss';

import { ReactComponent as SmallFocus } from 'app/../static/svg_icons/small_focus_icon.svg';
import { ReactComponent as BigFocus } from 'app/../static/svg_icons/big_focus_icon.svg';
import { FOCUS } from 'app/xray/generator/constants';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const FocusIcon = ({ focus }) => {
  if (focus === undefined) return null;

  let content;
  let trigger;

  if (focus === FOCUS.SMALL) {
    content = <FormattedMessage id="xray_generator.focus.small.tooltip" />;
    trigger = <SmallFocus />;
  } else {
    content = <FormattedMessage id="xray_generator.focus.big.tooltip" />;
    trigger = <BigFocus />;
  }

  return (
    <div className="xray-focus-icon">
      <Popup content={content} trigger={trigger} />
    </div>
  );
};

export default FocusIcon;
