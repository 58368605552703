import * as cst from 'cornerstone-tools';

export default function invalidateAllTools(element) {
  cst.store.state.tools
    .filter((tool) => tool.element === element)
    .forEach((tool) => {
      cst.getToolState(element, tool.name)?.data?.forEach((measurementData) => {
        // eslint-disable-next-line no-param-reassign
        measurementData.invalidated = true;
      });
    });
}
