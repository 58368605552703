import _ from 'lodash';
import { ImageSaveState, ViewerImage } from 'app/components/Viewer/types';
import { ImageAnnotations, ToolAnnotations, XRayImage } from 'app/interfaces/Image';

const formatToolAnnotationsForSave = (annotations: ToolAnnotations) =>
  _.mapValues(annotations, (annotation) => ({
    ..._.omit(annotation, ['active', 'handles']),
    handles: _.mapValues(annotation.handles, (handle) => _.omit(handle, ['active'])),
  }));

const formatImageAnnotationsForSave = (annotations: ImageAnnotations) =>
  _.mapValues(annotations, formatToolAnnotationsForSave);

export default function formatXRayImageViewerState({
  annotations,
  viewport,
}: Pick<ViewerImage, 'annotations' | 'viewport'>): Partial<ImageSaveState['viewer']> {
  return _.omitBy(
    {
      annotations: annotations ? formatImageAnnotationsForSave(annotations) : undefined,
      viewport: viewport
        ? _.pick(viewport, [
            'vflip',
            'hflip',
            'invert',
            'rotation',
            'voi',
            'uint8Voi',
            'uint16Voi',
            'displayedArea',
          ])
        : undefined,
    },
    _.isUndefined
  );
}
