import React from 'react';
import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';
import { getDefaultAnatomicRegion } from 'app/utils/xrayRegions';

export default (props) => {
  const { anatomicRegion } = props;

  return (
    <ActivatableToolButton
      icon="tplo"
      tooltipId="tools.TPLO.tooltip"
      disabledState="disabled"
      {...props}
    />
  );
};
