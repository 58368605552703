import { Patient } from 'app/interfaces/Patient';
import { PmsTypes } from 'app/pms/parser/types';
import { parse } from 'date-fns';

export const parseDateTime = (dateTimeString: string) =>
  dateTimeString ? parse(dateTimeString, 'yyyyMMdd_HHmmss', new Date()) : undefined;

export const parseDate = (dateString: string) =>
  dateString ? parse(dateString, 'yyyyMMdd', new Date()) : undefined;

export type PmsConfiguration = unknown & { type: PmsTypes };
export type ParseResult = {
  patientInfo: Patient;
  exam: {
    pms_id: string;
    planningDate: Date;
    pms_software: string;
  };
};
export default interface PmsParser {
  updateConfiguration: (configuration: PmsConfiguration) => void;
  parse: (fileContent: string) => ParseResult;
}
