import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DisplayableImageEncoder } from 'app/adapters/DisplayableImageEncoder';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';

export default function DisplayableImageEncoderProvider({ children }: PropsWithChildren<{}>) {
  const intl = useIntl();
  const [displayableImageEncoder] = useState(() => new DisplayableImageEncoder(intl));

  useEffect(() => {
    displayableImageEncoder.intl = intl;
  }, [intl]);

  return (
    <IDisplayableImageEncoderContext.Provider value={displayableImageEncoder}>
      {children}
    </IDisplayableImageEncoderContext.Provider>
  );
}
