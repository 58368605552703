import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuItem, MenuItemProps } from 'semantic-ui-react';
import _ from 'lodash';

import PatternStructuredList from 'app/components/PatternStructuredList';
import {
  ABDOMEN_GROUPS,
  ABDOMEN_PATTERNS,
  THORAX_GROUPS,
  THORAX_PATTERNS,
} from 'app/components/PatternStructuredList/patternsGroups';
import {
  isAbdomenPredictionType,
  isPelvisPredictionType,
  isThoraxPredictionType,
} from 'app/utils/predictions/constants';
import DysplasiaPatternStructuredList from 'app/components/DysplasiaPatternStructuredList';
import InferenceErrorMessage from 'app/components/InferenceErrorMessage';
import { ReactComponent as Picoxia } from '../../../static/svg_icons/picoxia.svg';
import { FRONT_END_PATH } from 'app/redux/global/constants';
import VHSReferencesDropdown from 'app/components/VHSReferencesDropdown';
import { Feedbacks, ImageAnnotations } from 'app/interfaces/Image';
import { Patient } from 'app/interfaces/Patient';
import { AllPredictions } from 'app/interfaces/Predictions';
import GenericError from 'app/errors/GenericError';
import FormattedServerError from 'app/components/FormattedServerError';

type PredictionsDisplayProps = {
  patient?: Patient;
  annotations: ImageAnnotations;
  predictions: AllPredictions;
  onFeedbackUpdate: (patternName: string, isPositive: boolean) => void;
  loading: boolean;
  inferenceError: any;
  activeRegionName: string;
  setActiveRegionName: (regionName: string) => void;
  switchVHSState: () => void;
  setPatientRace: (race: string) => void;
  origin: string;
  feedback: Feedbacks;
};

function PredictionsDisplay({
  patient,
  annotations,
  predictions,
  onFeedbackUpdate,
  loading,
  inferenceError,
  activeRegionName,
  setActiveRegionName,
  switchVHSState,
  setPatientRace,
  origin,
  feedback = {},
}: PredictionsDisplayProps) {
  const onSelectMenuItem = (_e: any, { name }: MenuItemProps) => setActiveRegionName(name);

  if (inferenceError) {
    return <InferenceErrorMessage errorMessage={inferenceError} />;
  }

  if (loading) {
    return (
      <div className="patterns picoxia-loading-animation">
        <Picoxia />
      </div>
    );
  }

  if (predictions?.no_credit) {
    return (
      <button
        className="picoxia purchase-link button picoxiaLightGreen"
        type="button"
        onClick={() => window.open(`${FRONT_END_PATH}/purchase`)}
      >
        <Picoxia />
        <FormattedMessage id="buy_interpretation.link" />
      </button>
    );
  }
  if (predictions?.error) {
    return (
      <div className="errorMessage">
        <b>
          <FormattedServerError error={new GenericError(predictions?.error)} />
        </b>
      </div>
    );
  }

  const predictionTabs = [];
  if (isThoraxPredictionType(predictions?.type)) {
    predictionTabs.push({
      label: 'thorax',
      component: (
        <PatternStructuredList
          groups={THORAX_GROUPS}
          patterns={THORAX_PATTERNS}
          image={{ predictions, feedback }}
          onFeedbackUpdate={onFeedbackUpdate}
          groupSpecificChildrenBefore={{
            'Système Cardio-Vasculaire': (
              <div className="ui divided selection list" key="vhs">
                <div>
                  <VHSReferencesDropdown
                    onVHSClick={switchVHSState}
                    predictions={predictions}
                    annotations={annotations}
                    selectedRaceKey={patient?.race}
                    onRaceSelected={setPatientRace}
                    patientInfo={patient}
                  />
                </div>
              </div>
            ),
          }}
        />
      ),
    });
  }
  if (isAbdomenPredictionType(predictions?.type)) {
    predictionTabs.push({
      label: 'abdomen',
      component: (
        <PatternStructuredList
          groups={ABDOMEN_GROUPS}
          patterns={ABDOMEN_PATTERNS}
          image={{ predictions, feedback }}
          onFeedbackUpdate={onFeedbackUpdate}
        />
      ),
    });
  }

  if (isPelvisPredictionType(predictions?.type)) {
    return <DysplasiaPatternStructuredList predictions={predictions} annotations={annotations} />;
  }

  const { component: predictionsToDisplay, label: selectedLabel } =
    _.find(predictionTabs, { label: activeRegionName }) ?? predictionTabs[0] ?? {};

  let menuHeader;
  if (predictionTabs.length > 1) {
    menuHeader = (
      <Menu tabular attached="top" className="patterns-menu">
        {predictionTabs.map(({ label }) => (
          <MenuItem
            key={label}
            name={label}
            active={selectedLabel === label}
            onClick={onSelectMenuItem}
          >
            <FormattedMessage id={label} />
          </MenuItem>
        ))}
      </Menu>
    );
  }

  if (predictionsToDisplay) {
    return (
      <>
        {menuHeader}
        <div className="patterns">{predictionsToDisplay}</div>
      </>
    );
  }

  if (predictions === undefined) return null;
  if (origin === 'acquisition') return null;

  return (
    <div className="container">
      <div className="ui icon message">
        <i className="exclamation triangle icon" />
        <div className="content">
          <div className="header">
            <FormattedMessage id="patternStructuredList.badImage.header" />
          </div>
          <p>
            <FormattedMessage id="patternStructuredList.badImage.pleaseVerify" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.thorax" />
            </li>
            <li>
              <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.catOrDog" />
            </li>
            <li>
              <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.centered" />
            </li>
            <li>
              <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.machineConstants" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PredictionsDisplay;
