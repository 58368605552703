export const SET_LAST_RELEASE_URL = 'SET_LAST_RELEASE_URL';
export const SET_PATCH_NOTES = 'SET_LAST_PATCH_NOTES';

export const setLastReleaseUrl = (releaseUrl) => ({
  type: SET_LAST_RELEASE_URL,
  payload: { releaseUrl },
});
export const setPatchNotes = (patchNotes) => ({
  type: SET_PATCH_NOTES,
  payload: { patchNotes },
});
