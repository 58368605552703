/* eslint-disable camelcase */
import AlreadyInUseError from 'app/errors/AlreadyInUseError';
import GenericError from 'app/errors/GenericError';
import LicenseInUseError from 'app/errors/LicenseInUseError';
import PermissionError from 'app/errors/PermissionError';
import UpdateImpossibleError from 'app/errors/UpdateImpossibleError';

const errorToMessage = (error) => {
  if (error instanceof AlreadyInUseError) return 'back.cause.AlreadyInUse';
  if (error instanceof PermissionError) return 'back.cause.PermissionError';
  if (error instanceof LicenseInUseError) return 'back.cause.LicenseInUseError';
  if (error instanceof UpdateImpossibleError) return 'back.cause.UpdateImpossibleError';
  if (error instanceof GenericError) {
    return {
      id: `back.cause.${error.cause}`,
      values: { detail: error.detail },
    };
  }

  if (error?.response?.data?.validation_error) {
    const validationError = error?.response?.data?.validation_error;
    const { body_params } = validationError;

    if (body_params) {
      return {
        id: 'error.RequestValidationError',
        values: {
          detail: body_params
            .map((paramError) => {
              const { loc, msg } = paramError;
              return `- ${loc.map((l) => `"${l}"`).join(', ')} : ${msg}`;
            })
            .join('\n'),
        },
      };
    }
  }

  const cause = error?.response?.data?.cause;
  if (cause === 'SERVER_ERROR' && error?.response?.data?.detail) {
    const detail = error?.response?.data?.detail;
    return {
      id: 'back.cause.SERVER_ERROR_WITH_DETAILS',
      values: {
        detail,
      },
    };
  }
  if (cause) return `back.cause.${cause}`;

  const status = error?.response?.status;
  if (status) return `back.status.${status}`;

  return error?.message ?? 'back.cause.UNKNOWN_ERROR';
};

export default errorToMessage;
