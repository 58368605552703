import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { List } from 'semantic-ui-react';

import DysplasiaPattern from './DysplasiaPattern';

const TEST_PATTERNS = [
  {
    pattern: 'patterns.pelvis.incongruence',
    content: <FormattedMessage id="patterns.pelvis.incongruence" />,
    left: { value: '90%', color: 'red' },
    right: { value: '45%', color: 'green' },
  },
  {
    pattern: 'norberg_olsson',
    isTool: true,
    content: 'Test tool',
    left: { value: '90%', color: 'red' },
    right: { value: '45%', color: 'green' },
  },
  {
    pattern: 'patterns.pelvis.insuffisance_de_couverture_acetabulaire',
    content: <FormattedMessage id="patterns.pelvis.insuffisance_de_couverture_acetabulaire" />,
    left: { value: '90%', color: 'red' },
    right: { value: '45%', color: 'yellow' },
  },
];

const Test = () => {
  const patterns = TEST_PATTERNS;
  return (
    <div style={{ margin: 'auto', marginTop: '60px', width: '80%' }}>
      <List style={{ display: 'inline-block' }}>
        {patterns.map(({ pattern, content, left, right, isTool }) => (
          <List.Item key={pattern}>
            <DysplasiaPattern
              content={content}
              pattern={pattern}
              left={left}
              right={right}
              isTool={isTool}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default injectIntl(Test);
