/* eslint-disable import/prefer-default-export */
export const DARK_INPUT_STYLE = {
  container: (provided, state) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    borderRadius: '5px',
    backgroundColor: !state.isDisabled ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.08)',
    ':focus-within': {
      boxShadow: 'inset 0px 0px 0px 1.5px hsl(0, 0%, 60%)',
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    minHeight: '0',
    height: '32px',
    borderColor: 'inherit',
    boxShadow: 'none',
    paddingLeft: '7px',
    ':hover': {
      borderColor: 'inherit',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    lineHeight: 'initial',
  }),
  multiValue: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 40%)',
    lineHeight: 'initial',
  }),
  input: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    '>div>input': {
      opacity: '1!important',
    },
  }),
  valueContainer: (provided) => ({ ...provided, padding: '0' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
    color: 'hsl(0, 0%, 80%)',
  }),
  clearIndicator: (provided) => ({ ...provided, padding: '0', color: 'hsl(0, 0%, 80%)' }),
  dropdownIndicator: (provided) => ({ ...provided, padding: '0', color: 'hsl(0, 0%, 80%)' }),
  menu: (provided) => ({ ...provided, backgroundColor: '#444645' }),
  option: (provided, state) => ({
    ...provided,
    padding: '6px 0px 6px 5px',
    backgroundColor:
      (state.isSelected && 'rgba(255, 255, 255, 0.3)') ||
      (state.isFocused && 'rgba(255, 255, 255, 0.2)') ||
      'transparent',
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  }),
};
