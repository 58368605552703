import { UPDATE_TELERADIOLOGY } from './constants';

/**
 * Update teleradiology
 */
function updateTeleradiology(teleradiology = { type: null, affiliateId: null }) {
  return {
    type: UPDATE_TELERADIOLOGY,
    teleradiology,
  };
}

export default updateTeleradiology;
