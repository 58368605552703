import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import MainImageRendererFactoryContext from 'app/providers/ImageRenderer/MainImageRendererFactory/context';
import { MainImageRendererFactory } from 'app/adapters/MainImageRenderer';

export default function MainImageRendererFactoryProvider({ children }: PropsWithChildren<{}>) {
  const intl = useIntl();
  const [rendererFactory] = useState(() => new MainImageRendererFactory(intl));

  useEffect(() => {
    rendererFactory.syncIntl(intl);
  }, [intl]);

  return (
    <MainImageRendererFactoryContext.Provider value={rendererFactory}>
      {children}
    </MainImageRendererFactoryContext.Provider>
  );
}
