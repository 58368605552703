import observeStore from 'app/utils/redux/observeStore';
import { Iterable, Map, fromJS } from 'immutable';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { SET_AI_ONLY_ENABLED } from './constants';
import { Action, Store } from 'redux';

export const AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.aiOnlyConfiguration';

export type AIOnlyConfigurationState = {
  enabled: boolean;
};

const aiOnlyConfigurationInitialState = fromJS(
  restoreFromLocalStorage(AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY, { enabled: false })
);

function aiOnlyConfigurationReducer(
  state: Map<string, any> = aiOnlyConfigurationInitialState,
  action: Action & { payload: any }
) {
  switch (action.type) {
    case SET_AI_ONLY_ENABLED:
      return state.set('enabled', action.payload.enabled);
    default:
      return state;
  }
}

export const selectAiOnlyConfiguration = (state: Iterable<string, any>) => state.get('aiOnlyConfiguration');
export const selectAiOnlyEnabledConfiguration = (state: Iterable<string, any>) =>
  selectAiOnlyConfiguration(state).get('enabled');

export const attachAiOnlyConfigurationWithLocalStorageSynchronizationObserver = (
  store: Store<any>
) => {
  observeStore(
    store,
    selectAiOnlyConfiguration,
    makeSyncReduxWithLocalStorageObserver(AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default aiOnlyConfigurationReducer;
