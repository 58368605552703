import React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, CheckboxProps, Icon, Label, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnnouncementsTeleradiologySeen } from 'app/redux/announcements/reducer';
import { setAnnouncementsTeleradiologySeen } from 'app/redux/announcements/actions';
import { selectAiOnlyEnabledConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import { selectLoggedIn, selectSubscribed } from 'app/redux/global/reducer';
import {
  selectPermissionAcquisition,
  selectPermissionTeleradiology,
} from 'app/redux/permissions/reducer';

export default function TeleradiologyAnnouncementModal() {
  const isTeleradiologySeen = useSelector(selectAnnouncementsTeleradiologySeen);
  const isAIOnly = useSelector(selectAiOnlyEnabledConfiguration);
  const isLoggedIn = useSelector(selectLoggedIn);
  const hasAcquisitionPermission = useSelector(selectPermissionAcquisition);
  const hasAISubscription = useSelector(selectSubscribed);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(
    () =>
      isLoggedIn &&
      (hasAcquisitionPermission || hasAISubscription) &&
      !isAIOnly &&
      !isTeleradiologySeen
  );

  const onModalChecked = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    dispatch(setAnnouncementsTeleradiologySeen(data.checked));
  };

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Header>
        <h3>
          <Icon name="bullhorn" />
          <FormattedMessage id="announcements.teleradiology.title" />
        </h3>
      </Modal.Header>
      <Modal.Content>
        <pre>
          <b>
            <FormattedMessage id="announcements.teleradiology.content" />
          </b>
        </pre>
      </Modal.Content>
      <Modal.Actions>
        {/* <span>
          <Checkbox
            label={intl.formatMessage({ id: 'announcements.do-not-show-again-label' })}
            onChange={onModalChecked}
            value={isTeleradiologySeen ? 1 : 0}
          />
        </span> */}
        <Button primary onClick={() => setModalOpen(false)}>
          <FormattedMessage id="close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
