/* eslint-disable no-empty */

import * as cst from 'cornerstone-tools';
import {
  RealSizeMeasurementCalibrationMeasurement,
  computeRealSizePixelSpacingFromMeasurement,
} from 'app/CornerstoneTools/RealSizeMeasurementCalibrationTool';

import { updateToolsList } from 'app/components/ToolsBar/utils';
import { toggleRealSizeMeasurementConfigurationOngoing } from 'app/components/Dropzone/actions';
import { ToolsStates } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';

const onRealSizeMeasurementConfigurationToggle = (
  enableRealSizeMeasurementConfiguration: boolean,
  toolsList: ToolsStates,
  produceImageToolsList: (update: (tools: ToolsStates) => void) => void,
  produceCommonToolsList: (update: (tools: ToolsStates) => void) => void
) => {
  if (enableRealSizeMeasurementConfiguration) {
    updateToolsList(
      toolsList,
      'RealSizeMeasurementCalibration',
      produceImageToolsList,
      produceCommonToolsList,
      undefined,
      'active'
    );
  } else {
    updateToolsList(
      toolsList,
      'RealSizeMeasurementCalibration',
      produceImageToolsList,
      produceCommonToolsList,
      undefined,
      'disabled'
    );
  }
};

const confirmRealSizeMeasurementConfiguration = (
  element: HTMLElement,
  dropzoneDispatch: (action: any) => void
) => {
  const realSizeMeasurement: RealSizeMeasurementCalibrationMeasurement = cst.getToolState(
    element,
    'RealSizeMeasurementCalibration'
  )?.data?.[0];
  if (!realSizeMeasurement) return;

  const realSizeScale = computeRealSizePixelSpacingFromMeasurement(element, realSizeMeasurement);
  dropzoneDispatch(toggleRealSizeMeasurementConfigurationOngoing(false));
  return realSizeScale;
};

const cancelRealSizeMeasurementConfiguration = (dropzoneDispatch: (action: any) => void) =>
  dropzoneDispatch(toggleRealSizeMeasurementConfigurationOngoing(false));

export {
  onRealSizeMeasurementConfigurationToggle,
  confirmRealSizeMeasurementConfiguration,
  cancelRealSizeMeasurementConfiguration,
};
