export default (ctx, text, x, y, isActiveStep) => {
  ctx.save();
  ctx.textBaseline = 'top';
  if (isActiveStep) ctx.fillStyle = 'white';
  else {
    ctx.fillStyle = 'darkgray';
  }
  ctx.fillText(text, x, y);
  ctx.restore();
};
