import _ from 'lodash';
import { EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE } from 'app/xray/generator/constants';

export default function linkXRayExposureToImageAcquisition(
  xrayGeneratorControllerManager,
  flatPanelDetectorManager
) {
  xrayGeneratorControllerManager.xrayGeneratorControllerHandles.forEach((generatorHandle) => {
    generatorHandle.xrayGeneratorController.addEventListener(
      EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE,
      () => {
        _.forEach(flatPanelDetectorManager.getDetectors(), (detector) => {
          detector.acquireVirtualImage();
        });
      }
    );
  });
}
