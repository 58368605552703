/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const withContext = <P={}, C={}>(
  Element: React.ComponentType<P>,
  Context: React.Context<C>,
  contextKey: string = 'context'
) =>
  React.forwardRef((props: P, ref: any) => (
    <Context.Consumer>
      {(context) => <Element {...{ [contextKey]: context }} {...props} ref={ref} />}
    </Context.Consumer>
  ));

export default withContext;
