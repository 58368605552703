import { DataImage } from 'app/interfaces/DataImage';
import { IImageTransformer, IImageTransformerFactory } from 'app/interfaces/ImageTransformer';
import { DisplayableImageData, Rect } from 'app/interfaces/Image';
import Image, { ColorModel, ImageKind } from 'image-js';
import { Transformation } from 'app/interfaces/Image/Transformation';
import {
  convertDisplayableImageToTransformableImage,
  insertCanvasMaskIntoImage,
  transformImage,
} from 'app/utils/imageManipulation/ImageTransformation';

export default class ImageTransformer implements IImageTransformer {
  private image: Image;
  constructor(image: Image) {
    this.image = image;
  }
  get info() {
    return { ...this.image };
  }

  toBuffer = () =>
    this.image.bitDepth <= 8 ? new Uint8Array(this.image.data) : new Uint16Array(this.image.data);

  flipX = () => {
    this.image = this.image.flipX();
    return this;
  };

  flipY = () => {
    this.image = this.image.flipY();
    return this;
  };

  rotate = (rotation: number) => {
    this.image = this.image.rotate(rotation);
    return this;
  };

  crop = ({ x, y, width, height }: Rect) => {
    this.image = this.image.crop({ x, y, width, height });
    return this;
  };

  transform = (transform: Transformation): ImageTransformer => {
    this.image = transformImage(this.image, transform);
    return this;
  };

  insertCanvasMask = (canvas: HTMLCanvasElement) => {
    this.image = insertCanvasMaskIntoImage(this.image, canvas);
    return this;
  };

  getMin = () => Math.min(...this.image.getMin());

  getMax = () => Math.max(...this.image.getMax());
}

export class ImageTransformerFactory implements IImageTransformerFactory {
  fromDataImage(dataImage: DataImage) {
    return new ImageTransformer(
      new Image({
        ...dataImage,
        kind: 'GREY' as ImageKind,
        components: 1,
        alpha: 0,
        bitDepth: 16,
        colorModel: 'GREY' as ColorModel,
      })
    );
  }

  fromDisplayableImage = (image: DisplayableImageData) =>
    new ImageTransformer(convertDisplayableImageToTransformableImage(image));
}
