import { parse } from 'date-fns';

function removeQuotes(str) {
  return str.replaceAll('"', '');
}

export default function convertXRayFileToShotObjects(fileContent) {
  const shots = fileContent.split(/\r?\n/).map((lineContent) => {
    const lineContentWithoutTrailingComma = lineContent.replace(/,$/, '');
    const [date, kV, mAs, mA, ms, ...operators] = lineContentWithoutTrailingComma.split(',');
    return {
      date: parse(date, 'yyyy-MM-dd_kk:mm:ss', new Date()),
      kV: parseInt(kV, 10),
      mAs: parseFloat(mAs),
      mA: parseInt(mA, 10),
      ms: parseInt(ms, 10),
      operators: operators.length === 0 ? undefined : operators.map(removeQuotes),
    };
  });
  const shotsWithoutNanKV = shots.filter((shot) => !Number.isNaN(shot.kV));
  return shotsWithoutNanKV;
}
