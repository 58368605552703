import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

function RequestText({ requestState, requestType }) {
  return (
    <div>
      <h3>
        <FormattedMessage
          id={`request.state.${requestState}`}
          values={{ request: <FormattedMessage id={`request.type.${requestType}`} /> }}
        />
      </h3>
    </div>
  );
}

export default memo(RequestText);
