import _ from 'lodash';
import { loadIntoCornerstoneImage } from 'app/utils/cornerstone/loadImage';
import { convertDicomParserDataToDicomData } from 'app/utils/dicom/convertDicomParserDataToDicomData';
import { PACS_BLACKLISTED_DICOM_FIELDS, getPixelSpacing } from 'app/utils/dicom/DicomData';
import { ImageLoader, ImagePath, LoadableImageFile, LoadedImage } from 'app/interfaces/ImageLoader';
import { DicomData } from 'app/interfaces/Dicom';

export default class CornerstoneImageLoader implements ImageLoader {
  load = async (imageFile: LoadableImageFile): Promise<LoadedImage> => {
    const loadedImage: { cornerstoneImage: cornerstone.Image } = await loadIntoCornerstoneImage({
      imageFile,
    });
    const rawDicomData = (loadedImage?.cornerstoneImage as any)?.data;
    const dicomData: DicomData = rawDicomData
      ? (_.omit(
          convertDicomParserDataToDicomData(rawDicomData),
          PACS_BLACKLISTED_DICOM_FIELDS
        ) as DicomData)
      : undefined;

    const imageData = _.pick(loadedImage.cornerstoneImage, [
      'imageId',
      'minPixelValue',
      'maxPixelValue',
      'slope',
      'intercept',
      'windowCenter',
      'windowWidth',
      'getPixelData',
      'getImageData',
      'getCanvas',
      'getImage',
      'rows',
      'columns',
      'height',
      'width',
      'color',
      'lut',
      'rgba',
      'columnPixelSpacing',
      'rowPixelSpacing',
      'invert',
      'sizeInBytes',
      'falseColor',
      'origPixelData',
      'stats',
      'cachedLut',
      'colormap',
      'labelmap',
    ]);
    if (!imageData.rowPixelSpacing && dicomData) {
      const [rowPixelSpacing, columnPixelSpacing] = getPixelSpacing(dicomData) ?? [];
      imageData.rowPixelSpacing = rowPixelSpacing;
      imageData.columnPixelSpacing = columnPixelSpacing;
    }

    return {
      imageData,
      dicomData,
      filename: (imageFile as ImagePath).path ?? (imageFile as File).name,
    };
  };
}
