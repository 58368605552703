import { useEffect, useRef } from 'react';

export default function useDidUpdateEffect(effect: Parameters<typeof useEffect>[0], deps: any[]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) return effect();

    didMountRef.current = true;
  }, deps);
}
