import React from 'react';
import { TeleradiologyStudyIdentifier } from 'app/interfaces/TeleradiologyAPI';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Grid, GridColumn, GridRow, Header, Segment } from 'semantic-ui-react';
import { API } from 'app/utils/apiCalls';

type TeleradiologyVetFlowModalAnalyzeRequestDetailProps = {
  teleradiologyStudy: TeleradiologyStudyIdentifier;
};

/**
 * VetFlow Analyze Request Detail for Modal
 * @returns {JSX.Element}
 */
function TeleradiologyVetFlowModalAnalyzeRequestDetail({
  teleradiologyStudy,
}: TeleradiologyVetFlowModalAnalyzeRequestDetailProps) {
  const isStudyDone = teleradiologyStudy.analysis_status === API.Teleradiology.AnalysisStatus.DONE;

  const radiologistFieldset = isStudyDone && (
    <fieldset>
      <legend>
        <FormattedMessage id="teleradiology.analyze-request.radiologist.label" />

        {teleradiologyStudy?.response_date && (
          <>
            {' - '}
            <FormattedDate value={teleradiologyStudy?.response_date} />{' '}
            <FormattedTime value={teleradiologyStudy?.response_date} />
          </>
        )}
      </legend>
      {teleradiologyStudy?.radiologist_analysis && (
        <>
          <Header as="h4">
            <FormattedMessage id="teleradiology.analyze-request.radiologist.analysis.label" />
          </Header>
          <Segment>{teleradiologyStudy.radiologist_analysis}</Segment>
        </>
      )}
      {teleradiologyStudy?.radiologist_conclusion && (
        <>
          <Header as="h4">
            <FormattedMessage id="teleradiology.analyze-request.radiologist.conclusion.label" />
          </Header>
          <Segment>{teleradiologyStudy.radiologist_conclusion}</Segment>
        </>
      )}
      {teleradiologyStudy?.radiologist_recommendation && (
        <>
          <Header as="h4">
            <FormattedMessage id="teleradiology.analyze-request.radiologist.recommendation.label" />
          </Header>
          <Segment>{teleradiologyStudy.radiologist_recommendation}</Segment>
        </>
      )}
      {teleradiologyStudy?.radiologist_comment && (
        <>
          <Header as="h4">
            <FormattedMessage id="teleradiology.analyze-request.radiologist.comment.label" />
          </Header>
          <Segment>{teleradiologyStudy.radiologist_comment}</Segment>
        </>
      )}
    </fieldset>
  );

  return (
    <>
      {radiologistFieldset}
      <fieldset>
        <legend>
          <FormattedMessage id="teleradiology.analyze-request.exam.label" />
          {teleradiologyStudy?.date_submitted && (
            <>
              {' - '}
              <FormattedDate value={teleradiologyStudy.date_submitted} />{' '}
              <FormattedTime value={teleradiologyStudy.date_submitted} />
            </>
          )}
        </legend>

        <Header as="h4">
          <FormattedMessage id="teleradiology.analyze-request.reason.label" />
        </Header>
        <Segment>{teleradiologyStudy.reason}</Segment>

        <Header as="h4">
          <FormattedMessage id="teleradiology.analyze-request.clinical-signs.label" />
        </Header>
        <Segment>{teleradiologyStudy.clinical_signs}</Segment>

        <Header as="h4">
          <FormattedMessage id="teleradiology.analyze-request.veterinarian-interpretation.label" />
        </Header>
        <Segment>{teleradiologyStudy.interpretation}</Segment>

        {(teleradiologyStudy.referent_veterinarian_name ||
          teleradiologyStudy.referent_veterinarian_email) && (
          <>
            <br />
            <Grid>
              <GridRow columns={2}>
                {teleradiologyStudy.referent_veterinarian_name && (
                  <GridColumn>
                    <Header as="h4">
                      <FormattedMessage id="teleradiology.analyze-request.referent-veterinarian.label" />
                    </Header>
                    <Segment>{teleradiologyStudy.referent_veterinarian_name}</Segment>
                  </GridColumn>
                )}

                {teleradiologyStudy.referent_veterinarian_email && (
                  <GridColumn>
                    <Header as="h4">
                      <FormattedMessage id="teleradiology.analyze-request.referent-email.label" />
                    </Header>
                    <Segment>{teleradiologyStudy.referent_veterinarian_email}</Segment>
                  </GridColumn>
                )}
              </GridRow>
            </Grid>
          </>
        )}
      </fieldset>
    </>
  );
}

export default TeleradiologyVetFlowModalAnalyzeRequestDetail;
