import {
  // @ts-ignore
  import as cornerstoneToolsImport,
  addToolState,
  toolStyle,
  textStyle,
  store,
  PanTool,
  ScaleOverlayTool,
  ZoomTouchPinchTool,
  LengthTool,
  PanMultiTouchTool,
  addToolForElement,
  setToolActiveForElement,
  setToolDisabledForElement,
  setToolPassiveForElement,
  getToolForElement,
  getToolState,
  EVENTS,
  removeToolState,
  toolColors,
  getModule,
} from 'cornerstone-tools';

export const lineSegDistance = cornerstoneToolsImport('util/lineSegDistance');
export const setShadow = cornerstoneToolsImport('drawing/setShadow');
export const cursors = cornerstoneToolsImport('tools/cursors');
export interface IBaseAnnotationTool {
  configuration: {
    drawHandles?: boolean;
    drawHandlesOnHover?: boolean;
    hideHandlesIfMoving?: boolean;
    arrowFirst?: boolean;
    renderDashed?: boolean;
    allowEmptyLabel?: boolean;
    handleRadius?: number;
  };
  options: any;
  supportedInteractionTypes: ('Mouse' | 'Touch')[];
  name: string;
}
interface BaseAnnotationToolCtor {
  new (...args: any[]): IBaseAnnotationTool;
}

export const MouseCursor = cornerstoneToolsImport('tools/cursors/MouseCursor');
export const BaseAnnotationTool: BaseAnnotationToolCtor =
  cornerstoneToolsImport('base/BaseAnnotationTool');
export const moveHandleNearImagePoint = cornerstoneToolsImport(
  'manipulators/moveHandleNearImagePoint'
);
export const BaseBrushTool = cornerstoneToolsImport('base/BaseBrushTool');
export const BaseTool = cornerstoneToolsImport('base/BaseTool');
export const draw = cornerstoneToolsImport('drawing/draw');
export const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
export const drawHandles = cornerstoneToolsImport('drawing/drawHandles');
export const drawLinkedTextBox = cornerstoneToolsImport('drawing/drawLinkedTextBox');
export const drawCircle = cornerstoneToolsImport('drawing/drawCircle');
export const path = cornerstoneToolsImport('drawing/path');
export const drawLine = cornerstoneToolsImport('drawing/drawLine');
export const drawTextBox = cornerstoneToolsImport('drawing/drawTextBox');
export const clipToBox = cornerstoneToolsImport('util/clipToBox');
export const triggerEvent = cornerstoneToolsImport('util/triggerEvent');
export const getProximityThreshold = cornerstoneToolsImport('util/getProximityThreshold');
export const anyHandlesOutsideImage = cornerstoneToolsImport('manipulators/anyHandlesOutsideImage');
export const getHandleNearImagePoint = cornerstoneToolsImport(
  'manipulators/getHandleNearImagePoint'
);
export const findHandleDataNearImagePoint = cornerstoneToolsImport(
  'manipulators/findHandleDataNearImagePoint'
);
export const throttle = cornerstoneToolsImport('util/throttle');
export const getLogger = cornerstoneToolsImport('util/getLogger');

export {
  addToolState,
  toolStyle,
  textStyle,
  toolColors,
  store,
  PanTool,
  ScaleOverlayTool,
  LengthTool,
  ZoomTouchPinchTool,
  PanMultiTouchTool,
  addToolForElement,
  setToolActiveForElement,
  setToolDisabledForElement,
  setToolPassiveForElement,
  getToolForElement,
  getToolState,
  EVENTS,
  removeToolState,
  getModule,
};
