/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getToolState, removeToolState } from 'cornerstone-tools';

import OnClickToolButton from 'app/components/ToolsBar/OnClickToolButton';
import ToolWithOptions from 'app/components/ToolsBar/ToolWithOptions';

type RealSizeMeasurementConfigurationButtonProps = {
  isRealSizeMeasurementConfigurationOngoing: boolean;
  toggleRealSizeMeasurementConfiguration: (shouldToggle: boolean) => void;
} & (
  | {
      focusedElement: HTMLElement; // Cornerstone style usage
    }
  | {
      resetRealSizeMeasurement?: () => void; // Dependency injection style
    }
);

export default function RealSizeMeasurementConfigurationButton({
  isRealSizeMeasurementConfigurationOngoing,
  toggleRealSizeMeasurementConfiguration,
  ...props
}: RealSizeMeasurementConfigurationButtonProps) {
  const intl = useIntl();

  let resetAction = () => {};

  if ('resetRealSizeMeasurement' in props) {
    resetAction = props.resetRealSizeMeasurement;
  } else if ('focusedElement' in props) {
    () => {
      const toolData = getToolState(props.focusedElement, this.name);
      const measurement = toolData?.data?.[0];
      if (measurement) {
        removeToolState(props.focusedElement, 'RealSizeMeasurementCalibration', measurement);
      }
    };
  }
  const actions = [
    {
      content: <FormattedMessage id="general.reset" />,
      action: resetAction,
    },
  ];

  return (
    <>
      <ToolWithOptions
        content={
          <OnClickToolButton
            aria-label="realsize-measurement-button"
            onClick={() => toggleRealSizeMeasurementConfiguration(true)}
            content={<i className="svg-icon ruler" />}
            tooltipId="tools.real_size.tooltip"
            isActive={isRealSizeMeasurementConfigurationOngoing}
          />
        }
        actions={actions}
        aria-label="realsize-measurement-options"
      />
    </>
  );
}
