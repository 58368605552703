import { getCurrentHub } from '@sentry/browser';
import _ from 'lodash';
import {
  CONSOLE_LEVELS,
  GLOBAL_OBJ,
  addInstrumentationHandler,
  severityLevelFromString,
  safeJoin,
} from '@sentry/utils';

// Truncate big array buffer and deeply nested object to avoid performance issue due to sentry breadcrumbs
const safeCloneObject = (value, key, object, stack) => {
  if (stack?.size > 5) return {};
  if (value?.buffer instanceof ArrayBuffer || ArrayBuffer.isView(value)) {
    return value.slice(0, Math.min(value.length, 10));
  }
  return undefined;
};

const safeCopyArgs = (args) =>
  !Array.isArray(args) ? [] : args.map((arg) => _.cloneDeepWith(arg, safeCloneObject));

function makeConsoleBreadcrumb(levels) {
  return (handlerData) => {
    if (!levels.includes(handlerData.level)) return;

    const safeArgs = safeCopyArgs(handlerData.args);

    const breadcrumb = {
      category: 'console',
      data: {
        arguments: safeArgs,
        logger: 'console',
      },
      level: severityLevelFromString(handlerData.level),
      message: safeJoin(safeArgs, ' '),
    };

    if (handlerData.level === 'assert') {
      if (handlerData.args[0] === false) {
        breadcrumb.message = `Assertion failed: ${
          safeJoin(safeArgs.slice(1), ' ') || 'console.assert'
        }`;
        breadcrumb.data.arguments = safeArgs.slice(1);
      } else {
        // Don't capture a breadcrumb for passed assertions
        return;
      }
    }

    getCurrentHub().addBreadcrumb(breadcrumb, {
      input: safeArgs,
      level: handlerData.level,
    });
  };
}

/**
 * Implement a safer method for Sentry BreadCrumbs that truncate ArrayBuffer and deeply nested object to avoid performance issue when creating log entries
 */
export default class SafeConsoleBreadCrumbs {
  static id = 'SafeConsoleBreadCrumbs';

  constructor({ levels } = {}) {
    this.name = SafeConsoleBreadCrumbs.id;
    this._levels = levels || CONSOLE_LEVELS;
    this.counter = 0;
  }

  setupOnce(_ignored, getCurrentHub) {
    if (!('console' in GLOBAL_OBJ)) {
      return;
    }

    const levels = this._levels;

    addInstrumentationHandler('console', makeConsoleBreadcrumb(levels));
  }
}
