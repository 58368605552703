import './style.scss';

import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup, Progress, Table } from 'semantic-ui-react';

const GB_DIVIDER = 1024 * 1024 * 1024;
const GB_WARNING_THRESHOLD = 50;
const GB_CRITICAL_THRESHOLD = 20;
/*
usage
    totalSize: int
    availableSize: int
directory
deviceName
deviceIdentifier
*/
function SimpleStorageDisplay({ usage }) {
  const { totalSize, availableSize } = usage;

  const totalInGB = (totalSize / GB_DIVIDER).toFixed(0);
  const availableInGB = (availableSize / GB_DIVIDER).toFixed(0);
  const usedInGB = (totalInGB - availableInGB).toFixed(0);

  let color = 'blue';
  if (availableInGB < GB_CRITICAL_THRESHOLD) {
    color = 'red';
  } else if (availableInGB < GB_WARNING_THRESHOLD) {
    color = 'orange';
  }

  return (
    <div className="storage-display__simple-display">
      <div className="storage-display__simple-display__label">
        <Icon name="hdd" />
        <span>
          {usedInGB} / {totalInGB}
        </span>
      </div>
      <Progress
        className="storage-display__simple-display__progress"
        size="small"
        value={usedInGB}
        total={totalInGB}
        color={color}
      />
    </div>
  );
}

export default function StorageUsageDisplay({ storagesUsage }) {
  if (!storagesUsage) return null;

  const sortedStorages = _.sortBy(storagesUsage, ['usage.availableSize']);

  return (
    <Popup
      on="click"
      trigger={
        <Button basic compact>
          <SimpleStorageDisplay usage={sortedStorages[0].usage} />
        </Button>
      }
    >
      <Table compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="storage_usage.directory" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="storage_usage.usage" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedStorages.map(({ usage, directory, deviceName, deviceIdentifier }) => (
            <Table.Row key={`${deviceIdentifier}-${directory}`}>
              <Table.Cell
                style={{ whiteSpace: 'nowrap', fontWeight: '700' }}
              >{`${deviceName}\\\\${directory}`}</Table.Cell>
              <Table.Cell>
                <SimpleStorageDisplay usage={usage} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Popup>
  );
}
