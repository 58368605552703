/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Confirm } from 'semantic-ui-react';
import OnClickToolButton from 'app/components/ToolsBar/OnClickToolButton';
import ToolWithOptions from 'app/components/ToolsBar/ToolWithOptions';

const FullSizeConfigurationButton = ({
  applyFullSize,
  isFullSizeConfigurationOngoing,
  toggleFullSizeConfiguration,
  hidden,
  intl,
}) => {
  if (hidden) return null;

  const [isFullSizeConfigurationModalOpened, setIsFullSizeConfigurationModalOpened] =
    useState(false);

  const onFullSizeClick = () => {
    const isFullSizeApplied = applyFullSize();
    if (!isFullSizeApplied) setIsFullSizeConfigurationModalOpened(true);
  };

  const actions = [
    {
      content: intl.formatMessage({ id: 'tools.full_size.start_configuration' }),
      action: () => toggleFullSizeConfiguration(true),
    },
  ];

  const onConfirmFullSizeConfiguration = () => {
    toggleFullSizeConfiguration(true);
    setIsFullSizeConfigurationModalOpened(false);
  };

  return (
    <>
      <ToolWithOptions
        content={
          <OnClickToolButton
            aria-label="viewer-action"
            onClick={onFullSizeClick}
            content={<i className="svg-icon full-size" />}
            tooltipId="tools.full_size.tooltip"
            isActive={isFullSizeConfigurationOngoing}
          />
        }
        actions={actions}
      />
      <Confirm
        role="dialog"
        open={isFullSizeConfigurationModalOpened}
        header={intl.formatMessage({ id: 'tools.full_size.configuration_needed.title' })}
        content={intl.formatMessage({ id: 'tools.full_size.configuration_needed.description' })}
        cancelButton={intl.formatMessage({ id: 'general.cancel' })}
        confirmButton={intl.formatMessage({ id: 'general.confirm' })}
        onCancel={() => setIsFullSizeConfigurationModalOpened(false)}
        onConfirm={onConfirmFullSizeConfiguration}
      />
    </>
  );
};

export default injectIntl(FullSizeConfigurationButton);
