/* eslint-disable no-empty */
import { toggleFullSizeConfigurationOngoing } from 'app/components/Dropzone/actions';
import { updateToolsList } from 'app/components/ToolsBar/utils';
import { updateFullSize } from 'app/redux/viewerConfiguration/actions';
import { getViewport, setViewport, updateImage } from 'cornerstone-core';
import { merge } from 'lodash';

const onFullSizeConfigurationToggle = (
  enableFullSizeConfiguration,
  toolsList,
  produceImageToolsList,
  produceCommonToolsList
) => {
  if (enableFullSizeConfiguration) {
    updateToolsList(
      toolsList,
      'FineGrainZoomMouseWheel',
      produceImageToolsList,
      produceCommonToolsList,
      undefined,
      'active'
    );
    updateToolsList(
      toolsList,
      'Pan',
      produceImageToolsList,
      produceCommonToolsList,
      undefined,
      'active'
    );
  } else {
    updateToolsList(
      toolsList,
      'ZoomMouseWheel',
      produceImageToolsList,
      produceCommonToolsList,
      undefined,
      'active'
    );
  }
};

const confirmFullSizeConfiguration = (element, detectorId, reduxDispatch, dropzoneDispatch) => {
  reduxDispatch(updateFullSize(detectorId, getViewport(element).scale));
  dropzoneDispatch(toggleFullSizeConfigurationOngoing(false));
};

const cancelFullSizeConfiguration = (dropzoneDispatch) =>
  dropzoneDispatch(toggleFullSizeConfigurationOngoing(false));

const rescaleDisplayedImageToConfiguredFullSize = (
  displayedImageElement,
  fullSizeScaleConfigurations = {},
  detectorId
) => {
  const scale = fullSizeScaleConfigurations[detectorId];
  if (scale === undefined) return false;
  try {
    // Doing this is required instead of just setting scale else image will not be updated properly.
    const viewport = getViewport(displayedImageElement);
    const newViewport = merge({}, viewport, { scale });
    setViewport(displayedImageElement, newViewport);
  } catch {}
  return true;
};

export {
  onFullSizeConfigurationToggle,
  confirmFullSizeConfiguration,
  cancelFullSizeConfiguration,
  rescaleDisplayedImageToConfiguredFullSize,
};
