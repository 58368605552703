export const SET_RAD_PARAM_COMB = 0x42;
export const DR_READY = 0x44;
export const SET_SINGLE_RAD_PARAM = 0x46;
export const RESET_WARNING = 0x49;
export const START_CONFIG = 0x55;
export const PREP_STATE = 0x56;
export const EXP_STATE = 0x57;
export const START_XRAY = 0x58;
export const PREP_CONFIRMATION = 0x59;
export const REQ_THICKNESS = 0x60;
export const GEN_THICKNESS = 0x61;
export const REQ_CHANGE_OPERATING_MODE = 0x62;
export const RAD_VALUE_COMB_STATUS = 0x7f;
export const REQ_GEN_STATUS = 0x78;
export const GEN_OPERATIVE_STATUS = 0x79;
export const RAD_VALUES_POST_EXP = 0x7b;
export const ERROR = 0x80;
export const SW_VER = 0x81;
export const WARNING = 0x82;
