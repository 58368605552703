import { DicomData } from 'app/interfaces/Dicom';
import { IDicomWriter } from 'app/interfaces/IDicomWriter';
import picoxiaDicom from 'app/native/node-addons/picoxia-dicom';
import { getDicomDataValue } from 'app/utils/dicom/DicomData';

export class NativeDicomWriter implements IDicomWriter {
  async generateDicomFile(dicomData: DicomData): Promise<Uint8Array> {
    return picoxiaDicom()?.writeDicomAsync(
      dicomData,
      undefined,
      getDicomDataValue(dicomData, 'TransferSyntaxUID')
    );
  }
}
