import { restoreFromLocalStorage } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  PMS_SET_WATCHED_DIRECTORY,
  PMS_SET_IS_INVALID_WATCHED_DIRECTORY,
  PMS_SET_TYPE,
  PMS_SET_EXPORT_CONFIGURATION,
  PMS_SET_EXPORT_DIRECTORY,
  PMS_SET_SPECIFIC_CONFIGURATION,
} from 'app/pms/actions';
import { fromJS, Map } from 'immutable';
import _ from 'lodash';

const initState = () =>
  fromJS(
    restoreFromLocalStorage('redux.pms') ?? {
      watchedDirectory: undefined,
      isInvalidWatchedDirectory: undefined,
    }
  );

/**
 *
 * @param {Immutable.Map} state
 * @param {{
 * type: PMS_SET_WATCHED_DIRECTORY | PMS_SET_IS_INVALID_WATCHED_DIRECTORY | PMS_SET_TYPE | PMS_SET_EXPORT_CONFIGURATION | PMS_SET_EXPORT_DIRECTORY
 * payload: any
 * }} action
 * @returns
 */
type Action = { type: string; payload: any };

function pmsReducer(state = initState(), { type, payload }: Action) {
  switch (type) {
    case PMS_SET_WATCHED_DIRECTORY:
      return state.set('watchedDirectory', payload.watchedDirectory);
    case PMS_SET_IS_INVALID_WATCHED_DIRECTORY:
      return state.set('isInvalidWatchedDirectory', payload.isInvalidWatchedDirectory);
    case PMS_SET_TYPE:
      return state.set('type', payload.type);
    case PMS_SET_EXPORT_CONFIGURATION:
      return state.set('exportConfiguration', fromJS(payload.exportConfiguration));
    case PMS_SET_EXPORT_DIRECTORY:
      return state.setIn(['exportConfiguration', 'exportDirectory'], payload.exportDirectory);
    case PMS_SET_SPECIFIC_CONFIGURATION: {
      const { pmsType, path, value } = payload;
      return state.setIn([pmsType, ..._.toPath(path)], value);
    }
    default:
      return state;
  }
}

export default pmsReducer;
