// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { checkAuth, getAsyncInjectors } from 'app/utils/asyncInjectors';

// Imports of the components
import { makeSelectLocale } from 'app/containers/LanguageProvider/selectors';
import { getURLRedirectionWithNewLocale, isLanguageSupported } from 'app/utils/languageUtil';
import App from 'app/containers/App';
import DownloadPage from 'app/containers/DownloadPage';
import PartnerLinkPage from 'app/containers/PartnerLinkPage';
import ManagementPage from 'app/containers/ManagementPage';
import HomePage from 'app/containers/HomePage';
import CheatSheetPage from 'app/containers/CheatSheetPage';
import AccountPage from 'app/containers/AccountPage';
import MonitoringPage from 'app/containers/MonitoringPage';
import ChangePasswordPage from 'app/containers/ChangePasswordPage';
import UnsubscribePage from 'app/containers/UnsubscribePage';
import ConfirmMailPage from 'app/containers/ConfirmMailPage';
import LoginPage from 'app/containers/LoginPage';
import BillingPage from 'app/containers/BillingPage';
import PaymentSuccessPage from 'app/containers/PaymentSuccessPage';
import PaymentCanceledPage from 'app/containers/PaymentCanceledPage';
import AcquisitionPage from 'app/containers/AcquisitionPage';
import TutorialsPage from 'app/containers/TutorialsPage';
import TestDysplasiaPatternStructuredList from 'app/components/DysplasiaPatternStructuredList/TestDysplasiaPatternStructuredList';
import TestDysplasiaPattern from 'app/components/DysplasiaPatternStructuredList/TestDysplasiaPattern';
import TestPatientInfoForm from 'app/components/PatientInfoForm/TestPatientInfoForm';
import PicoxIAAcquisitionPage from 'app/containers/PicoxIAAcquisitionPage';
import PicoxIAIntelligencePage from 'app/containers/PicoxIAIntelligencePage';
import RequestDemoPage from 'app/containers/RequestDemoPage';
import ProductionViewer from 'app/components/Viewer/ProductionViewer';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import AdminPage from 'app/containers/AdminPage';
import PrivacyPolicyPage from 'app/containers/PrivacyPolicyPage';
import LegalNoticesPage from 'app/containers/LegalNoticesPage';
import TermsOfSalesPage from 'app/containers/TermsOfSalesPage';
import TermsOfUsePage from 'app/containers/TermsOfUsePage';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

const redirectToLocaleIfNeeded = (store, { location }, replace) => {
  const urlLanguage = location.pathname.split('/')[1];
  const locale = makeSelectLocale()(store.getState());
  const firstParamIsASupportedLanguage = isLanguageSupported(urlLanguage);
  if (!firstParamIsASupportedLanguage) {
    replace(getURLRedirectionWithNewLocale(location, locale));
  }
};

function ReloadableProductionViewer(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const [key, setKey] = useState(() => v4());
  const mountCheckRef = useRef(false);

  useEffect(() => {
    if (!mountCheckRef.current) {
      mountCheckRef.current = true;
      return;
    }
    setKey(v4());
  }, [props.location]);
  return <ProductionViewer {...props} key={key} />;
}

const DEFAULT_PATH_FOR_DESKTOP = isAcquisitionSoftware() ? 'acquisition' : 'viewer';

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  // const maintenance = [
  //   {
  //     path: '*',
  //     name: 'maintenancePage',
  //     component: MaintenancePage,
  //   },
  // ];
  const electronSpecific = [
    {
      path: '*',
      onEnter: (nextState, replace) =>
        replace({
          ...nextState.location,
          pathname: `/${nextState.params.lang}/${DEFAULT_PATH_FOR_DESKTOP}`,
        }),
    },
  ];

  const websiteSpecific = [
    {
      path: 'download',
      name: 'downloadPage',
      component: DownloadPage,
    },
    {
      path: 'picoxia-intelligence',
      name: 'picoxIAIntelligence',
      component: PicoxIAIntelligencePage,
    },
    {
      path: 'picoxia-acquisition',
      name: 'picoxIAAcquisition',
      component: PicoxIAAcquisitionPage,
    },
    {
      path: 'tutorials',
      name: 'tutorialsPage',
      component: TutorialsPage,
    },
    {
      path: 'request-demo',
      name: 'requestDemoPage',
      component: RequestDemoPage,
    },
    {
      path: 'link',
      name: 'partnerLinkPage',
      component: PartnerLinkPage,
    },
    {
      path: 'management',
      name: 'ManagementPage',
      component: ManagementPage,
    },
    {
      path: 'admin',
      name: 'AdminPage',
      component: AdminPage,
    },
    {
      name: 'homePage',
      // onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: HomePage,
    },
    {
      path: '*',
      onEnter: (nextState, replace) =>
        replace({ ...nextState.location, pathname: `/${nextState.params.lang}/` }),
    },
  ];

  const common = [
    {
      path: 'viewer',
      name: 'viewerPage',
      component: ReloadableProductionViewer,
    },
    {
      path: 'cheat_sheet',
      name: 'CheatSheetPage',
      component: CheatSheetPage,
    },
    {
      path: 'account',
      name: 'accountPage',
      onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: AccountPage,
    },
    {
      path: 'monitoring',
      name: 'monitoringPage',
      onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: MonitoringPage,
    },
    {
      path: 'change_password',
      name: 'changePasswordPage',
      component: ChangePasswordPage,
    },
    {
      path: 'unsubscribe_mail',
      name: 'UnsubscribePage',
      component: UnsubscribePage,
    },
    {
      path: 'confirm_email',
      name: 'confirmMailPage',
      component: ConfirmMailPage,
    },
    {
      path: 'login',
      name: 'loginPage',
      component: LoginPage,
    },
    {
      path: 'purchase',
      name: 'billingPage',
      component: BillingPage,
    },
    {
      path: 'purchase_success',
      name: 'paymentSuccess',
      component: PaymentSuccessPage,
    },
    {
      path: 'purchase_canceled',
      name: 'paymentCanceledPage',
      component: PaymentCanceledPage,
    },
    {
      path: 'register',
      name: 'registerPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('app/containers/RegisterPage/reducer'),
          import('app/containers/RegisterPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer('registerPage', reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: 'acquisition',
      name: 'AcquisitionPage',
      component: AcquisitionPage,
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: 'legal-notices',
      name: 'LegalNoticesPage',
      component: LegalNoticesPage,
    },
    {
      path: 'terms-of-sales',
      name: 'TermsOfSalesPage',
      component: TermsOfSalesPage,
    },
    {
      path: 'terms-of-use',
      name: 'TermsOfUsePage',
      component: TermsOfUsePage,
    },
  ];

  const testRoutes = [
    {
      path: 'test_dysplasia_pattern_structured_list',
      name: 'TestDysplasiaPatternStructuredList',
      component: TestDysplasiaPatternStructuredList,
    },
    {
      path: 'test_dysplasia_pattern',
      name: 'TestDysplasiaPattern',
      component: TestDysplasiaPattern,
    },
    {
      path: 'test_patient_info_form',
      name: 'TestPatientInfoForm',
      component: TestPatientInfoForm,
    },
  ];

  let childRoutes = common;
  if (process.env.NODE_ENV === 'development') {
    childRoutes = childRoutes.concat(testRoutes);
  }
  if (process.env.PLATFORM === 'electron') {
    childRoutes = childRoutes.concat(electronSpecific);
  } else {
    childRoutes = childRoutes.concat(websiteSpecific);
  }

  let componentToFirstLoad;
  if (process.env.PLATFORM === 'electron') {
    if (isAcquisitionSoftware()) {
      componentToFirstLoad = AcquisitionPage;
    } else {
      componentToFirstLoad = ReloadableProductionViewer;
    }
  } else {
    componentToFirstLoad = HomePage;
  }

  // Wrapp all Routes in the App component
  const rootRoute = {
    component: App,
    path: '/',
    indexRoute: { component: componentToFirstLoad },
    childRoutes: [
      {
        indexRoute: { component: componentToFirstLoad },
        onEnter: (nextState, replace) => redirectToLocaleIfNeeded(store, nextState, replace),
        path: ':lang',
        childRoutes,
      },
    ],
  };

  return rootRoute;
}
