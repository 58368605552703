import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { isDev } from 'app/utils/envUtil';

type GoogleReCaptchaProps = {
  useRef: React.MutableRefObject<undefined>;
};

const DEV_SITE_KEY = '6Le8GbgpAAAAAHlY0fGl5dKrvChHe2aGqpkHss5I';
const PROD_SITE_KEY = '6LePHLgpAAAAAFhE0M0C2T4Z-ZZ2ZWgqWPLvQmLf';

function GoogleReCaptcha({ useRef }: GoogleReCaptchaProps) {
  const siteKey = isDev() ? DEV_SITE_KEY : PROD_SITE_KEY;

  return <ReCAPTCHA sitekey={siteKey} ref={useRef} />;
}

export default GoogleReCaptcha;
