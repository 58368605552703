const TimeoutPromise = (timeout) => {
  let timeoutHandle;
  const promise = new Promise((resolve, reject) => {
    timeoutHandle = setTimeout(resolve, timeout);
  });
  promise.cancel = () => clearTimeout(timeoutHandle);
  return promise;
};

export default TimeoutPromise;
