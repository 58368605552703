import React, { memo } from 'react';
import * as csc from 'cornerstone-core';
import { FormattedMessage } from 'react-intl';
import * as pt from 'prop-types';

import ToolWithOptions from 'app/components/ToolsBar/ToolWithOptions';
import { forceImageUpdate } from 'app/CornerstoneTools/forceImageUpdate';
import ActivatableToolButton from './ActivatableToolButton';

/**
 * Window Width Window Center Group gather to tools linked to contrast with the active tool
 * being displayed and the options tools and function in a dropdown menu.
 */

const WWWCGroup = memo((props) => {
  const WWWCIcon = (
    <div style={{ display: 'flex' }} role="presentation">
      <ActivatableToolButton
        content={<i className="svg-icon wwwc-tool" />}
        tooltipId="tools.wwwc.tooltip"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        name="WWWC"
      />
    </div>
  );

  const resetWWWC = () => {
    const { focusedElement, initialWindowCenter, initialWindowWidth } = props;
    if (!focusedElement) return;
    try {
      const { viewport, image } = csc.getEnabledElement(focusedElement);
      if (!image) return;

      const maxVOI = image.maxPixelValue * image.slope + image.intercept;
      const minVOI = image.minPixelValue * image.slope + image.intercept;
      viewport.voi.windowWidth = initialWindowWidth ?? maxVOI - minVOI;
      viewport.voi.windowCenter = initialWindowCenter ?? (maxVOI + minVOI) / 2;

      // Unset any existing VOI LUT
      viewport.voiLUT = undefined;

      forceImageUpdate(focusedElement);
    } catch (error) {
      console.log('Error in WWWCResetOption', error);
    }
  };

  return (
    <ToolWithOptions
      content={WWWCIcon}
      actions={[
        {
          content: (
            <>
              <i className="svg-icon wwwc-tool" />
              <FormattedMessage id="tools.wwwc_reset.text" />
            </>
          ),
          action: resetWWWC,
        },
      ]}
    />
  );
});

WWWCGroup.propTypes = {
  focusedElement: pt.instanceOf(HTMLElement),
  initialWindowCenter: pt.number,
  initialWindowWidth: pt.number,
};

WWWCGroup.defaultProps = {
  focusedElement: undefined,
  initialWindowCenter: undefined,
  initialWindowWidth: undefined,
};

export default WWWCGroup;
