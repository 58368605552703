import { DisplayViewport, DisplayableImageData, Size } from 'app/interfaces/Image';
import {
  BasicDisplayOptions,
  BasicImageRenderer,
  BasicImageRendererFactory,
  BasicRendererAnnotationsForDisplay,
} from 'app/interfaces/ImageRenderer';
import { prepareImageForCanvasDisplay } from 'app/utils/imageManipulation/ImageTransformation';
import _ from 'lodash';
import DisplayEvent from 'app/interfaces/DisplayEvent';
import { Transformation } from 'app/interfaces/Image/Transformation';
import { convertCropAnnotationsToCropRect } from 'app/components/Viewer/convertCropAnnotationsToCropRect';
import {
  computeScaleFactorFromStoredViewport,
  getMatchingResolutionViewport,
  rescaleAnnotationsPoints,
} from 'app/utils/cornerstone/imageUtils';
import { getDefaultViewport } from 'cornerstone-core';

export class ThumbnailImageRenderer extends EventTarget implements BasicImageRenderer {
  public element: HTMLElement;
  private displayWidth: number;
  private displayHeight: number;
  private canvas: HTMLCanvasElement;
  private resizeObserver: ResizeObserver;
  private displayedImage?: DisplayableImageData;
  private lastDisplayedImage?: DisplayableImageData;
  private viewport?: DisplayViewport;
  private annotations?: BasicRendererAnnotationsForDisplay;
  private lastTransformation?: Transformation;

  constructor(element: HTMLElement) {
    super();
    this.element = element;

    this.element.style.width = '100%';
    this.element.style.height = '100%';
    this.element.style.display = 'flex';
    this.element.style.alignItems = 'center';
    this.element.style.justifyContent = 'center';

    this.canvas = this.element.querySelector('canvas');
    if (!this.canvas) {
      document.createElement('canvas');
      this.element.appendChild(this.canvas);
    }
    this.canvas.style.objectFit = 'none';

    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.element);

    const { width, height } = element.getBoundingClientRect();
    this.displayWidth = Math.floor(width);
    this.displayHeight = Math.floor(height);

    this.dispatchEvent(new DisplayEvent('display_updated', false));
  }

  displayImage = (image: DisplayableImageData, { viewport, annotations }: BasicDisplayOptions) => {
    this.annotations = annotations;
    // @ts-ignore image type from cornerstone is wrong
    this.viewport = viewport ?? getDefaultViewport(this.canvas, image);
    this.displayedImage = image;

    this.triggerCanvasDisplay();
  };

  destroy = () => {
    this.resizeObserver.disconnect();
    this.canvas.remove();
  };

  private triggerCanvasDisplay = () => {
    const isImageDisplayable =
      this.displayedImage &&
      this.displayWidth &&
      this.displayHeight &&
      this.element &&
      this.viewport;

    if (!isImageDisplayable) {
      this.dispatchEvent(new DisplayEvent('display_updated', false));
      return;
    }

    const viewport = getMatchingResolutionViewport(this.viewport, this.displayedImage);
    const scaleFactor = computeScaleFactorFromStoredViewport(
      this.viewport,
      this.displayedImage
    ).scaleFactor;
    const annotations =
      scaleFactor !== 1
        ? rescaleAnnotationsPoints(this.annotations, scaleFactor)
        : this.annotations;

    const cropRect = convertCropAnnotationsToCropRect(annotations?.Crop);
    let crop;
    if (cropRect) {
      const [x, y, width, height] = cropRect;
      crop = { x, y, width, height };
    }
    const transformations = {
      ..._.pick(viewport, ['invert', 'rotation', 'hflip', 'vflip']),
      voi: {
        windowCenter: viewport?.voi?.windowCenter,
        windowWidth: viewport?.voi?.windowWidth,
      },
      crop,
      resize: { width: this.displayWidth, height: this.displayHeight },
    };

    const isImageAlreadyDisplayed =
      this.lastDisplayedImage === this.displayedImage &&
      _.isEqual(this.lastTransformation, transformations);

    if (isImageAlreadyDisplayed) return;

    this.lastDisplayedImage = this.displayedImage;
    this.lastTransformation = transformations;
    const dataForCanvas = prepareImageForCanvasDisplay(this.displayedImage, transformations);
    if (!dataForCanvas) return;

    this.canvas.width = dataForCanvas.size.width;
    this.canvas.height = dataForCanvas.size.height;

    this.canvas
      .getContext('2d')
      .putImageData(
        new ImageData(dataForCanvas.data, dataForCanvas.size.width, dataForCanvas.size.height),
        0,
        0
      );

    this.dispatchEvent(new DisplayEvent('display_updated', true));
  };

  private updateDimensions = ({ width, height }: Size) => {
    this.displayWidth = Math.floor(width);
    this.displayHeight = Math.floor(height);

    this.triggerCanvasDisplay();
  };

  private handleResize: ResizeObserverCallback = ([resizeData]) => {
    const { width, height } = this.element.getBoundingClientRect();

    this.updateDimensions({ width, height });
  };
}

export const makeThumbnailImageRendererFactory = (): BasicImageRendererFactory => ({
  create: (element: HTMLElement) => new ThumbnailImageRenderer(element),
});
