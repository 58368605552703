import useGenericSHA256Protection from 'app/utils/hooks/passwordProtection/useGenericSHA256Protection';

const PASSWORD_HASH = '2b30aa7868bea47e5c31e7cc447f1679febc0ceb03b9dfda60e00dab684b334e';

export default function useAiOnlyPasswordProtection() {
  return useGenericSHA256Protection({
    headerId: 'password_required.picoxia_ai_only',
    placeholderId: 'password_required.picoxia_ai_only.placeholder',
    passwordHash: PASSWORD_HASH,
  });
}
