import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List, Table } from 'semantic-ui-react';

const PREFIX = 'patterns.dysplasia.stades.helpMessage';
const STADES = ['A', 'B', 'C', 'D', 'E'];

const renderStadeRow = (stade) => (
  <Table.Row key={stade}>
    <Table.Cell>
      <FormattedMessage id={`${PREFIX}.${stade}.stade`} />
    </Table.Cell>
    <Table.Cell>
      <List size="tiny" bulleted>
        <List.Item>
          <FormattedMessage id={`${PREFIX}.${stade}.hip`} />
        </List.Item>
        <List.Item>
          <FormattedMessage id={`${PREFIX}.${stade}.NO`} />
        </List.Item>
      </List>
    </Table.Cell>
  </Table.Row>
);

class StadeHelpMessage extends React.PureComponent {
  render = () => (
    <div className="stade-help-message" style={{ marginBottom: '10px' }}>
      <Table celled compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>
              <FormattedMessage id={`${PREFIX}.signification`} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{STADES.map(renderStadeRow)}</Table.Body>
      </Table>
    </div>
  );
}

export default StadeHelpMessage;
