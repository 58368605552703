import ApiCalls from 'app/utils/apiCalls';

export default class CloudUserStore {
  getPermissions = async () => (await ApiCalls.getPermissions()).data.permissions;

  /** @note Only return 10 users paginated using `pageNumber` */
  getUsers = async ({
    pageNumber = 1,
    partialEmail = '',
    partialAddress = '',
    labels = [],
    sortOrder = {},
    filterSubscription = '',
    filterAcquisition = '',
  } = {}) =>
    (
      await ApiCalls.getUsersFilteredAndCounted(
        pageNumber,
        partialEmail,
        partialAddress,
        labels,
        sortOrder,
        filterSubscription,
        filterAcquisition
      )
    ).data;
}
