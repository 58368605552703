/* eslint-disable class-methods-use-this */
import Image from 'image-js';

import { updateDicomDataImage } from 'app/utils/dicom/DicomData';
import makeDicomFromAcquisitionDetails from 'app/utils/dicom/makeDicomFromAcquisitionDetails';
import { ImageLoader } from 'app/interfaces/ImageLoader';
import { IntlShape } from 'react-intl';
import { AcquisitionDetails, StoredImage, StudyStore } from 'app/interfaces/StudyStore';
import { LocalDicomStore } from 'app/interfaces/LocalDicomStore';
import { DataImage } from 'app/interfaces/DataImage';
import { DicomData } from 'app/interfaces/Dicom';

export default class LocalStudyStore implements StudyStore {
  private imageLoader: ImageLoader;
  private localDicomStore: LocalDicomStore;
  private intl: IntlShape;

  constructor(imageLoader: ImageLoader) {
    this.imageLoader = imageLoader;
    this.localDicomStore = undefined;
    this.intl = undefined;
  }

  syncLocalDicomStore(localDicomStore: LocalDicomStore) {
    this.localDicomStore = localDicomStore;
  }

  syncIntl(intl: IntlShape) {
    this.intl = intl;
  }

  // NOOP until offline storage
  updatePatient = async () => Promise.reject(new Error('Not implemented'));

  deleteImage = async () => Promise.reject(new Error('Not implemented'));

  getStudy = async () => Promise.reject(new Error('Not implemented'));

  updateReport = async () => Promise.reject(new Error('Not implemented'));

  updateImage = async () => Promise.reject(new Error('Not implemented'));

  updateThumbnailImage = async () => Promise.reject(new Error('Not implemented'));

  loadImage = async (studyId: string, imageId: string): Promise<StoredImage> => {
    const dicomPath = await this.localDicomStore?.getDicomImagePath(studyId, imageId);

    if (!dicomPath) {
      throw new Error('No dicom found');
    }
    console.info('Found dicom', dicomPath);

    const [loadedImageResult, processedImageResult] = await Promise.allSettled([
      this.imageLoader.load({
        path: dicomPath,
        isFromFileSystem: true,
      }),
      this.localDicomStore?.getProcessedImage(studyId, imageId),
    ]);

    if (loadedImageResult.status !== 'fulfilled') {
      throw loadedImageResult.reason;
    }
    return {
      ...loadedImageResult.value,
      processedImage:
        processedImageResult.status === 'rejected' ? undefined : processedImageResult.value,
    };
  };

  loadThumbnail = async () => Promise.reject(new Error('Not implemented'));

  saveRawImage = async (
    studyId: string,
    imageId: string,
    dataImage: DataImage,
    acquisitionDetails: AcquisitionDetails,
    dicomData: DicomData
  ) => {
    try {
      let savedDicomData = makeDicomFromAcquisitionDetails({
        studyId,
        imageId,
        dicomData,
        intl: this.intl,
        ...acquisitionDetails,
      });
      savedDicomData = updateDicomDataImage(savedDicomData, dataImage);

      return this.localDicomStore?.storeDicomImage(studyId, imageId, savedDicomData);
    } catch (e) {
      console.error('Failed to store dicom image', e);
      throw e;
    }
  };

  saveLastProcessedImage = async (
    studyId: string,
    imageId: string,
    lastProcessedImage: DataImage
  ) => {
    console.time(`${studyId}-${imageId} processedImageWrite`);
    await this.localDicomStore?.storeProcessedImage(studyId, imageId, lastProcessedImage);
    console.timeEnd(`${studyId}-${imageId} processedImageWrite`);
  };
}
