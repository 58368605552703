import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';

const DropdownItem = ({ item, menuIsOpen, renderNavigationLink, setMenuIsOpen }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!menuIsOpen) {
      setTimeout(() => setDropdownIsOpen(false), 1000);
    }
  }, [menuIsOpen]);

  const toggleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  return (
    <li>
      <button type="button" className="navbar-burger__link" onClick={toggleDropdown}>
        <FormattedMessage id={item.menuName} />
        <Icon name="caret down" className="navbar-burger__dropdown-icon" />
      </button>
      {dropdownIsOpen && (
        <ul className="navbar-burger__dropdown-menu">
          {item.subMenu.map((subItem) =>
            renderNavigationLink(
              subItem.routes,
              intl.formatMessage({ id: subItem.messageId }),
              subItem.messageId,
              'navbar-burger__link navbar-burger__link--sub',
              () => setMenuIsOpen(false)
            )
          )}
        </ul>
      )}
    </li>
  );
};

export default DropdownItem;
