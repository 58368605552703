/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';

import { CONNECTION_STATE, DETECTOR_STATE } from '../../types/xray';
import { ReactComponent as FlatPanelIcon } from '../../../static/svg_icons/flat_panel_detector_square.svg';
import { ReactComponent as Disconnected } from '../../../static/svg_icons/disconnected.svg';
import { ReactComponent as Connected } from '../../../static/svg_icons/connected.svg';
import { FlatPanelStateContext } from '../FlatPanelStateProvider';
import withContext from '../../utils/withContext';

const BATTERY_THRESHOLDS = [
  { batteryClass: 'four', color: 'green', threshold: 75 },
  { batteryClass: 'three', color: 'green', threshold: 50 },
  { batteryClass: 'two', color: 'yellow', threshold: 25 },
  { batteryClass: 'one', color: 'red', threshold: 10 },
  { batteryClass: 'zero', color: 'red', threshold: 0 },
];

const CONNECTED_STATE = [CONNECTION_STATE.LowRate, CONNECTION_STATE.OK];

const getObjectOverThreshold = (thresholds, value) => {
  const thresholdObject = _.find(thresholds, ({ threshold }) => value > threshold);
  return thresholdObject || thresholds[thresholds.length - 1];
};

class FlatPanelShortStatus extends React.PureComponent {
  renderState = () => {
    const { state } = this.state;
    return DETECTOR_STATE.toString(state);
  };

  renderConnectionState = () => {
    const { connectionState } = this.state;
    return CONNECTION_STATE.toString(connectionState);
  };

  renderBattery = () => {
    const { detectorId, context } = this.props;
    const { detectorsStates } = context;
    const { batteryRemaining } = detectorsStates[detectorId];
    const batteryCharge = getObjectOverThreshold(BATTERY_THRESHOLDS, batteryRemaining);
    return <Icon name={`battery ${batteryCharge.batteryClass}`} color={batteryCharge.color} />;
  };

  selectDetector = () => {
    const { context, detectorId } = this.props;
    const { selectDetector } = context;
    selectDetector(detectorId);
  };

  render() {
    const { className, detectorId, context } = this.props;
    const { detectorsStates, selectedDetectorIndex } = context;
    const detectorState = detectorsStates[detectorId];
    const { serialNumber, connectionState, state } = detectorState;
    const connectionStateString = CONNECTION_STATE.toString(connectionState);
    const isConnected = CONNECTED_STATE.includes(connectionState);
    const isSelected = selectedDetectorIndex === detectorId;
    return (
      <div
        className={`${className} flat-panel-short-status ${isSelected && 'selected'}`}
        onClick={this.selectDetector}
      >
        <Popup
          content={
            <div>
              <FormattedMessage id="flat_panel.state" />
              {' : '}
              <FormattedMessage id={`flat_panel.state.${DETECTOR_STATE.toString(state)}`} />
            </div>
          }
          trigger={
            <FlatPanelIcon className={`flat-panel-icon ${DETECTOR_STATE.toString(state)}`} />
          }
        />
        <div className="info">
          <div>{serialNumber}</div>
          <div className="status">
            <Popup
              content={
                <div>
                  <FormattedMessage id="flat_panel.connection_state" />
                  {' : '}
                  <FormattedMessage
                    id={`flat_panel.connection_state.${CONNECTION_STATE.toString(connectionState)}`}
                  />
                </div>
              }
              trigger={
                isConnected ? (
                  <Connected className={`connection-state ${connectionStateString}`} />
                ) : (
                  <Disconnected className={`connection-state ${connectionStateString}`} />
                )
              }
            />
            {/* <Popup
              content={
                <div>
                  <FormattedMessage id="remaining_battery" />
                  {` : ${batteryRemaining}%`}
                </div>
              }
              trigger={this.renderBattery()}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
FlatPanelShortStatus.propTypes = {
  context: pt.shape().isRequired,
  detectorId: pt.string.isRequired,
  className: pt.string,
};

FlatPanelShortStatus.defaultProps = {
  className: '',
};

export default withContext(FlatPanelShortStatus, FlatPanelStateContext);
