import isDataArray from 'app/utils/isDataArray';
import _ from 'lodash';

export default function mergeWithArrayBuffer(obj: any, ...sources: any[]) {
  return _.mergeWith(obj, ...sources, (objValue: any, othValue: any) => {
    if (othValue && (isDataArray(othValue) || isDataArray(objValue))) {
      return othValue;
    }
    return undefined;
  });
}
