const localToBase64 = (image) => {
  const canvas = document.createElement('canvas');
  canvas.height = image.naturalHeight;
  canvas.width = image.naturalWidth;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL();
};

let loadedDemoImages;

const loadDemoImages = () => {
  if (loadedDemoImages) return loadedDemoImages;
  /* eslint-disable global-require */
  const demoImages = [
    require('app/images/demo/1.png'),
    require('app/images/demo/3.png'),
    require('app/images/demo/4.png'),
    require('app/images/demo/abdo_face.jpg'),
    require('app/images/demo/abdo_profil.jpg'),
  ];
  /* eslint-enable global-require */

  loadedDemoImages = demoImages.map(
    (imagePath) =>
      new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve(localToBase64(image));
        image.src = imagePath;
      })
  );

  return loadedDemoImages;
};

export default loadDemoImages;
