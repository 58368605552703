import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Hook = ({ as, size, children }) => {
  let hookClassName = 'hook';
  if (size !== '') {
    hookClassName = `hook hook--${size}`;
  }

  if (as === 'h3') {
    return <h3 className={hookClassName}>{children}</h3>;
  }
  return <p className={hookClassName}>{children}</p>;
};

Hook.defaultProps = {
  as: '',
  size: '',
};

Hook.propTypes = {
  as: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Hook;
