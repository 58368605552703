import { Point } from 'app/CornerstoneTools/types';
import { Size } from 'app/interfaces/Image';
import { cloneDeep, debounce, mapValues } from 'lodash';

export function restoreLastAnnotation(
  storageKey: string,
  storageKeyPrefix: string = 'tools.last_annotations.'
) {
  try {
    return JSON.parse(localStorage.getItem(`${storageKeyPrefix}${storageKey}`) || 'undefined');
  } catch {
    return undefined;
  }
}

// Annotations are saved in a normalized format
export function makeDebouncedLastAnnotationSave(
  storageKey: string,
  debounceTime: number = 500,
  storageKeyPrefix: string = 'tools.last_annotations.'
) {
  return debounce((annotation: any) => {
    if (annotation && !('handles' in annotation)) return;
    for (const handleName in annotation.handles) {
      const handle = annotation.handles[handleName];
      if (handle.x < 0 || handle.x > 1) return;
      if (handle.y < 0 || handle.y > 1) return;
    }
    localStorage.setItem(`${storageKeyPrefix}${storageKey}`, JSON.stringify(annotation));
  }, debounceTime);
}
