/* eslint-disable class-methods-use-this */
import ApiCalls from 'app/utils/apiCalls';

/**
 * @class
 * @implements {PatientStore}
 */
export default class CloudBasedPatientStore {
  /** @type {PatientStore.getPatient} */
  getPatient = async (patientId) => (await ApiCalls.getPatientInfo(patientId)).data.animal;
}
