/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY = 'global.subscriptions';

import { isDev, isStaging } from 'app/utils/envUtil';

export const DEFAULT_LOCALE = 'en';

export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_AUTH = 'SET_AUTH';
export const SENDING_REQUEST = 'SENDING_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const LOGOUT = 'LOGOUT';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_HAS_SUBSCRIBED = 'SET_HAS_SUBSCRIBED';
export const SET_SUBSCRIPTION_MAIL_SENT = 'SET_SUBSCRIPTION_MAIL_SENT';
export const SET_AI_PANEL_DISPLAY_MODE = 'SET_AI_PANEL_DISPLAY_MODE';

let frontEndPath = 'https://picoxia.com';
if (isDev()) {
  frontEndPath = 'http://localhost:3000';
} else if (isStaging()) {
  frontEndPath = 'https://staging.picoxia.com';
}
export const FRONT_END_PATH = frontEndPath;
