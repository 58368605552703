// @ts-ignores
import { AngleTool, LengthTool, EraserTool, WwwcTool, ArrowAnnotateTool } from 'cornerstone-tools';

import DistractionIndexTool from 'app/CornerstoneTools/DistractionIndexTool';
import { PanTool, PanMultiTouchTool, ZoomTouchPinchTool } from 'app/CornerstoneTools';
import PicoxiaPanTool from 'app/CornerstoneTools/PanTool';
import injectSwitchableTool from 'app/CornerstoneTools/injectSwitchableTool';
import ZoomMouseWheelTool from 'app/CornerstoneTools/ZoomWheelTool';
import NorbergOlssonTool from 'app/CornerstoneTools/NorbergOlssonTool';
import VHSTool from 'app/CornerstoneTools/VHSTool';
import TextMarkerTool from 'app/CornerstoneTools/TextMarkerTool';
import ImageMarkerTool from 'app/CornerstoneTools/ImageMarkerTool';

import CropTool from 'app/CornerstoneTools/CropTool';
import MagnifyTool from 'app/CornerstoneTools/MagnifyTool';
import CircleRadiusTool from 'app/CornerstoneTools/CircleRadiusTool';
import DicomDataPrinterTool from 'app/CornerstoneTools/DicomDataPrinterTool';
import TPLOTool from 'app/CornerstoneTools/TPLOTool';
import SilentScaleOverlayTool from 'app/CornerstoneTools/SilentScaleOverlayTool';
import _ from 'lodash';
import { IntlShape } from 'react-intl';
import {
  createCommonToolsList as defaultCreateCommonToolsList,
  createImageToolsList as defaultCreateImageToolsList,
  createThumbnailToolsList as defaultCreateThumbnailToolsList,
} from 'app/adapters/ImageRenderer/DefaultToolsConfigurations';
import RealSizeMeasurementCalibrationTool from 'app/CornerstoneTools/RealSizeMeasurementCalibrationTool';

export const createCommonToolsList = (getIntl: () => IntlShape) =>
  _.merge(defaultCreateCommonToolsList(getIntl), {
    ZoomMouseWheel: { tool: ZoomMouseWheelTool },
    FineGrainZoomMouseWheel: { tool: ZoomMouseWheelTool },
    ZoomTouchPinch: { tool: ZoomTouchPinchTool },
    Pan: { tool: PicoxiaPanTool },
    PanMultiTouch: { tool: PanMultiTouchTool },
    PanWheel: { tool: PanTool },
    Eraser: { tool: EraserTool },
    WWWCRightClick: { tool: WwwcTool },
    WWWC: { tool: WwwcTool },
  });

export const createImageToolsList = (getIntl: () => IntlShape) =>
  _.merge({}, defaultCreateImageToolsList(getIntl), {
    ArrowAnnotate: { tool: ArrowAnnotateTool },
    TextMarker: { tool: TextMarkerTool },
    Length: { tool: LengthTool },
    CircleRadius: { tool: CircleRadiusTool },
    Angle: { tool: AngleTool },
    Magnify: { tool: MagnifyTool },
    VHS: { tool: injectSwitchableTool(VHSTool) },
    Crop: { tool: CropTool },
    ScaleOverlay: { tool: SilentScaleOverlayTool },
    TPLO: { tool: TPLOTool },
    DistractionIndex: { tool: DistractionIndexTool },
    NorbergOlsson: { tool: injectSwitchableTool(NorbergOlssonTool) },
    LeftMarker: { tool: ImageMarkerTool },
    RightMarker: { tool: ImageMarkerTool },
    RealSizeMeasurementCalibration: { tool: RealSizeMeasurementCalibrationTool },
    DicomDataPrinter: { tool: DicomDataPrinterTool },
  });

export const createThumbnailToolsList = () =>
  _.merge({}, defaultCreateThumbnailToolsList(), {
    Crop: { tool: CropTool },
  });
