import React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';

type TeleradiologyIconProps = {
  size?: IconProps['size'];
  color?: IconProps['color'];
  inverted?: IconProps['inverted'];
};

export default function TeleradiologyIcon({ size, color, inverted }: TeleradiologyIconProps) {
  return (
    <Icon.Group size={size}>
      <Icon name="doctor" color={color} inverted={inverted} />
      <Icon corner="top right" name="feed" color={color} inverted={inverted} />
    </Icon.Group>
  );
}
