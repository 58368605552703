import styled from 'styled-components';

export const ButtonGroup = styled.div`
  pointer-events: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: transparent;
`;
