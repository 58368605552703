import { DisplayViewport, DisplayableImageData, ImageOverlayData } from 'app/interfaces/Image';

export type EncodableImage = {
  image: DisplayableImageData;
  viewport?: DisplayViewport;
} & ImageOverlayData;

export type EncodeOptions = {
  maxWidth?: number;
  maxHeight?: number;
  viewport?: DisplayViewport;
} & ImageOverlayData;

export type JPEGEncodeOptions = EncodeOptions & { quality?: number };

export const DEFAULT_JPEG_ENCODE_OPTIONS: JPEGEncodeOptions = {
  quality: 0.92,
  maxWidth: 1920,
  maxHeight: 1920,
};

export type IDisplayableImageEncoder = {
  toBase64: (image: DisplayableImageData, options?: JPEGEncodeOptions) => Promise<string>;
  toBlob: (image: DisplayableImageData, options?: JPEGEncodeOptions) => Promise<Blob>;
  toJPEG: (image: DisplayableImageData, options?: JPEGEncodeOptions) => Promise<Blob>;
  toCanvas: (image: DisplayableImageData, options?: EncodeOptions) => Promise<HTMLCanvasElement>;
};
