import childProcess from 'app/native/node/child_process';
import electron from 'app/native/node/electron';
import ApiCalls from 'app/utils/apiCalls';

export default class ElectronUpdater {
  /** @type {Download} */
  download = ({ url, onProgress, options }) => {
    const token = localStorage?.loggedInToken;

    if (onProgress) {
      electron()?.ipcRenderer.on('download progress', onProgress);
    }
    return electron()?.ipcRenderer.invoke('download', {
      url: `${ApiCalls.getBackendRoute()}${url}`,
      token,
      options,
    });
  };

  /** @type {LaunchUpdate} */
  launchUpdate = (filePath) => {
    if (!childProcess() || !electron()) return;

    const { spawn } = childProcess();
    const { remote } = electron();

    const subprocess = spawn(filePath, [], {
      detached: true,
      stdio: ['ignore', 'ignore', 'ignore'],
    });
    // Unref the installer to avoid it being terminated when client is closed.
    subprocess.unref();
    // We have to end application here else the installer will kill itself when it
    // tries to stop the app.
    remote.app.quit();
  };
}
