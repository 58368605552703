/* eslint-disable camelcase */
import queryString from 'query-string';
import React, { useContext, useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { useIntl } from 'react-intl';

import BSONObjectIdGenerator from 'app/adapters/BSONObjectIdGenerator';
import CloudBasedStudyStore from 'app/adapters/CloudBasedStudyStore';
import CornerstoneImageLoader from 'app/adapters/CornerstoneImageLoader';
import ReportGenerator from 'app/adapters/ReportGenerator';
import imageProcessing from 'app/native/node-addons/imageProcessing';
import LocalStudyStore from 'app/adapters/LocalStudyStore';
import CombinedStudyStore from 'app/adapters/CombinedStudyStore';
import { LocalDicomStoreContext } from 'app/providers/LocalDicomStoreProvider';
import electron from 'app/native/node/electron';
import ViewerWithAutoImportedStudies from 'app/components/Viewer/ViewerWithAutoImportedStudies';
import OnlineInferenceExecutor from 'app/adapters/OnlineInferenceExecutor';
import AppHelmet from 'app/components/AppHelmet';
import CloudBasedPatientStore from 'app/adapters/CloudBasedPatientStore';
import _ from 'lodash';
import { ImagePath } from 'app/interfaces/ImageLoader';
import { ViewerMode, isViewerMode } from 'app/components/Viewer/types';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';
import HideHeaderFnProvider from 'app/providers/HideHeaderFnProvider/context';
import IImageProcessorContext from 'app/providers/ImageProcessorProvider/context';
import { isElectron } from 'app/utils/envUtil';

type ParsedQuery = {
  [key: string]: string | string[];
};

const parseInitialStudyId = (queryStringParsed: ParsedQuery) => {
  if (!queryStringParsed.study_ids) return undefined;

  return queryStringParsed.study_ids[0];
};

const parseImagesInQuery = (queryStringParsed: ParsedQuery) => {
  if (!queryStringParsed.images) return undefined;

  return (queryStringParsed.images as string[]).map((path: string) => ({ path }));
};

const parseQueryMode = ({ mode }: ParsedQuery = {}): ViewerMode => {
  if (!mode) return undefined;
  return isViewerMode(mode) ? mode : undefined;
};

const parseInitialPatientInfoInQuery = ({
  animalName,
  ownerName,
  patient_id,
}: ParsedQuery = {}) => {
  if (animalName || ownerName || patient_id)
    return {
      name: animalName as string,
      owner_name: ownerName as string,
      _id: patient_id as string,
    };
  return undefined;
};

type newCommandLineArgumentsPayload = {
  images: string[];
  ownerName: string;
  animalName: string;
};

function ProductionViewer() {
  const intl = useIntl();
  const localDicomStore = useContext(LocalDicomStoreContext);

  const queryStringParsed = queryString.parse(window.location.search, { arrayFormat: 'bracket' });

  const imageEncoder = useContext(IDisplayableImageEncoderContext);
  const hideHeader = useContext(HideHeaderFnProvider);
  const imageProcessor = useContext(IImageProcessorContext);
  const [initialStudyId] = useState(() => parseInitialStudyId(queryStringParsed));
  const [imagePaths, setImagePaths] = useState(() => parseImagesInQuery(queryStringParsed));
  const [initialPatientInfo] = useState(() => parseInitialPatientInfoInQuery(queryStringParsed));
  const [mode] = useState(() => parseQueryMode(queryStringParsed));
  const [objectIdGenerator] = useState(() => new BSONObjectIdGenerator());
  const [imageLoader] = useState(() => new CornerstoneImageLoader());
  const [cloudBaseStudyStore] = useState(() => new CloudBasedStudyStore(imageLoader, imageEncoder));
  const [cloudBasedPatientStore] = useState(() => new CloudBasedPatientStore());
  const [localStudyStore] = useState(() =>
    isElectron() ? new LocalStudyStore(imageLoader) : undefined
  );
  const [studyStore] = useState(() =>
    localStudyStore
      ? new CombinedStudyStore(localStudyStore, cloudBaseStudyStore)
      : cloudBaseStudyStore
  );
  const [inferenceExecutor] = useState(() => new OnlineInferenceExecutor());
  const [reportGenerator] = useState(() => new ReportGenerator());

  const acknowledgeImagePaths = (acknowledgedImagePaths: ImagePath[]) => {
    setImagePaths(_.difference(imagePaths, acknowledgedImagePaths));
  };

  useEffect(() => {
    localStudyStore?.syncIntl(intl);
  }, [intl]);

  useEffect(() => {
    localStudyStore?.syncLocalDicomStore(localDicomStore);
  }, [localDicomStore]);

  const setupCommandLineListener = () => {
    if (!electron()) return undefined;

    const { ipcRenderer } = electron();

    const refreshPageOnCommandLineChange = (
      _event: any,
      { images, ownerName, animalName }: newCommandLineArgumentsPayload
    ) => {
      if (!images && !ownerName && !animalName) return;
      setImagePaths(images.map((path) => ({ path })));
    };

    ipcRenderer.on('newCommandLineArguments', refreshPageOnCommandLineChange);

    return () => {
      ipcRenderer.removeListener('newCommandLineArguments', refreshPageOnCommandLineChange);
    };
  };

  useEffect(setupCommandLineListener, []);

  return (
    <>
      <AppHelmet titleIntlID="demo.pageTitle" descriptionIntlID="demo.pageDescription" />
      <ViewerWithAutoImportedStudies
        imageLoader={imageLoader}
        objectIdGenerator={objectIdGenerator}
        studyStore={studyStore}
        patientStore={cloudBasedPatientStore}
        inferenceExecutor={inferenceExecutor}
        initialStudyId={initialStudyId}
        imageProcessor={imageProcessor}
        reportGenerator={reportGenerator}
        hideHeader={hideHeader}
        history={browserHistory}
        imagePaths={imagePaths}
        initialPatientInfo={initialPatientInfo}
        onImagePathsAcknowledged={acknowledgeImagePaths}
        mode={mode}
      />
    </>
  );
}

export default ProductionViewer;
