import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import StorageUsageMonitorContext from 'app/providers/StorageUsageMonitorProvider/context';
import StorageUsageMonitor from 'app/adapters/StorageUsageMonitor';
import { selectDeviceIdentifier } from 'app/redux/deviceParameters/reducer';
import os from 'app/native/node/os';
import getElectron from 'app/native/node/electron';

// eslint-disable-next-line react/prop-types
function StorageUsageMonitorProviderImpl({ children }) {
  const deviceName = os()?.hostname();
  const deviceIdentifier = useSelector(selectDeviceIdentifier);
  const [storageUsageMonitor] = useState(
    () => new StorageUsageMonitor(deviceName, deviceIdentifier)
  );

  useEffect(() => {
    storageUsageMonitor.deviceName = deviceName;
  }, [deviceName]);
  useEffect(() => {
    storageUsageMonitor.deviceIdentifier = deviceIdentifier;
  }, [deviceIdentifier]);

  return (
    <StorageUsageMonitorContext.Provider value={storageUsageMonitor}>
      {children}
    </StorageUsageMonitorContext.Provider>
  );
}

// eslint-disable-next-line react/prop-types
export default function StorageUsageMonitorProvider({ children }) {
  if (!getElectron()) return children;
  return <StorageUsageMonitorProviderImpl>{children}</StorageUsageMonitorProviderImpl>;
}
