/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import { useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import { FlatPanelStateContext } from 'app/components/FlatPanelStateProvider';
import withContext from 'app/utils/withContext';

const SentryFlatPanelInfo = ({ flatPanelStateContext }) => {
  // TODO This should be a function next to `getCurrentDetector`
  const detectorState =
    flatPanelStateContext?.detectorsStates?.[flatPanelStateContext?.selectedDetectorIndex];
  const manufacturer = detectorState?.allAttributes?.manufacturer;

  useEffect(() => {
    Sentry.setTag('flat_panel_manufacturer', manufacturer);
  }, [manufacturer]);

  return null;
};

export default withContext(SentryFlatPanelInfo, FlatPanelStateContext, 'flatPanelStateContext');
