/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { createIntlCache, createIntl, RawIntlProvider, IntlProvider } from 'react-intl';
import { FRONT_END_PATH } from 'app/redux/global/constants';
import { DEFAULT_LOCALE } from 'app/containers/LanguageProvider/constants';
import { makeSelectLocale } from './selectors';
// prevents memory leak
const cache = createIntlCache();

const displayPicoxiaRef = (intl, chunks) => {
  const trimmedChunk = chunks[0].trim();
  const labelKey = `${trimmedChunk}.label`;
  const refKey = `${trimmedChunk}.picoxiaref`;

  if (intl.messages[refKey] === undefined) {
    return intl.formatMessage({ id: labelKey });
  }
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`${FRONT_END_PATH}/${intl.formatMessage({ id: refKey })}`}
    >
      {intl.formatMessage({ id: labelKey })}
    </a>
  );
};
export class LanguageProvider extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  render() {
    const { locale, messages, children } = this.props;
    const localMessages =
      locale === DEFAULT_LOCALE
        ? messages[DEFAULT_LOCALE]
        : {
            ..._.omitBy(messages[DEFAULT_LOCALE], (_value, key) => key.endsWith('.picoxiaref')),
            ...messages[locale],
          };

    const intl = createIntl(
      {
        locale,
        messages: localMessages,
        defaultRichTextElements: {
          b: (chunks) => <b>{chunks}</b>,
          li: (chunks) => <li>{chunks}</li>,
          ul: (chunks) => <ul>{chunks}</ul>,
          br: () => <br />,
          picoxiaref: (chunks) => displayPicoxiaRef(intl, chunks),
        },
      },
      cache
    );

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

//        {React.Children.only(React.cloneElement(this.props.children, {onChangeLanguage: this.changeLanguage}))}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({ locale }));

// function mapStateToProps(state) {
//  const { lang, messages } = state.locales;
//  return { locale: lang, messages };
// }

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
