/* eslint-disable camelcase */
import { format } from 'date-fns';
import _ from 'lodash';

export default function formatUsersToCSV(users, intl) {
  const flattenedUsersWithSubscriptions = _.flatten(
    users.map(
      ({
        mail,
        address,
        clinicInfo,
        subscription,
        registration_date,
        managed_by,
        language,
        mail_confirmed,
        permissions,
      }) => {
        if (!subscription || subscription.length === 0) {
          return {
            mail,
            mail_confirmed,
            clinicInfo,
            registration_date: new Date(registration_date),
            phoneNumber: address?.phoneNumber,
            postCode: address?.postCode,
            country: address?.country,
            acquisition: !!permissions?.includes('acquisition'),
            teleradiology: !!permissions?.includes('teleradiology'),
            name: undefined,
            subStart: undefined,
            subEnd: undefined,
            used: undefined,
            price: undefined,
            manager: managed_by,
            language,
          };
        }

        return subscription.map(({ backend_name, buyDate, currentPeriodEnd, used, price }) => ({
          mail,
          mail_confirmed,
          clinicInfo,
          registration_date: new Date(registration_date),
          phoneNumber: address?.phoneNumber,
          postCode: address?.postCode,
          country: address?.country,
          acquisition: !!permissions?.includes('acquisition'),
          teleradiology: !!permissions?.includes('teleradiology'),
          name: backend_name,
          subStart: new Date(buyDate),
          subEnd: new Date(currentPeriodEnd),
          used,
          price,
          manager: managed_by,
          language,
        }));
      }
    )
  );

  const header = [
    intl.formatMessage({ id: 'usersToCSV.header.mail' }),
    intl.formatMessage({ id: 'usersToCSV.header.country' }),
    intl.formatMessage({ id: 'usersToCSV.header.post-code' }),
    intl.formatMessage({ id: 'usersToCSV.header.language' }),
    intl.formatMessage({ id: 'usersToCSV.header.clinicInfo' }),
    intl.formatMessage({ id: 'usersToCSV.header.phoneNumber' }),
    intl.formatMessage({ id: 'usersToCSV.header.mail_confirmed' }),
    intl.formatMessage({ id: 'usersToCSV.header.registration_date' }),
    intl.formatMessage({ id: 'usersToCSV.header.manager' }),
    intl.formatMessage({ id: 'usersToCSV.header.acquisition' }),
    intl.formatMessage({ id: 'usersToCSV.header.teleradiology' }),
    intl.formatMessage({ id: 'usersToCSV.header.name' }),
    intl.formatMessage({ id: 'usersToCSV.header.subStart' }),
    intl.formatMessage({ id: 'usersToCSV.header.subEnd' }),
    intl.formatMessage({ id: 'usersToCSV.header.used' }),
    intl.formatMessage({ id: 'usersToCSV.header.price' }),
  ].join(';');

  const csvLines = flattenedUsersWithSubscriptions.map(
    ({
      mail,
      mail_confirmed,
      clinicInfo,
      registration_date,
      phoneNumber,
      postCode,
      country,
      acquisition,
      teleradiology,
      name,
      subStart,
      subEnd,
      used,
      price,
      manager,
      language,
    }) =>
      [
        mail,
        country,
        `"${postCode}"`,
        language,
        `"${clinicInfo ?? ''}"`,
        `"${phoneNumber ?? ''}"`,
        !!mail_confirmed,
        format(registration_date, 'yyyy-MM-dd'),
        manager,
        acquisition,
        teleradiology,
        name,
        subStart ? format(subStart, 'yyyy-MM-dd') : undefined,
        subEnd ? format(subEnd, 'yyyy-MM-dd') : undefined,
        used,
        price,
      ].join(';')
  );

  return `${header}\n${csvLines.join('\n')}`;
}
