import 'app/styles/style.scss';
import 'semantic-ui-css/semantic.min.css';

import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormattedServerError from '../FormattedServerError';

const InferenceErrorMessage = ({ errorMessage }) => {
  if (errorMessage === null) {
    return null;
  }
  if (errorMessage.message === 'Network Error' || !('response' in errorMessage)) {
    return (
      <div className="errorMessage">
        <b>
          <FormattedMessage id="inferenceErrorMessage.noInternet" />
        </b>
      </div>
    );
  }
  if (errorMessage.response.data.cause === 'unsubscribed') {
    return null;
  }
  if (errorMessage.response.data.cause === 'unbillable') {
    return (
      <div className="errorMessage">
        <b>
          <div style={{ marginTop: '2vh', textAlign: 'center' }}>
            <h3>
              Vous avez utilisé vos{' '}
              {errorMessage.response.data.NUMBER_OF_FREE_REQUESTS_FOR_REGISTERED_USER} essais
              gratuits
            </h3>
          </div>
          <div style={{ marginTop: '4vh' }}>
            <FormattedMessage id="inferenceErrorMessage.activateBilling" />
          </div>
        </b>
      </div>
    );
  }
  return (
    <div className="errorMessage">
      <b>
        <FormattedServerError error={errorMessage} />
      </b>
    </div>
  );
};

InferenceErrorMessage.propTypes = {
  errorMessage: PropTypes.shape().isRequired,
};

export default InferenceErrorMessage;
