import React, { useContext, useEffect, useState } from 'react';

import JPEGLSModuleContext from 'app/providers/JPEGLSModuleProvider/context';
import CodecModuleContext from 'app/providers/CodecModuleProvider/context';
import { CodecModule, ImageInfo } from 'app/interfaces/CodecModule';

const jpegLSModuleFromCodecModule = (codecModule: CodecModule): JPEGLSModule => {
  if (!codecModule) return undefined;

  return {
    encode: async (data: Uint16Array, frameInfo: FrameInfo, nearlossless: number) => {
      const jpegLSData = await codecModule.encode(
        data,
        {
          columns: frameInfo.width,
          rows: frameInfo.height,
          bitsAllocated: frameInfo.bitsPerSample,
          samplesPerPixel: frameInfo.componentCount,
          signed: false,
        },
        '1.2.840.10008.1.2.4.80',
        {
          nearlossless,
        }
      );

      return jpegLSData;
    },
    decode: async (data: Uint8Array) => {
      const { buffer, frameInfo } = await codecModule.decode(data, '1.2.840.10008.1.2.4.80', {});

      return {
        buffer: buffer as Uint8Array | Uint16Array,
        frameInfo: {
          width: frameInfo.columns,
          height: frameInfo.rows,
          bitsPerSample: frameInfo.bitsAllocated,
          componentCount: frameInfo.samplesPerPixel,
        },
      };
    },
  };
};

// eslint-disable-next-line react/prop-types
export default function JPEGLSModuleProvider({ children }: JSX.ElementChildrenAttribute) {
  const codecModule = useContext(CodecModuleContext);
  const [jpegLSModule, setJpegLSModule] = useState<JPEGLSModule>(() =>
    jpegLSModuleFromCodecModule(codecModule)
  );

  useEffect(() => {
    setJpegLSModule(jpegLSModuleFromCodecModule(codecModule));
  }, [codecModule]);

  return (
    <JPEGLSModuleContext.Provider value={jpegLSModule}>{children}</JPEGLSModuleContext.Provider>
  );
}
