import { map } from 'lodash';
import { DisplayableImageData, Point, Size, VHSAnnotation } from 'app/interfaces/Image';
import computeVHSAxisLength from 'app/utils/predictions/computeVHSAxisLength';
import { ThoraxPredictions } from 'app/interfaces/Predictions';

const mapFloatCoordsToPixels = ({ x, y }: Point, { width, height }: Size) => ({
  x: width * x,
  y: height * y,
});

export function convertPredictionsToVHSAnnotation(
  vhsPrediction: ThoraxPredictions['vhs'],
  image: DisplayableImageData
) {
  const { heart, vertebrae } = vhsPrediction;
  if (!heart || !vertebrae) return undefined;

  const [t4, t5, t6, t7, t8, ...rest] = vertebrae;

  const annotation: VHSAnnotation = {
    handles: {
      heartBottom: mapFloatCoordsToPixels(heart.bottom, image),
      heartLeft: mapFloatCoordsToPixels(heart.left, image),
      heartRight: mapFloatCoordsToPixels(heart.right, image),
      heartTop: mapFloatCoordsToPixels(heart.top, image),
      t4: mapFloatCoordsToPixels(t4, image),
      t8: mapFloatCoordsToPixels(t8, image),
    },
    additionalVertebraPoints: map([t5, t6, t7, ...rest], (v) => mapFloatCoordsToPixels(v, image)),
    completed: true,
  };
  const { longAxisVertebralLength, shortAxisVertebralLength } = computeVHSAxisLength(
    image,
    annotation
  );
  annotation.longAxisVertebralLength = longAxisVertebralLength;
  annotation.shortAxisVertebralLength = shortAxisVertebralLength;

  return annotation;
}
