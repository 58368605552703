import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Modal, Input, Icon, Confirm } from 'semantic-ui-react';
import './style.scss';
import * as _ from 'lodash';
import ExamSelector from '../ExamSelector';
import Exams from '../Exams';
import ProtocolExams from '../ProtocolExams';
import ApiCalls from '../../utils/apiCalls';
import { DEFAULT_SPECIE } from 'app/constants/species';

class ConfigureProtocolsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exams: [],
      name: '',
      open: false,
      protocols: [],
      protocolToDelete: {},
      protocolLoaded: {},
    };
  }

  componentDidMount = () => {
    this.getProtocols();
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getProtocols = () => {
    ApiCalls.getProtocols().then((response) => {
      this.setState({ protocols: response.data.protocols });
    });
  };

  displayProtocol = (protocol) => {
    this.setState({
      name: protocol.name,
      exams: _.map(protocol.exams, (exam) => ({ anatomicRegion: exam })),
      protocolLoaded: protocol,
    });
  };

  renderProtocols = () => {
    const { protocols, protocolLoaded } = this.state;
    return protocols.map((protocol, index) => (
      <div
        className={protocolLoaded._id === protocol._id ? 'protocol selected' : 'protocol'}
        key={index}
        onClick={() => this.displayProtocol(protocol)}
      >
        <div className="title">
          <div> {protocol.name} :</div>
          <div>
            <Icon
              link
              className="trash alternate icon"
              onClick={() => {
                this.setState({ open: true, protocolToDelete: protocol });
              }}
            />
          </div>
        </div>
        <ProtocolExams protocol={protocol} />
      </div>
    ));
  };

  handleSelectExam = ({ mainRegion, subRegion, view }) => {
    const { exams } = this.state;
    const anatomicRegion = _.filter(['animal', mainRegion, subRegion, view], _.identity).join(' ');

    this.setState({ exams: [...exams, { anatomicRegion }] });
  };

  handleRemoveExam = (exam) =>
    this.setState(({ exams }) => ({ exams: exams.filter((v) => v !== exam) }));

  handleProtocolAdd = () => {
    const { exams, name } = this.state;
    ApiCalls.addProtocol(_.map(exams, 'anatomicRegion'), name).then(() => {
      this.getProtocols();
    });
  };

  handleProtocolEdit = () => {
    const { exams, name, protocolLoaded } = this.state;
    ApiCalls.editProtocol(protocolLoaded._id, name, _.map(exams, 'anatomicRegion')).then(() => {
      this.getProtocols();
    });
  };

  renderRemoveAlert = () => {
    const { open } = this.state;
    return (
      <div>
        <Confirm
          open={open}
          header={this.formatMessage({ id: 'account.configuration.protocols.delete.header' })}
          content={this.formatMessage({ id: 'account.configuration.protocols.delete.content' })}
          onConfirm={() => {
            const { protocolToDelete } = this.state;
            ApiCalls.removeProtocol(protocolToDelete._id)
              .then(() => {
                this.setState({
                  exams: [],
                  name: '',
                  open: false,
                });
              })
              .then(() => {
                this.getProtocols();
              });
          }}
          onCancel={() => {
            this.setState({ open: false });
          }}
        />
      </div>
    );
  };

  render() {
    const { exams, protocolLoaded, isOpen, name } = this.state;
    return (
      <Modal
        style={{ backgroundColor: '#040404', width: '70vw', height: '70vh' }}
        onClose={this.close}
        onOpen={this.open}
        open={isOpen}
        size="mini"
        trigger={
          <Button>
            <FormattedMessage id="general.configure" />
          </Button>
        }
      >
        <div className="protocols-configuration">
          <div className="protocols">
            <h4>
              <FormattedMessage id="account.protocols" />
            </h4>
            <div className="protocols-list">{this.renderProtocols()}</div>
            <div className="protocols-add-button">
              <button
                type="button"
                onClick={() => {
                  this.setState({ exams: [], name: '', protocolLoaded: {} });
                }}
              >
                <Icon className="plus icon" />
                <FormattedMessage id="account.protocols.create" />
              </button>
            </div>
          </div>

          <div className="add-protocol">
            <div className="protocol-name">
              <h4>
                {_.isEmpty(protocolLoaded) ? (
                  <FormattedMessage id="account.protocols.create.label" />
                ) : (
                  <FormattedMessage id="account.protocols.edit.label" />
                )}
              </h4>
              <Input
                onChange={(nameInput) => this.setState({ name: nameInput.target.value })}
                value={name}
                placeholder={this.formatMessage({ id: 'account.protocols.new' })}
              />
            </div>
            <div className="exams-selection">
              <ExamSelector key={name} specie="dog" onComplete={this.handleSelectExam} />
            </div>
            <div className="exams-actions">
              <div className="exam-list">
                <Exams
                  exams={exams}
                  onRemoveExam={this.handleRemoveExam}
                  animal={{ specie: DEFAULT_SPECIE }}
                />
              </div>
              <div className="actions">
                <button
                  className="green ui button"
                  type="button"
                  onClick={
                    _.isEmpty(protocolLoaded) ? this.handleProtocolAdd : this.handleProtocolEdit
                  }
                  disabled={name === ''}
                >
                  <Icon className="plus icon" />
                  {_.isEmpty(protocolLoaded) ? (
                    <FormattedMessage id="account.protocols.add" />
                  ) : (
                    <FormattedMessage id="account.protocols.edit" />
                  )}
                </button>
                <button
                  className="grey ui button"
                  type="button"
                  onClick={() => {
                    this.setState({ exams: [], name: '' });
                  }}
                >
                  <Icon className="close icon" />
                  {_.isEmpty(protocolLoaded) ? (
                    <FormattedMessage id="account.protocols.delete" />
                  ) : (
                    <FormattedMessage id="account.protocols.cancel" />
                  )}
                </button>
                {this.renderRemoveAlert()}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(ConfigureProtocolsButton);
