import './style.scss';

import React, { useCallback, useMemo, useState } from 'react';
import * as pt from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Form, Icon, Input, Label, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';

import PMSWatchedDirectorySelector from 'app/components/PMSWatchedDirectorySelector';
import {
  setPMSExportDirectory,
  setPMSSpecificConfiguration,
  setPMSType,
  setWatchedDirectory as setWatchedDirectoryAction,
} from 'app/pms/actions';
import { selectPMSReducer } from 'app/pms/selector';
import DirectorySelector from 'app/components/DirectorySelector';
import FSFileSystemUtility from 'app/adapters/FSFileSystemUtility';
import FilesystemUtility from 'app/interfaces/FileSystemUtility';
import { SPECIES } from 'app/constants/species';
import electron from 'app/native/node/electron';
import { DEFAULT_SPECIES_ALIASES_BY_PMS } from 'app/components/PMSConfiguration/constants';
import {
  VETO_PARTNER_CONFIGURATION_KEY,
  VETO_PARTNER_ORIGIN,
} from 'app/pms/parser/AggregatedParser';

const CONFIGURATION_STATE_PENDING = 'pending';
const CONFIGURATION_STATE_SUCCESS = 'success';
const CONFIGURATION_STATE_ERROR = 'error';

const PMS_OPTIONS = [
  { key: 'assisto_vet', value: 'assisto_vet', text: 'AssistoVet' },
  { key: 'gm_vet', value: 'gm_vet', text: 'GMVet' },
  {
    key: VETO_PARTNER_CONFIGURATION_KEY,
    value: VETO_PARTNER_CONFIGURATION_KEY,
    text: VETO_PARTNER_ORIGIN,
  },
  // { key: 'veto_partner', value: 'veto_partner', text: 'VetoPartner' },
];
const PMS_WITH_SPECIES_ALIASES = ['assisto_vet', 'gm_vet', VETO_PARTNER_CONFIGURATION_KEY];

const formatSpeciesAliases = (speciesAliases) =>
  _.map(speciesAliases, (value, key) => `${key} ${value}`).join('\n');

const parseSpeciesAliasesField = (speciesAliasesValue) => {
  const invalidLines = [];
  let parsedLines = [];
  if (!speciesAliasesValue) return { parsedLines, invalidLines };

  const lines = speciesAliasesValue.split('\n');
  parsedLines = lines
    .map((line) => {
      if (!line) return undefined;
      const splitLine = line.split(/\s/);
      const specie = splitLine[splitLine.length - 1];
      if (!SPECIES.includes(specie)) {
        invalidLines.push(line);
        return undefined;
      }
      return [splitLine.slice(0, -1).join(' ').trim(), specie];
    })
    .filter(_.identity);
  return { parsedLines, invalidLines };
};

const getCleanedSpeciesAliasesField = (speciesAliasesValue) => {
  const { parsedLines, invalidLines } = parseSpeciesAliasesField(speciesAliasesValue);
  const speciesAliasesObject = Object.fromEntries(parsedLines);
  const cleanedSpeciesAliasesText = formatSpeciesAliases(speciesAliasesObject);
  return { speciesAliasesObject, cleanedSpeciesAliasesText, invalidLines };
};

const iconActiveClass = (isActive) => (isActive ? 'pms-configuration__confirm-icon--active' : '');

function ConfigurationStateButton({ configurationState, onClick }) {
  const isPending = configurationState === CONFIGURATION_STATE_PENDING;
  const isSuccess = configurationState === CONFIGURATION_STATE_SUCCESS;
  const isError = configurationState === CONFIGURATION_STATE_ERROR;
  const pendingIcon = (
    <Icon
      name="save"
      className={`pms-configuration__confirm-icon__icon ${iconActiveClass(isPending)}`}
    />
  );
  const successIcon = (
    <Icon
      name="check"
      className={`pms-configuration__confirm-icon__icon ${iconActiveClass(isSuccess)}`}
    />
  );
  const errorIcon = (
    <Icon
      name="close"
      className={`pms-configuration__confirm-icon__icon ${iconActiveClass(isError)}`}
    />
  );

  return (
    <Button
      primary={isPending}
      positive={isSuccess}
      negative={isError}
      type="submit"
      floated="right"
      onClick={onClick}
      className="pms-configuration__confirm-button"
    >
      <span className="pms-configuration__confirm-icon">
        {pendingIcon}
        {successIcon}
        {errorIcon}
      </span>
      <FormattedMessage id="general.confirm" />
    </Button>
  );
}

function PMSConfiguration({
  fileSystemUtility,
  defaultSpeciesAliasesByPMS = DEFAULT_SPECIES_ALIASES_BY_PMS,
} = {}) {
  if (!electron()) return null;

  const intl = useIntl();
  const dispatch = useDispatch();
  const pmsConfig = useSelector(selectPMSReducer);
  const pmsConfigJS = useMemo(() => pmsConfig?.toJS?.(), [pmsConfig]);
  const [configurationState, setConfigurationState] = useState(CONFIGURATION_STATE_PENDING);
  const [pmsType, setPmsType] = useState(() => pmsConfigJS?.type);
  const [invalidAliases, setInvalidAliases] = useState([]);
  const [speciesAliasesValue, setSpeciesAliasesValue] = useState(() =>
    pmsConfigJS?.[pmsType]?.specieCodesAliases
      ? formatSpeciesAliases(pmsConfigJS?.[pmsType]?.specieCodesAliases)
      : ''
  );
  const [watchedDirectory, setWatchedDirectory] = useState(() => pmsConfigJS?.watchedDirectory);
  const useWatchDirectory = useCallback(() => watchedDirectory, [watchedDirectory]);

  const [exportDirectory, setExportDirectory] = useState(
    () => pmsConfigJS?.exportConfiguration?.exportDirectory
  );
  const useExportDirectory = () => exportDirectory;

  const onPMSTypeChange = useCallback((_event, { value: newPmsType }) => {
    setPmsType(newPmsType);

    const defaultAliasesForPMS = defaultSpeciesAliasesByPMS[newPmsType];
    if (!defaultAliasesForPMS) return;
    setSpeciesAliasesValue(formatSpeciesAliases(defaultAliasesForPMS));
  });

  const exportPlaceholderKey = 'account.configuration.no_directory_selected';
  const invalidDirectoryKey = 'account.configuration.is_invalid_directory';
  const setExportDirectoryDescriptionKey = 'account.configuration.pms.export_directory.description';
  const clearExportDirectoryDescriptionKey =
    'account.configuration.pms.export_directory.clear_directory.description';

  const validateConfiguration = () => {
    dispatch(setPMSType(pmsType));
    dispatch(setWatchedDirectoryAction(watchedDirectory));
    dispatch(setPMSExportDirectory(exportDirectory));

    const { speciesAliasesObject, cleanedSpeciesAliasesText, invalidLines } =
      getCleanedSpeciesAliasesField(speciesAliasesValue) ?? {};
    dispatch(setPMSSpecificConfiguration(pmsType, 'specieCodesAliases', speciesAliasesObject));
    setSpeciesAliasesValue(cleanedSpeciesAliasesText);
    setInvalidAliases(invalidLines);
    setConfigurationState(
      invalidLines.length === 0 ? CONFIGURATION_STATE_SUCCESS : CONFIGURATION_STATE_ERROR
    );
  };

  return (
    <Form className="pms-configuration" error={invalidAliases.length > 0}>
      <Input
        label={intl.formatMessage({ id: 'account.configuration.pms.type.label' })}
        input={
          <Dropdown
            clearable
            placeholder={intl.formatMessage({ id: 'account.configuration.pms.type.placeholder' })}
            options={PMS_OPTIONS}
            value={pmsType}
            search
            selection
            data-testid="pms-type-selector"
            onChange={onPMSTypeChange}
            style={{
              borderRadius: '0 4px 4px 0',
            }}
          />
        }
      />
      {pmsType && (
        <div className="pms-configuration__sub-configuration">
          <div data-testid="pms-watched-directory-selector">
            <PMSWatchedDirectorySelector
              label={intl.formatMessage({
                id: 'account.configuration.pms.watched_directory.label',
              })}
              fileSystemUtility={fileSystemUtility}
              useDirectory={useWatchDirectory}
              setSelectedDirectory={setWatchedDirectory}
            />
          </div>
          <div data-testid="pms-export-directory-selector">
            <DirectorySelector
              label={intl.formatMessage({
                id: 'account.configuration.pms.export_directory.label',
              })}
              fileSystemUtility={fileSystemUtility}
              useDirectory={useExportDirectory}
              setSelectedDirectory={setExportDirectory}
              placeholderKey={exportPlaceholderKey}
              invalidDirectoryKey={invalidDirectoryKey}
              setDirectoryDescriptionKey={setExportDirectoryDescriptionKey}
              clearDirectoryDescriptionKey={clearExportDirectoryDescriptionKey}
            />
          </div>

          {PMS_WITH_SPECIES_ALIASES.includes(pmsType) && (
            <>
              <Form.TextArea
                className="ui input labeled"
                label={
                  <Label>
                    <FormattedMessage id="account.configuration.pms.species_aliases.label" />
                  </Label>
                }
                placeholder={intl.formatMessage({
                  id: 'account.configuration.pms.species_aliases.placeholder',
                })}
                onChange={(evt, { value }) => setSpeciesAliasesValue(value)}
                value={speciesAliasesValue}
                error={invalidAliases.length > 0}
                rows={7}
              />
            </>
          )}
        </div>
      )}
      {invalidAliases.length > 0 && (
        <Message error>
          <Message.Header>
            <FormattedMessage id="account.configuration.pms.species_aliases.error" />
          </Message.Header>
          <Message.Content>
            <ul>
              {invalidAliases.map((line) => (
                <li key={line}>{line}</li>
              ))}
            </ul>
          </Message.Content>
        </Message>
      )}
      <div>
        <ConfigurationStateButton
          onClick={validateConfiguration}
          configurationState={configurationState}
        />
      </div>
    </Form>
  );
}

PMSConfiguration.propTypes = {
  fileSystemUtility: pt.shape(FilesystemUtility),
  defaultSpeciesAliasesByPMS: pt.shape({}),
};
PMSConfiguration.defaultProps = {
  fileSystemUtility: new FSFileSystemUtility(),
  defaultSpeciesAliasesByPMS: DEFAULT_SPECIES_ALIASES_BY_PMS,
};

export default PMSConfiguration;
