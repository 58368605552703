/* eslint-disable no-underscore-dangle */
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import Helmet from 'react-helmet';
import * as PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import * as _ from 'lodash';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon, Table, Checkbox, Confirm, Loader } from 'semantic-ui-react';
import { makeSelectUsername, makeSelectPassword } from './selectors';
import AnimalSearch from '../../components/AnimalSearch';
import ApiCalls, { API } from 'app/utils/apiCalls';
import DisplayDate from '../../components/DisplayDate';
import PatternUnstructuredList from '../../components/PatternUnstructuredList';

import { getReport } from '../../utils/reports/reportsGenerator';
import AgeFromDate from '../../components/AgeFromDate';
import { getVHSValue } from '../../CornerstoneTools/VHSTool';
import VHSReferencesDropdown from '../../components/VHSReferencesDropdown';

import Select from 'react-select';
import RadioImage from '../../components/RadioImage';
import RadioImageData from '../../utils/RadioImageData';
import EditableReport from '../../components/EditableReport';
import InfiniteScroll from 'react-infinite-scroller';
import { getReportFromStudy } from '../../utils/reports/reportsGenerator';
import {
  ABDOMEN_PATTERNS,
  THORAX_PATTERNS,
} from '../../components/PatternStructuredList/patternsGroups';
import { loadStudy } from '../../utils/studyUtils';
import isWorkListImage from '../../utils/isWorkListImage';
import ExamIcon from '../../components/ExamIcon';
import { predictionsMerger } from '../../utils/predictions/utils';

import './style.scss';
import PACSSyncStatus from 'app/containers/MonitoringPage/PACSSyncStatus';
import TeleradiologyStudyShortStatus from 'app/containers/Teleradiology/StudyShortStatus';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
`;

const TextField = styled.input`
  display: block;
  border: 1px solid #eee;
  margin: 10px 0;
  background: white;
  padding: 5px;
`;

const PATTERNS_TO_DISPLAY = [...Object.keys(THORAX_PATTERNS), ...Object.keys(ABDOMEN_PATTERNS)];

export class MonitoringPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /** @type {API.Study[]} */
      studies: [],
      thresholdForDisplay: 0.5,
      animalId: null,
      ownerId: null,
      fileId: null,
      showImageModal: false,
      activePage: 0,
      startDate: null,
      endDate: null,
      regions: [],
      currentStudy: {
        images: [],
        comment: '',
      },
      currentStudyID: null,
      studySelectedForRemoving: [],
      isAlertDeleteStudyOpen: false,
      hasMore: true,
    };
    // TODO REFACTO passing props.intl can create some inconsistency if intl is updated.
    this.loadStudy = _.memoize((studyID) => loadStudy([studyID], this.props.intl));
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  setAnimalId = (input) => {
    this.setState({ animalId: input }, () => {
      this.submit();
    });
  };

  setOwnerId = (input) => {
    this.setState({ ownerId: input }, () => {
      this.submit();
    });
  };

  setFileId = (input) => {
    this.setState({ fileId: input }, () => {
      this.submit();
    });
  };

  getHistory = (activePage) => {
    const { ownerId, animalId, fileId, startDate, endDate, regions, hasMore } = this.state;
    if (!hasMore || activePage < 1) return Promise.resolve();
    this.setState({ hasMore: false });
    return ApiCalls.filterStudies({
      ownerId,
      animalId,
      fileId,
      page_number: activePage,
      use_study_structure: true,
      startDate,
      endDate,
      regions,
    }).then(({ data: { studies } }) => {
      studies.forEach((study) => {
        if (!study.comment) {
          study.comment = getReport(
            study.animalName,
            study.ownerName,
            study.images,
            this.props.intl,
            study.creationDate
          );
        }
        study.images = study.images.map((image) => ({
          ...image,
          annotations: _.mapValues(
            image.annotations,
            (annotation) => new Map(Object.entries(annotation))
          ),
        }));
      });
      this.setState({
        studies: this.state.studies.concat(studies),
        activePage,
        hasMore: studies.length > 0,
      });
    });
  };

  renderStudiesList = () =>
    this.state.studies.map((study, index) => this.renderStudy(study, index));

  renderVHSPattern = (study) => {
    const vhsImages = _.filter(
      study.images,
      (image) => _.get(image, 'annotations.VHS', []).size > 0
    );

    const imageMaxVHS = _.maxBy(vhsImages, (image) =>
      getVHSValue(image.annotations.VHS.values().next().value, 1)
    );
    if (!imageMaxVHS) return null;
    return (
      <VHSReferencesDropdown
        hideDropdown
        onRaceSelected={() => {}}
        predictions={imageMaxVHS.predictions}
        patientInfo={study.animal}
        annotations={imageMaxVHS.annotations}
        valueSize="small"
      />
    );
  };

  /** @type {(study:API.Study, index:number) => JSX.Element} */
  renderStudy = (study, index) => {
    const { intl } = this.props;
    const predictionsWrapper = {
      predictions: _.mergeWith(
        {},
        ...study.images.map(({ predictions }) => predictions),
        predictionsMerger
      ),
      feedback: _.merge({}, ...study.images.map(({ feedback }) => feedback)),
    };

    const openStudyInViewer = (event) => {
      event.preventDefault();
      event.stopPropagation();

      browserHistory.push(`/viewer?study_ids[]=${study._id}`);
    };

    const objectDate = new Date(study.creationDate);
    const today = new Date();
    const sex = _.get(study.animal, 'sex');

    const handleSelectRow = (studyID) => {
      if (this.state.currentStudyID != null) {
        document.getElementById(this.state.currentStudyID).style = 'border: none !important';
      }
      document.getElementById(studyID).style = 'border: solid 2px #4183c4 !important';
      this.loadStudy(studyID).then(
        (loadedStudy) => this.setState({ currentStudy: loadedStudy }),
        (error) => {
          console.warn(error);
          this.loadStudy.cache.delete(studyID);
        }
      );
      this.setState({ currentStudyID: studyID });
    };
    const handleSelectStudyForRemoving = (event) => {
      event.stopPropagation();
      this.setState({ studySelectedForRemoving: [study._id], isAlertDeleteStudyOpen: true });
    };
    return (
      // eslint-disable-next-line no-underscore-dangle
      <Table.Row
        key={study._id}
        onClick={() => handleSelectRow(study._id)}
        onDoubleClick={openStudyInViewer}
        id={study._id}
      >
        <Table.Cell className="date">
          <DisplayDate
            date={study.creationDate}
            format={
              objectDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0) ? 'today' : 'shortTime'
            }
          />
        </Table.Cell>
        <Table.Cell>{_.get(study, 'animal.file_id')}</Table.Cell>
        <Table.Cell>{_.get(study, 'animal.owner_name')}</Table.Cell>
        <Table.Cell>{_.get(study, 'animal.name')}</Table.Cell>
        <Table.Cell>
          <AgeFromDate birthDate={_.get(study.animal, 'birth_date')} />
        </Table.Cell>
        <Table.Cell>
          {sex && sex !== 'other' ? (
            <i
              className={`svg-icon ${sex}`}
              title={intl.formatMessage({ id: `patient_info.sex.${sex}` })}
              aria-label={intl.formatMessage({ id: `patient_info.sex.${sex}` })}
            />
          ) : null}
        </Table.Cell>
        <Table.Cell>{study.images.length}</Table.Cell>

        <Table.Cell>
          <PatternUnstructuredList
            image={predictionsWrapper}
            patternsToDisplay={PATTERNS_TO_DISPLAY}
            thresholdForDisplay={this.state.thresholdForDisplay}
            showFeedbackButtons={false}
            displayShowAllButton={true}
            additionalPrefixPatterns={this.renderVHSPattern(study)}
            hideConfidence
          />
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>
          <div className="actions">
            <Icon
              name="trash alternate"
              className="icon-trash picoxia-fix"
              onClick={handleSelectStudyForRemoving}
              title={intl.formatMessage({ id: 'general.delete' })}
              aria-label={intl.formatMessage({ id: 'general.delete' })}
            />

            <Icon
              link
              className="eye icon picoxia-fix"
              onClick={openStudyInViewer}
              title={intl.formatMessage({ id: 'general.see' })}
              aria-label={intl.formatMessage({ id: 'general.see' })}
            />

            <PACSSyncStatus images={study.images} />

            <TeleradiologyStudyShortStatus
              studyId={study._id}
              initialAnalysisStatus={study?.teleradiology_study?.analysis_status}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.submit();
  };

  submit = () => {
    this.setState({ hasMore: true, activePage: 0, studies: [], currentStudyID: null });
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      this.submit();
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      this.submit();
    });
  };

  setRegions = (regions) => {
    const selectedRegions = [];
    regions.forEach((region) => {
      if (region.value === 'thorax') selectedRegions.push('thorax_profil', 'thorax_face');
      else if (region.value === 'pelvis') selectedRegions.push('pelvis_profil', 'pelvis_face');
      else if (region.value === 'abdomen') selectedRegions.push('abdomen_profil', 'abdomen_face');
      else if (region.value === 'autre') selectedRegions.push('autre_autre');
    });
    this.setState({ regions: selectedRegions }, () => {
      this.submit();
    });
  };

  renderPreviewImage = (image) => (
    <div className="image" key={image.id} role="presentation">
      {isWorkListImage(image) ? (
        // eslint-disable-next-line react/destructuring-assignment
        <ExamIcon exam={image.anatomicRegion} animal={this.state.currentStudy.animal} />
      ) : (
        <RadioImage
          cornerstoneDivId={`cornerstoneImageThumbnail${image.id}`}
          thumbnailMode
          image={image}
        />
      )}
    </div>
  );

  renderPreviewImages = () => {
    const { currentStudy } = this.state;
    return (
      <div>
        <div className="thumbnails">
          <div>{currentStudy.images.map((image) => this.renderPreviewImage(image))}</div>
        </div>
      </div>
    );
  };

  renderCommentZone = () => {
    const { currentStudy } = this.state;
    if (currentStudy.images.length > 0) {
      return (
        <div>
          <div
            className="editable-report non-editable-report"
            style={{ height: '100%', borderRadius: '3px' }}
          >
            <textarea
              value={currentStudy.comment}
              disabled
              placeholder={this.formatMessage({ id: 'monitoring.comment' })}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  updateReport = (forceUpdate = false) => {
    this.setState((state) => {
      const { intl } = this.props;
      const { currentStudy } = state;
      if (currentStudy.isCommentDirty && !forceUpdate) return null;
      const report = getReportFromStudy(currentStudy, intl);
      return {
        currentStudy: {
          ...currentStudy,
          comment: report,
          ...(forceUpdate ? { isCommentDirty: false } : undefined),
        },
      };
    });
  };

  deleteStudy = () => {
    ApiCalls.deleteStudies(this.state.studySelectedForRemoving).then(() => {
      this.setState({ hasMore: true, activePage: 0, studies: [], currentStudyID: null });
    });
  };

  handleConfirmDeleteStudy = () => {
    this.setState({ isAlertDeleteStudyOpen: false }, () => {
      this.deleteStudy();
    });
  };

  handleCancelDeleteStudy = () => this.setState({ isAlertDeleteStudyOpen: false });

  renderAlertDeleteStudy = () => {
    const { intl } = this.props;
    return (
      <div>
        <Confirm
          open={this.state.isAlertDeleteStudyOpen}
          header={intl.formatMessage({ id: 'monitoring.study.delete.header' })}
          content={intl.formatMessage({ id: 'monitoring.study.delete.content' })}
          cancelButton={intl.formatMessage({ id: 'general.cancel' })}
          onCancel={this.handleCancelDeleteStudy}
          onConfirm={this.handleConfirmDeleteStudy}
        />
      </div>
    );
  };

  render() {
    let { activePage } = this.state;

    const loadFunc = () => {
      activePage += 1;
      this.getHistory(activePage).then(() => {
        this.setState({ activePage });
      });
    };
    return (
      <div className=" monitoring-background">
        <Helmet title={this.props.intl.formatMessage({ id: 'monitoring.pageTitle' })} />
        <div className="search-form">
          <label style={{ color: 'white' }}>
            <FormattedMessage id="monitoring.animalSearch" />
          </label>
          <div>
            <form className="filters" onSubmit={this.onSubmit}>
              <AnimalSearch
                onComplete={this.submit}
                setAnimalId={this.setAnimalId}
                setOwnerId={this.setOwnerId}
                setFileId={this.setFileId}
                onChooseStartDate={this.setStartDate}
                onChooseEndDate={this.setEndDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                setRegions={this.setRegions}
              />
            </form>
          </div>
        </div>
        <div className="monitoring-table" ref={(ref) => (this.scrollParentRef = ref)}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={this.state.hasMore}
            loader={<Loader key={-1} active inline="centered" />}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
          >
            <Table collapsing={false} inverted className="table-colors">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: '12%' }}>
                    <FormattedMessage id="monitoring.date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '8%' }}>
                    <FormattedMessage id="monitoring.id" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '8%' }}>
                    <FormattedMessage id="monitoring.owner" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '8%' }}>
                    <FormattedMessage id="monitoring.animal" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '5%' }}>
                    <FormattedMessage id="monitoring.age" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '5%' }}>
                    <FormattedMessage id="monitoring.sex" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '5%' }}>
                    <FormattedMessage id="monitoring.images" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '37%' }}>
                    <FormattedMessage id="monitoring.patterns" />
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '10%' }}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderStudiesList()}</Table.Body>
            </Table>
          </InfiniteScroll>
          {this.renderAlertDeleteStudy()}
        </div>
        <div className={this.state.currentStudyID ? 'preview' : 'preview hidden'}>
          <div className="preview-images">{this.renderPreviewImages()}</div>
          <div className="preview-report">{this.renderCommentZone()}</div>
        </div>
      </div>
    );
  }
}

MonitoringPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  username: makeSelectUsername(),
  password: makeSelectPassword(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MonitoringPage));
