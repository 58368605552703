import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { clearError } from '../../redux/global/actions';
import { makeLoginError } from './selectors';
import MinimalButton from '../../components/MinimalButton';
import { parseQueryString } from '../../utils/windowUtils';
import auth from '../auth';
import { REQUEST_ERROR, SET_AUTH } from '../../redux/global/constants';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import FormattedServerError, { errorType } from '../../components/FormattedServerError';

const Error = styled.h4`
  color: #cc0000;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
`;

const TextField = styled.input`
  display: block;
  border: 1px solid #eee;
  margin: 10px 0;
  background: white;
  padding: 5px;
`;

export class ChangePasswordPage extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      password: null,
      passwordSuccessfullyChanged: false,
    };
  }

  componentDidMount = () => {
    this.token = parseQueryString()['token'];
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  onSubmit = (event) => {
    event.preventDefault();
    auth.changePassword(this.token, { password: this.state.password }).then((response) => {
      if (response !== 'undefined' && response.error) {
        this.props.dispatch({ type: REQUEST_ERROR, error: response.error });
      } else {
        this.props.dispatch({ type: SET_AUTH, newAuthState: true });
        this.setState({ passwordSuccessfullyChanged: true });
        setTimeout(function () {
          browserHistory.push('/');
        }, 2000);
      }
    });
  };

  handleChange = (event, fieldName) => {
    let stateUpdate = {};
    this.props.dispatch(clearError());
    stateUpdate[fieldName] = event.target.value;
    this.setState(stateUpdate);
  };

  allStatesNotNull = (stateAttributeNames) => {
    return !stateAttributeNames.some((stateAttributeName) => !this.state[stateAttributeName]);
  };

  isFormFilled = () => {
    return this.allStatesNotNull(['password']);
  };

  renderBody = () => {
    if (this.state.passwordSuccessfullyChanged) {
      return this.renderSuccess();
    } else {
      return this.renderForm();
    }
  };

  renderSuccess = () => {
    return (
      <div style={{ marginTop: '10%', textAlign: 'center' }}>
        <h1>
          <FormattedMessage id="changePassword.success.title" />
        </h1>
        <span style={{ fontSize: '2em' }}>
          <FormattedMessage id="changePassword.success" />
        </span>
      </div>
    );
  };

  renderForm = () => {
    return (
      <div>
        <Form className="ui form" onSubmit={this.onSubmit}>
          <h1 style={{ marginTop: '20vh' }}>
            <FormattedMessage id="changePassword.title" />
          </h1>
          <div className="container">
            <div className="field">
              <label>
                <FormattedMessage id="changePassword.fields.newPassword" />
              </label>
              <div className="field">
                <TextField
                  type="password"
                  name="password"
                  placeholder={this.formatMessage({ id: 'forms.password' })}
                  onChange={(event) => this.handleChange(event, 'password')}
                  required
                />
              </div>
            </div>
          </div>
        </Form>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {this.props.loginError && (
            <Error>
              <FormattedServerError error={this.props.loginError} />
            </Error>
          )}
          <MinimalButton
            className={'ui button ' + (this.isFormFilled() ? 'active' : 'disabled')}
            type="submit"
            value={this.formatMessage({ id: 'forms.send' })}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.renderBody();
  }
}

ChangePasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginError: PropTypes.shape(errorType),
};

ChangePasswordPage.defaultProps = {
  loginError: null,
};

const mapStateToProps = createStructuredSelector({
  loginError: makeLoginError(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordPage));
