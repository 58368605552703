/* eslint-disable camelcase */

import _, { zip } from 'lodash';

import {
  ALREADY_EXISTS,
  DATA_CONFLICT,
  INVALID_PERMISSIONS,
  NO_LICENSE_PACK,
} from 'app/constants/serverError';
import AlreadyInUseError from 'app/errors/AlreadyInUseError';
import LicenseInUseError from 'app/errors/LicenseInUseError';
import PermissionError from 'app/errors/PermissionError';
import UpdateImpossibleError from 'app/errors/UpdateImpossibleError';
import ApiCalls from 'app/utils/apiCalls';

const responseErrorToError = (error) => {
  switch (error.response.data.cause) {
    case ALREADY_EXISTS:
      return new AlreadyInUseError(error);
    case INVALID_PERMISSIONS:
      return new PermissionError(error);
    case NO_LICENSE_PACK:
      return new LicenseInUseError(error);
    case DATA_CONFLICT:
      return new UpdateImpossibleError(error);
    default:
      return error;
  }
};

const parseTeam = (team) => ({
  ...team,
  ia_licenses: _.map(team.ia_licenses, ({ start, end, ...rest }) => ({
    ...rest,
    start: new Date(Date.parse(start)),
    end: new Date(Date.parse(end)),
  })),
});

export default class CloudAdminStore {
  getTeams = async () => {
    try {
      const response = await ApiCalls.getHttpClient().get('/admin/teams');
      const teams = response.data.teams.map(parseTeam);
      const usages = await Promise.all(
        teams.map(async (team) =>
          ApiCalls.getHttpClient()
            .get(`/admin/teams/${team.name}/usage`)
            .then(({ data }) => data.usage)
        )
      );
      return zip(teams, usages).map(([team, currently_in_use]) => ({ ...team, currently_in_use }));
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  /** @note teamName can contain characters following this regex only [\w_-] */
  addTeam = async (team) => {
    try {
      const response = await ApiCalls.getHttpClient().post('/admin/teams', { team });
      return response.data.team;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  updateTeamReferents = async (team, referents_mails) => {
    try {
      const response = await ApiCalls.getHttpClient().put(`/admin/teams/${team}`, {
        referents_mails,
      });
      return response.data.team;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  updateTeamSponsors = async (teamName, sponsors) => {
    try {
      const response = await ApiCalls.getHttpClient().put(`/admin/teams/${teamName}`, {
        sponsors,
      });
      return response.data.team;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  addTeamMember = async (teamName, mail) => {
    try {
      await ApiCalls.getHttpClient().post(`/admin/teams/${teamName}/users`, {
        mail,
      });
      return undefined;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  removeTeamMember = async (teamName, mail) => {
    try {
      await ApiCalls.getHttpClient().delete(`/admin/teams/${teamName}/users/${mail}`);
      return undefined;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  updateTeamMember = async (teamName, email, role) => {
    try {
      await ApiCalls.getHttpClient().put(`/admin/teams/${teamName}/users/${email}`, { role });
      return undefined;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  getTeamMembers = async (teamName) => {
    try {
      const response = await ApiCalls.getHttpClient().get(`/admin/teams/${teamName}/users`);
      return response.data.members;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  // Implement of addIALicensePack
  addIALicensePack = async (teamName, packName, start, end, total_count) => {
    try {
      await ApiCalls.getHttpClient().post(`/admin/teams/${teamName}/ia_licenses`, {
        pack_name: packName,
        start: start.toISOString(),
        end: end.toISOString(),
        total_count,
      });
      return undefined;
    } catch (e) {
      throw responseErrorToError(e);
    }
  };

  editIALicensePack = async (teamName, packId, pack_name, total_count) => {
    try {
      await ApiCalls.getHttpClient().put(`/admin/teams/${teamName}/ia_licenses/${packId}`, {
        pack_name,
        total_count,
      });
      return undefined;
    } catch (error) {
      throw responseErrorToError(error);
    }
  };
}
