import React, { PropsWithChildren, useEffect, useState } from 'react';

import IImageProcessorContext from 'app/providers/ImageProcessorProvider/context';
import imageProcessing from 'app/native/node-addons/imageProcessing';
import { IImageProcessor, ProcessingOptions } from 'app/interfaces/IImageProcessor';
import { DataImage } from 'app/interfaces/DataImage';

type ImageProcessingModuleDataImage = {
  data: Uint16Array;
  width: number;
  height: number;
  bytes_per_pixel: number;
  max_pixel_value: number;
  min_pixel_value: number;
  windowCenter: number;
  windowWidth: number;
  collimation_rect: [number, number, number, number];
};

const makeImageProcessorFromImageProcessingModule = () => {
  if (!imageProcessing()) return undefined;

  const imageProcessor: IImageProcessor = {
    process: async (rawImage: DataImage, options: ProcessingOptions) => {
      const processedImage: ImageProcessingModuleDataImage = await imageProcessing().processImage(
        rawImage,
        options
      );

      if (!processedImage) return undefined;

      const {
        data,
        width,
        height,
        bytes_per_pixel,
        max_pixel_value,
        min_pixel_value,
        windowCenter,
        windowWidth,
        collimation_rect,
      } = processedImage;

      return {
        data,
        width,
        height,
        bytes_per_pixel,
        maxPixelValue: max_pixel_value,
        minPixelValue: min_pixel_value,
        windowCenter,
        windowWidth,
        collimation_rect,
      };
    },
  };
  return imageProcessor;
};

export default function ImageProcessorProvider({ children }: PropsWithChildren<{}>) {
  const [imageProcessor] = useState<IImageProcessor>(makeImageProcessorFromImageProcessingModule);

  return (
    <IImageProcessorContext.Provider value={imageProcessor}>
      {children}
    </IImageProcessorContext.Provider>
  );
}
