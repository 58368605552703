import fs from 'app/native/node/fs';
import ApiCalls from 'app/utils/apiCalls';
import logger from 'app/utils/debug/logger';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';

export default class StorageUsageMonitor {
  constructor(deviceName, deviceIdentifier) {
    this.deviceName = deviceName;
    this.deviceIdentifier = deviceIdentifier;
  }

  /** @type {(directory: string) => Promise<void>} */
  updateStorageUsageForDirectory = async (directory) => {
    if (!fs() || !isAcquisitionSoftware()) return;

    try {
      const directoryStats = await fs().promises.statfs(directory);

      ApiCalls.updateStorageUsageForDirectory({
        usage: {
          totalSize: directoryStats.blocks * directoryStats.bsize,
          availableSize: directoryStats.bavail * directoryStats.bsize,
        },
        directory,
        deviceName: this.deviceName,
        deviceIdentifier: this.deviceIdentifier,
      }).catch((error) => {
        // Ignore permission disabled error
        if (error.response.status !== 401) {
          throw error;
        }
      });
    } catch (err) {
      logger.error(err);
    }
  };
}
