/**
 * Countries List for Application
 * @link https://www.iso.org/obp/ui/#search
 */
const countries = [
  { name: 'France', countryCode: 'fr' },

  { name: 'Belgium', countryCode: 'be' },
  { name: 'Brazil', countryCode: 'br' },
  { name: 'Finland', countryCode: 'fi' },
  { name: 'Germany', countryCode: 'de' },
  { name: 'Greece', countryCode: 'gr' },
  { name: 'Italy', countryCode: 'it' },
  { name: 'Portugal', countryCode: 'pt' },
  { name: 'Czechia', countryCode: 'cz' },
  { name: 'Spain', countryCode: 'es' },
  { name: 'Switzerland', countryCode: 'ch' },
  { name: 'United Kingdom of Great Britain and Northern Ireland (the)', countryCode: 'gb' },
  { name: 'United States of America (the)', countryCode: 'us' },

  { name: 'Afghanistan', countryCode: 'af' },
  { name: 'Albania', countryCode: 'al' },
  { name: 'Algeria', countryCode: 'dz' },
  { name: 'American Samoa', countryCode: 'as' },
  { name: 'Andorra', countryCode: 'ad' },
  { name: 'Angola', countryCode: 'ao' },
  { name: 'Anguilla', countryCode: 'ai' },
  { name: 'Antarctica', countryCode: 'aq' },
  { name: 'Antigua and Barbuda', countryCode: 'ag' },
  { name: 'Argentina', countryCode: 'ar' },
  { name: 'Armenia', countryCode: 'am' },
  { name: 'Aruba', countryCode: 'aw' },
  { name: 'Australia', countryCode: 'au' },
  { name: 'Austria', countryCode: 'at' },
  { name: 'Azerbaijan', countryCode: 'az' },
  { name: 'Bahamas (the)', countryCode: 'bs' },
  { name: 'Bahrain', countryCode: 'bh' },
  { name: 'Bangladesh', countryCode: 'bd' },
  { name: 'Barbados', countryCode: 'bb' },
  { name: 'Belarus', countryCode: 'by' },
  { name: 'Belize', countryCode: 'bz' },
  { name: 'Benin', countryCode: 'bj' },
  { name: 'Bermuda', countryCode: 'bm' },
  { name: 'Bhutan', countryCode: 'bt' },
  { name: 'Bolivia (Plurinational State of)', countryCode: 'bo' },
  { name: 'Bonaire, Sint Eustatius and Saba', countryCode: 'bq' },
  { name: 'Bosnia and Herzegovina', countryCode: 'ba' },
  { name: 'Botswana', countryCode: 'bw' },
  { name: 'Bouvet Island', countryCode: 'bv' },
  { name: 'British Indian Ocean Territory (the)', countryCode: 'io' },
  { name: 'Brunei Darussalam', countryCode: 'bn' },
  { name: 'Bulgaria', countryCode: 'bg' },
  { name: 'Burkina Faso', countryCode: 'bf' },
  { name: 'Burundi', countryCode: 'bi' },
  { name: 'Cabo Verde', countryCode: 'cv' },
  { name: 'Cambodia', countryCode: 'kh' },
  { name: 'Cameroon', countryCode: 'cm' },
  { name: 'Canada', countryCode: 'ca' },
  { name: 'Cayman Islands (the)', countryCode: 'ky' },
  { name: 'Central African Republic (the)', countryCode: 'cf' },
  { name: 'Chad', countryCode: 'td' },
  { name: 'Chile', countryCode: 'cl' },
  { name: 'China', countryCode: 'cn' },
  { name: 'Christmas Island', countryCode: 'cx' },
  { name: 'Cocos (Keeling) Islands (the)', countryCode: 'cc' },
  { name: 'Colombia', countryCode: 'co' },
  { name: 'Comoros (the)', countryCode: 'km' },
  { name: 'Congo (the Democratic Republic of the)', countryCode: 'cd' },
  { name: 'Congo (the)', countryCode: 'cg' },
  { name: 'Cook Islands (the)', countryCode: 'ck' },
  { name: 'Costa Rica', countryCode: 'cr' },
  { name: 'Croatia', countryCode: 'hr' },
  { name: 'Cuba', countryCode: 'cu' },
  { name: 'Curaçao', countryCode: 'cw' },
  { name: 'Cyprus', countryCode: 'cy' },
  { name: "Côte d'Ivoire", countryCode: 'ci' },
  { name: 'Denmark', countryCode: 'dk' },
  { name: 'Djibouti', countryCode: 'dj' },
  { name: 'Dominica', countryCode: 'dm' },
  { name: 'Dominican Republic (the)', countryCode: 'do' },
  { name: 'Ecuador', countryCode: 'ec' },
  { name: 'Egypt', countryCode: 'eg' },
  { name: 'El Salvador', countryCode: 'sv' },
  { name: 'Equatorial Guinea', countryCode: 'gq' },
  { name: 'Eritrea', countryCode: 'er' },
  { name: 'Estonia', countryCode: 'ee' },
  { name: 'Eswatini', countryCode: 'sz' },
  { name: 'Ethiopia', countryCode: 'et' },
  { name: 'Falkland Islands (the) [Malvinas]', countryCode: 'fk' },
  { name: 'Faroe Islands (the)', countryCode: 'fo' },
  { name: 'Fiji', countryCode: 'fj' },
  { name: 'French Guiana', countryCode: 'gf' },
  { name: 'French Polynesia', countryCode: 'pf' },
  { name: 'French Southern Territories (the)', countryCode: 'tf' },
  { name: 'Gabon', countryCode: 'ga' },
  { name: 'Gambia (the)', countryCode: 'gm' },
  { name: 'Georgia', countryCode: 'ge' },
  { name: 'Ghana', countryCode: 'gh' },
  { name: 'Gibraltar', countryCode: 'gi' },
  { name: 'Greenland', countryCode: 'gl' },
  { name: 'Grenada', countryCode: 'gd' },
  { name: 'Guadeloupe', countryCode: 'gp' },
  { name: 'Guam', countryCode: 'gu' },
  { name: 'Guatemala', countryCode: 'gt' },
  { name: 'Guernsey', countryCode: 'gg' },
  { name: 'Guinea', countryCode: 'gn' },
  { name: 'Guinea-Bissau', countryCode: 'gw' },
  { name: 'Guyana', countryCode: 'gy' },
  { name: 'Haiti', countryCode: 'ht' },
  { name: 'Heard Island and McDonald Islands', countryCode: 'hm' },
  { name: 'Holy See (the)', countryCode: 'va' },
  { name: 'Honduras', countryCode: 'hn' },
  { name: 'Hong Kong', countryCode: 'hk' },
  { name: 'Hungary', countryCode: 'hu' },
  { name: 'Iceland', countryCode: 'is' },
  { name: 'India', countryCode: 'in' },
  { name: 'Indonesia', countryCode: 'id' },
  { name: 'Iran (Islamic Republic of)', countryCode: 'ir' },
  { name: 'Iraq', countryCode: 'iq' },
  { name: 'Ireland', countryCode: 'ie' },
  { name: 'Isle of Man', countryCode: 'im' },
  { name: 'Israel', countryCode: 'il' },
  { name: 'Jamaica', countryCode: 'jm' },
  { name: 'Japan', countryCode: 'jp' },
  { name: 'Jersey', countryCode: 'je' },
  { name: 'Jordan', countryCode: 'jo' },
  { name: 'Kazakhstan', countryCode: 'kz' },
  { name: 'Kenya', countryCode: 'ke' },
  { name: 'Kiribati', countryCode: 'ki' },
  { name: "Korea (the Democratic People's Republic of)", countryCode: 'kp' },
  { name: 'Korea (the Republic of)', countryCode: 'kr' },
  { name: 'Kuwait', countryCode: 'kw' },
  { name: 'Kyrgyzstan', countryCode: 'kg' },
  { name: "Lao People's Democratic Republic (the)", countryCode: 'la' },
  { name: 'Latvia', countryCode: 'lv' },
  { name: 'Lebanon', countryCode: 'lb' },
  { name: 'Lesotho', countryCode: 'ls' },
  { name: 'Liberia', countryCode: 'lr' },
  { name: 'Libya', countryCode: 'ly' },
  { name: 'Liechtenstein', countryCode: 'li' },
  { name: 'Lithuania', countryCode: 'lt' },
  { name: 'Luxembourg', countryCode: 'lu' },
  { name: 'Macao', countryCode: 'mo' },
  { name: 'Madagascar', countryCode: 'mg' },
  { name: 'Malawi', countryCode: 'mw' },
  { name: 'Malaysia', countryCode: 'my' },
  { name: 'Maldives', countryCode: 'mv' },
  { name: 'Mali', countryCode: 'ml' },
  { name: 'Malta', countryCode: 'mt' },
  { name: 'Marshall Islands (the)', countryCode: 'mh' },
  { name: 'Martinique', countryCode: 'mq' },
  { name: 'Mauritania', countryCode: 'mr' },
  { name: 'Mauritius', countryCode: 'mu' },
  { name: 'Mayotte', countryCode: 'yt' },
  { name: 'Mexico', countryCode: 'mx' },
  { name: 'Micronesia (Federated States of)', countryCode: 'fm' },
  { name: 'Moldova (the Republic of)', countryCode: 'md' },
  { name: 'Monaco', countryCode: 'mc' },
  { name: 'Mongolia', countryCode: 'mn' },
  { name: 'Montenegro', countryCode: 'me' },
  { name: 'Montserrat', countryCode: 'ms' },
  { name: 'Morocco', countryCode: 'ma' },
  { name: 'Mozambique', countryCode: 'mz' },
  { name: 'Myanmar', countryCode: 'mm' },
  { name: 'Namibia', countryCode: 'na' },
  { name: 'Nauru', countryCode: 'nr' },
  { name: 'Nepal', countryCode: 'np' },
  { name: 'Netherlands (Kingdom of the)', countryCode: 'nl' },
  { name: 'New Caledonia', countryCode: 'nc' },
  { name: 'New Zealand', countryCode: 'nz' },
  { name: 'Nicaragua', countryCode: 'ni' },
  { name: 'Niger (the)', countryCode: 'ne' },
  { name: 'Nigeria', countryCode: 'ng' },
  { name: 'Niue', countryCode: 'nu' },
  { name: 'Norfolk Island', countryCode: 'nf' },
  { name: 'North Macedonia', countryCode: 'mk' },
  { name: 'Northern Mariana Islands (the)', countryCode: 'mp' },
  { name: 'Norway', countryCode: 'no' },
  { name: 'Oman', countryCode: 'om' },
  { name: 'Pakistan', countryCode: 'pk' },
  { name: 'Palau', countryCode: 'pw' },
  { name: 'Palestine, State of', countryCode: 'ps' },
  { name: 'Panama', countryCode: 'pa' },
  { name: 'Papua New Guinea', countryCode: 'pg' },
  { name: 'Paraguay', countryCode: 'py' },
  { name: 'Peru', countryCode: 'pe' },
  { name: 'Philippines (the)', countryCode: 'ph' },
  { name: 'Pitcairn', countryCode: 'pn' },
  { name: 'Poland', countryCode: 'pl' },
  { name: 'Puerto Rico', countryCode: 'pr' },
  { name: 'Qatar', countryCode: 'qa' },
  { name: 'Romania', countryCode: 'ro' },
  { name: 'Russian Federation (the)', countryCode: 'ru' },
  { name: 'Rwanda', countryCode: 'rw' },
  { name: 'Réunion', countryCode: 're' },
  { name: 'Saint Barthélemy', countryCode: 'bl' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', countryCode: 'sh' },
  { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
  { name: 'Saint Lucia', countryCode: 'lc' },
  { name: 'Saint Martin (French part)', countryCode: 'mf' },
  { name: 'Saint Pierre and Miquelon', countryCode: 'pm' },
  { name: 'Saint Vincent and the Grenadines', countryCode: 'vc' },
  { name: 'Samoa', countryCode: 'ws' },
  { name: 'San Marino', countryCode: 'sm' },
  { name: 'Sao Tome and Principe', countryCode: 'st' },
  { name: 'Saudi Arabia', countryCode: 'sa' },
  { name: 'Senegal', countryCode: 'sn' },
  { name: 'Serbia', countryCode: 'rs' },
  { name: 'Seychelles', countryCode: 'sc' },
  { name: 'Sierra Leone', countryCode: 'sl' },
  { name: 'Singapore', countryCode: 'sg' },
  { name: 'Sint Maarten (Dutch part)', countryCode: 'sx' },
  { name: 'Slovakia', countryCode: 'sk' },
  { name: 'Slovenia', countryCode: 'si' },
  { name: 'Solomon Islands', countryCode: 'sb' },
  { name: 'Somalia', countryCode: 'so' },
  { name: 'South Africa', countryCode: 'za' },
  { name: 'South Georgia and the South Sandwich Islands', countryCode: 'gs' },
  { name: 'South Sudan', countryCode: 'ss' },
  { name: 'Sri Lanka', countryCode: 'lk' },
  { name: 'Sudan (the)', countryCode: 'sd' },
  { name: 'Suriname', countryCode: 'sr' },
  { name: 'Svalbard and Jan Mayen', countryCode: 'sj' },
  { name: 'Sweden', countryCode: 'se' },
  { name: 'Syrian Arab Republic (the)', countryCode: 'sy' },
  { name: 'Taiwan (Province of China)', countryCode: 'tw' },
  { name: 'Tajikistan', countryCode: 'tj' },
  { name: 'Tanzania, the United Republic of', countryCode: 'tz' },
  { name: 'Thailand', countryCode: 'th' },
  { name: 'Timor-Leste', countryCode: 'tl' },
  { name: 'Togo', countryCode: 'tg' },
  { name: 'Tokelau', countryCode: 'tk' },
  { name: 'Tonga', countryCode: 'to' },
  { name: 'Trinidad and Tobago', countryCode: 'tt' },
  { name: 'Tunisia', countryCode: 'tn' },
  { name: 'Turkmenistan', countryCode: 'tm' },
  { name: 'Turks and Caicos Islands (the)', countryCode: 'tc' },
  { name: 'Tuvalu', countryCode: 'tv' },
  { name: 'Türkiye', countryCode: 'tr' },
  { name: 'Uganda', countryCode: 'ug' },
  { name: 'Ukraine', countryCode: 'ua' },
  { name: 'United Arab Emirates (the)', countryCode: 'ae' },
  { name: 'United States Minor Outlying Islands (the)', countryCode: 'um' },
  { name: 'Uruguay', countryCode: 'uy' },
  { name: 'Uzbekistan', countryCode: 'uz' },
  { name: 'Vanuatu', countryCode: 'vu' },
  { name: 'Venezuela (Bolivarian Republic of)', countryCode: 've' },
  { name: 'Viet Nam', countryCode: 'vn' },
  { name: 'Virgin Islands (British)', countryCode: 'vg' },
  { name: 'Virgin Islands (U.S.)', countryCode: 'vi' },
  { name: 'Wallis and Futuna', countryCode: 'wf' },
  { name: 'Western Sahara', countryCode: 'eh' },
  { name: 'Yemen', countryCode: 'ye' },
  { name: 'Zambia', countryCode: 'zm' },
  { name: 'Zimbabwe', countryCode: 'zw' },
  { name: 'Åland Islands', countryCode: 'ax' },
];

export default countries;
