function parseForOwnerName(clientName) {
  if (clientName) {
    return clientName.split('^')[0];
  }
  return '';
}

function parseForAnimalName(clientName) {
  if (clientName && clientName.includes('^')) {
    return clientName.split('^')[1];
  }
  return '';
}

export const isDicomTerminated = (path) =>
  path?.endsWith?.('.dicom') ||
  path?.endsWith?.('.dcm') ||
  path?.endsWith?.('.DCM') ||
  path?.endsWith?.('.DICOM');

const hasDicomExtension = (imageFile) =>
  isDicomTerminated(imageFile.path) || isDicomTerminated(imageFile.name);

const isDicom = (imageFile) =>
  typeof imageFile === 'object' &&
  hasDicomExtension(imageFile) &&
  (!('isFromFileSystem' in imageFile) || !imageFile.isFromFileSystem);

const isDicomFromFileSystem = (imageFile) =>
  typeof imageFile === 'object' && 'isFromFileSystem' in imageFile && imageFile.isFromFileSystem;

const isAnyDicomImage = (imageFile) => isDicom(imageFile) || isDicomFromFileSystem(imageFile);

const isBase64Image = (imageFile) =>
  typeof imageFile === 'string' && imageFile.startsWith('data:image/');

export {
  parseForOwnerName,
  parseForAnimalName,
  hasDicomExtension,
  isDicom,
  isDicomFromFileSystem,
  isAnyDicomImage,
  isBase64Image,
};
