/* eslint-disable camelcase */
import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const PACSErrorMessage = ({ error, titleID, size = undefined }) => {
  if (error === undefined) return null;

  const { error_type, status, error_comment } = error;

  const isRawTextStatus = error_type === 'request_failure';

  return (
    <Message error icon size={size}>
      <Icon name="warning sign" />
      <Message.Content>
        <Message.Header>
          <FormattedMessage id={titleID} />
        </Message.Header>
        <p>
          {isRawTextStatus ? status : <FormattedMessage id={`pacs.association_error.${status}`} />}
        </p>
        {error_comment && <p>{error_comment}</p>}
      </Message.Content>
    </Message>
  );
};

export default PACSErrorMessage;
