/* eslint-disable react/style-prop-object */
import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { getWeekOfMonth } from 'date-fns';

const computeAge = (birthDate) => {
  if (!birthDate) return null;
  const diff = new Date(Date.now() - birthDate);
  if (diff < 0) return null;
  return {
    years: diff.getFullYear() - 1970,
    months: diff.getMonth(),
    weeks: getWeekOfMonth(diff),
  };
};

const ageToString = (birthDate, intl) => {
  const age = computeAge(birthDate);
  if (!age) return '';
  const years =
    age.years > 0
      ? intl.formatNumber(age.years, {
          style: 'unit',
          unit: 'year',
          unitDisplay: 'narrow',
        })
      : '';
  const months =
    age.months > 0
      ? intl.formatNumber(age.months, {
          style: 'unit',
          unit: 'month',
          unitDisplay: 'narrow',
        })
      : '';
  return `${years}${months !== '' ? ' ' : ''}${months}`;
};

class AgeFromDate extends React.PureComponent {
  render = () => {
    const { birthDate } = this.props;
    const age = computeAge(birthDate);
    if (!age) return null;
    return (
      <div className="age-from-date">
        {age.years > 0 ? (
          <div>
            <FormattedNumber style="unit" unit="year" unitDisplay="narrow" value={age.years} />
          </div>
        ) : null}
        {age.months > 0 ? (
          <div>
            <FormattedNumber style="unit" unit="month" unitDisplay="narrow" value={age.months} />
          </div>
        ) : null}
        {birthDate && age.months === 0 && age.years === 0 ? (
          <div>
            {'<'}
            <FormattedNumber style="unit" unit="month" unitDisplay="narrow" value={1} />
          </div>
        ) : null}
      </div>
    );
  };
}

AgeFromDate.propTypes = {
  birthDate: pt.instanceOf(Date),
};

AgeFromDate.defaultProps = {
  birthDate: null,
};

export default AgeFromDate;
export { computeAge, ageToString };
