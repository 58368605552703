import React from 'react';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';

class OnClickToolButton extends React.PureComponent {
  render = () => {
    const { intl, tooltipId, content, onClick, isActive, ...restProps } = this.props;
    const activeClass = isActive ? 'active' : '';
    return (
      <button
        type="button"
        className={`picoxia tool-button ${activeClass}`}
        onClick={onClick}
        title={intl.formatMessage({ id: tooltipId })}
        {...restProps}
      >
        {content}
      </button>
    );
  };
}

OnClickToolButton.propTypes = {
  intl: pt.shape().isRequired,
  tooltipId: pt.string.isRequired,
  content: pt.oneOfType([pt.string, pt.shape()]).isRequired,
  onClick: pt.func.isRequired,
  isActive: pt.bool,
};

OnClickToolButton.defaultProps = {
  isActive: false,
};

export default injectIntl(OnClickToolButton);
