import * as Sentry from '@sentry/browser';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectFlatPanelConfiguration } from 'app/redux/flatPanelConfiguration/reducer';

const SentryFlatPanelKind = () => {
  const flatPanelConfiguration = useSelector(selectFlatPanelConfiguration);
  const { detectorKind } = flatPanelConfiguration;

  useEffect(() => {
    if (!detectorKind) return;
    Sentry.setTag('detector_kind', detectorKind);
  }, [detectorKind]);

  return null;
};

export default SentryFlatPanelKind;
