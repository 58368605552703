import { ViewerImage } from 'app/components/Viewer/types';
import { Patient } from 'app/interfaces/Patient';

export const makeAcquisitionDetailsFromImage = (
  patient: Patient,
  image: ViewerImage,
  imageIndex?: number
) => ({
  acquisitionTime: image.acquisitionTime,
  acquisitionConstants: image.acquisitionConstants,
  anatomicRegion: image.anatomicRegion,
  detectorInfo: image.detectorInfo,
  patient,
  imageIndex,
});
