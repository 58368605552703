import makeCancelable from 'app/utils/makeCancelable';
import { useEffect, useRef } from 'react';

function useCancellablePromises() {
  // think of useRef as member variables inside a hook
  // you cannot define promises here as an array because
  // they will get initialized at every render refresh
  const promises = useRef();
  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(() => {
    promises.current = promises.current || [];
    return function cancel() {
      promises.current.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, []);

  // cancelablePromise remembers the promises that you
  // have called so far. It returns a wrapped cancelable
  // promise
  /**
   * @template ResultType
   * @param {Promise<ResultType>} p
   * @returns {Promise<ResultType|import('app/utils/makeCancelable').PromiseCanceledError>}
   */
  function cancellablePromises(p) {
    const cPromise = makeCancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }
  return cancellablePromises;
}

export default useCancellablePromises;
