import React, { PropsWithChildren, useContext, useState } from 'react';
import DicomBuilderContext from 'app/providers/DicomBuilder/context';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';
import DicomBuilder from 'app/utils/dicom/DicomBuilder';
import JPEGLSEncoder from 'app/adapters/JPEGLSEncoder';
import { ImageTransformerFactory } from 'app/adapters/ImageTransformer';
import JPEGLSModuleContext from 'app/providers/JPEGLSModuleProvider/context';

export default function DicomBuilderProvider({ children }: PropsWithChildren<{}>) {
  const displayableImageEncoder = useContext(IDisplayableImageEncoderContext);
  const jpeglsModule = useContext(JPEGLSModuleContext);
  const [dicomBuilder] = useState(
    () =>
      new DicomBuilder(new ImageTransformerFactory(), displayableImageEncoder, {
        '1.2.840.10008.1.2.4.80': new JPEGLSEncoder(jpeglsModule),
      })
  );

  return (
    <DicomBuilderContext.Provider value={dicomBuilder}>{children}</DicomBuilderContext.Provider>
  );
}
