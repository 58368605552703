import _ from 'lodash';
import { DicomData, DicomTransferSyntax } from 'app/interfaces/Dicom';
import { IImageWriter } from 'app/interfaces/IImageWriter';
import { DisplayableImageData } from 'app/interfaces/Image';
import { Transformation } from 'app/interfaces/Image/Transformation';
import ImageWriter from 'app/interfaces/ImageWriter';
import { IDicomWriter } from 'app/interfaces/IDicomWriter';
import IDicomBuilder, { EncodedTransferSyntaxes } from 'app/interfaces/IDicomBuilder';

export type FormatImageForUploadOptions = {
  dicomWriter: IDicomWriter;
  dicomBuilder: IDicomBuilder;
  imageWriter?: IImageWriter;
};

export default async function formatImageForUpload(
  formatter: FormatImageForUploadOptions,
  image: DisplayableImageData,
  transformation: Transformation,
  dicomData?: DicomData,
  transferSyntax?: EncodedTransferSyntaxes
): Promise<Blob | undefined> {
  const { dicomWriter, dicomBuilder, imageWriter = new ImageWriter() } = formatter;
  if (dicomData) {
    const dicomDataWithEncodedImage = await dicomBuilder.injectPixelDataFromDisplayableImage(
      dicomData,
      image,
      _.omit(transformation, ['resize', 'voi']),
      transformation.voi,
      transferSyntax
    );

    return new Blob([await dicomWriter.generateDicomFile(dicomDataWithEncodedImage)], {
      type: 'application/dicom',
    });
  }

  return imageWriter.writeImageFileDataFromDisplayableImage(image, transformation, {
    type: 'image/png',
  });
}
