import 'app/styles/style.scss';
import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ReactComponent as Abdomen } from '../../../static/svg_icons/abdomen.svg';
import { ReactComponent as Bassin } from '../../../static/svg_icons/bassin.svg';
import { ReactComponent as Crane } from '../../../static/svg_icons/crane.svg';
import { ReactComponent as Thorax } from '../../../static/svg_icons/thorax.svg';
import { ReactComponent as Rachis } from '../../../static/svg_icons/rachis.svg';
import { ReactComponent as MembrePelvien } from '../../../static/svg_icons/membre_pelvien.svg';
import { ReactComponent as MembreThoracique } from '../../../static/svg_icons/membre_thoracique.svg';

import { ReactComponent as DogOutline } from '../../../static/svg_icons/dog_contour.svg';
import { ReactComponent as CatOutline } from '../../../static/svg_icons/cat_contour.svg';
import { ReactComponent as RabbitOutline } from '../../../static/svg_icons/rabbit_contour.svg';
import { ReactComponent as FerretOutline } from '../../../static/svg_icons/ferret_contour.svg';
import { ReactComponent as RodentOutline } from '../../../static/svg_icons/rodent_contour.svg';
import { ReactComponent as BirdOutline } from '../../../static/svg_icons/bird_contour.svg';
import { ReactComponent as ReptileOutline } from '../../../static/svg_icons/reptile_contour.svg';
import { getAnatomicRegionFromString } from 'app/utils/xrayRegions';

const AREAS = {
  abdomen: Abdomen,
  Bassin,
  crane: Crane,
  thorax: Thorax,
  rachis: Rachis,
  membre_pelvien_gauche: MembrePelvien,
  membre_pelvien_droit: MembrePelvien,
  membre_thoracique_gauche: MembreThoracique,
  membre_thoracique_droit: MembreThoracique,
  entire_body: {
    dog: DogOutline,
    cat: CatOutline,
    rabbit: RabbitOutline,
    ferret: FerretOutline,
    rodent: RodentOutline,
    bird: BirdOutline,
    reptile: ReptileOutline,
    other: DogOutline,
  },
};

class ExamIcon extends React.PureComponent {
  render = () => {
    const { exam, className, style, intl } = this.props;
    const { specie, mainRegion, subRegion, view, orientation } = getAnatomicRegionFromString(exam);
    const AreaSVG =
      mainRegion !== 'entire_body'
        ? AREAS[subRegion] ?? AREAS[mainRegion]
        : AREAS[mainRegion][specie];
    return (
      <div className={`exam-icon ${className}`} style={style}>
        {AreaSVG && <AreaSVG />}
        {subRegion && (
          <div title={intl.formatMessage({ id: `exam.body_part.${subRegion}` })}>
            <FormattedMessage id={`exam.body_part.${subRegion}`} />
            {orientation && ' '}
            {orientation && (
              <FormattedMessage id={`exam.orientation.${orientation}.short_format`} />
            )}
          </div>
        )}
        {view && (
          <div>
            <FormattedMessage id={`exam.view.${view}`} />
          </div>
        )}
      </div>
    );
  };
}

ExamIcon.propTypes = {
  exam: pt.string.isRequired,
  className: pt.string,
  style: pt.shape(),
  intl: pt.shape().isRequired,
};

ExamIcon.defaultProps = {
  className: '',
  style: null,
};

export default injectIntl(ExamIcon);
