import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';

import PatientInfoForm from '.';
import ApiCalls from '../../utils/apiCalls';

class TestPatientInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.patientInfoFormRef = React.createRef();
    this.state = { patientInfo: undefined, hasConflict: false };
  }

  savePatient = () => {
    const { patientInfo } = this.state;
    ApiCalls.savePatientInfo(patientInfo)
      .then((response) => {
        console.log('success', response);
      })
      .catch((error) => {
        console.error('request error', error);
      });
  };

  onChange = ({ patientInfo, hasConflict }) => {
    console.log({ patientInfo, hasConflict });
    this.setState({ patientInfo, hasConflict });
  };

  render = () => {
    const { hasConflict } = this.state;
    return (
      <div style={{ backgroundColor: '#444645' }}>
        <PatientInfoForm onChange={this.onChange} />
        <Button icon="save" onClick={this.savePatient} disabled={hasConflict} />

      </div>
    );
  };
}
export default injectIntl(TestPatientInfoForm);
