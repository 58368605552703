import produce from 'immer';
import * as _ from 'lodash';
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';

import { prepareDicomForPACS } from 'app/utils/dicom/DicomData';
import { isAcquisitionImage } from 'app/utils/isAcquisitionImage';
import convertCropHandlesToProcessingRect from 'app/utils/processing/convertCropHandlesToProcessingRect';
import { DicomTransferSyntax } from 'app/interfaces/Dicom';
import createDeferredPromise from 'app/utils/createDeferredPromise';
import { computeScaleFactorFromStoredViewport, restoreViewport } from './cornerstone/imageUtils';
import { forceImageUpdate } from '../CornerstoneTools/forceImageUpdate';
import { isAnyDicomImage } from './DicomHelpers';
import { isRawImage } from './cornerstone/loadImage';
import convertDicomParserDataToDicomData from './dicom/convertDicomParserDataToDicomData';
import { PRECOMPUTED_TOOLS } from 'app/components/ToolsBar/PRECOMPUTED_TOOLS';
import { memoizeDebounce } from './lodashMixins';
import { isCropInProgress } from '../CornerstoneTools/CropTool';
import PROCESSING_OPTIONS from './PROCESSING_OPTIONS';
import { computeRealSizePixelSpacingFromMeasurement } from 'app/CornerstoneTools/RealSizeMeasurementCalibrationTool';
import invalidateAllTools from 'app/CornerstoneTools/invalidateAllTools';

const processImage = window.nodeModules?.imageProcessing?.processImage;

let lastImageId = 0;

const produceImageId = () => {
  lastImageId += 1;
  return lastImageId;
};

const EVENTS = { MEASUREMENT_UPDATED: 'measurement_updated' };

const fillProcessedImage = (processingType, processedImage, options) => ({
  ...processedImage,
  processingType,
  minPixelValue: processedImage.min_pixel_value,
  maxPixelValue: processedImage.max_pixel_value,
  options,
});

const getProcessingFromTools = (processingType, toolsProps) => {
  let realProcessingType = processingType;
  if (processingType.includes('thorax')) {
    realProcessingType = 'thorax';
  }
  if (processingType.includes('abdomen')) {
    realProcessingType = 'abdomen';
  }
  if (processingType === 'dog_skull') {
    realProcessingType = 'bones';
  }
  return toolsProps.Processing.processingList[realProcessingType];
};

const RESTORATION_STATUS = {
  needed: 'needed',
  ongoing: 'ongoing',
  done: 'done',
};

export function encodeProcessedImageForDicom(
  dicomBuilder,
  { dicomData, processedImage, cornerstoneRef, cropRect } = {},
  insertAnnotations = false,
  transferSyntax = undefined
) {
  if (!dicomData || !processedImage || !cornerstoneRef) {
    return undefined;
  }

  const { invert, rotation, hflip, vflip, voi } = csc.getViewport(cornerstoneRef);
  const crop = cropRect
    ? {
        x: cropRect[0],
        y: cropRect[1],
        width: cropRect[2],
        height: cropRect[3],
      }
    : undefined;

  // eslint-disable-next-line no-param-reassign
  return dicomBuilder.injectProcessedImageData(
    dicomData,
    processedImage,
    { invert, crop, hflip, vflip, rotation },
    voi,
    transferSyntax,
    insertAnnotations ? cornerstoneRef : undefined
  );
}

export async function injectProcessedDicomData(
  dicomBuilder,
  radioImageData,
  insertAnnotations = false,
  transferSyntax = undefined
) {
  const dicomDataWithProcessedImage = await encodeProcessedImageForDicom(
    dicomBuilder,
    radioImageData,
    insertAnnotations,
    transferSyntax
  );
  if (!dicomDataWithProcessedImage) return radioImageData;

  // eslint-disable-next-line no-param-reassign
  radioImageData.dicomData = dicomDataWithProcessedImage;
  return radioImageData;
}

export default class RadioImageData extends EventTarget {
  constructor(
    imageFile,
    origin,
    toolsList,
    initialAnnotations,
    initialViewport,
    initialImageMetadata,
    intl,
    applyProcessCallback = () => {},
    computePicoxiaAnalysisFn = () => {},
    updateImageFn = () => {},
    taskScheduler
  ) {
    super();
    this.id = produceImageId();
    this.restorationStatus =
      initialImageMetadata === undefined ? RESTORATION_STATUS.done : RESTORATION_STATUS.needed;
    this.imageFile = imageFile;
    this.backendId = null;
    this.predictions = {};
    this.origin = origin;
    this.cornerstoneRef = null;
    this.analyzed = true;
    this.feedback = {};
    this.annotationsHistory = [];
    this.redoAnnotationsHistory = [];
    this.allowMeasurementSave = true;
    this.initialAnnotations = _.mapValues(
      initialAnnotations,
      (value) => new Map(Object.entries(value))
    );
    this.annotations = {};
    this.initialViewport = initialViewport;
    this.initialImageMetadata = initialImageMetadata;
    this.toolsList = _.cloneDeep(toolsList);
    this.applyProcessCallback = applyProcessCallback;
    this.updateImageFn = updateImageFn;
    this.isLoaded = false;
    this.saved = true;
    this.needLogRescale = initialImageMetadata?.needLogRescale ?? initialImageMetadata?.isRawIRay;
    this.initialImageSpacing = {}; // Has following form
    // {
    //   rowPixelSpacing:number;
    //   columnPixelSpacing:number;
    // }

    this.lastVOI = undefined;
    this.currentProcessingType = undefined;
    this.isProcessingOnGoing = false;
    this.PACS = {
      isSync: initialImageMetadata?.PACS?.isSync ?? false,
      isSyncOngoing: false,
      syncError: undefined,
    };
    this.taskScheduler = taskScheduler;

    this.updateToolsAnnotations = (evt) => {
      const { toolName, measurementData } = evt.detail;
      const { uuid } = measurementData;

      // Some update of cornerstone introduced a bogus MEASUREMENT_COMPLETED event.
      // This event lack a toolName so we filter it out.
      if (!toolName) return;

      this.annotations = produce(this.annotations, (draftAnnotations) => {
        if (evt.type === cst.EVENTS.MEASUREMENT_REMOVED) {
          if (draftAnnotations[toolName] !== undefined) {
            draftAnnotations[toolName].delete(uuid);
          }
        } else {
          if (draftAnnotations[toolName] === undefined) {
            draftAnnotations[toolName] = new Map();
          }
          if (
            evt.type === cst.EVENTS.MEASUREMENT_MODIFIED &&
            PRECOMPUTED_TOOLS.includes(toolName)
          ) {
            measurementData.userModified = true;
          }
          const newMeasurementData = _.merge(
            draftAnnotations[toolName].get(uuid) || {},
            measurementData
          );
          draftAnnotations[toolName].set(uuid, newMeasurementData);
        }
      });
      const initialAnnotation = this.initialAnnotations?.[toolName]?.get(uuid);
      const isInitialAnnotation = !!initialAnnotation && !initialAnnotation.isInitialAnnotationSent;
      if (initialAnnotation) {
        // Crop is considered successfully initialized once the `COMPLETED` event has been sent.
        const isInitialCropAdd = toolName === 'Crop' && evt.type === cst.EVENTS.MEASUREMENT_ADDED;
        if (!isInitialCropAdd) initialAnnotation.isInitialAnnotationSent = true;
      }
      const measurementUpdated = new Event(EVENTS.MEASUREMENT_UPDATED);
      measurementUpdated.detail = { image: this, isInitialAnnotation };

      this.dispatchEvent(measurementUpdated);
    };

    this.updateToolsAnnotationsDebounced = memoizeDebounce(this.updateToolsAnnotations, 300, {
      resolver: (evt) => {
        const { type, measurementData } = evt.detail;
        return `${type}${measurementData?.toolName}`;
      },
    });

    this.onMeasurementUpdate = (evt) => {
      // Some data inside measurementData are throttled and only updated after a short delay
      // (100ms). Because of this, we have to run this data update after a debounce and not
      // on every update.
      if (evt.type === cst.EVENTS.MEASUREMENT_MODIFIED) {
        this.updateToolsAnnotationsDebounced(evt);
      } else {
        this.updateToolsAnnotations(evt);
      }
    };

    const checkIsRawImage = isRawImage(imageFile);
    const isDicomImage = isAnyDicomImage(imageFile);
    this.toolsProps = {
      PicoxiaAnalysis: {
        isImageModified: false,
        onClick: () => {
          computePicoxiaAnalysisFn(this);
          this.toolsProps = produce(this.toolsProps, (draft) => {
            draft.PicoxiaAnalysis.isImageModified = false;
          });
        },
      },
    };
    if (processImage && (checkIsRawImage || isDicomImage)) {
      // Current processed image that will be displayed instead of imageFile if set.
      this.processedImage = undefined;
      // First image that was displayed used in case we open a dicom and wish to retrieve initial
      // dicom data.
      this.initialImage = undefined;
      this.toolsProps = {
        ...this.toolsProps,
        WWWC: {},
        Processing: {
          isShown: true,
          processingList: {
            default: {
              label: intl.formatMessage({ id: 'tools.default_processing.label' }),
              callback: this.applyDefaultProcessing,
              isCurrentProcessing: false,
              processedImage: undefined,
            },
            abdomen: {
              label: intl.formatMessage({ id: 'tools.abdomen_processing.label' }),
              callback: (study) => {
                const processingType =
                  study?.animal?.specie === 'dog' ? 'dog_abdomen' : 'cat_abdomen';
                this.applyProcessing(processingType, PROCESSING_OPTIONS[processingType]);
              },
              isCurrentProcessing: false,
              processedImage: undefined,
            },
            bones: {
              label: intl.formatMessage({ id: 'tools.bones_processing.label' }),
              callback: (study) => {
                const isSkull = this.anatomicRegion?.includes('crane');
                const isDog = study?.animal?.specie === 'dog';
                const appliedProcessing = isDog && isSkull ? 'dog_skull' : 'bones';
                this.applyProcessing(appliedProcessing);
              },
              isCurrentProcessing: false,
              processedImage: undefined,
            },
            thorax: {
              label: intl.formatMessage({ id: 'tools.thorax_processing.label' }),
              callback: (study) => {
                if (study?.animal?.specie === 'dog') {
                  this.applyDogThoraxProcessing();
                  return;
                }
                this.applyCatThoraxProcessing();
              },
              isCurrentProcessing: false,
              processedImage: undefined,
            },
          },
        },
      };
    }
  }

  applyDefaultProcessing = async () => this.applyProcessing('default', PROCESSING_OPTIONS.default);

  applyBonesProcessing = async () => this.applyProcessing('bones', PROCESSING_OPTIONS.bones);

  applyDogThoraxProcessing = async () =>
    this.applyProcessing('dog_thorax', PROCESSING_OPTIONS.dog_thorax);

  applyCatThoraxProcessing = async () =>
    this.applyProcessing('cat_thorax', PROCESSING_OPTIONS.cat_thorax);

  applyProcessingWithCustomImage = async (
    image,
    processingType,
    processingTypeOptions,
    isInit = false
  ) => {
    try {
      this.isProcessingOnGoing = true;

      if (!processImage) return;

      const processingStore = getProcessingFromTools(processingType, this.toolsProps);
      const options = { ...processingTypeOptions };
      if (this.photometricInterpretation) {
        options.photometric_interpretation = this.photometricInterpretation;
      }
      if (this.cropRect || options.crop_rect) {
        options.crop_rect = this.cropRect;
      }
      if (this.needLogRescale) options.need_log_rescale = !!this.needLogRescale;

      const isProcessingAlreadyComputed =
        processingStore.processedImage &&
        _.isEqual(processingStore.processedImage.options, options);

      const isProcessingAlreadyCurrent =
        isProcessingAlreadyComputed && this.currentProcessingType === processingType;

      if (isProcessingAlreadyComputed) {
        this.selectProcessing(processingType);
      } else {
        console.time(`Processing ${this.id}`);
        const processedImagePromise = this.processImage(options, image);

        this.saveProcessedImage(processingType, await processedImagePromise, options);
        this.selectProcessing(processingType);
        console.timeEnd(`Processing ${this.id}`);
      }

      this.applyProcessCallback(this, isInit, isProcessingAlreadyCurrent);
      if (!isInit && !isProcessingAlreadyCurrent) {
        this.toolsProps = produce(this.toolsProps, (draftTools) => {
          draftTools.PicoxiaAnalysis.isImageModified = true;
        });
        this.PACS.isSync = false;
        this.updateImageFn(this);
      }
    } finally {
      this.isProcessingOnGoing = false;
    }
  };

  applyProcessing = async (processingType, processingTypeOptions = undefined, isInit = false) =>
    this.applyProcessingWithCustomImage(
      undefined,
      processingType,
      processingTypeOptions ?? PROCESSING_OPTIONS[processingType],
      isInit
    );

  processImage = (options, inputImage = undefined) => {
    console.log('options', options);
    const deferredPromise = createDeferredPromise();
    let imageContent;
    let processingOptions;
    if (isRawImage(this.imageFile)) {
      imageContent = inputImage || this.imageFile;
      processingOptions = options;
    }
    if (isAnyDicomImage(this.imageFile)) {
      const image = inputImage || csc.getImage(this.cornerstoneRef);
      if (!this.initialImage) {
        this.initialImage = image;
      }
      const photometricInterpretation = this.initialImage.data.string('x00280004');

      imageContent = {
        height: this.initialImage.height,
        width: this.initialImage.width,
        data: new Uint16Array(this.initialImage.getPixelData().buffer),
        bytes_per_pixel: 2,
      };
      processingOptions = { ...options, photometric_interpretation: photometricInterpretation };
    }

    if (imageContent && processingOptions) {
      this.taskScheduler.schedule(() =>
        processImage(imageContent, processingOptions).then(
          deferredPromise.resolve,
          deferredPromise.reject
        )
      );
      return deferredPromise;
    }

    return Promise.reject(new Error('Image type incompatible with processing'));
  };

  saveProcessedImage = (processingType, processedImage, options) => {
    this.toolsProps = produce(this.toolsProps, (draftToolsProps) => {
      const processingStore = getProcessingFromTools(processingType, draftToolsProps);
      const savedOptions = { ...options };
      const isCollimationRectComputed = !options.crop_rect && processedImage.collimation_rect;
      if (isCollimationRectComputed) {
        savedOptions.crop_rect = processedImage.collimation_rect;
      }
      processingStore.processedImage = fillProcessedImage(
        processingType,
        processedImage,
        savedOptions
      );
    });
  };

  selectProcessing = (selectedProcessingType) => {
    this.processedImage = getProcessingFromTools(
      selectedProcessingType,
      this.toolsProps
    ).processedImage;
    this.currentProcessingType = selectedProcessingType;

    if (!this.cropRect && this.processedImage.collimation_rect) {
      this.cropRect = this.processedImage.collimation_rect;
      const cropTool = cst.getToolForElement(this.cornerstoneRef, 'Crop');
      cropTool?.cropOnRect(this.cornerstoneRef, this.processedImage.collimation_rect);
    }
    this.toolsProps = produce(this.toolsProps, (draftToolsProps) => {
      draftToolsProps.WWWC.initialWindowWidth = this.processedImage.windowWidth;
      draftToolsProps.WWWC.initialWindowCenter = this.processedImage.windowCenter;
      _.forEach(draftToolsProps.Processing.processingList, (draftProcessing, processingType) => {
        // Instead of checking for exact match we check for inclusion for thorax special case.
        draftProcessing.isCurrentProcessing = selectedProcessingType.includes(processingType);
      });
    });
  };

  processImageOnCropCompletion = async (evt) => {
    if (this.restorationStatus !== RESTORATION_STATUS.done) return;
    const { toolName, measurementData } = evt.detail;
    if (toolName !== 'Crop') return;
    this.cropRect = convertCropHandlesToProcessingRect(measurementData.handles);

    if (this.currentProcessingType) {
      await this.applyProcessing(
        this.currentProcessingType,
        PROCESSING_OPTIONS[this.currentProcessingType]
      );
    }
  };

  attachListeners = (element) => {
    this.attachCropListener(element);
    this.attachWWWCListener(element);
  };

  attachCropListener = (element) => {
    if (element) {
      element.addEventListener(cst.EVENTS.MEASUREMENT_COMPLETED, this.processImageOnCropCompletion);
    }
  };

  attachWWWCListener = (element) => {
    const markWWWCChange = (evt) => {
      const { viewport } = evt.detail;
      if (isCropInProgress(element)) return;
      if (this.lastVOI === undefined) {
        this.lastVOI = _.cloneDeep(viewport.voi);
      }

      if (!_.isEqual(viewport.voi, this.lastVOI)) {
        this.toolsProps = produce(this.toolsProps, (draftTools) => {
          draftTools.PicoxiaAnalysis.isImageModified = true;
        });
        this.updateImageFn(this);
      }
      this.lastVOI = _.cloneDeep(viewport.voi);
    };
    element?.addEventListener(csc.EVENTS.IMAGE_RENDERED, markWWWCChange);
  };

  restoreDicomFromFilesystem = (cornerstoneImage) => {
    const {
      initialImageMetadata,
      initialAnnotations,
      initialViewport,
      isLoaded,
      restorationStatus,
    } = this;
    if (isLoaded) return false;
    if (restorationStatus === RESTORATION_STATUS.ongoing) return true;
    if (restorationStatus === RESTORATION_STATUS.done) return false;

    if (cornerstoneImage.data) {
      this.dicomData = convertDicomParserDataToDicomData(cornerstoneImage.data);
      if (!this.rawImageDicomData) {
        this.rawImageDicomData = this.dicomData;
      }
    }
    const shouldApplyProcessing =
      initialImageMetadata?.processingType && isAnyDicomImage(this.imageFile) && processImage;

    if (!shouldApplyProcessing) {
      this.restorationStatus = RESTORATION_STATUS.done;
      return false;
    }
    const { processingType } = initialImageMetadata;

    const cropHandles = initialAnnotations?.Crop?.values().next().value.handles;
    if (cropHandles) {
      const { scaleFactor } = computeScaleFactorFromStoredViewport(
        initialViewport,
        cornerstoneImage
      );
      this.cropRect = convertCropHandlesToProcessingRect(cropHandles, scaleFactor);
    }
    this.restorationStatus = RESTORATION_STATUS.ongoing;
    this.applyProcessingWithCustomImage(
      cornerstoneImage,
      processingType,
      PROCESSING_OPTIONS[processingType],
      true
    ).then(() => {
      this.restorationStatus = RESTORATION_STATUS.done;
    });
    return true;
  };

  resetPixelSpacing = () => {
    const { cornerstoneRef } = this;

    const realSizeMeasurement = cst.getToolState(cornerstoneRef, 'RealSizeMeasurementCalibration')
      ?.data?.[0];
    if (realSizeMeasurement) {
      cst.removeToolState(cornerstoneRef, 'RealSizeMeasurementCalibration', realSizeMeasurement);
      invalidateAllTools(cornerstoneRef);
    }

    const { rowPixelSpacing, columnPixelSpacing } = this.initialImageSpacing ?? {};
    if (!rowPixelSpacing || !columnPixelSpacing) return;

    const cscImage = csc.getImage(cornerstoneRef);

    cscImage.rowPixelSpacing = this.initialImageSpacing.rowPixelSpacing;
    cscImage.columnPixelSpacing = this.initialImageSpacing.columnPixelSpacing;

    forceImageUpdate(cornerstoneRef);
  };

  restorePixelSpacing = () => {
    const { cornerstoneRef } = this;

    const realSizeMeasurement = cst.getToolState(cornerstoneRef, 'RealSizeMeasurementCalibration')
      ?.data?.[0];
    if (!realSizeMeasurement) return;

    const realSizeScale = computeRealSizePixelSpacingFromMeasurement(
      cornerstoneRef,
      realSizeMeasurement
    );

    const { rowPixelSpacing, columnPixelSpacing } = realSizeScale ?? {};
    if (!rowPixelSpacing || !columnPixelSpacing) return;

    const cscImage = csc.getImage(cornerstoneRef);

    this.initialImageSpacing.rowPixelSpacing ??= cscImage.rowPixelSpacing;
    this.initialImageSpacing.columnPixelSpacing ??= cscImage.columnPixelSpacing;

    cscImage.rowPixelSpacing = rowPixelSpacing;
    cscImage.columnPixelSpacing = columnPixelSpacing;

    invalidateAllTools(cornerstoneRef);
    forceImageUpdate(cornerstoneRef);
  };

  updatePixelSpacing = (pixelSpacing = {}) => {
    const { rowPixelSpacing, columnPixelSpacing } = pixelSpacing ?? {};
    if (!rowPixelSpacing || !columnPixelSpacing) return false;

    try {
      const cscImage = csc.getImage(this.cornerstoneRef);
      this.initialImageSpacing.rowPixelSpacing ??= cscImage.rowPixelSpacing;
      this.initialImageSpacing.columnPixelSpacing ??= cscImage.columnPixelSpacing;
      this.initialColumnPixelSpacing ??= cscImage.columnPixelSpacing;
      cscImage.rowPixelSpacing = rowPixelSpacing;
      cscImage.columnPixelSpacing = columnPixelSpacing;

      invalidateAllTools(this.cornerstoneRef);
      forceImageUpdate(this.cornerstoneRef);
    } catch (error) {
      console.warn('Failed to apply real size measurement to current image', error);
    }

    return true;
  };

  restoreInitialAnnotations = () => {
    const { cornerstoneRef, initialViewport, initialAnnotations, initialImageMetadata } = this;
    const enabledElement = csc.getEnabledElement(cornerstoneRef);
    const { image: cornerstoneImage } = enabledElement;
    if (initialViewport && initialImageMetadata && initialAnnotations) {
      let scaleFactor = 1.0;
      if (!_.isEmpty(initialViewport)) {
        const viewport = restoreViewport(initialViewport, cornerstoneRef);
        csc.setViewport(cornerstoneRef, viewport);
        scaleFactor = computeScaleFactorFromStoredViewport(
          initialViewport,
          cornerstoneImage
        ).scaleFactor;
      }
      this.annotations = produce(this.annotations, (draftAnnotations) => {
        _.forEach(initialAnnotations, (toolAnnotations, toolName) => {
          const tool = cst.getToolForElement(cornerstoneRef, toolName);
          if (!tool) return;

          draftAnnotations[toolName] = new Map();
          toolAnnotations.forEach((annotationData, uuid) => {
            const rescaledAnnotationData = _.merge({}, annotationData, {
              handles: _.mapValues(annotationData.handles, (value) => ({
                ...value,
                x: value.x * scaleFactor,
                y: value.y * scaleFactor,
              })),
            });
            if (annotationData.additionalVertebraPoints) {
              rescaledAnnotationData.additionalVertebraPoints =
                annotationData.additionalVertebraPoints.map((value) => ({
                  ...value,
                  x: value.x * scaleFactor,
                  y: value.y * scaleFactor,
                }));
            }
            tool.updateCachedStats(cornerstoneImage, cornerstoneRef, rescaledAnnotationData);

            draftAnnotations[toolName].set(uuid, rescaledAnnotationData);

            cst.addToolState(cornerstoneRef, toolName, _.cloneDeep(rescaledAnnotationData));
          });
        });
      });
      const cropTool = cst.getToolForElement(cornerstoneRef, 'Crop');
      if (cropTool) {
        cropTool.validateCrop(cornerstoneRef);
      }
      forceImageUpdate(cornerstoneRef);
    }
  };

  loadInitialMetadata = () => {
    const { isLoaded, cornerstoneRef } = this;
    if (isLoaded) return false;
    this.restoreInitialAnnotations();
    this.restorePixelSpacing();
    // Restore crop set by a collimation
    const cropTool = cst.getToolForElement(cornerstoneRef, 'Crop');
    const noCropApplied = !cst.getToolState(cornerstoneRef, 'Crop')?.data?.length;
    if (cropTool && this.cropRect && noCropApplied) {
      cropTool.cropOnRect(cornerstoneRef, this.cropRect);
    }
    this.isLoaded = true;
    return true;
  };

  /**
   *
   * @param {import('app/native/node-addons/picoxia-dicom').PACSCommunication} pacsCommunication
   * @returns {Promise?}
   */
  syncWithPACS = async (pacsCommunication, dicomBuilder, studyID, imageIndex) => {
    if (!pacsCommunication) return undefined;
    if (!dicomBuilder) return undefined;
    if (!this.dicomData) return undefined;

    try {
      this.PACS.syncError = undefined;
      this.PACS.isSyncOngoing = true;
      await injectProcessedDicomData(dicomBuilder, this, true, null);
      const dicomData = prepareDicomForPACS(this, studyID, imageIndex);
      const storageResult = await pacsCommunication.store(dicomData);

      this.PACS.isSync = true;

      return storageResult;
    } catch (error) {
      this.PACS.syncError = error;

      return Promise.reject(error);
    } finally {
      this.PACS.isSyncOngoing = false;
    }
  };

  /** @type {(image: RadioImageData) => bool}  */
  static isCompatibleWithPACS = (image) => isAcquisitionImage(image);
}

export { EVENTS, produceImageId, RESTORATION_STATUS };
