import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';

import { Icon, Button, Card, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ApiCalls from '../../utils/apiCalls';

class ClinicLogoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: this.props.logo,
      base64Image: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (!this.props.logo) {
      this.setState({ isLoading: true });
      this.getLogoFromServer();
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getLogoFromServer = () =>
    ApiCalls.getReportInfo(true).then((response) => {
      this.setState({ logo: response.data.reportInfo.clinic_logo, isLoading: false });
      if (this.props.onLogoChange) {
        this.props.onLogoChange(response.data.reportInfo.clinic_logo);
      }
    });

  hasLogo() {
    return this.state.logo && this.state.logo !== 'NO_LOGO';
  }

  renderImage() {
    if (this.state.isLoading) {
      return (
        <div
          style={{
            height: '120px',
            width: '25vw',
            textAlign: 'center',
            padding: '10px',
            color: 'rgb(100,100,100)',
          }}
        >
          <div style={{ padding: '5px' }}>
            <Icon name="cog" loading size="huge" />
          </div>
          <div style={{ marginTop: '15px' }}>{this.formatMessage({ id: 'general.loading' })}</div>
        </div>
      );
    }
    if (this.hasLogo()) {
      return <img src={this.state.logo.base64} style={{ width: 'fit-content' }} />;
    }
    return (
      <div
        className="ui placeholder placeholderForImage"
        style={{ height: '100px', width: '25vw' }}
      >
        <span style={{ position: 'relative', top: '30%', color: 'rgb(100,100,100)' }}>
          <FormattedMessage id="clinicLogo.clickOrDragToAdd" />
        </span>
      </div>
    );
  }

  openImageBrowser = () => {
    this.refs.importLogoInput.click();
  };

  onDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const firstImageOnly = 0;
    const imageFile = event.dataTransfer.items[firstImageOnly].getAsFile();
    return this.imageFileToBase64(imageFile).then(this.uploadImage);
  };

  onFileBrowserImageSelection = () => {
    const firstImageOnly = 0;
    const imageFile = this.refs.importLogoInput.files[firstImageOnly];
    return this.imageFileToBase64(imageFile).then(this.uploadImage);
  };

  toBase64SetState = (imageFile) =>
    this.imageFileToBase64(imageFile).then((base64Image) => {
      this.setState({ base64Image });
    });

  imageFileToBase64 = (imageFile) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  uploadImage = (base64Image) => {
    this.setState({ isLoading: true });
    return ApiCalls.setClinicLogo(base64Image).then(() => {
      this.getLogoFromServer();
    });
  };

  removeLogo = (event) => {
    event.preventDefault();
    ApiCalls.removeClinicLogo().then(() => {
      this.setState({ isLoading: true });
      this.getLogoFromServer();
    });
  };

  backToReportButton = () => {
    if (this.props.showExitButton) {
      return (
        <Button onClick={(event) => this.props.onExit(event, this.state.logo)}>
          {this.formatMessage({ id: 'clinicLogo.backToReport' })}
        </Button>
      );
    }
  };

  render() {
    return (
      <div>
        <Card
          style={{ padding: '10px', width: 'fit-content' }}
          id="logoCard"
          onClick={this.openImageBrowser}
          onDrop={this.onDrop}
        >
          {this.renderImage()}
        </Card>
        <input
          ref="importLogoInput"
          type="file"
          style={{ display: 'none' }}
          onChange={this.onFileBrowserImageSelection}
          accept=".jpg,.JPG,.png,.PNG,.JPEG,.jpeg"
        />
        <Button style={{ display: this.hasLogo() ? '' : 'None' }} onClick={this.removeLogo}>
          {this.formatMessage({ id: 'clinicLogo.doNotUseALogo' })}
        </Button>
        {this.backToReportButton()}
      </div>
    );
  }
}

ClinicLogoForm.defaultProps = {
  requestSelfTimeout: 0,
  showExitButton: false,
  onExit: null,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClinicLogoForm));
