import makeChainablePromise, { ChainablePromise } from 'app/utils/async/ChainablePromise';
import _ from 'lodash';

export type KeyedChainablePromise = {
  chainPromise: <R>(nextTask: () => Promise<R>, key: string) => Promise<R>;
  cancel: () => void;
};

function makeKeyedChainablePromises() {
  const ctx = {
    chainablePromises: {} as { [key: string]: ChainablePromise },
    isCanceled: false,
  };

  const chainPromise = async <R>(nextTask: () => Promise<R>, key: string) => {
    if (ctx.isCanceled) return undefined;
    ctx.chainablePromises[key] ??= makeChainablePromise();
    return ctx.chainablePromises[key].chainPromise(nextTask);
  };

  const cancel = () => {
    ctx.isCanceled = true;
    _.map(ctx.chainablePromises, (promise) => promise.cancel());
  };

  return { chainPromise, cancel };
}

export default makeKeyedChainablePromises;
