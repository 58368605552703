import React from 'react';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';

import { ReactComponent as PicoxiaNoAnalysis } from '../../../static/svg_icons/picoxia_icon_wide_white_only.svg';
import { ReactComponent as PicoxiaAnalysis } from '../../../static/svg_icons/picoxia_icon_wide_white.svg';

class PicoxiaAnalysisButton extends React.PureComponent {
  render() {
    const { isImageModified, onClick, intl } = this.props;

    return (
      <button
        type="button"
        className={`picoxia tool-button picoxia-analysis-button ${isImageModified && 'modified'}`}
        onClick={onClick}
        title={intl.formatMessage({ id: 'tools.picoxia_analysis.tooltip' })}
      >
        <i>
          {isImageModified && <PicoxiaAnalysis />}
          {!isImageModified && <PicoxiaNoAnalysis />}
        </i>
      </button>
    );
  }
}

PicoxiaAnalysisButton.propTypes = {
  intl: pt.shape().isRequired,
  isImageModified: pt.bool,
  onClick: pt.func,
};

PicoxiaAnalysisButton.defaultProps = {
  isImageModified: false,
  onClick: () => {},
};

export default injectIntl(PicoxiaAnalysisButton);
