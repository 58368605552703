/* eslint-disable no-underscore-dangle */
import {
  EVENT_GENERATOR_EXPOSURE_STATE,
  EVENT_GENERATOR_PREP_STATE,
  EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
  EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE,
  EVENT_GENERATOR_STATUS,
  EVENT_GENERATOR_THICKNESS,
  EVENT_SW_VER,
  OPERATING_MODE,
  READY_STATUS,
  STATE_CHANGE,
  UNIT_STATUS,
} from 'app/xray/generator/constants';
import _ from 'lodash';

const toRadParameters = (radParameters) => ({
  ...radParameters,
  mAs: (radParameters.mA * radParameters.ms) / 1000,
});

export default class InMemoryXRayGeneratorController extends EventTarget {
  constructor(handle) {
    super();

    this.handle = handle;
    this.operatingMode = OPERATING_MODE.SLEEPING;
    this.unitStatus = UNIT_STATUS.SLEEPING_MODE;
    this.isLocked = true;
    this.radParameters = { kV: 0, mAs: 0, mA: 0, ms: 0, focus: 0 };
    this.thickness = 0;

    this._sendEvent(EVENT_SW_VER, { version: 3, major: 2, minor: 1 });

    window.inMemoryXRayGeneratorController = this;
  }

  close = async () => {
    this.handle?.close();
  };

  init = async () => this._sendEvent(EVENT_SW_VER, { version: 3, major: 2, minor: 1 });

  /**
   * This message is used for a keep alive mechanism. The imaging system will send
   * this message periodically at 4/5 sec interval.
   * @returns {void}
   */
  requestStatus = async () => {
    let readyStatus = READY_STATUS.BUSY;
    if (!this.isLocked && this.unitStatus === UNIT_STATUS.STANDBY_MODE) {
      readyStatus = READY_STATUS.READY;
    }

    this._sendEvent(EVENT_GENERATOR_STATUS, {
      unitStatus: this.unitStatus,
      readyStatus,
    });
  };

  /**
   * This message is used to lock or unlock the generator.
   * The generator need to be locked if the imaging system is not in image acquisition mode.
   * @param {boolean} isLocked
   */
  lock = async (isLocked) => {
    this.isLocked = isLocked;
  };

  /**
   * his message is used to change the state of the generator.
   * @param {OPERATING_MODE.SLEEPING|OPERATING_MODE.STANDBY|OPERATING_MODE.SHUTDOWN} mode
   */
  changeOperatingMode = async (mode) => {
    this.operatingMode = mode;
    if (
      this.unitStatus !== UNIT_STATUS.SYSTEM_ERROR &&
      this.unitStatus !== UNIT_STATUS.SHUTDOWN_IN_PROGRESS
    ) {
      if (mode === OPERATING_MODE.SHUTDOWN) {
        this.unitStatus = UNIT_STATUS.SHUTDOWN_IN_PROGRESS;
      } else if (mode === OPERATING_MODE.SLEEPING) {
        this.unitStatus = UNIT_STATUS.SLEEPING_MODE;
      } else if (mode === OPERATING_MODE.STANDBY) {
        this.unitStatus = UNIT_STATUS.STANDBY_MODE;
      }
    }
  };

  /**
   * This message is used to set a complete set of exposure values e.g. if an organ is chosen.
   * @param {RadParameters} radParameters
   * @param {Number} mAs    This property is ignored and automatically inferred from mA and mS
   * @param {Number} focus  Automatically inferred from mA
   */
  setRadParameters = async (radParameters) => {
    this.radParameters = { ...radParameters, focus: radParameters.mA > 75 ? 1 : 0 };
    this._sendEvent(EVENT_GENERATOR_RAD_PARAMETERS_CHANGE, toRadParameters(this.radParameters));
  };

  /**
   * This message is used to set a single radiological parameter (kV, mA, ms).
   * This can be the case if a single radiological parameter is adjusted by the user
   * after choosing an organ.
   * @param {"kV"|"mAs"|"mA"|"ms"} parameterType
   * @param {boolean} shouldIncrement
   */
  updateRadParameter = async (parameterType, shouldIncrement) => {
    this.radParameters[parameterType] += shouldIncrement ? 1 : 0;
  };

  /**
   * This message is sent by the imaging system and is used as a confirmation
   * for the generator to start the x-ray preparation.
   * @param {boolean} allowed
   */
  prepConfirmation = async (allowed) => {
    if (!allowed) return;
    this.unitStatus = UNIT_STATUS.FIRST_TIME;
  };

  /**
   * In case the practician pressed the `prep switch` too soon the prep state can be canceled
   * @returns {void}
   */
  cancelPreparation = async () => {
    this.changeOperatingMode(OPERATING_MODE.STANDBY);
  };

  /**
   * This message is sent by the imaging system and is used as a confirmation
   * for the generator to start exposure.
   * The value “Rad not allowed” will be sent periodically until imaging software is ready.
   * If the imaging software is ready the value “Rad allowed” will be sent.
   * @param {boolean} allowed
   */
  startXRay = async (allowed) => {
    if (!allowed) return;
    this.unitStatus = UNIT_STATUS.XRAY_IN_PROGRESS;

    setTimeout(
      () =>
        this._sendEvent(EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE, {
          ...toRadParameters(this.radParameters),
          dose: 301,
        }),
      1000
    );

    setTimeout(() => {
      this.unitStatus = UNIT_STATUS.END_OF_XRAY;
    }, 2000);
    setTimeout(() => {
      this.unitStatus = UNIT_STATUS.STANDBY_MODE;
    }, 4000);
  };

  /**
   * This message is used to determine the radio parameters according to the thickness of the
   * animal.
   * In the visual menu of X-ray parameters, the software (aqs) inquiries repeatedly as
   * often it could be done the thickness, even the case it has already received a value.
   * @returns {void}
   */
  requestThickness = async () =>
    this._sendEvent(EVENT_GENERATOR_THICKNESS, { thickness: this.thickness * 10 });

  /**
   * This message is used by the imaging system to reset the warning state of the generator.
   * @returns {void}
   */
  resetWarning = () => {
    this.unitStatus = UNIT_STATUS.SLEEPING_MODE;
  };

  shutdown = () => {
    this.unitStatus = UNIT_STATUS.SHUTDOWN_IN_PROGRESS;
  };

  /**
   * @param {EVENT_SW_VER|EVENT_GENERATOR_STATUS|EVENT_GENERATOR_RAD_PARAMETERS_CHANGE|
   * EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE|EVENT_GENERATOR_PREP_STATE|
   * EVENT_GENERATOR_EXPOSURE_STATE|EVENT_GENERATOR_THICKNESS|EVENT_GENERATOR_ERROR|
   * EVENT_GENERATOR_WARNING} eventName
   * @param {(evt:{target:XRayGeneratorController, eventName:string} & (
   * GeneratorSoftwareVersion|RadParameters|PostExposureRadParameters|GeneratorThickness|
   * GeneratorOperativeStatus|GeneratorStateChange|GeneratorError|GeneratorWarning
   * )) => void} callback
   */
  on = (eventName, callback) => this.addEventListener(eventName, callback);

  /* Below are demo only functions not found on real Controller */

  _sendEvent = async (eventName, payload) => {
    await undefined;
    this.dispatchEvent(_.merge(new Event(eventName), payload));
  };

  triggerVirtualPedal = async () => {
    if (this.unitStatus === UNIT_STATUS.STANDBY_MODE) {
      this._sendEvent(EVENT_GENERATOR_PREP_STATE, {
        status: STATE_CHANGE.ACTIVE,
      });
    }
    if (this.unitStatus === UNIT_STATUS.FIRST_TIME) {
      this._sendEvent(EVENT_GENERATOR_EXPOSURE_STATE, {
        status: STATE_CHANGE.ACTIVE,
      });
      setTimeout(() => {
        this._sendEvent(EVENT_GENERATOR_EXPOSURE_STATE, {
          status: STATE_CHANGE.DISACTIVE,
        });
      }, 1000);
    }
  };
}
