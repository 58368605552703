import * as _ from 'lodash';
import * as csc from 'cornerstone-core';
/**
 * Check if the supplied parameter is undefined or null and throws and error
 * @param {any} checkParam the parameter to validate for undefined
 * @param {any} errorMsg the error message to be thrown
 * @returns {void}
 * @memberof internal
 */
function validateParameterUndefinedOrNull(checkParam, errorMsg) {
  if (checkParam === undefined || checkParam === null) {
    throw new Error(errorMsg);
  }
}

/**
 * Check if the angle is rotated
 * @param {Number} rotation the rotation angle
 * @returns {Boolean} true if the angle is rotated; Otherwise, false.
 * @memberof Internal
 */
function isRotated(rotation) {
  return !(rotation === null || rotation === undefined || rotation % 180 === 0);
}

/**
 * Retrieves the current image dimensions given an enabled element
 *
 * @param {any} image The Cornerstone image.
 * @param {Number} rotation Optional. The rotation angle of the image.
 * @return {{width:Number, height:Number}} The Image dimensions
 * @memberof Internal
 */
function getImageSize(image, rotation = null) {
  validateParameterUndefinedOrNull(image, 'getImageSize: parameter image must not be undefined');
  validateParameterUndefinedOrNull(image.width, 'getImageSize: parameter image must have width');
  validateParameterUndefinedOrNull(image.height, 'getImageSize: parameter image must have height');

  if (isRotated(rotation)) {
    return {
      height: image.width,
      width: image.height,
    };
  }

  return {
    width: image.width,
    height: image.height,
  };
}

export function getViewportDimensions(viewport) {
  const width = viewport?.displayedArea?.brhc?.x;
  const height = viewport?.displayedArea?.brhc?.y;
  return { width, height };
}

/**
 * Calculates the horizontal, vertical and minimum scale factor for an image
   @param {{width, height}} windowSize The window size where the image is displayed. This can be any HTML element or structure with a width, height fields (e.g. canvas).
 * @param {any} image The cornerstone image object
 * @param {Number} rotation Optional. The rotation angle of the image.
 * @return {{horizontalScale, verticalScale, scaleFactor}} The calculated horizontal, vertical and minimum scale factor
 * @memberof Internal
 */
function getImageFitScale(windowSize, image, rotation = null) {
  validateParameterUndefinedOrNull(
    windowSize,
    'getImageScale: parameter windowSize must not be undefined'
  );
  validateParameterUndefinedOrNull(image, 'getImageScale: parameter image must not be undefined');

  const imageSize = getImageSize(image, rotation);
  const rowPixelSpacing = image.rowPixelSpacing || 1;
  const columnPixelSpacing = image.columnPixelSpacing || 1;
  let verticalRatio = 1;
  let horizontalRatio = 1;

  if (rowPixelSpacing < columnPixelSpacing) {
    horizontalRatio = columnPixelSpacing / rowPixelSpacing;
  } else {
    // even if they are equal we want to calculate this ratio (the ration might be 0.5)
    verticalRatio = rowPixelSpacing / columnPixelSpacing;
  }

  const verticalScale = windowSize.height / imageSize.height / verticalRatio;
  const horizontalScale = windowSize.width / imageSize.width / horizontalRatio;

  // Fit image to window
  return {
    verticalScale,
    horizontalScale,
    scaleFactor: Math.min(horizontalScale, verticalScale),
  };
}

function getViewportScaleRatio(viewport) {
  const { brhc } = viewport?.displayedArea || {};
  const rowPixelSpacing = brhc?.rowPixelSpacing || 1;
  const columnPixelSpacing = brhc?.columnPixelSpacing || 1;
  let verticalRatio = 1;
  let horizontalRatio = 1;

  if (rowPixelSpacing < columnPixelSpacing) {
    horizontalRatio = columnPixelSpacing / rowPixelSpacing;
  } else {
    // even if they are equal we want to calculate this ratio (the ration might be 0.5)
    verticalRatio = rowPixelSpacing / columnPixelSpacing;
  }
  return { horizontalRatio, verticalRatio };
}

const getViewportPixelRect = (viewport) => {
  const viewportScaleRatio = getViewportScaleRatio(viewport);
  const width = viewport?.displayedArea?.brhc?.x;
  const height = viewport?.displayedArea?.brhc?.y;
  if (!width || !height) return undefined;

  return [width * viewportScaleRatio.horizontalRatio, height * viewportScaleRatio.verticalRatio];
};

const computeScaleFactor = (rect1, rect2) => {
  if (!rect1 || !rect2) return { verticalScale: 1, horizontalScale: 1, scaleFactor: 1 };
  const verticalScale = rect2[1] / rect1[1];
  const horizontalScale = rect2[0] / rect1[0];

  return { verticalScale, horizontalScale, scaleFactor: Math.min(horizontalScale, verticalScale) };
};

const computeScaleFactorFromStoredViewport = (storedViewport, image) =>
  computeScaleFactor(getViewportPixelRect(storedViewport), [image.width, image.height]);

/**
 * Restore rotations, color inversion, flips and windowWidth/Center from old viewport into the new one.
 */
function mergeViewports(oldViewport, newViewport) {
  // Fit old viewport into new viewport
  const viewport = _.merge({}, newViewport, {
    vflip: oldViewport.vflip,
    hflip: oldViewport.hflip,
    invert: oldViewport.invert,
    rotation: oldViewport.rotation,
  });

  // Image saved on server are created from displayed dicom image with
  // initial voi applied this makes it impossible to restore dicom voi.
  const { windowWidth, windowCenter } = oldViewport?.voi ?? {};
  const areViewportCompatible = windowCenter > 255 === viewport.voi.windowCenter > 255;
  if (areViewportCompatible && windowWidth && windowCenter) {
    viewport.voi = { windowWidth, windowCenter };
  }
  return viewport;
}

const getBytesPerElement = (image) => {
  const { rgba, color, width, height, sizeInBytes } = image;
  if (rgba || color) return 1;
  return Math.round(sizeInBytes / width / height);
};

export const getMatchingResolutionViewport = (viewportWithResolutions, image) => {
  const bytesPerElement = getBytesPerElement(image);
  const restoredViewport = { ...viewportWithResolutions };
  const matchingVoiResolutionKey = bytesPerElement === 1 ? 'uint8Voi' : 'uint16Voi';

  if (restoredViewport[matchingVoiResolutionKey]) {
    restoredViewport.voi = restoredViewport[matchingVoiResolutionKey];
  } else {
    restoredViewport[matchingVoiResolutionKey] = {
      windowCenter: image.windowCenter,
      windowWidth: image.windowWidth,
    };
    restoredViewport.voi = restoredViewport[matchingVoiResolutionKey];
  }

  return restoredViewport;
};

const restoreViewport = (initialViewport, element) => {
  const { image, viewport } = csc.getEnabledElement(element);
  const bytesPerElement = getBytesPerElement(image);
  const restoredViewport = { ...initialViewport };
  if (bytesPerElement === 1 && restoredViewport.uint8Voi) {
    restoredViewport.voi = restoredViewport.uint8Voi;
  } else if (bytesPerElement === 2 && restoredViewport.uint16Voi) {
    restoredViewport.voi = restoredViewport.uint16Voi;
  }
  return mergeViewports(restoredViewport, viewport);
};

const getMinMaxValues = (element) => {
  let image;
  try {
    ({ image } = csc.getEnabledElement(element));
  } catch {
    return undefined;
  }

  if (!image) return undefined;

  const { minPixelValue, maxPixelValue } = image;
  const bytesPerElement = getBytesPerElement(image);
  if (bytesPerElement === 1) {
    return {
      minPixelValueUint8: minPixelValue,
      maxPixelValueUint8: maxPixelValue,
    };
  }
  if (bytesPerElement === 2) {
    return {
      minPixelValueUint16: minPixelValue,
      maxPixelValueUint16: maxPixelValue,
    };
  }
  return undefined;
};

export const injectImageDimensionsInViewport = (viewport, image) =>
  // This is to comply with a deprecated usage of cornerstone we are using.
  // A better way would be to same image dimensions separatly instead of recreating this deprecated structure.
  ({
    ...viewport,
    displayedArea: {
      tlhc: {
        x: 1,
        y: 1,
      },
      brhc: {
        x: image.width,
        y: image.height,
      },
      rowPixelSpacing: image.rowPixelSpacing ?? 1,
      columnPixelSpacing: image.columnPixelSpacing ?? 1,
      presentationSizeMode: 'NONE',
    },
  });

export const injectImageInViewport = (viewport, image) => {
  try {
    const { rgba, color, width, height, sizeInBytes } = image;
    let bytesPerElement;
    if (rgba || color) bytesPerElement = 1;
    else {
      bytesPerElement = Math.round(sizeInBytes / width / height);
    }

    // This is to comply with a deprecated usage of cornerstone we are using.
    // A better way would be to same image dimensions separatly instead of recreating this deprecated structure.
    const viewportToSave = injectImageDimensionsInViewport(viewport, image);

    if (bytesPerElement === 1) viewportToSave.uint8Voi = viewport.voi;
    if (bytesPerElement === 2) viewportToSave.uint16Voi = viewport.voi;
    return viewportToSave;
  } catch {
    return undefined;
  }
};

const getViewportToSave = (element) => {
  try {
    const { image, viewport } = csc.getEnabledElement(element);
    return _.omitBy(injectImageInViewport(viewport, image), _.isUndefined);
  } catch {
    return undefined;
  }
};

const pointRescaler =
  (scaleFactor, { x = 0, y = 0 } = {}) =>
  (value, key) => {
    if (key === 'x') return (value + x) * scaleFactor;
    if (key === 'y') return (value + y) * scaleFactor;
    return undefined;
  };

export const rescaleAnnotationsPoints = (annotations, scaleFactor = 1, translation = undefined) =>
  _.cloneDeepWith(annotations, pointRescaler(scaleFactor, translation));

export {
  isRotated,
  getImageSize,
  getImageFitScale,
  getViewportPixelRect,
  computeScaleFactor,
  mergeViewports,
  restoreViewport,
  getViewportToSave,
  getMinMaxValues,
  computeScaleFactorFromStoredViewport,
};
