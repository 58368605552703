import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { makeSelectUsername, makeSelectPassword, makeLoginError } from './selectors';
import styled from 'styled-components';
import ApiCalls from '../../utils/apiCalls';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { REQUEST_ERROR, CLEAR_ERROR } from '../../redux/global/constants';
import { loginRequest, clearError } from '../../redux/global/actions';
import { Button, Card, Form, Grid, Icon } from 'semantic-ui-react';
import queryString from 'query-string';
import FormattedServerError, { errorType } from '../../components/FormattedServerError';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import './styles.scss';

const Error = styled.h4`
  color: #cc0000;
  text-align: center;
`;
const Success = styled.h4`
  color: green;
  text-align: center;
`;

export class LoginPage extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    const queryStringParsed = queryString.parse(window.location.search);
    const sessionId = queryStringParsed.id;
    (this.sessionId = sessionId),
      (this.state = {
        mail: '',
        password: '',
        resetPasswordMailSent: false,
        displayResetMailInstructions: false,
      });
  }

  componentDidMount() {
    this.prefillMailWithToken();
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  prefillMailWithToken = () => {
    const queryStringParsed = queryString.parse(window.location.search);
    const token = queryStringParsed.token;
    if (token) {
      ApiCalls.getMail(token).then((response) => {
        console.log(response.data.mail);
        this.setState({ mail: response.data.mail });
      });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.displayResetMailInstructions) {
      this.sendResetPasswordMail();
    } else {
      this.login();
    }
  };

  login = () => {
    let redirectRoute = '/';
    this.setState({ resetPasswordMailSent: false });
    if (this.sessionId) {
      redirectRoute = '/link?id=' + this.sessionId;
    }
    this.props.dispatch(
      loginRequest({
        username: this.state.mail,
        password: this.state.password,
        redirectRoute: redirectRoute,
      })
    );
  };

  handleChange = (event, fieldName) => {
    let stateUpdate = {};
    this.props.dispatch(clearError());
    stateUpdate[fieldName] = event.target.value;
    this.setState(stateUpdate);
  };

  allStatesNotNull = (stateAttributeNames) => {
    return !stateAttributeNames.some((stateAttributeName) => !this.state[stateAttributeName]);
  };

  isFormFilled = () => {
    if (this.state.displayResetMailInstructions) {
      return this.allStatesNotNull(['mail']);
    } else {
      return this.allStatesNotNull(['mail', 'password']);
    }
  };

  sendResetPasswordMail = () => {
    ApiCalls.sendResetPasswordMail({ username: this.state.mail })
      .then(() => {
        this.props.dispatch({ type: CLEAR_ERROR });
        this.setState({ resetPasswordMailSent: true });
        setTimeout(function () {
          browserHistory.push('/');
        }, 4000);
      })
      .catch((error) => {
        this.props.dispatch({ type: REQUEST_ERROR, error });
      });
  };

  onPasswordForgottenClick = () => {
    this.setState({ displayResetMailInstructions: true });
  };

  onLoginClick = () => {
    this.setState({ displayResetMailInstructions: false });
  };

  renderResetPassword = () => {
    const { displayResetMailInstructions } = this.state;
    return (
      <>
        {!displayResetMailInstructions && (
          <a className="grayLink" onClick={this.onPasswordForgottenClick}>
            <FormattedMessage id="login.forgottenPassword" />
          </a>
        )}
        {displayResetMailInstructions && (
          <a className="grayLink" onClick={this.onLoginClick}>
            <FormattedMessage id="login.login" />
          </a>
        )}
        <br />
        <IntlCrawlableLink url="/register" className="grayLink">
          <FormattedMessage id="login.register" />
        </IntlCrawlableLink>
      </>
    );
  };

  displayResetMailInstructions = () => {
    const { displayResetMailInstructions } = this.state;
    if (displayResetMailInstructions) {
      return (
        <b style={{ fontSize: '1em', textAlign: 'center', marginBottom: '10px' }}>
          <FormattedMessage id="login.pleaseEnterYourMail" />
          <br />
          <FormattedMessage id="login.forgottenPasswordProcedure" />
          <br />
          &nbsp;
        </b>
      );
    }
    return null;
  };

  render() {
    const { mail, displayResetMailInstructions, resetPasswordMailSent } = this.state;
    return (
      <div className="login-page">
        <Helmet
          title={this.formatMessage({ id: 'login.pageTitle' })}
          meta={[
            { name: 'description', content: this.formatMessage({ id: 'login.pageDescription' }) },
          ]}
        />

        <Grid stackable centered columns={3}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <FormattedMessage id="login.title" />
                </Card.Header>
              </Card.Content>
              <Card.Content>
                {this.props.loginError && (
                  <Error>
                    <FormattedServerError error={this.props.loginError} />
                  </Error>
                )}
                {resetPasswordMailSent && (
                  <Success>
                    <FormattedMessage id="login.resetPasswordMailSent" />
                  </Success>
                )}
                <Card.Description>{this.displayResetMailInstructions()}</Card.Description>

                <Form>
                  <Form.Field>
                    <label style={{ display: displayResetMailInstructions ? 'None' : '' }}>
                      <FormattedMessage id="login.emailPassword" />
                    </label>
                    <Form.Input
                      type="email"
                      name="mail"
                      value={mail}
                      placeholder={this.formatMessage({ id: 'forms.email' })}
                      onChange={(event) => this.handleChange(event, 'mail')}
                      iconPosition="left"
                    >
                      <Icon name="at" />
                      <input />
                    </Form.Input>
                  </Form.Field>
                  <Form.Field style={{ display: displayResetMailInstructions ? 'None' : '' }}>
                    <Form.Input
                      type="password"
                      name="password"
                      placeholder={this.formatMessage({ id: 'forms.password' })}
                      onChange={(event) => this.handleChange(event, 'password')}
                      iconPosition="left"
                    >
                      <Icon name="lock" />
                      <input />
                    </Form.Input>
                  </Form.Field>
                  <Button
                    fluid
                    className={this.isFormFilled() ? '' : 'disabled'}
                    type="submit"
                    style={{ marginTop: '10px' }}
                    onClick={this.onSubmit}
                    primary
                  >
                    {this.formatMessage({
                      id: displayResetMailInstructions ? 'forms.send' : 'login.login',
                    })}
                  </Button>
                </Form>
              </Card.Content>
              <Card.Content extra>{this.renderResetPassword()}</Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loginError: PropTypes.shape(errorType),
};

LoginPage.defaultProps = {
  loginError: null,
};

const mapStateToProps = createStructuredSelector({
  username: makeSelectUsername(),
  password: makeSelectPassword(),
  loginError: makeLoginError(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginPage));
