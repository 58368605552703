import { DetectorManager } from 'app/types/xray';
import EventEmitter from 'events';

export default class InMemoryFlatPanelDetectorManager extends EventEmitter {
  constructor(initialDetector = undefined) {
    super();
    this.detectors = {};
    this.on(DetectorManager.EVENTS.NEW_DETECTOR, (newDetector) => {
      this.detectors[newDetector.getDetectorID().toString()] = newDetector;
    });
    this.on(DetectorManager.EVENTS.DETECTOR_REMOVED, (detectorID) => {
      delete this.detectors[detectorID.toString()];
    });

    if (initialDetector) {
      setTimeout(() => this.addDemoDetector(initialDetector), 1000);
    }
  }

  setDetectorImplementation(implementation) {
    this.detectorImplementation = implementation;
  }

  destroy = () => {};

  scanOnce = () => {};

  startScan = () => {};

  stopScan = () => {};

  getDetectors = () => this.detectors;

  /* Demo/Test only methods */
  addDemoDetector = (detector) => {
    this.emit(DetectorManager.EVENTS.NEW_DETECTOR, detector);
  };

  removeDetector = (detectorID) => {
    this.emit(DetectorManager.EVENTS.DETECTOR_REMOVED, detectorID);
  };
}
