export const MAX_THICKNESS = 40;
export const MIN_THICKNESS = 1;
export const MIN_KV = 40;
export const MAX_KV = 120;

const ACQUISITION_CONSTANTS = {
  default: [
    { thickness: 1, kV: 52, mA: 75, s: 0.084 },
    { thickness: 2, kV: 54, mA: 75, s: 0.084 },
    { thickness: 3, kV: 56, mA: 75, s: 0.084 },
    { thickness: 4, kV: 58, mA: 75, s: 0.084 },
    { thickness: 5, kV: 60, mA: 75, s: 0.084 },
    { thickness: 6, kV: 62, mA: 75, s: 0.084 },
    { thickness: 7, kV: 64, mA: 75, s: 0.084 },
    { thickness: 8, kV: 66, mA: 75, s: 0.084 },
    { thickness: 9, kV: 68, mA: 75, s: 0.084 },
    { thickness: 10, kV: 70, mA: 300, s: 0.027 },
    { thickness: 11, kV: 72, mA: 300, s: 0.027 },
    { thickness: 12, kV: 74, mA: 300, s: 0.027 },
    { thickness: 13, kV: 76, mA: 300, s: 0.027 },
    { thickness: 14, kV: 78, mA: 300, s: 0.027 },
    { thickness: 15, kV: 80, mA: 300, s: 0.034 },
    { thickness: 16, kV: 82, mA: 300, s: 0.034 },
    { thickness: 17, kV: 84, mA: 300, s: 0.034 },
    { thickness: 18, kV: 86, mA: 300, s: 0.034 },
    { thickness: 19, kV: 88, mA: 300, s: 0.034 },
    { thickness: 20, kV: 90, mA: 250, s: 0.05 },
    { thickness: 21, kV: 92, mA: 250, s: 0.05 },
    { thickness: 22, kV: 94, mA: 250, s: 0.05 },
    { thickness: 23, kV: 96, mA: 250, s: 0.05 },
    { thickness: 24, kV: 98, mA: 250, s: 0.05 },
    { thickness: 25, kV: 100, mA: 250, s: 0.064 },
    { thickness: 26, kV: 102, mA: 250, s: 0.064 },
    { thickness: 27, kV: 104, mA: 250, s: 0.064 },
    { thickness: 28, kV: 106, mA: 250, s: 0.064 },
    { thickness: 29, kV: 108, mA: 250, s: 0.064 },
    { thickness: 30, kV: 110, mA: 250, s: 0.064 },
    { thickness: 31, kV: 112, mA: 200, s: 0.09 },
    { thickness: 32, kV: 114, mA: 200, s: 0.09 },
    { thickness: 33, kV: 116, mA: 200, s: 0.09 },
    { thickness: 34, kV: 118, mA: 200, s: 0.09 },
    { thickness: 35, kV: 120, mA: 200, s: 0.09 },
    { thickness: 36, kV: 120, mA: 200, s: 0.09 },
    { thickness: 37, kV: 120, mA: 200, s: 0.09 },
    { thickness: 38, kV: 120, mA: 200, s: 0.09 },
    { thickness: 39, kV: 120, mA: 200, s: 0.09 },
    { thickness: 40, kV: 120, mA: 200, s: 0.09 },
  ],
  thorax: [
    { thickness: 1, kV: 52, mA: 250, s: 0.016 },
    { thickness: 2, kV: 54, mA: 250, s: 0.016 },
    { thickness: 3, kV: 56, mA: 250, s: 0.016 },
    { thickness: 4, kV: 58, mA: 250, s: 0.016 },
    { thickness: 5, kV: 60, mA: 250, s: 0.016 },
    { thickness: 6, kV: 62, mA: 250, s: 0.016 },
    { thickness: 7, kV: 64, mA: 250, s: 0.016 },
    { thickness: 8, kV: 66, mA: 250, s: 0.016 },
    { thickness: 9, kV: 68, mA: 250, s: 0.016 },
    { thickness: 10, kV: 70, mA: 250, s: 0.016 },
    { thickness: 11, kV: 72, mA: 250, s: 0.016 },
    { thickness: 12, kV: 74, mA: 250, s: 0.016 },
    { thickness: 13, kV: 76, mA: 250, s: 0.016 },
    { thickness: 14, kV: 78, mA: 250, s: 0.016 },
    { thickness: 15, kV: 80, mA: 250, s: 0.02 },
    { thickness: 16, kV: 82, mA: 250, s: 0.02 },
    { thickness: 17, kV: 84, mA: 250, s: 0.02 },
    { thickness: 18, kV: 86, mA: 250, s: 0.02 },
    { thickness: 19, kV: 88, mA: 250, s: 0.02 },
    { thickness: 20, kV: 90, mA: 250, s: 0.02 },
    { thickness: 21, kV: 92, mA: 250, s: 0.02 },
    { thickness: 22, kV: 94, mA: 250, s: 0.02 },
    { thickness: 23, kV: 96, mA: 250, s: 0.02 },
    { thickness: 24, kV: 98, mA: 250, s: 0.02 },
    { thickness: 25, kV: 100, mA: 250, s: 0.026 },
    { thickness: 26, kV: 102, mA: 250, s: 0.026 },
    { thickness: 27, kV: 104, mA: 250, s: 0.026 },
    { thickness: 28, kV: 106, mA: 250, s: 0.026 },
    { thickness: 29, kV: 108, mA: 250, s: 0.026 },
    { thickness: 30, kV: 110, mA: 200, s: 0.032 },
    { thickness: 31, kV: 112, mA: 200, s: 0.035 },
    { thickness: 32, kV: 114, mA: 200, s: 0.035 },
    { thickness: 33, kV: 116, mA: 200, s: 0.035 },
    { thickness: 34, kV: 118, mA: 200, s: 0.035 },
    { thickness: 35, kV: 120, mA: 200, s: 0.035 },
    { thickness: 36, kV: 120, mA: 200, s: 0.035 },
    { thickness: 37, kV: 120, mA: 200, s: 0.035 },
    { thickness: 38, kV: 120, mA: 200, s: 0.035 },
    { thickness: 39, kV: 120, mA: 200, s: 0.035 },
    { thickness: 40, kV: 120, mA: 200, s: 0.035 },
  ],
  abdomen: [
    { thickness: 1, kV: 51, mA: 300, s: 0.021 },
    { thickness: 2, kV: 52, mA: 300, s: 0.021 },
    { thickness: 3, kV: 53, mA: 300, s: 0.021 },
    { thickness: 4, kV: 54, mA: 300, s: 0.021 },
    { thickness: 5, kV: 55, mA: 300, s: 0.027 },
    { thickness: 6, kV: 56, mA: 300, s: 0.027 },
    { thickness: 7, kV: 57, mA: 300, s: 0.027 },
    { thickness: 8, kV: 58, mA: 300, s: 0.027 },
    { thickness: 9, kV: 59, mA: 300, s: 0.027 },
    { thickness: 10, kV: 60, mA: 300, s: 0.027 },
    { thickness: 11, kV: 62, mA: 300, s: 0.034 },
    { thickness: 12, kV: 64, mA: 300, s: 0.034 },
    { thickness: 13, kV: 66, mA: 300, s: 0.034 },
    { thickness: 14, kV: 68, mA: 300, s: 0.034 },
    { thickness: 15, kV: 70, mA: 300, s: 0.034 },
    { thickness: 16, kV: 72, mA: 300, s: 0.034 },
    { thickness: 17, kV: 74, mA: 300, s: 0.034 },
    { thickness: 18, kV: 76, mA: 300, s: 0.034 },
    { thickness: 19, kV: 78, mA: 300, s: 0.034 },
    { thickness: 20, kV: 80, mA: 300, s: 0.042 },
    { thickness: 21, kV: 82, mA: 300, s: 0.042 },
    { thickness: 22, kV: 84, mA: 300, s: 0.042 },
    { thickness: 23, kV: 86, mA: 300, s: 0.042 },
    { thickness: 24, kV: 88, mA: 300, s: 0.042 },
    { thickness: 25, kV: 90, mA: 300, s: 0.042 },
    { thickness: 26, kV: 92, mA: 250, s: 0.064 },
    { thickness: 27, kV: 94, mA: 250, s: 0.064 },
    { thickness: 28, kV: 96, mA: 250, s: 0.064 },
    { thickness: 29, kV: 98, mA: 250, s: 0.064 },
    { thickness: 30, kV: 100, mA: 250, s: 0.064 },
    { thickness: 31, kV: 102, mA: 250, s: 0.072 },
    { thickness: 32, kV: 104, mA: 250, s: 0.072 },
    { thickness: 33, kV: 106, mA: 250, s: 0.072 },
    { thickness: 34, kV: 108, mA: 250, s: 0.072 },
    { thickness: 35, kV: 110, mA: 250, s: 0.072 },
    { thickness: 36, kV: 110, mA: 250, s: 0.072 },
    { thickness: 37, kV: 110, mA: 250, s: 0.072 },
    { thickness: 38, kV: 110, mA: 250, s: 0.072 },
    { thickness: 39, kV: 110, mA: 250, s: 0.072 },
    { thickness: 40, kV: 110, mA: 250, s: 0.072 },
  ],
};

export default ACQUISITION_CONSTANTS;
