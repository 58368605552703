// Local Storage management

export const setLocalAIPanelMode = (mode) => {
  localStorage.setItem('AIPanelDisplayMode', mode);
};

export const getLocalHasSubscribed = () => localStorage.AIPanelDisplayMode;

export const getAIPanelMode = () => {
  const localAIPanelMode = getLocalHasSubscribed();
  if (localAIPanelMode !== undefined) {
    return localAIPanelMode;
  }
  return 'fixed';
};
