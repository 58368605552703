export type ChainablePromise = {
  chainPromise: <R>(nextTask: () => Promise<R>) => Promise<R>;
  cancel: () => void;
  getLastPromise: () => Promise<unknown>;
};

function makeChainablePromise() {
  const ctx = {
    promiseChain: Promise.resolve() as Promise<any>,
    isCanceled: false,
  };

  const chainPromise = <R>(nextTask: () => Promise<R>) => {
    const executeTaskIfNotCancelled = () => {
      if (ctx.isCanceled) return undefined;
      return nextTask();
    };
    const chainedTaskExecution = ctx.promiseChain.then(
      executeTaskIfNotCancelled,
      executeTaskIfNotCancelled
    );
    ctx.promiseChain = chainedTaskExecution;

    return chainedTaskExecution;
  };

  const cancel = () => {
    ctx.isCanceled = true;
  };

  const getLastPromise = () => ctx.promiseChain;

  return { chainPromise, cancel, getLastPromise };
}

export default makeChainablePromise;
