import React, { FC } from 'react';

import UserDisplayableError from 'app/interfaces/UserDisplayableError';
import electron from 'app/native/node/electron';
import MinDurationPromise from 'app/utils/MinDurationPromise';
import createDeferredPromise from 'app/utils/createDeferredPromise';
import _ from 'lodash';
import { useRef, useCallback, useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { WithRouterProps, withRouter } from 'react-router';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { DataLossGuardConnectorContext } from './DataLossGuardConnectorContext';

export type DataLossGuardProps = WithRouterProps & {};

type SimpleCallback = () => void;

type PromiseCallback = () => Promise<void>;

type PromiseCallbackMap = { [key: string]: PromiseCallback };

type ErrorRejectionDetail = {
  message: string;
};

const DataLossGuard: FC<DataLossGuardProps> = ({ children, router, routes }) => {
  const intl = useIntl();
  const [resetKey, setResetKey] = useState(0);
  const [saveOngoing, setSaveOngoing] = useState(false);
  const [failedSaveRequests, setFailedSaveRequests] = useState<string[]>(undefined);
  const [dataLossCallbacksMap, setDataLossCallbacksMap] = useState<PromiseCallbackMap>({});
  const saveAllDataFn = useRef<() => Promise<void>>();
  const componentClearPromise = useRef<DeferredPromise<void>>();
  const dataLossGuardConnector = useRef<DataLossGuardConnector>(
    {} as unknown as DataLossGuardConnector
  );
  const actionOnLeave = useRef<SimpleCallback>();
  const dataLossCallbacksMapRef = useRef<PromiseCallbackMap>({});
  const cleanupExitHooks = useRef<SimpleCallback>();
  const exitFn = useRef<SimpleCallback>();
  const setDataSaveCallbackDisabled = useRef<boolean>(false);

  exitFn.current = () => {
    setDataSaveCallbackDisabled.current = true;
    cleanupExitHooks.current?.();
    cleanupExitHooks.current = undefined;
    actionOnLeave.current?.();
  };

  saveAllDataFn.current = async () => {
    setSaveOngoing(true);

    const requestsResultsPromise = Promise.allSettled(
      _.map(dataLossCallbacksMapRef.current, (saveCallback, saveKey) =>
        saveCallback?.()?.catch((error) => {
          throw {
            message: error instanceof UserDisplayableError ? error.message : saveKey,
          } as ErrorRejectionDetail;
        })
      )
    );

    const requestsResults = await (failedSaveRequests
      ? MinDurationPromise(requestsResultsPromise, 1000)
      : requestsResultsPromise);

    setSaveOngoing(false);
    if (_.every(requestsResults, { status: 'fulfilled' })) {
      setFailedSaveRequests(undefined);
      exitFn.current?.();
      componentClearPromise.current?.resolve();
    } else {
      setFailedSaveRequests(
        _.reject(requestsResults, { status: 'fulfilled' }).map(
          ({ reason }: PromiseRejectedResult) => (reason as ErrorRejectionDetail).message
        )
      );
    }
  };

  const retry = useCallback(() => saveAllDataFn.current(), []);

  const cancel = useCallback(() => {
    setFailedSaveRequests(undefined);
    componentClearPromise.current?.reject();
    componentClearPromise.current = undefined;
    actionOnLeave.current = undefined;
  }, [setFailedSaveRequests]);

  const leavePage = useCallback(() => {
    setResetKey((prevResetKey) => prevResetKey + 1);
    setFailedSaveRequests(undefined);
    exitFn.current?.();
  }, [setFailedSaveRequests, setResetKey]);

  const registerExitHooksIfNeeded = (currentDataLossGuardMap: PromiseCallbackMap) => {
    if (_.every(currentDataLossGuardMap, _.isUndefined)) {
      return undefined;
    }

    const unbindHook = router?.setRouteLeaveHook(_.last(routes), (nextRoute) => {
      actionOnLeave.current = () => router.push(nextRoute);

      saveAllDataFn.current();
      return false;
    });

    const beforeUnloadCallback = (evt: BeforeUnloadEvent) => {
      evt.preventDefault();
      evt.returnValue = intl.formatMessage({ id: 'dropzone.get_out_without_save' });

      actionOnLeave.current = () => {
        // We cannot control the window as finely when using the browser.
        electron()?.remote.getCurrentWindow().close();
      };
      saveAllDataFn.current();
      return evt.returnValue;
    };
    window.addEventListener('beforeunload', beforeUnloadCallback);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      unbindHook();
    };
  };

  dataLossGuardConnector.current.setDataSaveCallback = (key, saveCallback) => {
    if (setDataSaveCallbackDisabled.current) return;

    cleanupExitHooks.current?.();
    dataLossCallbacksMapRef.current = { ...dataLossCallbacksMapRef.current, [key]: saveCallback };
    cleanupExitHooks.current = registerExitHooksIfNeeded(dataLossCallbacksMapRef.current);
    // This function seems to sometimes trigger effects in a synchronous way.
    // This can cause `registerExitHooksIfNeeded` to be called twice in a row
    // without intermediate cleanup.
    setDataLossCallbacksMap(dataLossCallbacksMapRef.current);
  };

  dataLossGuardConnector.current.clearComponentWithDataSave = async () => {
    actionOnLeave.current = () => setResetKey((prevResetKey) => prevResetKey + 1);
    componentClearPromise.current ??= createDeferredPromise();
    saveAllDataFn.current();
    return componentClearPromise.current;
  };

  const triggerExitOnLastDataSave = () => {
    if (_.some(dataLossCallbacksMap)) return;

    const isDataLossGuardActive = failedSaveRequests !== undefined;
    if (!isDataLossGuardActive) return;

    setFailedSaveRequests(undefined);
    exitFn.current?.();
    componentClearPromise.current?.resolve();
  };
  // We trigger this inside an effect instead of during the setCallback to only run final save
  // once user has cleared all callbacks.
  useEffect(triggerExitOnLastDataSave, [dataLossCallbacksMap, failedSaveRequests]);

  return (
    <DataLossGuardConnectorContext.Provider key={resetKey} value={dataLossGuardConnector.current}>
      {children}

      <Modal
        open={failedSaveRequests !== undefined}
        closeIcon
        onClose={cancel}
        centered={false}
        dimmer={
          <Modal.Dimmer
            style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            data-testid="dataloss-guard"
          />
        }
      >
        <Modal.Header>
          <FormattedMessage id="dropzone.unsaved_data_modal.header" />
        </Modal.Header>
        <Modal.Content>
          <FormattedMessage id="dropzone.unsaved_data_modal.content" />
          <br />
          <FormattedMessage id="dropzone.unsaved_data_modal.error_list" />
          <br />
          <ul>
            {failedSaveRequests?.map((saveKey) => (
              <li key={saveKey}>{saveKey}</li>
            ))}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={retry} style={saveOngoing ? { pointerEvent: 'none' } : {}}>
            {saveOngoing && <Icon name="sync" data-testid="retry-ongoing" loading />}
            <FormattedMessage id="dropzone.unsaved_data_modal.retry" />
          </Button>
          <Button onClick={leavePage} secondary>
            <FormattedMessage id="dropzone.unsaved_data_modal.leave_page" />
          </Button>
        </Modal.Actions>
      </Modal>
    </DataLossGuardConnectorContext.Provider>
  );
};

export default withRouter(DataLossGuard);
