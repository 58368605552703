import _ from 'lodash';
import './style.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { selectFlatPanelConfiguration } from 'app/redux/flatPanelConfiguration/reducer';
import {
  DetectorIPConfiguration,
  DetectorKind,
  setDetectorIPConfiguration,
} from 'app/redux/flatPanelConfiguration/actions';
import FormattedLabel from 'app/components/FormattedLabel';

const DEFAULT_THALES_IP_CONFIGURATION = {
  detectorIP: '192.168.1.3',
  hostIP: '192.168.1.5/24',
};
const DEFAULT_GER_IP_CONFIGURATION = {
  detectorIP: '192.168.8.8',
  hostIP: '192.168.8.188/24',
};

const IP_ADDRESS_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
const IP_ADDRESS_REGEX_WITH_MASK = /^(?:\d{1,3}\.){3}\d{1,3}\/(3[0-2]|[12]\d|\d)$/;

const getDefaultIPConfiguration = (detectorKind: DetectorKind): DetectorIPConfiguration => {
  switch (detectorKind) {
    case 'THALES':
      return DEFAULT_THALES_IP_CONFIGURATION;
    case 'GER':
      return DEFAULT_GER_IP_CONFIGURATION;
    default:
      return undefined;
  }
};

function errorToText(intl: IntlShape, key: string, error: FieldError) {
  if (!error) return undefined;
  return intl.formatMessage({ id: `account.configuration.flat_panel.error.${error.type}.${key}` });
}

const FlatPanelIPConfiguration: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { detectorKind, detectorIP, hostIP } = useSelector(selectFlatPanelConfiguration);
  const [ipConfigurationChanged, setIpConfigurationChanged] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DetectorIPConfiguration>({
    values: { detectorIP, hostIP },
    defaultValues: { ...getDefaultIPConfiguration(detectorKind) },
  });

  const onSubmit = (data: DetectorIPConfiguration) => {
    dispatch(setDetectorIPConfiguration(data));
    setIpConfigurationChanged(true);
  };

  return (
    <div>
      <fieldset
        className={`flat-panel-configuration__ip-configuration ${
          ipConfigurationChanged ? 'attached' : ''
        }`}
      >
        <legend>
          <FormattedMessage id="account.configuration.flat_panel.ip_configuration" />
        </legend>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="flat-panel-configuration__ip-configuration__fields">
            <Controller
              name="detectorIP"
              control={control}
              rules={{
                required: true,
                pattern: IP_ADDRESS_REGEX,
              }}
              render={({ field }) => (
                <Form.Field
                  error={errorToText(intl, 'detectorIP', errors.detectorIP)}
                  className="flat-panel-configuration__ip-configuration__fields--field"
                >
                  <Input
                    label={
                      <FormattedLabel id="account.configuration.flat_panel.detector_ip_address" />
                    }
                    id="account.configuration.flat_panel.detector_ip_address"
                    {..._.omit(field, ['ref', 'name'])}
                  />
                </Form.Field>
              )}
            />

            <Controller
              name="hostIP"
              control={control}
              rules={{
                required: true,
                pattern: IP_ADDRESS_REGEX_WITH_MASK,
              }}
              render={({ field }) => (
                <Form.Field
                  error={errorToText(intl, 'hostIP', errors.hostIP)}
                  className="flat-panel-configuration__ip-configuration__fields--field"
                >
                  <Input
                    label={<FormattedLabel id="account.configuration.flat_panel.host_ip_address" />}
                    id="account.configuration.flat_panel.host_ip_address"
                    {..._.omit(field, ['ref', 'name'])}
                  />
                </Form.Field>
              )}
            />
          </div>
          <div>
            <Button floated="right" primary type="submit">
              <FormattedMessage id="general.confirm" />
            </Button>
          </div>
        </Form>
      </fieldset>
      {ipConfigurationChanged && (
        <Message warning attached="bottom">
          <Message.Header>
            <FormattedMessage id="account.configuration.flat_panel.ip_changed_warning.title" />
          </Message.Header>
          <p>
            <FormattedMessage id="account.configuration.flat_panel.ip_changed_warning.description" />
          </p>
        </Message>
      )}
    </div>
  );
};

export default FlatPanelIPConfiguration;
