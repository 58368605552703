const CAT_THORAX_OPTIONS = {
  intensities: [0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.8, 0.9, 1.0],
  denoise_intensities: [0.25, 0.25, 0.25, 0.34, 0.34, 0.34, 0.34, 0.34, 0.34, 0.0, 0.0, 0.0],
  black_relative_steepness: 1.0,
  white_relative_steepness: 2.5,
  general_steepness: 1.4,
  centering_quantile: 70 / 100,
  need_log_rescale: true,
};

const PROCESSING_OPTIONS = {
  default: { ...CAT_THORAX_OPTIONS },
  dog_abdomen: {
    intensities: [0.6, 0.6, 0.7, 0.75, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.9, 1],
    denoise_intensities: [0.25, 0.25, 0.2, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0],
    black_relative_steepness: 1.2,
    white_relative_steepness: 1.5,
    general_steepness: 2,
    centering_quantile: 50 / 100,
    need_log_rescale: true,
  },
  cat_abdomen: {
    intensities: [0.6, 0.6, 0.7, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.9, 1.0],
    denoise_intensities: [0.25, 0.25, 0.25, 0.34, 0.34, 0.34, 0.34, 0.34, 0.34, 0.0, 0.0, 0.0],
    black_relative_steepness: 1.2,
    white_relative_steepness: 1.5,
    general_steepness: 2,
    centering_quantile: 50 / 100,
    need_log_rescale: true,
  },
  bones: {
    intensities: [0.49, 0.55, 0.6, 0.65, 0.7, 0.75, 0.75, 0.8, 0.8, 0.8, 0.9, 1.0],
    denoise_intensities: [0.5, 0.6, 0.7, 0.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    black_relative_steepness: 1.0,
    white_relative_steepness: 3.0,
    general_steepness: 1.3,
    centering_quantile: 40 / 100,
    need_log_rescale: true,
  },
  dog_skull: {
    intensities: [0.42, 0.48, 0.53, 0.63, 0.7, 0.75, 0.75, 0.8, 0.8, 0.8, 0.9, 1.0],
    denoise_intensities: [0.5, 0.6, 0.7, 0.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    black_relative_steepness: 1.3,
    white_relative_steepness: 3.0,
    general_steepness: 1.4,
    centering_quantile: 55 / 100,
    need_log_rescale: true,
  },
  dog_thorax: {
    intensities: [0.6, 0.6, 0.7, 0.7, 0.7, 0.8, 0.8, 0.8, 0.8, 0.8, 0.9, 1.0],
    denoise_intensities: [0.25, 0.0, 0.0, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    black_relative_steepness: 1.0,
    white_relative_steepness: 2.5,
    general_steepness: 1.8,
    centering_quantile: 70 / 100,
    need_log_rescale: true,
  },
  cat_thorax: { ...CAT_THORAX_OPTIONS },
};

Object.freeze(PROCESSING_OPTIONS);

export default PROCESSING_OPTIONS;
