import { fromJS } from 'immutable';
import _ from 'lodash';
import { v4 } from 'uuid';

import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';

export const DEVICE_PARAMETERS_LOCAL_STORAGE_KEY = 'redux.deviceParameters';

export const selectDeviceParameters = (state) => state.get('deviceParameters');
export const selectDeviceIdentifier = (state) =>
  selectDeviceParameters(state).get('deviceIdentifier');

export const attachDeviceParametersWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectDeviceParameters,
    makeSyncReduxWithLocalStorageObserver(DEVICE_PARAMETERS_LOCAL_STORAGE_KEY)
  );
};

const getInitialState = () => {
  const initialState = restoreFromLocalStorage(DEVICE_PARAMETERS_LOCAL_STORAGE_KEY, {});
  initialState.deviceIdentifier ??= v4();
  return fromJS(initialState);
};

const deviceParametersReducer = (state = getInitialState(), { type, payload }) => {
  return state;
  // eslint-disable-next-line no-unreachable
  switch (type) {
    default:
      return state;
  }
};

export default deviceParametersReducer;
