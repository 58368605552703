import 'app/styles/style.scss';
import './style.scss';

import React, { useState } from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Modal, Popup } from 'semantic-ui-react';

import { DEFAULT_SPECIE } from 'app/constants/species';
import ExamSelector from 'app/components/ExamSelector';
import { ReactComponent as FlatPanelIcon } from 'app/../static/svg_icons/flat_panel_detector_square.svg';

export type AddXRayViewModalProps = {
  specie: string;
  onNewAnatomicRegion: (anatomicRegion: string) => void;
};

const AddXRayViewModal = ({ specie, onNewAnatomicRegion }: AddXRayViewModalProps) => {
  const [open, setOpen] = useState(false);

  const onAnatomicRegionSelected = ({
    mainRegion,
    subRegion,
    view,
  }: {
    mainRegion: string;
    subRegion: string;
    view: string;
  }) => {
    const anatomicRegion = _.filter([specie, mainRegion, subRegion, view]).join(' ');
    onNewAnatomicRegion(anatomicRegion);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      trigger={
        <Popup
          position="right center"
          trigger={
            <button
              type="button"
              className="ui button picoxia add-acquisition"
              onClick={() => setOpen(true)}
            >
              <FlatPanelIcon />
              <span>
                <FormattedMessage id="dropzone.add_acquisition" />
              </span>
            </button>
          }
          content={<FormattedMessage id="dropzone.add_acquisition.tooltip" />}
        />
      }
      className="anatomic-region-selection-modal"
    >
      <div className="anatomic-region-selection-modal__content">
        <h2>
          <FormattedMessage id="dropzone.add_acquisition.title" />
        </h2>
        <div className="flex center">
          <ExamSelector specie={specie} onComplete={onAnatomicRegionSelected} />
        </div>
      </div>
    </Modal>
  );
};

AddXRayViewModal.propTypes = {
  specie: pt.string,
  onNewAnatomicRegion: pt.func,
};

AddXRayViewModal.defaultProps = {
  specie: DEFAULT_SPECIE,
  onNewAnatomicRegion: () => {},
};

export default React.memo(AddXRayViewModal);
