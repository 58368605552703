// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./arrow-right.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".picoxia .hook{color:rgba(0,0,0,.87);font-weight:bold;font-size:1.3rem;letter-spacing:2px}.picoxia .hook::before{content:\"\";display:inline-block;width:1.5rem;height:1rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");color:#358cca}.picoxia .hook--big{font-size:1.4rem}", "",{"version":3,"sources":["webpack://./app/components/UIComponents/Hook/style.scss"],"names":[],"mappings":"AACI,eACI,qBAAA,CACA,gBAAA,CACA,gBAAA,CACA,kBAAA,CAEA,uBACI,UAAA,CACA,oBAAA,CACA,YAAA,CACA,WAAA,CACA,wDAAA,CACA,aAAA,CAGJ,oBACI,gBAAA","sourcesContent":[".picoxia {\r\n    .hook {\r\n        color: rgba(0, 0, 0, 0.87);\r\n        font-weight: bold;\r\n        font-size: 1.3rem;\r\n        letter-spacing: 2px;\r\n    \r\n        &::before {\r\n            content:'';\r\n            display: inline-block;\r\n            width: 1.5rem;\r\n            height: 1rem;\r\n            background-image: url('./arrow-right.svg');\r\n            color: #358cca;\r\n        }\r\n    \r\n        &--big {\r\n            font-size: 1.4rem;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
