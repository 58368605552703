const getCouponMessage = (coupon, formatMessage) => {
  if (!coupon) {
    return '';
  }
  if (coupon === 'DIGIVET') {
    return formatMessage({ id: 'billing.coupon.DIGIVET' });
  }
  //      return this.formatMessage({id: "billing.coupon." + type + "Discount"}, {discountPercentage: coupon.percent_off, discountDuration: coupon.duration_in_months})
};

export { getCouponMessage };
