import * as pt from 'prop-types';

const ToolType = pt.shape({
  tool: pt.func.isRequired,
  state: pt.oneOf(['active', 'disabled', 'enabled', 'passive']).isRequired,
  options: pt.shape({
    name: pt.string.isRequired,
    mouseButtonMask: pt.number,
  }),
});

export default ToolType;
