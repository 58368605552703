/**
 * Cornerstone button mask are made to work with MouseEvent.buttons
 * This is not usable to match mouseup event or click since activating button is no longer pressed.
 * To solve that we provide a function that match `buttons` value to `button` value
 * This property record the buttons currently pressed by summing 2 ** `buttonValue` together.
 * See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
 * @param {Array.Number} mouseButtonMask Button mask provide for cornerstone configuration
 */
export default function (mouseButtonMasks) {
  return mouseButtonMasks.map((mouseButtonMask) => {
    switch (mouseButtonMask) {
      case 0:
        return 0;
      case 1:
        return 0;
      case 2:
        return 1;
      case 4:
        return 2;
      case 8:
        return 3;
      case 16:
        return 4;
      default:
        return mouseButtonMask;
    }
  });
}
