/* eslint-disable react/prop-types */
import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import countries from 'app/constants/countries';
import { useIntl } from 'react-intl';
import './styles.css';

type DropdownCountriesListProps = {
  fluid?: boolean;
  upward?: boolean;
  value?: string;
  onChange: (event: React.SyntheticEvent<HTMLElement, Event>, value: { value: string }) => void;
  onBlur?: () => void;
  error?: boolean;
  id?: string;
  placeholder?: string;
};

/**
 * Dropdown Search & Select Countries List
 */
function DropdownCountriesList({
  fluid = false,
  upward = false,
  value = null,
  onChange,
  onBlur = () => {},
  error = undefined,
  id = undefined,
  placeholder = undefined,
}: DropdownCountriesListProps) {
  const countryOptions = countries.map(({ name, countryCode }) => ({
    key: countryCode,
    value: countryCode,
    name: name.toLowerCase(),
    text: (
      <span>
        <i className={`flag ${countryCode}`} />
        {name}
      </span>
    ),
  }));

  const intl = useIntl();
  const placeholderOrDefault =
    placeholder ??
    intl.formatMessage({ id: 'components.dropdown.countries-list.country.placeholder' });

  const onSearch = (options: DropdownItemProps[], value: string) => {
    return options.filter(({ name }) => new RegExp(value.toLowerCase()).test(name));
  };

  return (
    <Dropdown
      id={id}
      search={onSearch}
      selection
      fluid={fluid}
      upward={upward}
      options={countryOptions}
      placeholder={placeholderOrDefault}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
    />
  );
}

export default DropdownCountriesList;
