import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';

import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React from 'react';

class EditableField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      hover: false,
      editMode: false,
      onTextChange: this.props.onTextChange,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      text: nextProps.text,
    });
  }

  onMouseEnter = () => {
    this.setState({ hover: true });
  };

  onMouseLeave = () => {
    this.setState({ hover: false });
  };

  enterEditMode = (event) => {
    this.setState({ editMode: true });
    setTimeout(() => {
      if (this.props.type === 'text') {
        this.refs.text.select();
      } else {
        this.refs.text.focus();
      }
    }, 100);
  };

  leaveEditMode = () => {
    this.setState({ editMode: false });
  };

  displayPencilIcon = () => (this.state.hover && !this.state.editMode ? '' : 'none');

  getIconOpacity = () => (this.state.hover && !this.state.editMode ? '100' : '0');

  onKeyUp = (event) => {
    if (event.key === 'Enter' && this.props.type !== 'textarea') {
      this.validate();
    } else if (event.key === 'Escape') {
      this.escape();
    }
  };

  validate = () => {
    const newText = this.refs.text.value;
    this.props.onTextChange(newText);
    this.setState({ text: newText });
    this.leaveEditMode();
  };

  escape = () => {
    this.leaveEditMode();
  };

  getShortText = () => {
    if (!this.state.text) {
      return '';
    }
    if (this.props.nCharactersForShortText) {
      return this.state.text.slice(0, this.props.nCharactersForShortText);
    }
    return this.state.text;
  };

  renderPassiveMode = () => {
    if (this.props.type === 'textarea') {
      return (
        <pre style={{ padding: '10px 25px 10px 0px', whiteSpace: 'pre-wrap' }}>
          {this.getShortText()}
          <Icon
            link
            name="pencil"
            onClick={this.enterEditMode}
            style={{ opacity: this.getIconOpacity(), marginLeft: '3px' }}
          />
        </pre>
      );
    }
    if (this.props.type === 'text') {
      return (
        <span
          style={{ padding: '10px 25px 10px 0px', display: 'contents', cursor: 'pointer' }}
          onClick={this.enterEditMode}
        >
          {this.state.text}
          <Icon name="pencil" style={{ opacity: this.getIconOpacity(), marginLeft: '3px' }} />
        </span>
      );
    }
  };

  renderEditMode = () => {
    if (this.props.type === 'text') {
      return (
        <span style={{ display: 'contents' }}>
          <input
            ref="text"
            type="text"
            defaultValue={this.state.text}
            placeholder={this.props.placeholder}
            onBlur={this.validate}
            onKeyUp={this.onKeyUp}
            style={{ padding: '6px', border: '1px solid grey', borderRadius: '4px', width: '100%' }}
          />
        </span>
      );
    }
    if (this.props.type === 'textarea') {
      return (
        <span>
          <textarea
            name="text"
            ref="text"
            style={{ width: '100%', height: '150px', padding: '10px' }}
            defaultValue={this.state.text}
            placeholder={this.props.placeholder}
            onKeyUp={this.onKeyUp}
          />
          <Icon
            link
            name="check"
            onClick={this.validate}
            style={{ verticalAlign: 'top', marginLeft: '5px' }}
          />
          <Icon
            link
            name="delete"
            onClick={this.escape}
            style={{ verticalAlign: 'top', marginLeft: '5px' }}
          />
        </span>
      );
    }
  };

  render() {
    // open={this.state.open}
    // <Modal.Header></Modal.Header>
    const { editMode } = this.state;
    return (
      <span onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {editMode ? this.renderEditMode() : this.renderPassiveMode()}
      </span>
    );
  }
}

EditableField.defaultProps = {
  nCharactersForShortText: undefined,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditableField);
