/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import getElectron from 'app/native/node/electron';

class ElectronSnipper extends React.Component {
  initCropper(e) {
    const { ipcRenderer, remote } = getElectron() ?? {};
    const { screen, getCurrentWindow } = remote ?? {};

    this.screenSize = screen.getPrimaryDisplay().size;
    ipcRenderer.invoke('createSnipWindow', { screenSize: this.screenSize });

    ipcRenderer.once('snip', (event, data) => {
      this.captureScreen(data, null);
    });
    ipcRenderer.once('snip-cancelled', () => {
      getCurrentWindow?.().show();
    });
  }

  getContext() {
    const context = global.location.search;
    return context.substr(1, context.length - 1);
  }

  getScreenShot = (coordinates, callback) => {
    const screenSize = this.screenSize;
    const imageFormat = 'image/png';

    this.handleStream = (stream) => {
      // Create hidden video tag
      const video_dom = document.createElement('video');
      video_dom.style.cssText = 'position:absolute;top:-10000px;left:-10000px;';
      // Event connected to stream
      video_dom.onloadedmetadata = function () {
        video_dom.pause();
        // Set video ORIGINAL height (screenshot)
        video_dom.style.height = `${video_dom.videoHeight}px`; // videoHeight
        video_dom.style.width = `${video_dom.videoWidth}px`; // videoWidth

        // Create canvas
        const canvas = document.createElement('canvas');
        canvas.width = video_dom.videoWidth;
        canvas.height = video_dom.videoHeight;
        const ctx = canvas.getContext('2d');

        // Draw video on canvas
        const x = (coordinates.x * video_dom.videoWidth) / screenSize.width;
        const y = (coordinates.y * video_dom.videoHeight) / screenSize.height;
        const width = (coordinates.width * canvas.width) / screenSize.width;
        const height = (coordinates.height * canvas.height) / screenSize.height;
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(video_dom, x, y, width, height, 0, 0, canvas.width, canvas.height);

        if (callback) {
          // Save screenshot to base64
          callback(canvas.toDataURL(imageFormat));
        } else {
          console.log('Need callback!');
        }

        // Remove hidden video tag
        video_dom.remove();

        try {
          // Destroy connect to stream
          stream.getTracks()[0].stop();
        } catch (e) {
          console.log(e);
        }
        canvas.remove();
        video_dom.remove();
      };
      video_dom.srcObject = stream;
      video_dom.play();
    };

    this.handleError = (e) => {
      console.log(e);
    };

    // desktopCapturer.getSources({ types: ['window'] }, () => {
    getElectron()
      ?.desktopCapturer.getSources({ types: ['screen'] })
      .then((sources) => {
        const sourceToUse = this.chooseScreenToCapture(sources);
        if (sourceToUse === null) {
          return;
        }
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              mandatory: {
                chromeMediaSource: 'desktop',
                chromeMediaSourceId: sourceToUse.id,
              },
              optional: [
                { minWidth: 320 },
                { minWidth: 640 },
                { minWidth: 1024 },
                { minWidth: 1280 },
                { minWidth: 1920 },
                { minWidth: 2560 },
              ],
            },
          })
          .then(this.handleStream, this.handleError);
      });
    // });
  };

  chooseScreenToCapture(sources) {
    let sourceToUse = null;
    let entireScreenFound = false;
    let firstScreenFound = false;
    sources.forEach((source) => {
      if (source.name === 'Entire screen') {
        sourceToUse = source;
        entireScreenFound = true;
      } else if (source.name === 'Screen 1' && !entireScreenFound) {
        sourceToUse = source;
        firstScreenFound = true;
      } else if (!entireScreenFound && !firstScreenFound) {
        sourceToUse = source;
      }
    });
    return sourceToUse;
  }

  captureScreen(coordinates, e) {
    const mainWindow = getElectron()?.remote?.getCurrentWindow();
    mainWindow.hide();
    // mainWindow.hide();
    this.getScreenShot(coordinates, (base64data) => {
      this.props.onImageReady([base64data]);
      mainWindow.show();
    });
  }

  renderCropChoiceButtons = () => (
    <Popup
      content={<FormattedMessage id="snipper.button.tooltip" />}
      position="right center"
      trigger={
        <button
          type="button"
          className="button snipper picoxia"
          onClick={this.initCropper.bind(this)}
        >
          <Icon name="cut" />
          <span>
            <FormattedMessage id="snipper.button" />
          </span>
        </button>
      }
    />
  );

  render() {
    return this.renderCropChoiceButtons();
  }
}

export default ElectronSnipper;
