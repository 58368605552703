import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';

const RequiredAsterisk = () => (
  <Popup
    content={<FormattedMessage id="field_required" />}
    trigger={<span style={{ color: 'red' }}>*</span>}
  />
);

export default RequiredAsterisk;
