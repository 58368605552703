import './style.css';

import * as PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import React from 'react';
import { isWorkListImage } from '../../utils/isWorkListImage';

class WorkListImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overflow: false,
    };
    this.setImagesRef = (e) => {
      this.imagesRef = e;
    };
  }

  componentDidMount = () => this.setState({ overflow: this.isOverflow() });

  componentDidUpdate = () => {
    const { overflow } = this.state;
    const checkOverflow = this.isOverflow();
    if (checkOverflow !== overflow) this.setState({ overflow: checkOverflow });
  };

  isOverflow = () => {
    const ref = this.imagesRef;

    if (!ref) return false;
    return ref.offsetHeight < ref.scrollHeight;
  };

  showMore = () => {
    const overflow = this.isOverflow();
    if (!overflow) return undefined;
    return (
      <div className="overflow">
        <Icon className="ellipsis horizontal icon " />
      </div>
    );
  };

  renderImages = (study) =>
    study.images.map((image, index) => {
      if (!isWorkListImage(image)) return undefined;
      const examArr = image.anatomicRegion.split(' ');
      let specie = examArr[0];
      if (specie === 'other') specie = 'dog';
      return (
        <i
          className={`svg-icon ${
            examArr[1] === 'entire_body' ? ` ${specie} entire_body` : examArr[1]
          }`}
          key={index}
        />
      );
    });

  render() {
    const { study } = this.props;
    const { overflow } = this.state;
    return (
      <Popup
        inverted
        position="bottom center"
        trigger={
          <div style={{ width: '40%' }} className="workList-images">
            <div className="images" ref={this.setImagesRef}>
              {this.renderImages(study)}
            </div>
            {this.showMore(study._id)}
          </div>
        }
        disabled={!overflow}
        content={
          <div className="workList-images-popup">
            <div className="images" ref={this.setImagesRef}>
              {this.renderImages(study)}
            </div>
          </div>
        }
      />
    );
  }
}

WorkListImages.propTypes = {
  study: PropTypes.shape().isRequired,
};

export default WorkListImages;
