import { getAnatomicRegionFromString } from 'app/utils/xrayRegions';

/**
 *
 * @param {string} processingType
 * @param {object} info
 * @returns {ProcessingKind}
 */
export default function getProcessingKeyFromInfo(processingType, { specie, anatomicRegion }) {
  let speciePrefix;
  if (specie === 'dog') speciePrefix = 'dog_';
  // Cat processing is the default case for non dog patient since other
  // animals that can be taken xray of are closer in size to cats.
  else speciePrefix = 'cat_';

  switch (processingType) {
    case 'abdomen':
      return `${speciePrefix}abdomen`;
    case 'thorax':
      return `${speciePrefix}thorax`;
    case 'bones': {
      const isDogSkull =
        specie === 'dog' && getAnatomicRegionFromString(anatomicRegion).mainRegion === 'crane';
      if (isDogSkull) return 'dog_skull';

      return 'bones';
    }
    default:
      break;
  }
  return 'default';
}
