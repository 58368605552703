import SerialXRayGeneratorControllerHandle from 'app/adapters/XRayGenerator/SerialXRayGeneratorControllerHandle';
import EventEmitter from 'events';

export default class SerialXRayGeneratorControllerManager extends EventEmitter {
  constructor(serialportModule) {
    super();

    this.serialportModule = serialportModule;
  }

  list() {
    return this.serialportModule?.SerialPort.list().then((portsList) =>
      portsList.map(
        (port) => new SerialXRayGeneratorControllerHandle(this.serialportModule, port.path)
      )
    );
  }

  get(devicePath) {
    return new SerialXRayGeneratorControllerHandle(this.serialportModule, devicePath);
  }
}
