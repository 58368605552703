import forceImageUpdate from './forceImageUpdate';

/**
 * Extend the given class with callback to trigger image rerender on tool state change.
 * @param {*} toolApi
 */
export default function injectSwitchableTool(toolApi) {
  return class extends toolApi {
    // eslint-disable-next-line class-methods-use-this
    activeCallback(element) {
      if (toolApi.prototype.activeCallback) {
        toolApi.prototype.activeCallback.call(this, element);
      }
      forceImageUpdate(element);
    }

    // eslint-disable-next-line class-methods-use-this
    passiveCallback(element) {
      if (toolApi.prototype.passiveCallback) {
        toolApi.prototype.passiveCallback.call(this, element);
      }
      forceImageUpdate(element);
    }

    // eslint-disable-next-line class-methods-use-this
    enabledCallback(element) {
      if (toolApi.prototype.enabledCallback) {
        toolApi.prototype.enabledCallback.call(this, element);
      }
      forceImageUpdate(element);
    }

    // eslint-disable-next-line class-methods-use-this
    disabledCallback(element) {
      if (toolApi.prototype.disabledCallback) {
        toolApi.prototype.disabledCallback.call(this, element);
      }
      forceImageUpdate(element);
    }
  };
}
