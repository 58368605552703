import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';

import { isRotated } from 'app/utils/cornerstone/imageUtils';
import clearElement from './clearElement';
import { get } from '../utils/PromiseHelpers';
import { ElementToolStateManager } from './ElementToolStateManager';

const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1920;

/**
 *  Compute canvas dimension that respect input image aspect ratio and respect some size constraints
 * @param {*} image     A cornerstone image
 * @param {number} maxWidth  Maximum width for the resulting canvas
 * @param {number} maxHeight Maximum height for the resulting canvas
 */
const computeCanvasDimension = (image, rotation = 0, maxWidth = null, maxHeight = null) => {
  const isImageRotated = isRotated(rotation);

  const imageWidth = isImageRotated ? image.height : image.width;
  const imageHeight = isImageRotated ? image.width : image.height;

  if (!maxWidth && !maxHeight) return [imageWidth, imageHeight];
  let imageRatio = imageWidth / imageHeight;
  // In the case of dicom images, the height and width of each pixel can be specified.
  // non square pixel affect the rendering process and the viewable image aspect ratio.
  if (image.columnPixelSpacing && image.rowPixelSpacing) {
    imageRatio *= image.columnPixelSpacing / image.rowPixelSpacing;
  }
  let canvasWidth = maxWidth ? Math.min(imageWidth, maxWidth) : imageWidth;
  let canvasHeight = maxHeight ? Math.min(imageHeight, maxHeight) : imageHeight;
  if (canvasWidth / canvasHeight > imageRatio) {
    canvasWidth = canvasHeight * imageRatio;
  } else {
    canvasHeight = canvasWidth / imageRatio;
  }
  return [canvasWidth, canvasHeight];
};

/**
 * Load the provided `cornerstoneImage` into an element an allow for manipulation via callback
 * @template Result
 * @param {cornerstone.Image} cornerstoneImage Image acquired from a cornerstone loader
 * @param {(cornerstoneImage:cornerstone.EnabledElement) => Result} imageManipulationCallback The callback in which it is
 * allowed to use the canvas, to avoid leaking data once the callback has completed the div and
 * image will be teared down.
 * @param {number} [rotation] How image will be rotated, used to setup max dimensions
 * @param {number} [maxWidth]
 * @param {number} [maxHeight]
 * @return {Promise<Result>} The `imageManipulationCallback` result else reject promise.
 */
const loadImageInCanvas = async (
  cornerstoneImage,
  imageManipulationCallback,
  rotation = 0,
  maxWidth = MAX_WIDTH,
  maxHeight = MAX_HEIGHT
) => {
  // We create a non visible temporary  element that will be used to retrieve pixels data.
  const tmpDiv = document.createElement('div');
  csc.enable(tmpDiv);
  cst.setElementToolStateManager(tmpDiv, new ElementToolStateManager(tmpDiv));
  const tmpElement = csc.getEnabledElement(tmpDiv);

  const [canvasWidth, canvasHeight] = computeCanvasDimension(
    cornerstoneImage,
    rotation,
    maxWidth,
    maxHeight
  );
  tmpElement.canvas.width = canvasWidth;
  tmpElement.canvas.height = canvasHeight;

  // Canvas data are not instantly ready, we have to wait for the image_rendered event.
  const [err] = await get(
    new Promise((resolve, reject) => {
      tmpDiv.addEventListener(csc.EVENTS.IMAGE_RENDERED, resolve, { once: true });
      tmpDiv.addEventListener(csc.EVENTS.IMAGE_LOAD_FAILED, reject, { once: true });
      csc.displayImage(tmpDiv, cornerstoneImage);
    })
  );

  // We must execute the imageManipulation callback before tearing down the element.
  const resolveResult = err ? null : await imageManipulationCallback(tmpElement);
  // Whatever happens, we cleanup after ourselves to remove leftover tools from cornerstone store.
  clearElement(tmpDiv);
  tmpDiv.remove();

  if (err) {
    console.log('Image load failed:', err);
    throw new Error(err);
  }
  return resolveResult;
};

export default loadImageInCanvas;

export { computeCanvasDimension };
