import _ from 'lodash';

const getKeyByValue = (object, value, defaultKey = undefined) => {
  let result = defaultKey;
  _.forEach(object, (item, key) => {
    if (item === value) {
      result = key;
      return false;
    }
    return true;
  });
  return result;
};

const IRAY_RETCODE = {
  OK: 0,
  TaskPending: 1,
  Unknown: 2,
  DuplicatedCreation: 3,
  DetectorIdNotFound: 4,
  StateErr: 5,
  NotInitialized: 6,
  NotImplemented: 7,
  AccessDenied: 8,
  LoadDllFailed: 9,
  DllCreateObjFailed: 10,
  OpenFileFailed: 11,
  FileNotExist: 12,
  ConfigFileNotExist: 13,
  TemplateFileNotExist: 14,
  TemplateFileNotMatch: 15,
  InvalidFileFormat: 16,
  CreateLoggerFailed: 17,
  InvalidParamCount: 18,
  InvalidParamType: 19,
  InvalidParamValue: 20,
  PreCondition: 21,
  TaskTimeOut: 22,
  ProdInfoMismatch: 23,
  DetectorRespTimeout: 24,
  InvalidPacketNo: 25,
  InvalidPacketFormat: 26,
  PacketDataCheckFailed: 27,
  PacketLost_BufOverflow: 28,
  FrameLost_BufOverflow: 29,
  ImgChBreak: 30,
  BadImgQuality: 31,
  GeneralSocketErr: 32,
  DetectorSN_Mismatch: 33,
  CommDeviceNotFound: 34,
  CommDeviceOccupied: 35,
  CommParamNotMatch: 36,
  NotEnoughDiskSpace: 37,
  NotEnoughMemorySpace: 38,
  ApplyFirmwareFailed: 39,
  CallbackNotFinished: 40,
  FirmwareUpdated: 41,
  TooMuchDefectPoints: 42,
  TooLongFilePath: 43,
  ClientIPNotMatch: 44,
  AddressOccupied: 45,
  NotInSameNetworkSegment: 46,
  FPD_General_Detector_Error: 1001,
  FPD_General_ControlBox_Error: 1002,
  FPD_General_FirmwareUpgrade_Error: 1003,
  FPD_General_GSensor_Error: 1004,
  FPD_NotImplemented: 1005,
  FPD_SeqNoOutOfSync: 1006,
  FPD_Busy: 1007,
  FPD_Busy_Initializing: 1018,
  FPD_Busy_Last_Command_Suspending: 1019,
  FPD_Busy_Mode_Not_Supported: 1020,
  FPD_Busy_MCU_Busy: 1021,
  FPD_Busy_FPGA_Busy: 1022,
  FPD_Busy_FPGA_Timeout: 1023,
  FPD_Busy_Doing_Dynamic_Ghost: 1024,
  FPD_Busy_Doing_Dynamic_Preoffset: 1025,
  FPD_Busy_FTP_Image_Uploading: 1026,
  FPD_Busy_Capture_State_Recover: 1027,
  FPD_Busy_System_Error: 1028,
  FPD_Busy_BatteryLow: 1029,
  FPD_Occupied: 1008,
  FPD_SleepWakeupFailed: 1009,
  FPD_SleepCaptureError: 1010,
  FPD_CmdExecuteTimeout: 1011,
  FPD_FirmwareFallback: 1012,
  FPD_NotSupportInCurrMode: 1013,
  FPD_NoEnoughStorageSpace: 1014,
  FPD_FileNotExist: 1015,
  FPD_FtpServerAccessError: 1016,
  FPD_HWCaliFileError: 1017,
  FPD_AcquisitionBlock: 1040,
  FPD_SelfTestFailed: 1041,
  TemperatureHigh: 1042,
  Ftrans_Not_Ready: 1043,
  Ftrans_LocalFileOpenFailed: 1044,
  Ftrans_NetError: 1045,
  Ftrans_StartTimeout: 1046,
  Ftrans_StartError: 1047,
  Ftrans_FinishedTimeout: 1048,
  Ftrans_Aborted: 1049,
  Ftrans_DownloadTimeout: 1050,
  Ftrans_DownloadNotComplete: 1051,
  Ftrans_UploadTimeout: 1052,
  Ftrans_TcpBreak: 1053,
  Ftrans_FileLen_Zero: 1054,
  Ftrans_ParamError: 1055,
  Ftrans_FinishError: 1056,
  FPD_SelfTest_DiskReadOnly: 1100,
  FPD_SelfTest_SpaceNotEnough: 1101,
  FPD_SelfTest_CanNotGetFPDCfg: 1102,
  FPD_SelfTest_FPDCfgInvalid: 1103,
  FPD_SelfTest_TemplateFailed: 1104,
  FPD_SelfTest_SyncDftFailed: 1105,
  FPD_SelfTest_SyncGainFailed: 1106,
  FPD_SelfTest_Reserved: 1199,
  FTP_OpenLoginFailed: 2001,
  FTP_MkdirCdFailed: 2002,
  FTP_LocalFileOpenFailed: 2003,
  FTP_UploadFailed: 2004,
  FTP_DownloadFailed: 2005,
  FTP_FileVerifyFailed: 2006,
  FTP_TypeError: 2007,
  Cali_GeneralError: 3001,
  Cali_UnexpectImage_DoseHighHigh: 3002,
  Cali_UnexpectImage_ExpLineNotSatisfy: 3003,
  Cali_UnexpectImage_MistakeTrigger: 3004,
  Cali_DataNotReadyForGen: 3005,
  Cali_NotEnoughIntervalTime_OffsetTmpl: 3006,
  Cali_UnexpectImage_DoseOver: 3007,
  Cali_UnexpectImage_DoseUnder: 3008,
  Cali_UnexpectImage_ObjectDetected: 3009,
  Cali_UnexpectImage_PartialExposure: 3010,
  FPD_CertificationFailed: 4000,
  FPD_AccessDenied_Unauthorized: 4001,
};

const IRAY_COMMANDS = {
  Cmd_SetLogLevel: 1,
  Cmd_Connect: 2,
  Cmd_Disconnect: 3,
  Cmd_Sleep: 4,
  Cmd_Wakeup: 5,
  Cmd_SetCorrectOption: 6,
  Cmd_SetCaliSubset: 7,
  Cmd_Reset: 8,
  Cmd_Clear: 1001,
  Cmd_ClearAcq: 1002,
  Cmd_Acq2: 1003,
  Cmd_AecPreExp: 1016,
  Cmd_StartAcq: 1004,
  Cmd_StopAcq: 1005,
  Cmd_ForceSingleAcq: 1006,
  Cmd_ForceContinuousAcq: 1007,
  Cmd_ForceDarkSingleAcq: 1008,
  Cmd_ForceDarkClearAcq: 1009,
  Cmd_ForceDarkContinuousAcq: 1010,
  Cmd_ProhibOutExp: 1011,
  Cmd_EnableOutExp: 1012,
  Cmd_SyncStart: 1013,
  Cmd_SyncCancel: 1014,
  Cmd_SyncAcq: 1015,
  Cmd_EnterStitchingFlow: 1017,
  Cmd_StitchingAcqOnce: 1018,
  Cmd_QuitStitchingFlow: 1019,
  Cmd_RetrieveFreshStitchingImages: 1020,
  Cmd_ClearStitchingImages: 1021,
  Cmd_RetrieveFreshImages: 1022,
  Cmd_ReadUserROM: 2001,
  Cmd_WriteUserROM: 2002,
  Cmd_ReadUserRAM: 2030,
  Cmd_WriteUserRAM: 2031,
  Cmd_ReadFactoryROM: 2003,
  Cmd_WriteFactoryROM: 2004,
  Cmd_ReadVtMap: 2005,
  Cmd_WriteVtMap: 2006,
  Cmd_Recover: 2007,
  Cmd_UpdateFirmware: 2008,
  Cmd_SetImgChannel: 2009,
  Cmd_ReadTemperature: 2010,
  Cmd_ReadHumidity: 2011,
  Cmd_UploadDetectorLog: 2012,
  Cmd_UploadShockLog: 2013,
  Cmd_ClearShockLog: 2014,
  Cmd_WriteShockThreshold: 2015,
  Cmd_ReadShockThreshold: 2016,
  Cmd_ReadBatteryStatus: 2017,
  Cmd_SetTimeByDiff: 2018,
  Cmd_QueryTimeDiff: 2019,
  Cmd_QueryLivingTime: 2020,
  Cmd_ReadWifiStatus: 2021,
  Cmd_QueryWifiScanList: 2022,
  Cmd_WriteWifiSettings: 2023,
  Cmd_ReadWifiSettings: 2024,
  Cmd_DownloadWorkList: 2025,
  Cmd_QueryHistoricalImageList: 2026,
  Cmd_SelectWorkItem: 2027,
  Cmd_UploadHistoricalImages: 2028,
  Cmd_StopUploadingHistoricalImages: 2029,
  Cmd_ChangeParamsOfCurrentAppMode: 2032,
  Cmd_QueryLastImageID: 2033,
  Cmd_GetImageByImageID: 2034,
  Cmd_WriteCustomROM: 2035,
  Cmd_ReadCustomROM: 2036,
  Cmd_WriteLicenseData: 2037,
  Cmd_ReadLicenseData: 2038,
  Cmd_ReadHallSensor: 2039,
  Cmd_ClearHistoricalImages: 2040,
  Cmd_OffsetGeneration: 3001,
  Cmd_GainInit: 3002,
  Cmd_GainSelectCurrent: 3004,
  Cmd_GainSelectAll: 3005,
  Cmd_GainGeneration: 3006,
  Cmd_DefectInit: 3007,
  Cmd_LoadTemporaryDefectFile: 3008,
  Cmd_DefectSelectCurrent: 3009,
  Cmd_DefectSelectAll: 3033,
  Cmd_DefectGeneration: 3010,
  Cmd_LagPrepareDarkImages: 3037,
  Cmd_LagInit: 3012,
  Cmd_LagSelectCurrent: 3013,
  Cmd_LagGeneration: 3014,
  Cmd_GridInit: 3036,
  Cmd_GridGeneration: 3038,
  Cmd_DownloadCertificationFile: 3039,
  Cmd_PanelCertificate: 3040,
  Cmd_UpdateFreqCompositeCoeff: 3032,
  Cmd_CalibrationInit: 3035,
  Cmd_FinishGenerationProcess: 3015,
  Cmd_DeleteGenerationTempFile: 3016,
  Cmd_DownloadCaliFile: 3017,
  Cmd_UploadCaliFile: 3018,
  Cmd_SelectCaliFile: 3019,
  Cmd_HwGeneratePreOffsetTemplate: 3020,
  Cmd_QueryHwCaliTemplateList: 3021,
  Cmd_ApplyDefectCorrection: 3022,
  Cmd_RequestSyncboxControl: 3023,
  Cmd_ReleaseSyncboxControl: 3024,
  Cmd_ReadOutExpEnableState: 3025,
  Cmd_EnableAutoSleep: 3026,
  Cmd_DisableAutoSleep: 3027,
  Cmd_ReadAutoSleepEnableState: 3028,
  Cmd_PowerOff: 3029,
  Cmd_StartAutoCleanup: 3030,
  Cmd_StopAutoCleanup: 3031,
  Cmd_PanelRecover: 3034,
  Cmd_Debug_ReadCRC: 10001,
  Cmd_ReadCustomFile: 4001,
  Cmd_WriteCustomFile: 4002,
  Cmd_ActivePanel: 4003,
  Cmd_DeactivePanel: 4004,
  Cmd_ReadCustomData: 4005,
  Cmd_WriteCustomData: 4006,
};

const isCommandValid = (cmdReturn) =>
  cmdReturn === IRAY_RETCODE.OK || cmdReturn === IRAY_RETCODE.TaskPending;

const IRAY_EVENTS = {
  Evt_GeneralInfo: 1, // General Info
  Evt_GeneralWarn: 2, // General Warn
  Evt_GeneralError: 3, // General Error
  Evt_TaskResult_Succeed: 4, // Task succeed
  Evt_TaskResult_Failed: 5, // Task failed
  Evt_TaskResult_Canceled: 6, // Task been canceled
  Evt_AutoTask_Started: 7, // Task is started which is not emitted by user command, outer or AED image acquiring will trigger this event.
  Evt_ScanOnce_Request: 8, // Request scanner to start a broadcast scanning
  Evt_ImageFileUpload_Result: 9, // Image file upload result notify
  Evt_TemplateFileUpload_Result: 10, // Template file upload result notify
  Evt_TemplateFileDownload_Result: 11, // Template file download result notify
  Evt_HwCaliTemplateList: 12, // Return hardware calibration template list
  Evt_HwWiFiScanList: 13, // Return hardware WiFi scan list
  Evt_HistoricalImageList: 14, // Return hardware historical image list
  Evt_TimeDiff: 15, // Return time difference between detector and PC in seconds
  Evt_LivingTime: 16, // Return living time from detector in seconds
  Evt_TransactionAborted: 17, // Notify that current transaction was aborted
  Evt_Image: 1001, // Image received
  Evt_Prev_Image: 1002, // Preview image received
  Evt_Retransfer_Image: 1012, // Retransfered image received after break and re-connecting
  Evt_WaitImage_Timeout: 1003, // Acqusition operation time out(equal: Possiable Image Loss)
  Evt_Exp_Prohibit: 1004, // Exposure prohibit
  Evt_Exp_Enable: 1005, // Exposure enable
  Evt_Exp_Timeout: 1006, // Exposure timeout
  Evt_OffsetAmassingTime: 1008, // Pixel base amassing time,typically from previous clear operation to current acquisition
  Evt_MistakenTrigger: 1009, // Mistaken trigger in FreeSync mode
  Evt_Image_Trans_Slow: 1010, // Warn that image transfer speed low, usually at wireless connection.
  Evt_Image_Abandon: 1011, // Warn that currently acquiring image abandoned because bad network situation
  Evt_LastImageID: 1013, // Return the image ID while invoking Cmd_QueryLastImageID
  Evt_CustomROM: 1014, // Return the customized data while invoking Cmd_ReadCustomROM
  Evt_LicenseData: 1015, // Return the license data while invoking Cmd_ReadLicenseData
  Evt_NextStitchingPositionEnable: 1016, // Inform the system that the acquisition of post-offset dark image is finished
  Evt_StitchingFlowTimeout: 1017, // If two stitching exposures time out, the detector will exit the stitching workflow and back to normal workflow
  Evt_FreshImageIDs: 1018, // Return remain images that haven't been transferred. If all the images have been transferred, then nFirstImageID and nFreshImageCount will be "0" if use Cmd_RetrieveFreshStitchingImages again
  Evt_CustomData: 1019, // Return the customized data while invoking Cmd_ReadCustomData
  Evt_ConnectProcess: 2001, // Report Process info while connecting
  Evt_CommFailure: 2002, // Communication failed
  Evt_TemperatureHigh: 2003, // Temperature too high
  Evt_FpsOutOfRange: 2004, // Fps too high or too low
  Evt_LowBattery: 2005, // Low battery warn
  Evt_TemplateOverDue: 2006, // Calibration template file over due
  Evt_SWHWDataNotMatch: 2007, // The correct data of software and hardware not match, for example:Software defect template create time not same as Software defect template create time
  Evt_DefectPointsIncreased: 2008, // Notify user after new defect map generated if new defect points are found.
};

const IRAY_ATTRIBUTES = {
  Cfg_ProtocolEdition: 1,
  Cfg_ProductNo: 2,
  Cfg_SN: 3,
  Cfg_UseServiceProcess: 8,
  Cfg_DetectorImp: 4,
  Cfg_ConnImp: 5,
  Cfg_CaliImp: 6,
  Cfg_LogLevel: 7,
  Cfg_HostIP: 101,
  Cfg_HostPort: 102,
  Cfg_RemoteIP: 103,
  Cfg_RemotePort: 104,
  Cfg_ComPort: 105,
  Cfg_PleoraConnStr: 106,
  Cfg_PleoraPacketSize: 107,
  Cfg_WinpcapConnStr: 108,
  Cfg_PleoraMaxFps: 109,
  Cfg_HostImagePort: 110,
  Cfg_HostImageAckPort: 111,
  Cfg_PCIEBoardIndex: 112,
  Cfg_E5_ResendCnt: 113,
  Cfg_E5_ResendInterval: 114,
  Cfg_HeartBeatTimeOutDuration: 115,
  Cfg_RepeatCmdEnable: 150,
  Cfg_USBConnStr: 151,
  Cfg_FTP_Download_HostIP: 201,
  Cfg_FTP_Download_HostPort: 202,
  Cfg_FTP_Download_User: 203,
  Cfg_FTP_Download_PWD: 204,
  Cfg_FTP_Download_LocalPath: 205,
  Cfg_FTP_Upload_HostIP: 206,
  Cfg_FTP_Upload_HostPort: 207,
  Cfg_FTP_Upload_User: 208,
  Cfg_FTP_Upload_PWD: 209,
  Cfg_FTP_Upload_LocalPath: 210,
  Cfg_OffsetAlarmMinute: 301,
  Cfg_GainAlarmTime: 302,
  Cfg_DefectAlarmTime: 303,
  Cfg_CaliValidity_PreWarnMinute: 304,
  Cfg_CaliValidity_Enable: 305,
  Cfg_DefaultSubset: 306,
  Cfg_DefaultCorrectOption: 307,
  Cfg_DefectStatistical_DummyTop: 308,
  Cfg_DefectStatistical_DummyBottom: 309,
  Cfg_DefectStatistical_DummyLeft: 310,
  Cfg_DefectStatistical_DummyRight: 311,
  Cfg_CalibrationFlow: 312,
  Cfg_EnableROIShareTemplate: 313,
  Cfg_GridRemovalPreProcess_Enable: 314,
  Cfg_ClearAcqParam_DelayTime: 501,
  Cfg_FpsCheck_Enable: 502,
  Cfg_FpsCheck_Tolerance: 503,
  Cfg_FWUpdTimeOut: 504,
  Cfg_OfflineInspectTimeout: 505,
  Cfg_AllowReconnectByOnlineNotice: 506,
  Cfg_ResetTimeout: 507,
  Cfg_PreviewImage_Enable: 508,
  Cfg_PushImageAtExpTimeout_Enable: 509,
  Cfg_RetransferCount: 510,
  Cfg_ConnRecoverTimeout: 511,
  Cfg_TemperatureHighThreshold: 512,
  Cfg_AllowMismatchSN: 513,
  Cfg_ImagePacketGapTimeout: 514,
  Cfg_FwAllowedDefectPoints: 515,
  Cfg_PostOffsetStart_DelayTime: 516,
  Cfg_TotalAcqTimeout: 517,
  Cfg_PreExpImageAcqTimeout: 518,
  Cfg_CleanupProcessTime: 519,
  Cfg_SeqAcq_AutoStopToSyncExp: 520,
  Cfg_Acq2SubFlow: 521,
  Cfg_DetectorSleepMode: 522,
  Cfg_SkipFirmwareCheck: 523,
  Cfg_TimingOutput: 524,
  Cfg_RetransferImageEnable: 525,
  Cfg_AecPrevBinningMode: 526,
  Cfg_FtransTimeoutPreM: 527,
  Cfg_SubjoinDelayTime: 528,
  Cfg_BEImageCacheTimeMs: 529,
  Cfg_ShockLog_ShowMode: 530,
  Cfg_WaitGenHWOffsetTemplateTime: 531,
  Attr_Prod_Name: 1001,
  Attr_Prod_Description: 1002,
  Attr_Prod_FullWidth: 1003,
  Attr_Prod_FullHeight: 1004,
  Attr_Prod_PhysicalPixelSize: 1005,
  Attr_Prod_BitDepth: 1006,
  Attr_Prod_DataBytesPerPacket: 1007,
  Attr_Prod_TotalPacketNumber: 1008,
  Attr_Prod_DummyTop: 1009,
  Attr_Prod_DummyBottom: 1010,
  Attr_Prod_DummyLeft: 1011,
  Attr_Prod_DummyRight: 1012,
  Attr_Prod_AfeChSize: 1014,
  Attr_Prod_GateSize: 1016,
  Attr_Prod_GateEdge: 1017,
  Attr_Prod_DriveMode: 1013,
  Attr_Prod_ReXferMode: 1015,
  Attr_UROM_ProductNo: 2001,
  Attr_UROM_MainVersion: 2002,
  Attr_UROM_ReadVersion: 2003,
  Attr_UROM_McuVersion: 2004,
  Attr_UROM_ArmVersion: 2005,
  Attr_UROM_KernelVersion: 2006,
  Attr_UROM_ProtocolVersion: 2007,
  Attr_UROM_MasterBuildTime: 2008,
  Attr_UROM_SlaveBuildTime: 2009,
  Attr_UROM_McuBuildTime: 2010,
  Attr_UROM_RowPreDelayTime: 2011,
  Attr_UROM_RowPostDelayTime: 2012,
  Attr_UROM_IntegrateTime: 2013,
  Attr_UROM_ZoomMode: 2014,
  Attr_UROM_ExpEnable_SignalLevel: 2015,
  Attr_UROM_SelfClearEnable: 2016,
  Attr_UROM_SelfClearSpanTime: 2017,
  Attr_UROM_SequenceIntervalTime: 2018,
  Attr_UROM_TriggerMode: 2019,
  Attr_UROM_DynamicFlag: 2020,
  Attr_UROM_TubeReadyTime: 2021,
  Attr_UROM_SequenceIntervalTime_HighPrecision: 2022,
  Attr_UROM_SetDelayTime: 2023,
  Attr_UROM_ExpWindowTime: 2025,
  Attr_UROM_SyncExpTime: 2027,
  Attr_UROM_SyncExpTime_HighPrecision: 2028,
  Attr_UROM_VT: 2029,
  Attr_UROM_PGA: 2030,
  Attr_UROM_PrepCapMode: 2032,
  Attr_UROM_SelfCapEnable: 2033,
  Attr_UROM_FluroSync: 2034,
  Attr_UROM_SrcPort: 2035,
  Attr_UROM_SrcIP: 2036,
  Attr_UROM_SrcMAC: 2037,
  Attr_UROM_DestPort: 2038,
  Attr_UROM_DestIP: 2039,
  Attr_UROM_DestMAC: 2040,
  Attr_UROM_SyncboxIP: 2041,
  Attr_UROM_PreviewImgMode: 2044,
  Attr_UROM_HWOffsetType: 2045,
  Attr_UROM_AcquireDelayTime: 2046,
  Attr_UROM_BinningMode: 2047,
  Attr_UROM_ExpMode: 2050,
  Attr_UROM_AecMainTime: 2051,
  Attr_UROM_DynaOffsetGapTime: 2052,
  Attr_UROM_DynaOffsetEnable: 2053,
  Attr_UROM_ImagePktGapTime: 2054,
  Attr_UROM_OutModeCapTrig: 2069,
  Attr_UROM_HvgPrepOn: 2055,
  Attr_UROM_HvgXRayEnable: 2056,
  Attr_UROM_HvgXRayOn: 2057,
  Attr_UROM_HvgXRaySyncOut: 2058,
  Attr_UROM_HvgXRaySyncIn: 2059,
  Attr_UROM_CbxBuildTime: 2060,
  Attr_UROM_SubProductNo: 2061,
  Attr_UROM_SerialNo: 2062,
  Attr_UROM_ImageChType: 2063,
  Attr_UROM_ImageChProtocol: 2064,
  Attr_UROM_HWGainEnable: 2065,
  Attr_UROM_ExpTimeValidPercent: 2066,
  Attr_UROM_FreesyncCenterThreshold: 2067,
  Attr_UROM_FreesyncEdgeThreshold: 2068,
  Attr_UROM_FreesyncSubFlow: 2070,
  Attr_UROM_PowSeriesCorrectEnable: 2071,
  Attr_UROM_PulseClearTimes: 2072,
  Attr_UROM_ROIColStartPos: 2073,
  Attr_UROM_ROIColEndPos: 2074,
  Attr_UROM_ROIRowStartPos: 2075,
  Attr_UROM_ROIRowEndPos: 2076,
  Attr_UROM_FullWell: 2077,
  Attr_UROM_InnerSubFlow: 2078,
  Attr_UROM_SoftwareSubFlow: 2079,
  Attr_UROM_MainMBCpuVersion: 2080,
  Attr_UROM_MainMBCpuBuildTime: 2081,
  Attr_UROM_XCombWiseType: 2082,
  Attr_UROM_YCombWiseType: 2083,
  Attr_UROM_SubProductVersion: 2084,
  Attr_UROM_WarningTemperature: 2085,
  Attr_UROM_PowerOffTemperature: 2086,
  Attr_UROM_WLAN_DHCPEnable: 2087,
  Attr_UROM_LAN_DHCPEnable: 2088,
  Attr_UROM_DHCP_StaticIP: 2089,
  Attr_UROM_HWPreoffsetBase: 2090,
  Attr_UROM_ShockMcuVersion: 2091,
  Attr_UROM_ShockMcuBuildTime: 2092,
  Attr_UROM_ProductConfigCode: 2093,
  Attr_UROM_NetworkBand: 2094,
  Attr_UROM_CofPGA: 2095,
  Attr_UROM_IntegrateTime_W: 2540,
  Attr_UROM_ZoomMode_W: 2501,
  Attr_UROM_ExpEnable_SignalLevel_W: 2502,
  Attr_UROM_SelfClearEnable_W: 2503,
  Attr_UROM_SelfClearSpanTime_W: 2504,
  Attr_UROM_SequenceIntervalTime_W: 2505,
  Attr_UROM_TriggerMode_W: 2506,
  Attr_UROM_DynamicFlag_W: 2507,
  Attr_UROM_TubeReadyTime_W: 2508,
  Attr_UROM_SetDelayTime_W: 2510,
  Attr_UROM_SequenceIntervalTime_HighPrecision_W: 2511,
  Attr_UROM_ExpWindowTime_W: 2512,
  Attr_UROM_PGA_W: 2513,
  Attr_UROM_PrepCapMode_W: 2514,
  Attr_UROM_SelfCapEnable_W: 2515,
  Attr_UROM_FluroSync_W: 2516,
  Attr_UROM_SrcIP_W: 2518,
  Attr_UROM_SrcMAC_W: 2519,
  Attr_UROM_DestPort_W: 2520,
  Attr_UROM_DestIP_W: 2521,
  Attr_UROM_DestMAC_W: 2522,
  Attr_UROM_PreviewImgMode_W: 2523,
  Attr_UROM_HWOffsetType_W: 2544,
  Attr_UROM_SyncboxIP_W: 2543,
  Attr_UROM_AcquireDelayTime_W: 2524,
  Attr_UROM_BinningMode_W: 2525,
  Attr_UROM_ExpMode_W: 2528,
  Attr_UROM_AecMainTime_W: 2529,
  Attr_UROM_DynaOffsetGapTime_W: 2530,
  Attr_UROM_DynaOffsetEnable_W: 2531,
  Attr_UROM_ImagePktGapTime_W: 2542,
  Attr_UROM_OutModeCapTrig_W: 2541,
  Attr_UROM_HvgPrepOn_W: 2532,
  Attr_UROM_HvgXRayEnable_W: 2533,
  Attr_UROM_HvgXRayOn_W: 2534,
  Attr_UROM_HvgXRaySyncOut_W: 2535,
  Attr_UROM_HvgXRaySyncIn_W: 2536,
  Attr_UROM_ExpTimeValidPercent_W: 2537,
  Attr_UROM_FreesyncCenterThreshold_W: 2538,
  Attr_UROM_FreesyncEdgeThreshold_W: 2539,
  Attr_UROM_PowSeriesCorrectEnable_W: 2545,
  Attr_UROM_ROIColStartPos_W: 2546,
  Attr_UROM_ROIColEndPos_W: 2547,
  Attr_UROM_ROIRowStartPos_W: 2548,
  Attr_UROM_ROIRowEndPos_W: 2549,
  Attr_UROM_FullWell_W: 2550,
  Attr_UROM_PulseClearTimes_W: 2551,
  Attr_UROM_InnerSubFlow_W: 2552,
  Attr_UROM_SoftwareSubFlow_W: 2553,
  Attr_UROM_SubProductVersion_W: 2554,
  Attr_UROM_WarningTemperature_W: 2555,
  Attr_UROM_PowerOffTemperature_W: 2556,
  Attr_UROM_WLAN_DHCPEnable_W: 2557,
  Attr_UROM_LAN_DHCPEnable_W: 2558,
  Attr_UROM_DHCP_StaticIP_W: 2559,
  Attr_UROM_HWPreoffsetBase_W: 2560,
  Attr_UROM_YCombWiseType_W: 2561,
  Attr_UROM_NetworkBand_W: 2562,
  Attr_UROM_CofPGA_W: 2563,
  Attr_FROM_ProductNo: 3001,
  Attr_FROM_MainVersion: 3002,
  Attr_FROM_ReadVersion: 3003,
  Attr_FROM_McuVersion: 3004,
  Attr_FROM_ArmVersion: 3005,
  Attr_FROM_KernelVersion: 3006,
  Attr_FROM_ProtocolVersion: 3007,
  Attr_FROM_MasterBuildTime: 3008,
  Attr_FROM_SlaveBuildTime: 3009,
  Attr_FROM_McuBuildTime: 3010,
  Attr_FROM_RowPreDelayTime: 3011,
  Attr_FROM_RowPostDelayTime: 3012,
  Attr_FROM_IntegrateTime: 3013,
  Attr_FROM_ZoomMode: 3014,
  Attr_FROM_ExpEnable_SignalLevel: 3015,
  Attr_FROM_SelfClearEnable: 3016,
  Attr_FROM_SelfClearSpanTime: 3017,
  Attr_FROM_SequenceIntervalTime: 3018,
  Attr_FROM_TriggerMode: 3019,
  Attr_FROM_DynamicFlag: 3020,
  Attr_FROM_TubeReadyTime: 3021,
  Attr_FROM_SequenceIntervalTime_HighPrecision: 3022,
  Attr_FROM_SetDelayTime: 3023,
  Attr_FROM_ExpWindowTime: 3025,
  Attr_FROM_SyncExpTime: 3027,
  Attr_FROM_SyncExpTime_HighPrecision: 3028,
  Attr_FROM_VT: 3029,
  Attr_FROM_PGA: 3030,
  Attr_FROM_PrepCapMode: 3032,
  Attr_FROM_SelfCapEnable: 3033,
  Attr_FROM_FluroSync: 3034,
  Attr_FROM_SrcPort: 3035,
  Attr_FROM_SrcIP: 3036,
  Attr_FROM_SrcMAC: 3037,
  Attr_FROM_DestPort: 3038,
  Attr_FROM_DestIP: 3039,
  Attr_FROM_DestMAC: 3040,
  Attr_FROM_SyncboxIP: 3041,
  Attr_FROM_PreviewImgMode: 3044,
  Attr_FROM_HWOffsetType: 3045,
  Attr_FROM_AcquireDelayTime: 3046,
  Attr_FROM_BinningMode: 3047,
  Attr_FROM_ExpMode: 3050,
  Attr_FROM_AecMainTime: 3051,
  Attr_FROM_DynaOffsetGapTime: 3052,
  Attr_FROM_DynaOffsetEnable: 3053,
  Attr_FROM_ImagePktGapTime: 3054,
  Attr_FROM_OutModeCapTrig: 3069,
  Attr_FROM_HvgPrepOn: 3055,
  Attr_FROM_HvgXRayEnable: 3056,
  Attr_FROM_HvgXRayOn: 3057,
  Attr_FROM_HvgXRaySyncOut: 3058,
  Attr_FROM_HvgXRaySyncIn: 3059,
  Attr_FROM_CbxBuildTime: 3060,
  Attr_FROM_SubProductNo: 3061,
  Attr_FROM_SerialNo: 3062,
  Attr_FROM_ImageChType: 3063,
  Attr_FROM_ImageChProtocol: 3064,
  Attr_FROM_HWGainEnable: 3065,
  Attr_FROM_ExpTimeValidPercent: 3066,
  Attr_FROM_FreesyncCenterThreshold: 3067,
  Attr_FROM_FreesyncEdgeThreshold: 3068,
  Attr_FROM_FreesyncSubFlow: 3070,
  Attr_FROM_AutoSleepIdleTime: 3071,
  Attr_FROM_FSyncParalClearTimes: 3072,
  Attr_FROM_FSyncFastScanCpvCycle: 3073,
  Attr_FROM_FSyncTriggerCheckTimeout: 3074,
  Attr_FROM_FSyncSegmentThreshold: 3075,
  Attr_FROM_FSyncLineThreshold: 3076,
  Attr_FROM_FSyncFalseTriggerUnresponseStageTime: 3077,
  Attr_FROM_FSyncParalClearLine: 3078,
  Attr_FROM_PowSeriesCorrectEnable: 3079,
  Attr_FROM_PulseClearTimes: 3080,
  Attr_FROM_ROIColStartPos: 3081,
  Attr_FROM_ROIColEndPos: 3082,
  Attr_FROM_ROIRowStartPos: 3083,
  Attr_FROM_ROIRowEndPos: 3084,
  Attr_FROM_FullWell: 3085,
  Attr_FROM_InnerSubFlow: 3086,
  Attr_FROM_SoftwareSubFlow: 3087,
  Attr_FROM_LowPowerWarnThreshold: 3088,
  Attr_FROM_ShutDownWarnThreshold: 3089,
  Attr_FROM_MainMBCpuVersion: 3090,
  Attr_FROM_MainMBCpuBuildTime: 3091,
  Attr_FROM_CofPGA: 3092,
  Attr_FROM_XCombWiseType: 3093,
  Attr_FROM_YCombWiseType: 3094,
  Attr_FROM_WarningTemperature: 3095,
  Attr_FROM_PowerOffTemperature: 3096,
  Attr_FROM_HWPreoffsetBase: 3097,
  Attr_FROM_WLAN_DHCPEnable: 3098,
  Attr_FROM_LAN_DHCPEnable: 3099,
  Attr_FROM_AutoWakeUpTime: 3100,
  Attr_FROM_AutoPowerOffTimeOut: 3101,
  Attr_FROM_ProtocolEditionVersion: 3102,
  Attr_FROM_SubProductVersion: 3103,
  Attr_FROM_NetworkBand: 3104,
  Attr_FROM_Debug1: 3200,
  Attr_FROM_Debug2: 3201,
  Attr_FROM_Debug3: 3202,
  Attr_FROM_Debug4: 3203,
  Attr_FROM_Debug5: 3204,
  Attr_FROM_Debug6: 3205,
  Attr_FROM_Debug7: 3206,
  Attr_FROM_Debug8: 3207,
  Attr_FROM_Debug9: 3208,
  Attr_FROM_Debug10: 3209,
  Attr_FROM_Debug11: 3210,
  Attr_FROM_Debug12: 3211,
  Attr_FROM_Debug13: 3212,
  Attr_FROM_Debug14: 3213,
  Attr_FROM_Debug15: 3214,
  Attr_FROM_Debug16: 3215,
  Attr_FROM_Debug17: 3216,
  Attr_FROM_Debug18: 3217,
  Attr_FROM_Debug19: 3218,
  Attr_FROM_Debug20: 3219,
  Attr_FROM_Debug21: 3220,
  Attr_FROM_Debug22: 3221,
  Attr_FROM_Debug23: 3222,
  Attr_FROM_Debug24: 3223,
  Attr_FROM_Debug25: 3224,
  Attr_FROM_Debug26: 3225,
  Attr_FROM_Debug27: 3226,
  Attr_FROM_Debug28: 3227,
  Attr_FROM_Debug29: 3228,
  Attr_FROM_Debug30: 3229,
  Attr_FROM_Debug31: 3230,
  Attr_FROM_Debug32: 3231,
  Attr_FROM_Test1: 3232,
  Attr_FROM_Test2: 3233,
  Attr_FROM_Test3: 3234,
  Attr_FROM_Test4: 3235,
  Attr_FROM_Test5: 3236,
  Attr_FROM_Test6: 3237,
  Attr_FROM_Test7: 3238,
  Attr_FROM_Test8: 3239,
  Attr_FROM_Test9: 3240,
  Attr_FROM_Test10: 3241,
  Attr_FROM_Test11: 3242,
  Attr_FROM_Test12: 3243,
  Attr_FROM_Test13: 3244,
  Attr_FROM_Test14: 3245,
  Attr_FROM_Test15: 3246,
  Attr_FROM_Test16: 3247,
  Attr_FROM_ShockMcuVersion: 3248,
  Attr_FROM_ShockMcuBuildTime: 3249,
  Attr_FROM_ProductConfigCode: 3250,
  Attr_FROM_RowPreDelayTime_W: 3511,
  Attr_FROM_RowPostDelayTime_W: 3512,
  Attr_FROM_IntegrateTime_W: 3513,
  Attr_FROM_ZoomMode_W: 3514,
  Attr_FROM_ExpEnable_SignalLevel_W: 3515,
  Attr_FROM_SelfClearEnable_W: 3516,
  Attr_FROM_SelfClearSpanTime_W: 3517,
  Attr_FROM_SequenceIntervalTime_W: 3518,
  Attr_FROM_TriggerMode_W: 3519,
  Attr_FROM_DynamicFlag_W: 3520,
  Attr_FROM_TubeReadyTime_W: 3521,
  Attr_FROM_SequenceIntervalTime_HighPrecision_W: 3522,
  Attr_FROM_SetDelayTime_W: 3523,
  Attr_FROM_ExpWindowTime_W: 3525,
  Attr_FROM_VT_W: 3529,
  Attr_FROM_PGA_W: 3530,
  Attr_FROM_PrepCapMode_W: 3532,
  Attr_FROM_SelfCapEnable_W: 3533,
  Attr_FROM_FluroSync_W: 3534,
  Attr_FROM_SrcIP_W: 3536,
  Attr_FROM_SrcMAC_W: 3537,
  Attr_FROM_DestPort_W: 3538,
  Attr_FROM_DestIP_W: 3539,
  Attr_FROM_DestMAC_W: 3540,
  Attr_FROM_SyncboxIP_W: 3541,
  Attr_FROM_PreviewImgMode_W: 3544,
  Attr_FROM_HWOffsetType_W: 3545,
  Attr_FROM_AcquireDelayTime_W: 3546,
  Attr_FROM_BinningMode_W: 3547,
  Attr_FROM_ExpMode_W: 3550,
  Attr_FROM_AecMainTime_W: 3551,
  Attr_FROM_DynaOffsetGapTime_W: 3552,
  Attr_FROM_DynaOffsetEnable_W: 3553,
  Attr_FROM_ImagePktGapTime_W: 3554,
  Attr_FROM_OutModeCapTrig_W: 3569,
  Attr_FROM_HvgPrepOn_W: 3555,
  Attr_FROM_HvgXRayEnable_W: 3556,
  Attr_FROM_HvgXRayOn_W: 3557,
  Attr_FROM_HvgXRaySyncOut_W: 3558,
  Attr_FROM_HvgXRaySyncIn_W: 3559,
  Attr_FROM_CbxBuildTime_W: 3560,
  Attr_FROM_SubProductNo_W: 3561,
  Attr_FROM_SerialNo_W: 3562,
  Attr_FROM_ImageChType_W: 3563,
  Attr_FROM_ImageChProtocol_W: 3564,
  Attr_FROM_HWGainEnable_W: 3565,
  Attr_FROM_ExpTimeValidPercent_W: 3566,
  Attr_FROM_FreesyncCenterThreshold_W: 3567,
  Attr_FROM_FreesyncEdgeThreshold_W: 3568,
  Attr_FROM_FreesyncSubFlow_W: 3570,
  Attr_FROM_AutoSleepIdleTime_W: 3571,
  Attr_FROM_FSyncParalClearTimes_W: 3572,
  Attr_FROM_FSyncFastScanCpvCycle_W: 3573,
  Attr_FROM_FSyncTriggerCheckTimeout_W: 3574,
  Attr_FROM_FSyncSegmentThreshold_W: 3575,
  Attr_FROM_FSyncLineThreshold_W: 3576,
  Attr_FROM_FSyncFalseTriggerUnresponseStageTime_W: 3577,
  Attr_FROM_FSyncParalClearLine_W: 3578,
  Attr_FROM_PowSeriesCorrectEnable_W: 3579,
  Attr_FROM_PulseClearTimes_W: 3580,
  Attr_FROM_ROIColStartPos_W: 3581,
  Attr_FROM_ROIColEndPos_W: 3582,
  Attr_FROM_ROIRowStartPos_W: 3583,
  Attr_FROM_ROIRowEndPos_W: 3584,
  Attr_FROM_FullWell_W: 3585,
  Attr_FROM_InnerSubFlow_W: 3586,
  Attr_FROM_SoftwareSubFlow_W: 3587,
  Attr_FROM_LowPowerWarnThreshold_W: 3588,
  Attr_FROM_ShutDownWarnThreshold_W: 3589,
  Attr_FROM_CofPGA_W: 3590,
  Attr_FROM_XCombWiseType_W: 3591,
  Attr_FROM_YCombWiseType_W: 3592,
  Attr_FROM_WarningTemperature_W: 3593,
  Attr_FROM_PowerOffTemperature_W: 3594,
  Attr_FROM_HWPreoffsetBase_W: 3595,
  Attr_FROM_WLAN_DHCPEnable_W: 3596,
  Attr_FROM_LAN_DHCPEnable_W: 3597,
  Attr_FROM_AutoWakeUpTime_W: 3598,
  Attr_FROM_AutoPowerOffTimeOut_W: 3599,
  Attr_FROM_NetworkBand_W: 3600,
  Attr_FROM_Debug1_W: 3700,
  Attr_FROM_Debug2_W: 3701,
  Attr_FROM_Debug3_W: 3702,
  Attr_FROM_Debug4_W: 3703,
  Attr_FROM_Debug5_W: 3704,
  Attr_FROM_Debug6_W: 3705,
  Attr_FROM_Debug7_W: 3706,
  Attr_FROM_Debug8_W: 3707,
  Attr_FROM_Debug9_W: 3708,
  Attr_FROM_Debug10_W: 3709,
  Attr_FROM_Debug11_W: 3710,
  Attr_FROM_Debug12_W: 3711,
  Attr_FROM_Debug13_W: 3712,
  Attr_FROM_Debug14_W: 3713,
  Attr_FROM_Debug15_W: 3714,
  Attr_FROM_Debug16_W: 3715,
  Attr_FROM_Debug17_W: 3716,
  Attr_FROM_Debug18_W: 3717,
  Attr_FROM_Debug19_W: 3718,
  Attr_FROM_Debug20_W: 3719,
  Attr_FROM_Debug21_W: 3720,
  Attr_FROM_Debug22_W: 3721,
  Attr_FROM_Debug23_W: 3722,
  Attr_FROM_Debug24_W: 3723,
  Attr_FROM_Debug25_W: 3724,
  Attr_FROM_Debug26_W: 3725,
  Attr_FROM_Debug27_W: 3726,
  Attr_FROM_Debug28_W: 3727,
  Attr_FROM_Debug29_W: 3728,
  Attr_FROM_Debug30_W: 3729,
  Attr_FROM_Debug31_W: 3730,
  Attr_FROM_Debug32_W: 3731,
  Attr_FROM_Test1_W: 3732,
  Attr_FROM_Test2_W: 3733,
  Attr_FROM_Test3_W: 3734,
  Attr_FROM_Test4_W: 3735,
  Attr_FROM_Test5_W: 3736,
  Attr_FROM_Test6_W: 3737,
  Attr_FROM_Test7_W: 3738,
  Attr_FROM_Test8_W: 3739,
  Attr_FROM_Test9_W: 3740,
  Attr_FROM_Test10_W: 3741,
  Attr_FROM_Test11_W: 3742,
  Attr_FROM_Test12_W: 3743,
  Attr_FROM_Test13_W: 3744,
  Attr_FROM_Test14_W: 3745,
  Attr_FROM_Test15_W: 3746,
  Attr_FROM_Test16_W: 3747,
  Attr_FROM_ProductConfigCode_W: 3748,
  Attr_Wifi_AP_SSID: 4001,
  Attr_Wifi_AP_Key: 4002,
  Attr_Wifi_AP_CountryCode: 4003,
  Attr_Wifi_AP_FrequencySel: 4004,
  Attr_Wifi_AP_BandWidthSel: 4005,
  Attr_Wifi_AP_ChannelSel: 4006,
  Attr_Wifi_AP_SecuritySel: 4007,
  Attr_Wifi_AP_ApModeEn: 4008,
  Attr_Wifi_AP_DhcpServerEn: 4009,
  Attr_Wifi_Client_ListNum: 4010,
  Attr_Wifi_Client_CurrentSel: 4011,
  Attr_Wifi_Client_SSID0: 4012,
  Attr_Wifi_Client_Key0: 4013,
  Attr_Wifi_Client_SSID1: 4014,
  Attr_Wifi_Client_Key1: 4015,
  Attr_Wifi_Client_SSID2: 4016,
  Attr_Wifi_Client_Key2: 4017,
  Attr_Wifi_Client_SSID3: 4018,
  Attr_Wifi_Client_Key3: 4019,
  Attr_Wifi_Client_SSID4: 4020,
  Attr_Wifi_Client_Key4: 4021,
  Attr_Wifi_Client_SSID5: 4022,
  Attr_Wifi_Client_Key5: 4023,
  Attr_Wifi_Client_SSID6: 4024,
  Attr_Wifi_Client_Key6: 4025,
  Attr_Wifi_Client_SSID7: 4026,
  Attr_Wifi_Client_Key7: 4027,
  Attr_Wifi_Client_SSID8: 4028,
  Attr_Wifi_Client_Key8: 4029,
  Attr_Wifi_Client_SSID9: 4030,
  Attr_Wifi_Client_Key9: 4031,
  Attr_Wifi_AP_CountryCode_ByLetter: 4032,
  Attr_Wifi_AP_SSID_W: 4501,
  Attr_Wifi_AP_Key_W: 4502,
  Attr_Wifi_AP_CountryCode_W: 4503,
  Attr_Wifi_AP_FrequencySel_W: 4504,
  Attr_Wifi_AP_BandWidthSel_W: 4505,
  Attr_Wifi_AP_ChannelSel_W: 4506,
  Attr_Wifi_AP_SecuritySel_W: 4507,
  Attr_Wifi_AP_ApModeEn_W: 4508,
  Attr_Wifi_AP_DhcpServerEn_W: 4509,
  Attr_Wifi_Client_ListNum_W: 4510,
  Attr_Wifi_Client_CurrentSel_W: 4511,
  Attr_Wifi_Client_SSID0_W: 4512,
  Attr_Wifi_Client_Key0_W: 4513,
  Attr_Wifi_Client_SSID1_W: 4514,
  Attr_Wifi_Client_Key1_W: 4515,
  Attr_Wifi_Client_SSID2_W: 4516,
  Attr_Wifi_Client_Key2_W: 4517,
  Attr_Wifi_Client_SSID3_W: 4518,
  Attr_Wifi_Client_Key3_W: 4519,
  Attr_Wifi_Client_SSID4_W: 4520,
  Attr_Wifi_Client_Key4_W: 4521,
  Attr_Wifi_Client_SSID5_W: 4522,
  Attr_Wifi_Client_Key5_W: 4523,
  Attr_Wifi_Client_SSID6_W: 4524,
  Attr_Wifi_Client_Key6_W: 4525,
  Attr_Wifi_Client_SSID7_W: 4526,
  Attr_Wifi_Client_Key7_W: 4527,
  Attr_Wifi_Client_SSID8_W: 4528,
  Attr_Wifi_Client_Key8_W: 4529,
  Attr_Wifi_Client_SSID9_W: 4530,
  Attr_Wifi_Client_Key9_W: 4531,
  Attr_Wifi_AP_CountryCode_ByLetter_W: 4532,
  Attr_WorkDir: 5001,
  Attr_State: 5002,
  Attr_ConnState: 5003,
  Attr_CurrentTask: 5004,
  Attr_CurrentTransaction: 5005,
  Attr_FsmState: 5006,
  Attr_Width: 5007,
  Attr_Height: 5008,
  Attr_PrevImg_Width: 5009,
  Attr_PrevImg_Height: 5010,
  Attr_Authority: 5011,
  Attr_ConnState_CmdCh: 5012,
  Attr_ConnState_ImgCh: 5013,
  Attr_ElapsedExpWindowTime: 5014,
  Attr_FWUpdateProgress: 5015,
  Attr_ImageTransProgress: 5016,
  Attr_RdResult_T1: 5017,
  Attr_RdResult_T2: 5018,
  Attr_RdResult_Humidity: 5019,
  Attr_RdResult_Shock_Threshold: 5020,
  Attr_CurrentSubset: 5021,
  Attr_CurrentCorrectOption: 5022,
  Attr_OffsetValidityState: 5023,
  Attr_GainValidityState: 5024,
  Attr_DefectValidityState: 5025,
  Attr_LagValidityState: 5026,
  Attr_GhostValidityState: 5027,
  Attr_OffsetTotalFrames: 5028,
  Attr_OffsetValidFrames: 5029,
  Attr_GainTotalFrames: 5030,
  Attr_GainValidFrames: 5031,
  Attr_DefectTotalFrames: 5032,
  Attr_DefectValidFrames: 5033,
  Attr_LagTotalFrames: 5034,
  Attr_LagValidFrames: 5035,
  Attr_Battery_Exist: 5036,
  Attr_Battery_Remaining: 5037,
  Attr_Battery_ChargingStatus: 5038,
  Attr_Battery_PowerWarnStatus: 5039,
  Attr_Battery_FullChargeCapacity: 5067,
  Attr_Battery_DesignCapacity: 5068,
  Attr_Battery_CycleCount: 5069,
  Attr_Battery_Temperature: 5070,
  Attr_Battery_RelativeStateOfCharge: 5071,
  Attr_Battery_AbsoluteStateOfCharge: 5072,
  Attr_Battery_RemainingCapacity: 5073,
  Attr_Battery_Manufacture: 5074,
  Attr_Battery_SN: 5075,
  Attr_Battery_DeviceName: 5076,
  Attr_HallSensorValue: 5077,
  Attr_NetworkInterface: 5040,
  Attr_WifiStatu_LinkedAP: 5041,
  Attr_WifiStatu_WorkingBand: 5042,
  Attr_WifiStatu_WorkingSignalIntensity: 5043,
  Attr_WifiStatu_WorkingLinkQuality: 5044,
  Attr_WifiStatu_WorkingTxPower: 5045,
  Attr_HwTmpl_Offset_Enable: 5046,
  Attr_HwTmpl_Offset_ValidIndex: 5047,
  Attr_HwTmpl_Offset_FileCount: 5048,
  Attr_HwTmpl_Gain_Enable: 5049,
  Attr_HwTmpl_Gain_ValidIndex: 5050,
  Attr_HwTmpl_Gain_FileCount: 5051,
  Attr_HwTmpl_MostGain_Enable: 5052,
  Attr_HwTmpl_MostGain_ValidIndex: 5053,
  Attr_HwTmpl_MostGain_FileCount: 5054,
  Attr_HwTmpl_Defect_Enable: 5055,
  Attr_HwTmpl_Defect_ValidIndex: 5056,
  Attr_HwTmpl_Defect_FileCount: 5057,
  Attr_HwTmpl_Lag_Enable: 5058,
  Attr_HwTmpl_Lag_ValidIndex: 5059,
  Attr_HwTmpl_Lag_FileCount: 5060,
  Attr_CorrectionPrepared: 5061,
  Attr_RdResult_OutExpState: 5062,
  Attr_RdResult_AutoSleepState: 5063,
  Attr_Battery_ExternalPower: 5064,
  Attr_GCU_OnlineState: 5065,
  Attr_ClippingValue: 5066,
  Attr_FD_LifeTime: 5078,
  Attr_FD_PowerOnCount: 5079,
  Attr_FD_PowerOffCount: 5080,
  Attr_WifiInfo_WirelessAPMAC: 5081,
  Attr_WifiInfo_WirelessClientMAC: 5082,
  Attr_Temperature_State: 5083,
  Attr_RdResult_RiskA_Shock_Threshold: 5084,
  Attr_RdResult_RiskB_Shock_Threshold: 5085,
  Attr_RdResult_RiskC_Shock_Threshold: 5086,
  Attr_RdResult_RiskB2A_Shock_Threshold: 5087,
  Attr_RdResult_RiskC2B_Shock_Threshold: 5088,
  Attr_RdResult_RiskD2C_Shock_Threshold: 5089,
  Attr_MultiGainPointNumber: 5090,
  Attr_Network_LAN_IP_Addr: 5300,
  Attr_Network_WLAN_IP_Addr: 5301,
  Attr_Network_WLAN_DHCPEnable: 5498,
  Attr_Network_LAN_DHCPEnable: 5499,
  Attr_Network_LAN_IP_Addr_W: 5500,
  Attr_Network_WLAN_IP_Addr_W: 5501,
  Attr_Network_WLAN_DHCPEnable_W: 5698,
  Attr_Network_LAN_DHCPEnable_W: 5699,
};

const ENM_TRIGGERMODE = {
  Enm_TriggerMode_Outer: 0,
  Enm_TriggerMode_Inner: 1,
  Enm_TriggerMode_Soft: 2,
  Enm_TriggerMode_Prep: 3,
  Enm_TriggerMode_Service: 4,
  Enm_TriggerMode_FreeSync: 5,
};

const CONNECTION_STATE = {
  Unknown: 0, // not initialized
  HardwareBreak: 1, // specified communication hardware can not find, or been plugged out
  NotConnected: 2, // hardware exist but not ready for communication
  LowRate: 3, // connected but in bad situation
  OK: 4, // normal connected
  toString: (state) => getKeyByValue(CONNECTION_STATE, state, 'Unknown'),
};

const DETECTOR_STATE = {
  Unknown: 0,
  Ready: 1,
  Busy: 2,
  Sleeping: 3,
  toString: (state) => getKeyByValue(DETECTOR_STATE, state, 'Unknown'),
};

const DETECTOR_ACQUISITION_STATE = {
  Unknown: 0,
  Manual: 1,
  AutoTriggerPending: 2,
  AutoTrigger: 3,
  toString: (state) => getKeyByValue(DETECTOR_ACQUISITION_STATE, state, 'Unknown'),
};

const DETECTOR_EVENTS = {
  CONNECTED: 'connected',
  CONNECTION_FAILED: 'connection_failed',
  DISCONNECTED: 'disconnected',
  ACQUISITION_START: 'acquisition_start',
  ACQUISITION_END: 'acquisition_end',
  PREVIEW_IMAGE: 'preview_image',
  NEW_IMAGE: 'new_image',
  RETRANSFER_IMAGE: 'retransfer_image',
  STATUS_CHANGE: 'state_change',
  ACQUISITION_STATUS_CHANGE: 'acquisition_state_change',
  CONNECTION_STATUS_CHANGE: 'connection_state_change',
  RAW_EVENT: 'raw_event',
};

const DetectorManager = {
  EVENTS: {
    NEW_DETECTOR: 'new_detector',
    DETECTOR_REMOVED: 'detector_removed',
  },
};

const DETECTOR_IMPLEMENTATION = {
  IRAY: 0,
  THALES: 1,
  toString: (state) => getKeyByValue(DETECTOR_IMPLEMENTATION, state, `${state}`),
};

const DETECTOR_KINDS = ['GER', 'THALES'];

const detectorKindToImplementation = (kind) => {
  switch (kind) {
    case 'GER':
      return DETECTOR_IMPLEMENTATION.IRAY;
    case 'THALES':
      return DETECTOR_IMPLEMENTATION.THALES;
    default:
      return undefined;
  }
};
const detectorImplementationToKind = (implementation) => {
  switch (implementation) {
    case DETECTOR_IMPLEMENTATION.IRAY:
      return 'GER';
    case DETECTOR_IMPLEMENTATION.THALES:
      return 'THALES';
    default:
      return undefined;
  }
};

export {
  IRAY_RETCODE,
  IRAY_COMMANDS,
  IRAY_ATTRIBUTES,
  IRAY_EVENTS,
  ENM_TRIGGERMODE,
  CONNECTION_STATE,
  DETECTOR_STATE,
  DETECTOR_ACQUISITION_STATE,
  DETECTOR_EVENTS,
  DETECTOR_IMPLEMENTATION,
  DetectorManager,
  getKeyByValue,
  isCommandValid,
  detectorKindToImplementation,
  detectorImplementationToKind,
};
