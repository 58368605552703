import React from 'react';
import { injectIntl } from 'react-intl';
import * as pt from 'prop-types';

import ToolType from '../../types/ToolType';
import { updateToolsList } from './utils';
import forceImageUpdate from 'app/CornerstoneTools/forceImageUpdate';

/**
 * Generic class usable to display activation button for tools with no coords PreComputation.
 * Most of cornerstoneTools are of this kind.
 * For now the button, display the name of the tool but later improvements will allow for icon.
 */
const ActivatableToolButtonImpl = ({
  toolsList,
  name,
  produceImageToolsList,
  produceCommonToolsList,
  setToolStateFn = undefined,
  content = undefined,
  icon = undefined,
  tooltipId,
  focusedElement,
  intl,
  disabledState = 'passive',
} = {}) => {
  /* eslint-disable react/destructuring-assignment */
  const setToolState = (newState) => {
    updateToolsList(
      toolsList,
      name,
      produceImageToolsList,
      produceCommonToolsList,
      setToolStateFn,
      newState
    );
    forceImageUpdate(focusedElement);
  };
  /* eslint-enable react/destructuring-assignment */

  const isActive = () => toolsList[name].state === 'active';

  const switchToolState = () => (isActive() ? setToolState(disabledState) : setToolState('active'));

  const activeClass = isActive() ? 'active' : '';
  return (
    <button
      type="button"
      className={`picoxia tool-button ${activeClass}`}
      onPointerDown={switchToolState}
      title={intl.formatMessage({ id: tooltipId })}
    >
      {content ?? <i className={`svg-icon ${icon}`} />}
    </button>
  );
};

ActivatableToolButtonImpl.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  intl: pt.shape().isRequired,
  produceImageToolsList: pt.func.isRequired,
  produceCommonToolsList: pt.func.isRequired,
  tooltipId: pt.string.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  content: pt.oneOfType([pt.string, pt.shape()]),
  icon: pt.string,
  disabledState: pt.string,
  setToolStateFn: pt.func,
};

ActivatableToolButtonImpl.defaultProps = {
  setToolStateFn: null,
  disabledState: 'passive',
  content: undefined,
  icon: undefined,
};

const ActivatableToolButton = injectIntl(ActivatableToolButtonImpl);

export default ActivatableToolButton;

const makeActivatableToolButton = (content, tooltipId) => (props) =>
  (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ActivatableToolButton content={content} tooltipId={tooltipId} {...props} />
  );

export { makeActivatableToolButton };
