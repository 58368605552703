import React from 'react';
import { useIntl } from 'react-intl';
import { Confirm } from 'semantic-ui-react';

/**
 *
 * @param {Object} props
 *
 * @param {() => void} props.onConfirm Callback called when user confirm action
 * @param {React.ElementType} props.trigger Trigger element that will trigger confirm modal open
 * @param {string} [props.confirmId=general.confirm] Intl id used to display confirm button
 * @param {string} [props.cancelId=general.cancel] Intl id used to display confirm button
 * @param {string} [props.contentId] Intl id used to display content
 * @param {React.ElementType} [props.confirmButton] Element allowing customization of confirm button, override confirmId
 * @param {React.ElementType} [props.cancelButton] Element allowing customization of cancel button, override cancelId
 * @param {React.ElementType} [props.content] Element allowing customization of content, override contentId
 * }
 *
 *
 * @returns {React.Component}
 */
export default function ConfirmWithIntl({
  onConfirm,
  trigger,
  contentId,
  confirmId,
  cancelId,
  confirmButton,
  cancelButton,
  content,
} = {}) {
  const intl = useIntl();

  return (
    <Confirm
      trigger={trigger}
      onConfirm={onConfirm}
      confirmButton={confirmButton ?? intl.formatMessage({ id: confirmId ?? 'general.confirm' })}
      cancelButton={cancelButton ?? intl.formatMessage({ id: cancelId ?? 'general.cancel' })}
      content={
        content ??
        intl.formatMessage({
          id: contentId ?? 'adminPage.deleteMember.confirm',
        })
      }
    />
  );
}
