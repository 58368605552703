import React from 'react';
import * as pt from 'prop-types';
import ElectronSnipper from 'app/components/Snipper/ElectronSnipper';
import getElectron from 'app/native/node/electron';

/**
 * @param {{
 * onImageReady:(imageFiles:LoadableImageFile[])=>void,
 * }} props
 * @returns
 */
function Snipper({ onImageReady }) {
  return getElectron() ? <ElectronSnipper onImageReady={onImageReady} /> : null;
}

Snipper.propTypes = {
  onImageReady: pt.func.isRequired,
};

export default React.memo(Snipper);
