import csc from 'cornerstone-core';
import cst, { addToolState } from 'cornerstone-tools';
import csm from 'cornerstone-math';
import * as _ from 'lodash';

const getTextCallback = (doneChangingTextCallback) => {
  // Cannot be used inside electron.
  doneChangingTextCallback(prompt('Enter your annotation:'));
};

/**
 * This function is a callback to be overwritten in order to provide the wanted feature
 * modal, overlay, popup or any kind of interaction with the user to be able to update
 * the text marker label.
 *
 * @param  {Object} data
 * @param  {Object} eventData
 * @param  {doneChangingTextCallback} doneChangingTextCallback
 * @returns {void}
 */
const changeTextCallback = (data, eventData, doneChangingTextCallback) => {
  // eslint-disable-next-line no-alert
  doneChangingTextCallback(data, prompt('Change your annotation:'));
};

/**
 * Extend 'TextMarkerTool' to allow adding new markers instead of circling through existing
 * ones
 */
class TextMarkerTool extends cst.TextMarkerTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'TextMarker',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        getTextCallback,
        changeTextCallback,
      },
    };

    super(_.merge({}, defaultProps, props));
  }

  /**
   * This function never return a value, this is intended. The data will be added once the user
   * enter the text of the annotation.
   */
  createNewMeasurement = () => {};

  addTextMarker = (eventData) => {
    const { element } = eventData;
    const config = this.configuration;

    // Create the measurement data for this tool with the end handle activated
    const measurementData = {
      visible: true,
      active: false,
      color: undefined,
      handles: {
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: false,
          active: false,
          hasBoundingBox: true,
        },
      },
    };

    // Create a rectangle representing the image
    const imageRect = {
      left: 0,
      top: 0,
      width: eventData.image.width,
      height: eventData.image.height,
    };

    // Check if the current handle is outside the image,
    // If it is, prevent the handle creation
    if (!csm.point.insideRect(measurementData.handles.end, imageRect)) {
      return;
    }

    config.getTextCallback((text) => {
      if (!text) return;

      measurementData.text = text;
      addToolState(element, this.name, measurementData);
      csc.updateImage(element);
    }, eventData);
  };

  mouseUpCallback = (evt) => {
    this.addTextMarker(evt.detail);
  };

  mouseClickCallback = (evt) => {
    this.addTextMarker(evt.detail);
  };
}

export default TextMarkerTool;
