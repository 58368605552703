import { SET_MANAGER_PERMISSIONS, UPDATE_PERMISSIONS } from './constants';

/**
 * Update managerPermissions
 */
export function updateManagerPermissions(managerPermissions = {}) {
  return {
    type: SET_MANAGER_PERMISSIONS,
    managerPermissions,
  };
}

/**
 * Update permissions
 */
export function updatePermissions(permissions = []) {
  return {
    type: UPDATE_PERMISSIONS,
    permissions,
  };
}
