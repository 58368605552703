import React from 'react';
import Select from 'react-select';
import * as _ from 'lodash';
import { REACT_SELECT_TOOL_BAR_STYLE } from './utils';

const CUSTOM_STYLES = {
  ...REACT_SELECT_TOOL_BAR_STYLE,
  valueContainer: (provided) => ({ ...provided, padding: '0px', overflow: 'visible' }),
  input: () => ({ opacity: '0' }),
};

class ToolsDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedToolName: null, menuIsOpen: false };
  }

  getSingleValue = (props) => <>{props.data.tool}</>;

  getCustomOption = ({ data, innerRef, innerProps }) => (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <div className="tools-overflow-dropdown-option" {...innerProps} ref={innerRef}>
      {data.tool}
    </div>
  );

  onBlurCheckStillInsideParent = (evt) => {
    // We do not consider focus lost as long as we are inside the element
    const focusStillInSelect = this.selectRef.contains(evt.relatedTarget);
    if (!focusStillInSelect) this.setState({ menuIsOpen: false });
  };

  render() {
    const { tools } = this.props;
    const { selectedToolName, menuIsOpen } = this.state;
    const options = tools.map((tool) => ({
      tool,
      name: tool.props.name,
    }));

    const BigToolsDropdownIndicator = ({ innerProps }) => (
      <div
        className="tools-dropdown large"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...innerProps}
        onClick={() =>
          this.setState((state) => ({
            menuIsOpen: !state.menuIsOpen,
          }))
        }
        role="presentation"
      >
        <i className="svg-icon angle-down" />
      </div>
    );

    return (
      <div
        className="tools-overflow-dropdown-select"
        ref={(e) => {
          this.selectRef = e;
        }}
      >
        <Select
          classNamePrefix="tools-overflow-dropdown-select"
          options={options}
          components={{
            DropdownIndicator: BigToolsDropdownIndicator,
            IndicatorSeparator: null,
            Option: this.getCustomOption,
            SingleValue: this.getSingleValue,
          }}
          styles={CUSTOM_STYLES}
          onChange={(value) => this.setState({ selectedToolName: value.name, menuIsOpen: false })}
          onBlur={this.onBlurCheckStillInsideParent}
          menuIsOpen={menuIsOpen}
          value={_.find(options, { name: selectedToolName }) || options[0]}
          filterOption={() => true}
          isClearable={false}
          openMenuOnClick={false}
        />
      </div>
    );
  }
}

export default ToolsDropdown;
