/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LanguageDropdown from 'app/components/LanguageDropdown';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import MenuDropdownItem from './MenuDropdownItem';

import './style.scss';
import LogoutButton from 'app/components/LogoutButton';
import FormContact from 'app/components/Form/Contact';

const NavbarBurger = ({
  leftItems,
  middleItems,
  isLoggedIn,
  hasSubscribed,
  renderNavigationLink,
}) => {
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const intl = useIntl();

  // eslint-disable-next-line arrow-body-style
  const renderNavItems = (items) => {
    return items.map((item) => {
      if (item.isLoggedInNeeded === true && !isLoggedIn) {
        return null;
      }
      if (item.hasNotSubscribedNeeded === true && hasSubscribed) {
        return null;
      }
      if ('icon' in item) {
        return null;
      }
      if ('menuName' in item) {
        return (
          <MenuDropdownItem
            item={item}
            menuIsOpen={menuIsOpen}
            renderNavigationLink={renderNavigationLink}
            setMenuIsOpen={setMenuIsOpen}
            key={item.menuName}
          />
        );
      }
      return renderNavigationLink(
        item.routes,
        intl.formatMessage({ id: item.messageId }),
        item.titleMessageId,
        'navbar-burger__link',
        () => setMenuIsOpen(false)
      );
    });
  };

  const renderAccountLinks = () => {
    if (isLoggedIn) {
      return (
        <>
          <FormContact onClick={() => setMenuIsOpen(false)} device="mobile" />
          {renderNavigationLink(
            ['/account'],
            intl.formatMessage({ id: 'header.account.title' }),
            'header.account.title',
            'navbar-burger__link',
            () => setMenuIsOpen(false)
          )}

          <LogoutButton onClick={() => setMenuIsOpen(false)} />
        </>
      );
    }

    return (
      <>
        {renderNavigationLink(
          ['/register'],
          intl.formatMessage({ id: 'button.register.text' }),
          'button.register.text',
          'navbar-burger__link',
          () => setMenuIsOpen(false)
        )}
        {renderNavigationLink(
          ['/login'],
          intl.formatMessage({ id: 'loginButton.buttonText' }),
          'loginButton.buttonText',
          'navbar-burger__link',
          () => setMenuIsOpen(false)
        )}
      </>
    );
  };

  const thereAreMiddleItems = middleItems.length !== 0;

  const menuClassName = menuIsOpen ? 'navbar-burger__menu open' : 'navbar-burger__menu';

  const overlayClassName = menuIsOpen ? 'navbar-burger__overlay open' : 'navbar-burger__overlay';

  return (
    <nav className="ui container nav-header nav navbar-burger">
      <div className="ui large secondary inverted pointing menu navbar-burger__container">
        {renderNavigationLink(
          ['/'],
          <Icon name="home" className="headerNav" />,
          'header.home',
          'navbar-burger__link navbar-burger__link--home',
          () => setMenuIsOpen(false)
        )}
        {!menuIsOpen && (
          <button type="button" className="burger-icon" onClick={() => setMenuIsOpen(true)}>
            <div className="burger-icon__bar" />
            <div className="burger-icon__bar" />
            <div className="burger-icon__bar" />
          </button>
        )}
        {menuIsOpen && (
          <button type="button" className="cross-icon" onClick={() => setMenuIsOpen(false)}>
            <div className="cross-icon__bar cross-icon__bar--left" />
            <div className="cross-icon__bar cross-icon__bar--right" />
          </button>
        )}
      </div>

      <div className={overlayClassName} onClick={() => setMenuIsOpen(false)} />
      <ul className={menuClassName}>
        <div className="navbar-burger__content">
          {renderNavItems(leftItems)}
          {thereAreMiddleItems && <span className="navbar-burger__separator" />}
          {renderNavItems(middleItems)}
          <span className="navbar-burger__separator" />
          {renderAccountLinks()}
        </div>
        <div className="navbar-burger__language-dropdown">
          <LanguageDropdown location="navbarBurger" />
        </div>
      </ul>
    </nav>
  );
};

export default NavbarBurger;
