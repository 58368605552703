import React, { useEffect, useState } from 'react';
import { syncHistoryWithStore } from 'react-router-redux';
import { Router, browserHistory } from 'react-router';
import { useSelector } from 'react-redux';
import createRoutes from 'app/routes';
import { makeSelectLocale } from 'app/containers/LanguageProvider/selectors';
import { isLanguageSupported, redirectToURLWithNewLocale } from 'app/utils/languageUtil';
import { makeSelectRoute } from 'app/redux/reducers';

// Memoize allows to avoid Router re-renders due to the use of useSelector
// to access redux state routes into the AppRouter component
const MemoizedRouter = React.memo(Router);

const AppRouter = ({ store }) => {
  // Sync history and store, as the react-router-redux reducer
  // is under the non-default key ("routing"), selectLocationState
  // must be provided for resolving how to retrieve the "route" in the state
  const [history] = useState(() =>
    syncHistoryWithStore(browserHistory, store, {
      selectLocationState: makeSelectRoute(),
    })
  );

  const [routes] = useState(() => createRoutes(store));

  const route = useSelector(makeSelectRoute());

  // Redirection to the correct path if the locale is missing, on location change

  // Scroll to top / scroll to hash effect, on location change
  useEffect(() => {
    const { hash } = route.locationBeforeTransitions;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [route]);

  return <MemoizedRouter history={history} routes={routes} />;
};

export default AppRouter;
