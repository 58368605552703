export default function convertCropHandlesToProcessingRect(cropHandles, scaleFactor = 1) {
  const { topLeft, end } = cropHandles;

  return [
    Math.round(scaleFactor * Math.max(0, Math.min(topLeft.x, end.x))),
    Math.round(scaleFactor * Math.max(0, Math.min(topLeft.y, end.y))),
    Math.round(scaleFactor * Math.abs(end.x - topLeft.x)),
    Math.round(scaleFactor * Math.abs(end.y - topLeft.y)),
  ];
}
