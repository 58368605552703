/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  SET_AUTH,
  SENDING_REQUEST,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  LOGOUT,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SET_HAS_SUBSCRIBED,
  SET_AI_PANEL_DISPLAY_MODE,
} from './constants';

/** @deprecated semble-t-il */
// export function inferOnImage(imageFile) {
//   return {
//     type: INFER,
//     imageFile,
//   };
// }

export function changeUsername(username) {
  return {
    type: CHANGE_USERNAME,
    username,
  };
}

export function changePassword(password) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export function setAuthState(newAuthState) {
  return {
    type: SET_AUTH,
    newAuthState,
  };
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRequest(sending) {
  return {
    type: SENDING_REQUEST,
    sending,
  };
}

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.username The username of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export function loginRequest(data) {
  return {
    type: LOGIN_REQUEST,
    data,
  };
}

/**
 * Tells the app we want to log out a user
 */
export function logout(data) {
  return {
    type: LOGOUT,
    data,
  };
}

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.password The password of the user to register
 */
export function registerRequest(data) {
  return {
    type: REGISTER_REQUEST,
    data,
  };
}

/*
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError(error) {
  return {
    type: REQUEST_ERROR,
    error,
  };
}

/**
 * Sets the `error` state as empty
 */
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

/**
 * Sets the subscribed boolean as true or false
 */
export function setHasSubscribed(hasSubscribed) {
  return {
    type: SET_HAS_SUBSCRIBED,
    hasSubscribed,
  };
}

/**
 * Sets the AI Panel display mode
 */
export function setAIPanelDisplayMode(mode) {
  return {
    type: SET_AI_PANEL_DISPLAY_MODE,
    mode,
  };
}
