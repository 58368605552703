/* eslint-disable max-classes-per-file */
import EventEmitter from 'events';

import InMemoryXRayGeneratorController from 'app/adapters/XRayGenerator/InMemoryXRayGeneratorController';
import TimeoutPromise from 'app/utils/TimeoutPromise';

class InMemoryXRayGeneratorControllerHandle extends EventEmitter {
  constructor(id) {
    super();
    this.id = id;

    this.xrayGeneratorController = new InMemoryXRayGeneratorController({
      close: () => this.emit('disconnected'),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async connect() {
    await TimeoutPromise(1000);
    return this.xrayGeneratorController;
  }
}

export default class InMemoryXRayGeneratorControllerManager extends EventEmitter {
  constructor(ids = ['COM1', 'COM2']) {
    super();

    this.ids = ids;

    this.xrayGeneratorControllerHandles = ids.map(
      (id) => new InMemoryXRayGeneratorControllerHandle(id)
    );
  }

  async list() {
    return this.xrayGeneratorControllerHandles;
  }

  // eslint-disable-next-line class-methods-use-this
  async get(id) {
    const handleIndex = this.ids.indexOf(id);
    return this.xrayGeneratorControllerHandles[handleIndex];
  }
}
