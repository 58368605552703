export enum DicomRegistry {
  Verification = '1.2.840.10008.1.1',
  VerificationSOPClass = '1.2.840.10008.1.1',
  ImplicitVRLittleEndian = '1.2.840.10008.1.2',
  ExplicitVRLittleEndian = '1.2.840.10008.1.2.1',
  EncapsulatedUncompressedExplicitVRLittleEndian = '1.2.840.10008.1.2.1.98',
  DeflatedExplicitVRLittleEndian = '1.2.840.10008.1.2.1.99',
  ExplicitVRBigEndian = '1.2.840.10008.1.2.2',
  ExplicitVRBigEndian_Retired = '1.2.840.10008.1.2.2',
  JPEGBaseline8Bit = '1.2.840.10008.1.2.4.50',
  JPEGBaselineProcess1 = '1.2.840.10008.1.2.4.50',
  JPEGExtended12Bit = '1.2.840.10008.1.2.4.51',
  JPEGExtendedProcess24 = '1.2.840.10008.1.2.4.51',
  JPEGExtended35 = '1.2.840.10008.1.2.4.52',
  JPEGExtendedProcess35_Retired = '1.2.840.10008.1.2.4.52',
  JPEGSpectralSelectionNonHierarchical68 = '1.2.840.10008.1.2.4.53',
  JPEGSpectralSelectionNonHierarchicalProcess68_Retired = '1.2.840.10008.1.2.4.53',
  JPEGSpectralSelectionNonHierarchical79 = '1.2.840.10008.1.2.4.54',
  JPEGSpectralSelectionNonHierarchicalProcess79_Retired = '1.2.840.10008.1.2.4.54',
  JPEGFullProgressionNonHierarchical1012 = '1.2.840.10008.1.2.4.55',
  JPEGFullProgressionNonHierarchicalProcess1012_Retired = '1.2.840.10008.1.2.4.55',
  JPEGFullProgressionNonHierarchical1113 = '1.2.840.10008.1.2.4.56',
  JPEGFullProgressionNonHierarchicalProcess1113_Retired = '1.2.840.10008.1.2.4.56',
  JPEGLossless = '1.2.840.10008.1.2.4.57',
  JPEGLosslessNonHierarchicalProcess14 = '1.2.840.10008.1.2.4.57',
  JPEGLosslessNonHierarchical15 = '1.2.840.10008.1.2.4.58',
  JPEGLosslessNonHierarchicalProcess15_Retired = '1.2.840.10008.1.2.4.58',
  JPEGExtendedHierarchical1618 = '1.2.840.10008.1.2.4.59',
  JPEGExtendedHierarchicalProcess1618_Retired = '1.2.840.10008.1.2.4.59',
  JPEGExtendedHierarchical1719 = '1.2.840.10008.1.2.4.60',
  JPEGExtendedHierarchicalProcess1719_Retired = '1.2.840.10008.1.2.4.60',
  JPEGSpectralSelectionHierarchical2022 = '1.2.840.10008.1.2.4.61',
  JPEGSpectralSelectionHierarchicalProcess2022_Retired = '1.2.840.10008.1.2.4.61',
  JPEGSpectralSelectionHierarchical2123 = '1.2.840.10008.1.2.4.62',
  JPEGSpectralSelectionHierarchicalProcess2123_Retired = '1.2.840.10008.1.2.4.62',
  JPEGFullProgressionHierarchical2426 = '1.2.840.10008.1.2.4.63',
  JPEGFullProgressionHierarchicalProcess2426_Retired = '1.2.840.10008.1.2.4.63',
  JPEGFullProgressionHierarchical2527 = '1.2.840.10008.1.2.4.64',
  JPEGFullProgressionHierarchicalProcess2527_Retired = '1.2.840.10008.1.2.4.64',
  JPEGLosslessHierarchical28 = '1.2.840.10008.1.2.4.65',
  JPEGLosslessHierarchicalProcess28_Retired = '1.2.840.10008.1.2.4.65',
  JPEGLosslessHierarchical29 = '1.2.840.10008.1.2.4.66',
  JPEGLosslessHierarchicalProcess29_Retired = '1.2.840.10008.1.2.4.66',
  JPEGLosslessSV1 = '1.2.840.10008.1.2.4.70',
  JPEGLosslessNonHierarchicalFirstOrderPredictionProcess14SelectionValue1 = '1.2.840.10008.1.2.4.70',
  JPEGLSLossless = '1.2.840.10008.1.2.4.80',
  JPEGLSLosslessImageCompression = '1.2.840.10008.1.2.4.80',
  JPEGLSNearLossless = '1.2.840.10008.1.2.4.81',
  JPEGLSLossyNearLosslessImageCompression = '1.2.840.10008.1.2.4.81',
  JPEG2000Lossless = '1.2.840.10008.1.2.4.90',
  JPEG2000ImageCompressionLosslessOnly = '1.2.840.10008.1.2.4.90',
  JPEG2000 = '1.2.840.10008.1.2.4.91',
  JPEG2000ImageCompression = '1.2.840.10008.1.2.4.91',
  JPEG2000MCLossless = '1.2.840.10008.1.2.4.92',
  JPEG2000Part2MulticomponentImageCompressionLosslessOnly = '1.2.840.10008.1.2.4.92',
  JPEG2000MC = '1.2.840.10008.1.2.4.93',
  JPEG2000Part2MulticomponentImageCompression = '1.2.840.10008.1.2.4.93',
  JPIPReferenced = '1.2.840.10008.1.2.4.94',
  JPIPReferencedDeflate = '1.2.840.10008.1.2.4.95',
  MPEG2MPML = '1.2.840.10008.1.2.4.100',
  MPEG2MainProfileMainLevel = '1.2.840.10008.1.2.4.100',
  MPEG2MPHL = '1.2.840.10008.1.2.4.101',
  MPEG2MainProfileHighLevel = '1.2.840.10008.1.2.4.101',
  MPEG4HP41 = '1.2.840.10008.1.2.4.102',
  MPEG4AVCH264HighProfileLevel41 = '1.2.840.10008.1.2.4.102',
  MPEG4HP41BD = '1.2.840.10008.1.2.4.103',
  MPEG4AVCH264BDcompatibleHighProfileLevel41 = '1.2.840.10008.1.2.4.103',
  MPEG4HP422D = '1.2.840.10008.1.2.4.104',
  MPEG4AVCH264HighProfileLevel42For2DVideo = '1.2.840.10008.1.2.4.104',
  MPEG4HP423D = '1.2.840.10008.1.2.4.105',
  MPEG4AVCH264HighProfileLevel42For3DVideo = '1.2.840.10008.1.2.4.105',
  MPEG4HP42STEREO = '1.2.840.10008.1.2.4.106',
  MPEG4AVCH264StereoHighProfileLevel42 = '1.2.840.10008.1.2.4.106',
  HEVCMP51 = '1.2.840.10008.1.2.4.107',
  HEVCH265MainProfileLevel51 = '1.2.840.10008.1.2.4.107',
  HEVCM10P51 = '1.2.840.10008.1.2.4.108',
  HEVCH265Main10ProfileLevel51 = '1.2.840.10008.1.2.4.108',
  RLELossless = '1.2.840.10008.1.2.5',
  RFC2557MIMEEncapsulation = '1.2.840.10008.1.2.6.1',
  RFC2557MIMEencapsulation_Retired = '1.2.840.10008.1.2.6.1',
  XMLEncoding = '1.2.840.10008.1.2.6.2',
  XMLEncoding_Retired = '1.2.840.10008.1.2.6.2',
  SMPTEST211020UncompressedProgressiveActiveVideo = '1.2.840.10008.1.2.7.1',
  SMPTEST211020UncompressedInterlacedActiveVideo = '1.2.840.10008.1.2.7.2',
  SMPTEST211030PCMDigitalAudio = '1.2.840.10008.1.2.7.3',
  MediaStorageDirectoryStorage = '1.2.840.10008.1.3.10',
  HotIronPalette = '1.2.840.10008.1.5.1',
  HotIronColorPaletteSOPInstance = '1.2.840.10008.1.5.1',
  PETPalette = '1.2.840.10008.1.5.2',
  PETColorPaletteSOPInstance = '1.2.840.10008.1.5.2',
  HotMetalBluePalette = '1.2.840.10008.1.5.3',
  HotMetalBlueColorPaletteSOPInstance = '1.2.840.10008.1.5.3',
  PET20StepPalette = '1.2.840.10008.1.5.4',
  PET20StepColorPaletteSOPInstance = '1.2.840.10008.1.5.4',
  SpringPalette = '1.2.840.10008.1.5.5',
  SpringColorPaletteSOPInstance = '1.2.840.10008.1.5.5',
  SummerPalette = '1.2.840.10008.1.5.6',
  SummerColorPaletteSOPInstance = '1.2.840.10008.1.5.6',
  FallPalette = '1.2.840.10008.1.5.7',
  FallColorPaletteSOPInstance = '1.2.840.10008.1.5.7',
  WinterPalette = '1.2.840.10008.1.5.8',
  WinterColorPaletteSOPInstance = '1.2.840.10008.1.5.8',
  BasicStudyContentNotification = '1.2.840.10008.1.9',
  BasicStudyContentNotificationSOPClass_Retired = '1.2.840.10008.1.9',
  Papyrus3ImplicitVRLittleEndian = '1.2.840.10008.1.20',
  Papyrus3ImplicitVRLittleEndian_Retired = '1.2.840.10008.1.20',
  StorageCommitmentPushModel = '1.2.840.10008.1.20.1',
  StorageCommitmentPushModelSOPClass = '1.2.840.10008.1.20.1',
  StorageCommitmentPushModelInstance = '1.2.840.10008.1.20.1.1',
  StorageCommitmentPushModelSOPInstance = '1.2.840.10008.1.20.1.1',
  StorageCommitmentPullModel = '1.2.840.10008.1.20.2',
  StorageCommitmentPullModelSOPClass_Retired = '1.2.840.10008.1.20.2',
  StorageCommitmentPullModelInstance = '1.2.840.10008.1.20.2.1',
  StorageCommitmentPullModelSOPInstance_Retired = '1.2.840.10008.1.20.2.1',
  ProceduralEventLogging = '1.2.840.10008.1.40',
  ProceduralEventLoggingSOPClass = '1.2.840.10008.1.40',
  ProceduralEventLoggingInstance = '1.2.840.10008.1.40.1',
  ProceduralEventLoggingSOPInstance = '1.2.840.10008.1.40.1',
  SubstanceAdministrationLogging = '1.2.840.10008.1.42',
  SubstanceAdministrationLoggingSOPClass = '1.2.840.10008.1.42',
  SubstanceAdministrationLoggingInstance = '1.2.840.10008.1.42.1',
  SubstanceAdministrationLoggingSOPInstance = '1.2.840.10008.1.42.1',
  DCMUID = '1.2.840.10008.2.6.1',
  DICOMUIDRegistry = '1.2.840.10008.2.6.1',
  DCM = '1.2.840.10008.2.16.4',
  DICOMControlledTerminology = '1.2.840.10008.2.16.4',
  MA = '1.2.840.10008.2.16.5',
  AdultMouseAnatomyOntology = '1.2.840.10008.2.16.5',
  UBERON = '1.2.840.10008.2.16.6',
  UberonOntology = '1.2.840.10008.2.16.6',
  ITIS_TSN = '1.2.840.10008.2.16.7',
  IntegratedTaxonomicInformationSystemITISTaxonomicSerialNumberTSN = '1.2.840.10008.2.16.7',
  MGI = '1.2.840.10008.2.16.8',
  MouseGenomeInitiativeMGI = '1.2.840.10008.2.16.8',
  PUBCHEM_CID = '1.2.840.10008.2.16.9',
  PubChemCompoundCID = '1.2.840.10008.2.16.9',
  DC = '1.2.840.10008.2.16.10',
  DublinCore = '1.2.840.10008.2.16.10',
  NYUMCCG = '1.2.840.10008.2.16.11',
  NewYorkUniversityMelanomaClinicalCooperativeGroup = '1.2.840.10008.2.16.11',
  MAYONRISBSASRG = '1.2.840.10008.2.16.12',
  MayoClinicNonradiologicalImagesSpecificBodyStructureAnatomicalSurfaceRegionGuide = '1.2.840.10008.2.16.12',
  IBSI = '1.2.840.10008.2.16.13',
  ImageBiomarkerStandardisationInitiative = '1.2.840.10008.2.16.13',
  RO = '1.2.840.10008.2.16.14',
  RadiomicsOntology = '1.2.840.10008.2.16.14',
  RADELEMENT = '1.2.840.10008.2.16.15',
  RadElement = '1.2.840.10008.2.16.15',
  I11 = '1.2.840.10008.2.16.16',
  ICD11 = '1.2.840.10008.2.16.16',
  UNS = '1.2.840.10008.2.16.17',
  UnifiednumberingsystemUNSformetalsandalloys = '1.2.840.10008.2.16.17',
  RRID = '1.2.840.10008.2.16.18',
  ResearchResourceIdentification = '1.2.840.10008.2.16.18',
  DICOMApplicationContext = '1.2.840.10008.3.1.1.1',
  DICOMApplicationContextName = '1.2.840.10008.3.1.1.1',
  DetachedPatientManagement = '1.2.840.10008.3.1.2.1.1',
  DetachedPatientManagementSOPClass_Retired = '1.2.840.10008.3.1.2.1.1',
  DetachedPatientManagementMeta = '1.2.840.10008.3.1.2.1.4',
  DetachedPatientManagementMetaSOPClass_Retired = '1.2.840.10008.3.1.2.1.4',
  DetachedVisitManagement = '1.2.840.10008.3.1.2.2.1',
  DetachedVisitManagementSOPClass_Retired = '1.2.840.10008.3.1.2.2.1',
  DetachedStudyManagement = '1.2.840.10008.3.1.2.3.1',
  DetachedStudyManagementSOPClass_Retired = '1.2.840.10008.3.1.2.3.1',
  StudyComponentManagement = '1.2.840.10008.3.1.2.3.2',
  StudyComponentManagementSOPClass_Retired = '1.2.840.10008.3.1.2.3.2',
  ModalityPerformedProcedureStep = '1.2.840.10008.3.1.2.3.3',
  ModalityPerformedProcedureStepSOPClass = '1.2.840.10008.3.1.2.3.3',
  ModalityPerformedProcedureStepRetrieve = '1.2.840.10008.3.1.2.3.4',
  ModalityPerformedProcedureStepRetrieveSOPClass = '1.2.840.10008.3.1.2.3.4',
  ModalityPerformedProcedureStepNotification = '1.2.840.10008.3.1.2.3.5',
  ModalityPerformedProcedureStepNotificationSOPClass = '1.2.840.10008.3.1.2.3.5',
  DetachedResultsManagement = '1.2.840.10008.3.1.2.5.1',
  DetachedResultsManagementSOPClass_Retired = '1.2.840.10008.3.1.2.5.1',
  DetachedResultsManagementMeta = '1.2.840.10008.3.1.2.5.4',
  DetachedResultsManagementMetaSOPClass_Retired = '1.2.840.10008.3.1.2.5.4',
  DetachedStudyManagementMeta = '1.2.840.10008.3.1.2.5.5',
  DetachedStudyManagementMetaSOPClass_Retired = '1.2.840.10008.3.1.2.5.5',
  DetachedInterpretationManagement = '1.2.840.10008.3.1.2.6.1',
  DetachedInterpretationManagementSOPClass_Retired = '1.2.840.10008.3.1.2.6.1',
  Storage = '1.2.840.10008.4.2',
  StorageServiceClass = '1.2.840.10008.4.2',
  BasicFilmSession = '1.2.840.10008.5.1.1.1',
  BasicFilmSessionSOPClass = '1.2.840.10008.5.1.1.1',
  BasicFilmBox = '1.2.840.10008.5.1.1.2',
  BasicFilmBoxSOPClass = '1.2.840.10008.5.1.1.2',
  BasicGrayscaleImageBox = '1.2.840.10008.5.1.1.4',
  BasicGrayscaleImageBoxSOPClass = '1.2.840.10008.5.1.1.4',
  BasicColorImageBox = '1.2.840.10008.5.1.1.4.1',
  BasicColorImageBoxSOPClass = '1.2.840.10008.5.1.1.4.1',
  ReferencedImageBox = '1.2.840.10008.5.1.1.4.2',
  ReferencedImageBoxSOPClass_Retired = '1.2.840.10008.5.1.1.4.2',
  BasicGrayscalePrintManagementMeta = '1.2.840.10008.5.1.1.9',
  BasicGrayscalePrintManagementMetaSOPClass = '1.2.840.10008.5.1.1.9',
  ReferencedGrayscalePrintManagementMeta = '1.2.840.10008.5.1.1.9.1',
  ReferencedGrayscalePrintManagementMetaSOPClass_Retired = '1.2.840.10008.5.1.1.9.1',
  PrintJob = '1.2.840.10008.5.1.1.14',
  PrintJobSOPClass = '1.2.840.10008.5.1.1.14',
  BasicAnnotationBox = '1.2.840.10008.5.1.1.15',
  BasicAnnotationBoxSOPClass = '1.2.840.10008.5.1.1.15',
  Printer = '1.2.840.10008.5.1.1.16',
  PrinterSOPClass = '1.2.840.10008.5.1.1.16',
  PrinterConfigurationRetrieval = '1.2.840.10008.5.1.1.16.376',
  PrinterConfigurationRetrievalSOPClass = '1.2.840.10008.5.1.1.16.376',
  PrinterInstance = '1.2.840.10008.5.1.1.17',
  PrinterSOPInstance = '1.2.840.10008.5.1.1.17',
  PrinterConfigurationRetrievalInstance = '1.2.840.10008.5.1.1.17.376',
  PrinterConfigurationRetrievalSOPInstance = '1.2.840.10008.5.1.1.17.376',
  BasicColorPrintManagementMeta = '1.2.840.10008.5.1.1.18',
  BasicColorPrintManagementMetaSOPClass = '1.2.840.10008.5.1.1.18',
  ReferencedColorPrintManagementMeta = '1.2.840.10008.5.1.1.18.1',
  ReferencedColorPrintManagementMetaSOPClass_Retired = '1.2.840.10008.5.1.1.18.1',
  VOILUTBox = '1.2.840.10008.5.1.1.22',
  VOILUTBoxSOPClass = '1.2.840.10008.5.1.1.22',
  PresentationLUT = '1.2.840.10008.5.1.1.23',
  PresentationLUTSOPClass = '1.2.840.10008.5.1.1.23',
  ImageOverlayBox = '1.2.840.10008.5.1.1.24',
  ImageOverlayBoxSOPClass_Retired = '1.2.840.10008.5.1.1.24',
  BasicPrintImageOverlayBox = '1.2.840.10008.5.1.1.24.1',
  BasicPrintImageOverlayBoxSOPClass_Retired = '1.2.840.10008.5.1.1.24.1',
  PrintQueue = '1.2.840.10008.5.1.1.25',
  PrintQueueSOPInstance_Retired = '1.2.840.10008.5.1.1.25',
  PrintQueueManagement = '1.2.840.10008.5.1.1.26',
  PrintQueueManagementSOPClass_Retired = '1.2.840.10008.5.1.1.26',
  StoredPrintStorage = '1.2.840.10008.5.1.1.27',
  StoredPrintStorageSOPClass_Retired = '1.2.840.10008.5.1.1.27',
  HardcopyGrayscaleImageStorage = '1.2.840.10008.5.1.1.29',
  HardcopyGrayscaleImageStorageSOPClass_Retired = '1.2.840.10008.5.1.1.29',
  HardcopyColorImageStorage = '1.2.840.10008.5.1.1.30',
  HardcopyColorImageStorageSOPClass_Retired = '1.2.840.10008.5.1.1.30',
  PullPrintRequest = '1.2.840.10008.5.1.1.31',
  PullPrintRequestSOPClass_Retired = '1.2.840.10008.5.1.1.31',
  PullStoredPrintManagementMeta = '1.2.840.10008.5.1.1.32',
  PullStoredPrintManagementMetaSOPClass_Retired = '1.2.840.10008.5.1.1.32',
  MediaCreationManagement = '1.2.840.10008.5.1.1.33',
  MediaCreationManagementSOPClassUID = '1.2.840.10008.5.1.1.33',
  DisplaySystem = '1.2.840.10008.5.1.1.40',
  DisplaySystemSOPClass = '1.2.840.10008.5.1.1.40',
  DisplaySystemInstance = '1.2.840.10008.5.1.1.40.1',
  DisplaySystemSOPInstance = '1.2.840.10008.5.1.1.40.1',
  ComputedRadiographyImageStorage = '1.2.840.10008.5.1.4.1.1.1',
  DigitalXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.1',
  DigitalXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.1.1',
  DigitalMammographyXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.2',
  DigitalMammographyXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.2.1',
  DigitalIntraOralXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.3',
  DigitalIntraOralXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.3.1',
  CTImageStorage = '1.2.840.10008.5.1.4.1.1.2',
  EnhancedCTImageStorage = '1.2.840.10008.5.1.4.1.1.2.1',
  LegacyConvertedEnhancedCTImageStorage = '1.2.840.10008.5.1.4.1.1.2.2',
  UltrasoundMultiFrameImageStorageRetired = '1.2.840.10008.5.1.4.1.1.3',
  UltrasoundMultiframeImageStorage_Retired = '1.2.840.10008.5.1.4.1.1.3',
  UltrasoundMultiFrameImageStorage = '1.2.840.10008.5.1.4.1.1.3.1',
  UltrasoundMultiframeImageStorage = '1.2.840.10008.5.1.4.1.1.3.1',
  MRImageStorage = '1.2.840.10008.5.1.4.1.1.4',
  EnhancedMRImageStorage = '1.2.840.10008.5.1.4.1.1.4.1',
  MRSpectroscopyStorage = '1.2.840.10008.5.1.4.1.1.4.2',
  EnhancedMRColorImageStorage = '1.2.840.10008.5.1.4.1.1.4.3',
  LegacyConvertedEnhancedMRImageStorage = '1.2.840.10008.5.1.4.1.1.4.4',
  NuclearMedicineImageStorageRetired = '1.2.840.10008.5.1.4.1.1.5',
  NuclearMedicineImageStorage_Retired = '1.2.840.10008.5.1.4.1.1.5',
  UltrasoundImageStorageRetired = '1.2.840.10008.5.1.4.1.1.6',
  UltrasoundImageStorage_Retired = '1.2.840.10008.5.1.4.1.1.6',
  UltrasoundImageStorage = '1.2.840.10008.5.1.4.1.1.6.1',
  EnhancedUSVolumeStorage = '1.2.840.10008.5.1.4.1.1.6.2',
  SecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7',
  MultiFrameSingleBitSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.1',
  MultiframeSingleBitSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.1',
  MultiFrameGrayscaleByteSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.2',
  MultiframeGrayscaleByteSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.2',
  MultiFrameGrayscaleWordSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.3',
  MultiframeGrayscaleWordSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.3',
  MultiFrameTrueColorSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.4',
  MultiframeTrueColorSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.4',
  StandaloneOverlayStorage = '1.2.840.10008.5.1.4.1.1.8',
  StandaloneOverlayStorage_Retired = '1.2.840.10008.5.1.4.1.1.8',
  StandaloneCurveStorage = '1.2.840.10008.5.1.4.1.1.9',
  StandaloneCurveStorage_Retired = '1.2.840.10008.5.1.4.1.1.9',
  WaveformStorageTrial = '1.2.840.10008.5.1.4.1.1.9.1',
  WaveformStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.9.1',
  TwelveLeadECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.1',
  GeneralECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.2',
  AmbulatoryECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.3',
  HemodynamicWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.2.1',
  CardiacElectrophysiologyWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.3.1',
  BasicVoiceAudioWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.4.1',
  GeneralAudioWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.4.2',
  ArterialPulseWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.5.1',
  RespiratoryWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.6.1',
  MultichannelRespiratoryWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.6.2',
  RoutineScalpElectroencephalogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.1',
  ElectromyogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.2',
  ElectrooculogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.3',
  SleepElectroencephalogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.4',
  BodyPositionWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.8.1',
  StandaloneModalityLUTStorage = '1.2.840.10008.5.1.4.1.1.10',
  StandaloneModalityLUTStorage_Retired = '1.2.840.10008.5.1.4.1.1.10',
  StandaloneVOILUTStorage = '1.2.840.10008.5.1.4.1.1.11',
  StandaloneVOILUTStorage_Retired = '1.2.840.10008.5.1.4.1.1.11',
  GrayscaleSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.1',
  ColorSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.2',
  PseudoColorSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.3',
  BlendingSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.4',
  XAXRFGrayscaleSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.5',
  GrayscalePlanarMPRVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.6',
  CompositingPlanarMPRVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.7',
  AdvancedBlendingPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.8',
  VolumeRenderingVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.9',
  SegmentedVolumeRenderingVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.10',
  MultipleVolumeRenderingVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.11',
  XRayAngiographicImageStorage = '1.2.840.10008.5.1.4.1.1.12.1',
  EnhancedXAImageStorage = '1.2.840.10008.5.1.4.1.1.12.1.1',
  XRayRadiofluoroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.12.2',
  EnhancedXRFImageStorage = '1.2.840.10008.5.1.4.1.1.12.2.1',
  XRayAngiographicBiPlaneImageStorage = '1.2.840.10008.5.1.4.1.1.12.3',
  XRayAngiographicBiPlaneImageStorage_Retired = '1.2.840.10008.5.1.4.1.1.12.3',
  XRay3DAngiographicImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.1',
  XRay3DCraniofacialImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.2',
  BreastTomosynthesisImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.3',
  BreastProjectionXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.13.1.4',
  BreastProjectionXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.13.1.5',
  IntravascularOpticalCoherenceTomographyImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.14.1',
  IntravascularOpticalCoherenceTomographyImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.14.2',
  NuclearMedicineImageStorage = '1.2.840.10008.5.1.4.1.1.20',
  ParametricMapStorage = '1.2.840.10008.5.1.4.1.1.30',
  RawDataStorage = '1.2.840.10008.5.1.4.1.1.66',
  SpatialRegistrationStorage = '1.2.840.10008.5.1.4.1.1.66.1',
  SpatialFiducialsStorage = '1.2.840.10008.5.1.4.1.1.66.2',
  DeformableSpatialRegistrationStorage = '1.2.840.10008.5.1.4.1.1.66.3',
  SegmentationStorage = '1.2.840.10008.5.1.4.1.1.66.4',
  SurfaceSegmentationStorage = '1.2.840.10008.5.1.4.1.1.66.5',
  TractographyResultsStorage = '1.2.840.10008.5.1.4.1.1.66.6',
  RealWorldValueMappingStorage = '1.2.840.10008.5.1.4.1.1.67',
  SurfaceScanMeshStorage = '1.2.840.10008.5.1.4.1.1.68.1',
  SurfaceScanPointCloudStorage = '1.2.840.10008.5.1.4.1.1.68.2',
  VLImageStorageTrial = '1.2.840.10008.5.1.4.1.1.77.1',
  VLImageStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.77.1',
  VLMultiFrameImageStorageTrial = '1.2.840.10008.5.1.4.1.1.77.2',
  VLMultiframeImageStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.77.2',
  VLEndoscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.1',
  VideoEndoscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.1.1',
  VLMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.2',
  VideoMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.2.1',
  VLSlideCoordinatesMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.3',
  VLPhotographicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.4',
  VideoPhotographicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.4.1',
  OphthalmicPhotography8BitImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.1',
  OphthalmicPhotography16BitImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.2',
  StereometricRelationshipStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.3',
  OphthalmicTomographyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.4',
  WideFieldOphthalmicPhotographyStereographicProjectionImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.5',
  WideFieldOphthalmicPhotography3DCoordinatesImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.6',
  OphthalmicOpticalCoherenceTomographyEnFaceImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.7',
  OphthalmicOpticalCoherenceTomographyBscanVolumeAnalysisStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.8',
  VLWholeSlideMicroscopyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.6',
  DermoscopicPhotographyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.7',
  LensometryMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.1',
  AutorefractionMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.2',
  KeratometryMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.3',
  SubjectiveRefractionMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.4',
  VisualAcuityMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.5',
  SpectaclePrescriptionReportStorage = '1.2.840.10008.5.1.4.1.1.78.6',
  OphthalmicAxialMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.7',
  IntraocularLensCalculationsStorage = '1.2.840.10008.5.1.4.1.1.78.8',
  MacularGridThicknessAndVolumeReportStorage = '1.2.840.10008.5.1.4.1.1.79.1',
  MacularGridThicknessandVolumeReportStorage = '1.2.840.10008.5.1.4.1.1.79.1',
  OphthalmicVisualFieldStaticPerimetryMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.80.1',
  OphthalmicThicknessMapStorage = '1.2.840.10008.5.1.4.1.1.81.1',
  CornealTopographyMapStorage = '1.2.840.10008.5.1.4.1.1.82.1',
  TextSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.1',
  TextSRStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.88.1',
  AudioSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.2',
  AudioSRStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.88.2',
  DetailSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.3',
  DetailSRStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.88.3',
  ComprehensiveSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.4',
  ComprehensiveSRStorageTrial_Retired = '1.2.840.10008.5.1.4.1.1.88.4',
  BasicTextSRStorage = '1.2.840.10008.5.1.4.1.1.88.11',
  EnhancedSRStorage = '1.2.840.10008.5.1.4.1.1.88.22',
  ComprehensiveSRStorage = '1.2.840.10008.5.1.4.1.1.88.33',
  Comprehensive3DSRStorage = '1.2.840.10008.5.1.4.1.1.88.34',
  ExtensibleSRStorage = '1.2.840.10008.5.1.4.1.1.88.35',
  ProcedureLogStorage = '1.2.840.10008.5.1.4.1.1.88.40',
  MammographyCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.50',
  KeyObjectSelectionDocumentStorage = '1.2.840.10008.5.1.4.1.1.88.59',
  ChestCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.65',
  XRayRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.67',
  RadiopharmaceuticalRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.68',
  ColonCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.69',
  ImplantationPlanSRStorage = '1.2.840.10008.5.1.4.1.1.88.70',
  AcquisitionContextSRStorage = '1.2.840.10008.5.1.4.1.1.88.71',
  SimplifiedAdultEchoSRStorage = '1.2.840.10008.5.1.4.1.1.88.72',
  PatientRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.73',
  PlannedImagingAgentAdministrationSRStorage = '1.2.840.10008.5.1.4.1.1.88.74',
  PerformedImagingAgentAdministrationSRStorage = '1.2.840.10008.5.1.4.1.1.88.75',
  EnhancedXRayRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.76',
  ContentAssessmentResultsStorage = '1.2.840.10008.5.1.4.1.1.90.1',
  MicroscopyBulkSimpleAnnotationsStorage = '1.2.840.10008.5.1.4.1.1.91.1',
  EncapsulatedPDFStorage = '1.2.840.10008.5.1.4.1.1.104.1',
  EncapsulatedCDAStorage = '1.2.840.10008.5.1.4.1.1.104.2',
  EncapsulatedSTLStorage = '1.2.840.10008.5.1.4.1.1.104.3',
  EncapsulatedOBJStorage = '1.2.840.10008.5.1.4.1.1.104.4',
  EncapsulatedMTLStorage = '1.2.840.10008.5.1.4.1.1.104.5',
  PositronEmissionTomographyImageStorage = '1.2.840.10008.5.1.4.1.1.128',
  LegacyConvertedEnhancedPETImageStorage = '1.2.840.10008.5.1.4.1.1.128.1',
  StandalonePETCurveStorage = '1.2.840.10008.5.1.4.1.1.129',
  StandalonePETCurveStorage_Retired = '1.2.840.10008.5.1.4.1.1.129',
  EnhancedPETImageStorage = '1.2.840.10008.5.1.4.1.1.130',
  BasicStructuredDisplayStorage = '1.2.840.10008.5.1.4.1.1.131',
  CTDefinedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.1',
  CTPerformedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.2',
  ProtocolApprovalStorage = '1.2.840.10008.5.1.4.1.1.200.3',
  ProtocolApprovalInformationModelFind = '1.2.840.10008.5.1.4.1.1.200.4',
  ProtocolApprovalInformationModelFIND = '1.2.840.10008.5.1.4.1.1.200.4',
  ProtocolApprovalInformationModelMove = '1.2.840.10008.5.1.4.1.1.200.5',
  ProtocolApprovalInformationModelMOVE = '1.2.840.10008.5.1.4.1.1.200.5',
  ProtocolApprovalInformationModelGet = '1.2.840.10008.5.1.4.1.1.200.6',
  ProtocolApprovalInformationModelGET = '1.2.840.10008.5.1.4.1.1.200.6',
  XADefinedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.7',
  XAPerformedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.8',
  RTImageStorage = '1.2.840.10008.5.1.4.1.1.481.1',
  RTDoseStorage = '1.2.840.10008.5.1.4.1.1.481.2',
  RTStructureSetStorage = '1.2.840.10008.5.1.4.1.1.481.3',
  RTBeamsTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.4',
  RTPlanStorage = '1.2.840.10008.5.1.4.1.1.481.5',
  RTBrachyTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.6',
  RTTreatmentSummaryRecordStorage = '1.2.840.10008.5.1.4.1.1.481.7',
  RTIonPlanStorage = '1.2.840.10008.5.1.4.1.1.481.8',
  RTIonBeamsTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.9',
  RTPhysicianIntentStorage = '1.2.840.10008.5.1.4.1.1.481.10',
  RTSegmentAnnotationStorage = '1.2.840.10008.5.1.4.1.1.481.11',
  RTRadiationSetStorage = '1.2.840.10008.5.1.4.1.1.481.12',
  CArmPhotonElectronRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.13',
  TomotherapeuticRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.14',
  RoboticArmRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.15',
  RTRadiationRecordSetStorage = '1.2.840.10008.5.1.4.1.1.481.16',
  RTRadiationSalvageRecordStorage = '1.2.840.10008.5.1.4.1.1.481.17',
  TomotherapeuticRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.18',
  CArmPhotonElectronRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.19',
  RoboticRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.20',
  RTRadiationSetDeliveryInstructionStorage = '1.2.840.10008.5.1.4.1.1.481.21',
  RTTreatmentPreparationStorage = '1.2.840.10008.5.1.4.1.1.481.22',
  DICOSCTImageStorage = '1.2.840.10008.5.1.4.1.1.501.1',
  DICOSDigitalXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.501.2.1',
  DICOSDigitalXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.501.2.2',
  DICOSThreatDetectionReportStorage = '1.2.840.10008.5.1.4.1.1.501.3',
  DICOS2DAITStorage = '1.2.840.10008.5.1.4.1.1.501.4',
  DICOS3DAITStorage = '1.2.840.10008.5.1.4.1.1.501.5',
  DICOSQuadrupoleResonanceStorage = '1.2.840.10008.5.1.4.1.1.501.6',
  DICOSQuadrupoleResonanceQRStorage = '1.2.840.10008.5.1.4.1.1.501.6',
  EddyCurrentImageStorage = '1.2.840.10008.5.1.4.1.1.601.1',
  EddyCurrentMultiFrameImageStorage = '1.2.840.10008.5.1.4.1.1.601.2',
  EddyCurrentMultiframeImageStorage = '1.2.840.10008.5.1.4.1.1.601.2',
  PatientRootQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.1.1',
  PatientRootQueryRetrieveInformationModelFIND = '1.2.840.10008.5.1.4.1.2.1.1',
  PatientRootQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.1.2',
  PatientRootQueryRetrieveInformationModelMOVE = '1.2.840.10008.5.1.4.1.2.1.2',
  PatientRootQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.1.3',
  PatientRootQueryRetrieveInformationModelGET = '1.2.840.10008.5.1.4.1.2.1.3',
  StudyRootQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.2.1',
  StudyRootQueryRetrieveInformationModelFIND = '1.2.840.10008.5.1.4.1.2.2.1',
  StudyRootQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.2.2',
  StudyRootQueryRetrieveInformationModelMOVE = '1.2.840.10008.5.1.4.1.2.2.2',
  StudyRootQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.2.3',
  StudyRootQueryRetrieveInformationModelGET = '1.2.840.10008.5.1.4.1.2.2.3',
  PatientStudyOnlyQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.3.1',
  PatientStudyOnlyQueryRetrieveInformationModelFIND_Retired = '1.2.840.10008.5.1.4.1.2.3.1',
  PatientStudyOnlyQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.3.2',
  PatientStudyOnlyQueryRetrieveInformationModelMOVE_Retired = '1.2.840.10008.5.1.4.1.2.3.2',
  PatientStudyOnlyQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.3.3',
  PatientStudyOnlyQueryRetrieveInformationModelGET_Retired = '1.2.840.10008.5.1.4.1.2.3.3',
  CompositeInstanceRootRetrieveMove = '1.2.840.10008.5.1.4.1.2.4.2',
  CompositeInstanceRootRetrieveMOVE = '1.2.840.10008.5.1.4.1.2.4.2',
  CompositeInstanceRootRetrieveGet = '1.2.840.10008.5.1.4.1.2.4.3',
  CompositeInstanceRootRetrieveGET = '1.2.840.10008.5.1.4.1.2.4.3',
  CompositeInstanceRetrieveWithoutBulkDataGet = '1.2.840.10008.5.1.4.1.2.5.3',
  CompositeInstanceRetrieveWithoutBulkDataGET = '1.2.840.10008.5.1.4.1.2.5.3',
  DefinedProcedureProtocolInformationModelFind = '1.2.840.10008.5.1.4.20.1',
  DefinedProcedureProtocolInformationModelFIND = '1.2.840.10008.5.1.4.20.1',
  DefinedProcedureProtocolInformationModelMove = '1.2.840.10008.5.1.4.20.2',
  DefinedProcedureProtocolInformationModelMOVE = '1.2.840.10008.5.1.4.20.2',
  DefinedProcedureProtocolInformationModelGet = '1.2.840.10008.5.1.4.20.3',
  DefinedProcedureProtocolInformationModelGET = '1.2.840.10008.5.1.4.20.3',
  ModalityWorklistInformationModelFind = '1.2.840.10008.5.1.4.31',
  ModalityWorklistInformationModelFIND = '1.2.840.10008.5.1.4.31',
  GeneralPurposeWorklistManagementMeta = '1.2.840.10008.5.1.4.32',
  GeneralPurposeWorklistManagementMetaSOPClass_Retired = '1.2.840.10008.5.1.4.32',
  GeneralPurposeWorklistInformationModelFind = '1.2.840.10008.5.1.4.32.1',
  GeneralPurposeWorklistInformationModelFIND_Retired = '1.2.840.10008.5.1.4.32.1',
  GeneralPurposeScheduledProcedureStep = '1.2.840.10008.5.1.4.32.2',
  GeneralPurposeScheduledProcedureStepSOPClass_Retired = '1.2.840.10008.5.1.4.32.2',
  GeneralPurposePerformedProcedureStep = '1.2.840.10008.5.1.4.32.3',
  GeneralPurposePerformedProcedureStepSOPClass_Retired = '1.2.840.10008.5.1.4.32.3',
  InstanceAvailabilityNotification = '1.2.840.10008.5.1.4.33',
  InstanceAvailabilityNotificationSOPClass = '1.2.840.10008.5.1.4.33',
  RTBeamsDeliveryInstructionStorageTrial = '1.2.840.10008.5.1.4.34.1',
  RTBeamsDeliveryInstructionStorageTrial_Retired = '1.2.840.10008.5.1.4.34.1',
  RTConventionalMachineVerificationTrial = '1.2.840.10008.5.1.4.34.2',
  RTConventionalMachineVerificationTrial_Retired = '1.2.840.10008.5.1.4.34.2',
  RTIonMachineVerificationTrial = '1.2.840.10008.5.1.4.34.3',
  RTIonMachineVerificationTrial_Retired = '1.2.840.10008.5.1.4.34.3',
  UnifiedWorklistAndProcedureStepTrial = '1.2.840.10008.5.1.4.34.4',
  UnifiedWorklistandProcedureStepServiceClassTrial_Retired = '1.2.840.10008.5.1.4.34.4',
  UnifiedProcedureStepPushTrial = '1.2.840.10008.5.1.4.34.4.1',
  UnifiedProcedureStepPushSOPClassTrial_Retired = '1.2.840.10008.5.1.4.34.4.1',
  UnifiedProcedureStepWatchTrial = '1.2.840.10008.5.1.4.34.4.2',
  UnifiedProcedureStepWatchSOPClassTrial_Retired = '1.2.840.10008.5.1.4.34.4.2',
  UnifiedProcedureStepPullTrial = '1.2.840.10008.5.1.4.34.4.3',
  UnifiedProcedureStepPullSOPClassTrial_Retired = '1.2.840.10008.5.1.4.34.4.3',
  UnifiedProcedureStepEventTrial = '1.2.840.10008.5.1.4.34.4.4',
  UnifiedProcedureStepEventSOPClassTrial_Retired = '1.2.840.10008.5.1.4.34.4.4',
  UPSGlobalSubscriptionInstance = '1.2.840.10008.5.1.4.34.5',
  UPSGlobalSubscriptionSOPInstance = '1.2.840.10008.5.1.4.34.5',
  UPSFilteredGlobalSubscriptionInstance = '1.2.840.10008.5.1.4.34.5.1',
  UPSFilteredGlobalSubscriptionSOPInstance = '1.2.840.10008.5.1.4.34.5.1',
  UnifiedWorklistAndProcedureStep = '1.2.840.10008.5.1.4.34.6',
  UnifiedWorklistandProcedureStepServiceClass = '1.2.840.10008.5.1.4.34.6',
  UnifiedProcedureStepPush = '1.2.840.10008.5.1.4.34.6.1',
  UnifiedProcedureStepPushSOPClass = '1.2.840.10008.5.1.4.34.6.1',
  UnifiedProcedureStepWatch = '1.2.840.10008.5.1.4.34.6.2',
  UnifiedProcedureStepWatchSOPClass = '1.2.840.10008.5.1.4.34.6.2',
  UnifiedProcedureStepPull = '1.2.840.10008.5.1.4.34.6.3',
  UnifiedProcedureStepPullSOPClass = '1.2.840.10008.5.1.4.34.6.3',
  UnifiedProcedureStepEvent = '1.2.840.10008.5.1.4.34.6.4',
  UnifiedProcedureStepEventSOPClass = '1.2.840.10008.5.1.4.34.6.4',
  UnifiedProcedureStepQuery = '1.2.840.10008.5.1.4.34.6.5',
  UnifiedProcedureStepQuerySOPClass = '1.2.840.10008.5.1.4.34.6.5',
  RTBeamsDeliveryInstructionStorage = '1.2.840.10008.5.1.4.34.7',
  RTConventionalMachineVerification = '1.2.840.10008.5.1.4.34.8',
  RTIonMachineVerification = '1.2.840.10008.5.1.4.34.9',
  RTBrachyApplicationSetupDeliveryInstructionStorage = '1.2.840.10008.5.1.4.34.10',
  GeneralRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.1',
  BreastImagingRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.2',
  CardiacRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.3',
  HangingProtocolStorage = '1.2.840.10008.5.1.4.38.1',
  HangingProtocolInformationModelFind = '1.2.840.10008.5.1.4.38.2',
  HangingProtocolInformationModelFIND = '1.2.840.10008.5.1.4.38.2',
  HangingProtocolInformationModelMove = '1.2.840.10008.5.1.4.38.3',
  HangingProtocolInformationModelMOVE = '1.2.840.10008.5.1.4.38.3',
  HangingProtocolInformationModelGet = '1.2.840.10008.5.1.4.38.4',
  HangingProtocolInformationModelGET = '1.2.840.10008.5.1.4.38.4',
  ColorPaletteStorage = '1.2.840.10008.5.1.4.39.1',
  ColorPaletteQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.39.2',
  ColorPaletteQueryRetrieveInformationModelFIND = '1.2.840.10008.5.1.4.39.2',
  ColorPaletteQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.39.3',
  ColorPaletteQueryRetrieveInformationModelMOVE = '1.2.840.10008.5.1.4.39.3',
  ColorPaletteQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.39.4',
  ColorPaletteQueryRetrieveInformationModelGET = '1.2.840.10008.5.1.4.39.4',
  ProductCharacteristicsQuery = '1.2.840.10008.5.1.4.41',
  ProductCharacteristicsQuerySOPClass = '1.2.840.10008.5.1.4.41',
  SubstanceApprovalQuery = '1.2.840.10008.5.1.4.42',
  SubstanceApprovalQuerySOPClass = '1.2.840.10008.5.1.4.42',
  GenericImplantTemplateStorage = '1.2.840.10008.5.1.4.43.1',
  GenericImplantTemplateInformationModelFind = '1.2.840.10008.5.1.4.43.2',
  GenericImplantTemplateInformationModelFIND = '1.2.840.10008.5.1.4.43.2',
  GenericImplantTemplateInformationModelMove = '1.2.840.10008.5.1.4.43.3',
  GenericImplantTemplateInformationModelMOVE = '1.2.840.10008.5.1.4.43.3',
  GenericImplantTemplateInformationModelGet = '1.2.840.10008.5.1.4.43.4',
  GenericImplantTemplateInformationModelGET = '1.2.840.10008.5.1.4.43.4',
  ImplantAssemblyTemplateStorage = '1.2.840.10008.5.1.4.44.1',
  ImplantAssemblyTemplateInformationModelFind = '1.2.840.10008.5.1.4.44.2',
  ImplantAssemblyTemplateInformationModelFIND = '1.2.840.10008.5.1.4.44.2',
  ImplantAssemblyTemplateInformationModelMove = '1.2.840.10008.5.1.4.44.3',
  ImplantAssemblyTemplateInformationModelMOVE = '1.2.840.10008.5.1.4.44.3',
  ImplantAssemblyTemplateInformationModelGet = '1.2.840.10008.5.1.4.44.4',
  ImplantAssemblyTemplateInformationModelGET = '1.2.840.10008.5.1.4.44.4',
  ImplantTemplateGroupStorage = '1.2.840.10008.5.1.4.45.1',
  ImplantTemplateGroupInformationModelFind = '1.2.840.10008.5.1.4.45.2',
  ImplantTemplateGroupInformationModelFIND = '1.2.840.10008.5.1.4.45.2',
  ImplantTemplateGroupInformationModelMove = '1.2.840.10008.5.1.4.45.3',
  ImplantTemplateGroupInformationModelMOVE = '1.2.840.10008.5.1.4.45.3',
  ImplantTemplateGroupInformationModelGet = '1.2.840.10008.5.1.4.45.4',
  ImplantTemplateGroupInformationModelGET = '1.2.840.10008.5.1.4.45.4',
  NativeDICOMModel = '1.2.840.10008.7.1.1',
  AbstractMultiDimensionalImageModel = '1.2.840.10008.7.1.2',
  DICOMContentMappingResource = '1.2.840.10008.8.1.1',
  VideoEndoscopicImageRealTimeCommunication = '1.2.840.10008.10.1',
  VideoPhotographicImageRealTimeCommunication = '1.2.840.10008.10.2',
  AudioWaveformRealTimeCommunication = '1.2.840.10008.10.3',
  RenditionSelectionDocumentRealTimeCommunication = '1.2.840.10008.10.4',
  dicomDeviceName = '1.2.840.10008.15.0.3.1',
  dicomDescription = '1.2.840.10008.15.0.3.2',
  dicomManufacturer = '1.2.840.10008.15.0.3.3',
  dicomManufacturerModelName = '1.2.840.10008.15.0.3.4',
  dicomSoftwareVersion = '1.2.840.10008.15.0.3.5',
  dicomVendorData = '1.2.840.10008.15.0.3.6',
  dicomAETitle = '1.2.840.10008.15.0.3.7',
  dicomNetworkConnectionReference = '1.2.840.10008.15.0.3.8',
  dicomApplicationCluster = '1.2.840.10008.15.0.3.9',
  dicomAssociationInitiator = '1.2.840.10008.15.0.3.10',
  dicomAssociationAcceptor = '1.2.840.10008.15.0.3.11',
  dicomHostname = '1.2.840.10008.15.0.3.12',
  dicomPort = '1.2.840.10008.15.0.3.13',
  dicomSOPClass = '1.2.840.10008.15.0.3.14',
  dicomTransferRole = '1.2.840.10008.15.0.3.15',
  dicomTransferSyntax = '1.2.840.10008.15.0.3.16',
  dicomPrimaryDeviceType = '1.2.840.10008.15.0.3.17',
  dicomRelatedDeviceReference = '1.2.840.10008.15.0.3.18',
  dicomPreferredCalledAETitle = '1.2.840.10008.15.0.3.19',
  dicomTLSCyphersuite = '1.2.840.10008.15.0.3.20',
  dicomAuthorizedNodeCertificateReference = '1.2.840.10008.15.0.3.21',
  dicomThisNodeCertificateReference = '1.2.840.10008.15.0.3.22',
  dicomInstalled = '1.2.840.10008.15.0.3.23',
  dicomStationName = '1.2.840.10008.15.0.3.24',
  dicomDeviceSerialNumber = '1.2.840.10008.15.0.3.25',
  dicomInstitutionName = '1.2.840.10008.15.0.3.26',
  dicomInstitutionAddress = '1.2.840.10008.15.0.3.27',
  dicomInstitutionDepartmentName = '1.2.840.10008.15.0.3.28',
  dicomIssuerOfPatientID = '1.2.840.10008.15.0.3.29',
  dicomPreferredCallingAETitle = '1.2.840.10008.15.0.3.30',
  dicomSupportedCharacterSet = '1.2.840.10008.15.0.3.31',
  dicomConfigurationRoot = '1.2.840.10008.15.0.4.1',
  dicomDevicesRoot = '1.2.840.10008.15.0.4.2',
  dicomUniqueAETitlesRegistryRoot = '1.2.840.10008.15.0.4.3',
  dicomDevice = '1.2.840.10008.15.0.4.4',
  dicomNetworkAE = '1.2.840.10008.15.0.4.5',
  dicomNetworkConnection = '1.2.840.10008.15.0.4.6',
  dicomUniqueAETitle = '1.2.840.10008.15.0.4.7',
  dicomTransferCapability = '1.2.840.10008.15.0.4.8',
  UTC = '1.2.840.10008.15.1.1',
  UniversalCoordinatedTime = '1.2.840.10008.15.1.1',
}
