const DEFAULT_SPECIES_MAPPING = {
  cat: {
    aliases: ['cat', 'chat', 'ct', 'feline', 'cht', 'katze', 'gato'],
    breeds: {
      abyssinian: ['abyssin', 'abyssinian', 'abessinierkatze', 'abissínio'],
      american_bobtail: ['american bobtail', 'bobtail americano'],
      american_curl: ['american curl', 'curl americano'],
      american_shorthair: ['american shorthair', 'pêlo curto americano'],
      turkish_angora: ['angora turc', 'turkish angora', 'türkisch angora', 'angorá turco'],
      bengal: ['bengal', 'bengalkatze', 'bengala'],
      bombay: ['bombay', 'bombaim'],
      british_longhair: ['british longhair', 'britisch langhaar', 'cabelo comprido britânico'],
      british_shorthair: ['british shorthair', 'britisch kurzhaar', 'pêlo curto britânico'],
      burmese: ['burmese', 'burma katze', 'birmanês'],
      chartreux: ['chartreux'],
      devon_rex: ['devon rex'],
      european: ['européen', 'european', 'europeu'],
      exotic_shorthair: ['exotic shorthair', 'exotische kurzhaarkatze', 'pelo curto exótico'],
      maine_coon: ['maine coon', 'maine coon katze'],
      egyptian_mau: ['mau egyptien', 'egyptian mau', 'ägyptische mau', 'mau egípcio'],
      munchkin: ['munchkin'],
      norwegian: ['norvégien', 'norwegian', 'norwegische waldkatze', 'norueguês'],
      persian: ['persan', 'persian', 'perserkatze', 'persa'],
      ragdoll: ['ragdoll', 'boneca de pano'],
      sacred_of_burma: [
        'sacré de birmanie',
        'sacred of burma',
        'birma katze',
        'sagrado da birmânia',
      ],
      savannah: ['savannah', 'savannah katze', 'savana'],
      scottish_fold: ['scottish fold', 'schottische faltohrkatze', 'dobra escocesa'],
      siamese: ['siamois', 'siamese', 'siamkatze', 'siamês'],
      siberian: ['sibérien', 'siberian', 'sibirische katze', 'siberiano'],
      somali: ['somali', 'somali katze'],
      sphynx: ['sphynx', 'sphynx-katze', 'esfinge'],
    },
  },
  dog: {
    aliases: [
      'dog',
      'cn',
      'chien',
      'canine',
      'golden',
      'epagneul',
      'chizn',
      'canin',
      'chie',
      'hund',
      'cão',
    ],
    breeds: {
      american_akita: [
        'akita américain',
        'american akita',
        'amerikanischer akita',
        'akita americano',
      ],
      akita_inu: ['akita inu', 'akita'],
      american_staffordshire_terrier: [
        'american staffordshire terrier',
        'amerikanischer staffordshire terrier',
      ],
      borzoi: ['barzoï', 'borzoi', 'barsoi'],
      basenji: ['basenji'],
      basset: ['basset'],
      beagle: ['beagle'],
      beauceron: ['beauceron'],
      german_shepherd: [
        'berger allemand',
        'german shepherd',
        'deutscher schäferhund',
        'pastor alemão',
      ],
      australian_shepherd: [
        'berger australien',
        'australian shepherd',
        'australischer schäferhund',
        'pastor australiano',
      ],
      belgian_shepherd: [
        'berger belge',
        'belgian shepherd',
        'belgischer schäferhund',
        'pastor belga',
      ],
      swiss_white_shepherd: [
        'berger blanc suisse',
        'swiss white shepherd',
        'weißer schweizer schäferhund',
        'pastor branco suíço',
      ],
      bichon: ['bichon'],
      bobtail: ['bobtail'],
      border_collie: ['border collie'],
      boston_terrier: ['boston terrier', 'boston terrierer'],
      american_bulldog: [
        'bouledogue américain',
        'american bulldog',
        'amerikanische bulldoge',
        'bulldog americano',
      ],
      french_bulldog: [
        'bouledogue français',
        'french bulldog',
        'französische bulldoge',
        'bulldog francês',
      ],
      australian_cattle_dog: [
        'bouvier australien',
        'australian cattle dog',
        'australischer cattle dog',
        'cão de gado australiano',
      ],
      bernese_mountain_dog: [
        'bouvier bernois',
        'bernese mountain dog',
        'berner sennenhund',
        'cão de montanha de bernese',
      ],
      boxer: ['boxer'],
      german_shorthaired_pointer: [
        'braque allemand',
        'german shorthaired pointer',
        'deutsch kurzhaar',
        'ponteiro alemão de pelo curto',
      ],
      weimaraner: ['braque de weimar', 'weimaraner'],
      bull_terrier: ['bull terrier', 'bullterrier'],
      english_bulldog: [
        'bulldog anglais',
        'english bulldog',
        'englische bulldoge',
        'bulldog inglês',
      ],
      bullmastiff: ['bullmastiff'],
      cairn_terrier: ['cairn terrier'],
      cane_corso: ['cane corso'],
      poodle: ['caniche', 'poodle', 'pudel'],
      pug: ['carlin', 'pug', 'mops'],
      cavalier_king_charles: ['cavalier king charles', 'cavalier king charles spaniel'],
      irish_water_dog: [
        'chien d’eau irlandais',
        'irish water dog',
        'irish water spaniel',
        'cão de água irlandês',
      ],
      portuguese_water_dog: [
        'chien d’eau portugais',
        'portuguese water dog',
        'portugisicher wasserhund',
        'cão de água português',
      ],
      chihuahua: ['chihuahua'],
      chow_chow: ['chow chow'],
      american_cocker_spaniel: [
        'cocker américain',
        'american cocker spaniel',
        'amerikanischer cocker spaniel',
        'cocker spaniel americano',
      ],
      cocker_spaniel: ['cocker anglais', 'cocker spaniel'],
      collie: ['colley', 'collie'],
      cotton_tulear: ['coton de tuléar', 'cotton tulear', 'baumwollhund', 'algodão tulear'],
      dalmatian: ['dalmatien', 'dalmatian', 'dalmatiner', 'dálmata'],
      doberman_pinscher: ['dobermann', 'doberman pinscher', 'dobermann pinscher'],
      german_mastiff: ['dogue allemand', 'german mastiff', 'deutsche dogge', 'mastim alemão'],
      dogo_argentino: ['dogue argentin', 'dogo argentino'],
      dogue_de_bordeaux: ['dogue de bordeaux', 'bordeauxdogge', 'dogue de bordéus'],
      spaniel: ['epagneul', 'spaniel'],
      fox_terrier: ['fox terrier', 'foxterrier'],
      golden_retriever: ['golden retriever', 'retriever dourado'],
      greyhound: ['greyhound', 'galgo'],
      griffin: ['griffon', 'griffin', 'grifo'],
      hovawart: ['hovawart'],
      husky: ['husky'],
      jack_russell_terrier: ['jack russell terrier'],
      labrador: ['labrador'],
      leonberg: ['leonberg', 'leonberger'],
      lhasa_apso: ['lhassa apso', 'lhasa apso'],
      mastiff: ['mastiff', 'mastim'],
      pinscher: ['pinscher'],
      rottweiler: ['rottweiler'],
      saint_bernard: ['saint-bernard', 'saint bernard', 'bernhardiner', 'são bernardo'],
      samoyed: ['samoyède', 'samoyed', 'samojede', 'samoieda'],
      schnauzer: ['schnauzer'],
      setter: ['setter', 'normatizador'],
      'shar-pei': ['shar-pei', 'shar pei'],
      'shiba-inu': ['shiba-inu', 'shiba inu'],
      shih_tzu: ['shih tzu', 'shih-tzu'],
      spitz: ['spitz'],
      springer: ['springer', 'springer spaniel'],
      staffordshire_bull_terrier: ['staffordshire bull terrier', 'staffordshire bullterrier'],
      dachshund: ['teckel', 'dachshund'],
      newfoundland: ['terre neuve', 'newfoundland', 'neufundländer', 'terra nova'],
      welsh_corgi: ['welsh corgi', 'welsh corgi pembroke', 'corgi galês'],
      west_highland_white_terrier: [
        'west highland white terrier',
        'terrier branco de montanhas ocidentais',
      ],
      whippet: ['whippet'],
      yorkshire_terrier: ['yorkshire terrier'],
      irish_wolfhound: ['lévrier irlandais', 'levrier irlandais'],
      scottish_deerhound: ['Deerhound', 'lévrier écossais', 'levrier écossais'],
      spanish_greyhound: [
        'lévrier espagnol',
        'levrier espagnol',
        'lévrier galgo',
        'levrier galgo',
        'galgo espagnol',
      ],
      chart_polski: ['lévrier polonais', 'levrier polonais'],
      sloughi_touareg: [
        'lévrier azawakh',
        'levrier azawakh',
        'lévrier sud saharien',
        'levrier sud saharien',
        'lévrier touareg',
        'levrier touareg',
      ],
      magyar_agar: ['lévrier hongrois', 'levrier hongrois'],
      italian_greyhound: [
        'petit lévrier italien',
        'petit levrier italien',
        "levrette d'italie",
        'piccolo levriero italiano',
      ],
      afghan_hound: [
        'lévrier afghan',
        'levrier afghan',
        'tazi',
        'lévrier de balkh',
        'levrier de balkh',
        'lévrier de kaboul',
        'levrier de kaboul',
        'barutzy',
        'baluchi',
      ],
    },
  },
  rabbit: { aliases: ['rabbit', 'lapin', 'nac', 'lp', 'hase', 'coelho'], breeds: {} },
  ferret: { aliases: ['ferret', 'furet', 'frettchen', 'furão'], breeds: {} },
  rodent: {
    aliases: ['rodent', 'chinchilla', 'cobaye', 'rat', 'rongeur', 'nagetier', 'roedor'],
    breeds: {},
  },
  reptile: { aliases: ['reptile', 'reptil', 'réptil'], breeds: {} },
  bird: { aliases: ['bird', 'oiseau', 'vogel', 'pássaro'], breeds: {} },
};

export default DEFAULT_SPECIES_MAPPING;
