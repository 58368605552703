export const CREDIT_PACKS = {
  welcome: {
    price: 0,
    credits: 5,
    backendName: 'welcome',
  },
  early_adopter: {
    price: 0,
    credits: 5,
    backendName: 'early_adopter',
  },
  discovery: {
    price: 3000,
    credits: 10,
    backendName: 'discovery',
  },
};
export const SUBSCRIBE_PACKS = {
  'standard-90': {
    price: 9000,
    currency: '€',
    interval: 'month',
    countries: ['DEFAULT'],
    credits: 100,
    backendName: 'standard-90',
    trial_days: 7,
    isSpecialOffer: false,
  },
  'standard-90-GBP': {
    price: 9000,
    currency: '£',
    interval: 'month',
    countries: ['GB'],
    credits: 100,
    backendName: 'standard-90-GBP',
    trial_days: 7,
    isSpecialOffer: false,
  },
  'monthly-USD': {
    price: 12000,
    currency: '$',
    interval: 'month',
    countries: ['US'],
    credits: 100,
    backendName: 'monthly-USD',
    trial_days: 7,
    isSpecialOffer: false,
  },
  'monthly-CAD': {
    price: 16000,
    currency: '$ CA',
    interval: 'month',
    countries: ['CA'],
    credits: 100,
    backendName: 'monthly-CAD',
    trial_days: 7,
    isSpecialOffer: false,
  },
  'yearly-GBP': {
    price: 90000,
    currency: '£',
    interval: 'year',
    countries: ['GB'],
    credits: 100,
    backendName: 'yearly-GBP',
    trial_days: 0,
    isSpecialOffer: true,
  },
  'offer-75': {
    price: 7500,
    currency: '€',
    interval: 'month',
    countries: ['DEFAULT'],
    credits: 100,
    backendName: 'offer-75',
    trial_days: 7,
    isSpecialOffer: true,
  },
};
