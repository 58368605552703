import { addToolState, getToolForElement, getToolState } from 'app/CornerstoneTools';
import makeMoveHandlerChain from 'app/CornerstoneTools/makeMoveHandlerChain';
import { updateImage } from 'cornerstone-core';

const isMeasurementCreationOngoing = (element, toolName) =>
  getToolState(element, toolName)?.data.some(({ completed }) => !completed);

export default class ChainingToolBuilder {
  constructor({ name, createNewMeasurement, handlesKeys, maximumToolData }) {
    this.name = name;
    this.createNewMeasurement = createNewMeasurement;
    this.maximumToolData = maximumToolData;
    this.handlesKeys = handlesKeys;
  }

  addNewMeasurement = (evt, interactionType) => {
    const eventData = evt.detail;
    const { element } = eventData;
    if (isMeasurementCreationOngoing(element, this.name)) return;

    evt.preventDefault();
    evt.stopPropagation();

    const isMaximumToolDrawn =
      getToolState(element, this.name)?.data?.length >= this.maximumToolData;
    if (isMaximumToolDrawn) return;

    const measurementData = this.createNewMeasurement(eventData);

    addToolState(element, this.name, measurementData);

    const [firstHandleKey, ...remainingHandlesKeys] = this.handlesKeys;
    updateImage(element);
    this.currentToolChainCallbacks = makeMoveHandlerChain(
      eventData,
      this,
      measurementData,
      interactionType,
      measurementData.handles[firstHandleKey],
      remainingHandlesKeys
    );
  };

  checkToolCreationCancelled = (element, data) => {
    const toolInstance = getToolForElement(element, this.name);
    if (!data.completed && toolInstance.mode !== 'active' && this.currentToolChainCallbacks) {
      this.currentToolChainCallbacks.cancelFn();
      return true;
    }
    return false;
  };
}
