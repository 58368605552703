import React from 'react';
import * as PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Label, List } from 'semantic-ui-react';
import './style.css';
import { XRAY_REGIONS } from '../../utils/xrayRegions';

class ExamSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      type: null,
      test: XRAY_REGIONS[this.props.regionSelected].length === 0,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { regionSelected } = this.props;
    if (nextProps.regionSelected !== regionSelected) {
      this.setState({
        test: XRAY_REGIONS[nextProps.regionSelected].length === 0,
        detail: null,
        type: null,
      });
    }
  };

  componentDidMount = () => {
    const { regionSelected } = this.props;
    const { test } = this.state;
    if (test === false) this.handleClickList1(XRAY_REGIONS[regionSelected][0]);
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  renderSecondList = () => {
    const { type } = this.state;
    return (
      <div className="list">
        <button className="item" onClick={() => this.handleClickList2('face')} type="button">
          <Label id="face" className={type === 'face' ? 'selected' : 'not-selected'}>
            <FormattedMessage id="exam.view.face" />
          </Label>
        </button>
        <button className="item" onClick={() => this.handleClickList2('profil')} type="button">
          <Label id="profil" className={type === 'profil' ? 'selected' : 'not-selected'}>
            <FormattedMessage id="exam.view.profil" />
          </Label>
        </button>
      </div>
    );
  };

  handleClickList1 = (labelSelected) => {
    this.setState({ detail: labelSelected, test: true });
  };

  handleClickList2 = (labelSelected) => {
    const { detail } = this.state;
    const { onComplete } = this.props;

    this.setState({ type: labelSelected }, () => {
      onComplete(detail, labelSelected);
    });
  };

  renderFirstList = (region) => {
    const { detail } = this.state;
    return (
      XRAY_REGIONS[region].length > 0 && (
        <div className="list">
          {XRAY_REGIONS[region].map((zone) => (
            <button
              className="item"
              key={zone}
              onClick={() => this.handleClickList1(zone)}
              type="button"
            >
              <Label id={zone} className={zone === detail ? 'selected' : 'not-selected'}>
                <FormattedMessage id={`exam.body_part.${zone}`} />
              </Label>
            </button>
          ))}
        </div>
      )
    );
  };

  render() {
    const { regionSelected } = this.props;
    const { test } = this.state;
    return (
      <div className="two-lists">
        {this.renderFirstList(regionSelected)}
        {test ? this.renderSecondList() : ''}
      </div>
    );
  }
}
ExamSelection.propTypes = {
  intl: PropTypes.shape().isRequired,
  regionSelected: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
};

ExamSelection.defaultProps = {
  onComplete: () => {},
};

export default injectIntl(ExamSelection);
