import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';

import { connect } from 'react-redux';
import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { injectIntl } from 'react-intl';
import Select from 'react-select';

import ApiCalls from '../../utils/apiCalls';
import DateSearch from '../DateSearch';
import RegionsFilter from '../RegionsFilter';
import OptionWithPatientData from '../OptionWithPatientData';

const CUSTOM_STYLES = {
  container: (provided, state) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    borderRadius: '5px',
    backgroundColor: !state.isDisabled ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.4)',
    ':focus-within': {
      boxShadow: 'inset 0px 0px 0px 1.5px hsl(0, 0%, 60%)',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    justifyContent: 'center',
    alignItems: 'center',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#5e5e5e',
    color: 'white',
    borderColor: '#5e5e5e',
    fontSize: 12,
    height: 30,
    minHeight: 30,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    textAlign: 'center',
    flexGrow: '1',
    lineHeight: 'initial',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    lineHeight: 'initial',
  }),
  input: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 90%)',
    lineHeight: 'initial',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
    backgroundColor: '#444645',
    zIndex: '4',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  }),
};

class AnimalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileIdOptions: [],
      ownersOptions: [],
      animalsOptions: [],
      ownerId: undefined,
      animalId: undefined,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  /* eslint-disable react/destructuring-assignment */
  setFormToUnsaved = (...args) => this.props.setFormToUnsaved(...args);

  onComplete = (...args) => this.props.onComplete(...args);

  setAnimalId = (...args) => this.props.setAnimalId(...args);

  setOwnerId = (...args) => this.props.setOwnerId(...args);
  /* eslint-enable react/destructuring-assignment */

  onAnimalNameChange = (e, { action }) => {
    if (action === 'input-change') {
      this.getAnimalNameCompletion(e);
    }
  };

  validateAnimalName = (animal) => {
    if (animal != null) {
      const { _id: animalId } = animal.data;
      this.setState({ animalId }, () => {
        this.setAnimalId(animalId);
        this.getOwnerNameCompletion('');
      });
    } else {
      this.setState({ animalId: null }, () => {
        this.setAnimalId(null);
      });
    }
  };

  getAnimalNameCompletion = (animalNameEntered) =>
    ApiCalls.getAnimalNameCompletion({
      animal_name: animalNameEntered || '',
      owner_id: this.state.ownerId,
    }).then(({ data: { animals } }) => {
      this.setAnimalsOptions(animals);
    });

  onFileIdChange = (fileId, { action }) => {
    if (action === 'input-change') {
      ApiCalls.getAnimalNameCompletion({
        file_id: fileId || '',
      }).then(({ data: { animals } }) => {
        this.setState({
          fileIdOptions: animals.map((animal) => ({
            value: animal.file_id,
            label: animal.file_id,
            data: animal,
          })),
        });
      });
    }
  };

  setFileId = (fileId) => {
    const { setFileId } = this.props;
    setFileId(_.get(fileId, 'value'));
  };

  onOwnerNameChange = (e, { action }) => {
    if (action === 'input-change') {
      this.getOwnerNameCompletion(e);
    }
  };

  validateOwnerName = (owner) => {
    if (owner != null) {
      const { _id: ownerId } = owner.data;
      // eslint-disable-next-line no-underscore-dangle
      this.setState({ ownerId }, () => {
        this.setOwnerId(ownerId);
        if (!this.state.animalId) this.getAnimalNameCompletion();
      });
    } else {
      this.setState({ ownerId: null }, () => {
        this.setOwnerId(null);
      });
    }
  };

  getOwnerNameCompletion = (ownerNameEntered) => {
    ApiCalls.getOwnerNameCompletion({
      owner_name: ownerNameEntered,
      animal_id: this.state.animalId,
    }).then(({ data: { owners } }) => {
      this.setOwnersOptions(owners);
    });
  };

  setAnimalsOptions = (animals) =>
    this.setState({
      animalsOptions: animals.map((animal) => ({
        label: animal.name,
        value: animal.name,
        data: animal,
      })),
    });

  setOwnersOptions = (owners) =>
    this.setState({
      ownersOptions: owners.map((owner) => ({
        label: owner.name,
        value: owner.name,
        data: owner,
      })),
    });

  render() {
    const { ownersOptions, animalsOptions, fileIdOptions } = this.state;
    const { startDate, endDate, onChooseStartDate, onChooseEndDate, setRegions } = this.props;
    return (
      <div className="animal-search">
        <div className="field owner">
          <Select
            styles={CUSTOM_STYLES}
            isClearable
            id="ownerName"
            name="ownerName"
            placeholder={this.formatMessage({ id: 'monitoring.owner' })}
            noOptionsMessage={() => null}
            onInputChange={this.onOwnerNameChange}
            options={ownersOptions}
            onChange={this.validateOwnerName}
          />
        </div>
        <div className="field animal">
          <Select
            styles={CUSTOM_STYLES}
            isClearable
            id="animalName"
            placeholder={this.formatMessage({ id: 'monitoring.animal' })}
            noOptionsMessage={() => null}
            onInputChange={this.onAnimalNameChange}
            options={animalsOptions}
            onChange={this.validateAnimalName}
            components={{ Option: OptionWithPatientData('name') }}
          />
        </div>
        <div className="field">
          <Select
            styles={CUSTOM_STYLES}
            isClearable
            id="idFile"
            placeholder={this.formatMessage({ id: 'monitoring.id' })}
            onInputChange={this.onFileIdChange}
            onChange={this.setFileId}
            options={fileIdOptions}
            noOptionsMessage={() => null}
            components={{ Option: OptionWithPatientData('file_id') }}
          />
        </div>
        <div className="field date">
          <DateSearch
            type="start"
            startDate={startDate}
            endDate={endDate}
            onChangeDate={(date) => onChooseStartDate(date)}
          />
        </div>
        <div className="field date">
          <DateSearch
            type="end"
            startDate={startDate}
            endDate={endDate}
            onChangeDate={(date) => onChooseEndDate(date)}
          />
        </div>
        <div className="regions">
          <RegionsFilter setRegions={setRegions} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

AnimalSearch.propTypes = {
  setFormToUnsaved: pt.func,
  onComplete: pt.func,
  setAnimalId: pt.func,
  setOwnerId: pt.func,
  setFileId: pt.func,
};

AnimalSearch.defaultProps = {
  setFormToUnsaved: () => {},
  onComplete: () => {},
  setAnimalId: () => {},
  setOwnerId: () => {},
  setFileId: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnimalSearch));
