import { useRef } from 'react';
import * as pt from 'prop-types';

import useImagesEffect from 'app/components/Viewer/useImagesEffect';

function UseImagesEffect({ effect, images, deps = undefined } = {}) {
  const imagesRef = useRef();
  useImagesEffect((...args) => effect(...args), images, imagesRef.current, deps);
  imagesRef.current = images;

  return null;
}

UseImagesEffect.propTypes = {
  effect: pt.func.isRequired,
  images: pt.objectOf(pt.shape({})),
  deps: pt.arrayOf(pt.string),
};

UseImagesEffect.defaultProps = {
  images: undefined,
  deps: undefined,
};

export default UseImagesEffect;
