/* eslint-disable import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';

// Import all the third party stuff
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @sentry/react does not seems to work, need investigation
import * as Sentry from '@sentry/browser';
import { browserHistory } from 'react-router';
import 'sanitize.css/sanitize.css';
import { enableMapSet } from 'immer';
import * as cst from 'cornerstone-tools';
import { useIntl } from 'react-intl';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!../static/favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';
import '../static/manifest.json';
/* eslint-enable import/no-unresolved, import/extensions */

// Import CSS reset and Global Styles
import './global-styles';

import CornerstoneImageEncoder, {
  createDefaultToolsProvider,
} from 'app/adapters/CornerstoneImageEncoder';
import PACSCommunicationProvider from 'app/components/PACSCommunicationProvider';
import PACSCommunicationInjectorProvider from 'app/injectors/PACSCommunication/provider';
import electron from 'app/native/node/electron';
import serialport from 'app/native/modules/serialport';
import getFs from 'app/native/node/fs';
import path from 'app/native/node/path';
import syncFlatPanelKindWithBackEnd from 'app/observers/syncFlatPanelKindWithBackEnd';
import AssistoVetExporter from 'app/pms/exporter/AssistoVetExporter';
import VetoPartnerExporter from 'app/pms/exporter/VetoPartnerExporter';
import GmVetExporter from './pms/exporter/GmVetExporter';
import PMSExportProvider from 'app/providers/PMSIntegration/PMSExportProvider';
import PMSParserProvider from 'app/providers/PMSIntegration/PMSParserProvider';
import XRayGeneratorProvider from 'app/providers/XRayGeneratorProvider';
import { selectFlatPanelKind } from 'app/redux/flatPanelConfiguration/reducer';
import SentryFlatPanelKind from 'app/sentry/SentryFlatPanelKind';
import getProjectNameFromEnv from 'app/sentry/getProjectNameFromEnv';
import observeStore from 'app/utils/redux/observeStore';
import LanguageProvider from 'app/containers/LanguageProvider';
import { FlatPanelStateProvider } from 'app/components/FlatPanelStateProvider';
import AppRouter from 'app/containers/AppRouter';
import { LicenseManagerProvider } from 'app/providers/LicenseManagerProvider';
import { LocalDicomStoreProvider } from 'app/providers/LocalDicomStoreProvider';
import SentryFlatPanelInfo from 'app/sentry/SentryFlatPanelInfo';
import SentryUserInfo from 'app/sentry/SentryUserInfo';
import { translationMessages } from 'app/i18n';
import getDSN from 'app/sentry/getDSN';
import configureStore from 'app/store';
import FakeAdminStore from 'app/adapters/FakeAdminStore';
import AdminStoreContext from 'app/injectors/AdminStore/context';
import FakeUserStore from 'app/adapters/FakeUserStore';
import UserStoreContext from 'app/injectors/UserStore/context';
import CloudAdminStore from 'app/adapters/CloudAdminStore';
import CloudUserStore from 'app/adapters/CloudUserStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XRayGeneratorManagerProvider from 'app/providers/XRayGeneratorManagerProvider';
import SerialXRayGeneratorControllerManager from 'app/adapters/XRayGenerator/SerialXRayGeneratorControllerManager';
import { isAcquisitionDemo } from 'app/utils/envUtil';
import logger from 'app/utils/debug/logger';
import InMemoryXRayGeneratorControllerManager from 'app/adapters/XRayGenerator/InMemoryXRayGeneratorControllerManager';
import NativeFlatPanelDetectorManagerInitializer from 'app/adapters/flatPanel/NativeFlatPanelDetectorManagerInitializer';
import InMemoryFlatPanelDetectorManager from 'app/adapters/flatPanel/InMemoryFlatPanelDetectorManager';
import InMemoryFlatPanelDetector from 'app/adapters/flatPanel/InMemoryFlatPanelDetector';
import { DETECTOR_IMPLEMENTATION } from 'app/types/xray';
import loadXRayAcquisitionDemoImages from 'app/utils/xrayDemo/loadXRayAcquisitionDemoImages';
import linkXRayExposureToImageAcquisition from 'app/utils/xrayDemo/linkXRayExposureToImageAcquisition';
import InMemoryFlatPanelDetectorManagerInitializer from 'app/adapters/flatPanel/InMemoryFlatPanelDetectorManagerInitializer';
import StorageUsageMonitorProvider from 'app/providers/StorageUsageMonitorProvider';
import xray from 'app/native/node-addons/xray';
import SafeConsoleBreadCrumbs from 'app/sentry/SafeConsoleBreadCrumbs';
import InMemoryTeleradiologyAPI from 'app/adapters/InMemoryTeleradiologyAPI';
import CloudBasedTeleradiologyAPI from 'app/adapters/CloudBasedTeleradiologyAPI';
import TeleradiologyAPIProvider from 'app/providers/TeleradiologyAPIProvider';
import ReactSequentialProviders from 'app/providers/ReactSequentialProviders';
import { NativeDicomWriter } from 'app/adapters/NativeDicomWriter';
import DicomWriterContext from 'app/providers/DicomWriterContext';
import ImageWriterContext from 'app/providers/ImageWriterContext';
import ImageWriter from 'app/interfaces/ImageWriter';
import TeleradiologyAnnouncementModal from 'app/components/AnnouncementModal/Teleradiology';
import TechnicianPasswordValidationProvider from 'app/providers/TechnicianPasswordValidationProvider';

// Configure cornerstone
import 'app/utils/cornerstone/configureImageLoaders';
import MainImageRendererFactoryProvider from 'app/providers/ImageRenderer/MainImageRendererFactory/provider';
import thumbnailImageRendererFactoryContext from 'app/providers/ImageRenderer/ThumbnailImageRendererFactory/context';
import { makeThumbnailImageRendererFactory } from 'app/adapters/ThumbnailImageRenderer';
import DicomBuilderProvider from 'app/providers/DicomBuilder/DicomBuilderProvider';
import DisplayableImageEncoderProvider from 'app/providers/IDisplayableImageEncoder/DisplayableImageEncoderProvider';
import JPEGLSModuleProvider from 'app/providers/JPEGLSModuleProvider';
import CodecModuleProvider from 'app/providers/CodecModuleProvider';
import XRayGeneratorHistoryProvider from 'app/providers/XRayGeneratorHistoryProvider';
import ImageProcessorProvider from 'app/providers/ImageProcessorProvider/provider';

if (process.env.MODE !== 'development') {
  const isSentryInitialized = Sentry.getCurrentHub().getClient() !== undefined;
  if (!isSentryInitialized) {
    Sentry.init({
      dsn: getDSN(),
      release: process.env.VERSION,
      environment: process.env.MODE,
      dist: getProjectNameFromEnv(),
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      integrations: (integrations) => [
        ...integrations.filter((integration) => integration.name !== 'Breadcrumbs'),
        new Sentry.Integrations.Breadcrumbs({ console: false }),
        new SafeConsoleBreadCrumbs(),
      ],
    });

    Sentry.setTag('project_name', getProjectNameFromEnv());
  }
}

// Enable immerjs Map and Set feature.
enableMapSet();

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, browserHistory);

const PMSInjector = React.memo(({ children }) => {
  const intl = useIntl();
  const intlRef = useRef(intl);
  // We abuse `useState` here because useRef cannot be lazy initialized which is what we need.
  // Init our values once and only change intlRef.current for tools.
  const [imageEncoder] = useState(
    () => new CornerstoneImageEncoder(() => createDefaultToolsProvider(() => intlRef.current))
  );

  const [gmVetExporter] = useState(() => {
    const fs = getFs();
    if (!fs) return undefined;

    return new GmVetExporter(imageEncoder, fs.promises);
  });

  const [vetoPartnerExporter] = useState(() => {
    const fs = getFs();
    if (!fs) return undefined;

    return new VetoPartnerExporter(imageEncoder, fs.promises);
  });

  const [pmsExportFunctions] = useState(() => {
    const fs = getFs();
    if (fs) {
      return {
        assisto_vet: new AssistoVetExporter(imageEncoder, fs.promises).exportDataToPMS,
        gm_vet: gmVetExporter.exportDataToPMS,
        veto_partner: vetoPartnerExporter.exportDataToPMS,
      };
    }
    return undefined;
  });

  useEffect(() => {
    intlRef.current = intl;
    gmVetExporter?.setIntl(intl);
    vetoPartnerExporter?.setIntl(intl);
  }, [intl]);

  return <PMSExportProvider pmsExportFunctions={pmsExportFunctions}>{children}</PMSExportProvider>;
});
observeStore(store, selectFlatPanelKind, syncFlatPanelKindWithBackEnd);

cst.init({
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: false,
  showSVGCursors: true,
});
const adminStore = new CloudAdminStore();
const userStore = new CloudUserStore();
let xrayGeneratorManager;
let flatPanelDetectorManagerInitializer;
if (isAcquisitionDemo()) {
  logger.info('Starting in acquisition demo mode');

  xrayGeneratorManager = new InMemoryXRayGeneratorControllerManager();

  const flatPanelDetectorManager = new InMemoryFlatPanelDetectorManager(
    new InMemoryFlatPanelDetector({
      implementation: DETECTOR_IMPLEMENTATION.THALES,
      serialNumber: 'demo_123456789',
      productModel: 'DemoMars1717V3',
      pixelSpacing: [0.1, 0.1],
      imagesPromise: loadXRayAcquisitionDemoImages(),
    })
  );

  linkXRayExposureToImageAcquisition(xrayGeneratorManager, flatPanelDetectorManager);

  flatPanelDetectorManagerInitializer = new InMemoryFlatPanelDetectorManagerInitializer(
    flatPanelDetectorManager
  );
} else {
  xrayGeneratorManager = serialport()
    ? new SerialXRayGeneratorControllerManager(serialport())
    : undefined;
  flatPanelDetectorManagerInitializer = xray()
    ? new NativeFlatPanelDetectorManagerInitializer()
    : undefined;
}

const xRayHistoryPath = path()?.join(
  electron()?.remote.app.getPath('userData'),
  'xray_history.csv'
);
const xRayErrorLogPath = path()?.join(
  electron()?.remote.app.getPath('userData'),
  'fault_history.log'
);
const teleradiologyAPI = new CloudBasedTeleradiologyAPI();
const dicomWriter = new NativeDicomWriter();
const imageWriter = new ImageWriter();

const render = (messages) => {
  const providers = [
    [Provider, { store }],
    [LanguageProvider, { messages }],
    [TechnicianPasswordValidationProvider],
    [StorageUsageMonitorProvider],
    [MainImageRendererFactoryProvider],
    [thumbnailImageRendererFactoryContext.Provider, { value: makeThumbnailImageRendererFactory() }],
    [CodecModuleProvider],
    [JPEGLSModuleProvider],
    [DisplayableImageEncoderProvider],
    [PACSCommunicationInjectorProvider],
    [LicenseManagerProvider],
    [FlatPanelStateProvider, { flatPanelDetectorManagerInitializer }],
    [ImageProcessorProvider],
    [PACSCommunicationProvider],
    [DicomBuilderProvider],
    [DicomWriterContext.Provider, { value: dicomWriter }],
    [ImageWriterContext.Provider, { value: imageWriter }],
    [LocalDicomStoreProvider],
    [PMSParserProvider],
    [PMSInjector],
    [UserStoreContext.Provider, { value: userStore }],
    [AdminStoreContext.Provider, { value: adminStore }],
    [XRayGeneratorManagerProvider, { xrayGeneratorManager }],
    [XRayGeneratorProvider, { xRayErrorLogPath }],
    [XRayGeneratorHistoryProvider, { xRayHistoryPath }],
    [TeleradiologyAPIProvider, { teleradiologyAPI }],
  ];

  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ReactSequentialProviders providers={providers}>
          <ToastContainer
            position="top-right"
            theme="colored"
            pauseOnFocusLoss={false}
            draggable={false}
          />
          <AppRouter store={store} />
          <TeleradiologyAnnouncementModal />
          <SentryUserInfo />
          <SentryFlatPanelInfo />
          <SentryFlatPanelKind />
        </ReactSequentialProviders>
      </LanguageProvider>
    </Provider>,
    document.getElementById('app')
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept('./i18n', () => {
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
