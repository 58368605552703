/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
// @ts-ignore
import { point } from 'cornerstone-math';
import { EVENTS } from 'app/CornerstoneTools';
import { EventWithDetail, Point, ToolMeasurement, ToolPoint } from 'app/CornerstoneTools/types';
import {
  addVector,
  getLineIntersection,
  getLinesAngle,
  getNormalizedOrth,
  pointsToVector,
  projectPointOntoLine,
  rotateVector,
} from 'app/CornerstoneTools/geometryHelper';
import { createLine } from '../geometryHelper';

const _moveEvents = {
  mouse: [EVENTS.MOUSE_MOVE, EVENTS.MOUSE_DRAG],
  touch: [EVENTS.TOUCH_DRAG],
};

const _moveEndEvents = {
  mouse: [EVENTS.MOUSE_UP, EVENTS.MOUSE_CLICK],
  touch: [EVENTS.TOUCH_END, EVENTS.TOUCH_PINCH, EVENTS.TAP],
};

export type VHSMeasurement = ToolMeasurement & {
  handles: {
    heartBottom: ToolPoint;
    heartLeft: ToolPoint;
    heartRight: ToolPoint;
    heartTop: ToolPoint;
    t4: ToolPoint;
    t8: ToolPoint;
  };
  previousHandles: VHSMeasurement['handles'];
  additionalVertebraPoints: ToolPoint[];
};

const computeShortAxisDistances = (handles: VHSMeasurement['handles']) => {
  const previousLongAxis = createLine(handles.heartBottom, handles.heartTop);
  const previousShortAxis = createLine(handles.heartLeft, handles.heartRight);

  const previousAxisIntersectionPoint = getLineIntersection(previousLongAxis, previousShortAxis);

  const leftDistance = point.distance(previousAxisIntersectionPoint, handles.heartLeft);

  const rightDistance = point.distance(previousAxisIntersectionPoint, handles.heartRight);
  return { leftDistance, rightDistance };
};

const projectShortAxisHandle = (
  intersectionPoint: Point,
  handles: VHSMeasurement['handles'],
  handle: VHSMeasurement['handles']['heartLeft'] | VHSMeasurement['handles']['heartRight'],
  distance: number
) => {
  const orthoProjectionVector = getNormalizedOrth(handles.heartBottom, handles.heartTop);
  const angle = getLinesAngle(
    [intersectionPoint, addVector(intersectionPoint, orthoProjectionVector)],
    [intersectionPoint, handle]
  );
  const direction = Math.abs(angle) < Math.PI / 2 ? 1 : -1;

  const projection = addVector(intersectionPoint, {
    x: direction * distance * orthoProjectionVector.x,
    y: direction * distance * orthoProjectionVector.y,
  });

  return projection;
};

function moveLongAxisHandle(toolData: VHSMeasurement, handleName: keyof VHSMeasurement['handles']) {
  const { previousHandles, handles } = toolData;

  let fixedHandle;
  if (handleName === 'heartBottom') {
    fixedHandle = handles.heartTop;
  } else if (handleName === 'heartTop') {
    fixedHandle = handles.heartBottom;
  } else {
    console.warn('Invalid handle', handleName);
    return;
  }
  const angle = getLinesAngle(
    [fixedHandle, previousHandles[handleName]],
    [fixedHandle, handles[handleName]]
  );
  const rotatedLeftVector = rotateVector(pointsToVector(fixedHandle, handles.heartLeft), angle);
  const rotatedLeftHandle = addVector(fixedHandle, rotatedLeftVector);
  const rotatedRightVector = rotateVector(pointsToVector(fixedHandle, handles.heartRight), angle);
  const rotatedRightHandle = addVector(fixedHandle, rotatedRightVector);

  handles.heartLeft.x = rotatedLeftHandle.x;
  handles.heartLeft.y = rotatedLeftHandle.y;

  handles.heartRight.x = rotatedRightHandle.x;
  handles.heartRight.y = rotatedRightHandle.y;
  toolData.previousHandles = _.cloneDeep(handles);
}

function moveShortAxisHandle(
  toolData: VHSMeasurement,
  handleName: keyof VHSMeasurement['handles']
) {
  const { previousHandles, handles } = toolData;

  const { leftDistance, rightDistance } = computeShortAxisDistances(previousHandles);

  const longAxis = createLine(handles.heartBottom, handles.heartTop);
  const axisIntersectionPoint = projectPointOntoLine(longAxis, handles[handleName]);

  if (handleName === 'heartLeft') {
    const rightProjection = projectShortAxisHandle(
      axisIntersectionPoint,
      handles,
      handles.heartRight,
      rightDistance
    );
    handles.heartRight.x = rightProjection.x;
    handles.heartRight.y = rightProjection.y;
  } else if (handleName === 'heartRight') {
    const leftProjection = projectShortAxisHandle(
      axisIntersectionPoint,
      handles,
      handles.heartLeft,
      leftDistance
    );
    handles.heartLeft.x = leftProjection.x;
    handles.heartLeft.y = leftProjection.y;
  } else {
    console.warn('Invalid handle', handleName);
    return;
  }

  toolData.previousHandles = _.cloneDeep(handles);
}

export default function (event: EventWithDetail, toolData: any, handle: any) {
  if (!toolData) return;

  const { handles } = toolData;
  const activeHandleKey = _.findKey(handles, handle) as keyof VHSMeasurement['handles'];

  const isLongAxisHandle = activeHandleKey === 'heartBottom' || activeHandleKey === 'heartTop';
  const isShortAxisHandle = activeHandleKey === 'heartLeft' || activeHandleKey === 'heartRight';

  if (isLongAxisHandle) {
    moveLongAxisHandle(toolData, activeHandleKey);
  }
  if (isShortAxisHandle) {
    moveShortAxisHandle(toolData, activeHandleKey);
  }

  toolData.previousHandles = _.cloneDeep(toolData.handles);
}
