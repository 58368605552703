import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import errorToMessage from 'app/errors/errorToMessage';

function FailedRequestText({ error, requestType }) {
  const errorContent = errorToMessage(error);
  const errorId = errorContent?.id ?? errorContent;
  const errorValues = errorContent?.values;

  return (
    <div>
      <h3>
        <FormattedMessage
          id="request.state.failed"
          values={{ request: <FormattedMessage id={`request.type.${requestType}`} /> }}
        />
      </h3>
      <p style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={errorId} values={errorValues} />
      </p>
    </div>
  );
}

export default memo(FailedRequestText);
