import { DataLossGuardConnectorContext } from 'app/components/DataLossGuard/DataLossGuardConnectorContext';
import makeChainablePromise, { ChainablePromise } from 'app/utils/async/ChainablePromise';
import createDeferredPromise from 'app/utils/createDeferredPromise';
import isEqualWithArrayBuffer from 'app/utils/isEqualWithArrayBuffer';
import { ObjectID } from 'bson';
import _, { DebouncedFunc, debounce } from 'lodash';
import React, { useContext } from 'react';

function hasDataChanged<T>(
  lastSavedData: Partial<T> = {},
  currentData: Partial<T> = {},
  excludedProperties?: string[]
) {
  if (lastSavedData === currentData) return false;
  if (excludedProperties) {
    return !isEqualWithArrayBuffer(
      _.omit(lastSavedData, excludedProperties),
      _.omit(currentData, excludedProperties)
    );
  } else {
    return !isEqualWithArrayBuffer(lastSavedData, currentData);
  }
}
// Shallow comparison to use if performance are bad for big objects.
// _.some(
//   _.uniq([...Object.keys(currentData), ...Object.keys(lastSavedData)]),
//   (k: keyof T) => currentData?.[k] !== lastSavedData?.[k]
// );

export type DataSaveEffectProps<T> = {
  data: T; // Data to save when it changes. Data change is computed using _.isEqual
  saveCallback: (data: T) => Promise<any>; // Save function call with save data provided when data changes
  dataLossKey?: string;
  initialData?: T; // Some data state considered already saved. Save will only be triggered if data differ from initialData.
  debounceDelay?: number; // If set this value will waited before saving any change similar to lodash debounce
  onlyGuard?: boolean; // If true, only register guard if data is different from last saved data.
  excludedProperties?: string[]; // Properties to exclude from deep comparison
};

type DataSaveEffectImplProps<T> = DataSaveEffectProps<T> & {
  dataLossGuard: DataLossGuardConnector;
};

class DataSaveEffectImpl<T> extends React.PureComponent<DataSaveEffectImplProps<T>> {
  // This could be replaced with React18 `useID` hook
  private dataSaveId: string;
  private lastInitialData: T;
  private lastSavedData: T;
  private lastDataToSave: T;
  private promiseChain: ChainablePromise = makeChainablePromise();
  private lockingPromise: DeferredPromise<void> = undefined;
  private saveDataDebounce: DebouncedFunc<
    (dataToSave: T, promise: DeferredPromise<unknown>) => any
  >;
  private unMounted: boolean = false;

  constructor(props: DataSaveEffectImplProps<T>) {
    super(props);
    this.dataSaveId = props.dataLossKey ?? new ObjectID().toHexString();
    this.lastInitialData = props.initialData;
    this.lastSavedData = props.initialData;

    this.saveDataDebounce = debounce(this.saveDataAlongPromiseChain, props.debounceDelay ?? 0, {
      leading: !props.debounceDelay, // Needed to force direct when debounceDelay is 0
    });
  }

  componentDidUpdate(): void {
    this.updateInitialData();
    this.saveDataIfInternalChange();
  }

  componentDidMount(): void {
    this.saveDataIfInternalChange();
  }

  componentWillUnmount(): void {
    this.promiseChain.cancel();
    this.dataLossGuard?.setDataSaveCallback(this.dataSaveId, undefined);
    this.unMounted = true;
  }

  get dataLossGuard() {
    if (this.unMounted) return undefined;
    return this.props.dataLossGuard;
  }

  saveDataAlongPromiseChain = (dataToSave: T, promise: DeferredPromise<unknown>) =>
    this.promiseChain
      .chainPromise(async () => {
        // Avoid calling intermediate save if a successive update overrode it
        const isLatestData = !hasDataChanged(
          this.lastDataToSave,
          dataToSave,
          this.props.excludedProperties
        );
        if (!isLatestData) return;
        const isAlreadySaved = !hasDataChanged(
          this.lastSavedData,
          dataToSave,
          this.props.excludedProperties
        );
        if (isAlreadySaved) return;

        return this.props.saveCallback(dataToSave).then(() => {
          this.lastSavedData = dataToSave;
        });
      })
      .then(promise.resolve, promise.reject);

  updateInitialData = () => {
    const { initialData } = this.props;
    if (!hasDataChanged(this.lastInitialData, initialData, this.props.excludedProperties)) return;
    this.lastSavedData = initialData;
    this.lastInitialData = initialData;
  };

  saveDataIfInternalChange = () => {
    const { data } = this.props;
    if (!hasDataChanged(this.lastSavedData, data, this.props.excludedProperties)) return;
    if (!hasDataChanged(this.lastDataToSave, data, this.props.excludedProperties)) return;

    this.lastDataToSave = data;
    this.saveDataUnderGuard(data);
  };

  unlockGuardOnlySave = () => {
    if (!this.lockingPromise) return;

    this.lockingPromise.resolve();
    this.lockingPromise = undefined;
  };

  lockGuardOnlySave = () => {
    if (this.lockingPromise) return;

    this.lockingPromise = createDeferredPromise();
    // We lock the save mechanism until lockingPromise is resolved on guard trigger.
    this.promiseChain.chainPromise(() => this.lockingPromise);
  };

  saveData = (dataToSave: T) => {
    if (this.props.onlyGuard) {
      this.lockGuardOnlySave();
    }
    const saveResult = createDeferredPromise();
    if (this.props.debounceDelay) {
      this.saveDataDebounce(dataToSave, saveResult);
    } else {
      this.saveDataAlongPromiseChain(dataToSave, saveResult);
    }
    return saveResult;
  };

  awaitPromiseChainSettle = async (): Promise<void> => {
    let lastPromise;
    do {
      lastPromise = this.promiseChain.getLastPromise();
      this.unlockGuardOnlySave();
      try {
        await lastPromise;
      } catch {}
      this.saveDataDebounce.flush();
    } while (lastPromise !== this.promiseChain.getLastPromise());

    await lastPromise;
  };

  saveDataUnderGuard = (data: T) => {
    this.dataLossGuard?.setDataSaveCallback(this.dataSaveId, this.awaitPromiseChainSettle);

    return this.saveData(data)
      .then(() => {
        const isLatestDataToSave = data === this.lastDataToSave;
        if (!isLatestDataToSave) return;
        // Only the last data save will clear the data loss guard.
        this.dataLossGuard?.setDataSaveCallback(this.dataSaveId, undefined);
      })
      .catch((e) => {
        const isLatestDataToSave = data === this.lastDataToSave;
        if (!isLatestDataToSave) return;
        // Only the last data save will reset the data save guard.
        this.dataLossGuard?.setDataSaveCallback(this.dataSaveId, async () => {
          this.saveDataUnderGuard(data);
          return this.awaitPromiseChainSettle();
        });
        throw e;
      });
  };

  render(): JSX.Element {
    return null;
  }
}

export default function DataSaveEffect<T>(props: React.PropsWithChildren<DataSaveEffectProps<T>>) {
  const dataLossGuard = useContext(DataLossGuardConnectorContext);
  return <DataSaveEffectImpl {...props} dataLossGuard={dataLossGuard} />;
}
