import cst from 'cornerstone-tools';

export default function resetCursor(element) {
  // Reset cursor to initial
  if (cst.store.state.svgCursorUrl) {
    window.URL.revokeObjectURL(cst.store.state.svgCursorUrl);
  }
  cst.store.state.svgCursorUrl = null;
  if (element?.style?.cursor) element.style.cursor = 'initial';
}
