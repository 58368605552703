import React, { useContext, useEffect, useState } from 'react';

import {
  TeleradiologyAnalysisStatus,
  TeleradiologyStudyIdentifier,
} from 'app/interfaces/TeleradiologyAPI';
import TeleradiologyAPIContext from 'app/providers/TeleradiologyAPIProvider/context';
import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type TeleradiologyStudyShortStatus = {
  studyId: string;
  initialAnalysisStatus: TeleradiologyAnalysisStatus;
};

export default function TeleradiologyStudyShortStatus({
  studyId,
  initialAnalysisStatus,
}: TeleradiologyStudyShortStatus) {
  if (!initialAnalysisStatus) return null;

  const teleradiologyAPI = useContext(TeleradiologyAPIContext);
  const [analysisStatus, setAnalysisStatus] = useState(initialAnalysisStatus);

  useEffect(() => {
    if (analysisStatus === TeleradiologyAnalysisStatus.DONE) return;

    teleradiologyAPI
      .getStatus(studyId)
      .then((study: TeleradiologyStudyIdentifier) => {
        setAnalysisStatus(study.analysis_status);
      })
      .catch(() => {});
  }, []);

  const [icon, textId] = (() => {
    switch (analysisStatus) {
      case TeleradiologyAnalysisStatus.INCOMPLETE:
        return [<TeleradiologyIcon color="red" />, 'teleradiology.analyze-status.incomplete'];
      case TeleradiologyAnalysisStatus.PENDING:
        return [<TeleradiologyIcon color="orange" />, 'teleradiology.analyze-status.pending'];
      case TeleradiologyAnalysisStatus.DONE:
        return [<TeleradiologyIcon color="green" />, 'teleradiology.analyze-status.done'];
    }
  })();

  return (
    <Popup trigger={<div>{icon}</div>}>
      <Popup.Header>
        <FormattedMessage id="teleradiology.analyze-status.header" />
      </Popup.Header>
      <Popup.Content>
        <FormattedMessage id={textId} />
      </Popup.Content>
    </Popup>
  );
}
