/* eslint-disable no-continue */
import * as csc from 'cornerstone-core';
import * as csm from 'cornerstone-math';
import * as cst from 'cornerstone-tools';
import * as _ from 'lodash';
import {
  getCanvasDistance,
  offsetPixelPointInCanvas,
  projectAlongAxis,
  projectPerpendicularToAxis,
} from 'app/CornerstoneTools/pixelToCanvasUtils';
import {
  getLineIntersection,
  getLinesAngle,
  rotateVector,
} from 'app/CornerstoneTools/geometryHelper';
import toStringDegree from 'app/CornerstoneTools/geometry/toStringDegree';
import { drawToolStepExplanations } from 'app/CornerstoneTools/utils/toolStepsExplanations';
import {
  BaseAnnotationTool,
  getNewContext,
  draw,
  drawHandles,
  drawLine,
  drawTextBox,
  path,
  addToolState,
  toolStyle,
  getToolForElement,
} from 'app/CornerstoneTools';
import checkPointNearHandles from 'app/CornerstoneTools/checkPointNearHandles';
import makeMoveHandlerChain from 'app/CornerstoneTools/makeMoveHandlerChain';
import ChainingToolBuilder from 'app/CornerstoneTools/ChainingToolBuilder';

const CORNERSTONE_TEXT_PADDING = 5;

export const getTAProjection = (element, { TA1, TA2, MTP1, MTP2 }) => {
  if (!TA1 || !TA2 || !MTP1 || !MTP2) return undefined;

  const MTPLength = getCanvasDistance(element, MTP1, MTP2);
  const intersectionPoint = getLineIntersection(
    { start: TA1, end: TA2 },
    { start: MTP1, end: MTP2 }
  );
  if (!intersectionPoint) return intersectionPoint;

  return {
    start: projectPerpendicularToAxis(element, intersectionPoint, TA1, TA2, -MTPLength / 2),
    end: projectPerpendicularToAxis(element, intersectionPoint, TA1, TA2, MTPLength / 2),
  };
};

const getAcuteAngle = (angle) => {
  const isWideAngle = Math.abs(angle) >= Math.PI / 2;
  return isWideAngle ? angle - csm.sign(angle) * Math.PI : angle;
};

const computeTPLOAngleWithTAProjection = (element, { MTP1, MTP2 }, TAProjection) => {
  if (!TAProjection) return undefined;
  const TPLOAngle = getLinesAngle(
    [csc.pixelToCanvas(element, MTP1), csc.pixelToCanvas(element, MTP2)],
    [csc.pixelToCanvas(element, TAProjection.start), csc.pixelToCanvas(element, TAProjection.end)]
  );
  return getAcuteAngle(TPLOAngle);
};

export const computeTPLOAngle = (element, handles) =>
  computeTPLOAngleWithTAProjection(element, handles, getTAProjection(element, handles));

export default class TPLOTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'TPLOTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        dicomDataPrinterToolName: 'DicomDataPrinter',
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        getIntl: () => {},
      },
    };

    super(props, defaultProps);
    this.toolBuilder = new ChainingToolBuilder({
      name: this.name,
      createNewMeasurement: this.createNewMeasurement,
      handlesKeys: ['TA1', 'TA2', 'MTP1', 'MTP2'],
      maximumToolData: 1,
    });
    this.addNewMeasurement = this.toolBuilder.addNewMeasurement;

    this.pointNearTool = checkPointNearHandles;
  }

  createNewMeasurement = (eventData) => ({
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    handles: {
      TA1: {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        highlight: true,
        active: true,
      },
    },
  });

  // TODO(REFACTO): All angle and projection could be cached to reduce computation on rerender
  // where tool is not modified.
  updateCachedStats = (_image, element, data) => {};

  drawTool = (element, ctx, data) => {
    // Draw the handles
    const color = cst.toolColors.getColorIfActive(data);
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;

    const handleOptions = {
      color,
      handleRadius,
      drawHandlesIfActive: drawHandlesOnHover,
      hideHandlesIfMoving,
    };

    const lineWidth = toolStyle.getToolWidth();

    drawHandles(ctx, { element }, data.handles, handleOptions);

    let isLeftSideView = true;
    const TAProjection = getTAProjection(element, data.handles);

    if (TAProjection) {
      drawLine(ctx, element, TAProjection.start, TAProjection.end, { color: 'red' });

      const TPLOCircleCenter = csc.pixelToCanvas(element, {
        x: (TAProjection.end.x + TAProjection.start.x) / 2,
        y: (TAProjection.end.y + TAProjection.start.y) / 2,
      });

      const TPLOAngle = computeTPLOAngleWithTAProjection(element, data.handles, TAProjection);

      isLeftSideView = TPLOAngle < 0;

      // Using acute angle avoid error due to MTP1 and MTP2 being reversed.
      this.drawTPLOArc(
        element,
        ctx,
        data.handles,
        isLeftSideView,
        TAProjection,
        TPLOCircleCenter,
        TPLOAngle,
        { color, lineWidth }
      );
    }

    this.drawTALine(element, ctx, data.handles, isLeftSideView, { color, lineWidth });
    this.drawMTPLine(element, ctx, data.handles, isLeftSideView, { color, lineWidth });
  };

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { element } = eventData;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = cst.getToolState(evt.currentTarget, this.name);
    const { getIntl, dicomDataPrinterToolName } = this.configuration;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(
      context,
      /** @param {CanvasRenderingContext2D} ctx */
      (ctx) => {
        const dicomDataPrinterTextOffset =
          getToolForElement(element, dicomDataPrinterToolName)?.dicomTextArea?.topLeft?.y ?? 0;

        drawToolStepExplanations(
          ctx,
          toolData?.data,
          cst.getToolForElement(element, this.name).mode === 'active',
          ['tools.TPLO.steps.1', 'tools.TPLO.steps.2'].map(
            (id) => () => getIntl()?.formatMessage({ id })
          ),
          [
            ['TA1', 'TA2'],
            ['MTP1', 'MTP2'],
          ],
          {
            x: 5,
            y: dicomDataPrinterTextOffset + 20,
          },
          { maxWidth: (2 * eventData.canvasContext.canvas.width) / 5 }
        );

        toolData?.data.forEach((data) => {
          if (this.toolBuilder.checkToolCreationCancelled(element, data)) return;
          this.drawTool(element, ctx, data);
        });
      }
    );
  };

  drawTPLOArc = (
    element,
    ctx,
    handles,
    isLeftSideView,
    TAProjection,
    TPLOCircleCenter,
    TPLOAngle,
    { color, lineWidth }
  ) => {
    const { MTP1, MTP2 } = handles;
    const XAxisToMTPAxisAcuteAngle = getAcuteAngle(
      getLinesAngle(
        [
          { x: 0, y: 0 },
          { x: 1, y: 0 },
        ],
        [csc.pixelToCanvas(element, MTP1), csc.pixelToCanvas(element, MTP2)]
      )
    );

    let TPLOArcStart;
    let TPLOArcEnd;
    if (isLeftSideView) {
      TPLOArcStart = XAxisToMTPAxisAcuteAngle;
      TPLOArcEnd = TPLOArcStart + TPLOAngle;
    } else {
      TPLOArcStart = XAxisToMTPAxisAcuteAngle + Math.PI;
      TPLOArcEnd = TPLOArcStart + TPLOAngle;
    }

    const arcRadius =
      csm.point.distance(
        csc.pixelToCanvas(element, TAProjection.start),
        csc.pixelToCanvas(element, TAProjection.end)
      ) / 4;

    path(ctx, { color: 'red' }, (drawContext) => {
      drawContext.arc(
        TPLOCircleCenter.x,
        TPLOCircleCenter.y,
        arcRadius,
        TPLOArcStart,
        TPLOArcEnd,
        isLeftSideView
      );
    });

    const projectionAxis = rotateVector({ x: 1, y: 0 }, (TPLOArcStart + TPLOArcEnd) / 2);

    const textBoxCoordinates = csc.pixelToCanvas(
      element,
      projectAlongAxis(
        element,
        csc.canvasToPixel(element, TPLOCircleCenter),
        csc.canvasToPixel(element, { x: 0, y: 0 }),
        csc.canvasToPixel(element, projectionAxis),
        arcRadius + 20
      )
    );

    ctx.save();
    if (!isLeftSideView) {
      ctx.textAlign = 'right';
    }
    drawTextBox(
      ctx,
      toStringDegree(Math.abs(TPLOAngle)),
      textBoxCoordinates.x - CORNERSTONE_TEXT_PADDING,
      textBoxCoordinates.y,
      color,
      { centering: { y: true } }
    );
    ctx.restore();
  };

  drawTALine = (element, ctx, { TA1, TA2 }, isLeftSideView, { color, lineWidth }) => {
    if (!TA1 || !TA2) return;

    drawLine(ctx, element, TA1, TA2, { color, lineWidth });
    const TA1Canvas = csc.pixelToCanvas(element, TA1);
    const TA2Canvas = csc.pixelToCanvas(element, TA2);
    const bottomToTopTAAxis = TA2Canvas.y >= TA1Canvas.y ? [TA2, TA1] : [TA1, TA2];

    const offsetToTA = isLeftSideView ? -10 : 10;

    const TAAnnotationCoords = csc.pixelToCanvas(
      element,
      offsetPixelPointInCanvas(
        element,
        projectPerpendicularToAxis(element, bottomToTopTAAxis[0], ...bottomToTopTAAxis, offsetToTA),
        { x: -CORNERSTONE_TEXT_PADDING, y: -CORNERSTONE_TEXT_PADDING }
      )
    );

    ctx.save();
    if (isLeftSideView) {
      ctx.textAlign = 'right';
    }
    drawTextBox(ctx, 'TA', TAAnnotationCoords.x, TAAnnotationCoords.y, color);
    ctx.restore();
  };

  drawMTPLine = (element, ctx, { MTP1, MTP2 }, isLeftSideView, { color, lineWidth }) => {
    if (!MTP1 || !MTP2) return;
    drawLine(ctx, element, MTP1, MTP2, { color, lineWidth });

    const MTP1Canvas = csc.pixelToCanvas(element, MTP1);
    const MTP2Canvas = csc.pixelToCanvas(element, MTP2);
    const leftToRightMTPAxis = MTP2Canvas.x >= MTP1Canvas.x ? [MTP1, MTP2] : [MTP2, MTP1];

    const refPoint = isLeftSideView ? leftToRightMTPAxis[0] : leftToRightMTPAxis[1];

    const MTPAnnotationCoords = csc.pixelToCanvas(
      element,
      offsetPixelPointInCanvas(
        element,
        projectPerpendicularToAxis(element, refPoint, ...leftToRightMTPAxis, -20),
        { x: -CORNERSTONE_TEXT_PADDING, y: -CORNERSTONE_TEXT_PADDING }
      )
    );

    ctx.save();
    if (!isLeftSideView) {
      ctx.textAlign = 'right';
    }
    drawTextBox(ctx, 'MTP', MTPAnnotationCoords.x, MTPAnnotationCoords.y, color);
    ctx.restore();
  };
}
