import { createSelector } from 'reselect';
import { makeSyncReduxWithLocalStorageObserver } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';

export const selectPMSReducer = (state: any) => state.get('pms');

export const makeSelectIsInvalidWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms: any) => pms.get('isInvalidWatchedDirectory'));

export const makeSelectWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms: any) => pms.get('watchedDirectory'));

export const selectPMSWatchedDirectory = makeSelectWatchedDirectory();
export const selectPMSType = (state: any) => selectPMSReducer(state).get('type');
export const selectPMSExportConfiguration = (state: any) =>
  selectPMSReducer(state).get('exportConfiguration');

export const attachSyncPMSStateWithLocalStorageObserver = (store: any) => {
  observeStore(store, selectPMSReducer, makeSyncReduxWithLocalStorageObserver('redux.pms'));
};
