import { DataImage } from 'app/interfaces/DataImage';
import { DisplayableImageData } from 'app/interfaces/Image';

type AdditionalDataInfo = {
  columnPixelSpacing?: number;
  rowPixelSpacing?: number;
};

export default function convertDataImageToDisplayableImageData(
  imageData: DataImage & AdditionalDataInfo,
  imageId: string
): DisplayableImageData {
  const {
    height,
    width,
    minPixelValue = 0,
    maxPixelValue = 65535,
    windowWidth,
    windowCenter,
    columnPixelSpacing,
    rowPixelSpacing,
    data,
  } = imageData;

  return {
    imageId,
    getPixelData: () => data,
    minPixelValue,
    maxPixelValue,
    slope: 1,
    intercept: 0,
    windowCenter: windowCenter ?? (maxPixelValue + minPixelValue) / 2,
    windowWidth: windowWidth ?? maxPixelValue - minPixelValue,
    rows: height,
    columns: width,
    height,
    width,
    color: false,
    columnPixelSpacing,
    rowPixelSpacing,
    invert: false,
    sizeInBytes: width * height * 2,
    getImageData: undefined,
    getCanvas: undefined,
    getImage: undefined,
    lut: undefined,
    rgba: false,
    cachedLut: undefined,
  };
}
