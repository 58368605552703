/* eslint-disable react/prop-types */
import React from 'react';
import XRayGeneratorManagerContext from 'app/providers/XRayGeneratorManagerProvider/context';

const XRayGeneratorManagerProvider = ({ xrayGeneratorManager, children }) => (
  <XRayGeneratorManagerContext.Provider value={xrayGeneratorManager}>
    {children}
  </XRayGeneratorManagerContext.Provider>
);

export default XRayGeneratorManagerProvider;
