import React, { useMemo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Confirm, FormInput, Modal } from 'semantic-ui-react';

import createDeferredPromise from 'app/utils/createDeferredPromise';

/**
 * @typedef {Object} PasswordProtection
 * @prop {() => Promise<void>]} requestValidation
 */

const focusRef = (e) => e?.focus();

const VALIDATION_VALIDITY_MS = 30 * 60 * 1000;

export default function usePasswordProtection({ header, placeholder, passwordValidationFn }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isPasswordRequiredOpen, setIsPasswordRequiredOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [pendingValidationPromise, setPendingValidationPromise] = useState(undefined);
  const [lastValidationTime, setLastValidationTime] = useState(undefined);

  const requestValidation = useCallback(async () => {
    if (lastValidationTime && Date.now() - lastValidationTime.getTime() < VALIDATION_VALIDITY_MS) {
      return undefined;
    }

    const validationPromise = createDeferredPromise();
    setPendingValidationPromise(validationPromise);
    setIsPasswordRequiredOpen(true);
    return validationPromise;
  }, [lastValidationTime]);

  const closePasswordModal = useCallback(() => {
    pendingValidationPromise?.reject();
    setPendingValidationPromise(undefined);
    setIsPasswordRequiredOpen(false);
    setPassword('');
    setPasswordError(undefined);
  }, [pendingValidationPromise, setIsPasswordRequiredOpen]);

  const checkPassword = useCallback(
    (e) => {
      if (passwordValidationFn(password)) {
        pendingValidationPromise?.resolve();
        setPendingValidationPromise(undefined);
        setIsPasswordRequiredOpen(false);
        setPassword('');
        setPasswordError(undefined);
        setLastValidationTime(new Date());
      } else {
        setPasswordError(true);
      }
      // prevent form submission default
      e.preventDefault();
    },
    [pendingValidationPromise, password, setIsPasswordRequiredOpen, dispatch]
  );

  const onPasswordChange = useCallback((e, { value }) => setPassword(value), [setPassword]);

  const passwordErrorText = passwordError
    ? intl.formatMessage({ id: 'forms.invalid_password' })
    : undefined;

  const modalComponent = useMemo(
    () => (
      <Confirm
        as="form"
        className="ui form"
        open={isPasswordRequiredOpen}
        header={header}
        onConfirm={checkPassword}
        confirmButton={
          <Button type="submit">
            <FormattedMessage id="general.confirm" />
          </Button>
        }
        cancelButton={
          <Button type="button">
            <FormattedMessage id="general.cancel" />
          </Button>
        }
        onCancel={closePasswordModal}
        onClose={closePasswordModal}
        size="tiny"
        content={
          <Modal.Content>
            <FormInput
              input={{ ref: focusRef }}
              placeholder={placeholder}
              type="password"
              value={password}
              onChange={onPasswordChange}
              error={passwordErrorText}
            />
          </Modal.Content>
        }
      />
    ),
    [
      isPasswordRequiredOpen,
      header,
      checkPassword,
      closePasswordModal,
      placeholder,
      onPasswordChange,
      passwordErrorText,
    ]
  );

  return [requestValidation, modalComponent];
}
