import APICauses from 'app/errors/APICauses';
import { throwAsGenericError } from 'app/errors/GenericError/fromAxios';
import {
  Image,
  ImagesToUpload,
  TeleradiologyAPI,
  TeleradiologyAffiliate,
  TeleradiologyAnalysisStatus,
  TeleradiologyPatient,
  TeleradiologyStudy,
} from 'app/interfaces/TeleradiologyAPI';

// js modules
// @ts-ignore
import ApiCalls, { API } from 'app/utils/apiCalls';
import logger from 'app/utils/debug/logger';
import _ from 'lodash';

const formatAffiliateForAPI = (affiliate: TeleradiologyAffiliate): API.Teleradiology.Affiliate => {
  return {
    company_name: affiliate.companyName,
    first_name: affiliate.firstName,
    last_name: affiliate.lastName,
    title: API.Teleradiology.AffiliateTitle.Dr,
    email: affiliate.email,
    phone_number: affiliate.phoneNumber,
    workplace_name: affiliate.clinic,
    address: affiliate.address,
    address_complement: affiliate.addressComplement,
    city: affiliate.city,
    post_code: affiliate.postCode,
    country: affiliate.country,
    country_code: affiliate.countryCode,
    num_tva: affiliate.vatNumber,
    website: affiliate.website,
    diploma: affiliate.diploma,
    language: affiliate.language,
    timezone: affiliate.timezone,
  };
};

const convertSpecieToVetflowSpecie = (specie: string): API.Teleradiology.VetflowSpecie => {
  // Specie from app\utils\speciesConstants.jsx
  switch (specie) {
    case 'dog':
      return API.Teleradiology.VetflowSpecie.CHIEN;
    case 'cat':
      return API.Teleradiology.VetflowSpecie.CHAT;
    case 'horse': // Do not exists yet
      return API.Teleradiology.VetflowSpecie.CHEVAL;
    case 'rodent':
    case 'rabbit':
      return API.Teleradiology.VetflowSpecie.RONGEUR;
    case 'ferret':
    case 'bird':
    case 'reptile':
      return API.Teleradiology.VetflowSpecie.NAC;
  }

  return API.Teleradiology.VetflowSpecie.AUTRE;
};

const convertSexToVetflowSex = (sex: string | undefined): API.Teleradiology.VetflowSex => {
  // Specie from app\utils\sexesConstants.jsx
  if (sex === undefined) return undefined;
  switch (sex) {
    case 'male':
      return API.Teleradiology.VetflowSex.MALE;
    case 'female':
      return API.Teleradiology.VetflowSex.FEMALE;
    case 'male_castrated':
      return API.Teleradiology.VetflowSex.MALE_CASTRATED;
    case 'female_castrated':
      return API.Teleradiology.VetflowSex.FEMALE_CASTRATED;
    case 'other':
      return API.Teleradiology.VetflowSex.OTHER;
  }
  return API.Teleradiology.VetflowSex.OTHER;
};

const formatPatientForAPI = (
  patient: TeleradiologyPatient
): API.Teleradiology.VetflowPatient | undefined => {
  if (!patient) return undefined;

  const { owner } = patient;
  const address = owner?.address;

  return {
    nom: patient.name,
    especeNom: convertSpecieToVetflowSpecie(patient.specie),
    especePerso: patient.specieCustom,
    numero: patient.patientId,
    race: patient.race,
    age: patient.age,
    dateNaissance: patient.birthDate?.toISOString(),
    sexe: convertSexToVetflowSex(patient.sex),
    poids: patient.weight,
    proprietaire: {
      nom: owner.lastName,
      prenom: owner.firstName,
      adresse: {
        adresse1: address.address,
        adresse2: address.addressComplement,
        codePostal: address.postCode,
        ville: address.city,
        pays: address.country,
        telephone: address.phoneNumber,
        email: address.email,
      },
    },
  };
};

const formatStudyForAPI = (study: TeleradiologyStudy): API.Teleradiology.Study => {
  return {
    companyNom: study.companyName,
    dateExam: study.examDate.toISOString(),
    dateSoumission: new Date().toISOString(),
    typeExamNom: API.Teleradiology.VetflowExamType.Radiologie,
    vetoExaminant: study.examiningVeterinarian,
    emailExaminant: study.examiningEmail,
    vetoTraitant: study.referentVeterinarian,
    cliniqueTraitante: study.referentClinic,
    emailTraitant: study.referentEmail,
    motif: study.reason,
    signescliniques: study.clinicalSigns,
    moninterpretation: study.veterinarianInterpretation,
    dateExamRadio: study.xrayDate ? study.xrayDate.toISOString() : undefined,
    enUrgence: study.isUrgent,
    patient: formatPatientForAPI(study.patient),
  };
};

export default class CloudBasedTeleradiologyAPI implements TeleradiologyAPI {
  async isEnabled() {
    // TBD(GC): Need to implement admin teleradiology activation
    return true;
  }

  getAffiliateId = async () => {
    return ApiCalls.getTeleradiologyAffiliate()
      .then(({ data }) => data.teleradiology_affiliate_id as string)
      .catch((error) => {
        if (error.response?.cause === APICauses.TELERADIOLOGY_USER_NOT_FOUND) {
          return undefined as undefined;
        }
        return throwAsGenericError(error);
      });
  };

  getStatus = async (studyId: string) =>
    await ApiCalls.getTeleradiologyStudyStatus(studyId)
      .then(({ data }) => data)
      .catch(throwAsGenericError);

  // TBD
  async getAnalysisReport(studyId: string) {
    return ApiCalls.getTeleradiologyReport(studyId)
      .then(({ data }) => data)
      .catch(throwAsGenericError);
  }

  async createAffiliate(affiliate: TeleradiologyAffiliate) {
    return ApiCalls.createTeleradiologyAffiliate(formatAffiliateForAPI(affiliate))
      .then(({ data: affiliate }) => affiliate)
      .catch(throwAsGenericError);
  }

  async requestAnalyze(
    studyId: string,
    studyDetails: TeleradiologyStudy,
    images: ImagesToUpload,
    imageUploadProgressCb?: (imageId: string, progress: number, total: number) => void
  ) {
    const teleradiologyStudy = (
      await ApiCalls.createTeleradiologyStudy(
        studyId,
        formatStudyForAPI(studyDetails),
        _.map(images, (_value, imageId) => imageId)
      ).catch(throwAsGenericError)
    ).data;

    const imageUploadPromises = await this.addImagesToAnalyze(
      studyId,
      images,
      imageUploadProgressCb
    );

    // TBD(GC): Add send images to teleradiology
    return { ...teleradiologyStudy, imageUploadPromises };
  }

  /**
   * Images can be either image already present in the study that failed to upload or new images requested by the radiologist.
   */
  async addImagesToAnalyze(
    studyId: string,
    images: ImagesToUpload,
    imageUploadProgressCb?: (imageId: string, progress: number, total: number) => void
  ) {
    try {
      await ApiCalls.updateTeleradiologyImages(
        studyId,
        _.map(images, (_value, imageId) => imageId)
      );
    } catch (e) {
      logger.warn('updateTeleradiologyImages Failed', e);
    }
    // We prefer to send image one after the other to avoid flooding the bandwidth.
    // To do this we chain the promises one after the other.
    // To start the chain we start a dummy promise.
    let promiseChain = Promise.resolve();

    const imageUploadPromises = _.mapValues(images, async ({ imageContent }, imageId) => {
      const sendImageOncePreviousIsCompleted = () =>
        ApiCalls.sendTeleradiologyImage(studyId, imageId, imageContent, ({ total, progress }) =>
          imageUploadProgressCb?.(imageId, progress, total)
        )
          .then((response) => {
            console.log(`image(${imageId}) sent to telerad`, response.data);
          })
          .catch(throwAsGenericError);

      // We do not care wether the previous promise succeed or fail, we just want to start sending once the previous request returns.
      const imageSendPromise = promiseChain.then(
        sendImageOncePreviousIsCompleted,
        sendImageOncePreviousIsCompleted
      );

      promiseChain = imageSendPromise;
      return imageSendPromise;
    });

    return imageUploadPromises;
  }
}
