import { newline } from './constants';
import GenericPatternsReportGenerator from './GenericPatternsReportGenerator';
import {
  ABDOMEN_PATTERNS,
  COMMON_PATTERNS_DESCRIPTION,
} from '../../components/PatternStructuredList/patternsGroups';
import { ImageForReport } from 'app/utils/reports/types';
import { IntlShape } from 'react-intl';

class AbdomenThoraxReportGenerator extends GenericPatternsReportGenerator {
  generateReport = (imagesInCase: ImageForReport[]) => {
    const returned = this.getMaxPredictions(imagesInCase);
    const { maxPredictionsFeedback, maxPredictions } = returned;
    const numberOfPatternsDetected = this.getNumberOfPatternsToDisplay(
      maxPredictions,
      maxPredictionsFeedback,
      ABDOMEN_PATTERNS
    );
    let report = `${this.formatMessage('abdomen').toUpperCase()} :${newline}${newline}`;
    report += this.getShortCompteRendu(maxPredictions, maxPredictionsFeedback, {
      ...COMMON_PATTERNS_DESCRIPTION,
      ...ABDOMEN_PATTERNS,
    });

    if (numberOfPatternsDetected === 0) {
      report += this.formatMessage('report.abdomen.isOk');
    } else if (numberOfPatternsDetected <= 2) {
      report += this.formatMessage('report.abdomen.restIsOk');
    }

    report += newline + newline;
    return report;
  };
}

const getAbdomenReport = (imagesInCase: ImageForReport[], intl: IntlShape) => {
  const abdomenReportGenerator = new AbdomenThoraxReportGenerator(intl);
  return abdomenReportGenerator.generateReport(imagesInCase);
};

export default getAbdomenReport;
