import React from 'react';
import * as pt from 'prop-types';
import { localeDate } from '../../utils/dateUtil';

import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';

const DisplayDate = ({ date, format, style, long }) => {
  const dateFormatted = localeDate(date, format, long);
  return <span style={style}>{dateFormatted}</span>;
};

DisplayDate.propTypes = {
  date: pt.oneOfType([pt.instanceOf(Date), pt.string, pt.number]).isRequired,
  format: pt.string,
  style: pt.shape(),
  long: pt.bool,
};

DisplayDate.defaultProps = {
  long: false,
  style: {},
  format: 'longDateTime',
};

export default DisplayDate;
