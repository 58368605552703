import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import fs from 'app/native/node/fs';
import path from 'app/native/node/path';
import electron from 'app/native/node/electron';
import { isDicomImage, isWebImage, loadWebImage } from '../../utils/imageFsUtil';

class FileWatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watchedDirectory: null,
      watcher: null,
      isLoading: false,
    };
    this.base64Images = [];
    this.imagesPaths = [];
  }

  componentDidMount = () => {
    this.restoreChosenDirectoryFromLocalStorage();
  };

  componentWillUnmount = () => {
    const { watcher } = this.state;
    watcher?.close();
  };

  restoreChosenDirectoryFromLocalStorage = () => {
    const automaticExportDir = localStorage.getItem('automaticExportDir');
    const automaticExportOn = localStorage.getItem('automaticExportOn');
    if (
      automaticExportOn &&
      automaticExportOn !== 'false' &&
      fs()?.existsSync(automaticExportDir)
    ) {
      this.startWatcher(automaticExportDir);
    }
  };

  showDirectoryChoice = () => {
    electron()
      ?.remote?.dialog.showOpenDialog(this.getOpenDialogProperties())
      .then((selectedDirectories) => {
        if (!selectedDirectories.canceled) {
          this.startWatcher(selectedDirectories.filePaths[0]);
          localStorage.setItem('automaticExportDir', selectedDirectories.filePaths[0]);
        }
      });
  };

  getOpenDialogProperties = () => {
    if (fs().existsSync(this.state.watchedDirectory)) {
      return {
        defaultPath: this.state.watchedDirectory,
        properties: ['openDirectory'],
      };
    }
    return {
      properties: ['openDirectory'],
    };
  };

  startWatcher = (toWatchPath) => {
    localStorage.setItem('automaticExportOn', true);
    this.setState({ watchedDirectory: toWatchPath });
    const watcher = fs().watch(
      toWatchPath,
      {
        recursive: true,
        persistent: true,
      },
      (eventType, newFileName) => {
        const newFilePath = path().join(toWatchPath, newFileName);
        if (eventType === 'rename' && fs().existsSync(newFilePath)) {
          if (isDicomImage(newFilePath)) {
            //                  this.base64Images.unshift(newFilePath);
            if (!this.isAlreadyAdded(newFilePath)) {
              this.addImage({ path: newFilePath, isFromFileSystem: true }, newFilePath);
              const temporaryImageNumbers = this.base64Images.length;
              this.waitForImages(temporaryImageNumbers);
            }
          } else if (isWebImage(newFilePath)) {
            const waitTimeUntilImageIsWritten = 1000;
            setTimeout(() => {
              console.log(eventType, newFilePath);
              loadWebImage(newFilePath).then((base64Image) => {
                if (!this.isAlreadyAdded(newFilePath)) {
                  this.addImage(base64Image, newFilePath);
                  const temporaryImageNumbers = this.base64Images.length;
                  this.waitForImages(temporaryImageNumbers);
                }
              });
            }, waitTimeUntilImageIsWritten);
          }
        }
      }
    );
    this.setState({ watcher });
  };

  addImage = (base64Image, newFilePath) => {
    this.base64Images.unshift(base64Image);
    this.imagesPaths.unshift(newFilePath);
  };

  waitForImages = (temporaryImageNumbers) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      if (this.base64Images.length === temporaryImageNumbers) {
        this.props.onImageReady(this.base64Images);
        this.resetImagesList(temporaryImageNumbers);
        this.setState({ isLoading: false });
      }
    }, 2000);
  };

  resetImagesList = () => {
    this.base64Images = [];
    this.imagesPaths = [];
  };

  isAlreadyAdded = (newFilePath) => {
    console.log(newFilePath);
    console.log(this.imagesPaths);
    return this.imagesPaths.indexOf(newFilePath) > -1;
  };

  closeWatcher = () => {
    this.state.watcher.close();
    this.setState({ watcher: null });
    localStorage.setItem('automaticExportOn', false);
  };

  render() {
    if (!electron()) return null;

    const { watcher, isLoading } = this.state;
    const action = watcher ? this.closeWatcher : this.showDirectoryChoice;
    const icon = watcher ? 'pause' : 'paper plane';
    const popupDescriptionId = `fileWatcher.${watcher ? 'pause' : 'start'}`;
    return (
      <Popup
        position="right center"
        content={<FormattedMessage id={popupDescriptionId} />}
        trigger={
          <button type="button" className="ui button picoxia file-watcher" onClick={action}>
            <Icon name={isLoading ? 'sync alternate' : icon} loading={isLoading} />
            <span>
              <FormattedMessage id="fileWatcher.description" />
            </span>
          </button>
        }
      />
    );
  }
}

export default FileWatcher;
