/* eslint-disable no-bitwise */
import { FILE_ACCESSES, FILE_TYPE } from 'app/interfaces/FileSystemUtility';
import fs from 'app/native/node/fs';

export default class FSFileSystemUtility {
  getFileInfoSync = (filepath) => {
    try {
      const stat = fs().statSync(filepath);
      return {
        mode:
          (stat.mode & fs().constants.R_OK && FILE_ACCESSES.READ) |
          (stat.mode & fs().constants.W_OK && FILE_ACCESSES.WRITE),
        type: (stat.isDirectory() && FILE_TYPE.DIRECTORY) | (stat.isFile() && FILE_TYPE.FILE),
      };
    } catch {
      return undefined;
    }
  };
}
