/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import 'semantic-ui-css/semantic.min.css';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { Item, Grid, Accordion, Modal, Button } from 'semantic-ui-react';
import _ from 'lodash';

import ResetPasswordButton from 'app/components/ResetPasswordButton';
import LanguageDropdown from 'app/components/LanguageDropdown';
import PACSConfigurationRow from 'app/containers/AccountPage/PACSConfigurationRow';
import ApiCalls from 'app/utils/apiCalls';
import EditableField from 'app/components/EditableField';
import ClinicLogoForm from 'app/components/ClinicLogoForm';
import ConfigureProtocolsButton from 'app/components/ConfigureProtocolsButton';
import XRayGeneratorConfiguration from 'app/components/XRayGeneratorConfiguration';
import XRayGeneratorHistoryExtractor from 'app/components/XRayGeneratorHistoryExtractor';
import PMSConfiguration from 'app/components/PMSConfiguration';
import DicomStoreDirectoriesSelector from 'app/components/DicomStoreDirectoriesSelector';
import PurchaseHistory from 'app/components/PurchaseHistory';
import XRayGeneratorFaultsDisplay from 'app/components/XRayGeneratorFaultsDisplay';
import { XRayGeneratorStateContext } from 'app/providers/XRayGeneratorProvider';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import UserMappingConfiguration from 'app/components/UserMappingConfiguration';
import AiOnlyConfiguration from 'app/components/AiOnlyConfigureRow';
import { selectUsername } from 'app/redux/global/reducer';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import countries from 'app/constants/countries';
import ConfigureModal from 'app/components/ConfigureModal';
import FlatPanelConfiguration from 'app/components/FlatPanelConfiguration';
import TechnicianPasswordValidationContext from 'app/providers/TechnicianPasswordValidationProvider/context';
import TelemetryConfiguration from 'app/components/XRayGeneratorConfiguration/TelemetryConfiguration';

// eslint-disable-next-line react/prop-types
const GridRow = ({ title, item, style }) => (
  <Grid.Row style={style}>
    <Grid.Column>{title}</Grid.Column>
    <Grid.Column>{item}</Grid.Column>
  </Grid.Row>
);

const GeneratorPanel = () => {
  if (process.env.PLATFORM !== 'electron' || !isAcquisitionSoftware()) return undefined;

  const { softwareVersion } = useContext(XRayGeneratorStateContext) ?? {};
  const { version, major, minor } = softwareVersion ?? {};

  const xRaySoftwareVersion =
    version !== undefined ? (
      <Grid.Row>
        <Grid.Column>
          <FormattedMessage id="account.configuration.xray_generator.version" />
        </Grid.Column>
        <Grid.Column>
          {version}.{major}.{minor}
        </Grid.Column>
      </Grid.Row>
    ) : null;

  return {
    key: 'generator',
    title: {
      content: (
        <Item.Header as="h4" style={{ display: 'inline' }}>
          <FormattedMessage id="account.configuration.xray_generator.header" /> :
        </Item.Header>
      ),
    },
    content: {
      content: (
        <Grid columns={2} style={{ margin: '0' }}>
          {xRaySoftwareVersion}
          <Grid.Row>
            <Grid.Column>
              <FormattedMessage id="account.configuration.xray_generator.serial_port" />
            </Grid.Column>
            <Grid.Column>
              <XRayGeneratorConfiguration />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormattedMessage id="account.configuration.xray_generator.telemetry" />
            </Grid.Column>
            <Grid.Column>
              <TelemetryConfiguration />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormattedMessage id="account.configuration.xray_generator.extract_history" />
            </Grid.Column>
            <Grid.Column>
              <XRayGeneratorHistoryExtractor />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormattedMessage id="account.configuration.xray_generator.fault_history.display" />
            </Grid.Column>
            <Grid.Column>
              <XRayGeneratorFaultsDisplay />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
  };
};

const renderGridRow = (title, item, style) => <GridRow title={title} item={item} style={style} />;

function PMSConfigurationRow() {
  return (
    <Grid.Row>
      <Grid.Column style={{ paddingTop: '0.67857143em' }}>
        <FormattedMessage id="account.configuration.pms" />
      </Grid.Column>
      <Grid.Column>
        <Modal
          closeIcon
          trigger={
            <Button>
              <FormattedMessage id="general.configure" />
            </Button>
          }
        >
          <Modal.Header>
            <FormattedMessage id="account.configuration.pms.header" />
          </Modal.Header>
          <Modal.Content>
            <PMSConfiguration />
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid.Row>
  );
}

const saveClinicInfo = (clinicInfo) => ApiCalls.saveClinicInfo({ clinicInfo });

const getSaveInvoiceAddressFieldFunction = (fieldName) => (fieldValue) => {
  ApiCalls.setInvoiceAddressSingleField(fieldName, fieldValue);
};

const AccountPage = () => {
  const intl = useIntl();
  const username = useSelector(selectUsername);
  const requestTechnicianPassword = useContext(TechnicianPasswordValidationContext);

  const [reportInfo, setReportInfo] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState({});
  const panels = _.filter([GeneratorPanel()]);

  useEffect(() => {
    ApiCalls.getReportInfo(false).then((response) => setReportInfo(response.data.reportInfo));
    ApiCalls.getInvoiceAddress().then((response) => setInvoiceAddress(response.data));
  }, []);

  const renderInvoiceAddressRow = (fieldName, paddingBottom) =>
    renderGridRow(
      intl.formatMessage({ id: `invoiceAddressModal.${fieldName}` }),
      <EditableField
        type="text"
        placeholder={intl.formatMessage({ id: `invoiceAddressModal.${fieldName}` })}
        text={invoiceAddress[fieldName]}
        onTextChange={getSaveInvoiceAddressFieldFunction(fieldName)}
      />,
      { paddingBottom }
    );

  const handleChangeCountryCode = (event, { value }) => {
    const country = countries.find(({ countryCode }) => countryCode === value);
    // @todo preferer faire uns eul appel pour éditer la paire countryCode et country
    // @use ApiCalls.updateUserDetails(data) quand se sera disponible
    ApiCalls.setInvoiceAddressSingleField('countryCode', country.countryCode);
    ApiCalls.setInvoiceAddressSingleField('country', country.name);
    setInvoiceAddress((prevInvoiceAddress) => ({
      ...prevInvoiceAddress,
      countryCode: country.countryCode,
      country: country.name,
    }));
  };

  const renderUpdateButton = () => {
    if (process.env.PLATFORM !== 'website') {
      // eslint-disable-next-line global-require
      const UpdateButton = require('../../components/UpdateButton').default;
      return <UpdateButton color="blue" />;
    }
    return null;
  };

  return (
    <div className="billing">
      <Helmet title={intl.formatMessage({ id: 'account.pageTitle' })} />
      <div className="ui container" style={{ paddingTop: '40px' }}>
        <Item.Group divided>
          <Item style={{ paddingTop: '20px' }}>
            <Item.Content>
              <Item.Header as="h3">
                <FormattedMessage id="account.my_account.header" />
              </Item.Header>
              <Item.Meta />
              <Item.Description>
                <Grid columns={2} style={{ marginTop: '9px' }}>
                  {renderGridRow(intl.formatMessage({ id: 'forms.email' }), username)}
                  {renderGridRow(
                    intl.formatMessage({ id: 'forms.password' }),
                    <ResetPasswordButton />
                  )}
                  {process.env.PLATFORM === 'electron' &&
                    renderGridRow(
                      intl.formatMessage({ id: 'general.softwareVersion' }),
                      <div>
                        {ApiCalls.version} (<FormattedDate value={ApiCalls.releaseDate} />){' '}
                        {renderUpdateButton()}
                      </div>
                    )}
                </Grid>
              </Item.Description>
              <Item.Extra />
            </Item.Content>
          </Item>

          <Item style={{ paddingTop: '20px' }}>
            <Item.Content>
              <Item.Header as="h3">
                <FormattedMessage id="account.billing.header" />
              </Item.Header>
              <Item.Meta />
              <Item.Description>
                <Grid columns={2} style={{ marginTop: '9px' }}>
                  {renderInvoiceAddressRow('workplaceName', 0)}
                  {renderInvoiceAddressRow('address', 0)}
                  {renderInvoiceAddressRow('postCode', 0)}
                  {renderInvoiceAddressRow('city', 0)}
                  {/* {renderInvoiceAddressRow('country', 0)} */}
                  <Grid.Row>
                    <Grid.Column>
                      <FormattedMessage id="invoiceAddressModal.country" />
                    </Grid.Column>
                    <Grid.Column>
                      <DropdownCountriesList
                        value={invoiceAddress.countryCode}
                        onChange={handleChangeCountryCode}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {renderInvoiceAddressRow('phoneNumber', 10)}
                </Grid>
              </Item.Description>
              <Item.Extra />
            </Item.Content>
          </Item>

          <Item style={{ paddingTop: '20px' }}>
            <Item.Content>
              <Item.Header as="h3">
                <FormattedMessage id="account.report.header" />
              </Item.Header>
              <Item.Meta />
              <Item.Description>
                <Grid columns={2}>
                  {renderGridRow(
                    <pre style={{ padding: '10px 25px 10px 0px' }}>
                      <FormattedMessage id="account.report.info" />
                    </pre>,
                    <EditableField
                      type="textarea"
                      placeholder={intl.formatMessage({ id: 'monitoring.owner' })}
                      text={reportInfo?.clinicInfo}
                      onTextChange={saveClinicInfo}
                    />
                  )}
                  {renderGridRow(
                    intl.formatMessage({ id: 'clinicLogo.clinicLogo' }),
                    <ClinicLogoForm />
                  )}
                </Grid>
              </Item.Description>
              <Item.Extra />
            </Item.Content>
          </Item>

          <Item style={{ paddingTop: '20px' }}>
            <Item.Content>
              <Item.Header as="h3">
                <FormattedMessage id="account.configuration.header" />
              </Item.Header>
              <Item.Meta />
              <Item.Description>
                <Grid columns={2} style={{ marginTop: '9px' }}>
                  {renderGridRow(
                    <FormattedMessage id="account.configuration.language" />,
                    <LanguageDropdown location="accountPage" />
                  )}
                  {renderGridRow(
                    <FormattedMessage id="account.configuration.protocols" />,
                    <ConfigureProtocolsButton />
                  )}
                  {renderGridRow(
                    <FormattedMessage id="account.configuration.user_mapping" />,
                    <UserMappingConfiguration />
                  )}
                  {process.env.PLATFORM === 'electron' &&
                    renderGridRow(
                      <FormattedMessage id="account.configuration.acquisition_save_dir" />,
                      <DicomStoreDirectoriesSelector />
                    )}
                  <PACSConfigurationRow />
                  <AiOnlyConfiguration />
                  <PMSConfigurationRow />
                  {isAcquisitionSoftware() &&
                    renderGridRow(
                      <FormattedMessage id="account.configuration.flat_panel.title" />,
                      <ConfigureModal
                        title="account.configuration.flat_panel.title"
                        validateOpen={requestTechnicianPassword}
                      >
                        <FlatPanelConfiguration />
                      </ConfigureModal>
                    )}
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Accordion panels={panels} exclusive={false} fluid />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Description>
              <Item.Extra />
            </Item.Content>
          </Item>

          <Item style={{ paddingTop: '20px', marginBottom: '40px !important' }}>
            <Item.Content>
              <Item.Header as="h3">
                <FormattedMessage id="account.subscriptionsAndCredits.header" />
              </Item.Header>
              <Item.Meta />
              <Item.Description>
                <PurchaseHistory />
              </Item.Description>
              <Item.Extra />
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
    </div>
  );
};

export default AccountPage;
