const HSVtoRGB = (h, s, v) => {
  const f = (n, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5) * 255, f(3) * 255, f(1) * 255];
};

const hashCode = (string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i++) sum += ((i + 1) * string.codePointAt(i)) / (1 << 8);
  return sum % 1;
};

const stringToColor = (str) => {
  const h = hashCode(str) * 360;
  const s = 0.5;
  const v = 1;
  const rgb = HSVtoRGB(h, s, v);
  return rgb;
};

export default stringToColor;
