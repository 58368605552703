import stream from 'app/native/node/stream';
import _ from 'lodash';

export const STX = 0x02;
export const ETX = 0x03;

/** @returns {import("stream").Transform} */
export const GeneratorPacketWrapper = () => {
  /** @type {{Transform:import("stream").Transform}} */
  const { Transform } = stream();
  if (!Transform) return undefined;
  return new Transform({
    transform(chunk, encoding, callback) {
      this.push(Buffer.concat([Buffer.from([STX]), chunk, Buffer.from([ETX])]));
      callback();
    },
  });
};

const getPacketStart = (chunk, offset = 0) => {
  const stxIndex = chunk.indexOf(STX, offset);
  return stxIndex === -1 ? -1 : stxIndex + 1;
};

/** @type {(chunk:Buffer, ongoingPacket:Buffer)=>[[Buffer], Buffer]} */
const splitChunkIntoPackets = (chunk, previousOngoingPacket) => {
  const packets = [];
  let ongoingPacket = previousOngoingPacket;
  let etxIndex = 0;
  let packetStart = ongoingPacket ? 0 : getPacketStart(chunk);

  while (packetStart !== -1) {
    etxIndex = chunk.indexOf(ETX, packetStart);

    ongoingPacket = Buffer.concat(
      _.filter([ongoingPacket, chunk.subarray(packetStart, etxIndex === -1 ? undefined : etxIndex)])
    );
    if (etxIndex === -1) break;

    packets.push(ongoingPacket);
    ongoingPacket = undefined;

    packetStart = getPacketStart(chunk, packetStart);
  }

  return [packets, ongoingPacket];
};

/** @returns {import("stream").Transform} */
export const GeneratorPacketUnWrapper = () => {
  /** @type {{Transform:import("stream").Transform}} */
  const { Transform } = stream();
  if (!Transform) return undefined;
  return new Transform({
    /** @type {(chunk:Buffer, encoding, callback)=>void} */
    transform(chunk, encoding, chunkProcessed) {
      const [completePackets, ongoingPacket] = splitChunkIntoPackets(chunk, this.packet);
      completePackets.forEach((packet) => this.push(packet));

      this.packet = ongoingPacket;
      chunkProcessed();
    },
  });
};
