import './style.scss';

import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Creatable from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectXRayOperators,
  selectXRaySelectedOperators,
} from 'app/redux/xrayGeneratorConfiguration/reducer';
import { addOperator, setSelectedOperators } from 'app/redux/xrayGeneratorConfiguration/actions';
import _ from 'lodash';
import { DARK_INPUT_STYLE } from 'app/ReactSelectStyles';

const operatorListToOptions = (operator) => ({
  label: operator,
  value: operator,
});

const XRayOperatorSelector = () => {
  const operators = useSelector(selectXRayOperators);
  const selectedOperators = useSelector(selectXRaySelectedOperators);
  const dispatch = useDispatch();

  const operatorsOptions = useMemo(
    () => operators?.toJS().map(operatorListToOptions) ?? [],
    [operators]
  );
  const selectedOperatorsOptions = useMemo(
    () => selectedOperators?.toJS().map(operatorListToOptions) ?? [],
    [selectedOperators]
  );
  const onCreateOperator = useCallback(
    (operator) => {
      dispatch(addOperator(operator));
      dispatch(setSelectedOperators([...(selectedOperators?.toJS() ?? []), operator]));
    },
    [dispatch, selectedOperators]
  );

  return (
    <div className="xray-operators">
      <h3>
        <FormattedMessage id="xray_generator.operators" />:
      </h3>
      <div className="xray-operators__dropdown">
        <Creatable
          isMulti
          onChange={(value) => dispatch(setSelectedOperators(_.map(value, 'value')))}
          options={operatorsOptions}
          value={selectedOperatorsOptions}
          onCreateOption={onCreateOperator}
          styles={DARK_INPUT_STYLE}
        />
      </div>
    </div>
  );
};

export default memo(XRayOperatorSelector);
