import { DisplayViewport } from 'app/interfaces/Image';

export default class ViewportEvent extends Event {
  readonly viewport: DisplayViewport;
  readonly imageId: string;

  constructor(type: string, imageId: string, viewport: DisplayViewport) {
    super(type);
    this.imageId = imageId;
    this.viewport = viewport;
  }
}
