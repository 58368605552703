import './style.scss';

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import PACSErrorMessage from 'app/components/PACSErrorMessage';
import { FormattedMessage } from 'react-intl';

function ImageToPACSSyncStatus({ isSync, isSyncOngoing, syncError }) {
  if (isSyncOngoing) {
    return (
      <div className="image-to-pacs-sync-status" data-testid="status.sync-ongoing">
        <Icon name="sync" size="small" loading />
      </div>
    );
  }

  let status;
  let popupContent;
  if (syncError) {
    status = 'error';
    popupContent = (
      <PACSErrorMessage size="tiny" error={syncError} titleID="pacs.message.store_error.title" />
    );
  } else if (isSync) {
    status = 'sync';
    popupContent = <FormattedMessage id="pacs.store_status.sync" />;
  } else {
    status = 'not-sync';
    popupContent = <FormattedMessage id="pacs.store_status.not_sync" />;
  }

  const className = `image-to-pacs-sync-status image-to-pacs-sync-status--${status}`;

  const statusIndicator = (
    <div className={className} data-testid={`status.${status}`}>
      ●
    </div>
  );

  return (
    <Popup
      hoverable
      flowing
      trigger={statusIndicator}
      content={popupContent}
      position="right center"
    />
  );
}

export default ImageToPACSSyncStatus;
