export const FULL_SIZE_UPDATE = 'FULL_SIZE_UPDATE';

export const updateFullSize = (detectorId, scale) => ({
  type: FULL_SIZE_UPDATE,
  data: { detectorId, scale },
});

/**
 *
 * @param {boolean} syncToPACSOnValidate
 * @returns
 */
export const updateSyncToPACSOnValidate = (syncToPACSOnValidate) => ({
  type: 'updateSyncToPACSOnValidate',
  data: { syncToPACSOnValidate },
});
