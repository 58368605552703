import TimeoutPromise from 'app/utils/TimeoutPromise';

export class PromiseTimeoutError extends Error {}

/**
 *
 * @template InputPromise
 * @param {InputPromise} promise
 * @param {number} maximumDuration
 * @returns {InputPromise}
 */
const MaxDurationPromise = async (promise, maximumDuration) => {
  const timeoutPromise = TimeoutPromise(maximumDuration);

  const timeoutPromiseRejection = timeoutPromise.then(() => {
    throw new PromiseTimeoutError('Timeout reached');
  });
  return Promise.race([promise, timeoutPromiseRejection]).finally(timeoutPromise.cancel);
};

export default MaxDurationPromise;
