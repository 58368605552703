import { AcquisitionConstants } from 'app/interfaces/Image';
import React from 'react';

type XRayGeneratorHistoryEntry = AcquisitionConstants & {
  kV: number;
  mAs: number;
  mA: number;
  ms: number;
  date: Date;
  operators: string[];
};

export interface XRayGeneratorHistory {
  setAcquisitionConstants(constants: AcquisitionConstants): void;
  getHistoryAsCsv(operator: string): Promise<string>;
  getAllHistory(): Promise<XRayGeneratorHistoryEntry[]>;
}
const XRayGeneratorHistoryContext = React.createContext<XRayGeneratorHistory>(undefined);

export default XRayGeneratorHistoryContext;
