import convertDataImageToDisplayableImageData from 'app/utils/image/convertDataImageToDisplayableImageData';

// Loads an image id and given a url to an image
function loadImage(imageId, options) {
  return {
    promise: Promise.resolve(convertDataImageToDisplayableImageData({ ...options }, imageId)),
    cancelFn: () => {},
  };
}

function registerImageLoader(cornerstone) {
  cornerstone.registerImageLoader('rawUInt16', loadImage);
}

export { registerImageLoader, loadImage };
