function roundToStep(value, step = 1.0) {
  return Math.round(value / step) * step;
}

function isNumberConvertible(value) {
  return !Number.isNaN(+value);
}

// eslint-disable-next-line import/prefer-default-export
export { roundToStep, isNumberConvertible };
