import React from 'react';

import './style.scss';

const CountDown = ({ duration, count }) => {
  if (count <= 0 || count === undefined) {
    return null;
  }

  return (
    <div className="countdown" data-testid="countdown-timer">
      <div className="countdown__number">{count}</div>
      <svg>
        <circle
          data-testid="countdown-circle"
          r="54"
          cx="58.5"
          cy="58.5"
          style={{ animation: `countdown ${duration}s linear infinite forwards` }}
        />
      </svg>
    </div>
  );
};

export default CountDown;
