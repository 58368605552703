import React from 'react';
import * as pt from 'prop-types';
import csc, { EVENTS as CSC_EVENTS } from 'cornerstone-core';
import cst from 'cornerstone-tools';
import * as _ from 'lodash';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

import ActivatableToolButton from './ActivatableToolButton';
import ToolType from '../../types/ToolType';
import { getImageFitScale } from '../../utils/cornerstone/imageUtils';
import ToolsDropdownIndicator from './ToolsDropdownIndicator';
import { getCropMinScale } from '../../CornerstoneTools/CropTool';

const customStyles = {
  container: (provided) => ({
    ...provided,
    color: 'white',
    border: 'solid white 1px',
    textAlign: 'center',
    borderRadius: '2px',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    minHeight: '0',
    borderColor: 'inherit',
    boxShadow: 'none',
    flexWrap: 'nowrap',
    ':hover': {
      borderColor: 'inherit',
    },
  }),
  singleValue: (provided) => ({ ...provided, color: 'white', opacity: '1' }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    width: 'fit-content',
  }),
  input: (provided) => ({ ...provided, color: 'white', opacity: '1', minWidth: '3em' }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#444645',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  }),
};

const getMinScaleWithCropTool = (element, zoomWheelToolName) => {
  const {
    configuration: { cropToolName },
  } = cst.getToolForElement(element, zoomWheelToolName) || {};
  if (!cropToolName) return null;
  return getCropMinScale(element, cropToolName);
};

const convertScaleToZoomLevel = (element, scale, zoomWheelToolName) => {
  const { image, canvas, viewport } = csc.getEnabledElement(element);
  const cropMinScale = getMinScaleWithCropTool(element, zoomWheelToolName);

  if (cropMinScale) return (scale / cropMinScale) * 100;

  const fitScale = getImageFitScale(canvas, image, viewport.rotation);
  return (scale / fitScale.scaleFactor) * 100;
};

const convertZoomLevelToScale = (element, zoomLevel, zoomWheelToolName) => {
  const { image, canvas, viewport } = csc.getEnabledElement(element);
  const cropMinScale = getMinScaleWithCropTool(element, zoomWheelToolName);

  if (cropMinScale) return (zoomLevel / 100) * cropMinScale;

  const fitScale = getImageFitScale(canvas, image, viewport.rotation);
  return (zoomLevel / 100) * fitScale.scaleFactor;
};

class ZoomWithPercentButton extends React.PureComponent {
  static isActivatable = true;

  constructor(props = {}) {
    super(props);
    this.state = { zoomLevel: '100%' };
  }

  componentDidMount = () => {
    this.connectToFocusedElement();
  };

  componentWillUnmount = () => {
    const { focusedElement } = this.props;
    if (focusedElement) {
      focusedElement.removeEventListener(CSC_EVENTS.IMAGE_RENDERED, this.onViewportChange);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { focusedElement } = this.props;
    if (prevProps.focusedElement !== focusedElement) {
      this.connectToFocusedElement(prevProps.focusedElement);
    }
  };

  connectToFocusedElement = (previousElement) => {
    const { focusedElement } = this.props;
    if (previousElement) {
      previousElement.removeEventListener(CSC_EVENTS.IMAGE_RENDERED, this.onViewportChange);
    }
    if (focusedElement) {
      focusedElement.addEventListener(CSC_EVENTS.IMAGE_RENDERED, this.onViewportChange);
    }
  };

  onViewportChange = (evt) => {
    const { viewport } = evt.detail;
    const { focusedElement, name } = this.props;
    this.setState({
      zoomLevel: `${Math.round(convertScaleToZoomLevel(focusedElement, viewport.scale, name))}%`,
    });
  };

  onInputChange = (value) => {
    const { focusedElement, name } = this.props;
    if (!focusedElement) return;

    const zoomLevel = Math.max(parseFloat(value.split('%')[0]), 1);
    try {
      const { viewport } = csc.getEnabledElement(focusedElement);
      const newViewport = _.cloneDeep(viewport);
      newViewport.scale = convertZoomLevelToScale(focusedElement, zoomLevel, name);
      csc.setViewport(focusedElement, newViewport);
      this.setState({ zoomLevel: value });
    } catch (error) {
      // continue regardless of error
    }
  };

  onSelectInputChange = (value, { action }) => {
    if (action === 'input-change') {
      this.onInputChange(value);
    }
  };

  onSelectChange = (option, { action }) => {
    if (action === 'select-option') {
      this.onInputChange(option.value);
    }
  };

  getZoomOptions = () => {
    const { intl } = this.props;
    return [{ value: '100%', label: intl.formatMessage({ id: 'tools.zoom.reset' }) }];
  };

  render = () => {
    const { zoomLevel } = this.state;

    return (
      <div className="zoom-button-percent">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ActivatableToolButton
          content={<i className="svg-icon zoom" />}
          {...this.props}
          tooltipId="tools.zoom.tooltip"
        />
        <div className="zoom-button-react-select">
          <Select
            classNamePrefix="zoom-button-react-select"
            options={this.getZoomOptions()}
            components={{ DropdownIndicator: ToolsDropdownIndicator, IndicatorSeparator: null }}
            styles={customStyles}
            inputValue={zoomLevel}
            onInputChange={this.onSelectInputChange}
            onChange={this.onSelectChange}
            filterOption={() => true}
            isClearable={false}
            isSearchable
          />
        </div>
      </div>
    );
  };
}

ZoomWithPercentButton.propTypes = {
  focusedElement: pt.instanceOf(HTMLElement),
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
};

ZoomWithPercentButton.defaultProps = {
  focusedElement: null,
};

export default injectIntl(ZoomWithPercentButton);
