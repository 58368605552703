import GenericError from 'app/errors/GenericError';
import { API } from 'app/utils/apiCalls';

export type TeleradiologyAnalysisStatus = API.Teleradiology.AnalysisStatus;
export const TeleradiologyAnalysisStatus = API.Teleradiology.AnalysisStatus;

export type TeleradiologyExamType = API.Teleradiology.VetflowExamType;

export enum TeleradiologySpecie {
  DOG = 'dog',
  CAT = 'cat',
  HORSE = 'horse',
  RODENT = 'rodent',
  NAC = 'nac',
  OTHER = 'other',
}
export enum TeleradiologySex {
  MALE = 'male',
  FEMALE = 'female',
  MALE_CASTRATED = 'male_castrated',
  FEMALE_CASTRATED = 'female_castrated',
  OTHER = 'other',
}

export type TeleradiologyAffiliateId = API.Teleradiology.AffiliateId;
export type TeleradiologyStudyId = API.Teleradiology.StudyId;
export type TeleradiologyImageId = API.Teleradiology.ImageId;
export type TeleradiologyProviderType = API.Teleradiology.Provider;

export type TeleradiologyAffiliateIdentifier = API.Teleradiology.AffiliateIdentifier;

export type TeleradiologyAffiliate = {
  id?: number;
  companyName: API.Teleradiology.Provider; // VEDIM
  firstName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  clinic: string;
  address: string;
  addressComplement?: string;
  city: string;
  postCode: string;
  country: string;
  countryCode: string;
  vatNumber?: string;
  website?: string;
  diploma?: string;
  language?: string;
  timezone?: string;
};

export type TeleradiologyOwner = {
  lastName: string;
  firstName?: string;
  address: {
    address: string;
    addressComplement?: string;
    city: string;
    postCode: string;
    phoneNumber: string;
    country?: string;
    email?: string;
  };
};

export type TeleradiologyPatient = {
  name: string;
  specie: string;
  specieCustom?: string;
  patientId?: string;
  race: string;
  age: string;
  birthDate?: Date;
  sex: string;
  weight?: string;
  owner: TeleradiologyOwner;
};

export type TeleradiologyStudy = {
  companyName: string; // VEDIM
  examDate: Date;
  examType?: TeleradiologyExamType;
  nbImagesRadio?: number;
  examiningVeterinarian?: string;
  examiningEmail?: string;
  referentVeterinarian?: string;
  referentClinic?: string;
  referentEmail?: string;
  reason: string;
  clinicalSigns: string;
  veterinarianInterpretation: string;
  xrayDate?: Date;
  isUrgent: boolean;
  patient: TeleradiologyPatient;
};

export enum TeleradiologyImageUploadStatus {
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
}

export type TeleradiologyStudyImage = {
  image_id: string;
  teleradiology_image_id: TeleradiologyImageId;
  upload_status: TeleradiologyImageUploadStatus;
};

export type TeleradiologyStudyIdentifier = API.Teleradiology.StudyIdentifier;

export type ImageUploadPromise = Promise<void | GenericError>;

export type Image = {
  imageContent: Blob;
};

export type ImagesToUpload = { [imageId: string]: Image };

export type ImageUploadPromisesByImageId = { [imageId: string]: ImageUploadPromise };

export type AnalyzeResultWithImageUploadPromises = TeleradiologyStudyIdentifier & {
  imageUploadPromises: ImageUploadPromisesByImageId;
};

export interface TeleradiologyAPI {
  /**
   * Check if Teleradiology API is enabled for current user
   */
  isEnabled(): Promise<boolean>;

  /**
   * Check if provided user has an affiliate status on teleradiology platform
   * @param {[string]} email email of user, undefined email request status of current user
   */
  getAffiliateId(email?: string): Promise<string | undefined | GenericError>;

  /**
   * Fetch Teleradiology status of given study
   * @param studyId picoxia studyId for which status is requested
   */
  getStatus(studyId: string): Promise<TeleradiologyStudyIdentifier | GenericError>;

  /**
   * Retrieve the teleradiology analysis results as a pdf Blob file ready to be downloaded.
   * @param studyId Study for which analysis results should be requested.
   * Promise will reject if results are not ready yet.
   */
  getAnalysisReport(studyId: string): Promise<Blob | GenericError>;

  /**
   * Create affiliate for user with given parameters.
   * Only one affiliate can be created for a given email address
   * @param affiliate Parameters used for affiliate creation
   */
  createAffiliate(
    affiliate: TeleradiologyAffiliate
  ): Promise<TeleradiologyAffiliateIdentifier | GenericError>;

  /**
   * Request analysis for given study with associated images
   *
   * @param studyId picoxia study id
   * @param studyDetails study details with additional information for teleradiology
   * @param images images to send for teleradiology analysis
   * @param imageUploadProgressCb A callback to follow upload progress identified by imageId
   *
   * @returns Promise with the list of Promises for each image being uploaded to teleradiology.
   * First promise reject in case teleradiology reject analysis request.
   */
  requestAnalyze(
    studyId: string,
    studyDetails: TeleradiologyStudy,
    images: ImagesToUpload,
    imageUploadProgressCb?: (imageId: string, progress: number, total: number) => void
  ): Promise<AnalyzeResultWithImageUploadPromises | GenericError>;

  /**
   * Images can be either image already present in the study that failed to upload or new images requested by the radiologist.
   */
  addImagesToAnalyze(
    studyId: string,
    images: ImagesToUpload,
    imageUploadProgressCb?: (imageId: string, progress: number, total: number) => void
  ): Promise<ImageUploadPromisesByImageId | GenericError>;
}
