import _ from 'lodash';

import isWorkListImage from 'app/utils/isWorkListImage';
import { ImageForTeleradiologySelection } from 'app/containers/Teleradiology/VetFlow/Modal/ImageForTeleradiologySelection';
import { ViewerImages } from 'app/components/Viewer/types';
import { convertCropAnnotationsToCropRect } from 'app/components/Viewer/convertCropAnnotationsToCropRect';
import makeDicomFromAcquisitionDetails from 'app/utils/dicom/makeDicomFromAcquisitionDetails';
import { IntlShape } from 'react-intl';
import { Patient } from 'app/interfaces/Patient';
import { isFullResolutionDicom } from 'app/components/Viewer/isFullResolutionDicom';

export default function formatStudyImagesForTeleradiology(
  studyImages: ViewerImages,
  intl: IntlShape,
  studyId: string,
  patient: Patient,
  imagesOrder: string[]
): ImageForTeleradiologySelection[] {
  return _(studyImages)
    .mapValues((image, imageId) => {
      try {
        if (isWorkListImage(image)) return undefined;
        let crop;
        const cropRect = convertCropAnnotationsToCropRect(image.annotations?.Crop);
        if (cropRect) {
          crop = {
            x: cropRect[0],
            y: cropRect[1],
            width: cropRect[2],
            height: cropRect[3],
          };
        }
        let dicomData;
        if (isFullResolutionDicom(image)) {
          dicomData = makeDicomFromAcquisitionDetails({
            intl,
            studyId,
            imageId,
            imageIndex: imagesOrder.indexOf(imageId),
            acquisitionTime: image.acquisitionTime,
            acquisitionConstants: image.acquisitionConstants,
            anatomicRegion: image.anatomicRegion,
            detectorInfo: image.detectorInfo,
            patient,
            dicomData: image.dicomData,
          });
        }
        return {
          ...image,
          backendId: imageId,
          image_metadata: image.imageMetadata,
          id: imageId,
          image: image.displayableImage,
          transformation: { ...image.viewport, crop },
          dicomData,
        };
      } catch {
        return undefined;
      }
    })
    .filter()
    .value();
}
