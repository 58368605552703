import './style.css';

import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';

export enum NegatoMode {
  SINGLE = 'negato-single',
  DUAL = 'negato-dual',
  TRI_MIXED_COLUMN = 'negato-tri-mixed-column',
  TRI_MIXED_ROW = 'negato-tri-mixed-row',
  TRI_COLUMNS = 'negato-tri-columns',
  QUAD = 'negato-quad',
  QUAD_COLUMNS = 'negato-quad-columns',
}

export const NEGATO_MODE_INFO = {
  [NegatoMode.SINGLE]: { viewCount: 1 },
  [NegatoMode.DUAL]: { viewCount: 2 },
  [NegatoMode.TRI_MIXED_COLUMN]: { viewCount: 3 },
  [NegatoMode.TRI_MIXED_ROW]: { viewCount: 3 },
  [NegatoMode.TRI_COLUMNS]: { viewCount: 3 },
  [NegatoMode.QUAD]: { viewCount: 4 },
  [NegatoMode.QUAD_COLUMNS]: { viewCount: 4 },
};

type NegatoButtonProps = {
  currentMode: NegatoMode;
  onNegatoSelect: (mode: NegatoMode) => void;
};

const MODES_LIST = [
  { mode: NegatoMode.SINGLE, name: 'negato-single' },
  { mode: NegatoMode.DUAL, name: 'negato-dual' },
  { mode: NegatoMode.TRI_MIXED_COLUMN, name: 'negato-tri-mixed-column' },
  { mode: NegatoMode.TRI_MIXED_ROW, name: 'negato-tri-mixed-row' },
  { mode: NegatoMode.TRI_COLUMNS, name: 'negato-tri-columns' },
  { mode: NegatoMode.QUAD, name: 'negato-quad' },
  { mode: NegatoMode.QUAD_COLUMNS, name: 'negato-quad-columns' },
];

const NegatoButton = memo(({ currentMode, onNegatoSelect }: NegatoButtonProps) => {
  const intl = useIntl();
  return (
    <Popup
      on="click"
      basic
      hoverable
      position="bottom center"
      trigger={
        <button
          className="picoxia tool-button"
          title={intl.formatMessage({ id: 'tools.negato.tooltip' })}
        >
          <i className="svg-icon negato-quad"></i>
        </button>
      }
      className="negato-tools-bar"
      content={
        <div className="flex tools-bar" style={{ gap: '5px' }}>
          {MODES_LIST.map(({ mode, name }) => (
            <button
              key={name}
              className={`picoxia tool-button ${currentMode === mode ? 'active' : ''}`}
              onClick={() => onNegatoSelect?.(mode)}
              title={intl.formatMessage({ id: `tools.negato.${name}.tooltip` })}
            >
              <i className={`svg-icon ${name}`}></i>
            </button>
          ))}
        </div>
      }
    />
  );
});

export default NegatoButton;
