export const isParamsEqualDefault = (searchedKeys: any[], cachedKeys: any[]) =>
  searchedKeys.length === cachedKeys.length &&
  searchedKeys.every((searchedKey, i) => searchedKey === cachedKeys[i]);

type Value<T> = T extends Promise<infer Value> ? (Value extends WeakKey ? Value : never) : never;

// We could further improve it by adding an expiration mechanism that hold onto computed values for a period of time or a specific cache size.
export default class ComputationCache<Keys extends [...any], T> {
  private cache: Map<Keys, WeakRef<Value<T> | Promise<Value<T>>>> = new Map();
  private finalizer;

  constructor(
    private compute: (...params: Keys) => T,
    private isParamsEqual: (searchedKeys: Keys, cachedKeys: Keys) => boolean = isParamsEqualDefault
  ) {
    this.finalizer = new FinalizationRegistry((cacheKey: Keys) => {
      this.cache.delete(cacheKey);
    });
  }

  async getOrCompute(...params: Keys): Promise<Value<T>> {
    for (const [cachedKeys, cachedValue] of this.cache.entries()) {
      if (this.isParamsEqual(params, cachedKeys)) {
        const value = cachedValue?.deref();
        if (value) return value;

        this.cache.delete(cachedKeys);
        break;
      }
    }

    const promise = this.compute(...params) as Promise<Value<T>>;
    this.setCacheEntry(params, promise);

    const value = await promise;

    this.setCacheEntry(params, value);

    return value;
  }

  setCacheEntry(params: Keys, value: Value<T> | Promise<Value<T>>) {
    this.cache.set(params, new WeakRef(value));
    this.finalizer.unregister(params);
    this.finalizer.register(value, params, params);
  }
}
