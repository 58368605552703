import ApiCalls from 'app/utils/apiCalls';
import { FIELDS_MISSING } from 'app/constants/serverError';
import fs from 'app/native/node/fs';
import { PmsFileHandler } from 'app/pms/types';

const handleFileInput: PmsFileHandler = (filename, parser) =>
  fs()
    .promises.readFile(filename, { encoding: 'utf8' })
    .then(async (fileContent) => {
      let patientInfo;
      let exam;
      try {
        ({ patientInfo, exam } = parser(fileContent));
      } catch (e) {
        e.filename = filename;
        e.fileContent = fileContent;

        throw e;
      }
      try {
        await ApiCalls.importPMSExam(patientInfo, exam);
      } catch (error) {
        if (error?.response?.data?.cause === FIELDS_MISSING) {
          fs().promises.rm(filename);
        }
        return;
      }
      fs().promises.rm(filename);
    });

export default handleFileInput;
