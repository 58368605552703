const makeSyncReduxWithLocalStorageObserver =
  (localStorageKey) =>
  ({ state }) => {
    if (state === undefined) {
      localStorage.removeItem(localStorageKey);
    } else {
      try {
        localStorage.setItem(localStorageKey, JSON.stringify(state));
      } catch {
        //
      }
    }
  };

const restoreFromLocalStorage = (localStorageKey, defaultState) => {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey)) ?? defaultState;
  } catch {
    return defaultState;
  }
};

export { makeSyncReduxWithLocalStorageObserver, restoreFromLocalStorage };
