import { getDistanceForImage } from 'app/CornerstoneTools/pixelToCanvasUtils';
import { DisplayableImageData, VHSAnnotation } from 'app/interfaces/Image';
import roundToDecimal from 'app/utils/roundToDecimal';

/**
 * Subtract vertebra length until the whole axis as been reported onto the broken line.
 * @param {*} axisLength      Canvas length of the axis to report on the vertebras
 * @param {*} vertebrasLength List of vertebras length starting from t4
 */
const computeVertebralLength = (axisLength: number, vertebrasLength: number[]) => {
  let vertebralLength = 0;
  let vertebraIndex = 0;
  let remainingAxisLength = axisLength;

  while (remainingAxisLength > 0) {
    if (remainingAxisLength > vertebrasLength[vertebraIndex]) {
      vertebralLength += 1;
      remainingAxisLength -= vertebrasLength[vertebraIndex];
    } else {
      vertebralLength += remainingAxisLength / vertebrasLength[vertebraIndex];
      break;
    }
    if (vertebraIndex < vertebrasLength.length - 1) {
      vertebraIndex += 1;
    }
  }
  return vertebralLength;
};

export default function (image: DisplayableImageData, data: VHSAnnotation) {
  const { t4, t8, heartBottom, heartTop, heartLeft, heartRight } = data.handles;
  const longAxisLength = getDistanceForImage(image, heartBottom, heartTop);
  const shortAxisLength = getDistanceForImage(image, heartLeft, heartRight);
  let longAxisVertebralLength;
  let shortAxisVertebralLength;

  if (t4.hasMoved || t8.hasMoved || data.additionalVertebraPoints.length < 4) {
    // A vertebral handle has been moved or we do not have enough additionalVertebraPoints.
    // we compute VHS using averaged value.
    const averageVertebralLength = getDistanceForImage(image, t4, t8) / 4;
    longAxisVertebralLength = longAxisLength / averageVertebralLength;
    shortAxisVertebralLength = shortAxisLength / averageVertebralLength;
  } else {
    // If nothing was moved, we report each axis on the broken line composed of each vertebra
    const [t5, t6, t7, ...rest] = data.additionalVertebraPoints;
    const successivePoints = [t4, t5, t6, t7, t8, ...rest];
    const vertebrasLength = [];

    // Create the list of each vertebra length
    for (let i = 0; i < successivePoints.length; i += 1) {
      if (i < successivePoints.length - 1) {
        vertebrasLength.push(
          getDistanceForImage(image, successivePoints[i], successivePoints[i + 1])
        );
      }
    }

    longAxisVertebralLength = computeVertebralLength(longAxisLength, vertebrasLength);
    shortAxisVertebralLength = computeVertebralLength(shortAxisLength, vertebrasLength);
  }

  longAxisVertebralLength = roundToDecimal(longAxisVertebralLength, 1);
  shortAxisVertebralLength = roundToDecimal(shortAxisVertebralLength, 1);

  return { longAxisVertebralLength, shortAxisVertebralLength };
}
