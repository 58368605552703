import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { FormattedMessage } from 'react-intl';

const ConfigureModal: React.FC<{ title: string; validateOpen?: () => Promise<any> }> = ({
  children,
  title,
  validateOpen,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState(0);

  const openModal = useCallback(async () => {
    try {
      await validateOpen?.();
      setModalOpen(true);
    } catch {}
  }, [validateOpen]);

  const closeModal = useCallback(() => {
    setModalContentKey(modalContentKey + 1);
    setModalOpen(false);
  }, []);

  return (
    <Modal
      trigger={
        <Button>
          <FormattedMessage id="general.configure" />
        </Button>
      }
      open={modalOpen}
      closeOnEscape={false}
      onOpen={openModal}
      onClose={closeModal}
      size="large"
      closeIcon
    >
      <Modal.Header>
        <FormattedMessage id={title} />
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};
export default ConfigureModal;
