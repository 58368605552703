import './style.scss';

import React from 'react';
import { Icon, IconProps, Popup } from 'semantic-ui-react';

export enum SyncStatusEnum {
  SYNC = 'sync',
  ONGOING = 'ongoing',
  ERROR_SYNC = 'error_sync',
  NOT_SYNC = 'not_sync',
}

type LoaderCircularProps = {
  animationDuration?: number;
};

const LoaderCircular = ({ animationDuration: animationTime = 2.5 }: LoaderCircularProps) => (
  <div className="sync-status__circle-wrapper">
    <div className="sync-status__circle-wrapper__left-wrapper">
      <div className="loader-circle" style={{ animationDuration: `${animationTime}s` }} />
    </div>
    <div className="sync-status__circle-wrapper__right-wrapper">
      <div className="loader-circle" style={{ animationDuration: `${animationTime}s` }} />
    </div>
  </div>
);

type StatusIcons = {
  sync: React.ReactNode;
  ongoing: React.ReactNode;
  error_sync: React.ReactNode;
  not_sync: React.ReactNode;
};

type SyncStatusIconProps = {
  status: SyncStatusEnum;
  // animation time in seconds
  animationDuration?: number;
} & ({ statusIcons?: StatusIcons } | IconProps);

function SyncStatusIcon({ status, statusIcons, animationDuration, ...rest }: SyncStatusIconProps) {
  let displayedIcon;
  if (statusIcons) displayedIcon = statusIcons[status];
  else {
    const iconProps: IconProps = rest;
    switch (status) {
      case SyncStatusEnum.SYNC:
        displayedIcon = <Icon {...iconProps} color="green" />;
        break;
      case SyncStatusEnum.ONGOING:
        displayedIcon = <Icon {...iconProps} inverted />;
        break;
      case SyncStatusEnum.ERROR_SYNC:
        displayedIcon = <Icon {...iconProps} color="red" />;
        break;
      case SyncStatusEnum.NOT_SYNC:
        displayedIcon = <Icon {...iconProps} color="orange" />;
        break;
    }
  }

  const isOngoing = status === SyncStatusEnum.ONGOING;
  return (
    <div className="sync-status">
      {isOngoing && <LoaderCircular animationDuration={animationDuration} />}
      <div className="sync-status__circle-wrapper__icon">{displayedIcon}</div>
    </div>
  );
}

type StatusTexts = {
  sync: string | React.ReactNode;
  ongoing: string | React.ReactNode;
  error_sync: string | React.ReactNode;
  not_sync: string | React.ReactNode;
};

// User can either specify the semantic-ui icon they want to display or fully customize their icon for each status
type SyncStatusProps = SyncStatusIconProps & {
  statusTexts: StatusTexts;
};

const SyncStatus = ({ statusTexts, status, ...rest }: SyncStatusProps) => {
  const indicator = (
    <div>
      <SyncStatusIcon status={status} {...rest} />
    </div>
  );

  return (
    <Popup
      hoverable
      flowing
      trigger={indicator}
      content={statusTexts[status]}
      position="right center"
    />
  );
};

export default SyncStatus;
