import CornerstoneImageEncoder from 'app/adapters/CornerstoneImageEncoder';
import { ImageAnnotations, Viewport } from 'app/interfaces/Image';
import { Patient } from 'app/interfaces/Patient';
import path from 'app/native/node/path';
import generatePDFFromHtml from 'app/pms/exporter/generatePDFFromHtml';
import ApiCalls from 'app/utils/apiCalls';
import { getReportHtml } from 'app/utils/pdfUtil';
import _ from 'lodash';
import { IntlShape } from 'react-intl';

export const PMS_EXPORT_DEFAULT_JPEG_PARAMS = {
  maxWidth: 720,
  maxHeight: 720,
  quality: 0.95,
};

type FileSystemPromisesType = typeof import('fs').promises;

export type ImageType = {
  displayableImage: cornerstone.Image;
  annotations: ImageAnnotations;
  viewport: Viewport;
};
export type ImagesType = ImageType[];

export type StudyType = {
  _id: string;
  creationDate: Date;
  comment: string;
  isCommentDirty?: boolean;
  pms_id?: string;
};

export type PMSConfigurationType = {
  exportDirectory?: string;
};

export type PMSExportFn = (
  study: StudyType,
  patientInfos: Patient,
  images: ImagesType
) => Promise<PromiseSettledResult<void>[]>;

export default class PmsExporter {
  imageEncoder: CornerstoneImageEncoder;
  fileSystemPromises: FileSystemPromisesType;
  intl: IntlShape;

  constructor(imageEncoder: CornerstoneImageEncoder, fileSystemPromises: FileSystemPromisesType) {
    this.imageEncoder = imageEncoder;
    this.fileSystemPromises = fileSystemPromises;
  }

  public setIntl(intl: IntlShape) {
    this.intl = intl;
  }

  public exportDataToPMS = (
    { exportDirectory }: PMSConfigurationType = {},
    currentStudy: StudyType,
    _patientInfos: Patient,
    images: ImagesType = []
  ): Promise<PromiseSettledResult<void>[]> => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) {
      return undefined;
    }

    const pmsId: string = currentStudy?.pms_id;
    if (!pmsId) {
      console.warn('Patient pms_id is required to export images to PMS');
      return undefined;
    }

    return Promise.allSettled(this.exportImages(exportDirectory, pmsId, images));
  };

  protected exportImages = (exportDirectory: string, pmsId: string, images: ImagesType) => {
    const imagesWithFilenames = images.map((image, index) => {
      const paddedIndex = index.toString().padStart(2, '0');
      const filename = `${pmsId}_radio${paddedIndex}.jpg`;
      return { ...image, filename };
    });

    return imagesWithFilenames.map(({ displayableImage, filename, annotations, viewport }) =>
      this.imageEncoder
        .toJPEG(displayableImage, viewport, annotations, PMS_EXPORT_DEFAULT_JPEG_PARAMS)
        .then((jpegBuffer) =>
          this.fileSystemPromises.writeFile(path().join(exportDirectory, filename), jpegBuffer)
        )
        .catch((error: any) => {
          console.warn(error);
          throw error;
        })
    );
  };

  exportReportPdfWithElectron = async (
    exportDirectory: string,
    pmsId: string,
    study: StudyType,
    patient: Patient,
    images: ImagesType
  ) => {
    const { reportInfo } = (await ApiCalls.getReportInfo(true))?.data;
    const xRayImagesb64 = _.map(
      _.filter(
        await Promise.allSettled(
          images.map(async ({ displayableImage, annotations, viewport }) =>
            Buffer.from(
              await this.imageEncoder.toJPEG(
                displayableImage,
                viewport,
                annotations,
                PMS_EXPORT_DEFAULT_JPEG_PARAMS
              )
            ).toString('base64')
          )
        ),
        { status: 'fulfilled' }
      ),
      'value'
    );
    const reportHtml = getReportHtml(study, patient, xRayImagesb64, reportInfo, this.intl);

    const pdfBuffer = await generatePDFFromHtml(reportHtml);

    return this.fileSystemPromises.writeFile(
      path().join(exportDirectory, `${pmsId}_report.pdf`),
      pdfBuffer
    );
  };
}
