import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as pt from 'prop-types';
import Pattern from '../Pattern';

class PatternUnstructuredList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image,
      thresholdForDisplay: this.props.thresholdForDisplay,
      displayShowAllButton: this.props.displayShowAllButton,
      showAll: false,
      overflow: false,
    };
    this.setPatternsRef = (e) => {
      this.patternsRef = e;
    };
  }

  componentDidMount = () => this.setState({ overflow: this.isOverflow() });

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      image: nextProps.image,
      thresholdForDisplay: nextProps.thresholdForDisplay,
      displayShowAllButton: nextProps.displayShowAllButton,
    });
  }

  componentDidUpdate = () => {
    const { overflow } = this.state;
    const checkOverflow = this.isOverflow();
    if (checkOverflow !== overflow) this.setState({ overflow: checkOverflow });
  };

  sortPredictions = (predictions, feedbacks) => {
    let items = Object.keys(predictions).map((patternName) => {
      const confidence = feedbacks[patternName] ? 1 : predictions[patternName];
      return [patternName, confidence];
    });

    items.sort((first, second) => second[1] - first[1]);

    items = items.map((item) => item[0]);

    return items;
  };

  displayPatterns = () => {
    const { patternsToDisplay } = this.props;
    const positivePredictions = this.sortPredictions(
      this.state.image.predictions,
      this.state.image.feedback
    ).filter((patternName) => {
      const confidence = this.state.image.predictions[patternName];
      const feedback = this.state.image.feedback[patternName];
      return (
        patternsToDisplay.includes(patternName) &&
        confidence > this.state.thresholdForDisplay &&
        (this.state.thresholdForDisplay === 0 || feedback !== false)
      );
    });
    if (positivePredictions.length > 0) {
      return positivePredictions.map((patternName) => {
        const confidence = this.state.image.predictions[patternName];
        const feedback = this.state.image.feedback[patternName];
        return (
          <Pattern
            feedback={feedback}
            confidence={confidence}
            patternName={patternName}
            showFeedbackButtons={this.props.showFeedbackButtons}
            key={patternName}
            hideConfidence={this.props.hideConfidence}
            showIcon={false}
            largeSize="mediumLarge"
            smallSize="mediumLarge"
          />
        );
      });
    }
  };

  showLess = (event) => {
    event.stopPropagation();
    this.setState({ showAll: false });
  };

  showLessButton = () => {
    if (this.state.showAll) {
      return (
        <a onClick={this.showLess}>
          <Icon className="caret up" />{' '}
        </a>
      );
    }
    return null;
  };

  showAll = (event) => {
    event.stopPropagation();
    this.setState({ showAll: true });
  };

  showAllButton = () => {
    const { showAll, overflow } = this.state;

    if (this.state.displayShowAllButton) {
      const needDisplay = overflow && !showAll;
      return (
        <div className="overflow">
          <a
            onClick={this.showAll}
            style={{ fontWeight: 'bold', marginLeft: '5px' }}
            className={this.state.thresholdForDisplay === 0 ? 'hidden' : ''}
          >
            {needDisplay ? <Icon className="ellipsis horizontal icon " /> : ''}
          </a>
        </div>
      );
    }
    return null;
  };

  isOverflow = () => {
    const ref = this.patternsRef;
    if (!ref) return false;
    return ref.offsetHeight < ref.scrollHeight || ref.offsetWidth < ref.scrollWidth;
  };

  render() {
    const { additionalPrefixPatterns } = this.props;
    const { showAll } = this.state;
    const heightStyle = { height: showAll ? 'auto' : undefined };
    return (
      <div className="patterns-column" style={heightStyle}>
        <div ref={this.setPatternsRef} className="patterns">
          {additionalPrefixPatterns}
          {this.displayPatterns()}
        </div>
        {this.showAllButton()}
        {this.showLessButton()}
      </div>
    );
  }
}

PatternUnstructuredList.propTypes = {
  patternsToDisplay: pt.arrayOf(pt.string).isRequired,
};

PatternUnstructuredList.defaultProps = {
  thresholdForDisplay: 0.25,
  showFeedbackButtons: false,
  displayShowAllButton: false,
  hideConfidence: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternUnstructuredList);
