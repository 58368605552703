import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import TermsOfSalesContent from '../../../TermsOfSalesPage/content';

/**
 * Modal to display Terms of Sales
 * @returns {JSX.Element}
 */
function ModalTermsOfSales() {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <button className="ui link">
          <FormattedMessage id="terms-of-sales.title" />
        </button>
      }
      className="terms-of-sales-modal"
    >
      <Modal.Header>
        <FormattedMessage id="terms-of-sales.title" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <TermsOfSalesContent />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          <FormattedMessage id="billing.accept-terms-of-sales" /> <Icon name="right chevron" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ModalTermsOfSales;
