import { restoreFromLocalStorage } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { FULL_SIZE_UPDATE } from 'app/redux/viewerConfiguration/actions';
import { fromJS } from 'immutable';

export const VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.viewerConfiguration';

const configurationInitialState = fromJS({
  syncToPACSOnValidate: true,
  ...restoreFromLocalStorage(VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY),
});

const viewerConfigurationReducer = (state = configurationInitialState, action) => {
  switch (action.type) {
    case FULL_SIZE_UPDATE:
      if (!action.data.detectorId) return state;
      return state.setIn(['fullSize', action.data.detectorId], action.data.scale);
    case 'updateSyncToPACSOnValidate':
      return state.set('syncToPACSOnValidate', action.data.syncToPACSOnValidate);
    default:
      return state;
  }
};

export default viewerConfigurationReducer;
