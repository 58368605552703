import './styles.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { selectPermissionTeleradiology } from 'app/redux/permissions/reducer';
import TeleradiologyVetFlowModalAffiliateCreate from '../VetFlow/Modal/AffiliateCreate';
import TeleradiologyVetFlowModalAnalyzeRequest from '../VetFlow/Modal/AnalyzeRequest';
import { Patient } from 'app/interfaces/Patient';
import { ImageForTeleradiologySelection } from 'app/containers/Teleradiology/VetFlow/Modal/ImageForTeleradiologySelection';
import { selectTeleradiologyAffiliateId } from 'app/redux/teleradiology/reducer';

export type TeleradiologyPanelProps = {
  studyId?: string;
  studyDate?: Date | number;
  animal?: Patient;
  images: ImageForTeleradiologySelection[];
  aiReport: string; // Should only contain AI results, not the whole report.
};

/**
 * Panel For Teleradiology
 */
function TeleradiologyPanel({
  studyId,
  studyDate,
  animal,
  images,
  aiReport,
}: TeleradiologyPanelProps) {
  const hasTeleradiologyPermission = useSelector(selectPermissionTeleradiology);
  const teleradiologyAffiliateId = useSelector(selectTeleradiologyAffiliateId);

  if (hasTeleradiologyPermission === false) {
    return null;
  }

  return (
    <div className="teleradiology-panel">
      {!teleradiologyAffiliateId && <TeleradiologyVetFlowModalAffiliateCreate />}

      {teleradiologyAffiliateId && (
        <TeleradiologyVetFlowModalAnalyzeRequest
          studyId={studyId}
          studyDate={studyDate}
          animal={animal}
          images={images}
          aiReport={aiReport}
        />
      )}
    </div>
  );
}

export default TeleradiologyPanel;
