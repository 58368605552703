import React from 'react';
import { IntlShape } from 'react-intl';

const SEXES = ['male', 'female', 'male_castrated', 'female_castrated'];
const SEXES_WITH_OTHER = [...SEXES, 'other'];
const NEUTERED_SEXES = ['male_castrated', 'female_castrated'];

const makeSexesOptions = (intl: IntlShape) =>
  SEXES.map((sex) => ({
    label: (
      <i
        className={`svg-icon ${sex}`}
        aria-label={intl.formatMessage({ id: `patient_info.sex.${sex}` })}
        title={intl.formatMessage({ id: `patient_info.sex.${sex}` })}
      />
    ),
    value: sex,
  }));

export { NEUTERED_SEXES, SEXES, SEXES_WITH_OTHER, makeSexesOptions };
