import isDataArray from 'app/utils/isDataArray';
import _ from 'lodash';

export default function isEqualWithArrayBuffer(obj1: any, obj2: any) {
  return _.isEqualWith(obj1, obj2, (objValue: any, othValue: any) => {
    if (isDataArray(objValue) || isDataArray(othValue)) {
      return objValue === othValue;
    }
    return undefined;
  });
}
