import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  XRAY_CONFIGURATION_ADD_OPERATOR,
  XRAY_CONFIGURATION_TOGGLE_TELEMETRY,
  XRAY_CONFIGURATION_DELETE_OPERATOR,
  XRAY_CONFIGURATION_SET_DEVICE_PATH,
  XRAY_CONFIGURATION_SET_LAST_OPERATORS,
} from 'app/redux/xrayGeneratorConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import { fromJS, Set, Iterable, List } from 'immutable';

export const XRAY_GENERATOR_LOCAL_STORAGE_KEY = 'redux.xray_generator';

const configurationInitialState = () =>
  fromJS(
    {
      isTelemetry: false,
      ...restoreFromLocalStorage(XRAY_GENERATOR_LOCAL_STORAGE_KEY, {}),
    },
    (key, value) => {
      if (key === 'operators') return value.toSet();
      return Iterable.isIndexed(value) ? value.toList() : value.toMap();
    }
  );

const xrayConfigurationReducer = (
  xRayConfiguration = configurationInitialState(),
  { type, payload }
) => {
  switch (type) {
    case XRAY_CONFIGURATION_SET_DEVICE_PATH:
      return xRayConfiguration.set('devicePath', payload.devicePath);
    case XRAY_CONFIGURATION_TOGGLE_TELEMETRY:
      return xRayConfiguration.set('isTelemetry', payload.isTelemetry);
    case XRAY_CONFIGURATION_ADD_OPERATOR:
      return xRayConfiguration.update('operators', (operators) =>
        (operators ?? new Set()).add(payload.operator)
      );
    case XRAY_CONFIGURATION_SET_LAST_OPERATORS:
      return xRayConfiguration.set('selected_operators', new List(payload.operators));
    default:
      return xRayConfiguration;
    case XRAY_CONFIGURATION_DELETE_OPERATOR:
      return xRayConfiguration.update('operators', (operators) =>
        (operators ?? new Set()).filter((operator) => operator !== payload.operator)
      );
  }
};

export const selectXRayConfiguration = (state) => state.get('xRayConfiguration');
export const selectXRayDevicePath = (state) => selectXRayConfiguration(state).get('devicePath');
export const selectTelemetry = (state) => selectXRayConfiguration(state).get('isTelemetry');
export const selectXRayOperators = (state) => selectXRayConfiguration(state).get('operators');
export const selectXRaySelectedOperators = (state) =>
  selectXRayConfiguration(state).get('selected_operators');

export const attachXRayConfigurationWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectXRayConfiguration,
    makeSyncReduxWithLocalStorageObserver(XRAY_GENERATOR_LOCAL_STORAGE_KEY)
  );
};

export default xrayConfigurationReducer;
