/* eslint-disable no-bitwise */
export const FILE_ACCESSES = {
  READ: 0b1,
  WRITE: 0b10,
};
export const FILE_TYPE = {
  FILE: 0b1,
  DIRECTORY: 0b10,
};

/**
 * @typedef {{
 * mode:FILE_ACCESSES.READ|FILE_ACCESSES.WRITE,
 * type:FILE_TYPE.FILE|FILE_TYPE.DIRECTORY}} FileInfo
 */

export default class FilesystemUtility {
  /**
   * Get file or directory accesses rights.
   * Returns undefined if file does not exist.
   * @param {string} filepath
   * @returns {FileInfo|undefined}
   */
  getFileInfoSync = (filepath) => {};
}

/**
 * @param {FileInfo|undefined} fileInfo
 * @param {number} fileAccesses
 * @returns {bool}
 */
export const checkFileAccesses = (fileInfo, fileAccesses) =>
  (fileInfo?.mode & fileAccesses) === fileAccesses;

/**
 * @param {FileInfo|undefined} fileInfo
 * @returns {bool}
 */
export const isDirectory = (fileInfo) =>
  (fileInfo?.type & FILE_TYPE.DIRECTORY) === FILE_TYPE.DIRECTORY;

/**
 * @param {FileInfo|undefined} fileInfo
 * @returns {bool}
 */
export const isFile = (fileInfo) => (fileInfo?.type & FILE_TYPE.FILE) === FILE_TYPE.FILE;
