import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import AppHelmet from 'app/components/AppHelmet';
import { injectIntl, FormattedMessage } from 'react-intl';
// import {stripeClient} from '../../utils/stripeCalls';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import { Header, Message } from 'semantic-ui-react';
import PurchaseHistory from '../../components/PurchaseHistory';
import InvoiceAddressModal from '../InvoiceAddressModal';
import ApiCalls from '../../utils/apiCalls';
// import {injectStripe} from 'react-stripe-elements';
import stripe from '../../utils/stripeUtils';
import { getCouponMessage } from 'utils';
import getCountry from '../../utils/getCountryFromIP';
import queryString from 'query-string';

import './style.scss';

import { CREDIT_PACKS, SUBSCRIBE_PACKS } from './packs';
import ModalTermsOfSales from './Modal/TermsOfSales';

export class BillingPage extends React.Component {
  constructor(props) {
    super(props);
    const initialSubscriptionOffers = ['standard-90'];
    const initialIsInvoiceAddressModalOpen = {};
    initialSubscriptionOffers.forEach((offerName) => {
      initialIsInvoiceAddressModalOpen[offerName] = false;
    });
    this.state = {
      couponSuccessMessage: {},
      couponFailedMessage: {},
      subscriptionOffers: initialSubscriptionOffers,
      isInvoiceAddressModalOpen: initialIsInvoiceAddressModalOpen,
      couponValues: Object.fromEntries(
        Object.values(SUBSCRIBE_PACKS).map((values) => [`coupon.${values['backendName']}`, ''])
      ),
    };
    getCountry().then((country) =>
      this.setState({ subscriptionOffers: this.getSubscriptionOffers(country) })
    );
  }

  getSubscriptionOffers = (country) => {
    // country = 'CA';

    const queryStringParsed = queryString.parse(window.location.search);
    const referedFromCommercialForYearlySubscription =
      ['tony'].indexOf(queryStringParsed.from) > -1;
    if (referedFromCommercialForYearlySubscription) {
      return ['yearly-GBP'];
    }
    const packs = [];
    for (const key in SUBSCRIBE_PACKS) {
      const pack = SUBSCRIBE_PACKS[key];
      if (pack.countries.indexOf(country) > -1 && !pack.isSpecialOffer) {
        packs.push(pack.backendName);
      }
    }
    if (packs.length) {
      return packs;
    }
    return this.getSubscriptionOffers('DEFAULT');
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  buyCredits = (event, creditPack) => {
    event.preventDefault();
    ApiCalls.createCreditsCheckoutSession(creditPack).then((response) => {
      stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    });
  };

  subscribe = (subscriptionPlan, subscriptionName) => {
    const { couponValues } = this.state;
    return ApiCalls.createSubscriptionCheckoutSession(
      subscriptionPlan,
      couponValues[subscriptionName]
    ).then((response) => {
      stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    });
  };

  hasValidCoupon = (couponCode) => {
    if (couponCode === 'DIGIVET') {
      const couponName = 'couponMessage.offer-75';
      const couponSuccessMessage = {};
      couponSuccessMessage[couponName] = getCouponMessage('DIGIVET', this.formatMessage);
      return {
        subscriptionOffers: ['offer-75'],
        isInvoiceAddressModalOpen: { 'offer-75': false },
        couponSuccessMessage,
        validatedCouponValue: couponCode,
        couponValue: couponCode,
      };
    }
    return null;
  };

  renderHistoryIfLoggedIn = () => {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      return (
        <div style={{ marginTop: '50px' }}>
          <Header as="h2" dividing>
            <FormattedMessage id="billing.history" />
          </Header>
          <PurchaseHistory />
        </div>
      );
    }
    return null;
  };

  renderCreditPackOffer = (creditPack) => {
    const { isLoggedIn } = this.props;
    return (
      <div className="card">
        <div className="card content">
          <div className="header third">
            {creditPack.credits} <FormattedMessage id="billing.credits" />
          </div>
        </div>
        <div className="card content">
          <div className="header">
            <Header as="h4">
              <Header.Content>
                <FormattedMessage id={`billing.packsNames.${creditPack.backendName}`} />
              </Header.Content>
            </Header>
          </div>
        </div>
        <div className="card content">
          <Header as="h4">
            {creditPack.price / 100} {creditPack.currency} <FormattedMessage id="billing.HT" />
            <br />
            <Header.Subheader>
              {(creditPack.price * 1.2) / 100} {creditPack.currency}{' '}
              <FormattedMessage id="billing.TTC" />
            </Header.Subheader>
            <Header.Subheader>
              <FormattedMessage id="billing.packs.promoMessage" />
            </Header.Subheader>
          </Header>
        </div>
        {isLoggedIn ? this.renderBuyCreditsButton(creditPack) : this.renderRegisterButton()}
      </div>
    );
  };

  renderBuyCreditsButton = (creditPack) => (
    <button
      type="submit"
      className="ui bottom attached button picoxiaLightGreen greenHover"
      onMouseDown={(event) => this.buyCredits(event, creditPack)}
    >
      <span>
        <i className="add icon" />
        <FormattedMessage id="billing.purchase" />
      </span>
    </button>
  );

  renderSubscriptions = () => {
    const { subscriptionOffers } = this.state;
    return subscriptionOffers.map((subscriptionPlanName) =>
      this.renderSubscriptionPlanOffer(SUBSCRIBE_PACKS[subscriptionPlanName])
    );
  };

  onChangeCouponValue = (subscriptionKey, e) => {
    const { couponValues, validatedCouponValue } = this.state;
    const couponText = e.target.value;
    const couponInfo = this.hasValidCoupon(couponText);
    const newCouponValues = { ...couponValues, [subscriptionKey]: couponText };
    if (couponInfo) {
      this.setState({ ...couponInfo, couponValues: newCouponValues });
    } else if (couponText !== '' || couponText !== validatedCouponValue) {
      this.setState({ couponValues: newCouponValues });
    }
  };

  renderSubscriptionPlanOffer = (subscriptionPlan) => {
    const { locale, isLoggedIn } = this.props;
    const { isInvoiceAddressModalOpen, couponFailedMessage, couponSuccessMessage, couponValues } =
      this.state;
    const subscriptionKey = `coupon.${subscriptionPlan.backendName}`;
    const thisSubscriptionCouponValue = couponValues[subscriptionKey];
    const termsOfSales = locale === 'fr' ? 'termsOfSales.html' : 'termsOfSales-en.html';
    return (
      <div
        as="article"
        className="card subscriptionOffer"
        key={subscriptionPlan.backendName}
        style={{ maxWidth: '80%' }}
      >
        <div className="card content subscriptionOffer__row">
          <p className="subscriptionOffer__text">
            <strong>
              <FormattedMessage
                id={`billing.subscriptionPlansDescriptions.${subscriptionPlan.backendName}`}
              />
            </strong>
            <br />
            <span className="subscriptionOffer__sub-strong">
              <FormattedMessage id="billing.packs.reasonableUse" />
            </span>
          </p>
        </div>
        <div className="card content subscriptionOffer__row">
          <p className="subscriptionOffer__text">
            <strong>
              {subscriptionPlan.price / 100} {subscriptionPlan.currency}{' '}
              <FormattedMessage id={`billing.per.${subscriptionPlan.interval}`} />
            </strong>
            <br />
            <span className="subscriptionOffer__sub-strong">
              {(subscriptionPlan.price * 1.2) / 100} {subscriptionPlan.currency}{' '}
              <FormattedMessage id="billing.TTC" />
            </span>
            <br />
            {this.renderFreeTrial(subscriptionPlan)}
          </p>
          {/* <FormattedMessage id="billing.packs.promoMessage" /> */}
        </div>
        <div className="card content subscriptionOffer__row">
          <div className="ui action input subscriptionOffer__coupon">
            <input
              type="text"
              placeholder={this.formatMessage({ id: 'billing.coupon' })}
              value={thisSubscriptionCouponValue}
              onChange={(e) => this.onChangeCouponValue(subscriptionKey, e)}
            />
            <div className="ui icon button">
              <i aria-hidden="true" className="tag icon" />
            </div>
          </div>
          <p className="subscriptionOffer__termsOfSale">
            <FormattedMessage
              id="billing.CGV"
              values={{
                a: (chunks) => <a href={`/${termsOfSales}`}>{chunks}</a>,
              }}
            />
            <ModalTermsOfSales />
          </p>
          <b style={{ color: 'red' }}>
            {couponFailedMessage[`couponMessage.${subscriptionPlan.backendName}`]}
          </b>
          <b style={{ color: 'green' }}>
            {couponSuccessMessage[`couponMessage.${subscriptionPlan.backendName}`]}
          </b>
        </div>
        {isLoggedIn
          ? this.renderBuySubscriptionButton(subscriptionPlan)
          : this.renderRegisterButton()}
        <InvoiceAddressModal
          isOpen={isInvoiceAddressModalOpen[subscriptionPlan.backendName]}
          closeWhenDone={false}
          close={() => this.closeInvoiceAddressModal(subscriptionPlan)}
          onSave={() =>
            this.subscribe(
              subscriptionPlan,
              `coupon.${subscriptionPlan.backendName}`,
              `couponMessage.${subscriptionPlan.backendName}`
            )
          }
        />
      </div>
    );
  };

  renderFreeTrial = (subscriptionPlan) => {
    if (subscriptionPlan.trial_days === 0) {
      return null;
    }
    return (
      <strong style={{ color: 'green' }}>
        {this.formatMessage(
          { id: 'billing.packs.trialPeriod' },
          { periodDays: subscriptionPlan.trial_days }
        )}
      </strong>
    );
  };

  renderRegisterButton = () => (
    <IntlCrawlableLink url="/register" className="ui button blue bottom attached">
      <FormattedMessage id="billing.register" />
    </IntlCrawlableLink>
  );

  renderBuySubscriptionButton = (subscriptionPlan) => (
    <button
      className="ui bottom attached button picoxiaLightGreen greenHover"
      onMouseDown={() => this.openInvoiceAddressModal(subscriptionPlan)}
      type="button"
    >
      <i className="add icon" />
      <FormattedMessage id="billing.purchase" />
    </button>
  );

  openInvoiceAddressModal = (subscriptionPlan) => {
    const { isInvoiceAddressModalOpen } = this.state;
    const newInvoiceAddressModalOpen = {};
    Object.assign(newInvoiceAddressModalOpen, isInvoiceAddressModalOpen);
    newInvoiceAddressModalOpen[subscriptionPlan.backendName] = true;
    this.setState({ isInvoiceAddressModalOpen: newInvoiceAddressModalOpen });
  };

  closeInvoiceAddressModal = (subscriptionPlan) => {
    const { isInvoiceAddressModalOpen } = this.state;
    const newInvoiceAddressModalOpen = {};
    Object.assign(newInvoiceAddressModalOpen, isInvoiceAddressModalOpen);
    newInvoiceAddressModalOpen[subscriptionPlan.backendName] = false;
    this.setState({ isInvoiceAddressModalOpen: newInvoiceAddressModalOpen });
  };

  renderCreditPackSection = () => (
    <div>
      <Header as="h2" dividing>
        <FormattedMessage id="billing.creditMode" />
      </Header>
      <div className="ui centered cards">{this.renderCreditPackOffer(CREDIT_PACKS.discovery)}</div>
    </div>
  );

  renderSubscriptionOffersIfNotSubscribed = () => {
    const { hasSubscribed } = this.props;
    if (!hasSubscribed) {
      return (
        <section>
          <Header as="h2" dividing>
            <FormattedMessage id="billing.subscriptionMode" />
          </Header>
          <div className="ui centered cards">{this.renderSubscriptions()}</div>
        </section>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="billing">
        <AppHelmet titleIntlID="billing.pageTitle" descriptionIntlID="billing.pageDescription" />
        <div className="ui container" style={{ paddingTop: '20px' }}>
          <h1 style={{ fontSize: '2.7em', textAlign: 'center', marginBottom: '0px' }}>
            <FormattedMessage id="billing.offers" />
          </h1>
          <div
            className="ui custom divider"
            style={{ width: '10%', marginTop: '20px', marginBottom: '5px' }}
          />
          {this.renderSubscriptionOffersIfNotSubscribed()}
          {this.renderHistoryIfLoggedIn()}
          <Message warning>
            <FormattedMessage id="billing.warning" />
          </Message>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.get('language').get('locale'),
    isLoggedIn: state.get('global').get('loggedIn'),
    hasSubscribed: state.get('global').get('subscriptions').get('subscribed'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

BillingPage.propTypes = {
  isLoggedIn: pt.bool.isRequired,
  locale: pt.string.isRequired,
  intl: pt.shape().isRequired,
  hasSubscribed: pt.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BillingPage));
