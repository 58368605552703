/* eslint-disable no-underscore-dangle */
import * as _ from 'lodash';
import { memoizeDebounce } from 'app/utils/lodashMixins';
import { setInvalidWatchedDirectory } from 'app/pms/actions';
import handleFileInput from 'app/pms/handleFileInput';
import observeStore from 'app/utils/redux/observeStore';
import fs from 'app/native/node/fs';
import path from 'app/native/node/path';
import { makeSelectWatchedDirectory } from 'app/pms/selector';
import { DIRECTORY_WATCHER_RETRY_INTERVAL } from 'app/pms/constants';

async function* getFiles(dir) {
  const directoryEntries = await fs().promises.readdir(dir, { withFileTypes: true });
  for (let i = 0; i < directoryEntries.length; i += 1) {
    const directoryEntry = directoryEntries[i];
    const res = path().resolve(dir, directoryEntry.name);
    if (directoryEntry.isDirectory()) {
      yield* getFiles(res);
    } else {
      yield res;
    }
  }
}

class PmsDirectoryWatcher extends EventTarget {
  constructor(fileHandler, parser) {
    super();
    this.abortSignal = undefined;
    // Used for retry mechanism in case watcher cannot be initialized or fail to connect
    this.watcherSetupInterval = undefined;
    this.fileHandler = fileHandler;
    this.parser = parser;
    this.debouncedFileChange = memoizeDebounce(this.onFileChange, 100, {
      resolver: (watchedDirectory, _eventType, filename) => path().join(watchedDirectory, filename),
    });
  }

  destroy = () => {
    clearInterval(this.watcherSetupInterval);
    this.abortSignal?.abort();
  };

  onDirectoryChange = async ({ store, state: watchedDirectory }) => {
    if (!fs() || !path()) return;

    this.abortSignal?.abort();
    this.abortSignal = new AbortController();
    const { signal } = this.abortSignal;

    clearInterval(this.watcherSetupInterval);
    if (!watchedDirectory) {
      this.watcher = undefined;
      return;
    }
    try {
      console.info('Setting up directory watch for', watchedDirectory);
      const watcher = fs().promises.watch(watchedDirectory, {
        persistent: false,
        recursive: true,
        encoding: 'utf8',
        signal,
      });

      console.info('Watching directory', watchedDirectory);
      store.dispatch(setInvalidWatchedDirectory(false));

      (async () => {
        // eslint-disable-next-line no-restricted-syntax
        for await (const filename of getFiles(watchedDirectory)) {
          try {
            await this._readAndParseFile(filename);
          } catch (e) {
            console.warn('Could not read initial files present in folder:', filename, e);
          }
        }
      })();

      // eslint-disable-next-line no-restricted-syntax
      for await (const event of watcher) {
        const { eventType, filename } = event;
        this.debouncedFileChange(watchedDirectory, eventType, filename);
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        console.info('Watcher aborted:', watchedDirectory);
        return;
      }

      console.error('Failed to start watcher on directory:', watchedDirectory, e);
      store.dispatch(setInvalidWatchedDirectory(true));

      // Here we will retry setting up watcher infinitely until success or directory change.
      this.watcherSetupInterval = setTimeout(
        () => this.onDirectoryChange({ store, state: watchedDirectory }),
        DIRECTORY_WATCHER_RETRY_INTERVAL
      );
    }
  };

  _readAndParseFile = async (filepath) => {
    await this.fileHandler(filepath, this.parser);
    const event = new Event('file_parsed');
    this.dispatchEvent(event);
  };

  onFileChange = async (watchedDirectory, eventType, filename) => {
    console.log(`File change detected: ${eventType} - ${filename}`);
    if (eventType !== 'change') return;
    try {
      const filePath = path().join(watchedDirectory, filename);
      const fileStat = await fs().promises.stat(filePath);
      if (fileStat.isFile()) {
        await this._readAndParseFile(path().join(watchedDirectory, filename));
      }
    } catch (e) {
      console.warn(e);
    }
  };
}

const attachPMSDirectoryWatcher = (
  store,
  { fileHandler = handleFileInput, pmsSelector = makeSelectWatchedDirectory(), parser } = {}
) => {
  const pmsDirectoryWatcher = new PmsDirectoryWatcher(fileHandler, parser);
  const unsubscribe = observeStore(store, pmsSelector, pmsDirectoryWatcher.onDirectoryChange);
  return {
    pmsDirectoryWatcher,
    unsubscribe,
  };
};

export { PmsDirectoryWatcher, attachPMSDirectoryWatcher };
