import React from 'react';
import * as pt from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import errorToMessage from 'app/errors/errorToMessage';

const errorType = {
  response: pt.shape({
    data: pt.shape({
      cause: pt.string,
    }).isRequired,
    status: pt.number.isRequired,
  }).isRequired,
};

class FormattedServerError extends React.PureComponent {
  render() {
    const { error } = this.props;
    const errorContent = errorToMessage(error);
    const errorId = errorContent?.id ?? errorContent;
    const errorValues = errorContent?.values;

    return (
      <span style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id={errorId} values={errorValues} />
      </span>
    );
  }
}

FormattedServerError.propTypes = {
  error: pt.shape(errorType),
};

FormattedServerError.defaultProps = {
  error: {},
};

export { errorType };

export default injectIntl(FormattedServerError);
