// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place

import { hashSync } from 'bcryptjs';
import { browserHistory } from 'react-router';
import { take, call, put, fork, race } from 'redux-saga/effects';
import auth from '../containers/auth';

import {
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  SENDING_REQUEST,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  SET_AUTH,
  LOGOUT,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SET_SUBSCRIPTION_MAIL_SENT,
} from './global/constants';

export function* authorize({ username, password }) {
  yield put({ type: SENDING_REQUEST, sending: true });

  let { response, error } = yield call(auth.login, username, password);

  if (response && response.data && response.data.status && response.data.status === 'success') {
    yield put({ type: SENDING_REQUEST, sending: false });
    yield put({ type: SET_AUTH, newAuthState: true });
    return response;
  } else if (error) {
    yield put({ type: REQUEST_ERROR, error });
  } else {
    yield put({ type: REQUEST_ERROR, error: { message: 'Erreur inconnue' } });
  }
}

export function* logout() {
  // eslint-disable-line consistent-return
  // We tell Redux we're in the middle of a request
  yield put({ type: SENDING_REQUEST, sending: true });

  // Similar to above, we try to log out by calling the `logout` function in the
  // `auth` module. If we get an error, we send an appropiate action. If we don't,
  // we return the response.
  try {
    const response = yield call(auth.logout);
    yield put({ type: SENDING_REQUEST, sending: false });
    yield put({ type: SET_AUTH, newAuthState: false });

    return response;
  } catch (error) {
    yield put({ type: REQUEST_ERROR, error });
  }
}

export function* loginFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // eslint-disable-line no-constant-condition
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(LOGIN_REQUEST);
    const { username, password, redirectRoute } = request.data;

    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const winner = yield race({
      auth: call(authorize, { username, password }),
      logout: take(LOGOUT),
    });

    if (winner.auth) {
      yield put({ type: SET_AUTH, newAuthState: true }); // User is logged in (authorized)
      yield put({ type: CHANGE_USERNAME, username: username }); // Clear username
      yield put({ type: CHANGE_PASSWORD, password: '' }); // Clear password

      forwardTo(redirectRoute); // Go to home page
    } else if (winner.logout) {
      yield put({ type: SET_AUTH, newAuthState: false }); // User is not logged in (not authorized)
      yield call(logout); // Call `logout` effect
      forwardTo('/'); // Go to root page
    }
  }
}

export function* logoutFlow() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const request = yield take(LOGOUT);
    const { redirectRoute } = request.data;
    yield put({ type: SET_AUTH, newAuthState: false });

    yield call(logout);
    forwardTo(redirectRoute);
  }
}

export function* registerFlow() {
  while (true) {
    // eslint-disable-line no-constant-condition
    // We always listen to `REGISTER_REQUEST` actions
    const request = yield take(REGISTER_REQUEST);
    let { response, error } = yield call(auth.register, request.data);

    if (response && response.data && response.data.status && response.data.status === 'success') {
      yield put({ type: SENDING_REQUEST, sending: false });
      yield put({ type: SET_AUTH, newAuthState: false });
      yield put({ type: SET_SUBSCRIPTION_MAIL_SENT, value: true });

      // forwardTo('/');
      return response;
    } else if (response) {
      yield put({ type: REQUEST_ERROR, error: response.data });
    } else if (error) {
      yield put({ type: REQUEST_ERROR, error: { message: error } });
    }
    //  else {
    //   yield put({type: REQUEST_ERROR, error: {message: "Erreur inconnue"}});
    // }
  }
}

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* root() {
  yield fork(loginFlow);
  yield fork(logoutFlow);
  yield fork(registerFlow);
}

// Little helper function to abstract going to different pages
function forwardTo(location) {
  browserHistory.push(location);
}
