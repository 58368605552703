import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import IntlCrawlableLink from '../../IntlCrawlableLink';
import { useIntl } from 'react-intl';
import LanguageDropdown from '../../LanguageDropdown';
import AccountDropdown from '../../AccountDropdown';

import 'app/styles/style.scss';
import './style.scss';
import UpdateButton from 'app/components/UpdateButton';
import FormContact from 'app/components/Form/Contact';

const NavbarFullSize = ({
  leftItems,
  middleItems,
  isLoggedIn,
  hasSubscribed,
  style,
  renderNavigationLink,
  getUserNameOnLinkClick,
  switchFullScreenMode,
}) => {
  const intl = useIntl();
  const { electron } = window.nodeModules ?? {};

  const isFullScreen = () => {
    if (!electron) return undefined;
    return electron.remote.getCurrentWindow().isFullScreen();
  };

  const renderNavigationSubmenu = (item) => {
    const menuName = intl.formatMessage({ id: item.menuName });
    return (
      <Dropdown as="li" text={menuName} className="link item menu-dropdown" key={item.menuName}>
        <Dropdown.Menu as="ul" className="menu-dropdown__content">
          {item.subMenu.map((subItem) => {
            const linkName = intl.formatMessage({ id: subItem.messageId });
            return (
              <li key={subItem.messageId} className="menu-dropdown__item">
                <IntlCrawlableLink
                  url={subItem.routes[0]}
                  className="item menu-dropdown__link"
                  onClick={getUserNameOnLinkClick}
                >
                  {linkName}
                </IntlCrawlableLink>
              </li>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const renderNavItems = (items) => {
    return items.map((item) => {
      if (item.isLoggedInNeeded === true && !isLoggedIn) {
        return '';
      }
      if (item.hasNotSubscribedNeeded === true && hasSubscribed) {
        return '';
      }
      if ('icon' in item) {
        return renderNavigationLink(
          item.routes,
          <Icon name={item.icon} className="headerNav" />,
          item.titleMessageId,
          'item'
        );
      }
      if ('menuName' in item) {
        return renderNavigationSubmenu(item);
      }
      return renderNavigationLink(
        item.routes,
        intl.formatMessage({ id: item.messageId }),
        item.titleMessageId,
        'item'
      );
    });
  };

  const renderRightItems = () => {
    const { formatMessage } = intl;
    return (
      <li className="right">
        {process.env.PLATFORM === 'website' && <LanguageDropdown />}
        <div className="flex center">
          <div className="flex center">
            <UpdateButton />
          </div>
        </div>
        <FormContact />
        <AccountDropdown />
        {electron && (
          <>
            <button
              type="button"
              className="flex center right-button"
              onClick={() => electron.remote.BrowserWindow.getFocusedWindow().minimize()}
              title={formatMessage({ id: 'header.close.tooltip' })}
            >
              <Icon name="minus" />
            </button>
            <button
              type="button"
              className="flex center right-button"
              onClick={switchFullScreenMode}
              title={
                isFullScreen()
                  ? formatMessage({ id: 'header.minimize.tooltip' })
                  : formatMessage({ id: 'header.maximize.tooltip' })
              }
            >
              {isFullScreen() ? (
                <Icon name="window restore outline" />
              ) : (
                <Icon name="square outline" />
              )}
            </button>
            <button
              type="button"
              className="flex center right-button right-button--close"
              onClick={() => electron.remote.app.quit()}
              title={formatMessage({ id: 'header.close.tooltip' })}
            >
              <Icon name="close" />
            </button>
          </>
        )}
      </li>
    );
  };

  const thereAreMiddleItems = middleItems.length !== 0;

  return (
    <nav className="ui container nav-header nav" style={style}>
      <ul className="ui large secondary inverted pointing menu navbarFullSize">
        {renderNavItems(leftItems)}
        {thereAreMiddleItems && <span className="nav__separator" />}
        {renderNavItems(middleItems)}
        {renderRightItems()}
      </ul>
    </nav>
  );
};

export default NavbarFullSize;
