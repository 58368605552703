/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useRef, useState } from 'react';
import { Button, Form, Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Async from 'react-select/async';
import UserStoreContext from 'app/injectors/UserStore/context';
import AdminStoreContext from 'app/injectors/AdminStore/context';
import logger from 'app/utils/debug/logger';
import toastifyPromise from 'app/utils/toastifyPromise';
import useAutoFocusRef from 'app/utils/hooks/useAutoFocusRef';

function AddMemberButton({ team, onNewTeamMember }) {
  const userStore = useContext(UserStoreContext);
  const adminStore = useContext(AdminStoreContext);
  const autoFocusRef = useAutoFocusRef();
  const [open, setOpen] = useState(false);

  const searchUsers = async (inputValue) => {
    if (!inputValue || inputValue.length < 3) return undefined;
    try {
      const { users } = await userStore.getUsers({ partialEmail: inputValue });

      return users.map((user) => ({
        value: user.mail,
        label: user.mail,
      }));
    } catch (e) {
      logger.warn(e);
    }
    return undefined;
  };

  const handleSelect = ({ value }) => {
    if (!value) return;

    const promise = adminStore.addTeamMember(team.name, value);

    toastifyPromise('addTeamMember', promise);
    promise.then(() => {
      setOpen(false);
      onNewTeamMember();
    });
  };

  const onClick = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button floated="right" color="green" size="tiny" onClick={onClick}>
          <Icon name="plus" />
          <FormattedMessage id="adminPage.addMember.button" />
        </Button>
      }
      on="click"
      content={
        <Form.Field>
          <label>
            <FormattedMessage id="adminPage.addMember.label" />
            <Async
              ref={autoFocusRef}
              defaultOptions
              loadOptions={searchUsers}
              onChange={handleSelect}
              placeholder={<FormattedMessage id="adminPage.addMember.placeholder" />}
            />
          </label>
        </Form.Field>
      }
    />
  );
}

export default AddMemberButton;
