import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { IntlShape, injectIntl } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { ReportStudy, generateDocxFromStudy } from '../../utils/docxUtil';
import { getImageAttribute } from '../../utils/studyInfo';
import ApiCalls from '../../utils/apiCalls';

export type WordButtonProps = {
  intl: IntlShape;
  study: ReportStudy;
  report: string;
};
type WordButtonState = {
  reportInfo?: {
    workplace_name: string;
    first_name: string;
    last_name: string;
    mail: string;
    clinic_logo: string;
  };
};

class WordButton extends React.Component<WordButtonProps, WordButtonState> {
  constructor(props: WordButtonProps) {
    super(props);
    this.state = { reportInfo: null };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args: Parameters<IntlShape['formatMessage']>) =>
    this.props.intl.formatMessage(...args) as string;

  getReportInfo = () => {
    const { reportInfo } = this.state;
    if (reportInfo) {
      return Promise.resolve(reportInfo);
    }
    return ApiCalls.getReportInfo(true).then((response) => {
      this.setState({ reportInfo: response.data.reportInfo });
      return response.data.reportInfo;
    });
  };

  getMetadata = () => {
    const { study } = this.props;
    // TBD use patient info instead of image info.

    // const imagesWithMetadata = study.images.filter((image) => image.metadata);
    // if (imagesWithMetadata.length > 0) {
    //   return imagesWithMetadata[0].metadata;
    // }
    return { animalSex: '', animalWeight: '', animalSize: '', animalAge: '' };
  };

  getSpecie = () => {
    const { study } = this.props;
    const catConfidence = getImageAttribute(study.images, 'predictions').chat;
    if (catConfidence > 0.9) {
      return this.formatMessage({ id: 'complementaryInfoForm.specie.cat' });
    }
    if (catConfidence < 0.1) {
      return this.formatMessage({ id: 'complementaryInfoForm.specie.dog' });
    }
    return this.formatMessage({ id: 'complementaryInfoForm.specie.other' });
  };

  renderWordButton = () => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      const { study, intl } = this.props;
      generateDocxFromStudy(study, intl);
    };
    return (
      <Button
        type="button"
        size="large"
        style={{ float: 'right', backgroundColor: '#365695', color: 'white' }}
        onClick={onClick}
        icon
        data-testid="word-button"
      >
        <Icon name="file word" />
      </Button>
    );
  };

  render() {
    // {this.state.patternName.replace(/_/g, " ")}
    return (
      <Popup
        content={this.formatMessage({ id: 'complementaryInfoForm.openWord' })}
        trigger={this.renderWordButton()}
      />
    );
  }
}

export default injectIntl(WordButton);
