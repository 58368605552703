/* eslint-disable no-underscore-dangle */
import * as csc from 'cornerstone-core';
import {
  EVENTS,
  BaseAnnotationTool,
  store,
  clipToBox,
  triggerEvent,
  removeToolState,
  anyHandlesOutsideImage,
  getToolForElement,
} from 'app/CornerstoneTools';
import mouseButtonMaskToButtons from 'app/CornerstoneTools/mouseButtonMaskToButtons';

const _moveEvents = {
  mouse: [EVENTS.MOUSE_MOVE, EVENTS.MOUSE_DRAG],
  touch: [EVENTS.TOUCH_DRAG],
};

const _moveEndEvents = {
  mouse: [EVENTS.MOUSE_UP, EVENTS.MOUSE_CLICK],
  touch: [EVENTS.TOUCH_END, EVENTS.TOUCH_PINCH, EVENTS.TAP],
};

/**
 * Stop the CornerstoneToolsTouchStart event from
 * Becoming a CornerstoneToolsTouchStartActive event when
 * MoveNewHandle ends
 *
 * @private
 * @function stopImmediatePropagation
 *
 * @param {*} evt
 * @returns {Boolean} false
 */
function stopImmediatePropagation(evt) {
  evt.stopImmediatePropagation();

  return false;
}

/**
 * Move a new handle
 *
 * @public
 * @method moveNewHandle
 * @memberof Manipulators
 *
 * @param {*} eventData
 * @param {*} toolName
 * @param {*} annotation
 * @param {*} handle
 * @param {*} [options={}]
 * @param {Boolean}  [options.deleteIfHandleOutsideImage]
 * @param {Boolean}  [options.preventHandleOutsideImage]
 * @param {string} [interactionType=mouse]
 * @param {function} [doneMovingCallback]
 * @returns {void}
 */
export default function (
  { element },
  toolName,
  annotation,
  handle,
  toolOptions,
  interactionType = 'mouse',
  doneMovingCallback
) {
  // Use global defaults, unless overidden by provided options
  const options = {
    deleteIfHandleOutsideImage: store.state.deleteIfHandleOutsideImage,
    preventHandleOutsideImage: store.state.preventHandleOutsideImage,
    hasMoved: false,
    ...toolOptions,
  };

  const moveHandleCoords = (evt) => {
    const { currentPoints, image } = evt.detail;
    const { page } = currentPoints;
    const fingerOffset = -57;
    const targetLocation = csc.pageToPixel(
      element,
      interactionType === 'touch' ? page.x + fingerOffset : page.x,
      interactionType === 'touch' ? page.y + fingerOffset : page.y
    );
    handle.x = targetLocation.x;
    handle.y = targetLocation.y;

    if (options.preventHandleOutsideImage) {
      clipToBox(handle, image);
    }
  };

  let cancelFn;

  /**
   * Updates annotation as the "pointer" is moved/dragged
   * Emits `cornerstonetoolsmeasurementmodified` events
   *
   * @param {string} toolName
   * @param {*} annotation
   * @param {*} handle
   * @param {*} options
   * @param {string} interactionType
   * @param {*} evt
   *
   * @returns {void}
   */
  const moveHandler = (evt) => {
    const { image } = evt.detail;

    options.hasMoved = true;

    moveHandleCoords(evt);

    annotation.invalidated = true;
    handle.active = true;

    csc.updateImage(element);

    const activeTool = getToolForElement(element, toolName);
    if (activeTool.mode !== 'active') {
      cancelFn();
      return;
    }

    if (activeTool instanceof BaseAnnotationTool) {
      activeTool.updateCachedStats(image, element, annotation);
    }

    const eventType = EVENTS.MEASUREMENT_MODIFIED;
    const modifiedEventData = {
      toolName,
      toolType: toolName, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData: annotation,
    };

    triggerEvent(element, eventType, modifiedEventData);
  };

  let moveEndHandler;

  const endHandler = (success = true) => {
    // Remove event listeners
    _moveEvents[interactionType].forEach((eventType) => {
      element.removeEventListener(eventType, moveHandler);
    });
    _moveEndEvents[interactionType].forEach((eventType) => {
      element.removeEventListener(eventType, moveEndHandler);
    });
    element.removeEventListener(EVENTS.TOUCH_START, stopImmediatePropagation);

    if (typeof doneMovingCallback === 'function') {
      doneMovingCallback(success);
    }

    // Update Image
    csc.updateImage(element);
  };

  moveEndHandler = (evt) => {
    const eventData = evt.detail;
    let moveNewHandleSuccessful = true;

    moveHandleCoords(evt);

    if (options.hasMoved === false) {
      return;
    }
    const tool = getToolForElement(element, toolName);

    if (!mouseButtonMaskToButtons(tool.options.mouseButtonMask).includes(eventData.event.button))
      return;

    // "Release" the handle
    annotation.active = false;
    annotation.invalidated = true;
    handle.active = false;
    handle.moving = false;

    // If any handle is outside the image, delete the tool data
    if (
      options.deleteIfHandleOutsideImage &&
      anyHandlesOutsideImage(evt.detail, annotation.handles)
    ) {
      annotation.cancelled = true;
      moveNewHandleSuccessful = false;
      removeToolState(element, toolName, annotation);
    }

    endHandler(moveNewHandleSuccessful);
  };

  cancelFn = () => endHandler(false);

  // Add event listeners
  _moveEvents[interactionType].forEach((eventType) => {
    element.addEventListener(eventType, moveHandler);
  });
  element.addEventListener(EVENTS.TOUCH_START, stopImmediatePropagation);

  _moveEndEvents[interactionType].forEach((eventType) => {
    element.addEventListener(eventType, moveEndHandler);
  });

  annotation.active = true;

  handle.moving = true;
  handle.active = true;

  return cancelFn;
}
