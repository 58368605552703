/* eslint-disable no-underscore-dangle */
import * as cst from 'cornerstone-core';
import { BaseTool, cursors, store } from 'app/CornerstoneTools';
import getInteractiveToolsForElement from 'app/CornerstoneTools/utils/getInteractiveToolsForElement';
import getToolsWithDataForElement from 'app/CornerstoneTools/utils/getToolsWithDataForElement';
import getToolsWithMoveableHandles from 'app/CornerstoneTools/utils/getToolsWithMoveableHandles';

/**
 * @public
 * @class PanTool
 * @memberof Tools
 *
 * @classdesc Tool for panning the image.
 * @extends Tools.Base.BaseTool
 */
export default class PanTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'Pan',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: cursors.panCursor,
    };

    super(props, defaultProps);

    // Touch
    this.preTouchStartCallback = this._touchStart.bind(this);
    this.touchDragCallback = this._dragCallback.bind(this);
    this.touchEndCallback = this._dragStop.bind(this);
    this.touchDragEndCallback = this._dragStop.bind(this);
    // Mouse
    this.preMouseDownCallback = this._mouseStart.bind(this);
    this.mouseUpCallback = this._dragStop.bind(this);
    this.mouseDragCallback = this._dragCallback.bind(this);
    // On quick clicks, mouseUp does not fire, but this does
    this.mouseClickCallback = this._dragStop.bind(this);

    this.isNearAnnotations = false;
  }

  _touchStart = (evt) => {
    const { getters } = store;
    const eventData = evt.detail;
    const element = { eventData };
    const coords = eventData.startPoints.canvas;

    const activeAndPassiveTools = getInteractiveToolsForElement(element, getters.touchTools());

    const annotationTools = getToolsWithDataForElement(element, activeAndPassiveTools);

    // NEAR HANDLES?
    const annotationToolsWithMoveableHandles = getToolsWithMoveableHandles(
      element,
      annotationTools,
      coords,
      'touch'
    );

    // TODO: More than one? Which one was moved most recently?
    // We'll just grab the first one we encounter for now
    if (annotationToolsWithMoveableHandles.length > 0) {
      this.isNearAnnotations = true;
    }
  };

  _mouseStart = (evt) => {
    const { getters } = store;
    const { element, startPoints } = evt.detail;
    const coords = startPoints.canvas;
    const activeAndPassiveTools = getInteractiveToolsForElement(element, getters.mouseTools());

    const annotationTools = getToolsWithDataForElement(element, activeAndPassiveTools);

    // NEAR HANDLES?
    const annotationToolsWithMoveableHandles = getToolsWithMoveableHandles(
      element,
      annotationTools,
      coords,
      'mouse'
    );

    if (annotationToolsWithMoveableHandles.length > 0) {
      this.isNearAnnotations = true;
    }
  };

  _dragStop = () => {
    this.isNearAnnotations = false;
  };

  _dragCallback = (evt) => {
    if (this.isNearAnnotations) return;

    const eventData = evt.detail;
    const { element, viewport } = eventData;

    const translation = this._getTranslation(eventData);

    this._applyTranslation(viewport, translation);
    cst.setViewport(element, viewport);
  };

  _getTranslation = (eventData) => {
    const { viewport, image, deltaPoints } = eventData;

    let widthScale = viewport.scale;
    let heightScale = viewport.scale;

    if (image.rowPixelSpacing < image.columnPixelSpacing) {
      widthScale *= image.columnPixelSpacing / image.rowPixelSpacing;
    } else if (image.columnPixelSpacing < image.rowPixelSpacing) {
      heightScale *= image.rowPixelSpacing / image.columnPixelSpacing;
    }

    return {
      x: deltaPoints.page.x / widthScale,
      y: deltaPoints.page.y / heightScale,
    };
  };

  _applyTranslation = (viewport, translation) => {
    viewport.translation.x += translation.x;
    viewport.translation.y += translation.y;
  };
}
