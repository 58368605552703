/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import * as cst from 'cornerstone-tools';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import { Button } from 'app/components/ToolsBar/EmbeddedToolsButtons/Button';
import { ButtonGroup } from 'app/components/ToolsBar/EmbeddedToolsButtons/ButtonGroup';
import ToolType from '../../types/ToolType';
import ActivatableToolButton from './ActivatableToolButton';
import { updateToolsList } from './utils';

class CropToolButton extends React.PureComponent {
  componentDidUpdate = () => {
    const { toolsList, name, focusedElement } = this.props;
    if (toolsList[name].state === 'active') {
      this.addButtonGroup(focusedElement);
    } else {
      this.removeButtonGroup(focusedElement);
    }
  };

  addButtonGroup = (element) => {
    const { intl } = this.props;
    if (!element) return;
    this.removeButtonGroup(element);
    element.CropToolButtonButtonGroup = document.createElement('div');
    element.CropToolButtonButtonGroup.style = 'pointer-events: none;';
    element.appendChild(element.CropToolButtonButtonGroup);
    ReactDOM.render(
      <ButtonGroup>
        <Button
          className="picoxia"
          backgroundColor="#27ae60"
          onPointerDown={() => this.validateCrop(element)}
        >
          <Icon name="check" />
          {intl.formatMessage({ id: 'tools.crop.button.validate' })}
        </Button>
        <Button
          className="picoxia"
          backgroundColor="#605e5e"
          onPointerDown={() => this.cancelCrop(element)}
        >
          <Icon name="cancel" />
          {intl.formatMessage({ id: 'tools.crop.button.cancel' })}
        </Button>
      </ButtonGroup>,
      element.CropToolButtonButtonGroup
    );
  };

  removeButtonGroup = (element) => {
    if (element?.CropToolButtonButtonGroup) {
      element.removeChild(element.CropToolButtonButtonGroup);
      delete element.CropToolButtonButtonGroup;
    }
  };

  setToolState = (newState) => {
    const { focusedElement } = this.props;
    if (newState === 'active') {
      focusedElement.CropToolButtonKeyPressListener = this.keypressListen.bind(
        this,
        focusedElement
      );
      document.addEventListener('keydown', focusedElement.CropToolButtonKeyPressListener);
      this.addButtonGroup(focusedElement);
    } else {
      this.removeButtonGroup(focusedElement);
      document.removeEventListener('keydown', focusedElement.CropToolButtonKeyPressListener);
      delete focusedElement.CropToolButtonKeyPressListener;
    }
  };

  cancelCrop = (element) => {
    const { toolsList, name, produceImageToolsList, produceCommonToolsList } = this.props;
    const cropTool = cst.getToolForElement(element, name);
    cropTool.cancelCrop(element);
    updateToolsList(
      toolsList,
      name,
      produceImageToolsList,
      produceCommonToolsList,
      this.setToolState,
      'passive'
    );
  };

  validateCrop = (element) => {
    const { toolsList, name, produceImageToolsList, produceCommonToolsList } = this.props;
    const cropTool = cst.getToolForElement(element, name);
    cropTool.validateCrop(element);
    updateToolsList(
      toolsList,
      name,
      produceImageToolsList,
      produceCommonToolsList,
      this.setToolState,
      'passive'
    );
  };

  keypressListen = (element, evt) => {
    const { focusedElement } = this.props;
    if (element !== focusedElement) return;
    if (evt.key === 'Escape') this.cancelCrop(element);
    else if (evt.key === 'Enter') this.validateCrop(element);
  };

  render = () => (
    <ActivatableToolButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...this.props}
      content={<i className="svg-icon crop" />}
      tooltipId="tools.crop.tooltip"
      setToolStateFn={this.setToolState}
    />
  );
}

CropToolButton.propTypes = {
  name: pt.string.isRequired,
  toolsList: pt.objectOf(ToolType).isRequired,
  produceCommonToolsList: pt.func.isRequired,
  produceImageToolsList: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
};

CropToolButton.defaultProps = {
  focusedElement: null,
};

export default injectIntl(CropToolButton);
