import React from 'react';
import { injectIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';

import DysplasiaPatternStructuredList from '.';

const TEST_PREDICTIONS = {
  norberg_olsson: {
    left: {
      patterns: {
        arthrose: 0.9987174400032034,
        defaut_de_coaptation: 0.9814128664012847,
        incongruence: 0.9968520145637481,
        insuffisance_de_couverture_acetabulaire: 0.2450352769880699,
        ligne_de_morgan: 0.038542931898833795,
      },
      stade: 1.4,
    },
    right: {
      patterns: {
        arthrose: 0.9956676118221508,
        defaut_de_coaptation: 0.9705530772194523,
        incongruence: 0.9916547143971138,
        insuffisance_de_couverture_acetabulaire: 0.42142060592827757,
        ligne_de_morgan: 0.06854671797444849,
      },
      stade: 1.6,
    },
  },
  rotation: 'standard_position',
  type: 'pelvis_face',
};

const TEST_ANNOTATIONS = {
  NorbergOlsson: new Map([
    [
      '259ddbc2-1825-4b16-8b86-a2baeaffdb6f',
      {
        visible: true,
        leftAngle: 76.1,
        rightAngle: 104,
        uuid: '259ddbc2-1825-4b16-8b86-a2baeaffdb6f',
      },
    ],
  ]),
};

const Test = () => {
  const predictions = TEST_PREDICTIONS;
  return (
    <Container text>
      <DysplasiaPatternStructuredList predictions={predictions} annotations={TEST_ANNOTATIONS} />
    </Container>
  );
};

export default injectIntl(Test);
