import * as _ from 'lodash';
import { MERGED_OPACITIES } from '../reports/constants';

/* eslint-disable no-param-reassign */
const searchForCardiomegalieGlobale = (predictions) => {
  if (
    'cardiomegalie' in predictions &&
    'dilatation_ventricule_gauche' in predictions &&
    'dilatation_ventricule_droit' in predictions &&
    predictions.cardiomegalie > 0.5 &&
    predictions.dilatation_ventricule_gauche > 0.5 &&
    predictions.dilatation_ventricule_droit > 0.5
  ) {
    predictions.cardiomegalie_globale = Math.max(
      predictions.cardiomegalie,
      predictions.dilatation_ventricule_gauche,
      predictions.dilatation_ventricule_droit
    );
    delete predictions.cardiomegalie;
    delete predictions.dilatation_ventricule_gauche;
    delete predictions.dilatation_ventricule_droit;
  }
};

const useMostLikelyOpacite = (predictions) => {
  const opacityToMerge = _.pick(predictions, MERGED_OPACITIES);
  const maxOpacity = _.maxBy(Object.entries(opacityToMerge), (entry) => entry[1]);
  // Older predictions may not contain any MERGED_OPACITIES and cannot be merged
  if (!maxOpacity) return;
  MERGED_OPACITIES.forEach((opacityKey) => {
    if (opacityKey !== maxOpacity[0]) {
      delete predictions[opacityKey];
    }
  });
};

const filterAtelectasie = (predictions) => {
  const isProfil = 'face' in predictions && predictions.face < 0.5;
  if (isProfil && predictions.atelectasie !== undefined) {
    delete predictions.atelectasie;
  }
};
/* eslint-enable no-param-reassign */

const postProcessPredictions = (predictions) => {
  const postProcessedPredictions = _.cloneDeep(predictions);
  useMostLikelyOpacite(postProcessedPredictions);
  searchForCardiomegalieGlobale(postProcessedPredictions);
  filterAtelectasie(postProcessedPredictions);
  return postProcessedPredictions;
};

export { postProcessPredictions };
export default postProcessPredictions;
