import { loadStripe } from '@stripe/stripe-js';

const getStripeToken = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'pk_test_VygPtnJwWiAn8nZBEKyT46Rm00Bjz4VrIu';
  } else {
    return 'pk_live_d2GO5vCUaaKz3U5zChyIMcgk00QoNjzWVT';
  }
};
const stripe = loadStripe(getStripeToken());

export default await stripe;
