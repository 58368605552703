import React from 'react';
import TechnicianPasswordValidationContext from 'app/providers/TechnicianPasswordValidationProvider/context';
import useTechnicianPasswordProtection from 'app/utils/hooks/passwordProtection/useTechnicianPasswordProtection';

export default function TechnicianPasswordValidationProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [requestValidation, modalComponent] = useTechnicianPasswordProtection();
  return (
    <TechnicianPasswordValidationContext.Provider value={requestValidation as () => Promise<any>}>
      {children}
      {modalComponent}
    </TechnicianPasswordValidationContext.Provider>
  );
}
