import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PACSCommunicationInjector from 'app/injectors/PACSCommunication/context';
import {
  isPACSConfigurationDisabled,
  selectPACSConfiguration,
} from 'app/redux/PACSConfiguration/reducer';
import logger from 'app/utils/debug/logger';
import { PACSCommunication } from 'app/interfaces/PACSCommunication';

export const DEFAULT_PACS_COMMUNICATION_TIMEOUT = 60;

export const PACSCommunicationContext = React.createContext<PACSCommunication>(undefined);

export const IsPACSAssociatedContext = React.createContext<boolean>(undefined);

const PACSCommunicationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const PACSCommunication = useContext(PACSCommunicationInjector);

  if (!PACSCommunication) return <>{children}</>;

  const pacsConfiguration = useSelector(selectPACSConfiguration);
  const pacsConfigurationJS = pacsConfiguration.toJS();

  const isEmptyPACSConfiguration =
    pacsConfigurationJS === undefined || _.isEmpty(pacsConfigurationJS);
  const isPACSDisabled = isPACSConfigurationDisabled(pacsConfigurationJS);

  const [pacsCommunication] = useState(() =>
    isEmptyPACSConfiguration ? new PACSCommunication() : new PACSCommunication(pacsConfigurationJS)
  );
  const [isPACSAssociated, setIsPACSAssociated] = useState(undefined);

  const checkAssociationIntervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (!isPACSDisabled) {
      const checkAssociation = () =>
        pacsCommunication
          .checkAssociation()
          .then(() => setIsPACSAssociated(true))
          .catch((error: any) => {
            logger.warn('Association check error', error);
            setIsPACSAssociated(false);
          });
      const intervalTimeout = pacsConfigurationJS.timeout ?? DEFAULT_PACS_COMMUNICATION_TIMEOUT;

      checkAssociation();
      checkAssociationIntervalRef.current = setInterval(
        checkAssociation,
        (1000 * intervalTimeout) / 2
      );
    }

    return () => {
      clearInterval(checkAssociationIntervalRef.current);
      checkAssociationIntervalRef.current = undefined;
    };
  }, [pacsConfiguration, pacsCommunication]);

  return (
    <PACSCommunicationContext.Provider value={pacsCommunication}>
      <IsPACSAssociatedContext.Provider value={isPACSAssociated}>
        {children}
      </IsPACSAssociatedContext.Provider>
    </PACSCommunicationContext.Provider>
  );
};

export default PACSCommunicationProvider;
