/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

import ApiCalls from 'app/utils/apiCalls';
import { StudyStore } from 'app/interfaces/StudyStore';
import { Base64Image, ImageLoader } from 'app/interfaces/ImageLoader';
import {
  DEFAULT_JPEG_ENCODE_OPTIONS,
  IDisplayableImageEncoder,
} from 'app/interfaces/IDisplayableImageEncoder';
import { Patient } from 'app/interfaces/Patient';

/**
 * @class
 * @implements {StudyStore}
 */
export default class CloudBasedStudyStore implements StudyStore {
  /** @param {ImageLoader} imageLoader */
  /** @param {import('app/interfaces/IDisplayableImageEncoder').IDisplayableImageEncoder} imageEncoder */
  constructor(private imageLoader: ImageLoader, private imageEncoder: IDisplayableImageEncoder) {}

  updatePatient = (studyId: string, patient: Patient) => {
    if (patient._id) {
      return ApiCalls.linkStudyToAnimal(studyId, patient._id).then(() => patient);
    }

    return ApiCalls.savePatientInfo(patient).then(({ data }) =>
      ApiCalls.linkStudyToAnimal(studyId, data.animal._id).then(() => data.animal)
    );
  };

  deleteImage = async (studyId: string, imageId: string) => {
    await ApiCalls.removeImagesFromStudy(studyId, [imageId]);
  };

  updateThumbnailImage = async (imageId: string, thumbnailBlob: Blob) => {
    // ApiCalls.saveThumbnail(imageId, thumbnailBlob);
  };

  getStudy = async (studyId: string) => {
    const [study] = await ApiCalls.getStudies([studyId]);
    return { ...study, patient: study.animal };
  };

  loadImage: StudyStore['loadImage'] = async (studyId, imageId) => {
    const base64ImageResponse = await ApiCalls.getImage({ imageId });
    const imageFile: Base64Image = `data:image/png;base64,${base64ImageResponse.data.image}`;

    return this.imageLoader.load(imageFile);
  };

  loadThumbnail: StudyStore['loadThumbnail'] = async () => {
    throw new Error('No THumbnail found');
  };

  updateReport: StudyStore['updateReport'] = async (studyId, comment, isCommentDirty) => {
    await ApiCalls.sendComplementaryInfo({
      study_id: studyId,
      comment,
      isCommentDirty,
    });
  };

  updateImage: StudyStore['updateImage'] = async (studyId, imageId, image) => {
    await ApiCalls.updateImage(studyId, imageId, image);
  };

  saveRawImage: StudyStore['saveRawImage'] = async () => {
    throw new Error('Not raw storage available for CloudBasedStore');
    // We do not store raw image inside the server.
  };

  saveLastProcessedImage: StudyStore['saveLastProcessedImage'] = async (
    studyId,
    imageId,
    lastProcessedImage
  ) => {
    const { imageData } = await this.imageLoader.load(lastProcessedImage);
    const imageBlob = await this.imageEncoder.toJPEG(imageData);

    await ApiCalls.putImage(imageId, imageBlob);
  };
}
