import './style.css';

import React from 'react';
import { IntlShape, MessageDescriptor, injectIntl } from 'react-intl';
import withContext from 'app/utils/withContext';

import WordButton, { WordButtonProps } from 'app/components/WordButton';
import { delimiter } from 'app/utils/reports/constants';
import { SetAIPanelHoveredForceOpenContext } from 'app/components/FoldableAIPanel/FoldablePanel';

const getHeightToContentInTextArea = (
  textAreaRef: HTMLTextAreaElement,
  searchedContent: string
) => {
  const textAreaStyle = window.getComputedStyle(textAreaRef);

  const mirroredTextArea = document.createElement('textarea');
  mirroredTextArea.style.wordWrap = 'break-word';
  mirroredTextArea.style.whiteSpace = 'normal';
  mirroredTextArea.style.height = '0';
  mirroredTextArea.style.overflow = 'hidden';
  mirroredTextArea.style.visibility = 'hidden';
  mirroredTextArea.style.padding = textAreaStyle.padding;
  mirroredTextArea.style.width = textAreaStyle.width;
  mirroredTextArea.style.fontFamily = textAreaStyle.fontFamily;
  mirroredTextArea.style.fontSize = textAreaStyle.fontSize;
  mirroredTextArea.style.lineHeight = textAreaStyle.lineHeight;

  const contentIndex = textAreaRef.value.indexOf(searchedContent);
  if (contentIndex === -1) return 0;
  // We have to had the mirrored element after the real element else we get a 0 scrollHeight
  textAreaRef.after(mirroredTextArea);
  mirroredTextArea.value = textAreaRef.value.substring(0, contentIndex);
  const { scrollHeight } = mirroredTextArea;
  mirroredTextArea.remove();
  return scrollHeight;
};

type EditableReportProps = {
  study: {
    ID?: string;
    comment?: string;
    animal?: any;
    images?: {
      backendId?: string;
      metadata?: any;
    }[];
  } & WordButtonProps['study'];
  setAIPanelHoveredForceOpenContext?: (shouldForceOpen: boolean) => void;
  onChange?: (value: string) => void;
  minHeight: string;
  maxHeight?: string;
  isEditable?: boolean;
  isDirty?: boolean;
  intl: IntlShape;
};
type EditableReportState = {
  isFocused: boolean;
};

class EditableReport extends React.PureComponent<EditableReportProps, EditableReportState> {
  static defaultProps = {
    setAIPanelHoveredForceOpenContext: () => {},
    onChange: () => {},
    maxHeight: null as string,
    isDirty: true,
    isEditable: true,
  };

  private textAreaRef: HTMLTextAreaElement;
  constructor(props: EditableReportProps) {
    super(props);
    this.state = { isFocused: false };
    this.textAreaRef = undefined;
  }

  componentDidMount() {
    const scrollHeight = getHeightToContentInTextArea(
      this.textAreaRef,
      `${delimiter} ${this.formatMessage({ id: 'report.compteRendu' })}`
    );
    this.textAreaRef.scrollTop = scrollHeight;
  }

  componentDidUpdate(prevProps: EditableReportProps) {
    const { isDirty, study } = this.props;
    const isNewGeneratedComment = !isDirty && prevProps.study.comment !== study.comment;
    if (isNewGeneratedComment) {
      const scrollHeight = getHeightToContentInTextArea(
        this.textAreaRef,
        `${delimiter} ${this.formatMessage({ id: 'report.compteRendu' })}`
      );
      this.textAreaRef.scrollTop = scrollHeight;
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (descriptor: MessageDescriptor, ...args: any[]) =>
    this.props.intl.formatMessage(descriptor, ...args);

  onTextAreaRef = (ref: HTMLTextAreaElement) => {
    this.textAreaRef = ref;
  };

  onParentBlur = (
    evt: React.FocusEvent<HTMLDivElement>,
    setAIPanelHoveredForceOpen: (shouldForceOpen: boolean) => void
  ) => {
    // We do not consider focus lost as long as we are inside the element
    const focusStillInElement = evt.currentTarget.contains(evt.relatedTarget as Node);
    if (!focusStillInElement) {
      this.setState({ isFocused: false });
      setAIPanelHoveredForceOpen(false);
    }
  };

  render() {
    const { setAIPanelHoveredForceOpenContext, study, onChange, minHeight, maxHeight, isEditable } =
      this.props;
    const setAIPanelHoveredForceOpen = setAIPanelHoveredForceOpenContext;
    const { isFocused } = this.state;
    const height = isFocused && maxHeight ? maxHeight : minHeight;

    return (
      <div
        className={isEditable ? 'editable-report' : 'editable-report non-editable-report'}
        style={{ height, borderRadius: '3px' }}
        onBlur={(evt) => this.onParentBlur(evt, setAIPanelHoveredForceOpen)}
      >
        <textarea
          disabled={!isEditable}
          ref={this.onTextAreaRef}
          value={study.comment}
          placeholder={this.formatMessage({ id: 'monitoring.comment' })}
          onChange={(evt) => onChange(evt.target.value)}
          onFocus={() => {
            this.setState({ isFocused: true });
            setAIPanelHoveredForceOpen(true);
          }}
        />
        {isEditable && (
          <div className="word-button">
            <WordButton study={study} report={study.comment} />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(
  withContext(
    EditableReport,
    SetAIPanelHoveredForceOpenContext,
    'setAIPanelHoveredForceOpenContext'
  )
);
