import * as csc from 'cornerstone-core';
import { DisplayViewport, DisplayableImageData } from 'app/interfaces/Image';
import { get } from 'app/utils/PromiseHelpers';
import { EVENTS, displayImage } from 'cornerstone-core';

export default async function displayImageWithSync(
  element: HTMLElement,
  image: DisplayableImageData,
  viewport?: DisplayViewport
) {
  return await get(
    new Promise((resolve, reject) => {
      element.addEventListener(EVENTS.IMAGE_RENDERED, resolve, { once: true });
      element.addEventListener(EVENTS.IMAGE_LOAD_FAILED, reject, { once: true });
      displayImage(element, image as csc.Image, viewport);
    })
  );
}
