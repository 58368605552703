/* eslint-disable react/destructuring-assignment */
import 'app/styles/style.scss';
import './style.scss';

import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import DirectorySelector from 'app/components/DirectorySelector';
import electron from 'app/native/node/electron';
import {
  LocalDicomStoreContext,
  LocalDicomStoreContextShape,
} from 'app/providers/LocalDicomStoreProvider';
import withContext from 'app/utils/withContext';

const getDialog = () => electron()?.remote.dialog;

class DicomStoreDirectoriesSelector extends React.PureComponent {
  addSaveDirectory = () => {
    getDialog()
      ?.showOpenDialog({ properties: ['openDirectory'] })
      .then((selectedDirectories) => {
        if (selectedDirectories.canceled) return;
        this.props.localDicomStoreContext.produceStorageDirectories((storageDirectories) => {
          storageDirectories.push(selectedDirectories.filePaths[0]);
        });
      });
  };

  editSaveDirectory = (directory, index) =>
    this.props.localDicomStoreContext.produceStorageDirectories((draftStorageDirectories) => {
      draftStorageDirectories[index] = directory;
    });

  deleteSaveDirectory = (index) =>
    this.props.localDicomStoreContext.produceStorageDirectories((storageDirectories) => {
      storageDirectories.splice(index, 1);
    });

  updateDirectory = (directory, index) => {
    if (directory === undefined) {
      this.deleteSaveDirectory(index);
    } else {
      this.editSaveDirectory(directory, index);
    }
  };

  renderSaveDirectory = (saveDirectory, index) => (
    <DirectorySelector
      key={`${saveDirectory}-${index}`}
      useDirectory={() => saveDirectory}
      useIsInvalidDirectory={() => false}
      setSelectedDirectory={(directory) => this.updateDirectory(directory, index)}
      placeholderKey="account.configuration.dicom_save_directory.placeholder"
      invalidDirectoryKey="account.configuration.is_invalid_directory"
      setDirectoryDescriptionKey="account.configuration.dicom_save_directory.update.description"
      clearDirectoryDescriptionKey="account.configuration.dicom_save_directory.clear_directory.description"
    />
  );

  render = () => {
    if (!getDialog()) return null;
    const { storageDirectories } = this.props.localDicomStoreContext;
    return (
      <div className="dicom-store-directories-selector">
        <>{storageDirectories.map(this.renderSaveDirectory)}</>
        <Popup
          content={
            <FormattedMessage id="account.configuration.dicom_save_directory.add_directory.description" />
          }
          trigger={
            <Button
              aria-label="add-directory"
              className="dicom-store-directories-selector__add-directory-button"
              icon="add circle"
              onClick={this.addSaveDirectory}
            />
          }
        />
      </div>
    );
  };
}

DicomStoreDirectoriesSelector.propTypes = {
  localDicomStoreContext: LocalDicomStoreContextShape.isRequired,
};
export default withContext(
  DicomStoreDirectoriesSelector,
  LocalDicomStoreContext,
  'localDicomStoreContext'
);
