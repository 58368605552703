import React from 'react';
import * as PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Dimmer, Icon, Loader, Reveal } from 'semantic-ui-react';
import './style.css';
import ExamIcon from '../ExamIcon';
import { isAcquisitionImage } from '../../utils/isAcquisitionImage';
import withContext from '../../utils/withContext';
import { ImageCacheContext, DEFAULT_IMAGE_CACHE_CONTEXT } from '../../containers/ImageCache';

class Exams extends React.Component {
  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  renderExams = (exams) => exams.map((exam, index) => this.renderExam(exam, index));

  renderExam = (exam, index) => {
    const { animal, imageCache } = this.props;
    if (!exam || !exam.anatomicRegion) return undefined;
    let renderedVignette;
    if (isAcquisitionImage(exam)) {
      const imageUrl = imageCache.getImage(exam._id);
      if (imageUrl) {
        renderedVignette = (
          <Reveal animated="small fade" instant>
            <Reveal.Content visible>
              <img src={imageUrl} alt="x-ray" />
            </Reveal.Content>
            <Reveal.Content hidden>
              <ExamIcon exam={exam.anatomicRegion} animal={animal} />
            </Reveal.Content>
          </Reveal>
        );
      } else if (imageUrl === undefined) {
        renderedVignette = (
          <div>
            <Dimmer active>
              <Loader />
            </Dimmer>
            <ExamIcon className="xray" exam={exam.anatomicRegion} animal={animal} />
          </div>
        );
      } else {
        renderedVignette = <ExamIcon className="xray" exam={exam.anatomicRegion} animal={animal} />;
      }
    } else {
      renderedVignette = <ExamIcon exam={exam.anatomicRegion} animal={animal} />;
    }
    return (
      <div className="exam" key={index}>
        {renderedVignette}
        {this.renderDeleteIcon(exam)}
      </div>
    );
  };

  renderDeleteIcon = (exam) => (
    <div style={{ zIndex: 3 }}>
      <Icon
        onClick={() => this.props.onRemoveExam(exam)}
        fitted
        className="delete-image"
        name="times circle"
        size="large"
      />
    </div>
  );

  render() {
    const { exams } = this.props;
    return <div className="exams">{this.renderExams(exams)}</div>;
  }
}
Exams.propTypes = {
  intl: PropTypes.shape().isRequired,
  exams: PropTypes.arrayOf(PropTypes.shape({ anatomicRegion: PropTypes.string })).isRequired,
  animal: PropTypes.shape().isRequired,
  imageCache: PropTypes.shape(),
};

Exams.defaultProps = {
  imageCache: DEFAULT_IMAGE_CACHE_CONTEXT,
};

export default injectIntl(withContext(Exams, ImageCacheContext, 'imageCache'));
