import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { XRayGeneratorControllerContext } from 'app/providers/XRayGeneratorProvider';

export default function DemoVirtualXRayControllerPedal() {
  const xrayGeneratorController = useContext(XRayGeneratorControllerContext);

  if (!xrayGeneratorController?.triggerVirtualPedal) return null;

  const { triggerVirtualPedal } = xrayGeneratorController;

  return (
    <Button onClick={triggerVirtualPedal}>
      <FormattedMessage id="xray_generator.demo.virtual_pedal" />
    </Button>
  );
}
