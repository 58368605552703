import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';
import PatientInfoBar from '../PatientInfoBar';

const CancelButton = styled.button`
  width: fit-content;
`;

/* eslint-disable */
const conflictingPatientsList = (conflictingPatients) => (
  <div>
    {conflictingPatients.map(({ file_id, chip_id, name, owner_name, birth_date, specie, sex }) => (
      <div key={`${file_id}_${chip_id}_${name}_${owner_name}`}>
        <PatientInfoBar
          fileID={file_id}
          animalName={name}
          ownerName={owner_name}
          birthDate={birth_date}
          specie={specie}
          sex={sex}
        />
      </div>
    ))}
  </div>
);
/* eslint-enable */

const PatientConflictWarning = ({ conflictingPatients, selectCreate, selectUpdate, cancel }) => (
  <div className="patient-conflict">
    <div className="flex">
      <div>
        <FormattedMessage
          id="patient_info.conflict.info"
          values={{
            patient: (
              <Popup
                wide
                flowing
                content={conflictingPatientsList(conflictingPatients)}
                trigger={
                  <span className="patient">
                    <FormattedMessage id="patient_info.conflict.patient" />
                  </span>
                }
              />
            ),
          }}
        />
      </div>

      {cancel && (
        <CancelButton type="button" onClick={cancel}>
          <Icon fitted name="times" size="large" />
        </CancelButton>
      )}
    </div>
    <div className="conflicts-buttons">
      <Button size="tiny" color="green" onClick={() => selectCreate(conflictingPatients)}>
        <FormattedMessage id="general.create" />{' '}
        <FormattedMessage id="patient_info.conflict.create_text" />
      </Button>
      <Button size="tiny" color="green" onClick={() => selectUpdate(conflictingPatients)}>
        <FormattedMessage id="patient_info.conflict.update_button" />{' '}
        <FormattedMessage id="patient_info.conflict.update_text" />
      </Button>
    </div>
  </div>
);

PatientConflictWarning.propTypes = {
  conflictingPatients: pt.arrayOf(pt.shape({})).isRequired,
  selectCreate: pt.func.isRequired,
  selectUpdate: pt.func.isRequired,
  cancel: pt.func,
};

PatientConflictWarning.defaultProps = {
  cancel: undefined,
};

export default PatientConflictWarning;
