import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Gradient = ({ style }) => <div className="gradient" style={style} />;

Gradient.defaultProps = {
  style: null,
};

Gradient.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default Gradient;
