import { ScaleOverlayTool } from 'app/CornerstoneTools';

/**
 * Silence missing pixelSPacing for ScaleOverlayTool
 */
class SilentScaleOverlayTool extends ScaleOverlayTool {
  renderToolData(evt) {
    const eventData = evt.detail;
    const { image } = eventData;

    const { rowPixelSpacing, columnPixelSpacing } = image;
    if (!rowPixelSpacing || !columnPixelSpacing) return;

    super.renderToolData(evt);
  }
}

export default SilentScaleOverlayTool;
