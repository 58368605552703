/* eslint-disable react/prop-types */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
import produce from 'immer';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import React from 'react';
import path from 'app/native/node/path';
import fs from 'app/native/node/fs';
import withContext from 'app/utils/withContext';
import StorageUsageMonitorContext from 'app/providers/StorageUsageMonitorProvider/context';
import { getDicomDataValue } from 'app/utils/dicom/DicomData';
import picoxiaDicom from 'app/native/node-addons/picoxia-dicom';
import electron from 'app/native/node/electron';
import { LocalDicomStore } from 'app/interfaces/LocalDicomStore';
import { DicomData, DicomTransferSyntax } from 'app/interfaces/Dicom';
import StorageUsageMonitor from 'app/adapters/StorageUsageMonitor';
import { DataImage } from 'app/interfaces/DataImage';
import makeKeyedChainablePromises, {
  KeyedChainablePromise,
} from 'app/utils/async/KeyedChainablePromise';
import getPixelValues from 'app/utils/cornerstone/getPixelValues';
import CodecModuleContext from 'app/providers/CodecModuleProvider/context';
import { CodecModule, ImageInfo } from 'app/interfaces/CodecModule';

const UNCOMPRESSED_DICOM_TRANSFER_SYNTAX = [
  '1.2.840.10008.1.2', //  Implicit VR Little Endian Transfer Syntax
  '1.2.840.10008.1.2.1', //  Explicit VR Little Endian Transfer Syntax
];

const SAVED_DICOM_FIELDS = [
  'AcquisitionDate',
  'AcquisitionTime',
  'AdditionalPatientHistory',
  'BitsAllocated',
  'BitsStored',
  'BodyPartExamined',
  'BurnedInAnnotation',
  'Columns',
  'ContentDate',
  'ContentTime',
  'DateOfLastDetectorCalibration',
  'DetectorElementPhysicalSize',
  'DetectorID',
  'DetectorManufacturerModelName',
  'DetectorManufacturerName',
  'DetectorTemperature',
  'DetectorType',
  'FieldOfViewShape',
  'HighBit',
  'ImageLaterality',
  'ImagerPixelSpacing',
  'ImageType',
  'InstanceNumber',
  'InstitutionAddress',
  'InstitutionalDepartmentName',
  'InstitutionName',
  'LargestImagePixelValue',
  'LossyImageCompression',
  'Manufacturer',
  'ManufacturerModelName',
  'Modality',
  'NumberOfFrames',
  'OperatorsName',
  'OtherPatientIDs',
  'PatientAge',
  'PatientBirthDate',
  'PatientBreedDescription',
  'PatientComments',
  'PatientID',
  'PatientName',
  'PatientSex',
  'PatientSexNeutered',
  'PatientSpeciesDescription',
  'PhotometricInterpretation',
  'PhysiciansOfRecord',
  'PixelAspectRatio',
  'PixelData',
  'PixelIntensityRelationship',
  'PixelIntensityRelationshipSign',
  'PixelRepresentation',
  'PixelSpacing',
  'PresentationIntentType',
  'ReferringPhysicianName',
  'RescaleIntercept',
  'RescaleSlope',
  'RescaleType',
  'ResponsiblePerson',
  'ResponsiblePersonRole',
  'Rows',
  'SamplesPerPixel',
  'SeriesDate',
  'SeriesDescription',
  'SeriesInstanceUID',
  'SeriesTime',
  'SmallestImagePixelValue',
  'SOPClassUID',
  'SOPInstanceUID',
  'SpecificCharacterSet',
  'StationName',
  'StudyDate',
  'StudyDescription',
  'StudyID',
  'StudyInstanceUID',
  'StudyTime',
  'TimeOfLastDetectorCalibration',
  'ViewPosition',
  'WindowCenter',
  'WindowWidth',
  'KVP',
  'ExposureTime',
  'ExposureTimeInuS',
  'XRayTubeCurrent',
  'XRayTubeCurrentInuA',
  'Exposure',
  'ExposureInuAs',
];

// Those files seems to cause problems when they are kept from one dicom to another.
const DICOM_FILES_TO_REMOVE = [
  'FileMetaInformationGroupLength',
  'FileMetaInformationVersion',
  'MediaStorageSOPClassUID',
  'MediaStorageSOPInstanceUID',
  'TransferSyntaxUID',
  'ImplementationClassUID',
  'ImplementationVersionName',
];

const DEFAULT_LOCAL_DICOM_STORE_CONTEXT: LocalDicomStore = {
  storageDirectories: [],
  produceStorageDirectories: () => {},
  getDicomImagePath: async () => Promise.reject(new Error('Not implemented')),
  storeDicomImage: async () => Promise.reject(new Error('Not implemented')),
  getProcessedImage: async () => Promise.reject(new Error('Not implemented')),
  storeProcessedImage: async () => Promise.reject(new Error('Not implemented')),
};

const SAVE_BETWEEN_STORAGE_USAGE_UPDATE = 10;

const LocalDicomStoreContext = React.createContext<LocalDicomStore>(
  DEFAULT_LOCAL_DICOM_STORE_CONTEXT
);

const LocalDicomStoreContextShape = pt.shape({
  storageDirectories: pt.arrayOf(pt.string),
  produceStorageDirectories: pt.func,
  getDicomImagePath: pt.func,
  storeDicomImage: pt.func,
  storeProcessedImage: pt.func,
  getProcessedImagePath: pt.func,
});

export type LocalDicomStoreProviderImplProps = {
  storageUsageMonitor?: StorageUsageMonitor;
  codecModule: CodecModule;
};
type LocalDicomStoreProviderImplState = LocalDicomStore;

class LocalDicomStoreProviderImpl extends React.PureComponent<
  LocalDicomStoreProviderImplProps,
  LocalDicomStoreProviderImplState
> {
  private remainingSaveBeforeStorageUsageUpdate: number = SAVE_BETWEEN_STORAGE_USAGE_UPDATE;
  private imageWritePromisesChain: KeyedChainablePromise = makeKeyedChainablePromises();

  constructor(props: LocalDicomStoreProviderImplProps) {
    super(props);
    if (process.env.PLATFORM === 'electron') {
      const acquisitionSaveDirectory = localStorage.getItem('AcquisitionSaveDirectories');
      let storageDirectories: string[];
      if (acquisitionSaveDirectory) {
        storageDirectories = JSON.parse(acquisitionSaveDirectory);
      } else {
        const { remote } = electron();
        const defaultStorageDirectory = path().join(remote.app.getPath('userData'), 'images');
        storageDirectories = [defaultStorageDirectory];
        localStorage.setItem('AcquisitionSaveDirectories', JSON.stringify(storageDirectories));
      }
      this.state = {
        storageDirectories,
        produceStorageDirectories: this.produceStorageDirectories,
        getDicomImagePath: this.getDicomImagePath,
        storeDicomImage: this.storeDicomImage,
        storeProcessedImage: this.storeProcessedImage,
        getProcessedImage: this.getProcessedImage,
      };
    } else {
      this.state = DEFAULT_LOCAL_DICOM_STORE_CONTEXT;
      Object.freeze(this.state);
    }

    // We debounce this function to avoid running non essential task during processing.
  }

  componentDidMount() {
    this.updateStorageUsage();
  }

  componentDidUpdate(
    prevProps: Readonly<LocalDicomStoreProviderImplProps>,
    prevState: Readonly<LocalDicomStore>,
    snapshot?: any
  ) {
    const { storageDirectories } = this.state;
    if (prevState.storageDirectories !== storageDirectories) {
      localStorage.setItem('AcquisitionSaveDirectories', JSON.stringify(storageDirectories));
    }
  }

  triggerStorageUsageUpdateDebouncedIfLimitReached = _.debounce(() => {
    if (this.remainingSaveBeforeStorageUsageUpdate <= 0) {
      this.remainingSaveBeforeStorageUsageUpdate = SAVE_BETWEEN_STORAGE_USAGE_UPDATE;
      this.updateStorageUsage();
    }
  }, 1000);

  produceStorageDirectories = (producer: (storageDirectories: string[]) => void) =>
    this.setState(produce(({ storageDirectories }) => producer(storageDirectories)));

  getStoredFilePath = async (studyId: string, imageId: string, suffix: string) => {
    const { storageDirectories } = this.state;
    try {
      return await Promise.any(
        storageDirectories.map(async (storageDirectory) => {
          const testedDicomImagePath = path().join(
            storageDirectory,
            studyId,
            `${imageId}${suffix}`
          );
          // Promise will reject if file cannot be accessed.
          await fs().promises.access(testedDicomImagePath, fs().constants.R_OK);
          return testedDicomImagePath;
        })
      );
    } catch {
      return undefined;
    }
  };

  getDicomImagePath = async (studyId: string, imageId: string) =>
    this.getStoredFilePath(studyId, imageId, '.dcm');

  getProcessedImage = async (studyId: string, imageId: string): Promise<DataImage> => {
    const { codecModule } = this.props;
    if (!codecModule) return undefined;
    try {
      const processedPath = await this.getStoredFilePath(studyId, imageId, '_processed.htj2k');
      if (!processedPath) {
        console.log('No processed image found for', processedPath);
        return undefined;
      }
      const start = performance.now();
      const fileContent = fs().readFileSync(processedPath);

      const decodedBuffer = await codecModule.decode(fileContent, '1.2.840.10008.1.2.4.201');
      if (!decodedBuffer) {
        return undefined;
      }
      const { buffer, frameInfo } = decodedBuffer;

      const { minPixelValue, maxPixelValue } = getPixelValues(buffer as Uint16Array);
      const end = performance.now();
      console.log('Processed image successfully loaded:', processedPath, 'in', end - start, 'ms.');
      return {
        data: buffer as Uint16Array,
        width: frameInfo.columns,
        height: frameInfo.rows,
        maxPixelValue: maxPixelValue,
        minPixelValue: minPixelValue,
        bytes_per_pixel: buffer.BYTES_PER_ELEMENT,
      };
    } catch {
      return undefined;
    }
  };

  countSaveUsageAndUpdateStorageUsage = () => {
    this.remainingSaveBeforeStorageUsageUpdate -= 1;
    this.triggerStorageUsageUpdateDebouncedIfLimitReached();
  };

  updateStorageUsage = () => {
    const { storageUsageMonitor } = this.props;
    const { storageDirectories } = this.state;
    storageDirectories.forEach(storageUsageMonitor.updateStorageUsageForDirectory);
  };

  storeDicomImage = async (studyId: string, imageId: string, dicomData: DicomData) => {
    const { storageDirectories } = this.state;

    if (!dicomData) return;
    if (storageDirectories.length === 0) return;

    this.countSaveUsageAndUpdateStorageUsage();

    let transferSyntax = getDicomDataValue(dicomData, 'TransferSyntaxUID') ?? '1.2.840.10008.1.2.1';
    const dicomDataToSave = _.pick(dicomData, SAVED_DICOM_FIELDS);

    const start = performance.now();
    // We convert pixelData to jpegls for compression if module is available and data can be compressed.
    const encapsulationResult = await this.encapsulatePixelDataIfPossible(dicomData);
    if (encapsulationResult) {
      transferSyntax = encapsulationResult.transferSyntax;
      dicomDataToSave.TransferSyntaxUID = encapsulationResult.transferSyntax;
      dicomDataToSave.PixelData = encapsulationResult.PixelData;
    }
    let dicomImageBuffer: Uint8Array;
    try {
      dicomImageBuffer = await picoxiaDicom()?.writeDicomAsync(
        dicomDataToSave,
        undefined,
        transferSyntax
      );
    } catch (e) {
      console.error('Failed to write dicom', e);
    }
    if (!dicomImageBuffer) return;

    const end = performance.now();
    console.log('DICOM buffer written:', imageId, 'in', end - start, 'ms.');

    storageDirectories.forEach(async (storageDirectory) => {
      const studyPath = path().join(storageDirectory, studyId);
      try {
        await fs().promises.mkdir(studyPath, { recursive: true });
      } catch {
        return;
      }
      const imageFilePath = path().join(studyPath, `${imageId}.dcm`);

      const fileContent = new Uint8Array(dicomImageBuffer);
      return this.imageWritePromisesChain.chainPromise(
        () => fs().promises.writeFile(imageFilePath, fileContent),
        imageFilePath
      );
    });
  };

  storeProcessedImage = async (
    studyId: string,
    imageId: string,
    imageData: DataImage
  ): Promise<void> => {
    const { codecModule } = this.props;
    if (!codecModule) return undefined;
    if (!imageData) return undefined;

    const { storageDirectories } = this.state;
    if (storageDirectories.length === 0) return undefined;

    const start = performance.now();
    let fileContent: Uint8Array;
    try {
      fileContent = await codecModule?.encode(
        imageData.data,
        {
          columns: imageData.width,
          rows: imageData.height,
          bitsAllocated: imageData.data.BYTES_PER_ELEMENT * 8,
          samplesPerPixel: 1,
          signed: false,
        },
        '1.2.840.10008.1.2.4.201'
      );
    } catch {
      console.log('Failed to encode image', imageId);
      return;
    }
    const end = performance.now();
    console.log('Processed image successfully encoded:', imageId, 'in', end - start, 'ms.');

    await Promise.allSettled(
      storageDirectories.map(async (storageDirectory) => {
        const studyPath = path().join(storageDirectory, studyId);
        try {
          await fs().promises.mkdir(studyPath, { recursive: true });
        } catch {
          return;
        }
        const imageFilePath = path().join(studyPath, `${imageId}_processed.htj2k`);

        return this.imageWritePromisesChain.chainPromise(
          () => fs().promises.writeFile(imageFilePath, fileContent),
          imageFilePath
        );
      })
    );
  };

  async encapsulatePixelDataIfPossible(dicomData: DicomData) {
    const { codecModule } = this.props;
    if (!codecModule) return undefined;

    const originalTransferSyntax = getDicomDataValue(dicomData, 'TransferSyntaxUID');

    const alreadyEncapsulatedPixelData =
      originalTransferSyntax &&
      !UNCOMPRESSED_DICOM_TRANSFER_SYNTAX.includes(originalTransferSyntax);

    if (alreadyEncapsulatedPixelData) return undefined;

    const frameInfo: ImageInfo = {
      columns: getDicomDataValue(dicomData, 'Columns'),
      rows: getDicomDataValue(dicomData, 'Rows'),
      bitsAllocated: getDicomDataValue(dicomData, 'BitsStored'),
      samplesPerPixel: 1,
      signed: false,
    };

    let pixelData = getDicomDataValue(dicomData, 'PixelData');
    let jpegLSPixelData;
    try {
      jpegLSPixelData = await codecModule.encode(
        pixelData,
        frameInfo,
        DicomTransferSyntax.JPEGLSLossless
      );
    } catch (e) {
      console.error('Failed to compress pixel data', e);
      return undefined;
    }

    // This preceding Uint32Array represent the offset table that denote multi-frame image
    // decomposition.
    // For single-frame image an empty offset table is still needed.
    const PixelData = { data: [new Uint32Array(0), jpegLSPixelData] as const, VR: 'OB' };

    // JPEGLS lossless transfer syntax.
    return { transferSyntax: '1.2.840.10008.1.2.4.80', PixelData };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return (
      <LocalDicomStoreContext.Provider value={this.state}>
        {children}
      </LocalDicomStoreContext.Provider>
    );
  }
}

const LocalDicomStoreProvider = withContext(
  withContext(LocalDicomStoreProviderImpl, StorageUsageMonitorContext, 'storageUsageMonitor'),
  CodecModuleContext,
  'codecModule'
);

export {
  LocalDicomStoreContext,
  LocalDicomStoreContextShape,
  LocalDicomStoreProvider,
  SAVED_DICOM_FIELDS,
};
