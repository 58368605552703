import _ from 'lodash';

import { getReport } from 'app/utils/reports/reportsGenerator';
import { IReportGenerator } from 'app/interfaces/IReportGenerator';
import { IntlShape } from 'react-intl';
import { Patient } from 'app/interfaces/Patient';
import { ImageForReport } from 'app/utils/reports/types';

export default class ReportGenerator {
  public generate: IReportGenerator['generate'];
  constructor() {
    this.generate = ReportGenerator.generate;
  }

  /** @type {IReportGenerator.generate} */
  static generate(
    intl: IntlShape,
    patient: Patient,
    images: { [id: string]: ImageForReport },
    creationDate: Date
  ) {
    const oldAPIImages = _.map(
      images,
      ({ annotations, predictions, feedback, origin }, imageId) => ({
        id: imageId,
        annotations,
        predictions,
        origin,
        feedback,
      })
    );
    return getReport(patient?.name, patient?.owner_name, oldAPIImages, intl, creationDate);
  }
}
