/* eslint-disable no-continue */
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import * as _ from 'lodash';
import makeMoveHandlerChain from 'app/CornerstoneTools/makeMoveHandlerChain';
import { drawToolStepExplanations } from 'app/CornerstoneTools/utils/toolStepsExplanations';
import {
  addToolState,
  BaseAnnotationTool,
  draw,
  drawCircle,
  drawHandles,
  drawLine,
  EVENTS,
  getNewContext,
  getToolForElement,
  textStyle,
} from 'app/CornerstoneTools';
import compute3PointCircle from 'app/CornerstoneTools/geometry/compute3PointCircle';
import computeDistractionIndex from 'app/CornerstoneTools/DistractionIndexTool/computeDistractionIndex';
import {
  projectPerpendicularToAxis,
  scaleDistanceToCanvas,
} from 'app/CornerstoneTools/pixelToCanvasUtils';
import { getMiddlePoint } from 'app/CornerstoneTools/geometryHelper';
import checkPointNearHandles from 'app/CornerstoneTools/checkPointNearHandles';
import ChainingToolBuilder from 'app/CornerstoneTools/ChainingToolBuilder';

const drawnCircleWithCenter = (
  ctx,
  element,
  center,
  mainRadius,
  mainCircleOptions,
  centerRadius,
  centerOptions
) => {
  if (center && mainRadius) {
    drawCircle(ctx, element, center, mainRadius, mainCircleOptions);
    drawCircle(ctx, element, center, centerRadius, centerOptions);
  }
};

const computeDistractionIndexPoints = (CTF1, CTF2, CTF3, CCA1, CCA2, CCA3) => {
  const { center: femoralHeadCenter, radius: femoralHeadRadius } =
    compute3PointCircle(CTF1, CTF2, CTF3) ?? {};
  const { center: acetabularCavityCenter, radius: acetabularCavityRadius } =
    compute3PointCircle(CCA1, CCA2, CCA3) ?? {};

  const distractionIndex = computeDistractionIndex(
    femoralHeadCenter,
    femoralHeadRadius,
    acetabularCavityCenter
  );
  return {
    femoralHeadCenter,
    femoralHeadRadius,
    acetabularCavityCenter,
    acetabularCavityRadius,
    distractionIndex,
  };
};

export default class DistractionIndexTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'DistractionIndexTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        dicomDataPrinterToolName: 'DicomDataPrinter',
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        getIntl: () => {},
      },
    };

    super(props, defaultProps);
    this.toolBuilder = new ChainingToolBuilder({
      name: this.name,
      createNewMeasurement: this.createNewMeasurement,
      handlesKeys: [
        'CTF11',
        'CTF12',
        'CTF13',
        'CCA11',
        'CCA12',
        'CCA13',
        'CTF21',
        'CTF22',
        'CTF23',
        'CCA21',
        'CCA22',
        'CCA23',
      ],
      maximumToolData: 1,
    });
    this.addNewMeasurement = this.toolBuilder.addNewMeasurement;

    this.pointNearTool = checkPointNearHandles;
  }

  createNewMeasurement = (eventData) => ({
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    handles: {
      CTF11: {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        highlight: true,
        active: true,
      },
    },
  });

  // TODO(REFACTO): All angle and projection could be cached to reduce computation on rerender
  // where tool is not modified.
  updateCachedStats = (_image, element, data) => {
    const { CTF11, CTF12, CTF13, CCA11, CCA12, CCA13, CTF21, CTF22, CTF23, CCA21, CCA22, CCA23 } =
      data.handles;
    const distractionIndexPoints1 = computeDistractionIndexPoints(
      CTF11,
      CTF12,
      CTF13,
      CCA11,
      CCA12,
      CCA13
    );
    const distractionIndexPoints2 = computeDistractionIndexPoints(
      CTF21,
      CTF22,
      CTF23,
      CCA21,
      CCA22,
      CCA23
    );
    let leftIndex;
    let rightIndex;
    if (distractionIndexPoints1.femoralHeadCenter && distractionIndexPoints2.femoralHeadCenter) {
      const femoralHeadCenter1InCanvas = csc.pixelToCanvas(
        element,
        distractionIndexPoints1.femoralHeadCenter
      );
      const femoralHeadCenter2InCanvas = csc.pixelToCanvas(
        element,
        distractionIndexPoints2.femoralHeadCenter
      );
      const isPoints1OnTheLeft = femoralHeadCenter1InCanvas.x <= femoralHeadCenter2InCanvas.x;
      if (isPoints1OnTheLeft) {
        leftIndex = distractionIndexPoints1.distractionIndex;
        rightIndex = distractionIndexPoints2.distractionIndex;
      } else {
        leftIndex = distractionIndexPoints2.distractionIndex;
        rightIndex = distractionIndexPoints1.distractionIndex;
      }
    }
    _.merge(data, { distractionIndexPoints1, distractionIndexPoints2, leftIndex, rightIndex });

    const eventType = EVENTS.MEASUREMENT_MODIFIED;
    const modifiedEventData = {
      toolName: this.name,
      toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData: data,
    };

    csc.triggerEvent(element, eventType, modifiedEventData);
  };

  drawDistractionIndex = (
    element,
    ctx,
    {
      femoralHeadCenter,
      femoralHeadRadius,
      acetabularCavityCenter,
      acetabularCavityRadius,
      distractionIndex,
    },
    { color }
  ) => {
    const mainCircleOptions = { color };
    const circleCenterOptions = { color, fillStyle: 'yellow' };
    const centerRadius = 3;

    if (femoralHeadCenter && acetabularCavityCenter) {
      drawLine(ctx, element, femoralHeadCenter, acetabularCavityCenter, { color });
    }

    drawnCircleWithCenter(
      ctx,
      element,
      femoralHeadCenter,
      scaleDistanceToCanvas(element, femoralHeadRadius),
      mainCircleOptions,
      centerRadius,
      circleCenterOptions
    );
    drawnCircleWithCenter(
      ctx,
      element,
      acetabularCavityCenter,
      scaleDistanceToCanvas(element, acetabularCavityRadius),
      mainCircleOptions,
      centerRadius,
      circleCenterOptions
    );

    if (femoralHeadCenter && acetabularCavityCenter && distractionIndex) {
      const { x, y } = csc.pixelToCanvas(
        element,
        projectPerpendicularToAxis(
          element,
          getMiddlePoint(femoralHeadCenter, acetabularCavityCenter),
          femoralHeadCenter,
          acetabularCavityCenter,
          15
        )
      );
      ctx.save();
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.font = `${textStyle.getFontSize()}px sans-serif`;
      ctx.fillText(distractionIndex.toFixed(2), x, y);
      ctx.restore();
    }
  };

  /**
   *
   * @param {HTMLBaseElement} element
   * @param {CanvasRenderingContext2D} ctx
   * @param {*} data
   */
  drawTool = (element, ctx, data) => {
    const color = cst.toolColors.getColorIfActive(data);
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;

    const handleOptions = {
      color,
      handleRadius,
      drawHandlesIfActive: drawHandlesOnHover,
      hideHandlesIfMoving,
    };

    drawHandles(ctx, { element }, data.handles, handleOptions);

    if (data.invalidated) {
      this.updateCachedStats(undefined, element, data);
    }
    this.drawDistractionIndex(element, ctx, data.distractionIndexPoints1, { color });
    this.drawDistractionIndex(element, ctx, data.distractionIndexPoints2, { color });
  };

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { element } = eventData;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = cst.getToolState(evt.currentTarget, this.name);
    const { getIntl, dicomDataPrinterToolName } = this.configuration;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(
      context,
      /** @param {CanvasRenderingContext2D} ctx */
      (ctx) => {
        const dicomDataPrinterTextOffset =
          getToolForElement(element, dicomDataPrinterToolName)?.dicomTextArea?.topLeft?.y ?? 0;

        drawToolStepExplanations(
          ctx,
          toolData?.data,
          cst.getToolForElement(element, this.name).mode === 'active',
          ['tools.distraction_index.steps.1', 'tools.distraction_index.steps.2'].map(
            (id) => () => getIntl()?.formatMessage({ id })
          ),
          [
            ['CTF11', 'CTF12', 'CTF13', 'CTF21', 'CTF22', 'CTF23'],
            ['CCA11', 'CCA12', 'CCA13', 'CCA21', 'CCA22', 'CCA23'],
          ],
          { x: 5, y: dicomDataPrinterTextOffset + 20 },
          { maxWidth: (2 * eventData.canvasContext.canvas.width) / 5 }
        );
        toolData?.data.forEach((data) => {
          if (this.toolBuilder.checkToolCreationCancelled(element, data)) return;
          this.drawTool(element, ctx, data);
        });
      }
    );
  };
}
