/* eslint-disable camelcase */
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { fromJS } from 'immutable';
import observeStore from 'app/utils/redux/observeStore';
import { TELERADIOLOGY_LOCAL_STORAGE_KEY, UPDATE_TELERADIOLOGY } from './constants';

/** @var {object} teleradiologyInitialState */
const teleradiologyInitialState = () =>
  fromJS({
    type: undefined,
    affiliateId: undefined,
    ...restoreFromLocalStorage(TELERADIOLOGY_LOCAL_STORAGE_KEY),
  });

/**
 *
 * @param {object} state
 * @param {object} action
 * @returns
 */
function teleradiologyReducer(state = teleradiologyInitialState(), action) {
  switch (action.type) {
    case UPDATE_TELERADIOLOGY: {
      const teleradiologyType = action.teleradiology?.type;
      const teleradiologyAffiliateId = action.teleradiology?.affiliateId;

      return state.set('type', teleradiologyType).set('affiliateId', teleradiologyAffiliateId);
    }

    default:
      return state;
  }
}

export const selectTeleradiology = (state) => state.get('teleradiology');
export const selectTeleradiologyAffiliateId = (state) =>
  state.get('teleradiology').get('affiliateId');
export const selectTeleradiologyType = (state) => state.get('teleradiology').get('type');

export const attachTeleradiologyWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectTeleradiology,
    makeSyncReduxWithLocalStorageObserver(TELERADIOLOGY_LOCAL_STORAGE_KEY)
  );
};

export default teleradiologyReducer;
