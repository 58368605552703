import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';

// Sadly cornerstoneTools do not cleanup it's tool data properly on element disable
function clearElement(element) {
  // In case the element use the global state,
  // we cannot delete toolState without a risk to delete another tool data.
  const useGlobalToolState =
    cst.getElementToolStateManager(element) === cst.globalImageIdSpecificToolStateManager;
  if (useGlobalToolState) {
    csc.disable(element);
    return;
  }
  const toolsToRemove = cst.store.state.tools.filter((tool) => tool.element === element);
  toolsToRemove.forEach((tool) => cst.clearToolState(element, tool.name));
  cst.store.state.tools = cst.store.state.tools.filter((tool) => tool.element !== element);
  csc.disable(element);
}

export default clearElement;
