import SyncStatus, { SyncStatusEnum } from 'app/components/SyncStatus';
import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';
import React from 'react';

import { FormattedMessage } from 'react-intl';

const IMAGE_UPLOAD_STATUS_TEXTS = {
  sync: <FormattedMessage id="teleradiology.uploadStatus.sync" />,
  ongoing: <FormattedMessage id="teleradiology.uploadStatus.ongoing" />,
  error_sync: <FormattedMessage id="teleradiology.uploadStatus.error" />,
  not_sync: <FormattedMessage id="teleradiology.uploadStatus.notSync" />,
};

const statusIcons = {
  sync: <TeleradiologyIcon color="green" size="large" inverted />,
  ongoing: <TeleradiologyIcon color="grey" size="large" inverted />,
  error_sync: <TeleradiologyIcon color="red" size="large" inverted />,
  not_sync: <TeleradiologyIcon color="orange" size="large" inverted />,
};

type ImageUploadStatusProps = {
  status: SyncStatusEnum;
};
export default function ImageUploadStatus({ status }: ImageUploadStatusProps) {
  return (
    <SyncStatus status={status} statusIcons={statusIcons} statusTexts={IMAGE_UPLOAD_STATUS_TEXTS} />
  );
}
