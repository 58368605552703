import 'app/styles/style.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import {
  isPACSConfigurationDisabled,
  selectPACSConfiguration,
} from 'app/redux/PACSConfiguration/reducer';
import RadioImageData from 'app/utils/RadioImageData';
import { Icon, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const PACSSyncStatus = React.memo(({ images }) => {
  const pacsConfiguration = useSelector(selectPACSConfiguration);

  const isPACSDisabled = isPACSConfigurationDisabled(pacsConfiguration);
  if (isPACSDisabled) return null;

  const pacsCompatibleImages = images.filter(RadioImageData.isCompatibleWithPACS);

  if (pacsCompatibleImages.length === 0) return null;

  const isSyncWithPACS = !pacsCompatibleImages.some(
    (image) => !image?.image_metadata?.PACS?.isSync
  );

  return (
    <Popup
      trigger={
        <div>
          <Icon
            className="picoxia-fix"
            color={isSyncWithPACS ? 'green' : 'orange'}
            name="cloud upload"
          />
        </div>
      }
      content={
        <FormattedMessage id={`monitoring.actions.pacs.${isSyncWithPACS ? 'sync' : 'not_sync'}`} />
      }
      position="left center"
    />
  );
});

export default PACSSyncStatus;
