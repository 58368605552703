import _ from 'lodash';

const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const TEST_TODAY = new Date(2023, 7, 8);

export const TEAM1_MEMBERS = [
  {
    mail: 'mail@justinetrupheme.ac.kr',
    phoneNumber: '1234567890',
    role: 'member',
  },
  {
    mail: 'team1_admin@mail.com',
    phoneNumber: '2345678901',
    role: 'admin',
  },
];
export const DEFAULT_TEAMS = [
  {
    name: 'MyTeam1',
    ia_licenses: [
      {
        _id: 'pack-id',
        name: 'pack_name',
        start: new Date(TEST_TODAY.getTime() - 20 * DAY_IN_MS),
        end: new Date(TEST_TODAY.getTime() + 300 * DAY_IN_MS),
        total_count: 20,
      },
    ],
    referents_mails: ['justinetrupheme@cau.ac.kr'],
    unlimited_use: false,
    currently_in_use: 16,
    members: TEAM1_MEMBERS,
  },
];

export default class FakeAdminStore {
  constructor({ teams = DEFAULT_TEAMS, userStore = undefined } = {}) {
    /** @type {UserStore} */
    this.userStore = userStore;

    this.teams = _.cloneDeep(teams);
  }

  getTeams = async () =>
    _.cloneDeep(this.teams).map(({ members, ...teamResponse }) => teamResponse);

  addTeam = async (teamName) => {
    const team = this.teams.find((e) => e.name === teamName);
    if (team) throw new Error(`Team : ${teamName} already exists\n`);
    else {
      return this.teams.push({
        name: teamName,
        ia_licenses: [],
        referents_mails: [],
        unlimited_use: false,
      });
    }
  };

  getTeamMembers = async (teamName) =>
    _.cloneDeep(this.teams.find((e) => e.name === teamName).members);

  addTeamMember = async (teamName, mail) => {
    const team = this.teams.find((e) => e.name === teamName);
    if (!team) throw new Error(`Team : ${teamName} does not exist\n`);

    if (this.userStore) {
      return this.userStore.getUsers({ partialEmail: mail }).then(({ users }) => {
        const newUser = {
          mail: users[0].mail,
          role: 'member',
          phoneNumber: users[0].address?.phoneNUmber,
        };
        team.members = [...team.members, newUser];
      });
    }

    team.members = [...team.members, { mail, role: 'member' }];

    return undefined;
  };

  updateTeamMember = async (teamName, mail, role) => {
    const team = this.teams.find((e) => e.name === teamName);
    const member = team.members.find((e) => e.mail === mail);
    member.role = role;
  };

  removeTeamMember = async (teamName, email) => {
    const team = this.teams.find((e) => e.name === teamName);
    team.members = team.members.filter((e) => e.mail !== email);
  };

  updateTeamReferents = async (teamName, newReferents) => {
    const team = this.teams.find((e) => e.name === teamName);
    team.referents_mails = _.cloneDeep(newReferents);
  };

  updateTeamSponsors = async (teamName, sponsors) => {
    const team = this.teams.find((e) => e.name === teamName);
    team.sponsors = _.cloneDeep(sponsors);
  };

  addIALicensePack = async (teamName, packName, start, end, count) => {
    const team = this.teams.find((e) => e.name === teamName);

    if (!team) throw new Error(`Team : ${teamName} does not exists\n`);

    team.ia_licenses.push({
      _id: `${packName}--id`,
      name: packName,
      start,
      end,
      total_count: count,
    });

    return _.cloneDeep(team.ia_licenses.at(-1));
  };
}
