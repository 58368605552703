const getLanguage = () => navigator.language.split(/[-_]/)[0];

const parseQueryString = () => {
  const queryString = window.location.search;
  const objURL = {};

  queryString.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    objURL[$1] = $3;
  });
  return objURL;
};

const isSmallScreen = () => window.innerWidth <= 600;

const isNotLargeScreen = () => window.innerWidth <= 900;

const isLandscape = () =>
  //  return window.innerHeight < window.innerWidth;
  window.matchMedia('(orientation: landscape)').matches && window.innerWidth <= 900;
window.onorientationchange = function (event) {
  //  console.log(window.location.href)
  //  if(window.location.href === "/") {
  document.location.reload();
  //  }
  //  console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
};

const getBrowser = () => {
  // Chrome 1 - 71
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome) {
    return 'Chrome';
  }

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  if (isIE) {
    return 'IE';
  }

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;
  if (isEdge) {
    return 'Edge';
  }

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';
  if (isFirefox) {
    return 'Firefox';
  }

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));
  if (isSafari) {
    return 'Safari';
  }

  // Opera 8.0+
  const isOpera =
    (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  if (isOpera) {
    return 'Opera';
  }

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS;
  if (isBlink) {
    return 'Blink';
  }
  return 'Unknown';
};

const checkOverflowY = (element) => element.offsetHeight < element.scrollHeight;

const checkOverflowX = (element) => element.offsetWidth < element.scrollWidth;

const checkOverflow = (element) => checkOverflowY(element) || checkOverflowX(element);

export {
  isSmallScreen,
  getBrowser,
  parseQueryString,
  getLanguage,
  isLandscape,
  isNotLargeScreen,
  checkOverflowY,
  checkOverflowX,
  checkOverflow,
};
