import ApiCalls from 'app/utils/apiCalls';
import toastifyPromise from 'app/utils/toastifyPromise';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox } from 'semantic-ui-react';

/**
 * Checkbox to Enable / Disable Teleradiology
 * @param {User} user
 * @param [User] onChangePermission
 * @returns {JSX.Element}
 */
function TeleradiologyCheckboxPermission({ user, onChangePermission }) {
  const intl = useIntl();
  const [isRequestSending, setIsRequestSending] = useState(false);

  const { permissions } = user;
  const hasTeleradiology = permissions?.includes('teleradiology') ?? false;
  const label = hasTeleradiology
    ? intl.formatMessage({ id: 'general.yes' })
    : intl.formatMessage({ id: 'general.no' });

  if (user.mail_confirmed === false) {
    return null;
  }

  const updateUserTeleradiologyPermission = (userMail, permission) => {
    setIsRequestSending(true);

    const promise = ApiCalls.setUserTeleradiologyPermission(userMail, permission);
    toastifyPromise('user-update', promise);
    promise
      .then(() => {
        setIsRequestSending(false);
        onChangePermission();
      })
      .catch((error) => {
        setIsRequestSending(false);
        console.error('server-api-error', error);
      });
  };

  return (
    <Checkbox
      toggle
      checked={hasTeleradiology}
      label={label}
      onChange={() => updateUserTeleradiologyPermission(user.mail, !hasTeleradiology)}
    />
  );
}

export default TeleradiologyCheckboxPermission;
