import React from 'react';
import './styles.scss';
import AppHelmet from 'app/components/AppHelmet';
import { Container, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import TermsOfUseContent from './content';

/**
 * TermsOfUsePage du site
 * @returns {JSX.Element}
 */
function TermsOfUsePage() {
  return (
    <div className="terms-of-use-page">
      <AppHelmet
        titleIntlID="terms-of-use.title"
        descriptionIntlID="terms-of-use.page-description"
      />
      <Container textAlign="justified">
        <Header as="h1">
          <FormattedMessage id="terms-of-use.title" />
          <Header.Subheader>
            <FormattedMessage id="terms-of-use.sub-title" />
          </Header.Subheader>
        </Header>
        <TermsOfUseContent />
      </Container>
    </div>
  );
}

export default TermsOfUsePage;
