/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {String} text
 * @param {Number|undefined} maxWidth
 * @returns
 */
export default (ctx, text, maxWidth = undefined) => {
  if (maxWidth === undefined || text === undefined) return [text];
  const splittedText = text.split(' ');

  const lines = [splittedText[0]];

  splittedText.slice(1).forEach((word) => {
    const lastLineText = lines[lines.length - 1];
    const textWithWord = `${lastLineText} ${word}`;

    if (ctx.measureText(textWithWord).width <= maxWidth) {
      lines[lines.length - 1] = textWithWord;
    } else {
      lines.push(word);
    }
  });
  return lines;
};
