import cornerstone from 'cornerstone-core';
import Hammer from 'hammerjs';
import cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';

import dicomParser from 'dicom-parser';

const configureCornerstoneTools = () => {
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.disableLogger();

  // Required to respect `MultiToolUsageContract`
  // This `isToolLocked` interfere with all MouseEvent during a chained tool creation
  Object.defineProperty(cornerstoneTools.store.state, 'isToolLocked', {
    get: () => false,
    set: () => {},
  });
};

const configureImageLoaders = (cornerstoneWADOImageLoader, cornerstoneWebImageLoader) => {
  configureCornerstoneTools();
  if (!cornerstoneWebImageLoader.initialized) {
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneWebImageLoader.configure();

    cornerstoneWebImageLoader.initialized = true;
  }

  if (!cornerstoneWADOImageLoader.initialized) {
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      maxWebWorkers: navigator.hardwareConcurrency || 1,
      startWebWorkersOnDemand: false,
      webWorkerTaskPaths: [],
      taskConfiguration: {
        decodeTask: {
          loadCodecsOnStartup: true,
          initializeCodecsOnStartup: false,
          usePDFJS: false,
          strict: true,
        },
      },
    });

    cornerstoneWADOImageLoader.initialized = true;
  }
};

export { configureCornerstoneTools };
export default configureImageLoaders;
