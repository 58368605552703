import React, { useRef } from 'react';
import Select from 'react-select';

import ToolsDropdownIndicator from './ToolsDropdownIndicator';
import { REACT_SELECT_TOOL_BAR_STYLE } from './utils';

const ToolWithOptions = ({ content, actions, ...selectOptions }) => {
  const selectRef = useRef(null);
  const blurOut = () => selectRef.current.blur();

  const ContentDisplay = ({ children }) => (
    <div>
      {content}
      <div style={{ display: 'hidden', position: 'fixed', top: '-10000px' }}>{children}</div>
    </div>
  );

  const ActionDisplay = ({ data: { content: actionContent, action } }) => (
    <button
      type="button"
      className="picoxia tool-button tool-with-options__action"
      onClick={() => {
        action();
        blurOut();
      }}
    >
      {actionContent}
    </button>
  );

  return (
    <Select
      ref={selectRef}
      classNamePrefix="tool-with-options__select"
      options={actions}
      components={{
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        ValueContainer: ContentDisplay,
        Option: ActionDisplay,
      }}
      styles={REACT_SELECT_TOOL_BAR_STYLE}
      filterOption={() => true}
      isClearable={false}
      openMenuOnClick={false}
      {...selectOptions}
    />
  );
};

export default ToolWithOptions;
