export default function getPixelValues(pixelData: Uint8Array | Uint16Array | Uint32Array) {
  let minPixelValue = Number.MAX_VALUE;
  let maxPixelValue = Number.MIN_VALUE;
  const len = pixelData.length;

  for (let i = 0; i < len; i += 1) {
    const pixel = pixelData[i];
    if (pixel < minPixelValue) minPixelValue = pixel;
    if (pixel > maxPixelValue) maxPixelValue = pixel;
  }

  return {
    minPixelValue,
    maxPixelValue,
  };
}
