import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { AssociationParameters } from 'app/interfaces/PACSCommunication';
import { SET_PACS_CONFIGURATION, SET_PACS_ENABLED } from 'app/redux/PACSConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import { fromJS, Iterable } from 'immutable';
import _ from 'lodash';
import { Store } from 'redux';

export const PACS_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.PACSConfiguration';

export type PACSConfigurationState = AssociationParameters & { enabled?: boolean };

const initPACSConfigurationState = () =>
  fromJS(restoreFromLocalStorage(PACS_CONFIGURATION_LOCAL_STORAGE_KEY, {}));

const PACSConfigurationReducer = (state = initPACSConfigurationState(), action: any) => {
  switch (action.type) {
    case SET_PACS_CONFIGURATION:
      return state.clear().merge(action.pacsConfiguration);
    case SET_PACS_ENABLED:
      return state.set('enabled', action.enabled);
    default:
      return state;
  }
};

export const selectPACSConfiguration = (state: Iterable<string, Iterable<unknown, unknown>>) =>
  state.get('PACSConfiguration');

export const isPACSConfigurationDisabled = (
  PACSConfiguration: Iterable<unknown, unknown> | PACSConfigurationState
) => {
  const PACSConfigurationJS = Iterable.isIterable(PACSConfiguration)
    ? (PACSConfiguration as Iterable<unknown, unknown>).toJS()
    : PACSConfiguration;

  return (
    PACSConfigurationJS === undefined ||
    _.isEmpty(PACSConfigurationJS) ||
    !PACSConfigurationJS.enabled
  );
};

export const attachPACSConfigurationWithLocalStorageSynchronizationObserver = <S>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectPACSConfiguration,
    makeSyncReduxWithLocalStorageObserver(PACS_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default PACSConfigurationReducer;
