import { DEFAULT_SPECIE } from 'app/constants/species';

export const AUTOMATIC_IMPORT_TIMEOUT_DELAY_MS = 10 * 60 * 1000;
export const ACQUISITION_COUNTDOWN_DURATION_S = 5;
export const DEFAULT_ANIMAL = { specie: DEFAULT_SPECIE };
Object.freeze(DEFAULT_ANIMAL);
export const DEFAULT_ACQUISITION_CONSTANTS = {
  thickness: 0,
  kV: 0,
  mA: 0,
  s: 0,
};
Object.freeze(DEFAULT_ACQUISITION_CONSTANTS);

export const UPPER_TOOLS_BAR_TOOLS_LISTS = [
  ['LeftMarker', 'RightMarker', 'TextMarker', 'ArrowAnnotate'],
  ['ClockwiseRotation', 'CounterClockwiseRotation'],
  ['VerticalFlip', 'HorizontalFlip'],
  ['Magnify', 'ZoomMouseWheel', 'FullSizeConfiguration', 'RealSizeMeasurementCalibration', 'Crop'],
  ['NegativeColor', 'WWWC', 'Processing'],
  ['Negato', 'Eraser', 'Pan', 'FullScreen', 'PicoxiaAnalysis'],
];
Object.freeze(UPPER_TOOLS_BAR_TOOLS_LISTS);
