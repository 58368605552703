import _ from 'lodash';
import PmsExporter, { ImagesType, PMSConfigurationType, StudyType } from './PmsExporter';
import { Patient } from 'app/interfaces/Patient';

export default class VetoPartnerExporter extends PmsExporter {
  exportDataToPMS = async (
    { exportDirectory }: PMSConfigurationType = {},
    currentStudy: StudyType,
    patient: Patient,
    images: ImagesType = []
  ): Promise<PromiseSettledResult<void>[]> => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) {
      console.warn('exportDirectory is not configured cannot export data to PMS');
      return undefined;
    }

    const pmsId: string = currentStudy?.pms_id;
    if (!pmsId) {
      console.warn('Patient pms_id is required to export data to PMS');
      return undefined;
    }

    return Promise.allSettled([
      ...this.exportImages(exportDirectory, pmsId, images),
      this.exportReportPdfWithElectron(exportDirectory, pmsId, currentStudy, patient, images),
    ]);
    // this.exportLink(exportDirectory, pmsId, currentStudy.ID);
    // this.exportReportDocx(exportDirectory, pmsId, currentStudy);
    // this.exportReportPdf(exportDirectory, pmsId, currentStudy, images);
  };
}
