import React, { BaseSyntheticEvent, useContext, useEffect, useRef } from 'react';
import { DisplayViewport, DisplayableImageData, ImageAnnotations } from 'app/interfaces/Image';
import { ImageRenderer } from 'app/interfaces/ImageRenderer';
import { ImageDisplayableMetadata } from 'app/interfaces/Image';
import { ToolsStates } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';
import MainImageRendererFactoryContext from 'app/providers/ImageRenderer/MainImageRendererFactory/context';

type MainImageRendererProps = {
  imageId?: string;
  image: DisplayableImageData;
  toolsStates?: ToolsStates;
  viewport?: DisplayViewport;
  annotations?: ImageAnnotations;
  metadata?: ImageDisplayableMetadata;
  onRendererRef?: (ref: ImageRenderer) => void;
};

const preventDefault = (e: BaseSyntheticEvent) => e.preventDefault();

export function MainImageRenderer({
  imageId,
  image,
  viewport,
  annotations,
  toolsStates,
  metadata,
  onRendererRef,
}: MainImageRendererProps) {
  const renderer = useRef<ImageRenderer>();
  const imageRendererFactory = useContext(MainImageRendererFactoryContext);
  const currentRenderer = renderer.current;

  const onRef = (ref: HTMLElement) => {
    if (!ref || renderer.current?.element === ref) return;

    renderer.current = imageRendererFactory?.create(ref);
    onRendererRef?.(renderer.current);
  };

  useEffect(() => {
    return () => currentRenderer?.destroy();
  }, [currentRenderer]);

  useEffect(() => {
    if (!image) return;
    renderer.current?.displayImage(imageId, image, {
      toolsStates,
      viewport,
      annotations,
      metadata,
    });
  }, [currentRenderer, imageId, image, toolsStates, viewport, annotations, metadata]);

  return (
    <div
      ref={onRef}
      data-testid="image-render-area"
      onContextMenu={preventDefault}
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
    >
      <canvas className="cornerstone-canvas" style={{ objectFit: 'none' }} />
    </div>
  );
}
