import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
// import { Dropdown } from 'semantic-ui-react';

import ToolsDropdownIndicator from './ToolsDropdownIndicator';
import { REACT_SELECT_TOOL_BAR_STYLE } from './utils';
import { selectAiOnlyConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import { connect } from 'react-redux';
import Immutable from 'immutable';

const CUSTOM_STYLES = {
  ...REACT_SELECT_TOOL_BAR_STYLE,
  option: (provided, state) => {
    const style = {
      ...provided,
      padding: '3px 3px',
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    };
    if (state.isFocused) {
      style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
    }
    if (state.isSelected) {
      style.backgroundColor = 'rgba(255, 255, 255, 0.3)';
      style.paddingBottom = '1px';
      style.borderBottom = '2px solid lightblue';
    }
    return style;
  },
};

/**
 * Window Width Window Center Group gather to tools linked to contrast with the active tool
 * being displayed and the options tools and function in a dropdown menu.
 */
class ProcessingDropdownButton extends React.PureComponent {
  getValueContainer = ({ children }) => (
    <button className="picoxia tool-button" type="button">
      <i className="svg-icon processing" />
      <div style={{ display: 'hidden', position: 'fixed', top: '-10000px' }}>{children}</div>
    </button>
  );

  render() {
    const { processingList, intl, isShown, study, aiOnlyConfiguration } = this.props;
    /** AI ONLY */
    /** @type {boolean} */
    const aiOnlyEnabled = aiOnlyConfiguration.get('enabled');

    if (!isShown || aiOnlyEnabled) return null;

    const options = _.map(processingList, ({ label, callback, isCurrentProcessing }) => ({
      label,
      value: label,
      callback,
      isCurrentProcessing,
    }));

    return (
      <div title={intl.formatMessage({ id: 'tools.processing.tooltip' })}>
        <Select
          classNamePrefix="processing-list-button"
          options={options}
          components={{
            DropdownIndicator: ToolsDropdownIndicator,
            IndicatorSeparator: null,
            ValueContainer: this.getValueContainer,
          }}
          styles={CUSTOM_STYLES}
          onChange={({ callback }) => callback(study)}
          isOptionSelected={({ isCurrentProcessing }) => isCurrentProcessing}
          filterOption={() => true}
          isClearable={false}
          openMenuOnClick
        />
      </div>
    );
  }
}

ProcessingDropdownButton.propTypes = {
  intl: pt.shape().isRequired,
  isShown: pt.bool,
  study: pt.shape({}).isRequired,
  processingList: pt.objectOf(
    pt.shape({
      label: pt.string.isRequired,
      callback: pt.func.isRequired,
      isCurrentProcessing: pt.bool,
    })
  ),
  aiOnlyConfiguration: pt.instanceOf(Immutable.Map).isRequired,
};

ProcessingDropdownButton.defaultProps = {
  processingList: {},
  isShown: false,
};

/**
 * Redux
 * @param {object} state
 * @returns {object}
 */
function mapStateToProps(state) {
  return {
    aiOnlyConfiguration: selectAiOnlyConfiguration(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProcessingDropdownButton));
