import { browserHistory } from 'react-router';
import { translationMessages, appLocales } from '../i18n';

const getNavigatorLanguage = () => navigator.language.split(/[-_]/)[0];

const isLanguageSupported = (language) => appLocales.indexOf(language) > -1;

const isFirstParamALocale = (pathname) => {
  const splittedURL = pathname.split('/');
  const firstParam = splittedURL[1];
  return firstParam.length === 2;
};

const getInitLanguage = () => {
  const { pathname } = window.location;

  const splittedURL = pathname.split('/');
  const firstPartURL = splittedURL[1];
  if (isLanguageSupported(firstPartURL)) {
    return firstPartURL;
  }
  const localStorageLanguage = localStorage.getItem('language');
  if (isLanguageSupported(localStorageLanguage)) {
    return localStorageLanguage;
  }
  const navigatorLanguage = getNavigatorLanguage();
  if (isLanguageSupported(navigatorLanguage)) {
    return navigatorLanguage;
  }
  return 'en';
};

const getInnerURL = (pathname) => {
  const firstParamIsALocale = isFirstParamALocale(pathname);
  if (firstParamIsALocale) {
    return pathname.substring(4);
  }
  return pathname.substring(1);
};

const getURLRedirectionWithNewLocale = (location, locale) => {
  let URLToRedirectTo = `/${locale}/`;

  const { pathname } = location;
  const innerURL = getInnerURL(pathname);
  URLToRedirectTo = URLToRedirectTo.concat(innerURL);

  const { search } = location;
  URLToRedirectTo = URLToRedirectTo.concat(search);

  const { hash } = location;
  URLToRedirectTo = URLToRedirectTo.concat(hash);

  return URLToRedirectTo;
};

const redirectToURLWithNewLocale = (location, locale) => {
  browserHistory.push(getURLRedirectionWithNewLocale(location, locale));
};

const translate = (messageId) => {
  let language = getInitLanguage();
  if (
    !translationMessages.hasOwnProperty(language) ||
    !translationMessages[language].hasOwnProperty(messageId)
  ) {
    language = 'en';
  }
  if (!translationMessages[language].hasOwnProperty(messageId)) {
    return messageId;
  }
  if (typeof translationMessages[language][messageId] === 'undefined') {
    return '';
  }
  return translationMessages[language][messageId];
};

const hasTranslation = (messageId) => {
  return translate(messageId) !== messageId;
};

export const getRestLocales = (locale) => appLocales.filter((l) => l !== locale);

const makeHelmetHreflangLinks = (locale) => {
  const indexOfLocale = appLocales.indexOf(locale);
  let locales = [];
  if (indexOfLocale > -1) {
    locales = getRestLocales(locale);
  }

  const innerURL = getInnerURL(window.location.pathname);

  const arrayOfHreflangs = locales.map((existingLocale) => ({
    rel: 'alternate',
    hreflang: existingLocale,
    href: `https://picoxia.com/${existingLocale}/${innerURL}`,
  }));

  return arrayOfHreflangs;
};

export {
  isFirstParamALocale,
  translate,
  hasTranslation,
  getInitLanguage,
  getInnerURL,
  isLanguageSupported,
  getURLRedirectionWithNewLocale,
  redirectToURLWithNewLocale,
  makeHelmetHreflangLinks,
};
