import * as _ from 'lodash';
import { textStyle } from 'cornerstone-tools';
import writeStep from 'app/CornerstoneTools/utils/toolStepsExplanations/writeStep';
import wrapTextToWidth from 'app/utils/canvas/wrapTextToWidth';

/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {Object[]} toolData
 * @param {boolean} isToolActive
 * @param {Function[]} getMessagesBySteps
 * @param {String[][]|String[]} handlesBySteps
 * @param {Number} origin.x
 * @param {Number} origin.y
 * @param {Number} options.fontSize
 * @param {Number} options.padding
 * @param {Number} options.maxWidth
 * @returns
 */
const drawToolStepExplanations = (
  ctx,
  toolData,
  isToolActive,
  getMessagesBySteps,
  handlesBySteps = [],
  origin = { x: 10, y: 20 },
  { fontSize = textStyle.getFontSize(), interline = 5, padding = 5, maxWidth = undefined } = {}
) => {
  if (!isToolActive) return;

  if (toolData?.length > 0 && _.every(toolData, { completed: true })) return;
  let activeStepIndex = 0;

  if (toolData?.length > 0) {
    const movingHandle = _.findKey(toolData[0].handles, { moving: true });
    if (movingHandle) {
      activeStepIndex = _.findIndex(handlesBySteps, (handles) => {
        if (typeof handles === 'string') {
          return handles === movingHandle;
        }
        if (Array.isArray(handles)) {
          return handles.includes(movingHandle);
        }
        return false;
      });
    }
  }
  ctx.save();
  // eslint-disable-next-line no-param-reassign
  ctx.font = `${fontSize}px sans-serif`;

  let maxTextWidth = 0;
  const stepsLines = [];

  getMessagesBySteps.forEach((getMessage) => {
    const stepLines = wrapTextToWidth(ctx, getMessage(), maxWidth);
    stepsLines.push(stepLines);
    maxTextWidth = Math.max(maxTextWidth, ...stepLines.map((line) => ctx.measureText(line).width));
  });

  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = 'black';
  ctx.globalAlpha = 0.5;
  const numberOfLines = _.sum(stepsLines.map((lines) => lines.length));
  ctx.fillRect(
    origin.x,
    origin.y,
    maxTextWidth + 2 * padding,
    2 * padding + numberOfLines * fontSize + (numberOfLines - 1) * interline
  );

  ctx.globalAlpha = 1;

  let yOffset = padding;
  stepsLines.forEach((stepLines, index) => {
    stepLines.forEach((line) => {
      writeStep(ctx, line, origin.x + padding, origin.y + yOffset, index === activeStepIndex);
      yOffset += fontSize + interline;
    });
  });

  ctx.restore();
};

export { drawToolStepExplanations };
