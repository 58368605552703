import React from 'react';
import * as pt from 'prop-types';
import { injectIntl } from 'react-intl';

import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';
import ToolType from '../../types/ToolType';

const VHS_REGIONS = ['thorax_profil', 'entirebody_profil'];
Object.freeze(VHS_REGIONS);

function VHSButton(props) {
  return (
    <ActivatableToolButton
      content={<i className="svg-icon vhs" />}
      tooltipId="tools.VHS.tooltip"
      disabledState="disabled"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

VHSButton.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  produceImageToolsList: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape({
    type: pt.string,
    vhs: pt.shape(),
  }),
};

VHSButton.defaultProps = {
  focusedElement: null,
  predictions: {},
};

export default injectIntl(VHSButton);
