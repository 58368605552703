import path from 'app/native/node/path';
import { FRONT_END_PATH } from 'app/redux/global/constants';
import _ from 'lodash';
import PmsExporter, {
  ImagesType,
  PMSConfigurationType,
  PMS_EXPORT_DEFAULT_JPEG_PARAMS,
  StudyType,
} from './PmsExporter';
import ApiCalls from 'app/utils/apiCalls';
import generatePDFFromHtml from 'app/pms/exporter/generatePDFFromHtml';
import { getReportHtml } from 'app/utils/pdfUtil';
import { Patient } from 'app/interfaces/Patient';

export default class GmVetExporter extends PmsExporter {
  exportLink = async (exportDirectory: string, pmsId: string, studyId: string) => {
    this.fileSystemPromises.writeFile(
      path().join(exportDirectory, `${pmsId}_study_link.txt`),
      FRONT_END_PATH + '/' + this.intl.locale + '/online?study_ids[]=' + studyId
    );
  };

  // exportReportDocx = async (exportDirectory: string, pmsId: string, currentStudy: StudyType) => {
  //   const transformBlobToArrayBuffer = async (blob: Blob) => blob.arrayBuffer();
  //   generateDocxBlobFromStudy(currentStudy, this.intl).then(async (docxBlob) => {
  //     const arrayBuffer = await transformBlobToArrayBuffer(docxBlob);
  //     const pathToLocalFolderWithFileName = path().join(exportDirectory, `${pmsId}_report.docx`);
  //     this.fileSystemPromises.writeFile(pathToLocalFolderWithFileName, Buffer.from(arrayBuffer));
  //   });
  // };

  exportDataToPMS = async (
    { exportDirectory }: PMSConfigurationType = {},
    currentStudy: StudyType,
    patient: Patient,
    images: ImagesType = []
  ): Promise<PromiseSettledResult<void>[]> => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) {
      console.warn('exportDirectory is not configured cannot export data to PMS');
      return undefined;
    }

    const pmsId: string = currentStudy?.pms_id;
    if (!pmsId) {
      console.warn('Patient pms_id is required to export data to PMS');
      return undefined;
    }

    return Promise.allSettled([
      ...this.exportImages(exportDirectory, pmsId, images),
      this.exportReportPdfWithElectron(exportDirectory, pmsId, currentStudy, patient, images),
    ]);
    // this.exportLink(exportDirectory, pmsId, currentStudy.ID);
    // this.exportReportDocx(exportDirectory, pmsId, currentStudy);
    // this.exportReportPdf(exportDirectory, pmsId, currentStudy, images);
  };
}
