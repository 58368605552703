/*
 *
 * LanguageProvider actions
 *
 */

import { CHANGE_LOCALE, USER_SET_LANGUAGE } from './constants';

export function userSetLanguage(languageLocale) {
  return {
    type: USER_SET_LANGUAGE,
    locale: languageLocale,
  };
}

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}
