import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import * as pt from 'prop-types';

const ButtonGroup = styled.div`
  pointer-events: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: transparent;
`;

const Button = styled.button`
  display: flex;
  font-weight: 700;
  font-size: 1.3em;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  color: white;
  border-radius: 5px;
`;

const ToolValidationOverlay = ({ onConfirm, onCancel }) => (
  <ButtonGroup>
    <Button className="picoxia" backgroundColor="#27ae60" onClick={onConfirm}>
      <Icon name="check" />
      <FormattedMessage id="tools.crop.button.validate" />
    </Button>
    <Button className="picoxia" backgroundColor="#605e5e" onClick={onCancel}>
      <Icon name="cancel" />
      <FormattedMessage id="tools.crop.button.cancel" />
    </Button>
  </ButtonGroup>
);

ToolValidationOverlay.propTypes = {
  onConfirm: pt.func.isRequired,
  onCancel: pt.func.isRequired,
};

export default ToolValidationOverlay;
