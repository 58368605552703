import React from 'react';
import { Image, Card } from 'semantic-ui-react';

class HelpMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
  }

  getMessageClass = () => {
    let className = 'ui message';
    if (this.props.iconClass) {
      className += ' icon';
    }
    if (this.props.color) {
      className += ` ${this.props.color}`;
    }
    return className;
  };

  getIcon = () => this.props.iconClass || null;

  hide = () => {
    this.setState({ hidden: true });
  };

  displayHideButton = () => {
    if (this.props.closable) {
      return (
        <i
          className="ui x icon link"
          onClick={this.hide}
          style={{
            position: 'absolute',
            top: '5px',
            right: '2px',
            fontSize: '1.2em',
            zIndex: '100',
          }}
        />
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.props.content) {
      return this.props.content;
    }
    return (
      <div>
        <div className="header">{this.props.headerText}</div>
        {this.props.paragraphText}
        {this.props.additionalContent}
      </div>
    );
  };

  render() {
    if (this.state.hidden) {
      return null;
    }
    return (
      <div className="container" style={this.props.style}>
        <div className={this.getMessageClass()}>
          <i className={this.getIcon()} />
          <div className="content">{this.renderContent()}</div>
        </div>
        {this.displayHideButton()}
      </div>
    );
  }
}

export default HelpMessage;
