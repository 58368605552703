/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as pt from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import selectOnFocus from 'app/utils/dom/selectOnFocus';
import NumberInput from 'app/components/NumberInput';

class ConstantSelector extends React.PureComponent {
  onChange = (evt, value) => {
    const { onChange, constantName } = this.props;
    onChange(constantName, parseFloat(value));
  };

  onDropdownChange = (_event, data) => {
    const { onChange, constantName } = this.props;
    onChange(constantName, parseFloat(data.value));
  };

  onFocus = (event) => {
    const { onFocus } = this.props;
    selectOnFocus(event);
    onFocus(event);
  };

  renderValueField = () => {
    const { value, min, max, disabled, step, type, onBlur } = this.props;
    const testId = this.props['data-testid'];
    const inputMode = parseFloat(step) >= 1 ? 'numeric' : 'decimal';

    if (disabled) {
      return <span data-testid={testId}>{value}</span>;
    }
    if (type === 'dropdown') {
      const options = _.times((max - min) / parseInt(step, 10) + 1, (index) => {
        const integerValue = min + step * index;
        return {
          key: integerValue,
          value: integerValue,
          text: integerValue,
        };
      });

      return (
        <Dropdown
          data-testid={testId}
          pointing="top"
          upward
          options={options}
          value={value}
          onChange={this.onDropdownChange}
          search
          inputMode={inputMode}
          searchInput={{ inputMode }}
        />
      );
    }

    return (
      <NumberInput
        data-testid={testId}
        inputMode={inputMode}
        type="number"
        lang="en"
        value={value}
        onChange={this.onChange}
        min={min}
        max={max}
        step={step}
        onFocus={this.onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    );
  };

  render() {
    const { constantName, className, style, disabled } = this.props;

    return (
      <div className={`constant-selector ${className} ${disabled && 'disabled'}`} style={style}>
        <div className="constant-selector__title">
          <FormattedMessage id={`xray_area.constant.${constantName}`} />
        </div>
        <div className="constant-selector__value flex center">
          <div>{this.renderValueField()}</div>
        </div>
      </div>
    );
  }
}

ConstantSelector.propTypes = {
  constantName: pt.string.isRequired,
  value: pt.number.isRequired,
  onChange: pt.func.isRequired,
  onFocus: pt.func,
  onBlur: pt.func,
  className: pt.string,
  style: pt.shape({}),
  type: pt.oneOf(['dropdown', 'number']),
  min: pt.number,
  max: pt.number,
  disabled: pt.bool,
  step: pt.string,
};

ConstantSelector.defaultProps = {
  className: '',
  onFocus: () => {},
  onBlur: () => {},
  style: undefined,
  min: undefined,
  max: undefined,
  disabled: false,
  type: 'number',
  step: '1',
};

export default ConstantSelector;
