import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import './styles.scss';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import { List } from 'semantic-ui-react';

/**
 * French Terms Of Use
 * @returns {JSX.Element}
 */
function RenderFrenchText() {
  return (
    <>
      <section id="introduction">
        <p>
          Les présentes conditions générales d'utilisation (dites «&nbsp;CGU&nbsp;») ont pour objet
          l'encadrement juridique des modalités de mise à disposition du site et des services
          par&nbsp;
          <a href="https://picoxia.com/fr/">picoxia.com</a> et de définir les conditions d'accès et
          d'utilisation des services par «&nbsp;l'Utilisateur&nbsp;».
        </p>
        <p>
          Les présentes CGU sont accessibles sur le site à la rubrique «&nbsp;
          <IntlCrawlableLink url="/terms-of-use">CGU</IntlCrawlableLink>&nbsp;».
        </p>
      </section>

      <section id="1-legal-notices">
        <h2>1.&nbsp;Mentions légales</h2>
        <section id="publisher" className="sub-section">
          <h3>Éditeur</h3>
          <List>
            <List.Item>Nom&nbsp;: Robin IMBACH</List.Item>
            <List.Item>
              Email&nbsp;:&nbsp;
              <a href="mailto:contact@picoxia.com" target="_blank">
                contact@picoxia.com
              </a>
            </List.Item>
          </List>
        </section>

        <section id="hosting" className="sub-section">
          <h3>Hébergement</h3>
          <List>
            <List.Item>Google Ireland Limited</List.Item>
            <List.Item>Gordon House, Barrow Street, Dublin 4</List.Item>
            <List.Item>Irlande</List.Item>
            <List.Item>
              N° de téléphone&nbsp;:&nbsp;
              <a href="tel:0035314361000" target="_blank">
                +353 1 436 1000
              </a>
            </List.Item>
          </List>
        </section>
      </section>

      <section id="2-access-to-the-site">
        <h2>2.&nbsp;Accés au site</h2>
        <p>
          Le site <a href="https://picoxia.com/fr/">picoxia.com</a> permet à l'Utilisateur un accès
          aux services suivants&nbsp;:
        </p>
        <ul>
          <li>
            Aide à l'analyse des radiographies de carnivores domestiques (chiens et chats), centrées
            sur le thorax en vue de profil, sur la base d'une aide à la détection de
            13&nbsp;catégories de lésions différentes.
          </li>
        </ul>
        <p>
          Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à
          Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel
          informatique, logiciels, connexion Internet, etc.) sont à sa charge.
        </p>
      </section>

      <section id="3-data-collection">
        <h2>3.&nbsp;Collecte des données</h2>
        <p>
          Les images envoyées par l'Utilisateur sont sauvegardées pour la mise en place de la
          fonctionnalité «&nbsp;historique&nbsp;» à venir et pour l'amélioration du service
          d'interprétation.
        </p>
        <p>
          Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles
          dans le respect de la vie privée conformément à la loi n°&nbsp;78-17 du 6 janvier 1978
          relative à l'informatique, aux fichiers et aux libertés. Elles ne font l'objet d'aucune
          cession à des tiers.
        </p>
        <p>
          Conformément à la loi «&nbsp;Informatique et Libertés&nbsp;» n°&nbsp;78-17 du 6 janvier
          1978, vous disposez d'un droit d'accès, de rectification et d'opposition aux données
          personnelles vous concernant. Pour l'exercer, il suffit de nous en faire la demande par
          courriel à l'adresse suivante&nbsp;:&nbsp;
          <a href="mailto:contact@picoxia.com" target="_blank">
            contact@picoxia.com
          </a>
        </p>
      </section>

      <section id="4-intellectual-property-rights">
        <h2>4.&nbsp;Propriété intellectuelle</h2>
        <p>
          Les marques, logos, signes ainsi que tous les contenus du site (textes, images) font
          l'objet d'une protection par le Code de la propriété intellectuelle et plus
          particulièrement par le droit d'auteur.
        </p>
        <p>
          L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction,
          publication, copie des différents contenus. Il s'engage à une utilisation des contenus du
          site dans un cadre strictement privé, toute utilisation à des fins commerciales et
          publicitaires est strictement interdite. Toute représentation totale ou partielle de ce
          site par quelque procédé que ce soit, sans l'autorisation expresse de l'exploitant du site
          Internet constituerait une contrefaçon sanctionnée par l'article L. 335-2 et suivants du
          Code de la propriété intellectuelle.
        </p>
        <p>
          Il est rappelé conformément à l'article L. 122-5 du Code de propriété intellectuelle que
          l'Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l'auteur et sa
          source.
        </p>
      </section>

      <section id="5-responsibility">
        <h2>5.&nbsp;Responsabilité</h2>
        <p>
          Les résultats proposés par l'algorithme d'interprétation des radiographies ne sont pas
          exempts d'erreurs. L'utilisateur prend entière responsabilité de son utilisation des
          résultats.
        </p>
        <p>
          L'algorithme de traitement d'image est destiné aux professionels de la profession
          vétérinaire uniquement. L'utilisation dudit algorithme par un Utilisateur non-vétérinaire
          est à son entière responsabilité.
        </p>
        <p>
          Les sources des informations diffusées sur le site&nbsp;
          <a href="https://picoxia.com/fr/">picoxia.com</a> sont réputées fiables mais le site ne
          garantit pas qu'il soit exempt de défauts, d'erreurs ou d'omissions. Les informations
          communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
          Malgré des mises à jour régulières, le site&nbsp;
          <a href="https://picoxia.com/fr/">picoxia.com</a> ne peut être tenu responsable de la
          modification des dispositions administratives et juridiques survenant après la
          publication. De même, le site ne peut être tenu responsable de l'utilisation et de
          l'interprétation de l'information contenue dans ce site. Le site&nbsp;
          <a href="https://picoxia.com/fr/">picoxia.com</a> ne peut être tenu pour responsable
          d'éventuels virus qui pourraient infecter l'ordinateur ou tout matériel informatique de
          l'Internaute, suite à une utilisation, à l'accès, ou au téléchargement provenant de ce
          site. La responsabilité du site ne peut être engagée en cas de force majeure ou du fait
          imprévisible et insurmontable d'un tiers.
        </p>
      </section>

      <section id="6-hypertext-links">
        <h2>6.&nbsp;Liens hypertextes</h2>
        <p>
          Des liens hypertextes peuvent être présents sur le site. L'Utilisateur est informé qu'en
          cliquant sur ces liens, il sortira du site&nbsp;
          <a href="https://picoxia.com/fr/">picoxia.com</a>. Ce dernier n'a pas de contrôle sur les
          pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
          responsable de leur contenu.
        </p>
      </section>

      <section id="7-applicable-law-and-jurisdiction">
        <h2>7.&nbsp;Droit applicable et juridiction compétente</h2>
        <p>
          La législation française s'applique au présent contrat. En cas d'absence de résolution
          amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents
          pour en connaître.
        </p>
        <p>
          Pour toute question relative à l'application des présentes CGU, vous pouvez joindre
          l'éditeur aux coordonnées inscrites à l'
          <IntlCrawlableLink url="/terms-of-use#1-legal-notices">article 1</IntlCrawlableLink>.
        </p>
      </section>
    </>
  );
}

/**
 * English Terms Of Use
 * @returns {JSX.Element}
 */
function RenderEnglishText() {
  return (
    <>
      <section id="introduction">
        <p>
          The purpose of these General Conditions of Use (hereinafter referred to as "GCU") is to
          provide a legal framework for the terms and conditions under which{' '}
          <a href="https://picoxia.com/en/">picoxia.com</a> makes the Site and its Services
          available and to define the conditions under which the "User" may access and use the
          Services.
        </p>
        <p>
          These Terms and Conditions are available on the Site under the heading "
          <IntlCrawlableLink url="/terms-of-use">GCU</IntlCrawlableLink>".
        </p>
      </section>

      <section id="1-legal-notices">
        <h2>1.&nbsp;Legal Notices</h2>
        <section id="publisher" className="sub-section">
          <h3>Publisher</h3>
          <List>
            <List.Item>Name: Robin IMBACH</List.Item>
            <List.Item>
              E-mail:&nbsp;
              <a href="mailto:contact@picoxia.com" target="_blank">
                contact@picoxia.com
              </a>
            </List.Item>
          </List>
        </section>

        <section id="hosting" className="sub-section">
          <h3>Hosting</h3>
          <List>
            <List.Item>Google Ireland Limited</List.Item>
            <List.Item>Gordon House, Barrow Street, Dublin 4</List.Item>
            <List.Item>Irelande</List.Item>
            <List.Item>
              Phone number:&nbsp;
              <a href="tel:0035314361000" target="_blank">
                +353 1 436 1000
              </a>
            </List.Item>
          </List>
        </section>
      </section>

      <section id="2-access-to-the-site">
        <h2>2.&nbsp;Access to the site</h2>
        <p>
          The <a href="https://picoxia.com/en/">picoxia.com</a> website gives the user access to the
          following services:
        </p>
        <ul>
          <li>
            Assistance in the analysis of radiographs of domestic carnivores (dogs and cats),
            centred on the thorax in profile view, based on assistance in the detection of 13
            different categories of lesions.
          </li>
        </ul>
        <p>
          The site is accessible free of charge from any location to any user with Internet access.
          All costs incurred by the user in accessing the service (hardware, software, Internet
          connection, etc.) are at the user's expense.
        </p>
      </section>

      <section id="3-data-collection">
        <h2>3.&nbsp;Data collection</h2>
        <p>
          The images sent by the user are stored for future use of the "history" function and to
          improve the interpretation service.
        </p>
        <p>
          The Site guarantees that the User's personal data will be collected and processed in
          compliance with the French Data Protection Act no. 78-17 of 6 January 1978. This
          information will not be disclosed to third parties.
        </p>
        <p>
          In accordance with the French Data Protection Act no. 78-17 of 6 January 1978, you have
          the right to access, correct and oppose any personal data concerning you. To exercise this
          right, please send us a request by e-mail to the following address:&nbsp;
          <a href="mailto:contact@picoxia.com" target="_blank">
            contact@picoxia.com
          </a>
        </p>
      </section>

      <section id="4-intellectual-property-rights">
        <h2>4.&nbsp;Intellectual property rights</h2>
        <p>
          The trademarks, logos, signs and all the contents of the Site (texts, images) are
          protected by the French Intellectual Property Code and, in particular, by copyright.
        </p>
        <p>
          The User must obtain prior authorisation from the Site for any reproduction, publication
          or copying of the various contents. The User undertakes to use the content of the Site for
          strictly private purposes only; any use for commercial or advertising purposes is strictly
          prohibited. Any total or partial representation of this site by any means whatsoever,
          without the express authorisation of the site operator, constitutes an infringement
          punishable under articles L. 335-2 et seq. of the French Intellectual Property Code.
        </p>
        <p>
          In accordance with article L. 122-5 of the French Intellectual Property Code, users who
          reproduce, copy or publish protected content must indicate the author and source.
        </p>
      </section>

      <section id="5-responsibility">
        <h2>5.&nbsp;Responsibility</h2>
        <p>
          The results proposed by the X-ray interpretation algorithm are not error-free. The user
          assumes full responsibility for the use of the results.
        </p>
        <p>
          The image processing algorithm is intended for use by veterinary professionals only. The
          use of the said algorithm by a non-veterinary user is at his/her own risk.
        </p>
        <p>
          The sources of the information published on the{' '}
          <a href="https://picoxia.com/en/">picoxia.com</a> website are believed to be reliable, but
          the website does not guarantee that it is free from defects, errors or omissions. The
          information provided is for general guidance only and has no contractual value. Despite
          regular updates, the <a href="https://picoxia.com/en/">picoxia.com</a> website cannot be
          held responsible for any changes in administrative or legal provisions occurring after
          publication. Likewise, the site cannot be held responsible for the use and interpretation
          of the information contained in this site. The{' '}
          <a href="https://picoxia.com/en/">picoxia.com</a> site cannot be held liable for any
          viruses that may infect the computer or other equipment of the Internet user following use
          of, access to or downloading from this site. The site cannot be held liable in the event
          of force majeure or the unforeseeable and insurmountable act of a third party.
        </p>
      </section>

      <section id="6-hypertext-links">
        <h2>6.&nbsp;Hypertext links</h2>
        <p>
          The site may contain hypertext links. Users are informed that by clicking on these links
          they leave the <a href="https://picoxia.com/en/">picoxia.com</a> website.
          <br />
          <a href="https://picoxia.com/en/">Picoxia.com</a> has no control over the websites to
          which these links lead and cannot be held responsible for their content.
        </p>
      </section>

      <section id="7-applicable-law-and-jurisdiction">
        <h2>7.&nbsp;Applicable law and jurisdiction</h2>
        <p>
          This contract is governed by French law. If any dispute between the parties cannot be
          settled amicably, the French courts shall have exclusive jurisdiction.
        </p>
        <p>
          For any question relating to the application of these Terms and Conditions, you may
          contact the Publisher using the contact details provided in&nbsp;
          <IntlCrawlableLink url="/terms-of-use#1-legal-notices">article 1</IntlCrawlableLink>.
        </p>
      </section>
    </>
  );
}

/**
 * TermsOfUseContent
 * Display the content of the terms of use
 * @returns {JSX.Element}
 */
function TermsOfUseContent() {
  const locale = useSelector(makeSelectLocale());

  if (locale === 'fr') {
    return <RenderFrenchText />;
  }

  return <RenderEnglishText />;
}

export default TermsOfUseContent;
