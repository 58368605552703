/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import 'semantic-ui-css/semantic.min.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Icon,
  Table,
  Button,
  Modal,
  Form,
  Dropdown,
  Pagination,
  Label,
  Input,
  Checkbox,
  Loader,
} from 'semantic-ui-react';
import Select from 'react-select';
import _ from 'lodash';
import toastifyPromise from 'app/utils/toastifyPromise';
import withContext from 'app/utils/withContext';
import AdminStoreContext from 'app/injectors/AdminStore/context';
import EndSubscriptionConfirm from 'app/containers/ManagementPage/EndSubscriptionConfirm';
import RequiredAsterisk from 'app/components/RequiredAsterisk';
import formatUsersToCSV from 'app/utils/management/formatUsersToCSV';
import ApiCalls from '../../utils/apiCalls';
import DisplayDate from '../../components/DisplayDate';
import SubscriptionModal from '../../components/SubscriptionModal';
import FormattedServerError from '../../components/FormattedServerError';
import UserLabels from '../../components/UserLabels';

import './style.scss';
import downloadFile from 'app/utils/downloadFile';
import { format } from 'date-fns';
import computeUserMeanWeekUsageFromSubscriptions from 'app/containers/ManagementPage/computeUserMeanWeekUsageFromSubscriptions';
import StorageUsageDisplay from 'app/containers/StorageUsageDisplay';
import ButtonFreeTrialSubscription from 'app/components/Button/FreeTrialSubscription';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import countries from 'app/constants/countries';
import TeleradiologyCheckboxPermission from './Teleradiology/checkboxPermission';
import { selectPermissionAdmin, selectPermissions } from 'app/redux/permissions/reducer';

function WeekUsage({ user }) {
  const content = `${user.week_usage ?? 0} / ${user.week_limit ?? 100}`;
  if (user.week_usage === user.week_limit) {
    return <b>{content}</b>;
  }
  return <span>{content}</span>;
}

if (!String.prototype.format) {
  String.prototype.format = function () {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, number) =>
      typeof args[number] !== 'undefined' ? args[number] : match
    );
  };
}
const DEFAULT_LANGUAGE = 'fr';
const filterSubscriptionOptions = [
  {
    value: 'en cours',
    labelKey: 'mgmt.subscription.filter.ongoing',
  },
  {
    value: 'va expirer',
    labelKey: 'mgmt.subscription.filter.expireSoon',
  },
  {
    value: "vient d'expirer",
    labelKey: 'mgmt.subscription.filter.expiredRecently',
  },
  {
    value: "pas d'abonnements",
    labelKey: 'mgmt.subscription.filter.noSubscription',
  },
];

const filterAcquisitionOptions = [
  {
    value: 'avec license',
    labelKey: 'mgmt.subscription.acquisition.withLicense',
  },
  {
    value: 'sans license',
    labelKey: 'mgmt.subscription.acquisition.withoutLicense',
  },
];
export class ManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSubscription: '',
      filterAcquisition: '',
      users: [],
      teams: [],
      teamsLoading: false,
      numberOfUsers: 0,
      labels: null,
      isUserModalOpen: false,
      mail: '',

      workplaceName: '',
      address: '',
      postCode: '',
      city: '',
      country: '',
      countryCode: DEFAULT_LANGUAGE,
      newUserManager: undefined,
      isMonoManager: undefined,

      sortingMethods: {},

      options: null,
      selectedOptions: [],

      newUserFormError: null,
      newUserFormLoading: false,
      activePage: 1,
      mailSearch: '',
      addressSearch: '',
      language: DEFAULT_LANGUAGE,
    };
    this.userSearch = _.debounce(this.userSearch, 100);
  }

  componentDidMount = () => {
    this.getUsers();
    this.getLabels();
    this.getTeamsNames();
  };

  componentDidUpdate(prevProps, prevState) {
    let shouldComputeNewUserManager = false;
    ['teams', 'managerName', 'seenManagers', 'isAdmin'].forEach((key) => {
      if (prevProps[key] !== this.props[key]) shouldComputeNewUserManager = true;
    });
    ['teams'].forEach((key) => {
      if (prevState[key] !== this.state[key]) shouldComputeNewUserManager = true;
    });
    if (shouldComputeNewUserManager) this.computeManagerIfPossible();
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getUsers = () => {
    const {
      activePage,
      mailSearch,
      addressSearch,
      selectedOptions,
      sortingMethods,
      filterSubscription,
      filterAcquisition,
    } = this.state;
    return ApiCalls.getUsersFilteredAndCounted(
      activePage,
      mailSearch,
      addressSearch,
      selectedOptions,
      sortingMethods,
      filterSubscription,
      filterAcquisition
    ).then((response) => {
      this.setState({
        users: response.data.users,
        numberOfUsers: response.data.numberOfUsers,
      });
    });
  };

  getTeamsNames = () => {
    const { adminStore } = this.props;
    this.setState({ teamsLoading: true });
    adminStore.getTeams().then((teams) => this.setState({ teams, teamsLoading: false }));
  };

  getPossibleManagers = () => {
    const { teams, managerName, seenManagers, isAdmin } = this.props;
    let adminTeams;
    if (!isAdmin) {
      adminTeams = _.filter(_.mapValues(teams, (role, team) => team));
    } else {
      adminTeams = this.state.teams?.map(({ name }) => name);
    }
    return {
      teams: adminTeams,
      managerName,
      seenManagers: seenManagers?.filter((name) => name !== '*'),
    };
  };

  getMonoManagerName = () => {
    const { teams, managerName, seenManagers } = this.getPossibleManagers();
    const managerList = _.uniq(_.filter([...teams, managerName, ...seenManagers]));
    const isMonoManager = managerList.length === 1;
    return isMonoManager && managerList[0];
  };

  computeManagerIfPossible = () => {
    const monoManager = this.getMonoManagerName();
    if (monoManager) this.setState({ newUserManager: monoManager, isMonoManager: true });
    else this.setState({ isMonoManager: false });
  };

  renderManagerPicker = ({ manager: currentManager, onChange }) => {
    const { teamsLoading } = this.state;
    const { intl } = this.props;

    if (teamsLoading)
      return (
        <div className="flex center">
          <Loader active inline size="small" />
        </div>
      );

    const { teams, managerName, seenManagers } = this.getPossibleManagers();
    const teamsOptions = teams.map((team) => ({ value: team, text: team }));
    let managersOptions = _([managerName, ...seenManagers])
      .uniq()
      .map((manager) => ({
        value: manager,
        text: manager,
      }))
      .value();
    managersOptions = managersOptions.length ? managersOptions : undefined;

    const options = [...teamsOptions, ...managersOptions];
    const selectedOptionText = options.find((option) => option.value === currentManager)?.text;

    return (
      <Dropdown
        text={selectedOptionText}
        value={selectedOptionText}
        placeholder={intl.formatMessage({ id: 'mgmt.noManager' })}
        className="selection"
      >
        <Dropdown.Menu>
          {teamsOptions.length && (
            <>
              <Dropdown.Header
                icon="group"
                content={this.formatMessage({ id: 'mgmt.managerPicker.teams' })}
              />
              <Dropdown.Menu scrolling>
                {teamsOptions.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    {...option}
                    selected={currentManager === option.value}
                    onClick={(e, data) => onChange(data)}
                  />
                ))}
              </Dropdown.Menu>
            </>
          )}
          {managersOptions.length && (
            <>
              <Dropdown.Header
                icon="user"
                content={this.formatMessage({ id: 'mgmt.managerPicker.manager' })}
              />
              <Dropdown.Menu scrolling>
                {managersOptions.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    {...option}
                    selected={currentManager === option.value}
                    onClick={(e, data) => onChange(data)}
                  />
                ))}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  getLabels = () => {
    ApiCalls.getLabels()
      .then((response) => {
        const labels = {};
        const options = [];
        if (response.data.labels) {
          response.data.labels.forEach((label) => {
            labels[label._id] = label.name;
            options.push({
              label: label.name,
              value: label._id,
            });
          });
        }
        this.setState({
          labels,
          options,
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.setState({ labels: null, options: null });
        }
      });
  };

  updateUserManager = (user, newManager) => {
    const promise = ApiCalls.updateManagedBy(user.mail, newManager);
    toastifyPromise('changeManager', promise);
    promise.then(() => {
      user.managed_by = newManager;
      this.forceUpdate();
    });
  };

  renderUsers = () => {
    const { managerName, canChangeManager } = this.props;
    const { users, labels } = this.state;

    if (users.length !== 0) {
      return users.map((user) => (
        <Table.Row key={user._id}>
          <Table.Cell textAlign="center">{this.renderMailConfirmed(user)}</Table.Cell>
          <Table.Cell>{user.mail}</Table.Cell>
          <Table.Cell>{this.renderAddress(user)}</Table.Cell>
          <Table.Cell>
            <DisplayDate date={user.registration_date} format="short" />
          </Table.Cell>
          <Table.Cell>{user.sponsor}</Table.Cell>
          {/* <Table.Cell>{this.renderCredits(user.credits)}</Table.Cell> */}
          <Table.Cell>
            <WeekUsage user={user} />
          </Table.Cell>
          {canChangeManager && (
            <Table.Cell>
              {this.renderManagerPicker({
                manager: user.managed_by,
                onChange: ({ value }) => this.updateUserManager(user, value),
              })}
            </Table.Cell>
          )}
          {labels && (
            <Table.Cell>
              <UserLabels
                user={user}
                users={users}
                labels={labels}
                onAddLabel={this.getLabels}
                onAddLabelToUser={this.getUsers}
                onDeleteLabel={this.getLabels}
                onDeleteLabelUser={this.getUsers}
              />
            </Table.Cell>
          )}
          <Table.Cell>{this.renderSubscriptions(user)}</Table.Cell>
          <Table.Cell>{this.renderUsage(user)}</Table.Cell>
          {managerName && <Table.Cell>{this.renderAcquisitionLicense(user)}</Table.Cell>}
          {managerName && (
            <Table.Cell>
              <TeleradiologyCheckboxPermission user={user} onChangePermission={this.getUsers} />
            </Table.Cell>
          )}
        </Table.Row>
      ));
    }
    return (
      <Table.Row textAlign="center">
        <Table.Cell colSpan="9">
          <FormattedMessage id="mgmt.noUser" />
        </Table.Cell>
      </Table.Row>
    );
  };

  renderMailConfirmed = (user) => {
    const { intl } = this.props;
    if (user.mail_confirmed === true) {
      return (
        <Icon
          name="check circle outline"
          color="green"
          title={intl.formatMessage({ id: 'mgmt.mail-confirmed.title.true' })}
        />
      );
    }
    return (
      <Icon
        name="dont"
        color="red"
        title={intl.formatMessage({ id: 'mgmt.mail-confirmed.title.false' })}
      />
    );
  };

  renderAddress = (user) => {
    if (user.hasOwnProperty('address')) {
      return (
        <div>
          {user.address.workplaceName}
          <br />
          {user.address.address}
          <br />
          {user.address.postCode} {user.address.city}
          <br />
          {user.address.country}
          <br />
          {user.address.phoneNumber}
        </div>
      );
    }
    return user.clinicInfo;
  };

  renderSubscriptions = (user) => {
    const { intl } = this.props;
    const subscriptions = user.subscription;
    const shouldShowSubscriptionTable = subscriptions && subscriptions.length > 0;
    const mailIsConfirmed = user.mail_confirmed;
    const userTeam = this.state.teams.find(({ name }) => name === user.managed_by);
    /*
     * int freeTrialDays
     * Default :    15 days
     * TeamMember : 30 days
     * Si vous souhaitez changer ces valeurs, il faut également les changer dans
     * server/server/user_management.py
     * lignes 237 et 254
     */
    const freeTrialDays = userTeam === undefined ? 15 : 30;
    return (
      <>
        {shouldShowSubscriptionTable && (
          <Table compact className="table-licences-list">
            <Table.Header>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.subscription.name' })}>
                  <Icon
                    name="certificate"
                    aria-label={intl.formatMessage({ id: 'mgmt.subscription.name' })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.start' })}>
                  <Icon
                    name="calendar check outline"
                    aria-label={intl.formatMessage({ id: 'mgmt.start' })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.end' })}>
                  <Icon
                    name="calendar times outline"
                    aria-label={intl.formatMessage({ id: 'mgmt.end' })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.price' })}>
                  <Icon name="eur" aria-label={intl.formatMessage({ id: 'mgmt.price' })} />
                </Table.HeaderCell>
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.usage' })}>
                  <Icon name="image" aria-label={intl.formatMessage({ id: 'mgmt.usage' })} />
                </Table.HeaderCell>
                <Table.HeaderCell title={intl.formatMessage({ id: 'mgmt.actions' })} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subscriptions.map((subscription) =>
                this.renderSingleSubscription(user.mail, subscription)
              )}
            </Table.Body>
          </Table>
        )}
        {mailIsConfirmed && (
          <ButtonFreeTrialSubscription
            user={user}
            onSubscriptionAdded={this.getUsers}
            nbDays={freeTrialDays}
          />
        )}
        {mailIsConfirmed && <SubscriptionModal user={user} onSubscriptionAdded={this.getUsers} />}
      </>
    );
  };

  renderUsage = (user) => {
    const subscriptionsWithDates = (user.subscription ?? []).map(
      ({ buyDate, currentPeriodEnd, used }) => ({
        buyDate: new Date(Date.parse(buyDate)),
        currentPeriodEnd: new Date(Date.parse(currentPeriodEnd)),
        used,
      })
    );
    const meanWeekUsage = computeUserMeanWeekUsageFromSubscriptions(subscriptionsWithDates);
    return <p>{meanWeekUsage.toFixed(2)}</p>;
  };

  updateUserAcquisitionLicense = (userMail, acquisitionIdentifiersLimit) => {
    const hasAcquisition = acquisitionIdentifiersLimit > 0;
    ApiCalls.setAcquisitionPermission(
      userMail,
      hasAcquisition ? acquisitionIdentifiersLimit : 0
    ).then(this.getUsers);
  };

  renderAcquisitionLicense = (user) => {
    const { permissions, acquisition_identifiers_limit } = user;
    const hasAcquisition = permissions?.includes('acquisition') ?? false;
    const licenseLimit = hasAcquisition ? acquisition_identifiers_limit ?? 1 : 0;
    const text = hasAcquisition ? licenseLimit : this.formatMessage({ id: 'general.none' });

    if (user.mail_confirmed === false) {
      return null;
    }

    return (
      <div className="user-management__user-row__acquisition-cell">
        <div>
          <Button.Group>
            <Button basic color={hasAcquisition ? 'green' : 'red'}>
              {text}
            </Button>
            <Button
              icon
              onClick={() => this.updateUserAcquisitionLicense(user.mail, licenseLimit + 1)}
            >
              <Icon name="add" />
            </Button>
            <Button
              icon
              onClick={() => this.updateUserAcquisitionLicense(user.mail, licenseLimit - 1)}
            >
              <Icon name="minus" />
            </Button>
          </Button.Group>
        </div>
        <StorageUsageDisplay storagesUsage={user.storagesUsage} />
      </div>
    );
  };

  renderSingleSubscription = (user_mail, subscription) => (
    <Table.Row key={subscription._id} className={this.getSubscriptionColor(subscription)}>
      <Table.Cell>
        <b>{subscription.backend_name}</b>
      </Table.Cell>
      <Table.Cell>
        <DisplayDate date={subscription.buyDate} format="short" />
      </Table.Cell>
      <Table.Cell>
        <DisplayDate date={subscription.currentPeriodEnd} format="short" />
      </Table.Cell>
      <Table.Cell>{subscription.price / 100}&nbsp;€</Table.Cell>
      <Table.Cell>
        {subscription.used} / {subscription.numberOfImages}
      </Table.Cell>
      <Table.Cell textAlign="right">{this.renderEndButton(user_mail, subscription)}</Table.Cell>
    </Table.Row>
  );

  renderEndButton = (user_mail, subscription) => {
    if (!this.isSubscriptionOver(subscription)) {
      return (
        <EndSubscriptionConfirm
          userMail={user_mail}
          subscription={subscription}
          onSubscriptionEnded={this.getUsers}
        />
      );
    }
  };

  isSubscriptionOver = (subscription) => Date.parse(subscription.currentPeriodEnd) <= Date.now();

  getSubscriptionColor = (subscription) => {
    const dateNow = new Date();
    const dateInAMonth = new Date(new Date().setMonth(dateNow.getMonth() + 1));
    const endDate = new Date(subscription.currentPeriodEnd);
    if (endDate > dateInAMonth) {
      return 'green';
    }
    if (endDate > dateNow) {
      return 'orange';
    }
    return 'red';
  };

  renderCredits = (credits) => {
    if (!credits || credits.length == 0) {
      return <FormattedMessage id="general.none" />;
    }
    return (
      <>
        {credits[credits.length - 1].used} / {credits[credits.length - 1].numberOfImages}
      </>
    );
  };

  renderSingleCredit = (credit) => (
    <Table.Row key={credit._id} className={this.getCreditColor(credit)}>
      <Table.Cell>
        <DisplayDate date={credit.buyDate} format="short" />
      </Table.Cell>
      <Table.Cell>
        {credit.used} / {credit.numberOfImages}
      </Table.Cell>
    </Table.Row>
  );

  getCreditColor = (credit) => {
    if (credit.numberOfImages - credit.used > 0) {
      return 'green';
    }
    return 'red';
  };

  handleSubmitNewUser = () => {
    const { mail, workplaceName, address, postCode, city, language, phoneNumber, newUserManager } =
      this.state;
    this.setState({ newUserFormError: null, newUserFormLoading: true, newUserSuccess: false });

    const country = countries.find(({ countryCode }) => countryCode === this.state.countryCode);
    const { countryCode } = this.state;

    ApiCalls.addUser(
      mail,
      newUserManager,
      workplaceName,
      address,
      postCode,
      city,
      countryCode,
      country.name,
      language,
      phoneNumber
    )
      .then(() => {
        this.setState({ newUserFormLoading: false, newUserSuccess: true });
        setTimeout(() => {
          this.setState({ newUserSuccess: false, isUserModalOpen: false });
          this.getUsers();
        }, 1000);
      })
      .catch((response) => {
        this.setState({ newUserFormError: response, newUserFormLoading: false });
      });
  };

  renderNewUserFormError = () => {
    if (this.state.newUserFormError) {
      return (
        <span style={{ color: '#cc0000' }}>
          <FormattedServerError error={this.state.newUserFormError} />
        </span>
      );
    }
  };

  //  handleChange = (e, { name, value }) => this.setState({ [name]: value })
  handleChange = (value, fieldName) => {
    const stateUpdate = {};
    stateUpdate[fieldName] = value;
    this.setState(stateUpdate);
  };

  setNewUserManager = ({ value }) => this.setState({ newUserManager: value });

  renderNewUserModal = () => {
    const { isMonoManager, newUserManager } = this.state;
    return (
      <Modal
        onClose={this.closeNewUserModal}
        onOpen={this.openNewUserModal}
        open={this.state.isUserModalOpen}
        trigger={
          <Button positive>
            <Icon name="plus" />
            <FormattedMessage id="mgmt.newUser" />
          </Button>
        }
      >
        <Modal.Header>
          <FormattedMessage id="mgmt.newUser" />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmitNewUser}>
            <Form.Group widths="equal">
              <Form.Input
                placeholder={this.formatMessage({ id: 'forms.email' })}
                name="mail"
                label={
                  <label>
                    <FormattedMessage id="forms.email" />
                    &nbsp;
                    <RequiredAsterisk />
                  </label>
                }
                onChange={(e, { value }) => this.handleChange(value, 'mail')}
              />
              <Form.Field>
                <label>
                  <FormattedMessage id="mgmt.language" />
                  &nbsp;
                  <RequiredAsterisk />
                </label>
                <Dropdown
                  className="languageDropdown"
                  selection
                  onChange={(e, data) => this.setState({ language: data.value })}
                  defaultValue={DEFAULT_LANGUAGE}
                  options={[
                    { key: 'Français', text: 'Français', value: 'fr', flag: 'fr' },
                    { key: 'English', text: 'English', value: 'en', flag: 'us' },
                    { key: 'Deutsch', text: 'Deutsch', value: 'de', flag: 'de' },
                    { key: 'Serbian', text: 'Srpski', value: 'sr', flag: 'rs' },
                    { key: 'Greek', text: 'Ελληνικά', value: 'el', flag: 'gr' },
                    { key: 'Dutch ', text: 'Nederlands', value: 'nl', flag: 'nl' },
                    { key: 'Russian ', text: 'русский', value: 'ru', flag: 'ru' },
                    { key: 'Ukrainian ', text: 'Українська', value: 'uk', flag: 'ua' },
                    { key: 'Italian ', text: 'Italiano', value: 'it', flag: 'it' },
                    { key: 'Finish ', text: 'viedä loppuun', value: 'fi', flag: 'fi' },
                    { key: 'Portuguese ', text: 'Português', locale: 'pt', flag: 'pt' },
                    { key: 'Slovak', text: 'Slovenský', locale: 'sk', flag: 'sk' },
                    { key: 'Spanish', text: 'Español', locale: 'es', flag: 'es' },
                    { key: 'Czech', text: 'Čeština', locale: 'cz', flag: 'cz' },
                  ]}
                />
              </Form.Field>
            </Form.Group>
            <Form.Input
              fluid
              value={this.state.workplaceName}
              onChange={(e, { value }) => this.handleChange(value, 'workplaceName')}
              label={
                <label>
                  <FormattedMessage id="invoiceAddressModal.workplaceName" />
                  &nbsp;
                  <RequiredAsterisk />
                </label>
              }
              placeholder={this.formatMessage({ id: 'invoiceAddressModal.workplaceName' })}
            />
            <Form.Input
              fluid
              value={this.state.address}
              onChange={(e, { value }) => this.handleChange(value, 'address')}
              label={this.formatMessage({ id: 'invoiceAddressModal.address' })}
              placeholder={this.formatMessage({ id: 'invoiceAddressModal.address' })}
            />
            <Form.Group widths="equal">
              <Form.Input
                fluid
                value={this.state.postCode}
                onChange={(e, { value }) => this.handleChange(value, 'postCode')}
                label={
                  <label>
                    <FormattedMessage id="invoiceAddressModal.postCode" />
                    &nbsp;
                    <RequiredAsterisk />
                  </label>
                }
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.postCode' })}
              />
              <Form.Input
                fluid
                value={this.state.city}
                onChange={(e, { value }) => this.handleChange(value, 'city')}
                label={
                  <label>
                    <FormattedMessage id="invoiceAddressModal.city" />
                    &nbsp;
                    <RequiredAsterisk />
                  </label>
                }
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.city' })}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <div className="required field">
                <label htmlFor="country">
                  <FormattedMessage id="invoiceAddressModal.country" />
                </label>
                <DropdownCountriesList
                  fluid
                  value={this.state.countryCode}
                  onChange={(e, { value }) => this.handleChange(value, 'countryCode')}
                />
              </div>
              <Form.Input
                fluid
                value={this.state.phoneNumber}
                onChange={(e, { value }) => this.handleChange(value, 'phoneNumber')}
                label={this.formatMessage({ id: 'invoiceAddressModal.phoneNumber' })}
                placeholder={this.formatMessage({ id: 'invoiceAddressModal.phoneNumber' })}
              />
            </Form.Group>
            {!isMonoManager && (
              <Form.Field width="3">
                <label>
                  <FormattedMessage id="mgmt.manager" />
                  &nbsp;
                  <RequiredAsterisk />
                </label>
                {this.renderManagerPicker({
                  manager: newUserManager,
                  onChange: this.setNewUserManager,
                })}
              </Form.Field>
            )}
          </Form>
          {this.renderNewUserFormError()}
        </Modal.Content>
        {this.renderNewUserFormActions()}
      </Modal>
    );
  };

  renderNewUserFormActions = () => {
    if (this.state.newUserSuccess) {
      return (
        <Modal.Actions>
          <Button positive>
            <FormattedMessage id="adminPage.modal.confirmButton.success" />
          </Button>
        </Modal.Actions>
      );
    }
    if (this.state.newUserFormLoading) {
      return (
        <Modal.Actions>
          <Button positive loading />
        </Modal.Actions>
      );
    }
    return (
      <Modal.Actions>
        <Button basic color="red" onClick={this.closeNewUserModal}>
          <Icon name="times" />
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button onClick={this.handleSubmitNewUser} positive>
          <Icon name="plus" />
          <FormattedMessage id="general.add" />
        </Button>
      </Modal.Actions>
    );
  };

  closeNewUserModal = () => {
    this.setState({ isUserModalOpen: false, newUserFormError: null });
  };

  openNewUserModal = () => {
    this.setState({ isUserModalOpen: true });
  };

  onPageChange = (event, { activePage }) => {
    this.setState({ activePage }, () => {
      this.getUsers();
    });
  };

  userSearch = () => this.getUsers();

  renderUserSearch = () => (
    <div>
      <Input
        className="search-input"
        placeholder={this.formatMessage({ id: 'forms.email' })}
        name="mailSearch"
        value={this.state.mailSearch}
        onChange={(e) => {
          this.setState({ mailSearch: e.target.value, activePage: 1 });
          this.userSearch(e.target.value);
        }}
      />
    </div>
  );

  renderAdressSearch = () => (
    <div>
      <Input
        className="search-input"
        placeholder={this.formatMessage({ id: 'forms.address' })}
        name="addressSearch"
        value={this.state.addressSearch}
        onChange={(e) => {
          this.setState({ addressSearch: e.target.value, activePage: 1 });
          this.userSearch(e.target.value);
        }}
      />
    </div>
  );

  handleSelectLabels = (labels) => {
    const selectedOptions = labels.map((label) => label.value);
    this.setState({ selectedOptions }, () => {
      this.getUsers();
    });
  };

  renderSearchByLabels = () => (
    <Select
      className="search-input"
      placeholder={this.formatMessage({ id: 'general.labels' })}
      isMulti
      options={this.state.options}
      onChange={this.handleSelectLabels}
    />
  );

  handleSelectSubscriptionFilter = (option) => {
    if (option != null) {
      this.setState(
        {
          filterSubscription: option.value,
        },
        () => {
          this.getUsers();
        }
      );
    } else {
      this.setState(
        {
          filterSubscription: '',
        },
        () => {
          this.getUsers();
        }
      );
    }
  };

  renderSearchBySubscription = () => (
    <Select
      className="search-input"
      isClearable
      isSearchable
      placeholder={this.formatMessage({ id: 'mgmt.subscriptions' })}
      options={filterSubscriptionOptions.map(({ value, labelKey }) => ({
        value,
        label: this.formatMessage({ id: labelKey }),
      }))}
      onChange={this.handleSelectSubscriptionFilter}
    />
  );

  handleSelectAcquisitionFilter = (option) => {
    if (option != null) {
      this.setState(
        {
          filterAcquisition: option.value,
        },
        () => {
          this.getUsers();
        }
      );
    } else {
      this.setState(
        {
          filterAcquisition: '',
        },
        () => {
          this.getUsers();
        }
      );
    }
  };

  renderSearchByAcquisition = () => (
    <Select
      className="search-input"
      isClearable
      isSearchable
      placeholder={this.formatMessage({ id: 'mgmt.acquisition' })}
      options={filterAcquisitionOptions.map(({ value, labelKey }) => ({
        value,
        label: this.formatMessage({ id: labelKey }),
      }))}
      onChange={this.handleSelectAcquisitionFilter}
    />
  );

  changeSortingDirection = (type) => {
    const sortingMethods = { ...this.state.sortingMethods };
    if (sortingMethods[type] === undefined) {
      sortingMethods[type] = 1;
    } else if (sortingMethods[type] === 1) {
      sortingMethods[type] = -1;
    } else {
      delete sortingMethods[type];
    }
    this.setState(
      {
        sortingMethods,
      },
      () => {
        this.getUsers();
      }
    );
  };

  getSortedClassName = (type) => {
    const { sortingMethods } = this.state;
    if (sortingMethods[type] === 1) return 'sorted ascending';
    if (sortingMethods[type] === -1) return 'sorted descending';
    return 'sorted';
  };

  fetchUsersAndConvertToCSV = async () => {
    const { intl } = this.props;
    // Get all users
    const { users } = (
      await ApiCalls.getUsersFilteredAndCounted(1, '', '', [], { mail: 1 }, '', '', 0)
    ).data;
    return formatUsersToCSV(users, intl);
  };

  exportUsersToCSV = async () => {
    const usersCSV = await toastifyPromise('exportUsersToCSV', this.fetchUsersAndConvertToCSV());

    downloadFile(
      new Blob([usersCSV], { type: 'text/csv' }),
      `users_export-${format(new Date(), 'yyyy-MM-dd_kk-mm')}.csv`
    );
  };

  render() {
    const { managerName, canChangeManager, intl } = this.props;
    const { numberOfUsers, labels } = this.state;
    return (
      <div className="user-management">
        <Helmet title="User management" />
        <div className="ui user-management__container">
          <h2>
            <FormattedMessage id="mgmt.users" />
          </h2>
          <div>
            {this.renderNewUserModal()}
            <Button primary onClick={this.exportUsersToCSV}>
              <Icon name="file excel outline" title="CSV" />
              <FormattedMessage id="mgmt.exportUsers" />
            </Button>
          </div>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center" verticalAlign="middle">
                  <Icon
                    name="search"
                    size="large"
                    color="grey"
                    title={intl.formatMessage({ id: 'monitoring.animalSearch' })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell> {this.renderUserSearch()}</Table.HeaderCell>
                <Table.HeaderCell> {this.renderAdressSearch()}</Table.HeaderCell>
                <Table.HeaderCell>{this.renderSearchBySubscription()}</Table.HeaderCell>
                {managerName && (
                  <Table.HeaderCell>{this.renderSearchByAcquisition()}</Table.HeaderCell>
                )}
                {labels && <Table.HeaderCell>{this.renderSearchByLabels()}</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
          </Table>

          <div className="user-management__number-of-users">
            <Label color="blue">
              <Icon name="users" /> <FormattedMessage id="mgmt.numberOfUsers" /> :{' '}
              <strong>{numberOfUsers}</strong>
            </Label>
          </div>

          <div className="users-list">
            <Table sortable celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    className={this.getSortedClassName('mail_confirmed')}
                    onClick={() => this.changeSortingDirection('mail_confirmed')}
                    width={1}
                  >
                    <Icon.Group
                      size="large"
                      title={intl.formatMessage({ id: 'mgmt.mail-confirmed.label' })}
                    >
                      <Icon color="grey" name="user" />
                      <Icon size="large" color="green" name="check circle outline" />
                    </Icon.Group>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={this.getSortedClassName('mail')}
                    onClick={() => this.changeSortingDirection('mail')}
                  >
                    <FormattedMessage id="forms.email" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="forms.address" />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={this.getSortedClassName('registration_date')}
                    onClick={() => this.changeSortingDirection('registration_date')}
                  >
                    <FormattedMessage id="mgmt.subscriptionDate" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="mgmt.sponsor" />
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell>
                    <FormattedMessage id="mgmt.credits" />
                  </Table.HeaderCell> */}
                  <Table.HeaderCell>
                    <FormattedMessage id="mgmt.weeklyLimit" />
                  </Table.HeaderCell>
                  {canChangeManager && (
                    <Table.HeaderCell>
                      <FormattedMessage id="mgmt.manager" />
                    </Table.HeaderCell>
                  )}
                  {labels && (
                    <Table.HeaderCell>
                      <FormattedMessage id="mgmt.labels" />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    className={this.getSortedClassName('subscription.buyDate')}
                    onClick={() => this.changeSortingDirection('subscription.buyDate')}
                    width={5}
                  >
                    <FormattedMessage id="mgmt.subscriptions" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="mgmt.meanUsage" />
                  </Table.HeaderCell>
                  {managerName && (
                    <Table.HeaderCell
                      className={this.getSortedClassName('worst_storage')}
                      onClick={() => this.changeSortingDirection('worst_storage')}
                    >
                      <FormattedMessage id="mgmt.acquisitionLicense" />
                    </Table.HeaderCell>
                  )}
                  {managerName && (
                    <Table.HeaderCell>
                      <FormattedMessage id="teleradiology.title" />
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>{this.renderUsers()}</Table.Body>
            </Table>
          </div>
          <div className="user-management__pagination">
            <Pagination
              boundaryRange={0}
              activePage={this.state.activePage}
              onPageChange={this.onPageChange}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={2}
              totalPages={100}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const permissions = selectPermissions(state);
  const managerPermissions = permissions.get('managerPermissions');

  return {
    isLoggedIn: state.get('global').get('loggedIn'),
    isAdmin: selectPermissionAdmin(state),
    seenManagers: managerPermissions.get('can_see_users_managed_by'),
    managerName: managerPermissions.get('manager_name'),
    canChangeManager: managerPermissions.get('can_change_manager'),
    teams: permissions.get('teams'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withContext(injectIntl(ManagementPage), AdminStoreContext, 'adminStore'));
